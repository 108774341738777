import React from 'react';

export default class Footer extends React.Component{
    render(){
        return(
            <>
             <div className="flex justify-center items-center mt-32 mb-8 w-full bottom-0">
                <h6 className="text-sm">&copy; 2018 Schoolmedia. All right reserved</h6>
             </div>
            </>
        )
    }
}