import React from "react";
import DataTable from "../../partials/table/DataTables.js";
import { Link } from "react-router-dom";
import conf from "../../../config.js";
import SelectJs from "../../partials/Select.js";
import axios from "axios";
import { Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import tokenApi from "../../auth/Helpers";

export default class Analisis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${conf.uri_backend}api/v1/sarpras-m/sarpras/analisis_ruang/table`
    };

  }

  componentDidMount() {
    // get session user
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ data_session: res.data }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {
            headers: {
              Authorization: tokenApi
            }
          }).then(res => {
            this.setState({
              institusi_id_select : res.data.id,
              jenjang_id_select   : res.data.m_struktur_jenjang_id,
              npsn_id_select      : res.data.npsn,
            }, function(){
              this.dt.fetchEntities(`&jenjang_id=${this.state.jenjang_id_select}&institusi_id=${this.state.institusi_id_select}`);
            });
          });
        });
      });
    }

  }

  render() {
    return (
     <div>
      <div className=" shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border sm-container">
        <div className="flex justify-between items-center mt-2  px-6 pb-2">
          <h1 className="text-xl text-gray-800 font-extrabold">
            Sarana & Prasarana
            <span className="text-gray-500 font-thin block text-sm">
              Daftar Analisis.
              {/* <a href="/sadasd" className="text-blue-600 ml-1">
                Pelajari Lebih Lanjut ?
              </a> */}
            </span>
          </h1>
        </div>
      </div>

      <div className=" shadow-md mx-auto py-2 mt-2 rounded-lg bg-white border sm-container">
        <div className="px-2 py-1">
          <div className="px-2 py-1">
            <DataTable
              ref={(dt) => {
                this.dt = dt;
              }}
              action={{
                keterangan: (col, data) => {
                  var keterangan = 0;

                  keterangan = (data.jumlah_eksisting - data.jumlah_standar);

                  if(keterangan == 0){
                    return (<p className="text-sm">Sesuai Standar</p>);
                  }
                  if(keterangan < 0){
                    return (<p className="text-sm">Belum Sesuai Standar</p>);
                  }
                  if(keterangan > 0){
                    return (<p className="text-sm">Melebihi Standar</p>);
                  }
                },
              }}
              url={this.state.url}
              columns={[
                {
                  index: "nama_ruang",
                  label: "Jenis Ruang",
                },
                {
                  index: "indeks_ruang",
                  label: "Kode",
                },
                {
                  index: "jumlah_standar",
                  label: "Jumlah Sesuai Standart",
                },
                {
                  index: "jumlah_eksisting",
                  label: "JUMLAH EKSISTING",
                },
                {
                  index: "selisih",
                  label: "Selisih",
                },
                {
                  index: "keterangan",
                  label: "keterangan",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
    );
  }
}
