import React from "react";
import DataTable from "./../../partials/table/DataTables.js";
import { Link } from "react-router-dom";
import conf from "./../../../config.js";
import SelectJs from "./../../partials/Select.js";
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import SweetAlert from "sweetalert2-react";
import Skeleton from "react-loading-skeleton";

const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "100px",
    minWidth: "15rem",
  }),
};

const dataKurikulum = [
  { value: "k13", label: "K13" },
  { value: "merdeka", label: "Merdeka" },
];

export default class OrangtuaRombel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // url Api
      url: `${conf.uri_backend}api/v1/rombel/orangtua`,
      url_get_rombel: `${conf.uri_backend}api/v1/rombel/get_rombel`,
      url_get_smk: `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,
      url_get_spektrum: `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_filter: `${conf.uri_backend}api/v1/rombel/filter`,
      url_show_data: "",
      query: "",
      tahunSekarang: new Date().getFullYear(),
      searchText: "",
      search: false,
      showFilter: false,
      // input filter
      tahun_ajaran_search: "",
      kurikulum: "",
      kelas_search: "",
      peminatan_search: "",
      rombel_search: "",
      bidang_search: "",
      program_search: "",
      kompetensi_search: "",
      // data filter
      data_tahun_ajaran: "",
      data_kelas: "",
      data_peminatan: "",
      data_bidang: "",
      data_program: "",
      data_kompetensi: "",
      data_rombel: "",
      data_wali_kelas: "",
      loadingFilter: true,
    };

    // filter and search
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterKurikulum = this.filterKurikulum.bind(this);
    this.filterKelas = this.filterKelas.bind(this);
    this.filterRombel = this.filterRombel.bind(this);
    this.filterPeminatan = this.filterPeminatan.bind(this);
    this.filterProgram = this.filterProgram.bind(this);
    this.filterKompetensi = this.filterKompetensi.bind(this);
    this.searchText = this.searchText.bind(this);
    // show Data
    this.setFilter = this.setFilter.bind(this);
    this.filterBidang = this.filterBidang.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
    this.showData = this.showData.bind(this);
    // get option filter
    this.getTahunAjaranNow = this.getTahunAjaranNow.bind(this);
    this.getKelas = this.getKelas.bind(this);
    this.getRombel = this.getRombel.bind(this);
    this.getPeminatanSMA = this.getPeminatanSMA.bind(this);
    this.getDataFilterSMA = this.getDataFilterSMA.bind(this);
    // show select filter
    this.showSelectFilterSD = this.showSelectFilterSD.bind(this);
    this.showSelectFilterSMA = this.showSelectFilterSMA.bind(this);
    this.showSelectFilterSMK = this.showSelectFilterSMK.bind(this);
    this.showSelectFilterALL = this.showSelectFilterALL.bind(this);
  }

  componentDidMount() {
    // get session user
    if (localStorage.getItem("token") !== null) {
      axios
        .post(`${conf.uri_backend}api/v1/auth/sso/user`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          this.setState({ data_session: res.data }, function() {
            axios
              .get(
                `${conf.uri_backend}api/v1/select-institusi/` +
                  this.state.data_session.access_to,
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                this.setState(
                  {
                    institusi_id_select: res.data.id,
                    jenjang_id_select: res.data.m_struktur_jenjang_id,
                    url_show_data: `${this.state.url}`,
                    query: "&institusi_id=" + res.data.id,
                  },
                  function() {
                    this.getTahunAjaranNow();
                    this.getKelas();
                    this.getPeminatanSMA();
                    this.getDataFilterSMA();
                    this.dt.fetchEntities(this.state.query);
                  }
                );
              });
          });
        });
    }
  }

  getTahunAjaranNow() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  getKelas() {
    var kelas = [];
    if (this.state.jenjang_id_select == "2") {
      kelas = [
        { value: "I", label: "I" },
        { value: "II", label: "II" },
        { value: "III", label: "III" },
        { value: "IV", label: "IV" },
        { value: "V", label: "V" },
        { value: "VI", label: "VI" },
      ];
    } else if (this.state.jenjang_id_select == "3") {
      kelas = [
        { value: "VII", label: "VII" },
        { value: "VIII", label: "VIII" },
        { value: "IX", label: "IX" },
      ];
    } else if (
      this.state.jenjang_id_select == "4" ||
      this.state.jenjang_id_select == "5"
    ) {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
      ];
    }
    this.setState({ data_kelas: kelas });
  }

  getTahunAjaranNow() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  getKelas() {
    var kelas = [];
    if (this.state.jenjang_id_select == "2") {
      kelas = [
        { value: "I", label: "I" },
        { value: "II", label: "II" },
        { value: "III", label: "III" },
        { value: "IV", label: "IV" },
        { value: "V", label: "V" },
        { value: "VI", label: "VI" },
      ];
    } else if (this.state.jenjang_id_select == "3") {
      kelas = [
        { value: "VII", label: "VII" },
        { value: "VIII", label: "VIII" },
        { value: "IX", label: "IX" },
      ];
    } else if (
      this.state.jenjang_id_select == "4" ||
      this.state.jenjang_id_select == "5"
    ) {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
      ];
    }
    if (
      this.state.jenjang_id_select == "3" || this.state.jenjang_id_select == "2"
        ? this.setState({ loadingFilter: false })
        : ""
    );
    if (
      this.state.jenjang_id_select == "5"
        ? this.setState({ loadingFilter: false })
        : ""
    );
    this.setState({ data_kelas: kelas });
  }

  getPeminatanSMA() {
    this.setState({ loading_peminatan_sma: !this.state.loading_peminatan_sma });
    if (this.state.jenjang_id_select == "4") {
      axios
        .get(
          this.state.url_get_spektrum +
            "?jenjang=" +
            this.state.jenjang_id_select,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (res.data.status == true) {
            this.setState({
              data_peminatan: res.data.data,
              loadingFilter: false,
              loading_peminatan: false,
            });
          } else {
            this.setState({
              data_peminatan: [],
              loadingFilter: false,
              loading_peminatan: false,
            });
          }
        });
    }
  }

  getDataFilterSMA() {
    axios
      .get(this.state.url_get_filter + "/" + this.state.institusi_id_select, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        if (res.data.status == true) {
          if (res.data.data != null) {
            this.setState(
              {
                tahun_ajaran_search:
                  res.data.data.tahun_ajaran != null
                    ? res.data.data.tahun_ajaran
                    : "",
                kelas_search:
                  res.data.data.kelas !== null ? res.data.data.kelas : "",
                bidang_search:
                  res.data.data.bidang_id !== null
                    ? res.data.data.bidang_id
                    : "",
                bidang_search_label:
                  res.data.data.bidang !== null ? res.data.data.bidang : "",
                program_search:
                  res.data.data.program_id !== null
                    ? res.data.data.program_id
                    : "",
                program_search_label:
                  res.data.data.program !== null ? res.data.data.program : "",
                kompetensi_search:
                  res.data.data.kompetensi_id !== null
                    ? res.data.data.kompetensi_id
                    : "",
                kompetensi_search_label:
                  res.data.data.kompetensi !== null
                    ? res.data.data.kompetensi
                    : "",
                peminatan_search:
                  res.data.data.kompetensi_id !== null
                    ? res.data.data.kompetensi_id
                    : "",
                peminatan_search_label:
                  res.data.data.kompetensi !== null
                    ? res.data.data.kompetensi
                    : "",
                rombel_search:
                  res.data.data.rombel_id !== null
                    ? res.data.data.rombel_id
                    : "",
                rombel_search_label:
                  res.data.data.rombel !== null ? res.data.data.rombel : "",
              },
              function() {
                this.getRombel();
                this.getBidang();
                this.getProgram();
                this.getKompetensi();
                this.getPeminatanSMA();
                this.getTahunAjaranNow();
                this.getKelas();
              }
            );
          }
        }
      });
  }

  getRombel() {
    this.setState({ loading_rombel: !this.state.loading_rombel });
    axios
      .get(
        `${this.state.url_get_rombel}?tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&peminatan=${this.state.peminatan_search}&user_institusi_id=${this.state.institusi_id_select}`,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        if (res.data !== null) {
          this.setState({
            data_rombel: res.data,
            loadingFilter: false,
            loading_rombel: false,
          });
        } else {
          this.setState({
            data_rombel: null,
            loadingFilter: false,
            loading_rombel: false,
          });
        }
      });
  }

  // SMK
  getBidang(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_bidang: !this.state.loading_bidang });
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=0&jenjang_id=5&kurikulum=${this.state.kurikulum}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (create != null) {
            this.setState({ data_bidang_p: res.data });
          } else {
            this.setState({ data_bidang: res.data });
            this.setState({ loading_bidang: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_bidang: false });
        });
    }
  }
  getProgram(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_program: !this.state.loading_program });
      var bidang = "";
      if (
        create != null
          ? (bidang = this.state.bidang)
          : (bidang = this.state.bidang_search)
      );
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=${bidang}&jenjang_id=5&kurikulum=${this.state.kurikulum}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (create != null) {
            this.setState({ data_program_p: res.data });
          } else {
            this.setState({ data_program: res.data });
            this.setState({ loading_program: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_program: false });
        });
    }
  }
  getKompetensi(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_kompetensi: !this.state.loading_kompetensi });
      var program = "";
      if (
        create != null
          ? (program = this.state.program)
          : (program = this.state.program_search)
      );
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=${program}&jenjang_id=5&kurikulum=${this.state.kurikulum}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (create != null) {
            this.setState({ data_kompetensi_p: res.data });
          } else {
            this.setState({ data_kompetensi: res.data });
            this.setState({ loading_kompetensi: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_kompetensi: false });
        });
    }
  }

  getRombel() {
    this.setState({ loading_rombel: !this.state.loading_rombel });
    var peminatan = "";
    if (
      this.state.jenjang_id_select == "5"
        ? (peminatan = this.state.kompetensi_search)
        : (peminatan = this.state.peminatan_search)
    );
    axios
      .get(
        `${this.state.url_get_rombel}?tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&peminatan=${peminatan}&user_institusi_id=${this.state.institusi_id_select}`,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        if (res.data !== null) {
          this.setState({
            data_rombel: res.data,
            loading_rombel: false,
          });
        } else {
          this.setState({
            data_rombel: null,
            loading_rombel: false,
          });
        }
      });
  }

  // SHOW TYPE FILTER PER JENJANG
  showSelectFilterSD() {
    if (this.state.data_tahun_ajaran && this.state.data_kelas) {
      return (
        <div className="flex">
          <div className="py-2">
            <Select
              id="filterTahunAjaran"
              styles={selectStyles}
              isClearable={true}
              defaultValue={
                this.state.tahun_ajaran_search != ""
                  ? {
                      value: this.state.tahun_ajaran_search,
                      label: this.state.tahun_ajaran_search,
                    }
                  : ""
              }
              options={this.state.data_tahun_ajaran}
              onChange={this.filterTahunAjaran}
              placeholder={"Pilih Tahun Ajaran"}
            />
          </div>
          <div className="py-2 ml-2 mr-2">
            <Select
              id="filterKurikulum"
              styles={selectStyles}
              isClearable={true}
              options={dataKurikulum}
              onChange={this.filterKurikulum}
              placeholder={"Pilih Kurikulum"}
            />
          </div>
          <div className="py-2 ml-2 mr-2">
            <Select
              id="filterKelas"
              styles={selectStyles}
              isClearable={true}
              defaultValue={
                this.state.kelas_search != ""
                  ? {
                      value: this.state.kelas_search,
                      label: this.state.kelas_search,
                    }
                  : ""
              }
              options={this.state.data_kelas}
              onChange={this.filterKelas}
              placeholder={"Pilih Kelas"}
            />
          </div>
        </div>
      );
    }
  }

  showSelectFilterSMA() {
    if (this.state.jenjang_id_select == "4" && this.state.data_peminatan) {
      return (
        <div className="flex">
          <div className="py-2 mr-2">
            <Select
              id="filterPeminatan"
              styles={selectStyles}
              isClearable={true}
              defaultValue={
                this.state.peminatan_search != ""
                  ? {
                      value: this.state.peminatan_search,
                      label: this.state.peminatan_search_label,
                    }
                  : ""
              }
              isLoading={this.state.loading_peminatan_sma ? true : false}
              options={this.state.data_peminatan}
              onChange={this.filterPeminatan}
              placeholder={"Pilih Peminatan"}
            />
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  showSelectFilterSMK() {
    if (this.state.jenjang_id_select == "5") {
      return (
        <>
          {this.state.kelas_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterBidang"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.bidang_search != ""
                      ? {
                          value: this.state.bidang_search,
                          label: this.state.bidang_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_bidang ? true : false}
                  options={this.state.data_bidang}
                  onChange={this.filterBidang}
                  className="animateFadeInLeft"
                  placeholder={
                    this.state.loading_bidang ? "Loading" : "Bidang Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {this.state.bidang_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterProgram"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.program_search != ""
                      ? {
                          value: this.state.program_search,
                          label: this.state.program_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_program ? true : false}
                  options={this.state.data_program}
                  onChange={this.filterProgram}
                  placeholder={
                    this.state.loading_program ? "Loading" : "Program Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}
          {this.state.program_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterKompetensi"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.kompetensi_search != ""
                      ? {
                          value: this.state.kompetensi_search,
                          label: this.state.kompetensi_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_kompetensi ? true : false}
                  options={this.state.data_kompetensi}
                  onChange={this.filterKompetensi}
                  placeholder={
                    this.state.loading_kompetensi
                      ? "Loading"
                      : "Kompetensi Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  showSelectFilterALL() {
    if (this.state.data_rombel || this.state.rombel_search != "") {
      return (
        <div className="flex">
          <div className="py-2">
            <Select
              id="filterRombel"
              styles={selectStyles}
              isClearable={true}
              isLoading={this.state.loading_rombel ? true : false}
              defaultValue={
                this.state.rombel_search != ""
                  ? {
                      value: this.state.rombel_search,
                      label: this.state.rombel_search_label,
                    }
                  : ""
              }
              options={this.state.data_rombel}
              onChange={this.filterRombel}
              placeholder={"Pilih Rombel"}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex w-1/4">
          <div className="w-full px-1">
            <div className="pt-1">
              <Skeleton count={2} />
            </div>
          </div>
        </div>
      );
    }
  }

  // FILTER HANDLE CHANGE FUNCTION
  filterKurikulum(e) {
    if (e) {
      this.setState(
        {
          kurikulum: e.value,
        },
        function(e) {
          this.getBidang();
        }
      );
    } else {
      this.setState({
        kurikulum: null,
      });
    }
  }
  filterTahunAjaran(e) {
    if (e) {
      this.setState(
        {
          tahun_ajaran_search: e.value,
          data_kelas: "",
          data_peminatan: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          kelas_search: "",
          peminatan_search: "",
          rombel_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          this.getKelas();
        }
      );
    } else {
      this.setState({
        tahun_ajaran_search: "",
        url_show_data: "",
        data_kelas: "",
        data_peminatan: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        kelas_search: "",
        peminatan_search: "",
        rombel_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
      });
    }
  }

  filterKelas(e) {
    if (e) {
      this.setState(
        {
          kelas_search: e.value,
          data_peminatan: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          peminatan_search: "",
          rombel_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          if (this.state.jenjang_id_select == "5" ? this.getBidang() : "");
          if (
            this.state.jenjang_id_select == "4" ? this.getPeminatanSMA() : ""
          );
          if (
            this.state.jenjang_id_select == "2" ||
            this.state.jenjang_id_select == "3"
              ? this.getRombel()
              : ""
          );
        }
      );
    } else {
      this.setState(
        {
          kelas_search: "",
          url_show_data: "",
          data_peminatan: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          peminatan_search: "",
          rombel_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          if (
            this.state.jenjang_id_select == "3" ||
            this.state.jenjang_id_select == "2"
              ? this.getRombel()
              : ""
          );
          if (this.state.jenjang_id_select == "5" ? this.getBidang() : "");
        }
      );
    }
  }

  filterPeminatan(e) {
    // only for SMA
    if (e) {
      this.setState(
        {
          peminatan_search: e.value,
          peminatan_search_label: e.label,
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          rombel_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          this.getRombel();
        }
      );
    } else {
      this.setState({
        peminatan_search: "",
        peminatan_search_label: "",
        url_show_data: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        bidang_search: "",
        rombel_search: "",
        program_search: "",
        kompetensi_search: "",
      });
    }
  }

  // only for SMK
  filterBidang(e) {
    if (e) {
      this.setState(
        {
          bidang_search: e.value,
          bidang_search_label: e.label,
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          rombel_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          this.getProgram();
        }
      );
    } else {
      this.setState({
        bidang_search: "",
        bidang_search_label: "",
        bidang_search: "",
        bidang_search_label: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        rombel_search: "",
        program_search: "",
        kompetensi_search: "",
      });
    }
  }
  filterProgram(e) {
    if (e) {
      this.setState(
        {
          program_search: e.value,
          program_search_label: e.label,
          data_kompetensi: "",
          data_rombel: "",
          rombel_search: "",
          kompetensi_search: "",
        },
        function() {
          this.getKompetensi();
        }
      );
    } else {
      this.setState({
        program_search: "",
        program_search_label: "",
        data_kompetensi: "",
        data_rombel: "",
        rombel_search: "",
        kompetensi_search: "",
      });
    }
  }
  filterKompetensi(e) {
    if (e) {
      this.setState(
        { kompetensi_search: e.value, kompetensi_search_label: e.label },
        function() {
          this.getRombel();
        }
      );
    } else {
      this.setState(
        { kompetensi_search: "", kompetensi_search_label: "" },
        function() {
          this.getRombel();
        }
      );
    }
  }

  filterRombel(e) {
    if (e) {
      this.setState({ rombel_search: e.value, rombel_search_label: e.label });
    } else {
      this.setState({
        rombel_search: "",
        rombel_search_label: "",
        url_show_data: "",
      });
    }
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({ loading_button: true });
    axios
      .put(
        this.state.url_get_filter + "/" + this.state.institusi_id_select,
        {
          tahun_ajaran: this.state.tahun_ajaran_search,
          kelas: this.state.kelas_search,
          kompetensi: this.state.peminatan_search_label,
          kompetensi_id: this.state.peminatan_search,
          rombel_id: this.state.rombel_search,
          rombel: this.state.rombel_search_label,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        console.log("done");
        this.setState({ loading_button: false });
      });

    this.setState(
      {
        url_show_data: `${this.state.url}`,
        query:
          "&institusi_id=" +
          this.state.institusi_id_select +
          "&query=" +
          this.state.searchText +
          "&m_spektrum_id=" +
          this.state.peminatan_search +
          "&rombel_id=" +
          this.state.rombel_search +
          "&tahun_ajaran=" +
          this.state.tahun_ajaran_search +
          "&kurikulum=" +
          this.state.kurikulum +
          "&kelas=" +
          this.state.kelas_search,
        search: true,
      },
      function() {
        this.dt.loading();
        this.dt.resetPage(this.state.query);
      }
    );
  }

  setResetFilter(e) {
    e.preventDefault();
    this.setState(
      {
        data_tahun_ajaran: null,
        data_kelas: null,
        data_peminatan: null,
        data_rombel: null,
        searchText: "",
        rombel_search: "",
        rombel_search_label: "",
        tahun_ajaran_search: "",
        peminatan_search: "",
        peminatan_search_label: "",
        kelas_search: "",
      },
      function() {
        this.setState(
          {
            url_show_data: this.state.url,
            query: "&institusi_id=" + this.state.institusi_id_select,
          },
          function() {
            this.getTahunAjaranNow();
            this.getKelas();
            this.getPeminatanSMA();
          }
        );
      }
    );
  }

  // SEARCH TEXT
  searchText(e) {
    e.preventDefault();
    if (this.state.url_show_data !== "") {
      if (this.state.showFilter) {
        this.setState(
          {
            searchText: e.target.value,
            query:
              "&institusi_id=" +
              this.state.institusi_id_select +
              "&query=" +
              e.target.value +
              "&rombel_id=" +
              this.state.rombel_search +
              "&m_spektrum_id=" +
              this.state.peminatan_search +
              "&tahun_ajaran=" +
              this.state.tahun_ajaran_search +
              "&kelas=" +
              this.state.kelas_search,
          },
          function() {
            this.dt.resetPage(this.state.query);
          }
        );
      } else {
        this.setState(
          {
            searchText: e.target.value,
            query:
              "&institusi_id=" +
              this.state.institusi_id_select +
              "&query=" +
              e.target.value,
          },
          function() {
            this.dt.resetPage(this.state.query);
          }
        );
      }
    }
  }

  showData() {
    return (
      <div className="px-1 py-1">
        <DataTable
          ref={(dt) => {
            this.dt = dt;
          }}
          action={{
            aksi: (col, data) => {
              var button = (
                <button className="flex btn-blue text-sm text-white px-4 py-2 rounded-full items-center">
                  Detail
                </button>
              );
              return button;
            },

            // jenis_kelamin:(col, data) => {
            //     var jenis_kelamin = ['Perempuan', 'Laki - laki'];
            //     return(<div className="text-sm">{jenis_kelamin[col.jenis_kelamin]}</div>)
            // },

            // tanggal_lahir: (col, data) => {
            //   if(col.tanggal_lahir != null){
            //     var date  = '';
            //     var month = '';
            //     var year  = '';

            //     date      = col.tanggal_lahir.getDay();
            //     month     = col.tanggal_lahir.getMonth();
            //     year      = col.tanggal_lahir.getFullYear();

            //     return (<p className="text-sm">{date+"-"+month+"-"+year}</p>);
            //   } else {
            //     return (<p className="text-sm">Tidak Diketahui</p>);
            //   }
            // },

            username: (col, data) => {
              if (col.username !== null) {
                return <p className="text-sm">{col.username}</p>;
              } else {
                return <p className="text-sm">-</p>;
              }
            },
          }}
          url={this.state.url_show_data}
          columns={[
            {
              index: "tahun_ajaran",
              label: "TAHUN AJARAN",
            },
            {
              index: "kurikulum",
              label: "KURIKULUM",
            },
            {
              index: "nama_siswa",
              label: "NAMA SISWA",
            },
            {
              index: "kelas",
              label: "KELAS",
            },
            {
              index: "nama_spektrum",
              label: "PEMINATAN",
            },
            {
              index: "nama_rombel",
              label: "ROMBEL",
            },
            {
              index: "username",
              label: "USERNAME",
            },
            {
              index: "jenis_orangtua",
              label: "Jenis Orangtua",
            },
            {
              index: "nama_orangtua",
              label: "NAMA ORANG TUA / WALI",
            },
            {
              index: "no_handphone",
              label: "NO HP",
            },
            // {
            //   index: "tanggal_lahir",
            //   label: "TANGGAL LAHIR",
            // },
          ]}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="shadow-md mx-auto py-3 mt-6 rounded-lg bg-white sm-container border">
          <div className="bg-white flex justify-between items-center mt-2  px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Orang Tua Per Rombongan Belajar
              <span className="text-gray-500 font-thin block text-sm">
                Daftar Orang Tua per Rombongan Belajar.{" "}
                {/* <a href="/sadasd" className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
          </div>
        </div>

        <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white justify-between sm-container border">
          <div className="flex flex-row items-baseline px-3">
            <button
              className="btn-blue px-4 mr-2 flex items-center rounded-full text-white py-2 text-xs"
              onClick={() => {
                this.setState({
                  showFilter: !this.state.showFilter,
                });
              }}
            >
              <i className="bx bx-filter text-lg mr-1"></i>
              Pilih
            </button>
            <div className="flex w-full flex-wrap items-center my-1 px-3 pb-2 pt-2">
              <input
                type="search"
                className="w-1/4 pl-2 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Cari nama siswa, username"
                id="search"
                onChange={this.searchText}
              />
            </div>
          </div>
          {this.state.showFilter ? (
            <div className="flex flex-wrap items-center px-3 pb-2 pt-2">
              {this.state.loadingFilter ? (
                <>
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2} />
                    </div>
                  </div>
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2} />
                    </div>
                  </div>
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2} />
                    </div>
                  </div>
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div id="filterAll" className="flex flex-wrap w-full">
                    {this.showSelectFilterSD()}
                    {this.showSelectFilterSMA()}
                    {this.showSelectFilterSMK()}
                    {this.showSelectFilterALL()}
                    {this.state.rombel_search != "" ? (
                      <div className="flex py-2">
                        <button
                          className={
                            this.state.loading_button == true
                              ? "btn-disabled px-4 py-2 ml-1 flex items-center rounded-full text-sm"
                              : "btn-schoolmedia px-4 py-2 ml-1 flex items-center rounded-full text-white text-sm"
                          }
                          onClick={this.setFilter}
                          disabled={
                            this.state.loading_button == true ? true : false
                          }
                        >
                          {this.state.loading_button == true
                            ? "Tunggu..."
                            : "Cari"}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <button
                      className="flex btn-default text-sm text-gray-800 px-4 py-2 rounded-full items-center"
                      onClick={this.setResetFilter}
                    >
                      Reset
                  </button>  */}
                </>
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
          {this.showData()}
        </div>
      </div>
    );
  }
}
