import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SideBarJadwal from "./component/SideBarJadwal.js";
import { Tab, Tabs, Modal } from "react-bootstrap";
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import conf from "../../../config.js";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "sweetalert2-react";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

// select style
const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "100px",
    minWidth: "15rem",
  }),
};

const dataKurikulum = [
  { value: "k13", label: "K13" },
  { value: "merdeka", label: "Merdeka" },
];

const semester = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
];

function captFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default class AturWaktuPelajaran extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${conf.uri_backend}api/v1/jadwal/waktu_pelajaran`,
      url_create: `${conf.uri_backend}api/v1/jadwal/create_waktu_pelajaran`,
      url_delete: `${conf.uri_backend}api/v1/jadwal/delete_waktu_pelajaran`,
      url_kuota: `${conf.uri_backend}api/v1/jadwal/get_kuota_per_waktu_pelajaran`,
      url_check_jadwal: `${conf.uri_backend}api/v1/jadwal/check_jadwal_pelajaran`,
      url_get_rombel: `${conf.uri_backend}api/v1/rombel/get_rombel`,
      url_get_mapel: `${conf.uri_backend}api/v1/jadwal/atur_mapel_per_rombel`,
      url_get_mapel_rombel: `${conf.uri_backend}api/v1/jadwal/mapel_per_rombel`,
      url_create_mapel: `${conf.uri_backend}api/v1/jadwal/create_mapel_per_rombel`,
      url_get_spektrum: `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_filter: `${conf.uri_backend}api/v1/rombel/filter`,
      url_get_smk: `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,
      url_waktu: `${conf.uri_backend}api/v1/jadwal/waktu`,
      url_check_rombel: `${conf.uri_backend}api/v1/jadwal/check-checked`,

      // bind data form
      tahun_ajaran: "",
      tahunSekarang: new Date().getFullYear(),

      // get list data from DB
      data_tahun_ajaran: [],

      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",

      formCopy: null,

      // loading
      loadingSenin: true,
      loadingSelasa: true,
      loadingRabu: true,
      loadingKamis: true,
      loadingJumat: true,
      loadingSabtu: true,
      loadingMinggu: true,
      loadingFilter: true,

      // search
      tahun_ajaran_search: "",
      kelas_search: "",
      kurikulum_search: "",
      peminatan_search: "",
      rombel_search: "",
      rombel_id: "",
      bidang_search: "",
      program_search: "",
      kompetensi_search: "",
      
      modalPreview: false,
      inputHari: "",
      inputJamAwal: "",
      inputJamAkhir: "",
      inputKet: "",
      formCopy: null,
      formKe: "",
    };
    // Bind Data

    this.trPlush = this.trPlush.bind(this);
    this.trMinus = this.trMinus.bind(this);
    this.delete = this.delete.bind(this);

    this.saveWaktu = this.saveWaktu.bind(this);
    this.getDataWaktu = this.getDataWaktu.bind(this);
    this.showData = this.showData.bind(this);
    this.getKuota = this.getKuota.bind(this);
    this.checkJadwal = this.checkJadwal.bind(this);
    this.hidePreview = this.hidePreview.bind(this);
    this.copy = this.copy.bind(this);
    this.paste = this.paste.bind(this);

    // get option filter
    this.getTahunAjaranNow = this.getTahunAjaranNow.bind(this);
    this.getKelas = this.getKelas.bind(this);
    this.getKurikulum = this.getKurikulum.bind(this);

    // binding data
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterKurikulum = this.filterKurikulum.bind(this);
    this.filterKelas = this.filterKelas.bind(this);
    this.filterSemester = this.filterSemester.bind(this);

    // show select filter
    this.showSelectFilterSD = this.showSelectFilterSD.bind(this);
    this.showSelectFilterKurikulum = this.showSelectFilterKurikulum.bind(this);
    this.showSelectFilterSemester = this.showSelectFilterSemester.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
  }

  componentDidMount() {
    // get session user
    if (localStorage.getItem("token") !== null) {
      axios
        .post(`${conf.uri_backend}api/v1/auth/sso/user`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          this.setState({ data_session: res.data }, function() {
            axios
              .get(
                `${conf.uri_backend}api/v1/select-institusi/` +
                  this.state.data_session.access_to,
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                this.setState(
                  {
                    institusi_id_select: res.data.id,
                    jenjang_id_select: res.data.m_struktur_jenjang_id,
                    npsn_id_select: res.data.npsn,
                  },
                  function() {
                    this.getTahunAjaranNow();
                    this.getDataFilterSMA();
                  }
                );
              });
          });
        });
    }
  }

  alertMessage(e) {
    let message = null;

    switch (e.status) {
      case 422:
        message = e.data.messages[0];
        break;
      case 500:
        message =
          "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
        break;
      case 401:
        message =
          "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 400:
        message =
          "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 408:
        message =
          "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 429:
        message =
          "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
    }

    this.setState({
      show_alert: true,
      title_alert: "Gagal",
      icon_alert: "error",
      message_alert: message,
      simpleLoading: false,
    });
  }

  
  getTahunAjaranNow() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ 
      data_tahun_ajaran: ta,
    });
  }

  getKelas() {
    var kelas = [];
    if (this.state.jenjang_id_select == "2") {
      kelas = [
        { value: "I", label: "I" },
        { value: "II", label: "II" },
        { value: "III", label: "III" },
        { value: "IV", label: "IV" },
        { value: "V", label: "V" },
        { value: "VI", label: "VI" },
      ];
    } else if (this.state.jenjang_id_select == "3") {
      kelas = [
        { value: "VII", label: "VII" },
        { value: "VIII", label: "VIII" },
        { value: "IX", label: "IX" },
      ];
    } else if (this.state.jenjang_id_select == "4") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
      ];
    } else if (this.state.jenjang_id_select == "5") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
        { value: "XIII", label: "XIII" },
      ];
    }
    this.setState({ data_kelas: kelas });
  }

  getKurikulum() {
    this.setState({ data_kurikulum: dataKurikulum });
  }

  getDataFilterSMA() {
    axios
      .get(this.state.url_get_filter + "/" + this.state.institusi_id_select, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        if (res.data.status == true) {
          this.setState(
            {
              tahun_ajaran_search: res.data.data.tahun_ajaran != null ? res.data.data.tahun_ajaran : "",
              kelas_search: res.data.data.kelas !== null ? res.data.data.kelas : "",
              semester_search: res.data.data.semester !== null ? res.data.data.semester : "",
              kurikulum_search: res.data.data.kurikulum !== null ? res.data.data.kurikulum : "",
            },
            function() {
              this.getTahunAjaranNow();
              this.getKelas();
              this.getKurikulum();
              this.checkJadwal();
              this.getKuota();
              this.setState({ loadingFilter : false });
            }
            
          );
        } else {
          this.setState({ loadingFilter : false });
        }
      });
  }

  setFilter(e) {
    this.setState({
      loadingSenin : true,
      loadingSelasa : true,
      loadingRabu : true,
      loadingKamis : true,
      loadingJumat : true,
      loadingSabtu : true,
      loadingMinggu : true,
    }, function () {
      this.checkJadwal();
      this.getKuota();
    })
  }

  setResetFilter(e) {
    e.preventDefault();
    this.setState(
      {
        loading_filter : true,
        data_tahun_ajaran: null,
        data_kelas: null,
        tahun_ajaran_search: "",
        kurikulum_search: "",
        kelas_search: "",
        semester_search: "",
        disabledFilter: false,
      },
      function() {
        this.getTahunAjaranNow();
        this.setState({ loadingFilter : false })
      }
    );
  }

  // SHOW TYPE FILTER PER JENJANG
  showSelectFilterSD() {
    if (this.state.data_tahun_ajaran && this.state.loadingFilter == false) {
      return (
        <>
          <div className="flex w-1/4">
            <div className="py-2 mr-2">
              <Select
                id="filterTahunAjaran"
                styles={selectStyles}
                isDisabled={this.state.disabledFilter}
                defaultValue={
                  this.state.tahun_ajaran_search != "" ? { value: this.state.tahun_ajaran_search, label: this.state.tahun_ajaran_search } : ""
                }
                options={this.state.data_tahun_ajaran}
                onChange={this.filterTahunAjaran}
                placeholder={"Pilih Tahun Ajaran"}
              />
            </div>
          </div>
          {this.state.data_kelas && this.state.tahun_ajaran_search !== "" ? (
            <div className="flex w-1/4">
              <div className="py-2 mr-2">
                <Select
                  id="filterKelas"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.kelas_search != "" ? { value: this.state.kelas_search, label: this.state.kelas_search, } : ""
                  }
                  options={this.state.data_kelas}
                  onChange={this.filterKelas}
                  placeholder={"Pilih Kelas"}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  showSelectFilterKurikulum() {
    if (this.state.loadingFilter == false && this.state.data_kurikulum != "" && this.state.kelas_search != "") {
      return (
        <>
          <div className="flex w-1/4">
            <div className="py-2 mr-2">
              <Select
                styles={selectStyles}
                className="react-select__control"
                placeholder="Pilih Kurikulum"
                // isMulti
                defaultValue={
                  this.state.kurikulum_search != ""
                    ? {
                        value: this.state.kurikulum_search,
                        label: captFirstLetter(this.state.kurikulum_search),
                      }
                    : ""
                }
                options={this.state.data_kurikulum}
                onChange={this.filterKurikulum}
              />
            </div>
          </div>
        </>
      );
    } else {
      
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  showSelectFilterSemester() {
    if (this.state.loadingFilter == false && this.state.kurikulum_search != "") {
      return (
        <div className="flex w-1/4">
          <div className="py-2 ml-2 mr-2">
            <Select
              id="filterSemester"
              styles={selectStyles}
              isDisabled={this.state.disabledFilter}
              defaultValue={
                this.state.semester_search != ""
                  ? {
                      value: this.state.semester_search,
                      label: this.state.semester_search,
                    }
                  : ""
              }
              options={semester}
              onChange={this.filterSemester}
              placeholder={"Pilih Semester"}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  // FILTER HANDLE CHANGE FUNCTION
  filterTahunAjaran(e) {
    if (e) {
      this.setState(
        {
          tahun_ajaran_search: e.value,
          kelas_search: "",
          kurikulum_search: "",
          semester_search: "",
          data_kelas: "",
          data_kurikulum: "",
          loading_show_data: true,
        },
        function() {
          this.getKelas();
        }
      );
    } else {
      this.setState({
        tahun_ajaran_search: "",
        kelas_search: "",
        kurikulum_search: "",
        semester_search: "",
        data_spektrum: "",
        data_kelas: "",
        data_kurikulum: "",
        loading_show_data: true,
      }, function() {
        this.getKelas();
      });
    }
  }
  filterKurikulum(e) {
    if (e) {
      this.setState(
        {
          kurikulum_search: e.value,
          semester_search: "",
        },
        function() {

        }
      );
    } else {
      this.setState({
        kurikulum_search: "",
        semester_search: "",
      });
    }
  }

  filterKelas(e) {
    if (e) {
      this.setState(
        {
          kelas_search: e.value,
          kurikulum_search: "",
          semester_search: "",
          data_kurikulum: "",
          loading_show_data: true,
        },
        function() {
          this.getKurikulum();
        }
      );
    } else {
      this.setState({
        kelas_search: "",
        kurikulum_search: "",
        semester_search: "",
        data_kurikulum: "",
        loading_show_data: true,
      });
    }
  }

  filterSemester(e) {
    if (e) {
      this.setState({ semester_search: e.value, loading_show_data: false });
    } else {
      this.setState({ semester_search: "" });
    }
  }

  getKuota() {
    var total = 0;
    if (this.state.kelas_search != "" && this.state.tahun_ajaran_search != "" && this.state.kurikulum_search != "" && this.state.semester_search != '') {
      axios
        .get(
          `${this.state.url_kuota}?institusi=${this.state.institusi_id_select}&status=nobreak&tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&kurikulum=${this.state.kurikulum_search}&semester=${this.state.semester_search}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          total += res.data.senin.length;
          total += res.data.selasa.length;
          total += res.data.rabu.length;
          total += res.data.kamis.length;
          total += res.data.jumat.length;
          total += res.data.sabtu.length;
          total += res.data.minggu.length;
          

          this.setState({ totalKuota: total }, function() {
            this.getDataWaktu(1);
            this.getDataWaktu(2);
            this.getDataWaktu(3);
            this.getDataWaktu(4);
            this.getDataWaktu(5);
            this.getDataWaktu(6);
            this.getDataWaktu(7);
          });
        });
    }
  }

  checkJadwal() {
    var jadwal = "";
    if (this.state.kelas_search != "" && this.state.tahun_ajaran_search != "" && this.state.kurikulum_search != "" && this.state.semester_search != '') {
      axios
        .get(
          this.state.url_check_jadwal + "?institusi=" +  this.state.institusi_id_select+`&tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&kurikulum=${this.state.kurikulum_search}&semester=${this.state.semester_search}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          jadwal = res.data;
  
          if (
            jadwal.senin != 0
              ? this.setState({ jadwalSenin: true })
              : this.setState({ jadwalSenin: false })
          );
          if (
            jadwal.selasa != 0
              ? this.setState({ jadwalSelasa: true })
              : this.setState({ jadwalSelasa: false })
          );
          if (
            jadwal.rabu != 0
              ? this.setState({ jadwalRabu: true })
              : this.setState({ jadwalRabu: false })
          );
          if (
            jadwal.kamis != 0
              ? this.setState({ jadwalKamis: true })
              : this.setState({ jadwalKamis: false })
          );
          if (
            jadwal.jumat != 0
              ? this.setState({ jadwalJumat: true })
              : this.setState({ jadwalJumat: false })
          );
          if (
            jadwal.sabtu != 0
              ? this.setState({ jadwalSabtu: true })
              : this.setState({ jadwalSabtu: false })
          );
          if (
            jadwal.minggu != 0
              ? this.setState({ jadwalMinggu: true })
              : this.setState({ jadwalMinggu: false })
          );
        });
    }
  }

  trMinus(data) {
    var table = document.getElementById(data);
    var trCount = table.rows.length;
    var u = trCount - 1;
    if (trCount == 1) {
      this.setState({
        show_alert: true,
        title_alert: "Peringatan",
        message_alert: "Tidak bisa mengurangi waktu karena min: 1",
        formKe: u,
      });
    } else {
      table.deleteRow(u);
    }
  }

  trPlush(e, table, numberDay) {
    // e.preventDefault();

    var table = document.getElementById(table);
    var trCount = table.rows.length;
    var formKe = this.state.formKe;
    var u = trCount;
    var k = 0 + u;
    var l = 0 + k;
    var q = 20 + u;
    var c = 30 + u;
    var row = table.insertRow(trCount);
    var cell1 = row.insertCell(0);
    var cell2 = row.insertCell(1);
    var cell3 = row.insertCell(2);
    var cell4 = row.insertCell(3);
    cell1.innerHTML = '<p class="text-sm">-</p>';
    cell2.innerHTML = `<input type="time" id="copy-${numberDay}-${u}" name="${numberDay}-jamAwal[]" data-idc="${u}" data-id="${u}" data-hari="${numberDay}">`;
    cell3.innerHTML = `<input type="time" id="copyc-${numberDay}-${u}" name="${numberDay}-jamAkhir[]" data-idc="${u}" data-id="${u}" data-hari="${numberDay}">`;
    cell4.innerHTML =
      '<input type="text" id="copyccc-' +
      numberDay +
      "-" +
      u +
      '"  list="cars"   name="' +
      numberDay +
      '-keterangan[]" placeholder="Pilih Keterangan" data-idc="' +
      numberDay +
      '" data-id="' +
      u +
      '" data-hari="' +
      numberDay +
      '"/><datalist id="cars"><option value="Upacara">Upacara</option><option value="Istirahat">Istirahat</option><option value="Pelajaran">Pelajaran</option></datalist>';
  
    return false;  
  }

  getDataWaktu(hari) {
    axios
      .get(
        this.state.url + "?institusi=" + this.state.institusi_id_select + "&hari=" + hari + `&tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&kurikulum=${this.state.kurikulum_search}&semester=${this.state.semester_search}`,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        if (res.data != null) {
          console.log(res.data, hari)
          if (hari == 1) {
            this.setState(
              {
                data_senin: res.data.data,
                count_senin: res.data.count,
                kuota_senin: res.data.highValue,
                loadingSenin: false,
              },
              function() {
                var table = document.getElementById("1-tablenya");
                var tab = document.getElementById("tabSenin");
                if (table != null) {
                  table.remove();
                }
                if (res.data.count == 0) {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.renderWaktu(1), tab);
                } else {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.showData(1), tab);
                }
              }
            );
          } else if (hari == 2) {
            this.setState(
              {
                data_selasa: res.data.data,
                count_selasa: res.data.count,
                kuota_selasa: res.data.highValue,
                loadingSelasa: false,
              },
              function() {
                var table = document.getElementById("2-tablenya");
                var tab = document.getElementById("tabSelasa");
                if (table != null) {
                  table.remove();
                }
                if (res.data.count == 0) {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.renderWaktu(2), tab);
                } else {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.showData(2), tab);
                }
              }
            );
          } else if (hari == 3) {
            this.setState(
              {
                data_rabu: res.data.data,
                count_rabu: res.data.count,
                kuota_rabu: res.data.highValue,
                loadingRabu: false,
              },
              function() {
                var table = document.getElementById("3-tablenya");
                var tab = document.getElementById("tabRabu");
                if (table != null) {
                  table.remove();
                }
                if (res.data.count == 0) {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.renderWaktu(3), tab);
                } else {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.showData(3), tab);
                }
              }
            );
          } else if (hari == 4) {
            this.setState(
              {
                data_kamis: res.data.data,
                count_kamis: res.data.count,
                kuota_kamis: res.data.highValue,
                loadingKamis: false,
              },
              function() {
                var table = document.getElementById("4-tablenya");
                var tab = document.getElementById("tabKamis");
                if (table != null) {
                  table.remove();
                }
                if (res.data.count == 0) {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.renderWaktu(4), tab);
                } else {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.showData(4), tab);
                }
              }
            );
          } else if (hari == 5) {
            this.setState(
              {
                data_jumat: res.data.data,
                count_jumat: res.data.count,
                kuota_jumat: res.data.highValue,
                loadingJumat: false,
              },
              function() {
                var table = document.getElementById("5-tablenya");
                var tab = document.getElementById("tabJumat");
                if (table != null) {
                  table.remove();
                }
                if (res.data.count == 0) {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.renderWaktu(5), tab);
                } else {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.showData(5), tab);
                }
              }
            );
          } else if (hari == 6) {
            this.setState(
              {
                data_sabtu: res.data.data,
                count_sabtu: res.data.count,
                kuota_sabtu: res.data.highValue,
                loadingSabtu: false,
              },
              function() {
                var table = document.getElementById("6-tablenya");
                var tab = document.getElementById("tabSabtu");
                if (table != null) {
                  table.remove();
                }
                if (res.data.count == 0) {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.renderWaktu(6), tab);
                } else {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.showData(6), tab);
                }
              }
            );
          } else if (hari == 7) {
            this.setState(
              {
                data_minggu: res.data.data,
                count_minggu: res.data.count,
                kuota_minggu: res.data.highValue,
                loadingMinggu: false,
              },
              function() {
                var table = document.getElementById("7-tablenya");
                var tab = document.getElementById("tabMinggu");
                if (table != null) {
                  table.remove();
                }
                if (res.data.count == 0) {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.renderWaktu(7), tab);
                } else {
                  ReactDOM.unmountComponentAtNode(tab);
                  ReactDOM.render(this.showData(7), tab);
                }
              }
            );
          }

          // console.log(this.state.kuota_senin, "Senin")
          // console.log(this.state.kuota_selasa, "Seloso")
          // console.log(this.state.kuota_rabu, "Rabu")
          // console.log(this.state.kuota_kamis, "Kamis")
          // console.log(this.state.kuota_jumat, "Jumat")
          // console.log(this.state.kuota_sabtu, "Sabtu")
          // console.log(this.state.kuota_minggu, "Minggu")
        }
      });
  }

  showData(hari) {
    var dataWaktu = "";
    var jumlahData = "";
    var kuota_hari = "";
    var items = [];
    var u = 0;
    var uket = 30;
    var ket = 30;
    var k = 0;
    var q = 20;

    if (hari == 1 && this.state.data_senin) {
      dataWaktu = this.state.data_senin;
      jumlahData = this.state.count_senin;
      kuota_hari = this.state.kuota_senin;
    } else if (hari == 2 && this.state.data_selasa) {
      dataWaktu = this.state.data_selasa;
      jumlahData = this.state.count_selasa;
      kuota_hari = this.state.kuota_selasa;
    } else if (hari == 3 && this.state.data_rabu) {
      dataWaktu = this.state.data_rabu;
      jumlahData = this.state.count_rabu;
      kuota_hari = this.state.kuota_rabu;
    } else if (hari == 4 && this.state.data_kamis) {
      dataWaktu = this.state.data_kamis;
      jumlahData = this.state.count_kamis;
      kuota_hari = this.state.kuota_kamis;
    } else if (hari == 5 && this.state.data_jumat) {
      dataWaktu = this.state.data_jumat;
      jumlahData = this.state.count_jumat;
      kuota_hari = this.state.kuota_jumat;
    } else if (hari == 6 && this.state.data_sabtu) {
      dataWaktu = this.state.data_sabtu;
      jumlahData = this.state.count_sabtu;
      kuota_hari = this.state.kuota_sabtu;
    } else if (hari == 7 && this.state.data_minggu) {
      dataWaktu = this.state.data_minggu;
      jumlahData = this.state.count_minggu;
      kuota_hari = this.state.kuota_minggu;
    }

    if (dataWaktu && jumlahData > 0) {
      for (var i = 0; i < jumlahData; i++) {
        u = u + 1;
        uket = uket + 1;
        ket = ket + 1;
        k = k + 1;
        q = q + 1;
        items.push(
          <tr key={i} className="py-2">
            <td>
              {/* <input type="text" className="focus:outline-none w-8 px-2" disabled name={hari+"-jamKe[]"} data-id={u} data-hari={hari} value={u}/> */}
              <p className="text-sm">
                {dataWaktu[i].jam_ke != 0 ? dataWaktu[i].jam_ke : "-"}
              </p>
            </td>
            <td>
              <input
                type="time"
                id={"copy-" + hari + "-" + i}
                name={hari + "-jamAwal[]"}
                data-idc={i}
                data-id={u}
                data-hari={hari}
                defaultValue={dataWaktu[i].start}
              />
            </td>
            <td>
              <input
                type="time"
                id={"copyc-" + hari + "-" + i}
                name={hari + "-jamAkhir[]"}
                data-idc={i}
                onChange={this.changeFinish}
                data-id={q}
                data-hari={hari}
                defaultValue={dataWaktu[i].finish}
              />
            </td>
            <td>
              <input
                type="text"
                id={"copyccc-" + hari + "-" + i}
                list="cars"
                name={hari + "-keterangan[]"}
                placeholder="Pilih Keterangan "
                defaultValue={dataWaktu[i].keterangan}
                data-idc={i}
                data-id={uket}
                data-hari={hari}
              />
              <datalist id="cars">
                <option
                  selected={dataWaktu[i].keterangan == "Upacara"}
                  value="Upacara"
                >
                  Upacara
                </option>
                <option
                  selected={dataWaktu[i].keterangan == "Istirahat"}
                  value="Istirahat"
                >
                  Istirahat
                </option>
                <option
                  selected={dataWaktu[i].keterangan == "Pelajaran"}
                  value="Pelajaran"
                >
                  Pelajaran
                </option>
              </datalist>
              {/* <select name={hari+"-keterangan[]"} className="form-control" data-id={u} data-hari={hari}>
                          <option disabled value="">Pilih Keterangan</option>
                          <option selected={dataWaktu[i].keterangan == "Upacara"} value="Upacara">Upacara</option>
                          <option selected={dataWaktu[i].keterangan == "Istirahat"} value="Istirahat">Istirahat</option>
                          <option selected={dataWaktu[i].keterangan == "Pelajaran"} value="Pelajaran">Pelajaran</option>
                        </select> */}
            </td>
          </tr>
        );
      }

      return (
        <form id={hari + "-formnya"}>
          <input
            type="hidden"
            className="focus:outline-none w-8 px-2"
            id="harinya"
            disabled
            name="hari"
            value={hari}
          />
          <table className="table-bordered w-full text-center text-sm">
            <thead>
              <tr>
                <th>Jam ke</th>
                <th>Waktu Mulai</th>
                <th>Waktu Selesai</th>
                <th>Keterangan</th>
              </tr>
            </thead>
            <tbody id={hari + "-tablenya"}>{items}</tbody>
          </table>
          <div className="w-full flex items-center">
            <div className="w-1/2 text-right flex">
              <input
                type="button"
                onClick={this.saveWaktu.bind(this, hari)}
                className="btn-schoolmedia px-4 mt-2 py-2 flex items-center rounded-full text-white text-sm"
                value="Simpan"
              />
              <input
                type="button"
                onClick={this.delete.bind(this, hari)}
                className="bg-red-600 px-4 mt-2 ml-2 py-2 flex items-center rounded-full text-white text-sm"
                value="Hapus"
              />
            </div>
            <div className="w-1/2 text-right mr-2">
              <p className="text-sm">
                Jumlah Total Kuota per Minggu : {" "}
                <span className="font-bold">
                  {this.state.totalKuota}
                  {/* {this.state.kuota_senin} */}
                </span>
              </p>
            </div>
          </div>
        </form>
      ); 
    } else {
      this.renderWaktu(hari);
    }
  }

  renderWaktu(hari) {
    var u = 0;
    var uket = 30;
    var k = 0;
    var ket = 30;
    var q = 20;
    var mulai = 9;
    var items = [];

    for (var i = 0; i < mulai; i++) {
      uket = uket + 1;
      u = u + 1;
      k = k + 1;
      ket = ket + 1;
      q = q + 1;
      items.push(
        <tr key={i} className="py-2">
          <td>
            {/* <input type="text" className="focus:outline-none w-8 px-2" disabled name={hari+"-jamKe[]"} data-id={u} data-hari={hari} value={u}/> */}
            <p className="text-sm">-</p>
          </td>
          <td>
            <input
              type="time"
              id={"copy-" + hari + "-" + i}
              onChange={this.cobadulu}
              data-idc={i}
              name={hari + "-jamAwal[]"}
              data-id={u}
              data-hari={hari}
            />
          </td>
          <td>
            <input
              type="time"
              id={"copyc-" + hari + "-" + i}
              name={hari + "-jamAkhir[]"}
              data-idc={i}
              onChange={this.changeFinish}
              data-id={q}
              data-hari={hari}
            />
          </td>
          <td>
            <input
              type="text"
              id={"copyccc-" + hari + "-" + i}
              list="cars"
              name={hari + "-keterangan[]"}
              placeholder="Pilih Keterangan "
              data-idc={i}
              data-id={uket}
              data-hari={hari}
            />
            <datalist id="cars">
              <option value="Upacara">Upacara</option>
              <option value="Istirahat">Istirahat</option>
              <option value="Pelajaran">Pelajaran</option>
            </datalist>
          </td>
        </tr>
      );
    }

    return (
      <form id={hari + "-formnya"}>
        <input
          type="hidden"
          className="focus:outline-none w-8 px-2"
          id="harinya"
          disabled
          name="hari"
          value={hari}
        />
        <table className="table-bordered w-full text-center text-sm">
          <thead>
            <tr>
              <th>Jam ke</th>
              <th>Waktu Mulai</th>
              <th>Waktu Selesai</th>
              <th>Keterangan</th>
            </tr>
          </thead>
          <tbody id={hari + "-tablenya"}>{items}</tbody>
        </table>
        <div className="w-full flex items-center">
          <div className="w-1/2 text-left">
            <input
              type="button"
              onClick={this.saveWaktu.bind(this, hari)}
              className="btn-schoolmedia px-4 py-2 mt-2 flex items-center rounded-full text-white text-sm"
              value="Simpan"
            />
          </div>
          <div className="w-1/2 text-right mr-2">
            <p className="text-sm">
              Jumlah Total Kuota per Minggu :{" "}
              <span className="font-bold">
                  {this.state.totalKuota}
                  {/* {this.state.kuota_senin} */}
              </span>
            </p>
          </div>
        </div>
      </form>
    );
  }

  changeFinish(e) {
    var id = e.target.dataset.id;
    var hari = e.target.dataset.hari;
    var toId = parseInt(id, 10) - 19;

    if (document.getElementById(hari + "-" + toId)) {
      document.getElementById(hari + "-" + toId).value = e.target.value;
    }
  }

  delete(hari) {
    Swal.fire({
      title: "Peringatan",
      text: "Apakah anda yakin menghapus waktu pelajaran tersebut?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Iya`,
      cancelButtonText: 'Batal',
    }).then((result) => {
      if (result.value == true) {
        axios
          .post(
            this.state.url_delete +'/'+ this.state.institusi_id_select + "/" + hari,
            {
              kurikulum: this.state.kurikulum_search,
              semester: this.state.semester_search,
              tahun_ajaran: this.state.tahun_ajaran_search,
              kelas: this.state.kelas_search,
            },
            { headers: { Authorization: tokenApi } }
          )
          .then((res) => {
            this.setState({
              show_alert: true,
              title_alert: "Berhasil",
              icon_alert: "success",
              message_alert: res.data.messages[0],
            });
            this.checkJadwal();
            this.getDataWaktu(res.data.hari);
          });
      } else if (result.dismiss == "cancel") {
        // this.setState({loading_delete: false});
      } else if (result.dismiss == "backdrop") {
        // this.setState({loading_delete: false});
      }
    });
  }

  saveWaktu(hari) {
    var table = document.getElementById(hari + "-formnya");
    // var hari       = table.elements.namedItem('harinya').value;
    // var jamKe      = table.elements.namedItem(hari+'-jamKe[]');
    var jamAwal = table.elements.namedItem(hari + "-jamAwal[]");
    var jamAkhir = table.elements.namedItem(hari + "-jamAkhir[]");
    // console.log(jamAwal, jamAkhir);
    var keterangan = table.elements.namedItem(hari + "-keterangan[]");

    // var valJamKe      = [];
    var valJamAwal = [];
    var valJamAkhir = [];
    var valKeterangan = [];
    var check = false;
    var validasi = false;

    if (jamAkhir.length > 1) {
      if (hari == 1) {
        if (this.state.jadwalSenin != 0 ? (check = true) : (check = false));
      }

      if (hari == 2) {
        if (this.state.jadwalSelasa != 0 ? (check = true) : (check = false));
      }

      if (hari == 3) {
        if (this.state.jadwalRabu != 0 ? (check = true) : (check = false));
      }

      if (hari == 4) {
        if (this.state.jadwalKamis != 0 ? (check = true) : (check = false));
      }

      if (hari == 5) {
        if (this.state.jadwalJumat != 0 ? (check = true) : (check = false));
      }

      if (hari == 6) {
        if (this.state.jadwalSabtu != 0 ? (check = true) : (check = false));
      }

      if (hari == 7) {
        if (this.state.jadwalMinggu != 0 ? (check = true) : (check = false));
      }

      // if(jamKe != null){
      //   for (var i=0, n=jamKe.length; i<n; i++){
      //     if(jamKe[i].getAttribute('data-hari') == hari && jamKe[i].value != null){
      //       valJamKe.push(jamKe[i].value);
      //     }
      //   }
      // }

      if (jamAwal != null) {
        for (var i = 0, n = jamAwal.length; i < n; i++) {
          if (
            jamAwal[i].getAttribute("data-hari") == hari &&
            jamAwal[i].value != null
          ) {
            if (jamAwal[i].value == "") {
              validasi = true;
            } else {
              valJamAwal.push(jamAwal[i].value);
            }
          }
        }
      }

      if (jamAkhir != null) {
        for (var i = 0, n = jamAkhir.length; i < n; i++) {
          if (
            jamAkhir[i].getAttribute("data-hari") == hari &&
            jamAkhir[i].value != null
          ) {
            if (jamAkhir[i].value == "") {
              validasi = true;
            } else {
              valJamAkhir.push(jamAkhir[i].value);
            }
          }
        }
      }

      if (keterangan != null) {
        for (var i = 0, n = keterangan.length; i < n; i++) {
          if (
            keterangan[i].getAttribute("data-hari") == hari &&
            keterangan[i].value != null
          ) {
            if (keterangan[i].value == "") {
              validasi = true;
            } else {
              valKeterangan.push(keterangan[i].value);
            }
          }
        }
      }

      if (validasi == true) {
        this.setState({
          show_alert: true,
          title_alert: "Gagal",
          icon_alert: "error",
          message_alert: "Mohon isi form dengan benar",
        });
      } else {
        this.setState(
          {
            inputHari: hari,
            inputJamAwal: valJamAwal,
            inputJamAkhir: valJamAkhir,
            inputKet: valKeterangan,
          },
          function() {
            if (check) {
              this.setState({ modalPreview: true });
            } else {
              this.confirmSubmit();
            }
          }
        );
      }
    } else {
      if (
        jamAwal.value != "" &&
        jamAkhir.value != "" &&
        keterangan.value != ""
      ) {
        valJamAwal.push(jamAwal.value);
        valJamAkhir.push(jamAkhir.value);
        valKeterangan.push(keterangan.value);

        this.setState(
          {
            inputHari: hari,
            inputJamAwal: valJamAwal,
            inputJamAkhir: valJamAkhir,
            inputKet: valKeterangan,
          },
          function() {
            if (check) {
              this.setState({ modalPreview: true });
            } else {
              this.confirmSubmit();
            }
          }
        );
      } else {
        this.setState({
          show_alert: true,
          title_alert: "Gagal",
          icon_alert: "error",
          message_alert: "Mohon isi form dengan benar",
        });
      }
    }
  }

  confirmSubmit() {
    axios
      .post(
        this.state.url_create,
        {
          institusi: this.state.institusi_id_select,
          hari: this.state.inputHari,
          // jam_ke      : valJamKe,
          jam_awal: this.state.inputJamAwal,
          jam_akhir: this.state.inputJamAkhir,
          keterangan: this.state.inputKet,
          kelas: this.state.kelas_search,
          kurikulum: this.state.kurikulum_search,
          tahun_ajaran: this.state.tahun_ajaran_search,
          semester: this.state.semester_search,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState(
          {
            show_alert: true,
            title_alert: "Berhasil",
            icon_alert: "success",
            message_alert: res.data.messages[0],
          },
          function() {
            this.getDataWaktu(res.data.hari);
          }
        );
      })
      .catch((err) => {
        this.alertMessage(err.response);
      });
  }

  hidePreview() {
    this.setState({
      inputHari: "",
      inputJamAwal: "",
      inputJamAkhir: "",
      inputKet: "",
      modalPreview: false,
    });
  }

  copy(e) {
    let dataForm = document.getElementById(`${e}-formnya`);
    let formWaktuAwal = dataForm.elements.namedItem(
      `${e}-jamAwal[]`
    );
    let formWaktuAkhir = dataForm.elements.namedItem(
      `${e}-jamAkhir[]`
    );
    let formKeterangan = dataForm.elements.namedItem(
      `${e}-keterangan[]`
    );

    this.setState({
      formCopy : {
        formWaktuAwal :formWaktuAwal,
        formWaktuAkhir :formWaktuAkhir,
        formKeterangan :formKeterangan,
      }
    }, function() {
      Swal.fire({
        position: "center",
        title: "Berhasil",
        text: "Waktu Pelajaran Berhasil Dicopy",
        icon: "success",
        timer: 1500,
      });
    })
  }

  paste(e) {
    if (this.state.formCopy != null) {
      let formWaktu = this.state.formCopy?.formWaktuAwal;
      let formWaktuc = this.state.formCopy?.formWaktuAkhir;
      let formKeterangan = this.state.formCopy?.formKeterangan;
  
      // get target table
      const targetTable = document.getElementById(`${e}-tablenya`)
      const targetTableChild = targetTable.getElementsByTagName('tr')
  
      if (formWaktu.length > targetTableChild.length) {
        const addRow = formWaktu.length - targetTableChild.length
        for (let i = 0; i < addRow; i++) {
          this.trPlush(targetTable, `${e}-tablenya`, e)
        }
      }
  
      // paste keterangan
      for (var ic = 0; ic < formKeterangan.length; ic++) {
        if (
          document.getElementById(
            `copyccc-${e}-` +
              formKeterangan[ic]["attributes"]["data-idc"].nodeValue
          )
        ) {
          if (formKeterangan[ic].value != "") {
            document.getElementById(
              `copyccc-${e}-` +
                formKeterangan[ic]["attributes"]["data-idc"].nodeValue
            ).value = formKeterangan[ic].value;
          }
        }
      }
  
      // paste waktu mulai
      for (var iccc = 0; iccc < formWaktu.length; iccc++) {
        if (
          document.getElementById(
            `copy-${e}-` +
              formKeterangan[iccc]["attributes"]["data-idc"].nodeValue
          )
        ) {
          if (formWaktu[iccc].value != "") {
            document.getElementById(
              `copy-${e}-` +
                formKeterangan[iccc]["attributes"]["data-idc"].nodeValue
            ).value = formWaktu[iccc].value;
          }
        }
      }
  
      // paste waktu selesai
      for (var icccccc = 0; icccccc < formWaktuc.length; icccccc++) {
        if (
          document.getElementById(
            `copyc-${e}-` +
              formWaktuc[icccccc]["attributes"]["data-idc"].nodeValue
          )
        ) {
          if (formWaktuc[icccccc].value != "") {
            document.getElementById(
              `copyc-${e}-` +
                formWaktuc[icccccc]["attributes"]["data-idc"].nodeValue
            ).value = formWaktuc[icccccc].value;
          }
        }
      }
    }
  }

  render() {
    return (
      <div>
        {/* alert */}
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />

        <Modal show={this.state.modalPreview} onHide={this.hidePreview}>
          <Modal.Body>
            <p className="text-sm">
              Apa anda yakin ingin merubah waktu pelajaran ini? jika merubah
              akan menghapus jadwal pelajaran pada hari tersebut
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.confirmSubmit}
            >
              Iya
            </button>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hidePreview}
            >
              Tidak
            </button>
          </Modal.Footer>
        </Modal>

        <div className="sm-container flex flex-row mt-6 mx-auto">
          <SideBarJadwal />

          <div className="w-10/12 px-1 ml-2">
            <div className="flex justify-between border items-center px-6 py-4 rounded-lg shadow-md bg-white mb-2">
              <h1 className="text-xl text-gray-800 font-extrabold">
                <span className="text-gray-500 font-thin block text-sm mb-2">
                  <p href="/sadasd" className="text-gray-600">
                    1 dari 8 langkah
                  </p>
                </span>
                Atur Waktu Pelajaran
                <span className="text-gray-500 font-thin block text-sm">
                  {/* <a href="/sadasd" className="text-blue-600">
                    Pelajari Lebih Lanjut ?
                  </a> */}
                </span>
              </h1>
              <div className="flex"></div>
            </div>

            <div className="mt-4 px-6 py-4 rounded-lg shadow-md bg-white border">
              {this.state.loadingFilter == false ? (
                <span className="text-md text-gray-800">Cari :</span>
              ) : (
                ""
              )}
              <div className="mb-6">
                <div className="flex flex-row flex-wrap">
                  {this.state.loadingFilter ? (
                    <>
                      <div className="w-1/4 px-1">
                        <div className="py-2">
                          <Skeleton count={2} />
                        </div>
                      </div>
                      <div className="w-1/4 px-1">
                        <div className="py-2">
                          <Skeleton count={2} />
                        </div>
                      </div>
                      <div className="w-1/4 px-1">
                        <div className="py-2">
                          <Skeleton count={2} />
                        </div>
                      </div>
                      <div className="w-1/4 px-1">
                        <div className="py-2">
                          <Skeleton count={2} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {this.showSelectFilterSD()}
                      {this.showSelectFilterKurikulum()}
                      {this.showSelectFilterSemester()}
                      {this.state.loading_show_data ? (
                        <div className="w-1/4 px-1 flex items-center">
                          <div className="py-2">
                            <div className="flex">
                              <span>Pilih secara berurutan</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="w-1/4 px-1">
                          <div className="py-2">
                            <div className="flex">
                              {this.state.semester_search !== "" ? (
                                <>
                                  <button
                                    onClick={this.setFilter}
                                    className={`btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm`}
                                  >
                                    <i className="bx bx-search text-lg mr-1" ></i>
                                    Cari
                                  </button>
                                  <button onClick={this.setResetFilter} className="flex btn-default text-sm text-gray-800 px-4 py-2 ml-2 rounded-full items-center">
                                    Reset
                                  </button>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {/* <label className="mb-2 ml-2 text-red-600 block text-sm">Jika anda mengubah pengaturan mata pelajaran ini, anda harus mengubah data di menu lainnya.</label> */}
              </div>
            </div>

            <div className="mt-4 py-4 rounded-lg shadow-md bg-white border">
              <div className="w-full items-centerpy-4">
                <>
                  <Tabs
                    defaultActiveKey="senin"
                    id="uncontrolled-tab-example"
                    className="px-6"
                  >
                    <Tab eventKey="senin" title="Senin">
                      <div className="p-4">
                        {this.state.jadwalSenin ? (
                          <div
                            class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4"
                            role="alert"
                          >
                            <p class="font-bold">Informasi</p>
                            <p>
                              Jadwal Pelajaran untuk hari Senin sudah dibuat,
                              jika mengganti atau menghapus waktu pada hari
                              Senin maka akan menghapus Jadwal Pelajaran pada
                              hari Senin
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="flex flex-row my-2 w-full items-center">
                          <div className="w-1/2 flex text-left">
                            <button
                              onClick={(e) => this.trPlush(e, "1-tablenya", 1)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-plus text-lg mr-1"></i>
                              Tambah Jam
                            </button>
                            <button
                              onClick={this.trMinus.bind(this, "1-tablenya")}
                              className="btn-schoolmedia px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-minus text-lg mr-1"></i>
                              Kurangi Jam
                            </button>
                          </div>
                          <div className="w-1/2 flex text-left">
                            {/* <button
                              onClick={this.copy.bind(this, 1)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy & Paste Jam
                            </button> */}
                            <button
                              onClick={(e) => this.copy((e = 1))}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy
                            </button>
                            {!!this.state.formCopy ? (
                              <>
                                <button
                                  onClick={(e) => this.paste((e = 1))}
                                  className="btn-schoolmedia mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                                >
                                  <i className="bx bx-copy-alt text-lg mr-1"></i>
                                  Paste
                                </button>
                              </>
                            ) : (
                              <button
                                disabled
                                className="btn-schoolmedia bg-gray-500 mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                              >
                                <i className="bx bx-copy-alt text-lg mr-1"></i>
                                Paste
                              </button>
                            )}
                          </div>
                          <div className="w-1/2 text-right mr-2">
                            <p className="text-sm">
                              Kuota : {this.state.kuota_senin}
                            </p>
                          </div>
                        </div>
                        {this.state.loadingSenin ? (
                          <>
                            <div className="mb-6 mt-6">
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div id="tabSenin"></div>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="selasa" title="Selasa">
                      <div className="p-4">
                        {this.state.jadwalSelasa ? (
                          <div
                            class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4"
                            role="alert"
                          >
                            <p class="font-bold">Informasi</p>
                            <p>
                              Jadwal Pelajaran untuk hari Selasa sudah dibuat,
                              jika mengganti atau menghapus waktu pada hari
                              Selasa maka akan menghapus Jadwal Pelajaran pada
                              hari Selasa
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="flex flex-row my-2 w-full items-center">
                          <div className="w-1/2 flex text-left">
                            <button
                              onClick={(e) => this.trPlush(e, "2-tablenya", 2)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-plus text-lg mr-1"></i>
                              Tambah Jam
                            </button>
                            <button
                              onClick={this.trMinus.bind(this, "2-tablenya")}
                              className="btn-schoolmedia px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-minus text-lg mr-1"></i>
                              Kurangi Jam
                            </button>
                          </div>
                          <div className="w-1/2 flex text-left">
                            {/* <button
                              onClick={this.copy.bind(this, 1)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy & Paste Jam
                            </button> */}
                            <button
                              onClick={(e) => this.copy((e = 2))}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy
                            </button>
                            {this.state.formCopy != null ? (
                              <>
                                <button
                                  onClick={(e) => this.paste((e = 2))}
                                  className="btn-schoolmedia mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                                >
                                  <i className="bx bx-copy-alt text-lg mr-1"></i>
                                  Paste
                                </button>
                              </>
                            ) : (
                              <button
                                disabled
                                className="btn-schoolmedia bg-gray-500 mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                              >
                                <i className="bx bx-copy-alt text-lg mr-1"></i>
                                Paste
                              </button>
                            )}
                          </div>
                          <div className="w-1/2 text-right mr-2">
                            <p className="text-sm">
                              Kuota : {this.state.kuota_selasa}
                            </p>
                          </div>
                        </div>
                        {this.state.loadingSelasa ? (
                          <>
                            <div className="mb-6 mt-6">
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div id="tabSelasa"></div>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="rabu" title="Rabu">
                      <div className="p-4">
                        {this.state.jadwalRabu ? (
                          <div
                            class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4"
                            role="alert"
                          >
                            <p class="font-bold">Informasi</p>
                            <p>
                              Jadwal Pelajaran untuk hari Rabu sudah dibuat,
                              jika mengganti atau menghapus waktu pada hari Rabu
                              maka akan menghapus Jadwal Pelajaran pada hari
                              Rabu
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="flex flex-row my-2 w-full items-center">
                          <div className="w-1/2 flex text-left">
                            <button
                              onClick={(e) => this.trPlush(e, "3-tablenya", 3)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-plus text-lg mr-1"></i>
                              Tambah Jam
                            </button>
                            <button
                              onClick={this.trMinus.bind(this, "3-tablenya")}
                              className="btn-schoolmedia px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-minus text-lg mr-1"></i>
                              Kurangi Jam
                            </button>
                          </div>
                          <div className="w-1/2 flex text-left">
                            {/* <button
                              onClick={this.copy.bind(this, 1)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy & Paste Jam
                            </button> */}
                            <button
                              onClick={(e) => this.copy((e = 3))}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy
                            </button>
                            {this.state.formCopy != null ? (
                              <>
                                <button
                                  onClick={(e) => this.paste((e = 3))}
                                  className="btn-schoolmedia mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                                >
                                  <i className="bx bx-copy-alt text-lg mr-1"></i>
                                  Paste
                                </button>
                              </>
                            ) : (
                              <button
                                disabled
                                className="btn-schoolmedia bg-gray-500 mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                              >
                                <i className="bx bx-copy-alt text-lg mr-1"></i>
                                Paste
                              </button>
                            )}
                          </div>
                          <div className="w-1/2 text-right mr-2">
                            <p className="text-sm">
                              Kuota : {this.state.kuota_rabu}
                            </p>
                          </div>
                        </div>
                        {this.state.loadingRabu ? (
                          <>
                            <div className="mb-6 mt-6">
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div id="tabRabu"></div>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="kamis" title="Kamis">
                      <div className="p-4">
                        {this.state.jadwalKamis ? (
                          <div
                            class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4"
                            role="alert"
                          >
                            <p class="font-bold">Informasi</p>
                            <p>
                              Jadwal Pelajaran untuk hari Kamis sudah dibuat,
                              jika mengganti atau menghapus waktu pada hari
                              Kamis maka akan menghapus Jadwal Pelajaran pada
                              hari Kamis
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="flex flex-row my-2 w-full items-center">
                          <div className="w-1/2 flex text-left">
                            <button
                              onClick={(e) => this.trPlush(e, "4-tablenya", 4)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-plus text-lg mr-1"></i>
                              Tambah Jam
                            </button>
                            <button
                              onClick={this.trMinus.bind(this, "4-tablenya")}
                              className="btn-schoolmedia px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-minus text-lg mr-1"></i>
                              Kurangi Jam
                            </button>
                          </div>
                          <div className="w-1/2 flex text-left">
                            {/* <button
                              onClick={this.copy.bind(this, 1)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy & Paste Jam
                            </button> */}
                            <button
                              onClick={(e) => this.copy((e = 4))}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy
                            </button>
                            {this.state.formCopy != null ? (
                              <>
                                <button
                                  onClick={(e) => this.paste((e = 4))}
                                  className="btn-schoolmedia mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                                >
                                  <i className="bx bx-copy-alt text-lg mr-1"></i>
                                  Paste
                                </button>
                              </>
                            ) : (
                              <button
                                disabled
                                className="btn-schoolmedia bg-gray-500 mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                              >
                                <i className="bx bx-copy-alt text-lg mr-1"></i>
                                Paste
                              </button>
                            )}
                          </div>
                          <div className="w-1/2 text-right mr-2">
                            <p className="text-sm">
                              Kuota : {this.state.kuota_kamis}
                            </p>
                          </div>
                        </div>
                        <div id="tabKamis"></div>
                      </div>
                    </Tab>
                    <Tab eventKey="jumat" title="Jumat">
                      <div className="p-4">
                        {this.state.jadwalJumat ? (
                          <div
                            class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4"
                            role="alert"
                          >
                            <p class="font-bold">Informasi</p>
                            <p>
                              Jadwal Pelajaran untuk hari Jumat sudah dibuat,
                              jika mengganti atau menghapus waktu pada hari
                              Jumat maka akan menghapus Jadwal Pelajaran pada
                              hari Jumat
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="flex flex-row my-2 w-full items-center">
                          <div className="w-1/2 flex text-left">
                            <button
                              onClick={(e) => this.trPlush(e, "5-tablenya", 5)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-plus text-lg mr-1"></i>
                              Tambah Jam
                            </button>
                            <button
                              onClick={this.trMinus.bind(this, "5-tablenya")}
                              className="btn-schoolmedia px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-minus text-lg mr-1"></i>
                              Kurangi Jam
                            </button>
                          </div>
                          <div className="w-1/2 flex text-left">
                            {/* <button
                              onClick={this.copy.bind(this, 1)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy & Paste Jam
                            </button> */}
                            <button
                              onClick={(e) => this.copy((e = 5))}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy
                            </button>
                            {this.state.formCopy != null ? (
                              <>
                                <button
                                  onClick={(e) => this.paste((e = 5))}
                                  className="btn-schoolmedia mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                                >
                                  <i className="bx bx-copy-alt text-lg mr-1"></i>
                                  Paste
                                </button>
                              </>
                            ) : (
                              <button
                                disabled
                                className="btn-schoolmedia bg-gray-500 mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                              >
                                <i className="bx bx-copy-alt text-lg mr-1"></i>
                                Paste
                              </button>
                            )}
                          </div>
                          <div className="w-1/2 text-right mr-2">
                            <p className="text-sm">
                              Kuota : {this.state.kuota_jumat}
                            </p>
                          </div>
                        </div>
                        {this.state.loadingJumat ? (
                          <>
                            <div className="mb-6 mt-6">
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div id="tabJumat"></div>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="sabtu" title="Sabtu">
                      <div className="p-4">
                        {this.state.jadwalSabtu ? (
                          <div
                            class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4"
                            role="alert"
                          >
                            <p class="font-bold">Informasi</p>
                            <p>
                              Jadwal Pelajaran untuk hari Sabtu sudah dibuat,
                              jika mengganti atau menghapus waktu pada hari
                              Sabtu maka akan menghapus Jadwal Pelajaran pada
                              hari Sabtu
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="flex flex-row my-2 w-full items-center">
                          <div className="w-1/2 flex text-left">
                            <button
                              onClick={(e) => this.trPlush(e, "6-tablenya", 6)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-plus text-lg mr-1"></i>
                              Tambah Jam
                            </button>
                            <button
                              onClick={this.trMinus.bind(this, "6-tablenya")}
                              className="btn-schoolmedia px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-minus text-lg mr-1"></i>
                              Kurangi Jam
                            </button>
                          </div>
                          <div className="w-1/2 flex text-left">
                            {/* <button
                              onClick={this.copy.bind(this, 1)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy & Paste Jam
                            </button> */}
                            <button
                              onClick={(e) => this.copy((e = 6))}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy
                            </button>
                            {this.state.formCopy != null ? (
                              <>
                                <button
                                  onClick={(e) => this.paste((e = 6))}
                                  className="btn-schoolmedia mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                                >
                                  <i className="bx bx-copy-alt text-lg mr-1"></i>
                                  Paste
                                </button>
                              </>
                            ) : (
                              <button
                                disabled
                                className="btn-schoolmedia bg-gray-500 mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                              >
                                <i className="bx bx-copy-alt text-lg mr-1"></i>
                                Paste
                              </button>
                            )}
                          </div>
                          <div className="w-1/2 text-right mr-2">
                            <p className="text-sm">
                              Kuota : {this.state.kuota_sabtu}
                            </p>
                          </div>
                        </div>
                        {this.state.loadingSabtu ? (
                          <>
                            <div className="mb-6 mt-6">
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div id="tabSabtu"></div>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="minggu" title="Minggu">
                      <div className="p-4">
                        {this.state.jadwalMinggu ? (
                          <div
                            class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4"
                            role="alert"
                          >
                            <p class="font-bold">Informasi</p>
                            <p>
                              Jadwal Pelajaran untuk hari Minggu sudah dibuat,
                              jika mengganti atau menghapus waktu pada hari
                              Minggu maka akan menghapus Jadwal Pelajaran pada
                              hari Minggu
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="flex flex-row my-2 w-full items-center">
                          <div className="w-1/2 flex text-left">
                            <button
                              onClick={(e) => this.trPlush(e, "7-tablenya", 7)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-plus text-lg mr-1"></i>
                              Tambah Jam
                            </button>
                            <button
                              onClick={this.trMinus.bind(this, "7-tablenya")}
                              className="btn-schoolmedia px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-minus text-lg mr-1"></i>
                              Kurangi Jam
                            </button>
                          </div>
                          <div className="w-1/2 flex text-left">
                            {/* <button
                              onClick={this.copy.bind(this, 1)}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy & Paste Jam
                            </button> */}
                            <button
                              onClick={(e) => this.copy((e = 7))}
                              className="btn-schoolmedia mr-1 px-4 flex items-center rounded-l-full text-white py-2 text-sm"
                            >
                              <i className="bx bx-copy-alt text-lg mr-1"></i>
                              Copy
                            </button>
                            {this.state.formCopy != null ? (
                              <>
                                <button
                                  onClick={(e) => this.paste((e = 7))}
                                  className="btn-schoolmedia mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                                >
                                  <i className="bx bx-copy-alt text-lg mr-1"></i>
                                  Paste
                                </button>
                              </>
                            ) : (
                              <button
                                disabled
                                className="btn-schoolmedia bg-gray-500 mr-1 px-4 flex items-center rounded-r-full text-white py-2 text-sm"
                              >
                                <i className="bx bx-copy-alt text-lg mr-1"></i>
                                Paste
                              </button>
                            )}
                          </div>
                          <div className="w-1/2 text-right mr-2">
                            <p className="text-sm">
                              Kuota : {this.state.kuota_minggu}
                            </p>
                          </div>
                        </div>
                        {this.state.loadingMinggu ? (
                          <>
                            <div className="mb-6 mt-6">
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton
                                    height={20}
                                    className="bg-gray-400"
                                  />{" "}
                                </div>
                              </div>
                              <div className="flex flex-row mb-2 mt-2 px-4">
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                                <div className="w-1/3 px-2">
                                  {" "}
                                  <Skeleton height={20} />{" "}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div id="tabMinggu"></div>
                        )}
                      </div>
                    </Tab>
                  </Tabs>
                </>
              </div>
            </div>

            <div className="mt-2 mb-6 px-4 py-4 rounded-lg shadow-md bg-white border">
              <div className="px-1 flex flex-row justify-between items-center">
                <div className="flex">
                  <Link to="/jadwal-pelajaran/atur-mapel-rombel">
                    <button className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm">
                      Selanjutnya
                      <i className="bx bx-chevron-right-circle text-lg ml-1"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
