import React from "react";
import { Modal, Carousel } from 'react-bootstrap';


export default class ModalPelajari extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal_pelajari: false,
        }
        //Function
        this.showModalPelajari = this.showModalPelajari.bind(this);
        this.hideModalPelajari = this.hideModalPelajari.bind(this);
    }

    itemGambar () {

    }

    showModalPelajari(e){
        e.preventDefault();
        this.setState({ modal_pelajari:true });
    }
    hideModalPelajari(e){
        e.preventDefault();
        this.setState({ modal_pelajari: false });
    }


    render () {
        return (
            <Modal show={this.state.modal_pelajari}>
                <Modal.Header>Pelajari Lebih Lanjut</Modal.Header>
                <Modal.Body>
                    <Carousel>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="holder.js/800x400?text=First slide&bg=373940"
                            alt="First slide"
                            />
                            <Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="holder.js/800x400?text=Second slide&bg=282c34"
                            alt="Third slide"
                            />

                            <Carousel.Caption>
                            <h3>Second slide label</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </Modal.Body>
            </Modal>
        )
    }
}