import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import conf from "./../../config.js";
import axios from "axios";
import { browserHistory } from 'react-router';
// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

export default class landingpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
     
    };
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    // get session user
    if(localStorage.getItem('token') !== '')
    {
      window.location.replace('/profil');
    }
  }

  login(){
    this.setState({loading: true});
    localStorage.setItem("alert", "");
    axios.get(`${conf.uri_backend}api/v1/auth/client`).then(res => {
        // window.location.replace("https://login.schoolmedia.id//auth/is_login?&client_id="+JSON.parse(res.data.data).client_id+"&redirect_uri="+JSON.parse(res.data.data).caught_uri);
        window.location.replace(`${conf.uri_sso}`+"/auth/is_login?client_id="+JSON.parse(res.data.data).client_id+"&redirect_uri="+JSON.parse(res.data.data).caught_uri);
    })
  }

  render() {
    return (
      <>
        <style dangerouslySetInnerHTML={{__html: `
            .sm-navbar { display: none };
          `}}
        />
        <style dangerouslySetInnerHTML={{__html: `
            body, html {
              height: 100%;
              background: url(/static/media/landing_page_bg.8e9cd166.png);
              background-size: cover;
              background-repeat: no-repeat;
              background-color: #09719d;
              height: 100% !important;
              background-position: center;
            }
          `}}
        />
        <div className="sm:hidden lg:block flex flex-col w-full" style={{height: '100%'}}>
          <nav>
            <div className="mx-auto flex justify-between pt-5" style={{maxWidth: "85rem"}}>
              <div className="flex ">
                <div
                  className="flex relative pl-1 pr-3 items-center "
                  style={{ height: "3.2rem"}}
                >
                  <div className="flex items-center w-full">
                    <div className="flex-shrink-0 z-50 flex items-center">
                      <img src="/assets/img/site-logo.png" className="ml-12" style={{ width: "13.8rem" }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <div className="block px-4 sm:px-6 lg:px-8 float-right">
                    <div className="ml-4 flex flex-row items-center">
                      <Link>
                        <h3 className="text-white font-bold text-md mr-5">TENTANG SCHOOLMEDIA</h3>
                      </Link>
                      {/* <Link>
                        <h3 className="text-white font-bold text-md ml-5">PROMO</h3>
                      </Link> */}
                    </div> 
                </div>
              </div>
            </div>
          </nav>
          <div className="flex">
            <div className="flex flex-row items-center mx-auto pt-16" style={{maxWidth: "78rem", width: '78rem'}}>
              <div className="flex flex-col w-1/2">
                <div className="flex flex-row mt-2">
                  {localStorage.getItem("alert") != undefined && localStorage.getItem("alert") != '' ? (
                    <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4" role="alert">
                      <p class="font-bold">Informasi</p>
                      <p>{localStorage.getItem("alert")}</p>
                    </div>
                  )  : ''}
                </div>
                <h3 className="text-white font-bolder" style={{fontSize: '2.8rem', fontWeight: 'bolder'}}>USER MANAGEMENT</h3>
                <p className="text-white text-md my-10">
                Schoolmedia merupakan website aplikasi pendidikan yang dilengkapi dengan network sosial. Schoolmedia menghubungkan masyarakat dengan komunitas sekolah untuk saling berbagi pengetahuan. Schoolmedia tempat bertemunya lembaga dan individu antara guru, siswa, orang tua, alumni sekolah, personil lembaga, maupun masyarakat umum untuk saling berinteraksi dan saling memberikan manfaat.
                </p>
                <p className="text-white text-md mb-5">
                  Connecting People with School Communities
                </p>
                <div className="flex flex-row">
                  <button onClick={this.login} className={this.state.loading ? "btn-disabled flex flex-row items-center rounded px-5 py-3 text-grey-800 text-lg font-bold mr-5" : "btn-green flex flex-row items-center rounded px-5 py-3 text-white text-lg font-bold mr-5"}>
                    {this.state.loading ? "Tunggu..." : "MASUK"}
                  </button>
                  <button className="btn-orange rounded px-5 py-3 text-white text-lg font-bold mr-5">PELAJARI</button>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-16 w-full flex justify-between pt-5">
            <div className="flex ">
              <div
                className="flex relative pr-3 items-center "
                style={{ height: "3.2rem"}}
              >
                <div className="flex items-center w-full">
                  <div className="flex-shrink-0 z-50 flex items-center">
                    <p className="text-white font-bold text-bold ">© 2018 Schoolmedia. All rights reserved</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:block lg:hidden">
          <nav className="w-full">
            <div className="w-full flex justify-between pt-5">
              <div className="w-1/2 flex ">
                <div
                  className="flex relative pl-1 pr-3 items-center "
                  style={{ height: "3.2rem"}}
                >
                  <div className="flex items-center w-full">
                    <div className="flex-shrink-0 z-50 flex items-center">
                      <img src="/assets/img/site-logo.png" className="ml-6 mt-10" style={{ width: "25.8rem" }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 flex items-center">
                <div className="hidden block px-4 float-right">
                    <div className="ml-4 flex flex-row items-center">
                      <Link>
                        <h3 className="text-white font-bold text-md mr-5">TENTANG SCHOOLMEDIA</h3>
                      </Link>
                      {/* <Link>
                        <h3 className="text-white font-bold text-md ml-5">PROMO</h3>
                      </Link> */}
                    </div> 
                </div>
              </div>
            </div>
          </nav>
          <div className="w-full p-10" style={{marginTop: '15rem'}}>
            <div className="flex items-center mx-auto">
              <div className="flex flex-col w-1/2">
                <div className="flex flex-row mt-2">
                  {localStorage.getItem("alert") != undefined && localStorage.getItem("alert") != '' ? (
                    <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4" role="alert">
                      <p class="font-bold">Informasi</p>
                      <p>{localStorage.getItem("alert")}</p>
                    </div>
                  )  : ''}
                </div>
                <h3 className="text-white font-bolder" style={{fontSize: '4rem', fontWeight: 'bolder'}}>USER MANAGEMENT</h3>
                <p className="text-white text-2xl leading-7 my-10">
                Schoolmedia merupakan website aplikasi pendidikan yang dilengkapi dengan network sosial. Schoolmedia menghubungkan masyarakat dengan komunitas sekolah untuk saling berbagi pengetahuan. Schoolmedia tempat bertemunya lembaga dan individu antara guru, siswa, orang tua, alumni sekolah, personil lembaga, maupun masyarakat umum untuk saling berinteraksi dan saling memberikan manfaat.
                </p>
                <p className="text-white text-md mb-5 text-2xl leading-6">
                  Connecting People with School Communities
                </p>
                <div className="flex flex-row">
                  <button onClick={this.login} className={this.state.loading ? "btn-disabled flex flex-row items-center rounded px-5 py-3 text-grey-800 text-4xl font-bold mr-5" : "btn-green flex flex-row items-center rounded px-5 py-3 text-white text-4xl font-bold mr-5"}>
                    {this.state.loading ? "Tunggu..." : "MASUK"}
                  </button>
                  <button className="btn-orange rounded px-5 py-3 text-white text-4xl font-bold mr-5">PELAJARI</button>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto w-full flex justify-between pt-5 bottom-1" style={{position: 'absolute', left: '1.5rem'}}>
            <div className="flex ">
              <div
                className="flex relative pl-1 pr-3 items-center "
                style={{ height: "3.2rem"}}
              >
                <div className="flex items-center w-full">
                  <div className="flex-shrink-0 z-50 flex items-center">
                    <p className="text-white text-2xl font-bold text-bold ">© 2018 Schoolmedia. All rights reserved</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
