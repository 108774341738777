import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from "react-router-dom";
import "./style/errorPage.css";

export const Login = () =>  {
    let history = useHistory();
    return (
        <>
            <button
                className="btn-hover color-6"
            >
                <Link
                    to="/" 
                >
                    <a >Halaman Utama</a>
                </Link>
            </button>
        </>
    );
};

export default class NotLoginPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }
    render(){
        return(
            <React.Fragment>
            <style dangerouslySetInnerHTML={{__html: `
                .sm-navbar { display: none }
            `}}
            />
                <div id="error-pages">
                    <div id="notfound">
                        <div className="notfound">
                            <div className="notfound-404">
                                <h1 style={{letterSpacing: "-9px"}}>O<span></span>ps!</h1>
                            </div>
                            <h2 style={{textTransform: "none"}}>Anda Belum Login</h2>
                            <p>Silahkan Login terlebih dahulu</p>
                            <Login/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}