import React from "react";
import DataTable from "../../partials/table/DataTables.js";
import { Link, Redirect, useHistory } from "react-router-dom";
import conf from "../../../config.js";
import SelectJs from "../../partials/Select.js";
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import { Button, Modal, Carousel } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import SweetAlert from "sweetalert2-react";
import Skeleton from "react-loading-skeleton";
import swal from "sweetalert2";

import {
  UserEditSkeleton,
  DetailSkeleton,
  UserButtonLoading,
} from "../../partials/loadings/ComponentLoading.js";

// select style
const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "100px",
    minWidth: "15rem",
  }),
};

function captFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const dataKurikulum = [
  { value: "k13", label: "K13" },
  { value: "merdeka", label: "Merdeka" },
];

// const dataKurikulumSMK = [
//   { value: 'k13', label: 'K13' },
//   { value: 'merdeka', label: 'Merdeka' },
// ]

export default class RombelPerTA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${conf.uri_backend}api/v1/rombel`,
      url_get_rombel: `${conf.uri_backend}api/v1/rombel/get_rombel`,
      url_get_edit: `${conf.uri_backend}api/v1/rombel/`,
      url_delete_m: `${conf.uri_backend}api/v1/rombel/rombel_destroy`,
      url_put: `${conf.uri_backend}api/v1/rombel/`,
      url_update: `${conf.uri_backend}api/v1/rombel/update/wali-kelas/guru`,
      url_get_wali: `${conf.uri_backend}api/v1/rombel/kelas/wali/`,
      url_get_spektrum: `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_filter: `${conf.uri_backend}api/v1/rombel/filter`,
      url_get_smk: `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,
      url_put_filter_rombel: `${conf.uri_backend}api/v1/rombel/filter`,
      url_show_data: "",

      // modal
      modal_tambah: false,
      modal_edit: false,
      modal_pelajari: false,
      modal_editc: false,
      guru_id: "",
      // bind data
      nama_rombel: "",
      rombel: "",
      tahun_ajaran: "",
      id: "",
      wali_kelas: "",
      kurikulum: "",
      kurikulum_select: "",
      kelas: "",
      m_spektrum_id: "",
      tahunSekarang: new Date().getFullYear(),
      showFilter: "",
      // input filter
      tahun_ajaran_search: "",
      kelas_search: "",
      peminatan_search: "",
      rombel_search: "",
      bidang_search: "",
      kurikulum_search: "",
      program_search: "",
      kompetensi_search: "",
      // data filter
      data_tahun_ajaran: "",
      data_kelas: "",
      data_kurikulum: "",
      data_peminatan: "",
      data_rombel: "",
      data_peminatan_input: "",
      data_wali_kelas: "",
      // bind data edit
      select_wali: "",
      select_id_guru: "",
      select_id_spektrum: "",
      select_nama_peminatan: "",
      select_kelas: "",
      select_bidang_keahlian: "",
      select_program_keahlian: "",
      select_kompetensi_keahlian: "",
      // for message
      note_wali_kelas:
        "Pilih terlebih dahulu Tahun ajaran sebelum memilih wali kelas",
      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",
      // search and filter
      searchText: "",
      showFilter: "",
      loadingFilter: true,

      isChecked: false,
      check_item: false,
      checkAll: false,
      loading_check: false,
    };

    this.nama_rombel = this.nama_rombel.bind(this);
    this.m_spektrum_id = this.m_spektrum_id.bind(this);
    this.tahun_ajaran = this.tahun_ajaran.bind(this);
    this.wali_kelas = this.wali_kelas.bind(this);
    this.kelas = this.kelas.bind(this);
    this.bidang = this.bidang.bind(this);
    this.program = this.program.bind(this);
    this.kelas = this.kelas.bind(this);
    this.kurikulum = this.kurikulum.bind(this);
    this.kurikulumUpdate = this.kurikulumUpdate.bind(this);
    this.getWalas = this.getWalas.bind(this);
    // Create Funtion
    this.showModalTambah = this.showModalTambah.bind(this);
    this.hideModalTambah = this.hideModalTambah.bind(this);
    this.create = this.create.bind(this);
    // Edit Function
    this.showModalEdit = this.showModalEdit.bind(this);
    this.hideModalEdit = this.hideModalEdit.bind(this);
    this.update = this.update.bind(this);
    this.hideModalEditc = this.hideModalEditc.bind(this);
    this.updatec = this.updatec.bind(this);
    //Function
    this.showModalPelajari = this.showModalPelajari.bind(this);
    this.hideModalPelajari = this.hideModalPelajari.bind(this);
    this.delete = this.delete.bind(this);
    // filter and search
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterKurikulum = this.filterKurikulum.bind(this);
    this.filterKelas = this.filterKelas.bind(this);
    this.filterRombel = this.filterRombel.bind(this);
    this.filterPeminatan = this.filterPeminatan.bind(this);
    this.filterBidang = this.filterBidang.bind(this);
    this.filterProgram = this.filterProgram.bind(this);
    this.filterKompetensi = this.filterKompetensi.bind(this);
    this.searchText = this.searchText.bind(this);
    // show Data
    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
    this.showData = this.showData.bind(this);
    // get option filter
    this.getTahunAjaranNow = this.getTahunAjaranNow.bind(this);
    this.getKelas = this.getKelas.bind(this);
    this.getRombel = this.getRombel.bind(this);
    this.getPeminatanSMA = this.getPeminatanSMA.bind(this);
    this.getDataFilterSMA = this.getDataFilterSMA.bind(this);
    this.getBidang = this.getBidang.bind(this);
    this.getProgram = this.getProgram.bind(this);
    this.getKompetensi = this.getKompetensi.bind(this);
    // show select filter
    this.showSelectFilterSD = this.showSelectFilterSD.bind(this);
    this.showSelectFilterSMA = this.showSelectFilterSMA.bind(this);
    this.showSelectFilterSMK = this.showSelectFilterSMK.bind(this);
    this.showSelectFilterALL = this.showSelectFilterALL.bind(this);

    this.redirectToSiswaRombel = this.redirectToSiswaRombel.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.unCheckAll = this.unCheckAll.bind(this);
    this.deleteMulti = this.deleteMulti.bind(this);
  }

  componentDidMount() {
    // get session user
    if (localStorage.getItem("token") !== null) {
      axios
        .post(`${conf.uri_backend}api/v1/auth/sso/user`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          this.setState({ data_session: res.data }, function() {
            axios
              .get(
                `${conf.uri_backend}api/v1/select-institusi/` +
                  this.state.data_session.access_to,
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                this.setState(
                  {
                    institusi_id_select: res.data.id,
                    jenjang_id_select: res.data.m_struktur_jenjang_id,
                  },
                  function() {
                    // console.log(this.state.jenjang_id_select);
                    this.setState(
                      {
                        url_show_data: `${this.state.url}`,
                        query:
                          "&user_institusi_id=" +
                          this.state.institusi_id_select,
                      },
                      function() {
                        this.dt.fetchEntities(this.state.query);
                      }
                    );
                    this.getTahunAjaranNow();
                    this.getKelas();
                    this.getPeminatanSMA("filter");
                    this.getDataFilterSMA();
                    this.getRombel();
                  }
                );
              });
          });
        });
    }
  }

  getTahunAjaranNow() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ data_tahun_ajaran: ta });
    // console.log(this.state.data_tahun_ajaran, "iki data tahun ajaran");
  }

  getKelas() {
    var kelas = [];
    if (this.state.jenjang_id_select == "2") {
      kelas = [
        { value: "I", label: "I" },
        { value: "II", label: "II" },
        { value: "III", label: "III" },
        { value: "IV", label: "IV" },
        { value: "V", label: "V" },
        { value: "VI", label: "VI" },
      ];
    } else if (this.state.jenjang_id_select == "3") {
      kelas = [
        { value: "VII", label: "VII" },
        { value: "VIII", label: "VIII" },
        { value: "IX", label: "IX" },
      ];
    } else if (this.state.jenjang_id_select == "4") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
      ];
    } else if (this.state.jenjang_id_select == "5") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
        { value: "XIII", label: "XIII" },
      ];
    }
    if (
      this.state.jenjang_id_select == "3" || this.state.jenjang_id_select == "2"
        ? this.setState({ loadingFilter: false })
        : ""
    );
    if (
      this.state.jenjang_id_select == "5"
        ? this.setState({ loadingFilter: false })
        : ""
    );
    this.setState({ data_kelas: kelas });
  }

  getRombel() {
    this.setState({ loading_rombel: !this.state.loading_rombel });
    var peminatan = "";
    if (
      this.state.jenjang_id_select == "5"
        ? (peminatan = this.state.kompetensi_search)
        : (peminatan = this.state.peminatan_search)
    );
    axios
      .get(
        `${this.state.url_get_rombel}?tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&peminatan=${peminatan}&user_institusi_id=${this.state.institusi_id_select}`,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ data_rombel: res.data, loading_rombel: false });
        this.setState({
          loadingFilter: false,
          loading_rombel: false,
        });
      });
  }

  getPeminatanSMA(type) {
    this.setState({ loading_peminatan_sma: !this.state.loading_peminatan_sma });
    var kur = "";
    if (type == "peminatan") kur = kurikulum_search;

    if (this.state.kurikulum) 
      kur = this.state.kurikulum;
    else 
      kur = this.state.kurikulum_search;

    if (this.state.jenjang_id_select == "4") {
      axios
        .get(
          this.state.url_get_spektrum +
            "?jenjang=" +
            this.state.jenjang_id_select +
            "&kurikulum=" +
            kur,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (res.data.status == true) {
            if (type == "filter") {
              this.setState({ data_peminatan: res.data.data });
              this.setState({ loading_peminatan_sma: false });
            } else {
              this.setState({ data_peminatan_input: res.data.data });
            }
          } else {
            if (type == "filter") {
              this.setState({ data_peminatan: [] });
            } else {
              this.setState({ data_peminatan_input: [] });
            }
          }
        })
        .catch((err) => {
          this.setState({ loading_peminatan_sma: false });
        });
    }
  }

  // SMK
  getBidang(create, update, kur_val) {
    if (this.state.jenjang_id_select == 5) {
      // console.log(this.state.kurikulum_search, "jancok")
      console.log("state kurikulum", this.state.kurikulum)
      // console.log(this.state.kurikulum_search, "kurikulum search")
      // console.log(this.state.kurikulum_select, "kurikulum select")
      this.setState({ loading_bidang: !this.state.loading_bidang });
      let kur = "";

      if (update) {
        kur = kur_val ?? this.state.kurikulum_select;
      } else if (create) {
        kur = kur_val ?? this.state.kurikulum;
      } else if (this.state.kurikulum_search) {
        kur = this.state.kurikulum_search;
      }


      axios
        .get(
          `${this.state.url_get_smk}?parent_id=0&jenjang_id=5&kurikulum=${kur}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (create != null || update != null) {
            this.setState({ data_bidang_p: res.data });
          } else {
            this.setState({ data_bidang: res.data });
            this.setState({ loading_bidang: false });
          }

          let data_bidang_keahlian =
            res?.data.map((e) => {
              return { value: e, label: e }
            }) ?? []

          this.setState({ select_bidang_keahlian: data_bidang_keahlian });
        })
        .catch((err) => {
          this.setState({ loading_bidang: false });
        });
    }
  }
  getProgram(create, update, value) {
    // console.log("Get Program");
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_program: !this.state.loading_program });
      var bidang = "";
      var kurikulum = "";

      if (update) {
        kurikulum = this.state.kurikulum_select;
        bidang    = this.state.bidang_select;
        if(value){bidang = value}
      } else if (create) {
        kurikulum = this.state.kurikulum;
        bidang = this.state.bidang;
        if(value){bidang = value}
      } else if (this.state.kurikulum_search && this.state.bidang_search) {
        kurikulum = this.state.kurikulum_search;
        bidang    = this.state.bidang_search;
      }

      axios
        .get(`${this.state.url_get_smk}?parent_id=${bidang}&jenjang_id=5&kurikulum=` + kurikulum, {
          headers: { Authorization: tokenApi },
        })
        .then((res) => {
          // console.log(this.state.kurikulum, "Kurikulum 1")
          // console.log(this.state.kurikulum_select, "Kurikulum 2")
          if (create != null || update != null) {
            this.setState({ data_program_p: res.data });
          } else {
            this.setState({ data_program: res.data });
            this.setState({ loading_program: false });
          }

          // console.log(this.state.peminatan_id, "jancok")

          this.setState({ select_program_keahlian: res.data });
        })
        .catch((err) => {
          this.setState({ loading_program: false });
        });
    }
  }
  getKompetensi(create, update, value) {
    // console.log("Get Kompetensi");
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_kompetensi: !this.state.loading_kompetensi });
      var program = "";
      var kur = "";
      

      if (update) {
        kur = this.state.kurikulum_select;
        program = this.state.program_select;
        if (program) {program = value}
      } else {
        kur = this.state.kurikulum;
        program = this.state.program;
        if (program) {program = value}
      }

      if (this.state.kurikulum_search && this.state.program_search && this.state.program == null) {
        kur     = this.state.kurikulum_search;
        program = this.state.program_search;
      }

      console.log("state program", this.state.program)
      console.log("state program select", this.state.program_select)
      console.log("state program search", this.state.program_search)

      axios
        .get(`${this.state.url_get_smk}?parent_id=${program}&jenjang_id=5&kurikulum=${kur}`, {
          headers: { Authorization: tokenApi },
        })
        .then((res) => {
          if (create != null || update != null) {
            this.setState({ data_kompetensi_p: res.data });
          } else {
            this.setState({ data_kompetensi: res.data });
            this.setState({ loading_kompetensi: false });
          }

          this.setState({ select_kompetensi_keahlian: res.data });
        })
        .catch((err) => {
          this.setState({ loading_kompetensi: false });
        });
    }
  }

  getDataFilterSMA() {
    axios
      .get(this.state.url_get_filter + "/" + this.state.institusi_id_select, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        if (res.data.status == true) {
          if (res.data.data != null) {
            this.setState(
              {
                tahun_ajaran_search:
                  res.data.data.tahun_ajaran != null
                    ? res.data.data.tahun_ajaran
                    : "",
                kelas_search:
                  res.data.data.kelas !== null ? res.data.data.kelas : "",
                kurikulum_search: res.data.data.kurikulum !== null ? res.data.data.kurikulum : "",
                bidang_search:
                  res.data.data.bidang_id !== null
                    ? res.data.data.bidang_id
                    : "",
                bidang_search_label:
                  res.data.data.bidang !== null ? res.data.data.bidang : "",
                program_search:
                  res.data.data.program_id !== null
                    ? res.data.data.program_id
                    : "",
                program_search_label:
                  res.data.data.program !== null ? res.data.data.program : "",
                kompetensi_search:
                  res.data.data.kompetensi_id !== null
                    ? res.data.data.kompetensi_id
                    : "",
                kompetensi_search_label:
                  res.data.data.kompetensi !== null
                    ? res.data.data.kompetensi
                    : "",
                peminatan_search:
                  res.data.data.kompetensi_id !== null
                    ? res.data.data.kompetensi_id
                    : "",
                peminatan_search_label:
                  res.data.data.kompetensi !== null
                    ? res.data.data.kompetensi
                    : "",
                rombel_search:
                  res.data.data.rombel_id !== null
                    ? res.data.data.rombel_id
                    : "",
                rombel_search_label:
                  res.data.data.rombel !== null ? res.data.data.rombel : "",
              },
              function() {
                this.getRombel();
                this.getBidang();
                this.getKompetensi();
                this.getPeminatanSMA("filter");
                this.getTahunAjaranNow();
                this.getKelas();
              }
            );
          }
        }
      });
  }

  // SHOW TYPE FILTER PER JENJANG
  showSelectFilterSD() {
      return (
        <>
          {/* Filter Tahun Ajaran */}
          {this.state.data_tahun_ajaran != "" ? (
            <div className="flex w-1/5">
              <div className="py-2 mr-2">
                <Select
                  id="filterTahunAjaran"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={this.state.tahun_ajaran_search != null ? {value: this.state.tahun_ajaran_search, label: this.state.tahun_ajaran_search} : ""}
                  placeholder={"Pilih Tahun Ajaran"}
                  options={this.state.data_tahun_ajaran}
                  onChange={this.filterTahunAjaran}
                />
              </div>
            </div>
          ) : ("")}
          {/* Filter Kurikulum */}
          <div className="flex w-1/5">
            <div className="py-2 mr-2">
              <Select
                id="filterKurikulum"
                styles={selectStyles}
                isClearable={true}
                options={dataKurikulum}
                placeholder={"Pilih Kurikulum"}
                defaultValue={this.state.data_kurikulum != "" ? {value: this.state.kurikulum_search, label: this.state.kurikulum_search} : ""}
                onChange={(e) => {
                  if (e) {
                    this.filterKurikulum(e);
                  } else {
                    this.filterKurikulum("");
                  }
                }}
              />
            </div>
          </div>
          {/* Filter Kelas */}
          {this.state.data_kelas != "" ? (
            <div className="flex w-1/5">
              <div className="py-2 mr-2">
                <Select
                  id="filterKelas"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={this.state.kelas_search != "" ? {value: this.state.kelas_search, label: this.state.kelas_search} : ""}
                  options={this.state.data_kelas}
                  onChange={this.filterKelas}
                  placeholder={"Pilih Kelas"}
                />
              </div>
            </div>
          ) : ("")}
        </>
      );
  }

  showSelectFilterSMA() {
    if (this.state.jenjang_id_select == "4" && this.state.data_peminatan) {
      return (
        <div className="flex">
          <div className="py-2 mr-2">
            <Select
              id="filterPeminatan"
              styles={selectStyles}
              isClearable={true}
              defaultValue={
                this.state.peminatan_search != ""
                  ? {
                      value: this.state.peminatan_search,
                      label: this.state.peminatan_search_label,
                    }
                  : ""
              }
              isLoading={this.state.loading_peminatan_sma ? true : false}
              options={this.state.data_peminatan}
              onChange={this.filterPeminatan}
              placeholder={"Pilih Peminatan"}
            />
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  showSelectFilterSMK() {
    if (this.state.jenjang_id_select == "5") {
      return (
        <>
          {this.state.kurikulum_search === "k13" ? (
            <>
              {this.state.kelas_search != "" ? (
                <>
                  <div className="py-2 ml-2">
                    <Select
                      id="filterBidang"
                      styles={selectStyles}
                      isClearable={true}
                      defaultValue={
                        this.state.bidang_search != ""
                          ? {
                              value: this.state.bidang_search,
                              label: this.state.bidang_search_label,
                            }
                          : ""
                      }
                      isLoading={this.state.loading_bidang ? true : false}
                      options={this.state.data_bidang}
                      onChange={this.filterBidang}
                      className="animateFadeInLeft"
                      placeholder={
                        this.state.loading_bidang
                          ? "Loading"
                          : "Bidang Keahlian"
                      }
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              {this.state.bidang_search != "" ? (
                <>
                  <div className="py-2 ml-2">
                    <Select
                      id="filterProgram"
                      styles={selectStyles}
                      isClearable={true}
                      defaultValue={
                        this.state.program_search != ""
                          ? {
                              value: this.state.program_search,
                              label: this.state.program_search_label,
                            }
                          : ""
                      }
                      isLoading={this.state.loading_program ? true : false}
                      options={this.state.data_program}
                      onChange={this.filterProgram}
                      placeholder={
                        this.state.loading_program
                          ? "Loading"
                          : "Program Keahlian"
                      }
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              {this.state.program_search != "" ? (
                <>
                  <div className="py-2 ml-2">
                    <Select
                      id="filterKompetensi"
                      styles={selectStyles}
                      isClearable={true}
                      defaultValue={
                        this.state.kompetensi_search != ""
                          ? {
                              value: this.state.kompetensi_search,
                              label: this.state.kompetensi_search_label,
                            }
                          : ""
                      }
                      isLoading={this.state.loading_kompetensi ? true : false}
                      options={this.state.data_kompetensi}
                      onChange={this.filterKompetensi}
                      placeholder={
                        this.state.loading_kompetensi
                          ? "Loading"
                          : "Kompetensi Keahlian"
                      }
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {this.state.kurikulum_search === "merdeka" ? (
            <>
              {this.state.kelas_search != "" ? (
                <>
                  <div className="py-2 ml-2">
                    <Select
                      id="filterBidang"
                      styles={selectStyles}
                      isClearable={true}
                      defaultValue={
                        this.state.bidang_search != ""
                          ? {
                              value: this.state.bidang_search,
                              label: this.state.bidang_search_label,
                            }
                          : ""
                      }
                      isLoading={this.state.loading_bidang ? true : false}
                      options={this.state.data_bidang}
                      onChange={this.filterBidang}
                      className="animateFadeInLeft"
                      placeholder={
                        this.state.loading_bidang
                          ? "Loading"
                          : "Bidang Keahlian"
                      }
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              {this.state.bidang_search != "" ? (
                <>
                  <div className="py-2 ml-2">
                    <Select
                      id="filterProgram"
                      styles={selectStyles}
                      isClearable={true}
                      defaultValue={
                        this.state.program_search != ""
                          ? {
                              value: this.state.program_search,
                              label: this.state.program_search_label,
                            }
                          : ""
                      }
                      isLoading={this.state.loading_program ? true : false}
                      options={this.state.data_program}
                      onChange={this.filterProgram}
                      placeholder={
                        this.state.loading_program
                          ? "Loading"
                          : "Program Keahlian"
                      }
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  showSelectFilterALL() {
    if (this.state.data_rombel) {
      return (
        <div className="flex">
          <div className="py-2 ml-2">
            <Select
              id="filterRombel"
              styles={selectStyles}
              isClearable={true}
              isLoading={this.state.loading_rombel ? true : false}
              defaultValue={
                this.state.rombel_search != ""
                  ? {
                      value: this.state.rombel_search,
                      label: this.state.rombel_search_label,
                    }
                  : ""
              }
              options={this.state.data_rombel}
              onChange={this.filterRombel}
              placeholder={"Pilih Rombel"}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex w-1/4">
          <div className="w-full px-1">
            <div className="pt-1">
              <Skeleton count={2} />
            </div>
          </div>
        </div>
      );
    }
  }

  urutanJenjang() {
    if (
      this.state.tahun_ajaran_search != "" &&
      this.state.kelas_search != "" &&
      this.state.rombel_search != ""
    ) {
      if (
        this.state.jenjang_id_select == "3" ||
        this.state.jenjang_id_select == "2"
          ? this.setState({ urutanFilter: true })
          : ""
      );
      if (
        this.state.jenjang_id_select == "4" && this.state.peminatan_search != ""
          ? this.setState({ urutanFilter: true })
          : ""
      );
      if (
        this.state.bidang_search != "" &&
        this.state.program_search != "" &&
        this.state.kompetensi_search != ""
      ) {
        if (
          this.state.jenjang_id_select == "5"
            ? this.setState({ urutanFilter: true })
            : ""
        );
      }
      // Jenjang SMK -> Kurikulum Merdeka
      if (this.state.jenjang_id_select == "5") {
        if (this.state.kurikulum_search == "merdeka" && this.state.bidang_search != "" && this.state.program_search != "") {
          this.setState({ urutanFilter: true })
        }
        // Jenjang SMK -> Kurikulum 2013
        if (this.state.kurikulum_search == "k13" && this.state.bidang_search != "" && this.state.program_search != "" && this.state.kompetensi_search != "") {
          this.setState({ urutanFilter: true })
        }
      }
    }
  }
  filterKurikulum(e) {
    if (e) {
      this.setState(
        {
          kurikulum_search: e.value,
          kelas_search: "",
          peminatan_search: "",
          rombel_search: "",
          data_kelas: "",
          data_peminatan: "",
          data_rombel: "",
        },
        function() {
          this.getPeminatanSMA("filter");
          this.getKelas()
          if (this.state.jenjang_id_select == "5") {
            this.getBidang()
          }
        }
      );
      // console.log(this.state.kurikulum_search, "motomu picek")
    } else {
      this.setState(
        {
          kurikulum_search: "",
          peminatan_search: "",
          kelas_search: "",
          rombel_search: "",
          data_kelas: "",
          data_peminatan: "",
          data_rombel: "",
        },
        function() {
          this.getPeminatanSMA("filter");
        }
      );
    }
  }

  // FILTER HANDLE CHANGE FUNCTION
  filterTahunAjaran(e) {
    if (e) {
      this.setState(
        {
          tahun_ajaran_search: e.value,
          data_kelas: "",
          data_kurikulum: "",
          data_peminatan: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          kelas_search: "",
          peminatan_search: "",
          rombel_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
        }, function () {
          this.setState({ kurikulum_search: "" })
        }
      );
      // console.log(this.state.data_tahun_ajaran, "silite tahun ajaran");
      // console.log(this.state.loadingFilter, "raimu asu");
    } else {
      this.setState({
        tahun_ajaran_search: "",
        data_kelas: "",
        data_kurikulum: "",
        data_peminatan: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        kelas_search: "",
        peminatan_search: "",
        rombel_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
      });
    }
  }

  filterKelas(e) {
    if (e) {
      this.setState(
        {
          kelas_search: e.value,
          data_peminatan: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          peminatan_search: "",
          rombel_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          if (this.state.jenjang_id_select == "5") {
            this.getBidang()
          }
          if (
            this.state.jenjang_id_select == "4"
              ? this.getPeminatanSMA("filter")
              : ""
          );
          if (
            this.state.jenjang_id_select == "2" ||
            this.state.jenjang_id_select == "3"
              ? this.getRombel()
              : ""
          );
        }
      );
    } else {
      this.setState(
        {
          kelas_search: "",
          data_peminatan: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          peminatan_search: "",
          rombel_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          if (
            this.state.jenjang_id_select == "3" ||
            this.state.jenjang_id_select == "2"
              ? this.getRombel()
              : ""
          );
          if (this.state.jenjang_id_select == "5") {
            this.getBidang()
          }
        }
      );
    }
  }

  filterPeminatan(e) {
    // only for SMA
    if (e) {
      this.setState(
        {
          peminatan_search: e.value,
          peminatan_search_label: e.label,
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          rombel_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          this.getRombel();
        }
      );
    } else {
      this.setState({
        peminatan_search: "",
        peminatan_search_label: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        bidang_search: "",
        rombel_search: "",
        program_search: "",
        kompetensi_search: "",
      });
    }
  }

  // only for SMK
  filterBidang(e) {
    if (e) {
      this.setState(
        {
          bidang_search: e.value,
          bidang_search_label: e.label,
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          rombel_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          this.getProgram();
        }
      );
    } else {
      this.setState({
        bidang_search: "",
        bidang_search_label: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        rombel_search: "",
        program_search: "",
        kompetensi_search: "",
      });
    }
  }
  filterProgram(e) {
    if (e) {
      this.setState(
        {
          program_search: e.value,
          program_search_label: e.label,
          data_kompetensi: "",
          data_rombel: "",
          rombel_search: "",
          kompetensi_search: "",
        },
        function() {
          if (this.state.kurikulum_search === "merdeka") {
            this.getRombel();
          } else {
            this.getKompetensi();
          }
        }
      );
    } else {
      this.setState({
        program_search: "",
        program_search_label: "",
        data_kompetensi: "",
        data_rombel: "",
        rombel_search: "",
        kompetensi_search: "",
      });
    }
  }
  filterKompetensi(e) {
    if (e) {
      this.setState(
        {
          kompetensi_search: e.value,
          kompetensi_search_label: e.label,
          rombel_search: "",
        },
        function() {
          if (this.state.kurikulum_search === "k13") {
            this.getRombel();
          }
        }
      );
    } else {
      this.setState({
        kompetensi_search: "",
        kompetensi_search_label: "",
        rombel_search: "",
      });
    }
  }

  filterRombel(e) {
    if (e) {
      this.setState(
        {
          rombel_search: e.value,
          rombel_search_label: e.label,
        },
        function() {
          this.urutanJenjang();
        }
      );
    } else {
      this.setState(
        {
          rombel_search: "",
          rombel_search_label: "",
        },
        function() {
          this.urutanJenjang();
        }
      );
    }
  }

  // create
  nama_rombel(e) {
    this.setState({ nama_rombel: e.target.value });
  }

  tahun_ajaran(e) {
    this.getWalas(e.value);
    this.setState({ tahun_ajaran: e.value }, function() {});
  }

  wali_kelas(e) {
    this.setState({ wali_kelas: e.value });
  }

  kelas(e) {
    this.setState({ kelas: e.value });
  }

  bidang(e) {
    // Ini untuk create
    if (this.state.kurikulum) {
      this.setState({ bidang: e.value }, function() {
        this.getProgram(true, false, e.value);
      });
    }

    // Ini untuk update
    if (this.state.kurikulum_select) {
      this.setState({ bidang_select: e.value }, function() {
        this.getProgram(false, true, e.value);
      })
    }
  }
  

  program(e) {
    // Ini untuk create
    if (this.state.kurikulum) {
      this.setState({ program: e.value }, function() {
        this.getKompetensi(true, false, e.value);
      });
    }

    // Ini untuk update
    if (this.state.kurikulum_select) {
      this.setState({ program_select: e.value }, function() {
        this.getKompetensi(false, true, e.value);
      })
    }
  }

  kurikulum(e) {
    if (e) {
      this.setState(
        {
          kurikulum: e.value,
        },
        function() {
          if (this.state.jenjang_id_select == "4") this.getPeminatanSMA("filter");
          this.getWalas(this.state.tahun_ajaran);
          this.getBidang(true, false, e.value);
        }
      );

      console.log(this.state.kurikulum, "state kurikulum");
      console.log(this.state.kurikulum_search, "state kurikulum search");
    } 
  }

  // Untuk Edit
  kurikulumUpdate(e) {
    if (e) {
      this.setState(
        {
          kurikulum_select: e.value,
        },
        function() {
          if (this.state.jenjang_id_select == "4") this.getPeminatanSMA("filter");
          this.getWalas(this.state.tahun_ajaran);
          this.getBidang(false, true, e.value);
        }
      );

      console.log(this.state.kurikulum, "state kurikulum");
      console.log(this.state.kurikulum_search, "state kurikulum search");
    } 
  }

  m_spektrum_id(e) {
    if (this.state.jenjang_id_select == "5") {
      if (this.state.kurikulum == 'merdeka' || this.state.kurikulum_select == 'merdeka') {
          this.setState({ program: e.value });
          this.getProgram();
      } else {
        this.setState({ m_spektrum_id: e.value });
      }
    } else {
      this.setState({ m_spektrum_id: e.value });
    }

  }

  // Create Funtion
  showModalTambah() {
    if (this.state.jenjang_id_select == "3")
      this.setState({ m_spektrum_id: 3 });
    if (this.state.jenjang_id_select == "2")
      this.setState({ m_spektrum_id: 2 });
    this.setState({ modal_tambah: true });
    if (this.state.jenjang_id_select == "5") {
      this.getBidang(true, false, null);
    }

    
  }

  hideModalTambah() {
    this.setState({
      nama_rombel: "",
      kurikulum: "",
      tahun_ajaran: "",
      m_spektrum_id: "",
      kelas: "",
      wali_kelas: "",
      data_wali_kelas: [],
      modal_tambah: false,
    });
  }

  alertMessage(e) {
    let message = null;

    switch (e.status) {
      case 422:
        message = e.data.messages[0];
        break;
      case 500:
        message =
          "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
        break;
      case 401:
        message =
          "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 400:
        message =
          "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 408:
        message =
          "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 429:
        message =
          "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
    }

    this.setState({
      show_alert: true,
      title_alert: "Gagal",
      icon_alert: "error",
      message_alert: message,
      simpleLoading: false,
    });
  }

  create(e) {
    e.preventDefault();
    this.setState({ loading_create: true });
    var m_spektrum = "";

    if (this.state.jenjang_id_select == "5" && this.state.kurikulum === "merdeka") {
      m_spektrum = this.state.program
    } else {
      m_spektrum = this.state.m_spektrum_id
    }

    axios
      .post(
        this.state.url,
        {
          nama_rombel: this.state.nama_rombel,
          tahun_ajaran: this.state.tahun_ajaran,
          user_institusi_id: this.state.institusi_id_select,
          // m_spektrum_id: this.state.m_spektrum_id,
          m_spektrum_id: m_spektrum,
          kelas: this.state.kelas,
          penugasan_guru_id: this.state.wali_kelas,
          jenjang_id: this.state.jenjang_id_select,
          kurikulum: this.state.kurikulum,
          created_at: new Date(),
          created_by: this.state.institusi_id_select,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({
          show_alert: true,
          title_alert: "Berhasil",
          icon_alert: "success",
          message_alert: res.data.messages[0],
          loading_create: false,
        });
        this.hideModalTambah();
        this.dt.fetchEntities(
          "&user_institusi_id=" + this.state.institusi_id_select
        );
      })
      .catch((err) => {
        this.setState({ loading_create: false });
        this.alertMessage(err.response);
      });
  }

  // Edit Rombel
  showModalEdit(data) {
    this.setState({ modal_edit: true, loading_edit: true });
    axios
      .get(this.state.url_get_edit + data, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        this.setState(
          {
            id: res.data.data.rombel_id,
            nama_rombel: res.data.data.nama_rombel,
            tahun_ajaran: res.data.data.tahun_ajaran,
            select_wali: res.data.data.nama_wali,
            select_id_guru: res.data.data.id_penugasan,
            kurikulum_select: res.data.data.kurikulum,
            select_id_spektrum: res.data.data.peminatan_id,
            select_nama_peminatan: res.data.data.nama_peminatan,
            select_kelas: res.data.data.kelas,
            select_bidang_keahlian_id: res.data.data.bidang_keahlian_id,
            select_bidang_keahlian: res.data.data.nama_bidang_keahlian,
            select_program_keahlian_id: res.data.data.kategori_keahlian_id,
            select_program_keahlian: res.data.data.nama_kategori_keahlian,
            select_kompetensi_keahlian_id: res.data.data.peminatan_id,
            select_kompetensi_keahlian: res.data.data.nama_peminatan,
          },
          function() {
            this.setState({ loading_edit: false });
            if (this.state.jenjang_id_select == '5') {
              this.getBidang(false, true, this.state.kurikulum_select);
            }
            this.getWalas(this.state.tahun_ajaran);
          }
        ); 

        // console.log("state kurikulum", this.state.kurikulum)
        // console.log(this.state.kurikulum_select, "anjing")
        // console.log(res.data.data, "Datane");
        // console.log(this.state.select_bidang_keahlian, "ini Bidang Keahlian")
        // console.log(this.state.select_program_keahlian, "ini program keahlian")
        // console.log(this.state.select_kompetensi_keahlian, "ini Kompetensi Keahlian")
      });
      
  }

  update(event) {
    event.preventDefault();
    this.setState({ loading_saveedit: true });
    var m_spektrum = "";
    var informasi_user_guru_id = "";
    var kelas = "";
    var kurikulum = "";
    var bidang = "";
    var program = "";
    var kompetensi = "";

    
    if(this.state.m_spektrum_id != '' ? m_spektrum = this.state.m_spektrum_id : m_spektrum = this.state.select_id_spektrum);
    if(this.state.kelas != '' ? kelas = this.state.kelas : kelas = this.state.select_kelas);
    if(this.state.wali_kelas != '' ? informasi_user_guru_id = this.state.wali_kelas : informasi_user_guru_id = this.state.select_id_guru);
    if(this.state.kurikulum != '' ? kurikulum = this.state.kurikulum : kurikulum = this.state.kurikulum_select); 
    
    if (this.state.jenjang_id_select == "5" && this.state.kurikulum === "merdeka" || this.state.kurikulum_select === "merdeka" ? m_spektrum = this.state.program : '');
    if(this.state.jenjang_id_select == '3' ? m_spektrum = 3 : '');
    if(this.state.jenjang_id_select == '2' ? m_spektrum = 2 : '');


    axios
      .put(
        this.state.url_put + this.state.id,
        {
          nama_rombel: this.state.nama_rombel,
          tahun_ajaran: this.state.tahun_ajaran,
          user_institusi_id: this.state.institusi_id_select,
          m_spektrum_id: m_spektrum,
          kelas: kelas,
          penugasan_guru_id: informasi_user_guru_id,
          kurikulum: kurikulum,
          bidang_keahlian: this.state.select_bidang_keahlian_id,
          program_keahlian: this.state.select_program_keahlian_id,
          kompetensi_keahlian: this.state.select_kompetensi_keahlian_id,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({
          show_alert: true,
          title_alert: "Berhasil",
          icon_alert: "success",
          message_alert: res.data.messages[0],
          loading_saveedit: false,
        });
        this.hideModalEdit();
        this.dt.fetchEntities(
          "&user_institusi_id=" + this.state.institusi_id_select
        );
        // console.log(res.data);
      })
      .catch((err) => {
        this.setState({ loading_saveedit: false });
        this.alertMessage(err.response);
      });
  }

  updatec(event) {
    event.preventDefault();
    this.setState({ loading_saveedit: true });
    var m_spektrum = "";
    var informasi_user_guru_id = "";
    var kelas = "";

    if (
      this.state.m_spektrum_id != ""
        ? (m_spektrum = this.state.m_spektrum_id)
        : (m_spektrum = this.state.select_id_spektrum)
    );
    if (
      this.state.kelas != ""
        ? (kelas = this.state.kelas)
        : (kelas = this.state.select_kelas)
    );
    if (
      this.state.wali_kelas != ""
        ? (informasi_user_guru_id = this.state.wali_kelas)
        : (informasi_user_guru_id = this.state.select_id_guru)
    );

    if (this.state.jenjang_id_select == "3" ? (m_spektrum = 3) : "");
    if (this.state.jenjang_id_select == "2" ? (m_spektrum = 2) : "");

    axios
      .post(
        this.state.url_update,
        {
          guru_penugasan_id: this.state.wali_kelas,
          guru_id: this.state.guru_id,
          id: this.state.id,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({
          show_alert: true,
          title_alert: "Berhasil",
          icon_alert: "success",
          loading_saveedit: false,
        });
        this.hideModalEditc();
        this.dt.fetchEntities(
          "&user_institusi_id=" + this.state.institusi_id_select
        );
      })
      .catch((err) => {
        this.setState({ loading_saveedit: false });
        this.alertMessage(err.response);
      });
  }

  hideModalEdit() {
    this.setState({
      data_wali_kelas: [],
      id: "",
      nama_rombel: "",
      tahun_ajaran: "",
      select_wali: "",
      select_id_guru: "",
      select_id_spektrum: "",
      kurikulum_select: "",
      select_nama_peminatan: "",
      select_kelas: "",
      select_bidang_keahlian: "",
      select_program_keahlian: "",
      select_kompetensi_keahlian: "",
      modal_edit: false,
    });
  }

  // modal Pelajari
  showModalPelajari() {
    this.setState({ modal_pelajari: true });
  }

  hideModalPelajari() {
    this.setState({ modal_pelajari: false });
  }

  getWalas(tahun_ajaran, wali) {
    if (tahun_ajaran !== null) {
      axios
        .post(
          this.state.url_get_wali + this.state.institusi_id_select,
          {
            tahun_ajaran: tahun_ajaran,
            status: wali,
            kurikulum: this.state.kurikulum,
          },
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (res.data.status == true) {
            this.setState({ data_wali_kelas: res.data.data });
            this.setState({
              note_wali_kelas:
                "Pilih terlebih dahulu Tahun ajaran sebelum memilih wali kelas",
            });
          } else {
            this.setState({ data_wali_kelas: [] });
            this.setState({
              note_wali_kelas:
                "Semua guru yang ditugaskan sebagai wali kelas tahun ajaran tersebut sudah terdaftar dikelas, silahkan tugaskan guru di menu Penugasan Guru",
            });
          }
        });
    } else {
      var kosong = [];
      kosong.push({ value: null, label: "Pilih Tahun Ajaran Terlebih dahulu" });
      this.setState({ data_wali_kelas: kosong });
    }
  }

  delete(data) {
    this.setState({ loading_delete: true });

    swal
      .fire({
        title: "Peringatan",
        text: "Apakah anda yakin menghapus rombel tersebut?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Iya`,
        cancelButtonText: "Batal",
      })
      .then((result) => {
        if (result.value == true) {
          axios
            .delete(this.state.url + "/" + data, {
              headers: { Authorization: tokenApi },
            })
            .then((res) => {
              this.setState({
                show_alert: true,
                title_alert: "Berhasil",
                icon_alert: "success",
                message_alert: res.data.messages[0],
                loading_delete: false,
              });
              this.hideModalEdit();
              this.dt.fetchEntities(
                "&user_institusi_id=" + this.state.institusi_id_select
              );
            })
            .catch((err) => {
              this.alertMessage(err.response);
              this.setState({ loading_delete: false });
            });
        } else if (result.dismiss == "cancel") {
          this.setState({ loading_delete: false });
        } else if (result.dismiss == "backdrop") {
          this.setState({ loading_delete: false });
        }
      });
  }

  checkAll(e) {
    e.preventDefault();
    this.setState({ loading_check: true }, function() {
      this.setState({ checkAll: true, loading_check: false });
    });
  }

  unCheckAll(e) {
    e.preventDefault();
    this.setState({ loading_check: true }, function() {
      this.setState({ checkAll: false, loading_check: false });
    });
  }

  deleteMulti() {
    this.setState({ loading_delete: true });
    var checkboxes = document.getElementsByName("check_data[]");
    var vals = [];
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    if (vals.length == 0) {
      this.setState({
        show_alert: true,
        icon_alert: "warning",
        title_alert: "Gagal",
        message_alert: "Mohon centang data terlebih dahulu",
        disabledFilter: false,
        loading_delete: false,
      });
    } else {
      swal
        .fire({
          title: "Peringatan",
          text: "Apakah anda yakin menghapus rombel yang dipilih?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Iya`,
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value == true) {
            axios
              .post(
                this.state.url_delete_m,
                { data: vals },
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                if (res.data.status == false) {
                  this.setState({
                    show_alert: true,
                    title_alert: "Gagal",
                    icon_alert: "error",
                    message_alert: res.data.messages[0],
                    loading_delete: false,
                  });
                  this.dt.resetPage(this.state.query);
                } else {
                  this.setState({
                    show_alert: true,
                    title_alert: "Berhasil",
                    icon_alert: "success",
                    message_alert: res.data.messages[0],
                    loading_delete: false,
                  });
                  this.dt.resetPage(this.state.query);
                }
              })
              .catch((err) => {
                this.setState({ loading_delete: false });
                this.alertMessage(err.response);
              });
          } else if (result.dismiss == "cancel") {
            this.setState({ loading_delete: false });
          } else if (result.dismiss == "backdrop") {
            this.setState({ loading_delete: false });
          }
        });
    }
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({ loading_button: true });
    var kompetensi = "";
    var kompetensi_label = "";
    var program = "";
    var program_label = "";

    // For SMK level
    if (this.state.jenjang_id_select == "5" && this.state.kurikulum_search === "k13") {
      kompetensi = this.state.kompetensi_search;
      kompetensi_label = this.state.kompetensi_search_label;
    } else if (this.state.jenjang_id_select == "5" && this.state.kurikulum_search === "merdeka") {
      // For select kurikulum merdeka
      program = this.state.program_search;
      program_label = this.state.program_search_label;
    } else {
      // For another level
      kompetensi = this.state.peminatan_search;
      kompetensi_label = this.state.peminatan_search_label;
    }
    axios
      .put(
        this.state.url_get_filter + "/" + this.state.institusi_id_select,
        {
          tahun_ajaran: this.state.tahun_ajaran_search,
          kelas: this.state.kelas_search,
          bidang: this.state.bidang_search_label,
          bidang_id: this.state.bidang_search,
          program: program_label,
          program_id: program,
          kompetensi: kompetensi_label,
          kompetensi_id: kompetensi,
          rombel_id: this.state.rombel_search,
          rombel: this.state.rombel_search_label,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        // console.log("done");
        this.setState({ loading_button: false });
      });

    var m_spektrum = "";
    if (
      this.state.jenjang_id_select == "5"
        ? (m_spektrum = this.state.kompetensi_search)
        : (m_spektrum = this.state.peminatan_search)
    );
    // For Kurikulum Merdeka
    if (
      this.state.jenjang_id_select == "5" &&
      this.state.kurikulum_search === "merdeka"
        ? (m_spektrum = this.state.program_search)
        : (m_spektrum = this.state.peminatan_search)
    );

    this.setState(
      {
        url_show_data: `${this.state.url}`,
        query:
          "&query=&tahun_ajaran=" +
          this.state.tahun_ajaran_search +
          "&kelas=" +
          this.state.kelas_search +
          "&m_spektrum_id=" +
          m_spektrum +
          "&rombel_id=" +
          this.state.rombel_search +
          "&kurikulum=" +
          this.state.kurikulum +
          "&user_institusi_id=" +
          this.state.institusi_id_select,
        search: true,
      },
      function() {
        this.dt.loading();
        this.dt.resetPage(this.state.query);
      }
    );
  }

  setResetFilter(e) {
    e.preventDefault();
    // document.getElementById('filterAll');
    this.setState(
      {
        data_tahun_ajaran: "",
        data_kelas: "",
        data_kurikulum: "",
        data_peminatan: "",
        data_rombel: "",
        searchText: "",
        tahun_ajaran_search: "",
        kelas_search: "",
        peminatan_search: "",
        peminatan_search_label: "",
        rombel_search: "",
        rombel_search_label: "",
      },
      function() {
        this.getTahunAjaranNow();
        this.getKelas();
        this.getPeminatanSMA("filter");
        this.getRombel();
        this.getBidang();
        this.setState(
          {
            url_show_data: `${this.state.url}`,
            query: "&user_institusi_id=" + this.state.institusi_id_select,
          },
          function() {
            this.dt.fetchEntities(this.state.query);
          }
        );
      }
    );
  }

  // SEARCH TEXT
  searchText(e) {
    e.preventDefault();
    if (this.state.url_show_data !== "") {
      var m_spektrum = "";
      if (
        this.state.jenjang_id_select == "5"
          ? (m_spektrum = this.state.kompetensi_search)
          : (m_spektrum = this.state.peminatan_search)
      );
      // For select kurikulum merdeka
      if (
        this.state.jenjang_id_select == "5" &&
        this.state.kurikulum_search === "merdeka"
          ? (m_spektrum = this.state.program_search)
          : (m_spektrum = this.state.peminatan_search)
      );

      if (this.state.showFilter) {
        this.setState(
          {
            query:
              "&query=" +
              document.getElementById("search").value +
              "&tahun_ajaran=" +
              this.state.tahun_ajaran_search +
              "&kelas=" +
              this.state.kelas_search +
              "&m_spektrum_id=" +
              m_spektrum +
              "&rombel_id=" +
              this.state.rombel_search +
              "&user_institusi_id=" +
              this.state.institusi_id_select,
            searchText: e.target.value,
          },
          function() {
            this.dt.loading();
            this.dt.resetPage(this.state.query);
          }
        );
      } else {
        this.setState(
          {
            query:
              "&query=" +
              document.getElementById("search").value +
              "&user_institusi_id=" +
              this.state.institusi_id_select,
            searchText: e.target.value,
          },
          function() {
            this.dt.loading();
            this.dt.resetPage(this.state.query);
          }
        );
      }
    }
  }

  redirectToSiswaRombel(data) {
    this.setState({ loading_redirect: data });
    axios
      .put(
        this.state.url_put_filter_rombel + "/" + this.state.institusi_id_select,
        {
          tahun_ajaran: data.tahun_ajaran,
          kelas: data.kelas,

          //   bidang        : data.bidang,
          //   bidang_id     : data.bidang_id,
          //   program       : data.program,
          //   program_id    : data.program_id,

          kompetensi: data.nama_spektrum,
          kompetensi_id: data.m_spektrum_id,

          rombel_id: data.id,
          rombel: data.nama_rombel,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ loading_redirect: false });
        window.location.replace(`${conf.uri_frontend}rombel/siswa-rombel`);
      });
  }

  ModalWaliKelas(data) {
    this.setState({ modal_editc: true, loading_edit: true });
    axios
      .get(this.state.url_get_edit + data, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        this.setState(
          {
            id: res.data.data.rombel_id,
            // nama_rombel           : res.data.data.nama_rombel,
            tahun_ajaran: res.data.data.tahun_ajaran,
            select_wali: res.data.data.nama_wali,

            // select_id_guru        : res.data.data.id_penugasan,
            // select_id_spektrum    : res.data.data.peminatan_id,
            // select_nama_peminatan : res.data.data.nama_peminatan,
            // select_kelas          : res.data.data.kelas,
            loading_edit: false,
            guru_id: res.data.data.penugasan_guru_id,
          },
          function() {
            this.getWalas(this.state.tahun_ajaran, "wali");
          }
        );
      });
  }

  hideModalEditc() {
    this.setState({
      modal_editc: false,
      // nama_rombel     : '',
      tahun_ajaran: "",
      // m_spektrum_id   : '',
      // kelas           : '',
      wali_kelas: "",
      data_wali_kelas: [],
      modal_tambah: false,
      guru_id: "",
    });
  }

  showData() {
    return (
      <>
        <div className="px-1 py-1">
          <DataTable
            ref={(dt) => {
              this.dt = dt;
            }}
            action={{
              nama_rombel: (col, data) => {
                return (
                  <button
                    onClick={this.redirectToSiswaRombel.bind(this, data)}
                    className="btn-schoolmedia text-xs text-white py-1 px-2 rounded-full font-medium mr-1"
                    disabled={this.state.loading_redirect === data ? true : false}
                  >
                    {this.state.loading_redirect === data
                      ? "Tunggu..."
                      : data.nama_rombel}
                  </button>
                );
              },
              aksi: (col, data) => {
                return (
                  <div className="text-gray-700 px-4 py-1 flex items-center">
                    <button
                      onClick={this.showModalEdit.bind(this, data.id)}
                      className="btn-yellow text-lg text-white py-1 px-2 rounded-full font-medium mr-1"
                    >
                      <i className="bx bx-edit"></i>
                    </button>
                    <button
                      onClick={this.ModalWaliKelas.bind(this, data.id)}
                      className="btn-green text-sm text-white py-1 px-2 rounded-full font-medium mr-1"
                    >
                      Pindah
                    </button>
                    <button
                      onClick={this.delete.bind(this, data.id)}
                      className={`${
                        this.state.loading_delete === data.id
                          ? `btn-gray`
                          : `btn-red`
                      } text-lg text-white py-1 px-2 rounded-full font-medium`}
                      // disabled={this.state.loading_delete === datax.id ? true : false}
                      disabled={data.siswa_count != 0 ? true : false}
                    >
                      {this.state.loading_delete === data.id ? (
                        <UserButtonLoading loadingText="" />
                      ) : (
                        <i className="bx bx-trash"></i>
                      )}
                    </button>
                    {this.state.loading_check == false ? (
                      <input
                        type="checkbox"
                        name="check_data[]"
                        id={data.id}
                        value={data.id}
                        onChange={this.selectOne}
                        className="select-checkbox ml-2"
                        defaultChecked={this.state.checkAll}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              },
            }}
            url={this.state.url_show_data}
            columns={[
              {
                index: "tahun_ajaran",
                label: "TAHUN AJARAN",
              },
              {
                index: "kelas",
                label: "KELAS",
              },
              {
                index: "nama_rombel",
                label: "ROMBEL",
              },
              {
                index: "kurikulum",
                label: "KURIKULUM",
              },
              {
                index: "siswa_count",
                label: "JUMLAH",
              },
              {
                index: "nama_institusi",
                label: "INSTITUSI",
              },
              {
                index: "penugasan_guru_nama",
                label: "WALI KELAS",
              },
              {
                index: "nama_spektrum",
                label: "JURUSAN",
              },
              {
                index: "aksi",
                label: "AKSI",
              },
            ]}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div>
        <div className="sm-container shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border">
          <div className="flex justify-between items-center mt-2  px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Rombongan Belajar Per Tahun Ajaran
              <span className="text-gray-500 font-thin block text-sm">
                Daftar Rombongan Belajar Per Tahun ajaran.{" "}
                {/* <a href="#" onClick={this.showModalPelajari} className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
            <div className="flex">
              <button
                className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm"
                onClick={this.showModalTambah}
              >
                <i className="bx bx-plus text-lg mr-1"></i>
                Tambah Rombel Per TA
              </button>
            </div>
          </div>
        </div>

        {/* alert */}
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />

        <Modal
          show={this.state.modal_pelajari}
          className="modal-pelajari"
          onHide={this.hideModalPelajari}
        >
          <Modal.Body>
            <Carousel autoPlay={false} interval={null}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://static.schoolmedia.id/public/assets/um/sample/langkah-1.svg"
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://static.schoolmedia.id/public/assets/um/sample/langkah-2.svg"
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://static.schoolmedia.id/public/assets/um/sample/langkah-3.svg"
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://static.schoolmedia.id/public/assets/um/sample/langkah-4.svg"
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalPelajari}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        {/* modal edit */}
        <Modal show={this.state.modal_edit} onHide={this.hideModalEdit}>
          <Modal.Header>Edit Rombel</Modal.Header>
          {this.state.loading_edit ? (
            <UserEditSkeleton />
          ) : (
            <Modal.Body>
              <form onSubmit={this.update}>
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">
                    Tahun Ajaran
                  </label>
                  <Select
                    styles={selectStyles}
                    className="react-select__control"
                    defaultValue={{
                      value: this.state.tahun_ajaran,
                      label: this.state.tahun_ajaran,
                    }}
                    options={this.state.data_tahun_ajaran}
                    onChange={this.tahun_ajaran}
                  />
                </div>
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">
                    Kelas
                  </label>
                  <Select
                    styles={selectStyles}
                    className="react-select__control"
                    placeholder="Pilih Kelas"
                    defaultValue={{
                      value: this.state.select_kelas,
                      label: this.state.select_kelas,
                    }}
                    options={this.state.data_kelas}
                    onChange={this.kelas}
                  />
                </div>
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">
                    Kurikulum
                  </label>
                  <Select
                    styles={selectStyles}
                    className="react-select__control"
                    placeholder="Pilih Kurikulum"
                    // isMulti
                    defaultValue={{
                      value: this.state.kurikulum_select,
                      label:
                        this.state.kurikulum_select == null
                          ? ""
                          : captFirstLetter(this.state.kurikulum_select),
                    }}
                    options={dataKurikulum}
                    onChange={this.kurikulumUpdate}
                  />
                </div>
                {this.state.jenjang_id_select == 4 ? (
                  <div className="w-full mt-2">
                    <label className="mb-2 ml-2 text-gray-800 block text-sm">
                      Peminatan
                    </label>
                    <Select
                      styles={selectStyles}
                      className="react-select__control"
                      placeholder="Pilih Peminatan"
                      defaultValue={{
                        value: this.state.select_id_spektrum,
                        label: this.state.select_nama_peminatan,
                      }}
                      options={this.state.data_peminatan}
                      onChange={this.m_spektrum_id}
                    />
                  </div>
                ) : (
                  ""
                )}
                <>
                  {this.state.jenjang_id_select == 5 && this.state.kurikulum_select == "k13" ? (
                    <>
                      <div className="w-full mt-2">
                        <label className="mb-2 ml-2 text-gray-800 block text-sm">
                          Bidang Keahlian
                        </label>
                        <Select
                          styles={selectStyles}
                          className="react-select__control"
                          placeholder="Pilih Bidang Keahlian"
                          defaultValue={{
                            value: this.state.select_bidang_keahlian_id,
                            label: this.state.select_bidang_keahlian,
                          }}
                          options={this.state.data_bidang_p}
                          onChange={this.bidang}
                        />
                      </div>
                      <div className="w-full mt-2">
                        <label className="mb-2 ml-2 text-gray-800 block text-sm">
                          Program Keahlian
                        </label>
                        <Select
                          styles={selectStyles}
                          className="react-select__control"
                          placeholder="Pilih Program Keahlian"
                          defaultValue={{
                            value: this.state.select_program_keahlian_id,
                            label: this.state.select_program_keahlian,
                          }}
                          options={this.state.data_program_p}
                          onChange={this.program}
                        />
                      </div>
                      <div className="w-full mt-2">
                        <label className="mb-2 ml-2 text-gray-800 block text-sm">
                          Kompetensi Keahlian
                        </label>
                        <Select
                          styles={selectStyles}
                          className="react-select__control"
                          placeholder="Pilih Kompetensi Keahlian"
                          defaultValue={{
                            value: this.state.select_kompetensi_keahlian_id,
                            label: this.state.select_kompetensi_keahlian,
                          }}
                          options={this.state.data_kompetensi_p}
                          onChange={this.m_spektrum_id}
                        />
                      </div>
                    </>
                  ) : this.state.jenjang_id_select == 5 && this.state.kurikulum_select == "merdeka" ? (
                    <>
                      <div className="w-full mt-2">
                        <label className="mb-2 ml-2 text-gray-800 block text-sm">
                          Bidang Keahlian
                        </label>
                        <Select
                          styles={selectStyles}
                          className="react-select__control"
                          placeholder="Pilih Bidang Keahlian"
                          defaultValue={{
                            value: this.state.select_bidang_keahlian_id,
                            label: this.state.select_bidang_keahlian,
                          }}
                          options={this.state.data_bidang_p}
                          onChange={this.bidang}
                        />
                      </div>
                      <div className="w-full mt-2">
                        <label className="mb-2 ml-2 text-gray-800 block text-sm">
                          Program Keahlian
                        </label>
                        <Select
                          styles={selectStyles}
                          className="react-select__control"
                          placeholder="Pilih Program Keahlian"
                          defaultValue={{
                            value: this.state.select_program_keahlian_id,
                            label: this.state.select_program_keahlian,
                          }}
                          options={this.state.data_program_p}
                          onChange={this.m_spektrum_id}
                        />
                      </div>
                    </>
                  ) : ("")}
                </>
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">
                    Pilih Wali Kelas
                  </label>
                  <Select
                    styles={selectStyles}
                    className="react-select__control"
                    defaultValue={{
                      value: this.state.select_id_guru,
                      label: this.state.select_wali,
                    }}
                    options={this.state.data_wali_kelas}
                    onChange={this.wali_kelas}
                  />
                </div>
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">
                    Nama Rombel
                  </label>
                  <input
                    type="text"
                    placeholder="Masukkan Nama Rombel"
                    value={this.state.nama_rombel}
                    className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                    onChange={this.nama_rombel}
                  />
                </div>
                {this.state.loading_saveedit ? (
                  <button
                    className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                    disabled={
                      this.state.loading_saveedit == true ? true : false
                    }
                  >
                    <UserButtonLoading />
                  </button>
                ) : (
                  <input
                    type="submit"
                    className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                    value="Edit"
                  />
                )}
              </form>
            </Modal.Body>
          )}
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalEdit}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modal_editc} onHide={this.hideModalEditc}>
          <Modal.Header>Edit Rombel</Modal.Header>
          {this.state.loading_edit ? (
            <UserEditSkeleton />
          ) : (
            <Modal.Body>
              <form onSubmit={this.updatec}>
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">
                    Pilih Wali Kelas
                  </label>
                  <Select
                    styles={selectStyles}
                    className="react-select__control"
                    defaultValue={{
                      value: this.state.select_id_guru,
                      label: this.state.select_wali,
                    }}
                    options={this.state.data_wali_kelas}
                    onChange={this.wali_kelas}
                  />
                </div>

                {this.state.loading_saveedit ? (
                  <button
                    className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                    disabled={
                      this.state.loading_saveedit == true ? true : false
                    }
                  >
                    <UserButtonLoading />
                  </button>
                ) : (
                  <input
                    type="submit"
                    className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                    value="Edit"
                  />
                )}
              </form>
            </Modal.Body>
          )}
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalEditc}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        {/* modal tambah */}
        <Modal show={this.state.modal_tambah} onHide={this.hideModalTambah}>
          <Modal.Header>Tambah Rombel per Tahun Ajaran</Modal.Header>
          <Modal.Body>
            <form onSubmit={this.create}>
              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Tahun Ajaran
                </label>
                <Select
                  styles={selectStyles}
                  className="react-select__control"
                  placeholder="Pilih Tahun Ajaran"
                  options={this.state.data_tahun_ajaran}
                  onChange={this.tahun_ajaran}
                />
              </div>
              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Kelas
                </label>
                <Select
                  styles={selectStyles}
                  className="react-select__control"
                  placeholder="Pilih Kelas"
                  options={this.state.data_kelas}
                  onChange={this.kelas}
                />
              </div>
              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Kurikulum
                </label>
                <Select
                  styles={selectStyles}
                  className="react-select__control"
                  placeholder="Pilih Kurikulum"
                  options={dataKurikulum}
                  onChange={this.kurikulum}
                />
              </div>
              {this.state.jenjang_id_select == 4 ? (
                <>
                  <div className="w-full mt-2">
                    <label className="mb-2 ml-2 text-gray-800 block text-sm">
                      Peminatan
                    </label>
                    <Select
                      styles={selectStyles}
                      className="react-select__control"
                      placeholder="Pilih Peminatan"
                      options={this.state.data_peminatan}
                      onChange={this.m_spektrum_id}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              {this.state.jenjang_id_select == 5 ? (
                <>
                  {this.state.kurikulum === "k13" ? (
                    <>
                      <div className="w-full mt-2">
                        <label className="mb-2 ml-2 text-gray-800 block text-sm">
                          Bidang Keahlian
                        </label>
                        <Select
                          styles={selectStyles}
                          className="react-select__control"
                          placeholder="Pilih Bidang Keahlian"
                          options={this.state.data_bidang_p}
                          onChange={this.bidang}
                        />
                      </div>
                      <div className="w-full mt-2">
                        <label className="mb-2 ml-2 text-gray-800 block text-sm">
                          Program Keahlian
                        </label>
                        <Select
                          styles={selectStyles}
                          className="react-select__control"
                          placeholder="Pilih Program Keahlian"
                          options={this.state.data_program_p}
                          onChange={this.program}
                        />
                      </div>
                      <div className="w-full mt-2">
                        <label className="mb-2 ml-2 text-gray-800 block text-sm">
                          Kompetensi Keahlian
                        </label>
                        <Select
                          styles={selectStyles}
                          className="react-select__control"
                          placeholder="Pilih Kompetensi Keahlian"
                          options={this.state.data_kompetensi_p}
                          onChange={this.m_spektrum_id}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {this.state.kurikulum === "merdeka" ? (
                    <>
                      <div className="w-full mt-2">
                        <label className="mb-2 ml-2 text-gray-800 block text-sm">
                          Bidang Keahlian
                        </label>
                        <Select
                          styles={selectStyles}
                          className="react-select__control"
                          placeholder="Pilih Bidang Keahlian"
                          options={this.state.data_bidang_p}
                          onChange={this.bidang}
                        />
                      </div>
                      <div className="w-full mt-2">
                        <label className="mb-2 ml-2 text-gray-800 block text-sm">
                          Program Keahlian
                        </label>
                        <Select
                          styles={selectStyles}
                          className="react-select__control"
                          placeholder="Pilih Program Keahlian"
                          options={this.state.data_program_p}
                          onChange={this.m_spektrum_id}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Pilih Wali Kelas
                </label>
                <Select
                  styles={selectStyles}
                  placeholder="Pilih Wali Kelas"
                  options={this.state.data_wali_kelas}
                  className="react-select__control"
                  onChange={this.wali_kelas}
                  disabled
                />
                <p className="text-xs leading-tight mt-1 text-gray-700 ml-2">
                  {this.state.note_wali_kelas}
                </p>
              </div>
              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Nama Rombel
                </label>
                <input
                  type="text"
                  placeholder="Masukkan Nama Rombel"
                  className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                  onChange={this.nama_rombel}
                />
              </div>
              {this.state.loading_create ? (
                <button
                  className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                  disabled={this.state.loading_create === true ? true : false}
                >
                  <UserButtonLoading />
                </button>
              ) : (
                <input
                  type="submit"
                  className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                  value="Simpan"
                />
              )}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalTambah}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        <div className="sm-container shadow-md mx-auto py-3 mt-2 rounded-lg bg-white border">
          <div className="flex flex-row items-baseline px-3">
            <button
              className="btn-blue px-4 mr-2 flex items-center rounded-full text-white py-2 text-xs"
              onClick={() => {
                this.setState({
                  showFilter: !this.state.showFilter,
                });
              }}
            >
              <i className="bx bx-filter text-lg mr-1"></i>
              Pilih
            </button>
            <div className="flex w-full flex-wrap items-center my-1 px-3 pb-2 pt-2">
              <input
                type="search"
                className="w-1/4 pl-2 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Cari"
                id="search"
                onChange={this.searchText}
              />
            </div>
          </div>
          {this.state.showFilter ? (
            <div className="flex flex-wrap items-center px-3 pb-2 pt-2">
              {this.state.loadingFilter ? (
                <>
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2} />
                    </div>
                  </div>
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2} />
                    </div>
                  </div>
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2} />
                    </div>
                  </div>
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div id="filterAll" className="flex flex-wrap w-full">
                    {this.showSelectFilterSD()}
                    {this.showSelectFilterSMA()}
                    {this.showSelectFilterSMK()}
                    {this.showSelectFilterALL()}
                    <div className="flex py-2">
                      {this.state.urutanFilter === true ? (
                        <button
                          className={
                            this.state.loading_button == true
                              ? "btn-disabled px-4 py-2 ml-1 flex items-center rounded-full text-sm"
                              : "btn-schoolmedia px-4 py-2 ml-1 flex items-center rounded-full text-white text-sm"
                          }
                          onClick={this.setFilter}
                          disabled={
                            this.state.loading_button == true ? true : false
                          }
                        >
                          {this.state.loading_button == true ? "Tunggu..." : "Cari"}
                        </button>
                      ) : null}
                    </div>
                  </div>

                  <button
                      className="flex btn-default text-sm text-gray-800 px-4 py-2 rounded-full items-center"
                      onClick={this.setResetFilter}
                    >
                      Reset
                  </button> 
                </>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="sm-container shadow-md mx-auto py-3 mt-2 rounded-lg bg-white border">
          {this.showData()}
        </div>

        <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
          <div className="w-full text-right flex justify-end px-4">
            <div className="flex flex-wrap items-baseline px-3 justify-end">
              <p>Pilih rombel dan klik tombol</p>
              {this.state.checkAll == false ? (
                <button
                  className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                  onClick={this.checkAll}
                >
                  Pilih Semua
                </button>
              ) : (
                <button
                  className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                  onClick={this.unCheckAll}
                >
                  Tidak Pilih Semua
                </button>
              )}
              <button
                onClick={this.deleteMulti}
                className="btn-green px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                disabled={this.state.loading_delete == true ? true : false}
              >
                {this.state.loading_delete ? <UserButtonLoading /> : "Hapus"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
