import React, { Component } from "react";
import axios from "axios";
import TableSkeleton from "./TableSkeleton";
import tokenApi from "../../auth/Helpers";
import Spinner from "react-bootstrap/Spinner";

export default class DataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entities: {
        data: [],
        current_page: 1,
        from: 1,
        last_page: 50,
        per_page: 10,
        to: 1,
        total: 1,
      },
      data: [],
      query: "",
      first_page: 1,
      current_page: 1,
      sorted_column: this.props.columns[0].index,
      offset: 4,
      order: "asc",

      loading: null,

      loadingCreate: null,

      error_value: "",
      LoadingButton: false,
    };

    this.userList = this.userList.bind(this);
    // this.rowsNumber = this.rowsNumber.bind(this)

    this.fetchEntities = this.fetchEntities.bind(this);
  }

  loading(load) {
    if (load) {
      let lo = this.state.loading;
      return lo;
    }
    this.setState({ loading: true });
  }

  loadingCreate() {
    this.setState({ loadingCreate: true });
  }

  resetPage(qry, status) {
    if (!!status) {
      if (this.state.current_page > 1) {
        this.setState({ current_page: 1 }, function() {
          this.fetchEntities(qry);
        });
      }
    } else {
      this.setState({ current_page: 1 }, function() {
        this.fetchEntities(qry);
      });
    }
  }

  fetchEntities(qry = false, searchLoading = null) {
    // let fetchUrl = `${this.props.url}?page=${this.state.current_page}&column=${this.state.sorted_column}&order=${this.state.order}&per_page=${this.state.entities.per_page}`;
    let fetchUrl = `${this.props.url}?page=${this.state.current_page}&column=${this.state.sorted_column}&order=${this.state.order}&per_page=${this.state.entities.per_page}`;
    if (qry) {
      fetchUrl = `${fetchUrl}${qry}`;
      this.setState({ query: qry }, function() {});
    }
    axios
      .get(fetchUrl, { headers: { Authorization: tokenApi } })
      // .then((response) => {
      //   console.log("data", res,data.data)
      //   this.setState({ entities: response.data });
      //   this.setState({
      //     entities: { ...this.state.entities, data: response.data.data },
      //   });
      //   this.setState({ loading: false, loadingCreate: false });
      // })
      // .catch((e) => {
      //   console.log("error")
      //   this.setState({ loading: false, loadingCreate: false });
      // });
      .then((res) => {
        this.setState({
          entities: res.data,
          loading: false,
          loadingCreate: false,
        });
        this.setState({
          entities: { ...this.state.entities, data: response.data.data },
          loading: false,
          loadingCreate: false
        });
      })
      .catch((err) => {
        console.log("error", err);
        this.setState({ loading: false, loadingCreate: false });
      });
  }

  loadingIndicator(load) {
    // let load = this.state.loading;
    if (this.state.loading == false) {
      load = false;
    } else if (this.state.loading == true) {
      load = true;
    } else {
      load = null;
    }
    return load;
  }

  changePage(pageNumber) {
    this.loading();
    this.setState({ current_page: pageNumber }, () => {
      this.fetchEntities(this.state.query);
    });
  }

  columnHead(value) {
    if (value == null) {
      return null;
    } else {
      return value
        .split("_")
        .join(" ")
        .toUpperCase();
    }
  }

  pagesNumbers() {
    if (!this.state.entities.to) {
      return [];
    }
    let from = this.state.entities.current_page - this.state.offset;
    if (from < 1) {
      from = 1;
    }
    let to = from + this.state.offset * 2;
    if (to >= this.state.entities.last_page) {
      to = this.state.entities.last_page;
    }
    let pagesArray = [];
    for (let page = from; page <= to; page++) {
      pagesArray.push(page);
    }
    return pagesArray;
  }

  // componentDidMount() {
  //   this.setState(
  //     { current_page: this.state.entities.current_page },
  //     () => {
  //       this.fetchEntities();
  //     }
  //   );
  // }

  refreshPage() {
    window.location.reload();
  }

  tableHeadsColSpan() {
    return this.props.columns.map((column) => {
      if (column.colSpan != null && column.label != undefined) {
        return (
          <th
            className="top-0 px-1 font-extrabold border-b break-words py-2 text-gray-700 font-bold tracking-wider uppercase text-xs"
            colSpan={column.colSpan}
          >
            {this.columnHead(column.label)}
          </th>
        );
      }
    });
  }

  tableHeads() {
    return this.props.columns.map((column) => {
      if (column.colSpan == null && column.label != undefined) {
        return (
          <th
            className="top-0 px-1 border-b break-words border-gray-500 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"
            style={{ maxWidth: column.maxWidth }}
            key={column.index}
            onClick={() => this.sortByColumn(column.index)}
          >
            {this.columnHead(column.label)}
            {column.index == null ? <></> : null}
          </th>
        );
      }
    });
  }

  userList() {
    if (this.state.entities.total === 0 || this.state.entities.total === "0") {
      return (
        <tr>
          <td
            className=" border-t border-gray-200 userId"
            colSpan={this.props.columns.length}
            className="text-center"
          >
            <div className="flex flex-col py-4">
              <span
                className="bx bx-customize text-blue-700"
                style={{ fontSize: "3.5rem" }}
              ></span>
              <h3 className="font-bold text-gray-700">Belum ada data.</h3>
            </div>
          </td>
        </tr>
      );
    } else if (this.state.loading === true) {
      return (
        <tr>
          <td
            className=" border-t border-gray-200 userId"
            colSpan={this.props.columns.length}
            className="text-center"
          >
            <div className="flex flex-col py-4">
              <Spinner
                animation="border"
                variant="primary"
                className="mx-auto"
              />
              <h3 className="font-bold text-gray-700">Loading</h3>
            </div>
          </td>
        </tr>
      );
    } else if (this.state.loadingCreate === true) {
      return (
        <tr>
          <td
            className=" border-t border-gray-200 userId"
            colSpan={this.props.columns.length}
            className="text-center"
          >
            <div className="flex flex-col py-4">
              <Spinner
                animation="border"
                variant="primary"
                className="mx-auto"
              />
              <h3 className="font-bold text-gray-700">Memperbarui Data</h3>
            </div>
          </td>
        </tr>
      );
    } else if (
      this.state.entities.data.length >= 1
      // this.state.entities.data &&
      // this.state.entities.data.length > 1 &&
      // this.state.current_page >= 1
    ) {
      // console.log("loaded");
      return this.state.entities.data.map((data) => {
        let listAllow = {};
        let maxWidth = {};
        this.props.columns.map((value) => {
          if (value.index !== "thColSpan" && value.index !== undefined) {
            listAllow[value.index] = data[value.index];
            maxWidth[value.index] = value.maxWidth;
          }
          // console.log(value.index);
        });

        return (
          <>
            <tr key={listAllow.id}>
              {/* {this.state.entities.data.map((col, index) => (
                <td className="border-t  break-words px-1 border-gray-200 userId">{index.key}</td>
              ))} */}
              {Object.keys(listAllow).map((key) => {
                if (this.props.action !== undefined) {
                  if (key in this.props.action) {
                    let action = this.props.action[key];
                    return (
                      <td
                        className=" break-words px-1 border-t border-gray-200 userId"
                        key={key}
                        style={{ maxWidth: maxWidth[key] }}
                      >
                        {action(listAllow, data)}
                      </td>
                    );
                  }
                }
                return (
                  <td
                    className="border-t  break-words px-1 border-gray-200 userId"
                    key={key}
                    style={{ maxWidth: maxWidth[key] }}
                  >
                    <span
                      className="text-gray-700 py-2 flex items-cente text-sm"
                      style={{ wordBreak: "break-word" }}
                    >
                      {listAllow[key]}
                    </span>
                  </td>
                );
              })}
            </tr>
          </>
        );
      });
    } else if (this.state.error_value === 422) {
      return (
        <tr>
          <td
            className=" border-t border-gray-200 userId"
            colSpan={this.props.columns.length}
            className="text-center"
          >
            <div className="flex flex-col py-4">
              <span
                className="bx bx-error text-red-700"
                style={{ fontSize: "3.5rem" }}
              ></span>
              <h3 className="font-bold text-gray-700">
                Aksi tidak dapat diproses 🙏
              </h3>
            </div>
          </td>
        </tr>
      );
    } else if (
      this.state.error_value === 404 ||
      this.state.error_value === 500
    ) {
      return (
        <tr>
          <td
            className=" border-t border-gray-200 userId"
            colSpan={this.props.columns.length}
            className="text-center"
          >
            <div className="flex flex-col py-4">
              <span
                className="bx bx-error text-red-700"
                style={{ fontSize: "3.5rem" }}
              ></span>
              <h3 className="font-bold text-gray-700">
                Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi
                Admin. 😊
              </h3>
            </div>
          </td>
        </tr>
      );
    } else if (this.state.error_value === 408) {
      return (
        <tr>
          <td
            className=" border-t border-gray-200 userId"
            colSpan={this.props.columns.length}
            className="text-center"
          >
            <div className="flex flex-col py-4">
              <span
                className="bx bx-error text-orange-700"
                style={{ fontSize: "3.5rem" }}
              ></span>
              <h3 className="font-bold text-gray-700">
                Oops. Waktu Tunggu Habis.
              </h3>
              <p className="font-bold text-sm text-gray-500 my-2">
                Periksa koneksi anda dan refresh halaman ini.
              </p>
              <button
                className=" bg-blue-600 rounded-full px-2 py-2 mt-2 mx-auto"
                onClick={this.refreshPage}
              >
                <p className="font-bold text-sm text-white">Refresh</p>
              </button>
            </div>
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td
            className=" border-t border-gray-200 userId overflow-hidden"
            colSpan={this.props.columns.length}
            className=""
          >
            <TableSkeleton
              style={{ width: "80rem", bottom: "70px", position: "relative" }}
            />
          </td>
        </tr>
      );
    }
    // else{
    //   return(
    //     <tr>
    //       <td
    //         className=" border-t border-gray-200 userId overflow-hidden"
    //         colSpan={this.props.columns.length}
    //         className=""
    //       >

    //         <TableSkeleton style={{width: '80rem', bottom: '70px', position: 'relative'}}/>
    //       </td>
    //     </tr>
    //   )
    // }
  }

  sortByColumn(column) {
    if (column === this.state.sorted_column) {
      this.state.order === "asc"
        ? this.setState(
            { order: "desc", current_page: this.state.first_page },
            () => {
              this.fetchEntities(this.state.query);
            }
          )
        : this.setState({ order: "asc" }, () => {
            this.fetchEntities(this.state.query);
          });
    } else {
      this.setState(
        {
          sorted_column: column,
          order: "asc",
          current_page: this.state.first_page,
        },
        () => {
          this.fetchEntities(this.state.query);
        }
      );
    }
  }

  pageList() {
    // console.log(this.pagesNumbers());
    return this.pagesNumbers().map((page) => {
      return (
        <button
          key={page}
          onClick={() => this.changePage(page)}
          className={
            page === this.state.entities.current_page
              ? "font-medium px-4 py-2 rounded-full mr-1 text-sm bg-schoolmedia text-white "
              : "font-medium bg-white border border-gray-600 text-gray-700 px-4 py-2 rounded-full mr-1 text-sm"
          }
        >
          {" "}
          {page}
        </button>
      );
    });
  }

  render() {
    // console.log(this.state.error_value)
    // console.log(this.state.loading);
    return (
      <div className="py-1">
        <div>
          <div className="overflow-x-scroll only mx-4">
            <table className="rounded border-collapse w-full bg-white  break-words  relative">
              <thead>
                <tr className="text-left tr">{this.tableHeadsColSpan()}</tr>
                <tr className="text-left tr">
                  {/* <th className="top-0 px-1 border-b break-words border-gray-500 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs" style={{minWidth: '20px !important'}}>No</th> */}
                  {this.tableHeads()}
                </tr>
              </thead>
              <tbody>{this.userList()}</tbody>
            </table>
          </div>
          {this.state.entities.data && this.state.entities.data.length > 0 && (
            <div className="mt-4 flex justify-between">
              <div className="px-4">
                <button
                  disabled={1 === this.state.entities.current_page}
                  onClick={() =>
                    this.changePage(this.state.entities.current_page - 1)
                  }
                  className="font-medium bg-white border-gray-600 border text-gray-700 px-4 py-2 rounded-full mr-1 text-sm"
                >
                  Sebelumnya
                </button>
                {this.pageList()}
                <button
                  disabled={
                    this.state.entities.last_page ===
                    this.state.entities.current_page
                  }
                  onClick={() =>
                    this.changePage(this.state.entities.current_page + 1)
                  }
                  className="font-medium bg-white border border-gray-600 text-gray-700 px-4 py-2 rounded-full mr-1 text-sm"
                >
                  Selanjutnya
                </button>
                {/* <span style={{ marginTop: '8px' }}> &nbsp; <i>Displaying { this.state.entities.data.length } of { this.state.entities.total } entries.</i></span> */}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
