import React from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import tokenApi from "../auth/Helpers";
import conf from "../../config";
import Swal from "sweetalert2";


export default class ModalDropZoneImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            selectedImages: [],
            errorMessage: '',
            url_find_user: `${conf.uri_backend}api/v1/user/find_user`,
            url_upload_file: `${conf.uri_backend}api/v1/user/upload_foto_profil`
        };

        this.onDrop = this.onDrop.bind(this);
        this.onDropRejected = this.onDropRejected.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    // Set error message
    setErrorMessage(message) {
        this.setState({ errorMessage: message });

        if (message) {
            setTimeout(() => {
               this.setState({ errorMessage: '' }); 
            }, 4000);
        }
    }

    // Handle file drop
    async onDrop(acceptedFiles) {
        const validImages = [];

        for (const file of acceptedFiles) {
            if (!file.type.startsWith('image/')) {
                this.setErrorMessage(`File ${file.name} bukan gambar dan telah ditolak.`);
                continue;
            }

            try {
                let fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
                let generalParam;

                if (!isNaN(fileNameWithoutExtension)) {
                    generalParam = fileNameWithoutExtension;
                } else {
                    generalParam = file.name;
                }

                const response = await axios.get(this.state.url_find_user, {
                    params: {
                        general: generalParam,
                        type: this.props.type
                    },
                    headers: {
                        Authorization: tokenApi
                    }
                });

                if (response.data.status === 200) {
                    validImages.push({
                        file,
                        preview: URL.createObjectURL(file),
                        name: file.name,
                        type: file.type,
                        size: (file.size / 1024).toFixed(2)
                    });
                } else {
                    this.setErrorMessage(`User tidak ditemukan untuk file ${file.name}`);
                }
            } catch (error) {
                this.setErrorMessage(`Terjadi kesalahan saat memeriksa file ${file.name}`);
            }
        }

        this.setState(prevState => ({
            selectedImages: [...prevState.selectedImages, ...validImages],
            errorMessage: prevState.errorMessage || ''
        }));
    }

    // Handle rejected files
    onDropRejected(rejectedFiles) {
        let message = 'File tidak valid';

        rejectedFiles.forEach(file => {
            if (file.errors.some(error => error.code === 'file-too-large')) {
                message += ` ${file.file.name} melebihi ukuran 10MB.`;
            }

            if (file.errors.some(error => error.code === 'file-invalid-type' || !(file.type && file.type.startsWith('image/')))) {
                message += ` ${file.file.name} bukan gambar.`;
            }
        });

        this.setErrorMessage(message);
    }

    componentWillUnmount() {
        this.state.selectedImages.forEach(image => URL.revokeObjectURL(image.preview));
    }

    // Handle delete image on drop area
    removeImage(indexToRemove, event) {
        event.stopPropagation();
        this.setState(prevState => ({
            selectedImages: prevState.selectedImages.filter((_, index) => index !== indexToRemove)
        }));
    }

    // Handle upload image
    async handleUpload() {
        const { selectedImages } = this.state;

        if (selectedImages.length === 0) {
            this.setErrorMessage('Tidak ada gambar yang dipilih.');
            return;
        }

        const general = selectedImages.map(image => {
            let fileNameWithoutExtension = image.name.split('.').slice(0, -1).join('.');
    
            if (!isNaN(fileNameWithoutExtension)) {
                return fileNameWithoutExtension;
            }
    
            return image.name; 
        });

        const fotos = selectedImages.map(image => image.file);

        const formData = new FormData();
        general.forEach(g => formData.append('general[]', g));
        formData.append('type', this.props.type);

        fotos.forEach((file) => {
            formData.append('fotos[]', file);
        });

        for (let pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        try {
            const response = await axios.post(this.state.url_upload_file, formData, {
                headers: {
                    Authorization: tokenApi,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.status === 200) {
                this.setErrorMessage('Upload berhasil!');
                this.props.hideModal();

                Swal.fire({
                    title: 'Sukses!',
                    text: 'Foto profil berhasil diunggah.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    this.setState({ selectedImages: [] });
                });
            } else {
                this.setErrorMessage('Upload gagal.');
            }
        } catch (error) {
            this.setErrorMessage('Terjadi kesalahan saat upload.');
            console.error(error);
        }
    }

    render() {
        const { selectedImages, errorMessage } = this.state;
        const { showModal, hideModal } = this.props;

        return (
            <>
                <Modal show={showModal} onHide={hideModal} centered size="lg">
                    <Modal.Header closeButton>
                        <h5>Upload Foto Profil</h5>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMessage && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}

                        <DropzoneComponent onDrop={this.onDrop} onDropRejected={this.onDropRejected}>
                            {selectedImages.length > 0 && (
                                <div className="mt-4 grid grid-cols-3 gap-4 max-h-64 overflow-y-auto">
                                    {selectedImages.map((image, index) => (
                                        <div key={index} className="relative border-dashed border-2 border-gray-400 p-4 bg-white break-words">
                                            <img
                                                src={image.preview}
                                                alt={`Preview ${index}`}
                                                className="w-20 h-20 rounded-full object-cover mx-auto mb-2"
                                            />
                                            <div className="text-center">
                                                <p className="text-sm font-semibold">Nama: {image.name}</p>
                                                <p className="text-sm">Tipe: {image.type}</p>
                                                <p className="text-sm">Ukuran: {image.size} KB</p>
                                            </div>
                                            <button 
                                                onClick={(e) => this.removeImage(index, e)} 
                                                className="absolute top-0 right-0 p-1 m-1 bg-transparent hover:bg-blue-500 text-blue-700 rounded-full"
                                            >
                                                X
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </DropzoneComponent>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn-schoolmedia text-white font-bold font-medium py-2 px-4 rounded-full"
                            onClick={this.handleUpload}
                        >
                            <i className="bx bx-upload text-lg mr-1"></i> Upload
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

// Komponen Dropzone
function DropzoneComponent({ onDrop, onDropRejected, children }) {
    const { getRootProps, getInputProps } = useDropzone({ 
        onDrop,
        onDropRejected,
        accept: 'image/*',
        multiple: true,
        maxSize: 10485760 // Max file size
    });

    return (
        <>
            <div class="container-li">
                <div class="list-container">
                    <p className="text-base"><b>Jika Menggunakan Kode Pendaftaran</b></p>
                    <ul>
                        <li className="text-sm">Ganti nama file dengan <b>Kode Pendaftaran</b>. (Contoh: 482882724299.png)</li>
                        <li className="text-sm">Pastikan nama file yang diupload sudah sesuai dengan <b>Kode Pendaftaran</b>.</li>
                    </ul>
                </div>

                <div class="list-container">
                    <p className="text-base"><b>Jika Menggunakan Nama File</b></p>
                    <ul>
                        <li className="text-sm">Download format data excel</li>
                        <li className="text-sm">Pastikan kolom nama file sudah di isi.</li>
                        <li className="text-sm">File excel harus sudah di upload.</li>
                    </ul>
                </div>
            </div>

            <div
                {...getRootProps()}
                className="border-dashed border-2 border-gray-400 p-6 text-center flex flex-col items-center justify-center cursor-pointer bg-white h-64"
            >
                <input {...getInputProps()} />
                <p className="text-gray-600">Tarik gambar atau klik untuk memilih gambar</p>
                {children}
            </div>
        </>
    );
}
