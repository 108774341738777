import React, { Component } from "react";

export default class SimpleTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
        data: props.data
    };

    this.contentList = this.contentList.bind(this)
  }

  columnHead(value) {
    return value.split("_").join(" ").toUpperCase();
  }

  tableHeads() {
    return this.props.columns.map((column) => {
      return (
        <th
          className="top-0 px-1 border-b break-words border-gray-500 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"
          style={{ maxWidth: column.maxWidth }}
          key={column.index}
          onClick={() => this.sortByColumn(column.index)}
        >
          {this.columnHead(column.label)}
        </th>
      );
    });
  }

  contentList() {
    if (this.props.data.length) {
      return this.props.data.map((data) => {
        let listAllow = {};
        let maxWidth = {};
        this.props.columns.map((value) => {
          listAllow[value.index] = data[value.index];
          maxWidth[value.index] = value.maxWidth;
        });

        return (
          <tr key={listAllow.id}>
            {Object.keys(listAllow).map((key) => {
              if (this.props.action !== undefined) {
                if (key in this.props.action) {
                  let action = this.props.action[key];
                  return (
                    <td
                      className=" break-words px-1 border-t border-gray-200 userId"
                      key={key}
                      style={{ maxWidth: maxWidth[key] }}
                    >
                      {action(listAllow, data)}
                    </td>
                  );
                }
              }
              return (
                <td
                  className="border-t  break-words px-1 border-gray-200 userId"
                  key={key}
                  style={{ maxWidth: maxWidth[key] }}
                >
                  <span className="text-gray-700 py-2 flex items-cente text-sm">
                    {listAllow[key]}
                  </span>
                </td>
              );
            })}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td
            className=" border-t border-gray-200 userId"
            colSpan={this.props.columns.length}
            className="text-center"
          >
           Tidak ada data
          </td>
        </tr>
      );
    }
  }


  render() {
    return (
      <div className="py-1">
                 
          <table  className="w-full">
            <thead>
              <tr className="text-left">{this.tableHeads()}</tr>
            </thead>
            <tbody>{this.contentList()}</tbody>
          </table>
      </div>
    );
  }
}
