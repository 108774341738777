import React from "react";
import DataTable from "../../partials/table/DataTables.js";
import { Link } from "react-router-dom";
import conf from "../../../config.js";
import SelectJs from "../../partials/Select.js";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import SweetAlert from "sweetalert2-react";
import tokenApi from "../../auth/Helpers";
import Skeleton from "react-loading-skeleton";
import { isEmpty } from "lodash";

const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "100px",
    minWidth: "15rem",
  }),
};

const dataKurikulum = [
  { value: "k13", label: "K13" },
  { value: "merdeka", label: "Merdeka" },
];

export default class Alumni extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${conf.uri_backend}api/v1/rombel/list-tambah-alumni`,
      url_get_spektrum: `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_rombel: `${conf.uri_backend}api/v1/rombel/get_rombel`,
      url_get_spektrum: `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_filter: `${conf.uri_backend}api/v1/rombel/filter`,
      url_get_smk: `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,
      url_put_filter_rombel: `${conf.uri_backend}api/v1/rombel/filter`,
      url_show_data: "",

      // data session
      institusi_id_select: [],
      jenjang_id_select: [],
      user_institusi_id: "",

      tahunSekarang: new Date().getFullYear(),

      data_tahun_ajaran: null,
      data_kelas: null,
      data_spektrum: null,
      data_rombel: null,

      tahun_ajaran_search: "",
      kelas_search: "",
      peminatan_search: "",
      rombel_search: "",

      // input filter
      kurikulum: "",
      tahun_ajaran_search: "",
      kelas_search: "",
      peminatan_search: "",
      rombel_search: "",
      bidang_search: "",
      program_search: "",
      kompetensi_search: "",
      // data filter
      data_tahun_ajaran: "",
      data_kelas: "",
      data_peminatan: "",
      data_bidang: "",
      data_program: "",
      data_kompetensi: "",
      data_rombel: "",

      // search and filter
      searchText: "",
      showFilter: "",
      loadingFilter: true,

      data: [],
      data_detail: [],

      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",

      loadingFilter: true,
    };
    // filter and search
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterKelas = this.filterKelas.bind(this);
    this.filterRombel = this.filterRombel.bind(this);
    this.filterPeminatan = this.filterPeminatan.bind(this);
    this.filterBidang = this.filterBidang.bind(this);
    this.filterProgram = this.filterProgram.bind(this);
    this.filterKompetensi = this.filterKompetensi.bind(this);
    this.searchText = this.searchText.bind(this);
    // show Data
    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
    this.showData = this.showData.bind(this);
    // get option filter
    this.getTahunAjaranNow = this.getTahunAjaranNow.bind(this);
    this.getKelas = this.getKelas.bind(this);
    this.getRombel = this.getRombel.bind(this);
    this.getPeminatanSMA = this.getPeminatanSMA.bind(this);
    this.getDataFilterSMA = this.getDataFilterSMA.bind(this);
    this.getBidang = this.getBidang.bind(this);
    this.getProgram = this.getProgram.bind(this);
    this.getKompetensi = this.getKompetensi.bind(this);
    // show select filter
    this.showSelectFilterSD = this.showSelectFilterSD.bind(this);
    this.showSelectFilterSMA = this.showSelectFilterSMA.bind(this);
    this.showSelectFilterSMK = this.showSelectFilterSMK.bind(this);
    this.showSelectFilterALL = this.showSelectFilterALL.bind(this);

    this.redirectToSiswaRombel = this.redirectToSiswaRombel.bind(this);
  }

  componentDidMount() {
    // get session user
    if (localStorage.getItem("token") !== null) {
      axios
        .post(`${conf.uri_backend}api/v1/auth/sso/user`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          this.setState({ data_session: res.data }, function() {
            axios
              .get(
                `${conf.uri_backend}api/v1/select-institusi/` +
                  this.state.data_session.access_to,
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                this.setState(
                  {
                    institusi_id_select: res.data.id,
                    user_institusi_id: res.data.id,
                    jenjang_id_select: res.data.m_struktur_jenjang_id,
                    url_show_data: `${this.state.url}`,
                    query: "&institusi_id=" + res.data.id,
                  },
                  function() {
                    this.setState(
                      {
                        url_show_data: `${this.state.url}`,
                        query:
                          "&user_institusi_id=" +
                          this.state.institusi_id_select,
                      },
                      function() {
                        this.dt.fetchEntities(this.state.query);
                      }
                    );
                    this.getDataFilterSMA();
                    this.getTahunAjaranNow();
                    this.getKelas();
                    this.getPeminatanSMA();
                    this.getRombel();
                    this.getBidang("create");
                  }
                );
              });
          });
        });
    }
  }

  deleteAlumni(data) {
    axios
      .post(
        `${conf.uri_backend}api/v1/rombel/hapus-alumni`,
        { rombel_siswa_id: data },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.dt.fetchEntities();
        if (res.data.status == true) {
          this.setState({
            show_alert: true,
            title_alert: "Berhasil",
            icon_alert: "success",
            message_alert: res.data.messages,
          });
        }
      });
  }

  getTahunAjaranNow() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  getKelas() {
    var kelas = [];
    if (this.state.jenjang_id_select == "2") {
      kelas = [
        { value: "I", label: "I" },
        { value: "II", label: "II" },
        { value: "III", label: "III" },
        { value: "IV", label: "IV" },
        { value: "V", label: "V" },
        { value: "VI", label: "VI" },
      ];
    } else if (this.state.jenjang_id_select == "3") {
      kelas = [
        { value: "VII", label: "VII" },
        { value: "VIII", label: "VIII" },
        { value: "IX", label: "IX" },
      ];
    } else if (this.state.jenjang_id_select == "4") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
      ];
    } else if (this.state.jenjang_id_select == "5") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
        { value: "XIII", label: "XIII" },
      ];
    }
    if (
      this.state.jenjang_id_select == "3" || this.state.jenjang_id_select == "2"
        ? this.setState({ loadingFilter: false })
        : ""
    );
    if (
      this.state.jenjang_id_select == "5"
        ? this.setState({ loadingFilter: false })
        : ""
    );
    this.setState({ data_kelas: kelas });
  }

  getRombel() {
    this.setState({ loading_rombel: !this.state.loading_rombel });
    var peminatan = "";
    if (
      this.state.jenjang_id_select == "5"
        ? (peminatan = this.state.kompetensi_search)
        : (peminatan = this.state.peminatan_search)
    );
    axios
      .get(
        `${this.state.url_get_rombel}?tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&peminatan=${peminatan}&user_institusi_id=${this.state.institusi_id_select}`,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ data_rombel: res.data, loading_rombel: false });
        this.setState({
          loadingFilter: false,
          loading_rombel: false,
        });
      });
  }

  getPeminatanSMA() {
    this.setState({ loading_peminatan_sma: !this.state.loading_peminatan_sma });
    if (this.state.jenjang_id_select == "4") {
      axios
        .get(
          this.state.url_get_spektrum +
            "?jenjang=" +
            this.state.jenjang_id_select,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (res.data.status == true) {
            this.setState({ data_peminatan: res.data.data });
            this.setState({ loading_peminatan_sma: false });
          } else {
            this.setState({ data_peminatan: [] });
          }
        })
        .catch((err) => {
          this.setState({ loading_peminatan_sma: false });
        });
    }
  }

  // SMK
  getBidang(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_bidang: !this.state.loading_bidang });
      axios
        .get(`${this.state.url_get_smk}?parent_id=0&jenjang_id=5&kurikulum=${this.state.kurikulum}`, {
          headers: { Authorization: tokenApi },
        })
        .then((res) => {
          if (create != null) {
            this.setState({ data_bidang_p: res.data });
          } else {
            this.setState({ data_bidang: res.data });
            this.setState({ loading_bidang: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_bidang: false });
        });
    }
  }
  getProgram(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_program: !this.state.loading_program });
      var bidang = "";
      if (
        create != null
          ? (bidang = this.state.bidang)
          : (bidang = this.state.bidang_search)
      );
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=${bidang}&jenjang_id=5&kurikulum=${this.state.kurikulum}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (create != null) {
            this.setState({ data_program_p: res.data });
          } else {
            this.setState({ data_program: res.data });
            this.setState({ loading_program: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_program: false });
        });
    }
  }
  getKompetensi(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_kompetensi: !this.state.loading_kompetensi });
      var program = "";
      if (
        create != null
          ? (program = this.state.program)
          : (program = this.state.program_search)
      );
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=${program}&jenjang_id=5&kurikulum=${this.state.kurikulum}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (create != null) {
            this.setState({ data_kompetensi_p: res.data });
          } else {
            this.setState({ data_kompetensi: res.data });
            this.setState({ loading_kompetensi: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_kompetensi: false });
        });
    }
  }

  getDataFilterSMA() {
    axios
      .get(this.state.url_get_filter + "/" + this.state.institusi_id_select, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        if (res.data.status == true) {
          if (res.data.data != null) {
            this.setState(
              {
                tahun_ajaran_search:
                  res.data.data.tahun_ajaran != null
                    ? res.data.data.tahun_ajaran
                    : "",
                kelas_search:
                  res.data.data.kelas !== null ? res.data.data.kelas : "",
                bidang_search:
                  res.data.data.bidang_id !== null
                    ? res.data.data.bidang_id
                    : "",
                bidang_search_label:
                  res.data.data.bidang !== null ? res.data.data.bidang : "",
                program_search:
                  res.data.data.program_id !== null
                    ? res.data.data.program_id
                    : "",
                program_search_label:
                  res.data.data.program !== null ? res.data.data.program : "",
                kompetensi_search:
                  res.data.data.kompetensi_id !== null
                    ? res.data.data.kompetensi_id
                    : "",
                kompetensi_search_label:
                  res.data.data.kompetensi !== null
                    ? res.data.data.kompetensi
                    : "",
                peminatan_search:
                  res.data.data.kompetensi_id !== null
                    ? res.data.data.kompetensi_id
                    : "",
                peminatan_search_label:
                  res.data.data.kompetensi !== null
                    ? res.data.data.kompetensi
                    : "",
                rombel_search:
                  res.data.data.rombel_id !== null
                    ? res.data.data.rombel_id
                    : "",
                rombel_search_label:
                  res.data.data.rombel !== null ? res.data.data.rombel : "",
              },
              function() {
                this.getRombel();
                this.getProgram();
                this.getKompetensi();
                this.getPeminatanSMA();
                this.getTahunAjaranNow();
                this.getKelas();
              }
            );
          }
        }
      });
  }

  // SHOW TYPE FILTER PER JENJANG
  showSelectFilterSD() {
    if (this.state.data_tahun_ajaran && this.state.data_kelas) {
      return (
        <div className="flex">
          <div className="py-2">
            <Select
              id="filterTahunAjaran"
              styles={selectStyles}
              isClearable={true}
              defaultValue={
                this.state.tahun_ajaran_search != ""
                  ? {
                      value: this.state.tahun_ajaran_search,
                      label: this.state.tahun_ajaran_search,
                    }
                  : ""
              }
              options={this.state.data_tahun_ajaran}
              onChange={this.filterTahunAjaran}
              placeholder={"Pilih Tahun Ajaran"}
            />
          </div>

          <div className="py-2 mr-2 ml-2">
            <Select
              styles={selectStyles}
              isDisabled={this.state.disabledFilter}
              onChange={(e) => {
                if (!isEmpty(e)) {
                  this.setState(
                    {
                      kurikulum: e.value,
                    },
                    function() {
                      this.getBidang();
                    }
                  );
                }
              }}
              options={dataKurikulum}
              placeholder={"Pilih Kurikulum"}
            />
          </div>

          <div className="py-2 ml-2 mr-2">
            <Select
              id="filterKelas"
              styles={selectStyles}
              isClearable={true}
              defaultValue={
                this.state.kelas_search != ""
                  ? {
                      value: this.state.kelas_search,
                      label: this.state.kelas_search,
                    }
                  : ""
              }
              options={this.state.data_kelas}
              onChange={this.filterKelas}
              placeholder={"Pilih Kelas"}
            />
          </div>
        </div>
      );
    }
  }

  showSelectFilterSMA() {
    if (this.state.jenjang_id_select == "4" && this.state.data_peminatan) {
      return (
        <div className="flex">
          <div className="py-2 mr-2">
            <Select
              id="filterPeminatan"
              styles={selectStyles}
              isClearable={true}
              defaultValue={
                this.state.peminatan_search != ""
                  ? {
                      value: this.state.peminatan_search,
                      label: this.state.peminatan_search_label,
                    }
                  : ""
              }
              isLoading={this.state.loading_peminatan_sma ? true : false}
              options={this.state.data_peminatan}
              onChange={this.filterPeminatan}
              placeholder={"Pilih Peminatan"}
            />
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  showSelectFilterSMK() {
    if (this.state.jenjang_id_select == "5") {
      return (
        <>
          {this.state.kelas_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterBidang"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.bidang_search != ""
                      ? {
                          value: this.state.bidang_search,
                          label: this.state.bidang_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_bidang ? true : false}
                  options={this.state.data_bidang}
                  onChange={this.filterBidang}
                  className="animateFadeInLeft"
                  placeholder={
                    this.state.loading_bidang ? "Loading" : "Bidang Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {this.state.bidang_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterProgram"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.program_search != ""
                      ? {
                          value: this.state.program_search,
                          label: this.state.program_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_program ? true : false}
                  options={this.state.data_program}
                  onChange={this.filterProgram}
                  placeholder={
                    this.state.loading_program ? "Loading" : "Program Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}
          {this.state.program_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterKompetensi"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.kompetensi_search != ""
                      ? {
                          value: this.state.kompetensi_search,
                          label: this.state.kompetensi_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_kompetensi ? true : false}
                  options={this.state.data_kompetensi}
                  onChange={this.filterKompetensi}
                  placeholder={
                    this.state.loading_kompetensi
                      ? "Loading"
                      : "Kompetensi Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  showSelectFilterALL() {
    if (this.state.data_rombel || this.state.rombel_search != "") {
      return (
        <div className="flex">
          <div className="py-2">
            <Select
              id="filterRombel"
              styles={selectStyles}
              isClearable={true}
              isLoading={this.state.loading_rombel ? true : false}
              defaultValue={
                this.state.rombel_search != ""
                  ? {
                      value: this.state.rombel_search,
                      label: this.state.rombel_search_label,
                    }
                  : ""
              }
              options={this.state.data_rombel}
              onChange={this.filterRombel}
              placeholder={"Pilih Rombel"}
            />
          </div>
        </div>
      );
    }
  }

  // FILTER HANDLE CHANGE FUNCTION
  filterTahunAjaran(e) {
    if (e) {
      this.setState({ tahun_ajaran_search: e.value }, function() {
        this.getRombel();
        this.getPeminatanSMA();
      });
    } else {
      this.setState({ tahun_ajaran_search: "", url_show_data: "" }, function() {
        this.getRombel();
        this.getPeminatanSMA();
      });
    }
  }

  filterKelas(e) {
    if (e) {
      this.setState({ kelas_search: e.value }, function() {
        this.getRombel();
        this.getPeminatanSMA();
        this.getBidang();
      });
    } else {
      this.setState({ kelas_search: "", url_show_data: "" }, function() {
        this.getRombel();
        this.getPeminatanSMA();
        this.getBidang();
      });
    }
  }

  filterPeminatan(e) {
    // only for SMA
    if (e) {
      this.setState(
        { peminatan_search: e.value, peminatan_search_label: e.label },
        function() {
          this.getRombel();
          this.getPeminatanSMA();
          this.getBidang();
        }
      );
    } else {
      this.setState(
        { peminatan_search: "", peminatan_search_label: "", url_show_data: "" },
        function() {
          this.getRombel();
          this.getPeminatanSMA();
          this.getBidang();
        }
      );
    }
  }

  // only for SMK
  filterBidang(e) {
    if (e) {
      this.setState(
        { bidang_search: e.value, bidang_search_label: e.label },
        function() {
          this.getRombel();
          this.getProgram();
        }
      );
    } else {
      this.setState({ bidang_search: "", bidang_search_label: "" }, function() {
        this.getRombel();
        this.getProgram();
      });
    }
  }
  filterProgram(e) {
    if (e) {
      this.setState(
        { program_search: e.value, program_search_label: e.label },
        function() {
          this.getRombel();
          this.getKompetensi();
        }
      );
    } else {
      this.setState(
        { program_search: "", program_search_label: "" },
        function() {
          this.getRombel();
          this.getKompetensi();
        }
      );
    }
  }
  filterKompetensi(e) {
    if (e) {
      this.setState(
        { kompetensi_search: e.value, kompetensi_search_label: e.label },
        function() {
          this.getRombel();
        }
      );
    } else {
      this.setState(
        { kompetensi_search: "", kompetensi_search_label: "" },
        function() {
          this.getRombel();
        }
      );
    }
  }

  filterRombel(e) {
    if (e) {
      this.setState({ rombel_search: e.value, rombel_search_label: e.label });
    } else {
      this.setState({
        rombel_search: "",
        rombel_search_label: "",
        url_show_data: "",
      });
    }
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({ loading_button: true });
    var kompetensi = "";
    var kompetensi_label = "";

    if (this.state.jenjang_id_select == "5") {
      kompetensi = this.state.kompetensi_search;
      kompetensi_label = this.state.kompetensi_search_label;
    } else {
      kompetensi = this.state.peminatan_search;
      kompetensi_label = this.state.peminatan_search_label;
    }
    axios
      .put(
        this.state.url_get_filter + "/" + this.state.institusi_id_select,
        {
          tahun_ajaran: this.state.tahun_ajaran_search,
          kelas: this.state.kelas_search,
          bidang: this.state.bidang_search_label,
          bidang_id: this.state.bidang_search,
          program: this.state.program_search_label,
          program_id: this.state.program_search,
          kompetensi: kompetensi_label,
          kompetensi_id: kompetensi,
          rombel_id: this.state.rombel_search,
          rombel: this.state.rombel_search_label,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        console.log("done");
        this.setState({ loading_button: false });
      });

    var m_spektrum = "";
    if (
      this.state.jenjang_id_select == "5"
        ? (m_spektrum = this.state.kompetensi_search)
        : (m_spektrum = this.state.peminatan_search)
    );

    this.setState(
      {
        url_show_data: `${this.state.url}`,
        query:
          "&query=&tahun_ajaran=" +
          this.state.tahun_ajaran_search +
          "&kelas=" +
          this.state.kelas_search +
          "&m_spektrum_id=" +
          m_spektrum +
          "&rombel_id=" +
          this.state.rombel_search +
          "&user_institusi_id=" +
          this.state.institusi_id_select,
        search: true,
      },
      function() {
        this.dt.loading();
        this.dt.fetchEntities(this.state.query);
      }
    );
  }

  setResetFilter(e) {
    e.preventDefault();
    // document.getElementById('filterAll');
    this.setState(
      {
        data_tahun_ajaran: "",
        data_kelas: "",
        data_peminatan: "",
        data_rombel: "",
        searchText: "",
        kelas_search: "",
        peminatan_search: "",
        peminatan_search_label: "",
        rombel_search: "",
        rombel_search_label: "",
        tahun_ajaran_search: "",
      },
      function() {
        this.getTahunAjaranNow();
        this.getKelas();
        this.getPeminatanSMA();
        this.getRombel();
        this.getBidang();
        this.setState(
          {
            url_show_data: `${this.state.url}`,
            query: "&user_institusi_id=" + this.state.institusi_id_select,
          },
          function() {
            this.dt.fetchEntities(this.state.query);
          }
        );
      }
    );
  }

  // SEARCH TEXT
  searchText(e) {
    e.preventDefault();
    if (this.state.url_show_data !== "") {
      var m_spektrum = "";
      if (
        this.state.jenjang_id_select == "5"
          ? (m_spektrum = this.state.kompetensi_search)
          : (m_spektrum = this.state.peminatan_search)
      );

      this.setState(
        {
          query:
            "&query=" +
            document.getElementById("search").value +
            "&tahun_ajaran=" +
            this.state.tahun_ajaran_search +
            "&kelas=" +
            this.state.kelas_search +
            "&m_spektrum_id=" +
            m_spektrum +
            "&rombel_id=" +
            this.state.rombel_search +
            "&user_institusi_id=" +
            this.state.institusi_id_select,
          searchText: e.target.value,
        },
        function() {
          this.dt.fetchEntities(this.state.query);
        }
      );
    }
  }

  //ALumni dulu
  // showData(){
  //   return(
  //     <div className="px-1 py-1">
  //           <DataTable
  //             ref={(dt) => {
  //               this.dt = dt;
  //             }}
  //             action={{
  //               tahun_masuk: (col, data) => {
  //                 if(col.tahun_masuk !== null){
  //                   var date = '';
  //                   var month = '';
  //                   var year = '';
  //                   date = col.tahun_masuk.substr(8, 2);
  //                   month = col.tahun_masuk.substr(5, 2);
  //                   year = col.tahun_masuk.substr(0, 4);

  //                   return (<p className="text-sm">{date+"-"+month+"-"+year}</p>);
  //                 } else {
  //                   return (<p className="text-sm">Tidak Diketahui</p>);
  //                 }
  //               },

  //               aksi:(col, data) => {
  //                 var button = <button onClick={this.deleteAlumni.bind(this, data.rombel_siswa_id)} className="btn-red text-lg text-white py-1 px-2 rounded-full font-medium"><i className="bx bx-trash"></i> Hapus</button>;
  //                 return (button);
  //               }
  //             }}
  //             url={`${this.state.url}`}
  //             columns=
  //             {[
  //               {
  //                 index: "kode_pendaftaran",
  //                 label: "KODE PENDAFTARAN",
  //               },
  //               {
  //                 index: "tahun_ajaran",
  //                 label: "TAHUN AJARAN",
  //               },
  //               {
  //                 index: "tahun_lulus",
  //                 label: "TAHUN LULUS",
  //               },
  //               {
  //                 index: "kelas",
  //                 label: "KELAS",
  //               },
  //               {
  //                 index: "nama_spektrum",
  //                 label: "PEMINATAN",
  //               },
  //               {
  //                 index: "nama_rombel",
  //                 label: "ROMBEL",
  //               },
  //               {
  //                 index: "nama",
  //                 label: "NAMA",
  //               },
  //               {
  //                   index: "aksi",
  //                   label: "AKSI",
  //               },
  //             ]}
  //           />
  //     </div>
  //   )
  // }

  redirectToSiswaRombel(data) {
    this.setState({ loading_redirect: data });
    axios
      .put(
        this.state.url_put_filter_rombel + "/" + this.state.institusi_id_select,
        {
          tahun_ajaran: data.tahun_ajaran,
          kelas: data.kelas,

          //   bidang        : data.bidang,
          //   bidang_id     : data.bidang_id,
          //   program       : data.program,
          //   program_id    : data.program_id,

          kompetensi: data.nama_spektrum,
          kompetensi_id: data.m_spektrum_id,

          rombel_id: data.id,
          rombel: data.nama_rombel,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ loading_redirect: false });
        window.location.replace(`${conf.uri_frontend}rombel/siswa-alumni`);
      });
  }

  showData() {
    return (
      <div className="px-1 py-1">
        <DataTable
          ref={(dt) => {
            this.dt = dt;
          }}
          action={{
            nama_rombel: (col, data) => {
              return (
                <button
                  onClick={this.redirectToSiswaRombel.bind(this, data)}
                  className="btn-schoolmedia text-xs text-white py-1 px-2 rounded-full font-medium mr-1"
                  disabled={this.state.loading_redirect === data ? true : false}
                >
                  {this.state.loading_redirect === data
                    ? "Tunggu..."
                    : data.nama_rombel}
                </button>
              );
            },
          }}
          url={this.state.url_show_data}
          columns={[
            {
              index: "tahun_ajaran",
              label: "TAHUN AJARAN",
            },
            {
              index: "kelas",
              label: "KELAS",
            },
            {
              index: "nama_rombel",
              label: "ROMBEL",
            },
            {
              index: "siswa_alumni_count",
              label: "JUMLAH",
            },
            {
              index: "penugasan_guru_nama",
              label: "WALI KELAS",
            },
            {
              index: "nama_spektrum",
              label: "PEMINATAN",
            },
          ]}
        />
      </div>
    );
  }

  render() {
    console.log(this.state.data_kelas);
    return (
      <div>
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />
        <div className="sm-container shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border">
          <div className="flex justify-between items-center mt-2  px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Alumni
              <span className="text-gray-500 font-thin block text-sm">
                Daftar Alumni{" "}
                {/* <a href="/sadasd" className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
          </div>
        </div>

        {/* modal tambah */}
        <Modal show={this.state.modal_tambah}>
          <Modal.Header>Tambah Guru</Modal.Header>
          <Modal.Body>
            <form onSubmit={this.create}>
              <div className="w-full mt-1">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  NPSN
                </label>
                <input
                  type="text"
                  className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                  value="XII 345"
                  disabled
                  // onChange={this.nama}
                />
              </div>
              <div className="w-full mt-1">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Nama Sekolah
                </label>
                <input
                  type="text"
                  className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                  value="SMK Indo"
                  disabled
                />
              </div>
              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Tahun Ajaran
                </label>
                <Select
                  className="react-select__control"
                  defaultValue={{
                    label: this.state.tahun_ajaran,
                    value: this.state.tahun_ajaran,
                  }}
                  options={this.state.data_tahun_ajaran}
                  onChange={this.tahun_ajaran}
                />
              </div>
              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Pilih Kelas
                </label>
                <Select
                  className="react-select__control"
                  defaultValue={{
                    label: this.state.tahun_ajaran,
                    value: this.state.tahun_ajaran,
                  }}
                  options={this.state.data_tahun_ajaran}
                  onChange={this.tahun_ajaran}
                />
              </div>
              <div className="w-full mt-1">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Nama Rombel
                </label>
                <input
                  type="text"
                  className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                  onChange={this.nama}
                />
              </div>
              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Nama Wali Kelas
                </label>
                <Select
                  className="react-select__control"
                  defaultValue={{
                    label: this.state.tahun_ajaran,
                    value: this.state.tahun_ajaran,
                  }}
                  options={this.state.data_tahun_ajaran}
                  onChange={this.tahun_ajaran}
                />
              </div>
              <input
                type="submit"
                className="mt-2 bg-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm"
                value="Kirim Data"
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalTambah}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        <div className="sm-container shadow-md mx-auto pt-3 mt-2 rounded-lg bg-white border">
          <div className="flex flex-wrap items-center px-3 pb-2 pt-2">
            <button
              className="btn-blue px-4 mr-2 flex items-center rounded-full text-white py-2 text-xs"
              onClick={() => {
                this.setState({
                  showFilter: !this.state.showFilter,
                });
              }}
            >
              <i className="bx bx-filter text-lg mr-1"></i>
              Pilih
            </button>
            {/* <div className="relative w-1/3">
              <input
                type="search"
                className="w-full pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Cari Alumni"
                onChange={this.searchText}
              />
              <div className="absolute top-0 left-0 inline-flex items-center py-2 px-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 text-gray-400"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                  <circle cx="10" cy="10" r="7"></circle>
                  <line x1="21" y1="21" x2="15" y2="15"></line>
                </svg>
              </div>
            </div> */}
          </div>
          {this.state.showFilter ? (
            <div className="flex w-full border-t flex-wrap items-center my-1 px-2 justify-between">
              {this.state.loadingFilter ? (
                <>
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2} />
                    </div>
                  </div>
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2} />
                    </div>
                  </div>
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2} />
                    </div>
                  </div>
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-wrap items-center">
                    {this.showSelectFilterSD()}
                    {this.showSelectFilterSMA()}
                    {this.showSelectFilterSMK()}
                    {this.showSelectFilterALL()}
                    <div className="flex py-2">
                      <button
                        className={
                          this.state.loading_button == true
                            ? "btn-disabled px-4 py-2 ml-1 flex items-center rounded-full text-sm"
                            : "btn-schoolmedia px-4 py-2 ml-1 flex items-center rounded-full text-white text-sm"
                        }
                        onClick={this.setFilter}
                        disabled={
                          this.state.loading_button == true ? true : false
                        }
                      >
                        {this.state.loading_button == true
                          ? "Tunggu..."
                          : "Cari"}
                      </button>
                    </div>
                  </div>
                  {/* <div className="flex">
                  <button className="btn-red px-4 py-2 ml-1 flex items-center rounded-full text-white text-sm">
                      Hapus Alumni
                  </button>
                </div> */}
                </>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
          {this.showData()}
        </div>
      </div>
    );
  }
}
