import React from "react";
import DataTable from "./../../partials/table/DataTables.js";
import { Link } from "react-router-dom";
import conf from "./../../../config.js";
import SelectJs from "./../../partials/Select.js";
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import { Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import SweetAlert from 'sweetalert2-react';
import swal from 'sweetalert2';

import "react-datepicker/dist/react-datepicker.css";
import { UserEditSkeleton, DetailSkeleton, UserButtonLoading } from "../../partials/loadings/ComponentLoading.js";

// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '14rem' }),
};

export default class PenugasanLainnya extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // url API
      url                   : `${conf.uri_backend}api/v1/penugasan_lainnya/table_penugasan_lainnya/`,
      url_get_lainnya       : `${conf.uri_backend}api/v1/penugasan_lainnya/select_penugasan_lainnya/`,
      url_get_penugasan     : `${conf.uri_backend}api/v1/penugasan_lainnya/select_penugasan`,
      url_create_penugasan  : `${conf.uri_backend}api/v1/penugasan_lainnya/create`,
      url_detail_penugasan  : `${conf.uri_backend}api/v1/penugasan_lainnya/edit_penugasan/`,
      url_put_penugasan     : `${conf.uri_backend}api/v1/penugasan_lainnya/update_penugasan`,
      url_delete_penugasan  : `${conf.uri_backend}api/v1/penugasan_lainnya/delete_penugasan/`,
      url_delete_penugasan_m: `${conf.uri_backend}api/v1/penugasan_lainnya/penugasan_destroy/`,
      url_pindah_ta         : `${conf.uri_backend}api/v1/penugasan_lainnya/tahun_ajaran/update/`,

      modal_tambah  : false,
      modal_edit    : false,

      id                : '',
      nama              : '',
      lainnya_lama      : '',
      nama_lainnya      : '',
      penugasan         : '',
      penugasan_lama    : '',
      penugasan_nama    : '',
      keterangan_tugas  : '',
      tahun_ajaran      : '',
      tahun_ajaran_lama : '',

      data_nama       :[],
      data_penugasan  :[],

      tahunSekarang : new Date().getFullYear(),
      startDate     : new Date(),

      // alert
      show_alert    : false,
      title_alert   : '',
      message_alert : '',
      icon_alert    : '',

      // search and filter
      searchText        : '',
      filterTahunAjaran : '',
      filterPenugasan   : '',
      filterPindahTa    : '',

      institusi_id_select : '',
      data_session        : [],

      isChecked:false,
      check_item            : false,
      checkAll              : false,
      loading_check         : false,
      loading_delete        : false,
    };

    this.nama             = this.nama.bind(this);
    this.penugasan        = this.penugasan.bind(this);
    this.tahun_ajaran     = this.tahun_ajaran.bind(this);
    this.keterangan_tugas = this.keterangan_tugas.bind(this);

    this.getNamaLainnya = this.getNamaLainnya.bind(this);
    this.getPenugasan   = this.getPenugasan.bind(this);
    
    this.deletePenugasan = this.deletePenugasan.bind(this);

    // Create Funtion
    this.showModalTambah = this.showModalTambah.bind(this);
    this.hideModalTambah = this.hideModalTambah.bind(this);

    // Create Funtion
    this.showModalEdit = this.showModalEdit.bind(this);
    this.hideModalEdit = this.hideModalEdit.bind(this);

    this.create_penugasan = this.create_penugasan.bind(this);
    this.update_penugasan = this.update_penugasan.bind(this);

    // Reset Search Function
    this.resetSearch = this.resetSearch.bind(this);

    // filter and search
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterPindahTa     = this.filterPindahTa.bind(this);
    this.filterPenugasan   = this.filterPenugasan.bind(this);
    this.searchText        = this.searchText.bind(this);
    this.setFilter         = this.setFilter.bind(this);

    // get option filter
    this.getTahunAjaranNow  = this.getTahunAjaranNow.bind(this);

    this.checkAll           = this.checkAll.bind(this);
    this.unCheckAll         = this.unCheckAll.bind(this);
    this.savePindahTa       = this.savePindahTa.bind(this);
    this.pindahTa           = this.pindahTa.bind(this);
    this.hideModalTa        = this.hideModalTa.bind(this);
    this.deleteMulti        = this.deleteMulti.bind(this);
  }

  nama(e){
    this.setState({ nama: e.value });
  }

  penugasan(e){
    this.setState({ penugasan: e.value });
  }

  keterangan_tugas(e){
    this.setState({ keterangan_tugas: e.target.value });
  }

  tahun_ajaran(e){
    this.setState({ tahun_ajaran: e.value });
  }


  componentDidMount() {
    // get session user
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ data_session: res.data }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({ 
              institusi_id_select : res.data.id,
              jenjang_id_select   : res.data.m_struktur_jenjang_id,
              npsn_id_select      : res.data.npsn,
            }, function(){
              this.dt.fetchEntities();
              this.getPenugasan();
              this.getTahunAjaranNow();
              this.getNamaLainnya(res.data.id);
            });
          });
        });
      });
    }
  }

  getTahunAjaranNow(){
    var ta              = [];
    var u               = this.state.tahunSekarang + 1;
    var tahun_sekarang  = this.state.tahunSekarang + 1;

    for(var i=tahun_sekarang; i >= 2005; i--){
      u = u -1;
      ta.push({"value":u+' / '+i, "label":u+' / '+i});
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  // Create Funtion
  showModalTambah(){
    this.setState({ modal_tambah:true });
  }

  hideModalTambah(){
    this.setState({ 
      modal_tambah      : false,
      nama              : '',
      penugasan         : '',
      keterangan_tugas  : '',
      tahun_ajaran      : ''
    });
  }

  alertMessage(e){
    let message = null;

    switch(e.status){
      case 422:
        message = e.data.messages[0];
      break;
      case 500:
        message= "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
      break;
      case 401:
        message = "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 400:
        message = "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 408:
        message = "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 429:
        message = "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 405:
        message = "Method Tidak Diperbolehkan, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
    }

    this.setState({
      show_alert    : true,
      title_alert   : "Gagal",
      icon_alert    : "error",
      message_alert : message,
      simpleLoading: false,
    })
  }

  create_penugasan(event){
    event.preventDefault();

    this.setState({simpleLoading: true});
    var nama              = this.state.nama;
    var penugasan         = this.state.penugasan;
    var keterangan_tugas  = this.state.keterangan_tugas;
    var tahun_ajaran      = this.state.tahun_ajaran;

    axios.post(this.state.url_create_penugasan, {
      lainnya_id        : nama,
      penugasan_id      : penugasan,
      tahun_ajaran      : tahun_ajaran,
      keterangan_tugas  : keterangan_tugas,
      institusi_id      : this.state.institusi_id_select,
      created_by        : this.state.institusi_id_select,
      created_at        : new Date(),
    }, {headers:{Authorization:tokenApi}})
      .then(res => {
        this.setState({ 
          show_alert    : true,
          title_alert   : "Berhasil",
          icon_alert    : "success",
          message_alert : res.data.messages[0]
        });
        this.setState({simpleLoading: false});
        this.hideModalTambah();
        this.dt.fetchEntities();
      })
      .catch(err => {
        this.setState({simpleLoading: false});
        this.alertMessage(err.response)
      })
  }

  // Create Funtion
  showModalEdit(data){
    this.setState({loading_edit: true, modal_edit: true})
    axios.get(this.state.url_detail_penugasan+data, {headers:{Authorization:tokenApi}})
    .then(res => {
        if(res.data.status == true){
          this.setState({ 
            id                : res.data.data.id,
            nama              : res.data.data.lainnya_id,
            lainnya_lama      : res.data.data.lainnya_id,
            nama_lainnya      : res.data.data.nama,
            penugasan         : res.data.data.m_penugasan_id,
            penugasan_lama    : res.data.data.m_penugasan_id,
            penugasan_nama    : res.data.data.nama_penugasan,
            tahun_ajaran      : res.data.data.tahun_ajaran,
            tahun_ajaran_lama : res.data.data.tahun_ajaran,
            keterangan_tugas  : res.data.data.keterangan_tugas,
            loading_edit      : false
          });
        } else {
          console.log("error");
        }
    })
  }

  hideModalEdit(){
    this.setState({ 
      modal_edit        : false,
      id                : '',
      lainnya_id        : '',
      penugasan_id      : '',
      penugasan_lama    : '',
      keterangan_tugas  : '',
      tahun_ajaran      : '',
      tahun_ajaran_lama : '',
      lainnya_lama      : '',
    });
  }

  update_penugasan(event){
    event.preventDefault();

    this.setState({loading_saveedit: true})
    axios.put(this.state.url_put_penugasan, {
      id                : this.state.id,
      lainnya_id        : this.state.nama,
      penugasan_id      : this.state.penugasan,
      penugasan_lama    : this.state.penugasan_lama,
      keterangan_tugas  : this.state.keterangan_tugas,
      tahun_ajaran      : this.state.tahun_ajaran,
      tahun_ajaran_lama : this.state.tahun_ajaran_lama,
      lainnya_id_lama   : this.state.lainnya_lama,
      updated_at        : new Date(),
      updated_by        : this.state.institusi_id_select,
    }, {headers:{Authorization:tokenApi}})
      .then(res => {
        this.setState({ 
          show_alert    : true,
          title_alert   : "Berhasil",
          icon_alert    : "success",
          message_alert : res.data.messages[0]
        });
        this.hideModalEdit();
        this.dt.fetchEntities();
        this.setState({loading_saveedit: false});
      })
      .catch(err => {
        this.setState({loading_saveedit: false});
        this.alertMessage(err.response)
      })
  }

  getNamaLainnya(institusi_id_select){
    axios.get(this.state.url_get_lainnya+institusi_id_select, {headers:{Authorization:tokenApi}})
      .then(res => {
        this.setState({data_nama: res.data});
      });
  }

  getPenugasan(){
    axios.get(this.state.url_get_penugasan, {headers:{Authorization:tokenApi}})
      .then(res => {
        this.setState({data_penugasan: res.data});
      });
  }

  deletePenugasan(data){
    this.setState({loading_delete: true});

    swal.fire({
      title: 'Peringatan',
      text: 'Apakah anda yakin menghapus penugasan tersebut?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Iya`,
      cancelButtonText: 'Button'
    }).then((result) => {
      if (result.value == true) {
        axios.delete(this.state.url_delete_penugasan+data, {headers:{Authorization:tokenApi}})
          .then(res => {
            this.setState({ 
              show_alert    : true,
              title_alert   : "Berhasil",
              icon_alert    : "success",
              message_alert : res.data.messages[0],
              loading_delete: false,
            });
            this.dt.resetPage(`&query=${document.getElementById("search").value}&tahun_ajaran=${this.state.filterTahunAjaran}&penugasan=${this.state.filterPenugasan}`);
          })
          .catch(err => {
            this.setState({loading_delete: false})
            this.alertMessage(err.response); 
          })
      } else if (result.dismiss == "cancel") {
        this.setState({loading_delete: false});
      } else if (result.dismiss == "backdrop") {
          this.setState({loading_delete: false});
        }
    })    
  }

  // reset search
  resetSearch(event){
    event.preventDefault();
    document.getElementById("search").value = "";
    this.dt.fetchEntities();
  }

  searchText(){
    this.dt.loading();
    this.dt.resetPage(`&query=${document.getElementById("search").value}&tahun_ajaran=${this.state.filterTahunAjaran}&penugasan=${this.state.filterPenugasan}`);
  }

  filterTahunAjaran(e){
    if(e){
      this.setState({ filterTahunAjaran:e.value });
    } else {
      this.setState({ filterTahunAjaran:'' });
    }
  }
  
  filterPindahTa(e){
    if(e){
      this.setState({ filterPindahTa:e.value });
    } else {
      this.setState({ filterPindahTa:'' });
    }
  }

  filterPenugasan(e){
    if(e){
      this.setState({ filterPenugasan:e.value });
    } else {
      this.setState({ filterPenugasan:'' });
    }
  }

  setFilter(e){
    e.preventDefault();
    this.searchText();
    this.dt.loading();
    this.dt.resetPage(`&query=${document.getElementById("search").value}&tahun_ajaran=${this.state.filterTahunAjaran}&penugasan=${this.state.filterPenugasan}`);
  }

  checkAll(e){
    e.preventDefault();
    this.setState({ loading_check : true }, function(){
      this.setState({ checkAll:true, loading_check: false });
    })
  }

  unCheckAll(e){
    e.preventDefault();
    this.setState({ loading_check : true }, function(){
      this.setState({ checkAll:false, loading_check: false });
    })
  }
  
  deleteMulti(){
    this.setState({loading_delete: true});
    var checkboxes = document.getElementsByName('check_data[]');
    var vals = [];
    for (var i=0, n=checkboxes.length;i<n;i++){
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    if(vals.length == 0){
      this.setState({ 
        show_alert     : true, 
        title_alert    : "Gagal",
        icon_alert     : 'warning',
        message_alert  : "Mohon centang data terlebih dahulu",
        disabledFilter : false,
        loading_delete : false,
      });
    } else {
      swal.fire({
        title: 'Peringatan',
        text: 'Apakah anda yakin menghapus penugasan yang dipilih?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Iya`,
        cancelButtonText: 'Batal'
      }).then((result) => {
        if (result.value == true) {
          axios.post(this.state.url_delete_penugasan_m, {id  : vals}, {headers:{Authorization:tokenApi}})
            .then(res => {
              if(res.data.status == false){
                this.setState({ 
                  show_alert      : true,
                  title_alert     : "Gagal",
                  icon_alert      : "error",
                  message_alert   : res.data.messages[0],
                  loading_delete  : false,
                  checkAll        : false,
                });
                this.dt.loading();
                this.dt.resetPage(`&query=${document.getElementById("search").value}&tahun_ajaran=${this.state.filterTahunAjaran}&penugasan=${this.state.filterPenugasan}`);
              } else {
                this.setState({ 
                  show_alert      : true,
                  title_alert     : "Berhasil",
                  icon_alert      : "success",
                  message_alert   : res.data.messages[0],
                  loading_delete  : false,
                  checkAll        : false,
                });
                this.dt.loading();
                this.dt.resetPage(`&query=${document.getElementById("search").value}&tahun_ajaran=${this.state.filterTahunAjaran}&penugasan=${this.state.filterPenugasan}`);
              }
            })
            .catch(err => {
              this.setState({loading_delete : false})
              this.alertMessage(err.response);
            })
        } else if (result.dismiss == "cancel") {
          this.setState({loading_delete: false});
        } else if (result.dismiss == "backdrop") {
          this.setState({loading_delete: false});
        }
      })
    }
  }

  hideModalTa(){
    this.setState({
      modal_pindah_ta : false,
    });
  }
  
  pindahTa(){
    this.setState({
      modal_pindah_ta : true,
    });
  }

  
  pindahTa(){
    var checkboxes = document.getElementsByName('check_data[]');
    var vals = [];
    for (var i=0, n=checkboxes.length;i<n;i++){
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    if(vals.length == 0){
      this.setState({ 
        show_alert     : true, 
        title_alert    : "Gagal",
        icon_alert     : 'warning',
        message_alert  : "Mohon centang data terlebih dahulu",
        disabledFilter : false,
        loading_delete : false,
      });
    } else {
      this.setState({
        modal_pindah_ta : true,
      });
    }
  }

  savePindahTa(){
    var checkboxes = document.getElementsByName('check_data[]');
    var vals = [];
    for (var i=0, n=checkboxes.length;i<n;i++){
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    axios.post(this.state.url_pindah_ta, {id  : vals, tahun_ajaran: this.state.filterPindahTa}, {headers:{Authorization:tokenApi}})
      .then(res => {
        if(res.data.status == false){
          this.setState({ 
            show_alert      : true,
            title_alert     : "Gagal",
            icon_alert      : "error",
            message_alert   : res.data.messages[0],
            loading_delete  : false,
            checkAll        : false,
          });
          this.dt.loading();
          this.dt.resetPage(`&query=${document.getElementById("search").value}&tahun_ajaran=${this.state.filterTahunAjaran}&penugasan=${this.state.filterPenugasan}&mapel=${this.state.filterPengamuSc}`);
        } else {
          this.setState({ 
            show_alert      : true,
            title_alert     : "Berhasil",
            icon_alert      : "success",
            message_alert   : res.data.messages[0],
            loading_delete  : false,
            checkAll        : false,
          });
          this.dt.loading();
          this.dt.resetPage(`&query=${document.getElementById("search").value}&tahun_ajaran=${this.state.filterTahunAjaran}&penugasan=${this.state.filterPenugasan}&mapel=${this.state.filterPengamuSc}`);
        }
      })
      .catch(err => {
        this.setState({loading_delete : false})
        this.alertMessage(err.response);
      })
  }


  render() {
    return (
     <div>
      <div className="sm-container shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border">
        <div className="flex justify-between items-center mt-2  px-6 pb-2">
          <h1 className="text-xl text-gray-800 font-extrabold">
            Penugasan Lainnya
            <span className="text-gray-500 font-thin block text-sm">
              Daftar Penugasan Lainnya 
              {/* <a href="/sadasd" className="text-blue-600 ml-1">
                Pelajari Lebih Lanjut ?
              </a> */}
            </span>
          </h1>
          <div className="flex">
            <button onClick={this.showModalTambah} className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm" onClick={this.showModalTambah}>
              <i className="bx bx-plus text-lg mr-1"></i>
              Tambah Penugasan
            </button>
          </div>
        </div>
      </div>

      {/* alert */}
      <SweetAlert
        show={this.state.show_alert}
        title={this.state.title_alert}
        text={this.state.message_alert}
        type={this.state.icon_alert}
        onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
      />

      {/* Modal Tambah */}
      <Modal show={this.state.modal_tambah} onHide={this.hideModalTambah}>
        <Modal.Header>Tambah Data</Modal.Header>
        <Modal.Body>
          <form onSubmit={this.create_penugasan}>
            <div className="w-full mt-2">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Nama</label>
              <Select
                styles={selectStyles}
                className="react-select__control"
                options={this.state.data_nama}
                placeholder="Pilih Nama"
                onChange={this.nama}
              />
            </div>
            <div className="w-full mt-2">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Penugasan</label>
              <Select
                styles={selectStyles}
                className="react-select__control"
                options={this.state.data_penugasan}
                placeholder="Pilih Penugasan"
                onChange={this.penugasan}
              />
            </div>
            <div className="w-full mt-2">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Keterangan Tugas</label>
              <input 
                type="text" 
                onChange={this.keterangan_tugas} 
                placeholder="Masukkan keterangan tugas" 
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 " 
                />
            </div>
            <div className="w-full mt-2">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Tahun Ajaran</label>
              <Select
                styles={selectStyles}
                className="react-select__control"
                options={this.state.data_tahun_ajaran}
                placeholder="Pilih Tahun Ajaran"
                onChange={this.tahun_ajaran}
              />
            </div>
            <input type="submit" className={this.state.simpleLoading ? "btn-disabled px-4 flex items-center rounded-full py-2 mt-2 text-sm" : "btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"} value={this.state.simpleLoading ? "Tunggu..." : "Tambah"} disabled={this.state.simpleLoading ? true : false}/>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideModalTambah}>Tutup</button>
        </Modal.Footer>
      </Modal>

      {/* Modal Edit */}
      <Modal show={this.state.modal_edit} onHide={this.hideModalEdit}>
        <Modal.Header>Edit Data</Modal.Header>
        {this.state.loading_edit ? <UserEditSkeleton/> :
        <Modal.Body>
          <form onSubmit={this.update_penugasan}>
            <div className="w-full mt-2">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Nama</label>
              <Select
                styles={selectStyles}
                className="react-select__control"
                defaultValue={{ value: this.state.nama, label: this.state.nama_lainnya }}
                options={this.state.data_nama}
                placeholder="Pilih Nama"
                isDisabled={true}
                onChange={this.nama}
              />
            </div>
            <div className="w-full mt-2">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Penugasan</label>
              <Select
                styles={selectStyles}
                className="react-select__control"
                defaultValue={{ value: this.state.penugasan, label: this.state.penugasan_nama }}
                options={this.state.data_penugasan}
                placeholder="Pilih Penugasan"
                onChange={this.penugasan}
              />
            </div>
            <div className="w-full mt-2">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Keterangan Tugas</label>
              <input 
                type="text" 
                defaultValue={this.state.keterangan_tugas}
                placeholder="Pilih Penugasan"
                onChange={this.keterangan_tugas} 
                placeholder="Masukkan keterangan tugas" 
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 " 
                />
            </div>
            <div className="w-full mt-2">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Tahun Ajaran</label>
              <Select
                styles={selectStyles}
                className="react-select__control"
                defaultValue={{ label: this.state.tahun_ajaran, value: this.state.tahun_ajaran }}
                options={this.state.data_tahun_ajaran}
                placeholder="Pilih Tahun Ajaran"
                onChange={this.tahun_ajaran}
              />
            </div>
            {this.state.loading_saveedit == true ? 
              (<button className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm" disabled={this.state.loading_saveedit}><UserButtonLoading/></button>) 
            :
              (<input type="submit" className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm" value='Edit' />)
            }
          </form>
        </Modal.Body>}
        <Modal.Footer>
          <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideModalEdit}>Tutup</button>
        </Modal.Footer>
      </Modal>

      {/* Modal Pindah Ta */}
      <Modal size="sm" show={this.state.modal_pindah_ta} onHide={this.hideModalTa}>
        <Modal.Header>Pindah Penugasan Ke Tahun Ajaran</Modal.Header>
        <Modal.Body>
          <Select
            id="filterTahunAjaran"
            styles={selectStyles}
            isClearable
            options={this.state.data_tahun_ajaran}
            onChange={this.filterPindahTa}
            placeholder= {'Tahun Ajaran'}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.savePindahTa}>Pindah</button>
          <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideModalTa}>Batal</button>
        </Modal.Footer>
      </Modal>

      <div className="sm-container shadow-md  mx-auto py-2 mt-2 rounded-lg bg-white border">
        <div className="flex w-full flex-wrap items-center mt-3 mb-2 px-4 pb-2">
          <button
              className="btn-blue px-4 py-2 mr-1 flex items-center rounded-full text-white text-xs"
              onClick={() => {
                this.setState({
                  showFilter: !this.state.showFilter,
                });
              }}
            >
              <i className="bx bx-filter text-lg mr-1"></i>
              Pilih
          </button>
          <div className="relative w-1/3">
            <input
              type="search"
              className="w-full pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
              placeholder="Cari Nama dan Kode Pendaftaran"
              id="search"
              onChange={this.searchText}
            />
            <div className="absolute top-0 left-0 inline-flex items-center py-2 px-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 text-gray-400"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                <circle cx="10" cy="10" r="7"></circle>
                <line x1="21" y1="21" x2="15" y2="15"></line>
              </svg>
            </div>
          </div>
        </div>
        <div className="flex w-full border-t flex-wrap items-center my-1 pb-2 pt-2">
            {this.state.showFilter ? (
              <div className="flex w-full flex-wrap items-center px-4 pb-2 ">
                <div className="py-2">
                  <Select
                    id="filterTahunAjaran"
                    styles={selectStyles}
                    isClearable
                    options={this.state.data_tahun_ajaran}
                    onChange={this.filterTahunAjaran}
                    placeholder= {'Tahun Ajaran'}
                  />
                </div>
                <div className="py-2 pl-2">
                    <Select
                      id="filterPenugasan"
                      styles={selectStyles}
                      isClearable
                      options={this.state.data_penugasan}
                      onChange={this.filterPenugasan}
                      placeholder= {'Penugasan'}
                    />
                  </div>
                <button onClick={this.setFilter} className="flex btn-schoolmedia text-sm text-white px-4 py-2 ml-2 rounded-full items-center">
                  Cari
                </button>
              </div>
            ) : (
              ""
            )}
        </div>
        <div className="px-2 py-1">
          <div className="px-2 py-1">
            <DataTable
              ref={(dt) => {
                this.dt = dt;
              }}
              action={{
                aksi:(col, data) => {
                  return (
                    <div className="flex items-center">
                      <button onClick={this.showModalEdit.bind(this, data.id_penugasan)} className="btn-yellow text-lg text-white py-1 px-2 rounded-full font-medium mr-1">
                        <i className="bx bx-edit"></i>
                      </button>
                      <button onClick={this.deletePenugasan.bind(this, data.id_penugasan)} className={`${this.state.loading_delete === true ?`btn-gray`:`btn-red`} text-lg text-white py-1 px-2 rounded-full font-medium`} disabled={this.state.loading_delete === true ? true : false}>
                        {this.state.loading_delete === true ?<UserButtonLoading color="text-white" loadingText={null}/>:<i className="bx bx-trash"></i>}
                      </button>
                      {this.state.loading_check == false ? (
                        <input type="checkbox" name="check_data[]" id={data.id_penugasan} value={data.id_penugasan} onChange={this.selectOne}
                          className="select-checkbox ml-2"
                          defaultChecked={this.state.checkAll}
                        />
                      ):("")}
                    </div>
                  );
                },
                
                jenis_kelamin: (col, data) => {
                  var jekel = '';
                  if(col.jenis_kelamin == 1){
                    jekel = "Laki - laki";
                  } else if(col.jenis_kelamin == 0){ 
                    jekel = "Perempuan";
                  } else {
                    jekel = "Belum diketahui";
                  }
                  return (<p className="text-sm">{jekel}</p>);
                },
              }}
              url={this.state.url+this.state.institusi_id_select}
              columns={[
                {
                  index: "tahun_ajaran",
                  label: "TAHUN AJARAN",
                },
                {
                  index: "kode_pendaftaran",
                  label: "KODE Pendaftaran",
                },
                {
                  index: "nama",
                  label: "NAMA",
                  maxWidth: "12rem"
                },
                {
                  index: "tanggal_lahir",
                  label: "TANGGAL LAHIR",
                },
                {
                  index: "jenis_kelamin",
                  label: "Jenis Kelamin",
                },
                {
                  index: "nama_penugasan",
                  label: "PENUGASAN",
                },
                {
                  index: "keterangan_tugas",
                  label: "KETERANGAN TUGAS",
                  maxWidth: "12rem"
                },
                {
                  index: "aksi",
                  label: "Aksi",
                },
              ]}
            />
          </div>
        </div>
      </div>
      {this.state.url_show_data != '' ? (
        <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
          <div className="w-full text-right flex justify-end px-4">
            <div className="flex flex-wrap items-baseline px-3 justify-end">
              <p>Pilih Guru dan klik tombol</p>
              {this.state.checkAll == false ? (
                <button className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm" onClick={this.checkAll}>Pilih Semua</button>
              ) : (
                <button className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm" onClick={this.unCheckAll}>Tidak Pilih Semua</button>
              )}
              <button
                onClick={this.pindahTa} 
                className="btn-green px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                disabled={this.state.loading_pindah_ta == true ? true : false}
              >
                {this.state.loading_pindah_ta ? <UserButtonLoading/> :'Pindah ke Tahun Ajaran'}
              </button>
              <button
                onClick={this.deleteMulti} 
                className="btn-green px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                disabled={this.state.loading_delete == true ? true : false}
              >
                {this.state.loading_delete ? <UserButtonLoading/> :'Hapus'}
              </button>
            </div>
          </div>
        </div>
      ) : ''}
    </div>
    );
  }
}
