import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// import conf from "./../../../../config.js";
// import DataTable from "./../../../partials/table/DataTables.js";
// import tokenApi from "../../../auth/Helpers";
// import { Button, Modal } from "react-bootstrap";
// import TableSkeleton from "../../../partials/table/TableSkeleton";
import conf from "../../../../../../config";
import DataTable from "../../../../../partials/table/DataTables";
import { Button, Modal } from "react-bootstrap";
import tokenApi from "../../../../../auth/Helpers";
import TableSkeleton from "../../../../../partials/table/TableSkeleton";

export default class Kurikulum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table_url: ``,
      sepktrum: [],
      showModal: false,
      kode_mapel: null,
      tabsActive: "I",
      content: [],

      loadingDetail: true,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token") !== null) {
      axios
        .post(`${conf.uri_backend}api/v1/auth/sso/user`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          this.setState({ data_session: res.data }, function() {
            axios
              .get(
                `${conf.uri_backend}api/v1/select-institusi/` +
                  this.state.data_session.access_to,
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                this.setState(
                  {
                    table_url: `${conf.uri_backend}api/v1/referensi/struktur-kurikulum?jenjang_id=2&`,
                  },
                  function() {
                    this.dt.fetchEntities("&jenjang_id=2");
                  }
                );
              });
          });
        });
    }
  }

  render() {
    return (
      <div>
        {this.showDetailModal()}
        <div className="sm-container shadow-md mx-auto py-3 mt-6 rounded-lg bg-white border">
          <div className="flex justify-between items-center mt-2 px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Struktur Kurikulum
              <span className="text-gray-500 font-thin block text-sm">
                Daftar Struktur Kurikulum.
                {/* <a href="/sadasd" className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
          </div>
        </div>
        <div className="sm-container shadow-md mx-auto mt-2 rounded-lg bg-white border">
          <ul className="flex border-b mb-2">
            <li className="-mb-px mr-1">
              <Link
                className="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold"
                to="/referensi/jenis-kurikulum/k13/struktur-kurikulum/sd">
                {/* SD / MI */}
                Struktur Kurikulum
              </Link>
            </li>
            <li className="-mb-px mr-1">
              <Link
                className="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold"
                to="/referensi/jenis-kurikulum/k13/kompetensi-dasar/sd">
                Kompetensi Dasar
              </Link>
            </li>
          </ul>
          <div className="px-2 py-4">{this.renderTableData()}</div>
        </div>
      </div>
    );
  }

  renderTableData() {
    return (
      <DataTable
        ref={(dt) => {
          this.dt = dt;
        }}
        action={{
          aksi: (col, data) => {
            return (
              <div className="text-gray-700 px-4 py-1 flex items-center">
                <button
                  onClick={this.setShowModal.bind(this, data.id)}
                  className="flex bg-schoolmedia text-sm text-white px-4 py-2 rounded-full items-center mr-1"
                >
                  Detail
                </button>
              </div>
            );
          },
        }}
        url={`${conf.uri_backend}api/v1/referensi/struktur-kurikulum?jenjang_id=2&kurikulum=K13&`}
        columns={[
          {
            index: "nama_spektrum",
            label: "PROGRAM PEMINATAN",
          },
          {
            index: "kode_spektrum",
            label: "KODE",
          },
          {
            index: "lama_belajar",
            label: "MASA STUDI",
          },
          {
            index: "aksi",
            label: "AKSI",
          },
        ]}
      />
    );
  }

  renderTableDataDetail(content) {
    const detailStrukturMapel = content.map((peminatan, index) => {
      return (
        <tr key={index}>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.kode_kmp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.kmp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.kode_jkmp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.jkmp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.kode_mp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.mp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.indeks_mpk}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.semester_1}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.semester_2}
            </span>
          </td>
        </tr>
      );
    });
    return (
      <table className="w-full border-gray-400">
        <thead>
          <tr className="text-left">
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600 tracking-wider text-sm">
              KODE
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              KMP
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              KODE
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              JKMP
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              KODE
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              MATA PELAJARAN
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              INDEKS MPK
            </th>
            <th className="top-0  border-gray-700 border-b px-1 py-2 text-gray-600 tracking-wider  text-sm">
              KUOTA SEMESTER 1
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600 tracking-wider  text-sm">
              KUOTA SEMESTER 2
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.loadingDetail ? (
            <>
              <tr>
                <td
                  className=" border-t border-gray-200 userId overflow-hidden"
                  colSpan="100"
                >
                  <TableSkeleton
                    style={{
                      width: "76rem",
                      bottom: "90px",
                      position: "relative",
                    }}
                  />
                </td>
              </tr>
            </>
          ) : (
            <>{detailStrukturMapel}</>
          )}
        </tbody>
      </table>
    );
  }

  setShowModal(show = true, kode_mapel = null) {
    this.setState({ showModal: show, kode_mapel: kode_mapel });
    this.showDataDetail(kode_mapel, "I");
    if (show == false) {
      this.setState({ content: [], tabsActive: "I" });
    }
  }

  showDetailModal() {
    const elements = ["I", "II", "III", "IV", "V", "VI"];
    return (
      <>
        <Modal
          size="xl"
          show={this.state.showModal}
          onHide={() => this.setShowModal(false)}
        >
          <Modal.Body>
            <div className="flex items-start text-xl justify-between p-2 w-full rounded-t">
              Daftar Mata Pelajaran
              <button
                className="flex bg-white text-xl text-black px-4 py-1 rounded-full items-center"
                onClick={() => this.setShowModal(false)}
              >
                ×
              </button>
            </div>
            <div className="relative pb-4 flex-auto">
              <ul className="list-reset flex border-b">
                {elements.map((value, index) => {
                  return (
                    <li className="-mb-px mr-1" key={index}>
                      {this.state.tabsActive == value ? (
                        <a
                          className="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold"
                          href="#"
                          onClick={this.setActiveTabs.bind(this, value)}
                        >
                          {value}
                        </a>
                      ) : (
                        <a
                          className="bg-white inline-block py-2 px-4 hover:text-blue-800 font-semibold"
                          href="#"
                          onClick={this.setActiveTabs.bind(this, value)}
                        >
                          {value}
                        </a>
                      )}
                    </li>
                  );
                })}
              </ul>
              <div className="overflow-x-scroll">
                {this.state.content != undefined
                  ? this.renderTableDataDetail(this.state.content)
                  : ""}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center justify-end p-2 rounded-b">
              <button
                className="flex bg-white text-sm text-gray-800 border-2 px-4 py-2 rounded-full items-center"
                type="button"
                onClick={() => this.setShowModal(false)}
              >
                Tutup
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  setActiveTabs(id) {
    this.setState({
      tabsActive: id,
      // content: {
      //   nasional: undefined,
      //   lintas1: undefined,
      //   lintas2: undefined,
      // },
    });
    this.showDataDetail2(id);
  }

  showDataDetail(kode_mapel, defaults) {
    axios
      .get(
        `${conf.uri_backend}api/v1/referensi/struktur-kurikulum/2/detail?kode_mapel=${kode_mapel}&kelas=${defaults}`,
        {
          headers: {
            Authorization: tokenApi,
          },
        }
      )
      .then((res) => {
        this.setState({ content: res.data });
        this.setState({ loadingDetail: false });
      });
  }

  showDataDetail2(id) {
    this.setState({ loadingDetail: true });
    axios
      .get(
        `${conf.uri_backend}api/v1/referensi/struktur-kurikulum/2/detail?kode_mapel=${this.state.kode_mapel}&kelas=${id}`,
        {
          headers: {
            Authorization: tokenApi,
          },
        }
      )
      .then((res) => {
        this.setState({ content: res.data });
        this.setState({ loadingDetail: false });
      });
  }
}
