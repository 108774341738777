import React, {useState} from 'react';
import { Link } from "react-router-dom";
import conf from "./../../../config.js";
import Select from 'react-select';
import {Tab, Tabs} from 'react-bootstrap';
import DataTable from "./../../partials/table/DataTables.js";



export default class IndexJadwalPelajaran extends React.Component{
    constructor(props){
        super(props);
        this.state = {

            url: '',

        };
        // Bind Data
        
    }

    componentDidMount(){
    }

    render () {
        return(
            <div>
                <div className="sm-container shadow-md mx-auto py-3 mt-6 rounded-lg bg-white border">
                    <div className="flex justify-between items-center mt-2 px-6 pb-2">
                        <h1 className="text-xl text-gray-800 font-extrabold">
                            Jadwal Pelajaran
                            <span className="text-gray-500 font-thin block text-sm">
                                Buat Jadwal Pelajaran. 
                                {/* <a href="/sadasd" className="text-blue-600">
                                    Pelajari Lebih Lanjut ?
                                </a> */}
                            </span>
                        </h1>
                        <div className="flex">
                            <Link to="/jadwal-pelajaran/atur-waktu">
                                <button className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm">
                                    <i className="bx bx-plus text-lg mr-1"></i>
                                    Tambah Jadwal Pelajaran
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="sm-container flex flex-row shadow-md px-4  mx-auto py-4 mt-2 rounded-lg bg-white border">
                    <div className="w-1/2 px-4 py-4 flex flex-row border rounded-lg bg-1 mx-2 text-center">
                        <div className="m-auto w-1/2 flex flex-col py-5 text-center">
                            <h3 className="text-lg font-bold mb-2 text-white">Jadwal Pelajaran Siswa</h3>
                            <Link className="m-auto" to="/jadwal-pelajaran/jadwal-siswa">
                                <button className="btn-yellow px-4 flex items-center justify-center w-20 rounded-full text-white font-bold py-2 text-sm">
                                    <i className="bx bx-info-circle text-lg mr-1"></i>
                                    Lihat
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="w-1/2 px-4 py-4 flex flex-row border rounded-lg bg-2 mx-2 text-center">
                        <div className="m-auto w-1/2 flex flex-col py-5 text-center">
                            <h3 className="text-lg text-white font-bold mb-2">Jadwal Guru</h3>
                            <Link className="m-auto" to="/jadwal-pelajaran/jadwal-guru">
                                <button className="btn-yellow px-4 flex items-center justify-center w-20 rounded-full text-white font-bold py-2 text-sm">
                                    <i className="bx bx-info-circle text-lg mr-1"></i>
                                    Lihat
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}