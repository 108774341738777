import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import conf from "./../../../config.js";
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import { render } from "@testing-library/react";
import SideBarJadwal from "./component/SideBarJadwal.js";
import { Tab, Tabs, Modal } from "react-bootstrap";
import Select from "react-select";
import SweetAlert from "sweetalert2-react";
import { TombolKembali } from "../../partials/TombolKembali.js";
import Skeleton from "react-loading-skeleton";

// select style
const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "100px",
    minWidth: "15rem",
    width: "100%",
  }),
};

const dataKurikulum = [
  { value: "k13", label: "K13" },
  { value: "merdeka", label: "Merdeka" },
];

const semester = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
];

function captFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default class AturMapelRombel extends React.Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
    this.state = {
      url_get_rombel: `${conf.uri_backend}api/v1/rombel/get_rombel`,
      url_get_mapel: `${conf.uri_backend}api/v1/jadwal/atur_mapel_per_rombel`,
      url_get_mapel_rombel: `${conf.uri_backend}api/v1/jadwal/mapel_per_rombel`,
      url_create_mapel: `${conf.uri_backend}api/v1/jadwal/create_mapel_per_rombel`,
      url_get_spektrum: `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_filter: `${conf.uri_backend}api/v1/rombel/filter`,
      url_get_smk: `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,
      url_waktu: `${conf.uri_backend}api/v1/jadwal/waktu`,
      url_check_rombel: `${conf.uri_backend}api/v1/jadwal/check-checked`,
      tahunSekarang: new Date().getFullYear(),
      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",
      showMode: false,
      disabledFilter: false,
      loadingFilter: true,
      // search
      tahun_ajaran_search: "",
      kelas_search: "",
      peminatan_search: "",
      rombel_search: "",
      rombel_id: "",
      bidang_search: "",
      program_search: "",
      kompetensi_search: "",
      kurikulum_search: "",

      // data filter
      data_spektrum: "",
      data_bidang: "",
      data_program: "",
      data_kompetensi: "",
      data_rombel: "",
      loading_show_data: false,
      modal_preview: false,

      data_mapel: [],
    };
    // get option filter
    this.getTahunAjaranNow = this.getTahunAjaranNow.bind(this);
    this.getKelas = this.getKelas.bind(this);
    this.getKurikulum = this.getKurikulum.bind(this);
    this.getPeminatanSMA = this.getPeminatanSMA.bind(this);
    this.getDataFilterSMA = this.getDataFilterSMA.bind(this);
    this.getBidang = this.getBidang.bind(this);
    this.getProgram = this.getProgram.bind(this);
    this.getKompetensi = this.getKompetensi.bind(this);
    // binding data
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterKurikulum = this.filterKurikulum.bind(this);
    this.filterRombel = this.filterRombel.bind(this);
    this.filterKelas = this.filterKelas.bind(this);
    this.filterSemester = this.filterSemester.bind(this);
    this.filterPeminatan = this.filterPeminatan.bind(this);
    this.filterBidang = this.filterBidang.bind(this);
    this.filterProgram = this.filterProgram.bind(this);
    this.filterKompetensi = this.filterKompetensi.bind(this);
    // show select filter
    this.showSelectFilterSD = this.showSelectFilterSD.bind(this);
    this.showSelectFilterSMA = this.showSelectFilterSMA.bind(this);
    this.showSelectFilterKurikulum = this.showSelectFilterKurikulum.bind(this);
    this.showSelectFilterSMK = this.showSelectFilterSMK.bind(this);
    this.showSelectFilterALL = this.showSelectFilterALL.bind(this);
    this.showSelectFilterSemester = this.showSelectFilterSemester.bind(this);
    // processing data
    this.setMapel = this.setMapel.bind(this);
    this.showData = this.showData.bind(this);
    this.showMataPelajaran = this.showMataPelajaran.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
    this.simpanMapel = this.simpanMapel.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.hidePreview = this.hidePreview.bind(this);
    this.submitSave = this.submitSave.bind(this);
    this.sameValue = this.sameValue.bind(this);
    this.cekFilterJenjang = this.cekFilterJenjang.bind(this);

    this.changeVal = this.changeVal.bind(this);
  }

  componentDidMount() {
    // get session user
    if (localStorage.getItem("token") !== null) {
      axios
        .post(`${conf.uri_backend}api/v1/auth/sso/user`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          this.setState({ data_session: res.data }, function() {
            axios
              .get(
                `${conf.uri_backend}api/v1/select-institusi/` +
                  this.state.data_session.access_to,
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                this.setState(
                  {
                    institusi_id_select: res.data.id,
                    jenjang_id_select: res.data.m_struktur_jenjang_id,
                    npsn_id_select: res.data.npsn,
                  },
                  function() {}
                );
                this.getTahunAjaranNow();
                this.getDataFilterSMA();
                this.getBidang("create");
              });
          });
        });
    }
  }

  alertMessage(e) {
    let message = null;

    switch (e.status) {
      case 422:
        message = e.data.messages[0];
        break;
      case 500:
        message =
          "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
        break;
      case 401:
        message =
          "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 400:
        message =
          "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 408:
        message =
          "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 429:
        message =
          "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
    }

    this.setState({
      show_alert: true,
      title_alert: "Gagal",
      icon_alert: "error",
      message_alert: message,
      simpleLoading: false,
    });
  }

  getKuotaWaktu() {
    axios
      .get(
        this.state.url_waktu +
          "?institusi=" +
          this.state.institusi_id_select +
          `&kurikulum=${this.state.kurikulum_search}&tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&semester=${this.state.semester_search}`,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ kuota_minggu: res.data });
      });
  }

  getTahunAjaranNow() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  getKelas() {
    var kelas = [];
    if (this.state.jenjang_id_select == "2") {
      kelas = [
        { value: "I", label: "I" },
        { value: "II", label: "II" },
        { value: "III", label: "III" },
        { value: "IV", label: "IV" },
        { value: "V", label: "V" },
        { value: "VI", label: "VI" },
      ];
    } else if (this.state.jenjang_id_select == "3") {
      kelas = [
        { value: "VII", label: "VII" },
        { value: "VIII", label: "VIII" },
        { value: "IX", label: "IX" },
      ];
    } else if (this.state.jenjang_id_select == "4") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
      ];
    } else if (this.state.jenjang_id_select == "5") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
        { value: "XIII", label: "XIII" },
      ];
    }
    this.setState({ data_kelas: kelas });
  }

  getKurikulum() {
    this.setState({ data_kurikulum: dataKurikulum });
  }

  getPeminatanSMA() {
    this.setState({ loading_peminatan: !this.state.loading_peminatan });
    if (this.state.jenjang_id_select == "4") {
      axios
        .get(
          this.state.url_get_spektrum +
            "?jenjang=" +
            this.state.jenjang_id_select +
            "&kurikulum=" +
            this.state.kurikulum_search,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (res.data.status == true) {
            this.setState({ data_spektrum: res.data.data });
            this.setState({ loading_peminatan: false });
          } else {
            this.setState({ data_spektrum: [] });
            this.setState({ loading_peminatan: false });
          }
        });
    }
  }

  getRombel() {
    this.setState({ loading_rombel: !this.state.loading_rombel });
    var peminatan = "";
    if (
      this.state.jenjang_id_select == "5"
        ? (peminatan = this.state.kompetensi_search)
        : (peminatan = this.state.peminatan_search)
    );
    if (this.state.kurikulum_search == null) {
      this.setState({
        peminatan_search: "",
      });
    }
    axios
      .get(
        `${this.state.url_get_rombel}?tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&peminatan=${peminatan}&user_institusi_id=${this.state.institusi_id_select}&kurikulum=${this.state.kurikulum_search}`,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({
          data_rombel: res.data,
          loadingFilter: false,
          loading_rombel: false,
        });
      });
  }

  // SMK
  getBidang(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_bidang: !this.state.loading_bidang });
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=0&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`,
          {
            headers: { Authorization: tokenApi },
          }
        )
        .then((res) => {
          if (create != null) {
            this.setState({ data_bidang_p: res.data });
          } else {
            this.setState({ data_bidang: res.data });
            this.setState({ loading_bidang: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_bidang: false });
        });
    }
  }

  getProgram(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_program: !this.state.loading_program });
      var bidang = "";
      if (
        create != null
          ? (bidang = this.state.bidang)
          : (bidang = this.state.bidang_search)
      );
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=${bidang}&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`,
          {
            headers: { Authorization: tokenApi },
          }
        )
        .then((res) => {
          if (create != null) {
            this.setState({ data_program_p: res.data });
          } else {
            this.setState({ data_program: res.data });
            this.setState({ loading_program: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_program: false });
        });
    }
  }

  getKompetensi(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_kompetensi: !this.state.loading_kompetensi });
      var program = "";
      if (
        create != null
          ? (program = this.state.program)
          : (program = this.state.program_search)
      );
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=${program}&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`,
          {
            headers: { Authorization: tokenApi },
          }
        )
        .then((res) => {
          if (create != null) {
            this.setState({ data_kompetensi_p: res.data });
          } else {
            this.setState({ data_kompetensi: res.data });
            this.setState({ loading_kompetensi: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_kompetensi: false });
        });
    }
  }

  getDataFilterSMA() {
    axios
      .get(this.state.url_get_filter + "/" + this.state.institusi_id_select, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        if (res.data.status == true) {
          this.setState(
            {
              tahun_ajaran_search:
                res.data.data.tahun_ajaran != null
                  ? res.data.data.tahun_ajaran
                  : "",
              kelas_search:
                res.data.data.kelas !== null ? res.data.data.kelas : "",
              peminatan_search:
                res.data.data.kompetensi_id !== null
                  ? res.data.data.kompetensi_id
                  : "",
              peminatan_search_label:
                res.data.data.kompetensi !== null
                  ? res.data.data.kompetensi
                  : "",
              bidang_search:
                res.data.data.bidang_id !== null ? res.data.data.bidang_id : "",
              bidang_search_label:
                res.data.data.bidang !== null ? res.data.data.bidang : "",
              program_search:
                res.data.data.program_id !== null
                  ? res.data.data.program_id
                  : "",
              program_search_label:
                res.data.data.program !== null ? res.data.data.program : "",
              kompetensi_search:
                res.data.data.kompetensi_id !== null
                  ? res.data.data.kompetensi_id
                  : "",
              kompetensi_search_label:
                res.data.data.kompetensi !== null
                  ? res.data.data.kompetensi
                  : "",
              rombel_search:
                res.data.data.rombel_id !== null ? res.data.data.rombel_id : "",
              rombel_search_label:
                res.data.data.rombel !== null ? res.data.data.rombel : "",
              semester_search:
                res.data.data.semester !== null ? res.data.data.semester : "",
              kurikulum_search:
                res.data.data.kurikulum !== null ? res.data.data.kurikulum : "",
            },
            function() {
              this.getTahunAjaranNow();
              this.getKelas();
              this.getKurikulum();
              this.getPeminatanSMA();
              this.getRombel();
              this.getBidang();
              this.getProgram();
              if (
                this.state.jenjang_id_select == "5" &&
                this.state.kurikulum_search === "k13"
              ) {
                this.getKompetensi();
              }
              this.getKuotaWaktu();
            }
          );
        } else {
          this.setState({ loadingFilter: false });
        }
      });
  }

  // SHOW TYPE FILTER PER JENJANG
  showSelectFilterSD() {
    if (this.state.loadingFilter == false && this.state.data_tahun_ajaran) {
      return (
        <>
          <div className="flex w-1/4">
            <div className="py-2 mr-2">
              <Select
                id="filterTahunAjaran"
                styles={selectStyles}
                isDisabled={this.state.disabledFilter}
                defaultValue={
                  this.state.tahun_ajaran_search != ""
                    ? {
                        value: this.state.tahun_ajaran_search,
                        label: this.state.tahun_ajaran_search,
                      }
                    : ""
                }
                options={this.state.data_tahun_ajaran}
                onChange={this.filterTahunAjaran}
                placeholder={"Pilih Tahun Ajaran"}
              />
            </div>
          </div>
          {this.state.data_kelas && this.state.tahun_ajaran_search !== "" ? (
            <div className="flex w-1/4">
              <div className="py-2 mr-2">
                <Select
                  id="filterKelas"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.kelas_search != ""
                      ? {
                          value: this.state.kelas_search,
                          label: this.state.kelas_search,
                        }
                      : ""
                  }
                  options={this.state.data_kelas}
                  onChange={this.filterKelas}
                  placeholder={"Pilih Kelas"}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  showSelectFilterKurikulum() {
    if (
      this.state.loadingFilter == false &&
      this.state.data_kurikulum != "" &&
      this.state.kelas_search != ""
    ) {
      return (
        <>
          <div className="flex w-1/4">
            <div className="py-2 mr-2">
              <Select
                styles={selectStyles}
                className="react-select__control"
                placeholder="Pilih Kurikulum"
                isDisabled={this.state.disabledFilter}
                // isMulti
                defaultValue={
                  this.state.kurikulum_search != ""
                    ? {
                        value: this.state.kurikulum_search,
                        label: captFirstLetter(this.state.kurikulum_search),
                      }
                    : ""
                }
                options={this.state.data_kurikulum}
                onChange={this.filterKurikulum}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  showSelectFilterSMA() {
    if (
      this.state.loadingFilter == false &&
      this.state.jenjang_id_select == "4"
    ) {
      if (
        this.state.data_spektrum &&
        this.state.kelas_search !== "" &&
        this.state.kurikulum_search !== ""
      ) {
        return (
          <>
            <div className="flex w-1/4">
              <div className="py-2 mr-2">
                <Select
                  id="filterPeminatan"
                  styles={selectStyles}
                  isLoading={this.state.loading_peminatan ? true : false}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.peminatan_search != ""
                      ? {
                          value: this.state.peminatan_search,
                          label: this.state.peminatan_search_label,
                        }
                      : ""
                  }
                  options={this.state.data_spektrum}
                  onChange={this.filterPeminatan}
                  placeholder={"Pilih Peminatan"}
                />
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div className="w-1/4 px-1">
            <div className="py-2">
              <Skeleton count={2} />
            </div>
          </div>
        );
      }
    }
  }

  showSelectFilterSMK() {
    if (
      this.state.loadingFilter == false &&
      this.state.jenjang_id_select == "5" &&
      this.state.kurikulum_search === "k13"
    ) {
      return (
        <>
          {this.state.kelas_search != "" ? (
            <div className="flex w-1/4">
              <div className="py-2 ml-2">
                <Select
                  id="filterBidang"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.bidang_search != ""
                      ? {
                          value: this.state.bidang_search,
                          label: this.state.bidang_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_bidang ? true : false}
                  options={this.state.data_bidang}
                  onChange={this.filterBidang}
                  className="animateFadeInLeft"
                  placeholder={
                    this.state.loading_bidang ? "Loading" : "Bidang Keahlian"
                  }
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {this.state.bidang_search != "" ? (
            <div className="flex w-1/4">
              <div className="py-2 ml-2">
                <Select
                  id="filterProgram"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.program_search != ""
                      ? {
                          value: this.state.program_search,
                          label: this.state.program_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_program ? true : false}
                  options={this.state.data_program}
                  onChange={this.filterProgram}
                  placeholder={
                    this.state.loading_program ? "Loading" : "Program Keahlian"
                  }
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.program_search != "" ? (
            <div className="flex w-1/4">
              <div className="py-2 mr-2">
                <Select
                  id="filterKompetensi"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.kompetensi_search != ""
                      ? {
                          value: this.state.kompetensi_search,
                          label: this.state.kompetensi_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_kompetensi ? true : false}
                  options={this.state.data_kompetensi}
                  onChange={this.filterKompetensi}
                  placeholder={
                    this.state.loading_kompetensi
                      ? "Loading"
                      : "Kompetensi Keahlian"
                  }
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    }

    // For select kurikulum merdeka
    if (
      this.state.loadingFilter == false &&
      this.state.jenjang_id_select == "5" &&
      this.state.kurikulum_search === "merdeka"
    ) {
      return (
        <>
          {this.state.kelas_search != "" ? (
            <div className="flex w-1/4">
              <div className="py-2 ml-2">
                <Select
                  id="filterBidang"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.bidang_search != ""
                      ? {
                          value: this.state.bidang_search,
                          label: this.state.bidang_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_bidang ? true : false}
                  options={this.state.data_bidang}
                  onChange={this.filterBidang}
                  className="animateFadeInLeft"
                  placeholder={
                    this.state.loading_bidang ? "Loading" : "Bidang Keahlian"
                  }
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {this.state.bidang_search != "" ? (
            <div className="flex w-1/4">
              <div className="py-2 ml-2">
                <Select
                  id="filterProgram"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.program_search != ""
                      ? {
                          value: this.state.program_search,
                          label: this.state.program_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_program ? true : false}
                  options={this.state.data_program}
                  onChange={this.filterProgram}
                  placeholder={
                    this.state.loading_program ? "Loading" : "Program Keahlian"
                  }
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  showSelectFilterALL() {
    if (
      this.state.loadingFilter == false &&
      this.state.data_rombel &&
      this.cekFilterJenjang("peminatan", this.state.peminatan_search) &&
      this.cekFilterJenjang("kompetensi", this.state.kompetensi_search)
    ) {
      return (
        <>
          <div className="flex w-1/4">
            <div className="py-2 mr-2">
              <Select
                id="filterRombel"
                styles={selectStyles}
                isLoading={this.state.loading_rombel ? true : false}
                isDisabled={this.state.disabledFilter}
                defaultValue={
                  this.state.rombel_search != ""
                    ? {
                        value: this.state.rombel_search,
                        label: this.state.rombel_search_label,
                      }
                    : ""
                }
                options={this.state.data_rombel}
                onChange={this.filterRombel}
                placeholder={"Pilih Rombel"}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  showSelectFilterSemester() {
    if (this.state.loadingFilter == false && this.state.rombel_search != "") {
      return (
        <div className="flex w-1/4">
          <div className="py-2 ml-2 mr-2">
            <Select
              id="filterSemester"
              styles={selectStyles}
              isDisabled={this.state.disabledFilter}
              defaultValue={
                this.state.semester_search != ""
                  ? {
                      value: this.state.semester_search,
                      label: this.state.semester_search,
                    }
                  : ""
              }
              options={semester}
              onChange={this.filterSemester}
              placeholder={"Pilih Semester"}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  cekFilterJenjang(type, value) {
    var cek = true;
    if (this.state.jenjang_id_select == 4) {
      if (type == "peminatan" && value == "") cek = false;
    }

    if (this.state.jenjang_id_select == 5) {
      if (this.state.kurikulum_search === "k13") {
        if (type == "bidang" && value == "") cek = false;
        if (type == "program" && value == "") cek = false;
        if (type == "kompetensi" && value == "") cek = false;
      }
      if (this.state.kurikulum_search === "merdeka") {
        if (type == "bidang" && value == "") cek = false;
        if (type == "program" && value == "") cek = false;
      }
    }

    return cek;
  }
  // FILTER HANDLE CHANGE FUNCTION
  filterTahunAjaran(e) {
    if (e) {
      this.setState(
        {
          tahun_ajaran_search: e.value,
          kelas_search: "",
          kurikulum_search: "",
          peminatan_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_spektrum: "",
          data_bidang: "",
          data_program: "",
          data_kelas: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getKelas();
        }
      );
    } else {
      this.setState(
        {
          tahun_ajaran_search: "",
          kelas_search: "",
          kurikulum_search: "",
          peminatan_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_spektrum: "",
          data_kelas: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getKelas();
        }
      );
    }
  }
  filterKurikulum(e) {
    if (e) {
      this.setState(
        {
          kurikulum_search: e.value,
          peminatan_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_spektrum: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
        },
        function() {
          if (
            this.state.jenjang_id_select == 2 ||
            this.state.jenjang_id_select == 3
          )
            this.getRombel();
          else if (this.state.jenjang_id_select == 4) this.getPeminatanSMA();
          else if (this.state.jenjang_id_select == 5) this.getBidang();
        }
      );
      console.log(captFirstLetter(this.state.kurikulum_search), "Kurikulume");
    } else {
      this.setState({
        kurikulum_search: "",
        peminatan_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_spektrum: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
      });
    }
  }

  filterKelas(e) {
    if (e) {
      this.setState(
        {
          kelas_search: e.value,
          kurikulum_search: "",
          peminatan_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_kurikulum: "",
          data_spektrum: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getKurikulum();
        }
      );
    } else {
      this.setState({
        kelas_search: "",
        kurikulum_search: "",
        peminatan_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_kurikulum: "",
        data_spektrum: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }

  filterSemester(e) {
    if (e) {
      this.setState({ semester_search: e.value, loading_show_data: false });
    } else {
      this.setState({ semester_search: "" });
    }
  }

  filterPeminatan(e) {
    //Only SMA
    if (e) {
      this.setState(
        {
          peminatan_search: e.value,
          peminatan_search_label: e.label,
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getRombel();
        }
      );
    } else {
      this.setState({
        peminatan_search: "",
        peminatan_search_label: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }

  // only for SMK
  filterBidang(e) {
    if (e) {
      this.setState(
        {
          bidang_search: e.value,
          bidang_search_label: e.label,
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getProgram();
        }
      );
    } else {
      this.setState({
        bidang_search: "",
        bidang_search_label: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }
  filterProgram(e) {
    if (e) {
      this.setState(
        {
          program_search: e.value,
          program_search_label: e.label,
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          if (this.state.kurikulum_search === "k13") {
            this.getKompetensi();
          }
          if (this.state.kurikulum_search === "merdeka") {
            this.getRombel();
          }
        }
      );
    } else {
      this.setState({
        program_search: "",
        program_search_label: "",
        rombel_search: "",
        semester_search: "",
        kompetensi_search: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }

  filterKompetensi(e) {
    if (e) {
      this.setState(
        {
          kompetensi_search: e.value,
          kompetensi_search_label: e.label,
          rombel_search: "",
          semester_search: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getRombel();
        }
      );
    } else {
      this.setState({
        kompetensi_search: "",
        kompetensi_search_label: "",
        rombel_search: "",
        semester_search: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }

  filterRombel(e) {
    if (e) {
      this.setState({
        rombel_id: e.value,
      });
      this.setState({
        rombel_search: e.value,
        rombel_search_label: e.label,
        semester_search: "",
        loading_show_data: false,
      });
    } else {
      this.setState({
        rombel_search: "",
        rombel_search_label: "",
        semester_search: "",
        loading_show_data: false,
      });
    }
  }

  //  List MataPelajaran
  setMapel(e) {
    e.preventDefault();
    var mapel_umum = [];
    var mapel_agama = [];
    var mapel_bahasa = [];
    var mapel_seni = [];
    var mapel_prakarya = [];
    var mapel_peminatan = [];
    var mapel_pilihan_merdeka = [];
    var mapel_mipa = [];
    var mapel_ibb = [];
    var mapel_ips = [];
    var mapel_ibb_bahasa = [];
    var mapel_pilihan_1 = [];
    var mapel_pilihan_2 = [];
    var mapel_pilihan = [];
    var kode_mapel = [];
    var kompetensi = "";
    var kompetensi_label = "";
    var program = "";
    var program_label = "";

    axios
      .post(
        `${this.state.url_get_mapel_rombel}`,
        {
          rombel: this.state.rombel_search,
          semester: this.state.semester_search,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        if (res.data.data.length != 0) {
          this.setState({ availabeData: true });
        } else {
          this.setState({ availabeData: false });
        }
      });

    axios
      .post(
        this.state.url_check_rombel,
        {
          rombelid: this.state.rombel_search,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({
          data_mapel: res.data.data,
        });
      })
      .catch((err) => {});

    if (
      this.state.jenjang_id_select == "5" &&
      this.state.kurikulum_search === "k13"
    ) {
      kompetensi = this.state.kompetensi_search;
      kompetensi_label = this.state.kompetensi_search_label;
    } else if (
      this.state.jenjang_id_select == "5" &&
      this.state.kurikulum_search === "merdeka"
    ) {
      program = this.state.program_search;
      program_label = this.state.program_search_label;
    } else {
      kompetensi = this.state.peminatan_search;
      kompetensi_label = this.state.peminatan_search_label;
    }

    axios.put(
      this.state.url_get_filter + "/" + this.state.institusi_id_select,
      {
        tahun_ajaran: this.state.tahun_ajaran_search,
        kelas: this.state.kelas_search,
        bidang: this.state.bidang_search_label,
        bidang_id: this.state.bidang_search,
        program: program_label,
        program_id: program,
        kompetensi: kompetensi_label,
        kompetensi_id: kompetensi,
        kurikulum: this.state.kurikulum_search,
        rombel_id: this.state.rombel_search,
        rombel: this.state.rombel_search_label,
        semester: this.state.semester_search,
      },
      { headers: { Authorization: tokenApi } }
    );

    this.setState({ disabledFilter: true });
    this.setState({ loadingMapel: true });

    if (this.state.jenjang_id_select == 4) {
      if (this.state.kurikulum_search == "merdeka") {
        kode_mapel = ["umum", "agama", "pilihan"];
      } else {
        kode_mapel = [
          "umum",
          "agama",
          "seni",
          "prakarya",
          "peminatan-mipa",
          "peminatan-ips",
          "peminatan-ibb",
          "peminatan-ibb-bahasa",
        ];
      }
    } 
    // else if (this.state.jenjang_id_select == 5) {
    //   kode_mapel = [
    //     "umum",
    //     "agama",
    //     "seni",
    //     "prakarya",
    //     "peminatan-mipa",
    //     "peminatan-ips",
    //     "peminatan-ibb",
    //     "peminatan-ibb-bahasa",
    //   ];
    // } 
    else if (this.state.jenjang_id_select == 5) {
      if (this.state.kurikulum_search == "k13") {
        kode_mapel = [
          "umum",
          "agama",
          "bahasa",
          "seni",
          "prakarya",
          "peminatan-mipa",
          "peminatan-ips",
          "peminatan-ibb",
          "peminatan-ibb-bahasa",
        ];
      } else if (this.state.kurikulum_search == "merdeka" && this.state.kelas_search == "X") {
        kode_mapel = ["umum", "agama", "bahasa"];
      } else if (this.state.kurikulum_search == "merdeka" && this.state.kelas_search == "XI" || this.state.kelas_search == "XII") {
        kode_mapel = ["umum", "agama", "bahasa", "pilihan"];
      }
    } else if (this.state.jenjang_id_select == 3) {
      kode_mapel = ["umum", "agama", "seni", "prakarya"];
    } else {
      kode_mapel = ["umum", "agama"];
    }

    for (var i = 0; i < kode_mapel.length; i++) {
      axios
        .post(
          `${this.state.url_get_mapel}`,
          {
            kode: kode_mapel[i],
            semester: this.state.semester_search,
            kelas: this.state.kelas_search,
            jenjang_id: this.state.jenjang_id_select,
            bidang_keahlian: this.state.bidang_search,
            program_keahlian: this.state.program_search,
            kompetensi_keahlian: this.state.kompetensi_search,
            kurikulum: this.state.kurikulum_search,
            rombel_id: this.state.rombel_search,
          },
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (
            res.data.kode_mapel == "umum" ? (mapel_umum = res.data.data) : ""
          );
          if (
            res.data.kode_mapel == "agama" ? (mapel_agama = res.data.data) : ""
          );
          if (
            res.data.kode_mapel == "bahasa"
              ? (mapel_bahasa = res.data.data)
              : ""
          );
          // if (res.data.kode_mapel == "mapel_pilihan1" ? (mapel_pilihan1 = res.data.data) : "");
          // if (res.data.kode_mapel == "mapel_pilihan2" ? (mapel_pilihan2 = res.data.data) : "");
          if (
            res.data.kode_mapel == "seni" ? (mapel_seni = res.data.data) : ""
          );
          if (
            res.data.kode_mapel == "prakarya"
              ? (mapel_prakarya = res.data.data)
              : ""
          );
          if (
            res.data.kode_mapel == "peminatan-mipa"
              ? (mapel_mipa = res.data.data)
              : ""
          );
          if (
            res.data.kode_mapel == "peminatan-ips"
              ? (mapel_ips = res.data.data)
              : ""
          );
          if (
            res.data.kode_mapel == "peminatan-ibb"
              ? (mapel_ibb = res.data.data)
              : ""
          );
          if (
            res.data.kode_mapel == "peminatan-ibb-bahasa"
              ? (mapel_ibb_bahasa = res.data.data)
              : ""
          );
          if (
            res.data.kode_mapel == "pilihan"
              ? (mapel_pilihan = res.data.data)
              : ""
          );
          console.log("ini kelas : ", this.state.kelas_search);

          console.log("KODE MAPEL", res.data.kode_mapel)

          if (this.state.jenjang_id_select == "4") {
            if (this.state.kelas_search == "X") {
              for (var i = 0; i < mapel_pilihan.length; i++) {
                mapel_umum.push(mapel_pilihan[i]);
              }
              this.setState({ loadingMapel: false, disabledFilter: false });
            }

            if (this.state.peminatan_search == "4") {
              this.setState({ data_mapel_ibb: "" });
              if (res.data.kode_mapel == "peminatan-mipa") {
                for (var i = 0; i < mapel_mipa.length; i++) {
                  mapel_umum.push(mapel_mipa[i]);
                }
              }
              if (res.data.kode_mapel == "peminatan-ips") {
                for (var i = 0; i < mapel_ips.length; i++) {
                  mapel_peminatan.push(mapel_ips[i]);
                }
              }
              if (res.data.kode_mapel == "peminatan-ibb-bahasa") {
                for (var i = 0; i < mapel_ibb_bahasa.length; i++) {
                  mapel_peminatan.push(mapel_ibb_bahasa[i]);
                }
              }
              if (res.data.kode_mapel == "peminatan-ibb") {
                for (var i = 0; i < mapel_ibb.length; i++) {
                  mapel_peminatan.push(mapel_ibb[i]);
                }
                this.setState({ loadingMapel: false, disabledFilter: false });
              }
              if (res.data.kode_mapel == "pilihan") {
                for (var i = 0; i < mapel_pilihan.length; i++) {
                  mapel_pilihan_merdeka.push(mapel_pilihan[i]);
                }
                this.setState({ loadingMapel: false, disabledFilter: false });
              }
            } else if (this.state.peminatan_search == "5") {
              this.setState({ data_mapel_ibb: "" });
              if (res.data.kode_mapel == "peminatan-ips") {
                for (var i = 0; i < mapel_ips.length; i++) {
                  mapel_umum.push(mapel_ips[i]);
                }
              }
              if (res.data.kode_mapel == "peminatan-mipa") {
                for (var i = 0; i < mapel_mipa.length; i++) {
                  mapel_peminatan.push(mapel_mipa[i]);
                }
              }
              if (res.data.kode_mapel == "peminatan-ibb-bahasa") {
                for (var i = 0; i < mapel_ibb_bahasa.length; i++) {
                  mapel_peminatan.push(mapel_ibb_bahasa[i]);
                }
              }
              if (res.data.kode_mapel == "peminatan-ibb") {
                for (var i = 0; i < mapel_ibb.length; i++) {
                  mapel_peminatan.push(mapel_ibb[i]);
                }
                this.setState({ loadingMapel: false, disabledFilter: false });
              }
            } else if (this.state.peminatan_search == "6") {
              if (res.data.kode_mapel == "peminatan-ibb-bahasa") {
                for (var i = 0; i < mapel_ibb_bahasa.length; i++) {
                  mapel_umum.push(mapel_ibb_bahasa[i]);
                }
              }
              if (res.data.kode_mapel == "peminatan-ips") {
                for (var i = 0; i < mapel_ips.length; i++) {
                  mapel_peminatan.push(mapel_ips[i]);
                }
              }
              if (res.data.kode_mapel == "peminatan-mipa") {
                for (var i = 0; i < mapel_mipa.length; i++) {
                  mapel_peminatan.push(mapel_mipa[i]);
                }
              }
              if (res.data.kode_mapel == "peminatan-ibb") {
                for (var i = 0; i < mapel_ibb.length; i++) {
                  mapel_umum.push(mapel_ibb[i]);
                }
                this.setState({
                  data_mapel_ibb: mapel_ibb,
                  loadingMapel: false,
                  disabledFilter: false,
                });
              }
            } else {
              this.setState({ loadingMapel: false, disabledFilter: false });
            }
          }

          if (this.state.jenjang_id_select == "5") {
            if (res.data.kode_mapel == "pilihan") {
              for (var i = 0; i < mapel_pilihan.length; i++) {
                mapel_pilihan_merdeka.push(mapel_pilihan[i]);
              }
              this.setState({ loadingMapel: false, disabledFilter: false });
            }
          }

          this.setState(
            {
              data_mapel_umum: mapel_umum,
              data_mapel_agama: mapel_agama,
              data_mapel_seni: mapel_seni,
              data_mapel_bahasa: mapel_bahasa,
              data_mapel_prakarya: mapel_prakarya,
              data_mapel_lintas_minat_1: mapel_peminatan,
              data_mapel_lintas_minat_2: mapel_peminatan,
              data_mapel_pilihan: mapel_pilihan,
              data_mapel_pilihan_1: mapel_pilihan,
              data_mapel_pilihan_2: mapel_pilihan,
              data_mapel_pilihan_sma_1: mapel_pilihan,
              data_mapel_pilihan_sma_2: mapel_pilihan,
            },
            function() {
              if (this.state.jenjang_id_select == 4) {
                if (this.state.peminatan_search == 6) {
                  if (this.state.data_mapel_bahasa != "") {
                    this.setState({ loadingMapel: false });
                  }
                } else {
                  if (this.state.data_mapel_lintas_minat_2 != "") {
                    this.setState({ loadingMapel: false });
                  }
                }
              } else if (this.state.jenjang_id_select == 3) {
                if (this.state.data_mapel_prakarya != "") {
                  this.setState({ loadingMapel: false });
                }
              } else if (this.state.jenjang_id_select == 2) {
                if (this.state.data_mapel_agama != "") {
                  this.setState({ loadingMapel: false });
                }
              } else if (this.state.jenjang_id_select == 5) {
                // Ketika kurikulum 2013
                if (this.state.kompetensi_search != "" && this.state.kurikulum_search == "k13") {
                  if (this.state.data_mapel_bahasa != "") {
                    this.setState({ loadingMapel: false });
                  }
                } else if (this.state.program_search != "" && this.state.kurikulum_search == "merdeka" && this.state.kelas_search == "X") {   // Ketika kurikulum Merdeka dan kelas X
                  if (this.state.data_mapel_umum != "") {
                    this.setState({ loadingMapel: false });
                  }
                } else if (this.state.program_search != "" && this.state.kurikulum_search == "merdeka" && this.state.kelas_search == "XI" || this.state.kelas_search == "XII") {    // Ketika kurikulum Merdeka dan kelas XI atau XII
                  if (this.state.data_mapel_pilihan_1 != "" || this.state.data_mapel_pilihan_2 != "" || this.state.data_mapel_agama != "") {
                    this.setState({ loadingMapel: false });
                  }
                }
              }
                // console.log(this.state.data_mapel_pilihan_1, "Mapel Pilihan 1")
            }
          );
        });
    }
  }

  showData() {
    // console.log(this.state.peminatan_search, 'aaaa')
    if (this.state.data_mapel_umum && this.state.data_mapel_agama) {
      return (
        <form onSubmit={this.simpanMapel}>
          <Tabs defaultActiveKey="UMUM" id="uncontrolled-tab-example">
            {this.state.data_mapel_umum != "" ? this.showMataPelajaran("UMUM", this.state.data_mapel_umum)
              : ""}
            {this.state.data_mapel_bahasa != ""
              ? this.showMataPelajaran("BAHASA", this.state.data_mapel_bahasa)
              : ""}
            {/* {this.state.data_mapel_ibb != '' ? this.showMataPelajaran('BAHASA', this.state.data_mapel_ibb) : "" } */}
            {this.state.data_mapel_agama != ""
              ? this.showMataPelajaran("AGAMA", this.state.data_mapel_agama)
              : ""}
            {this.state.data_mapel_prakarya != ""
              ? this.showMataPelajaran(
                  "PRAKARYA",
                  this.state.data_mapel_prakarya
                )
              : ""}
            {this.state.data_mapel_seni != ""
              ? this.showMataPelajaran("SENI", this.state.data_mapel_seni)
              : ""}
            {this.state.data_mapel_lintas_minat_1 != ""
              ? this.showMataPelajaran(
                  "LINTAS MINAT 1",
                  this.state.data_mapel_lintas_minat_1
                )
              : ""}
            {this.state.data_mapel_lintas_minat_2 != ""
              ? this.showMataPelajaran(
                  "LINTAS MINAT 2",
                  this.state.data_mapel_lintas_minat_2
                )
              : ""}
            {this.state.data_mapel_pilihan_sma_1 != "" &&
            (this.state.kelas_search == "XI" ||
              this.state.kelas_search == "XII")
              ? this.showMataPelajaran(
                  "MAPEL PILIHAN 1",
                  this.state.data_mapel_pilihan_sma_1
                )
              : ""}
            {this.state.data_mapel_pilihan_sma_2 != "" &&
            (this.state.kelas_search == "XI" ||
              this.state.kelas_search == "XII")
              ? this.showMataPelajaran(
                  "MAPEL PILIHAN 2",
                  this.state.data_mapel_pilihan_sma_2
                )
              : ""}
            {/* For SMK */}
            {/* {this.state.jenjang_id_select == "5" && (this.state.kurikulum_search == "merdeka") ?
              <>
                {this.state.data_mapel_pilihan_1 != "" && (this.state.kelas_search == "XI" || this.state.kelas_search == "XII")
                  ? this.showMataPelajaran(
                    "MAPEL PILIHAN 1",
                    this.state.data_mapel_pilihan_1
                  ) : ""}
                {this.state.data_mapel_pilihan_2 != "" && (this.state.kelas_search == "XI" || this.state.kelas_search == "XII")
                  ? this.showMataPelajaran(
                    "MAPEL PILIHAN 2",
                    this.state.data_mapel_pilihan_2
                  ) : ""}
              </> : ""
            } */}
          </Tabs>
          <div className="flex w-full items-center">
            <div className="w-full flex text-left">
              <input
                type="submit"
                className="btn-schoolmedia px-4 py-2 mt-2 flex items-center rounded-full text-white text-sm"
                value="Simpan"
              />
            </div>
            <div className="w-full text-right mr-2">
              <p className="text-sm">
                Kuota per Mapel tidak boleh lebih dari {this.state.kuota_minggu}
              </p>
            </div>
          </div>
        </form>
      );
    } else {
      return (
        <div className="px-2 py-2 text-center text-sm font-bold text-gray-600">
          Select Filter terlebih dahulu
        </div>
      );
    }
  }

  setResetFilter(e) {
    e.preventDefault();
    this.setState(
      {
        data_tahun_ajaran: null,
        data_kelas: null,
        data_spektrum: null,
        data_rombel: null,
        searchText: "",
        kelas_search: "",
        peminatan_search: "",
        peminatan_search_label: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        rombel_search_label: "",
        tahun_ajaran_search: "",
        semester_search: "",
        data_mapel_umum: "",
        data_mapel_bahasa: "",
        data_mapel_agama: "",
        data_mapel_seni: "",
        data_mapel_prakarya: "",
        data_mapel_lintas_minat_1: "",
        data_mapel_lintas_minat_2: "",
        data_mapel_pilihan: "",
        data_mapel_pilihan_1: "",
        data_mapel_pilihan_2: "",
        data_mapel_pilihan_sma_1: "",
        data_mapel_pilihan_sma_2: "",
        data_mapel_ibb: "",
        disabledFilter: false,
      },
      function() {
        this.getTahunAjaranNow();
        this.setState({ loading_filter: false });
      }
    );
  }

  pointChange = () => {};

  changeVal = (params) => {
    let elm = document.getElementById(params);
    if (elm.checked) {
      elm.cheked = false;
    } else {
      elm.cheked = true;
    }
  };

  showMataPelajaran(kode, data) {
    // console.log(kode, data)
    if (data) {
      return (
        <Tab className="text-uppercase" eventKey={kode} title={kode}>
          <div className="overflow-x-scroll px-1 w-full items-center">
            <table className="table table-bordered font-thin text-sm">
              <thead className="text-center">
                <tr>
                  <th>No</th>
                  {/* <th>Kode</th> */}
                  <th>Jenjang</th>
                  {/* <th>Kode</th> */}
                  <th>KMP</th>
                  {/* <th>Kode</th> */}
                  <th>JKMP</th>
                  {/* <th>Kode</th> */}
                  <th>Mata Pelajaran</th>
                  <th>Indeks MPK</th>
                  <th>Kuota</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {data.map((mapel, index) => {
                  const id_mapel = mapel.id_mapel;
                  const d = this.state.data_mapel.find(
                    (i) => i.m_struktur_mpk_id === id_mapel
                  );

                  let kuota = 0;
                  if (!!d) {
                    kuota = d.kuota;
                  } else {
                    kuota =
                      this.state.semester_search === "1"
                        ? mapel.semester_1
                        : mapel.semester_2;
                  }
                  return (
                    <tr key={index}>
                      <td>
                        <div className="text-sm">{index + 1}</div>
                      </td>
                      {/* <td><div className="text-sm">{mapel.kode_jenjang}</div></td> */}
                      <td>
                        <div className="text-sm">
                          {mapel.kode_jenjang} - {mapel.jenjang}
                        </div>
                      </td>
                      {/* <td><div className="text-sm">{mapel.kode_kmp}</div></td> */}
                      <td>
                        <div className="text-sm">
                          {mapel.kode_kmp} - {mapel.kmp}
                        </div>
                      </td>
                      {/* <td><div className="text-sm">{mapel.kode_jkmp}</div></td> */}
                      <td>
                        <div className="text-sm">
                          {mapel.kode_jkmp} - {mapel.jkmp}
                        </div>
                      </td>
                      {/* <td><div className="text-sm">{mapel.kode_mp}</div></td> */}
                      <td>
                        <div className="text-sm">
                          {mapel.kode_mp} - {mapel.mp}
                        </div>
                      </td>
                      <td>
                        <div className="text-sm">{mapel.indeks_mpk}</div>
                      </td>
                      <td>
                        {kode == "AGAMA" ||
                        kode == "LINTAS MINAT 1" ||
                        kode == "LINTAS MINAT 2" ||
                        kode == "MAPEL PILIHAN 1" ||
                        kode == "MAPEL PILIHAN 2" ? (
                          <>
                            {(() => {
                              if (kode == "LINTAS MINAT 1")
                                return (
                                  <input
                                    type="text"
                                    className="l1-kuota"
                                    name="l1-kuota[]"
                                    onKeyUp={this.sameValue}
                                    id={"kuota-" + mapel.id_mapel + "-l1"}
                                    defaultValue={kuota}
                                    style={{ width: "50px" }}
                                  />
                                );
                              if (kode == "LINTAS MINAT 2")
                                return (
                                  <input
                                    type="text"
                                    className="l2-kuota"
                                    name="l2-kuota[]"
                                    onKeyUp={this.sameValue}
                                    id={"kuota-" + mapel.id_mapel + "-l2"}
                                    defaultValue={kuota}
                                    style={{ width: "50px" }}
                                  />
                                );
                              if (kode == "MAPEL PILIHAN 1")
                                return (
                                  <input
                                    type="text"
                                    className="pil1-kuota"
                                    name="pil1-kuota[]"
                                    onKeyUp={this.sameValue}
                                    id={"kuota-" + mapel.id_mapel + "-pil1"}
                                    defaultValue={kuota}
                                    style={{ width: "50px" }}
                                  />
                                );
                              if (kode == "MAPEL PILIHAN 2")
                                return (
                                  <input
                                    type="text"
                                    className="pil2-kuota"
                                    name="pil2-kuota[]"
                                    onKeyUp={this.sameValue}
                                    id={"kuota-" + mapel.id_mapel + "-pil2"}
                                    defaultValue={kuota}
                                    style={{ width: "50px" }}
                                  />
                                );
                              else
                                return (
                                  <input
                                    type="number"
                                    className="input-agama"
                                    name="kuota[]"
                                    onChange={this.sameValue}
                                    id={"kuota-" + mapel.id_mapel + "-AGAMA"}
                                    defaultValue={kuota}
                                    style={{ width: "50px" }}
                                  />
                                );
                            })()}
                          </>
                        ) : (
                          <input
                            type="text"
                            name={kode + "-kuota[]"}
                            id={"kuota-" + mapel.id_mapel + "-" + kode}
                            defaultValue={kuota}
                            style={{ width: "50px" }}
                          />
                        )}
                      </td>
                      <td>
                        {(() => {
                          if (kode == "AGAMA") {
                            return (
                              <input
                                onChange={() => this.changeVal(mapel.id_mapel)}
                                type="checkbox"
                                id={mapel.id_mapel}
                                name="check_data[]"
                                value={mapel.id_mapel}
                                data-kode={kode}
                                defaultChecked={!!d}
                              />
                            );
                          }
                          if (kode == "UMUM") {
                            return (
                              <input
                                onChange={() => this.changeVal(mapel.id_mapel)}
                                type="checkbox"
                                id={mapel.id_mapel}
                                name="check_data[]"
                                value={mapel.id_mapel}
                                data-kode={kode}
                                defaultChecked={!!d}
                              />
                            );
                          }
                          if (kode == "LINTAS MINAT 1") {
                            return (
                              <input
                                onChange={() => this.changeVal(mapel.id_mapel)}
                                type="checkbox"
                                id={mapel.id_mapel}
                                name="check_l1[]"
                                value={mapel.id_mapel}
                                data-kode={kode}
                                defaultChecked={!!d}
                              />
                            );
                          }
                          if (kode == "LINTAS MINAT 2") {
                            return (
                              <input
                                onChange={() => this.changeVal(mapel.id_mapel)}
                                type="checkbox"
                                id={mapel.id_mapel}
                                name="check_l2[]"
                                value={mapel.id_mapel}
                                data-kode={kode}
                                defaultChecked={!!d}
                              />
                            );
                          }
                          if (kode == "MAPEL PILIHAN 1") {
                            return (
                              <input
                                onChange={() => this.changeVal(mapel.id_mapel)}
                                type="checkbox"
                                id={mapel.id_mapel}
                                name="check_pilihan1[]"
                                value={mapel.id_mapel}
                                data-kode={kode}
                                defaultChecked={!!d}
                              />
                            );
                          }
                          if (kode == "MAPEL PILIHAN 2") {
                            return (
                              <input
                                onChange={() => this.changeVal(mapel.id_mapel)}
                                type="checkbox"
                                id={mapel.id_mapel}
                                name="check_pilihan2[]"
                                value={mapel.id_mapel}
                                data-kode={kode}
                                defaultChecked={!!d}
                              />
                            );
                          } else {
                            return (
                              <input
                                onChange={() => this.changeVal(mapel.id_mapel)}
                                type="checkbox"
                                id={mapel.id_mapel}
                                name="check_data[]"
                                value={mapel.id_mapel}
                                data-kode={kode}
                                defaultChecked={!!d}
                              />
                            );
                          }
                        })()}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Tab>
      );
    } else {
      return "";
    }
  }

  simpanMapel(e) {
    e.preventDefault();
    var checkboxes = document.getElementsByName("check_data[]");

    var checkboxesL1 = document.getElementsByName("check_l1[]");
    var checkboxesL2 = document.getElementsByName("check_l2[]");
    // var checkboxesPilihan = document.getElementsByName("check_pilihan[]");
    var checkboxesPilihan1 = document.getElementsByName("check_pilihan1[]");
    var checkboxesPilihan2 = document.getElementsByName("check_pilihan2[]");

    var vals = [];
    var inputVals = [];
    var valType = [];

    var rBtn;
    var radioButton = [];

    var total = 0;
    var totalAgama = 0;
    var totalLintas1 = 0;
    var totalLintas2 = 0;
    var totalPilihan1 = 0;
    var totalPilihan2 = 0;
    var allTotal = 0;

    var umum = 0;
    var ibb = 0;
    var pilihan = 0;
    var seni = 0;
    var prakar = 0;

    for (var i = 0, n = checkboxes.length; i < n; i++) {
      console.log(checkboxes[i]);
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);

        inputVals.push(
          document.getElementById(
            "kuota-" +
              checkboxes[i].value +
              "-" +
              checkboxes[i].getAttribute("data-kode")
          ).value
        );
        valType.push(checkboxes[i].getAttribute("data-kode"));
        if (checkboxes[i].getAttribute("data-kode") != "AGAMA") {
          if (checkboxes[i].getAttribute("data-kode") == "UMUM") {
            umum =
              umum +
              parseInt(
                document.getElementById(
                  "kuota-" +
                    checkboxes[i].value +
                    "-" +
                    checkboxes[i].getAttribute("data-kode")
                ).value
              );
          }

          if (checkboxes[i].getAttribute("data-kode") == "PRAKARYA") {
            prakar = parseInt(
              document.getElementById(
                "kuota-" +
                  checkboxes[i].value +
                  "-" +
                  checkboxes[i].getAttribute("data-kode")
              ).value
            );
          }

          if (checkboxes[i].getAttribute("data-kode") == "BAHASA") {
            ibb =
              ibb +
              parseInt(
                document.getElementById(
                  "kuota-" +
                    checkboxes[i].value +
                    "-" +
                    checkboxes[i].getAttribute("data-kode")
                ).value
              );
          }

          total =
            total +
            parseInt(
              document.getElementById(
                "kuota-" +
                  checkboxes[i].value +
                  "-" +
                  checkboxes[i].getAttribute("data-kode")
              ).value
            );
        } else {
          totalAgama = parseInt(
            document.getElementById(
              "kuota-" +
                checkboxes[i].value +
                "-" +
                checkboxes[i].getAttribute("data-kode")
            ).value
          );
        }
      }
    }

    if (this.state.jenjang_id_select == 5) {
      radioButton = document.getElementsByName("BAHASA-kuota[]");
      for (var u = 0, length = radioButton.length; u < length; u++) {
        if (radioButton[u].checked) {
          console.log(
            document.getElementById("kuota-" + radioButton[u].value + "-BAHASA")
          );
          inputVals.push(
            document.getElementById("kuota-" + radioButton[u].value + "-BAHASA")
              .value
          );
          valType.push(radioButton[u].getAttribute("data-kode"));
          ibb = parseInt(
            document.getElementById("kuota-" + radioButton[u].value + "-BAHASA")
              .value
          );
          total = total + ibb;
        }
      }
    }

    if (this.state.jenjang_id_select == 3) {
      rBtn = ["PRAKARYA", "SENI"];
      for (var i = 0, n = rBtn.length; i < n; i++) {
        radioButton = document.getElementsByName(rBtn[i] + "-kuota[]");
        // vals.push();
        for (var u = 0, length = radioButton.length; u < length; u++) {
          if (radioButton[u].checked) {
            vals.push(radioButton[u].value);
            inputVals.push(
              document.getElementById(
                "kuota-" + radioButton[u].value + "-" + rBtn[i]
              ).value
            );
            valType.push(radioButton[u].getAttribute("data-kode"));

            if (radioButton[u].getAttribute("data-kode") == "PRAKARYA") {
              prakar = parseInt(
                document.getElementById(
                  "kuota-" + radioButton[u].value + "-" + rBtn[i]
                ).value
              );
            }

            total =
              total +
              parseInt(
                document.getElementById(
                  "kuota-" + radioButton[u].value + "-" + rBtn[i]
                ).value
              );
          }
        }
      }
    }

    if (this.state.jenjang_id_select == 4) {
      if (
        this.state.peminatan_search == "6"
          ? (rBtn = ["BAHASA", "PRAKARYA", "SENI"])
          : (rBtn = ["PRAKARYA", "SENI"])
      );
      for (var i = 0, n = rBtn.length; i < n; i++) {
        radioButton = document.getElementsByName(rBtn[i] + "-kuota[]");
        // vals.push();
        for (var u = 0, length = radioButton.length; u < length; u++) {
          if (radioButton[u].checked) {
            vals.push(radioButton[u].value);
            inputVals.push(
              document.getElementById(
                "kuota-" + radioButton[u].value + "-" + rBtn[i]
              ).value
            );
            valType.push(radioButton[u].getAttribute("data-kode"));

            if (radioButton[u].getAttribute("data-kode") == "SENI") {
              seni = parseInt(
                document.getElementById(
                  "kuota-" + radioButton[u].value + "-" + rBtn[i]
                ).value
              );
            }

            if (radioButton[u].getAttribute("data-kode") == "PRAKARYA") {
              prakar = parseInt(
                document.getElementById(
                  "kuota-" + radioButton[u].value + "-" + rBtn[i]
                ).value
              );
            }

            if (radioButton[u].getAttribute("data-kode") == "BAHASA") {
              ibb = parseInt(
                document.getElementById(
                  "kuota-" + radioButton[u].value + "-" + rBtn[i]
                ).value
              );
            }

            total =
              total +
              parseInt(
                document.getElementById(
                  "kuota-" + radioButton[u].value + "-" + rBtn[i]
                ).value
              );
          }
        }
      }

      for (var i = 0, n = checkboxesL1.length; i < n; i++) {
        if (checkboxesL1[i].checked) {
          vals.push(checkboxesL1[i].value);
          inputVals.push(
            document.getElementById("kuota-" + checkboxesL1[i].value + "-l1")
              .value
          );
          valType.push(checkboxesL1[i].getAttribute("data-kode"));
          // totalLintas1 += parseInt(document.getElementById('kuota-'+checkboxesL1[i].value+'-l1').value);
          totalLintas1 = parseInt(
            document.getElementById("kuota-" + checkboxesL1[i].value + "-l1")
              .value
          );
        }
      }

      for (var i = 0, n = checkboxesL2.length; i < n; i++) {
        if (checkboxesL2[i].checked) {
          vals.push(checkboxesL2[i].value);
          inputVals.push(
            document.getElementById("kuota-" + checkboxesL2[i].value + "-l2")
              .value
          );
          valType.push(checkboxesL2[i].getAttribute("data-kode"));
          // totalLintas2 += parseInt(document.getElementById('kuota-'+checkboxesL2[i].value+'-l2').value);
          totalLintas2 = parseInt(
            document.getElementById("kuota-" + checkboxesL2[i].value + "-l2")
              .value
          );
        }
      }

      // for (var i = 0, n = checkboxesPilihan.length; i < n; i++) {
      //   if (checkboxesPilihan[i].checked) {
      //     vals.push(checkboxesPilihan[i].value);
      //     inputVals.push(
      //       document.getElementById("kuota-" + checkboxesPilihan[i].value + "-pil")
      //         .value
      //     );
      //     valType.push(checkboxesPilihan[i].getAttribute("data-kode"));
      //     pilihan = pilihan + parseInt(
      //       document.getElementById("kuota-" + checkboxesPilihan[i].value + "-pil")
      //         .value
      //     );
      //   }
      // }

      for (var i = 0, n = checkboxesPilihan1.length; i < n; i++) {
        if (checkboxesPilihan1[i].checked) {
          vals.push(checkboxesPilihan1[i].value);
          inputVals.push(
            document.getElementById(
              "kuota-" + checkboxesPilihan1[i].value + "-pil1"
            ).value
          );
          valType.push(checkboxesPilihan1[i].getAttribute("data-kode"));
          totalPilihan1 =
            totalPilihan1 +
            parseInt(
              document.getElementById(
                "kuota-" + checkboxesPilihan1[i].value + "-pil1"
              ).value
            );
        }
      }

      for (var i = 0, n = checkboxesPilihan2.length; i < n; i++) {
        if (checkboxesPilihan2[i].checked) {
          vals.push(checkboxesPilihan1[i].value);
          inputVals.push(
            document.getElementById(
              "kuota-" + checkboxesPilihan2[i].value + "-pil2"
            ).value
          );
          valType.push(checkboxesPilihan2[i].getAttribute("data-kode"));
          totalPilihan2 =
            totalPilihan2 +
            parseInt(
              document.getElementById(
                "kuota-" + checkboxesPilihan2[i].value + "-pil2"
              ).value
            );
        }
      }
    }

    allTotal =
      total +
      totalAgama +
      totalLintas1 +
      totalLintas2 +
      pilihan +
      totalPilihan1 +
      totalPilihan2;

    if (allTotal <= this.state.kuota_minggu) {
      this.setState(
        {
          mapel: vals,
          kuota: inputVals,
          type_of: valType,
        },
        function() {
          this.showPreview(
            umum,
            totalAgama,
            ibb,
            seni,
            prakar,
            totalLintas1,
            totalLintas2,
            pilihan,
            totalPilihan1,
            totalPilihan2,
            allTotal
          );
        }
      );
    } else {
      this.setState({
        show_alert: true,
        title_alert: "Kuota Lebih",
        icon_alert: "error",
        message_alert:
          "Kuota Mapel anda atur " +
          allTotal +
          " tidak boleh lebih dari " +
          this.state.kuota_minggu +
          " dari Kuota per Minggu",
      });
    }
  }

  showPreview(
    umum,
    agama,
    ibb,
    seni,
    prakar,
    l1,
    l2,
    pilihan,
    totalPilihan1,
    totalPilihan2,
    allTotal
  ) {
    var preview = [];
    preview.push(
      <div className="w-full px-2">
        <p className="text-sm">UMUM : {umum}</p>
        <p className="text-sm">AGAMA : {agama}</p>
        {this.state.peminatan_search == 6 &&
        this.state.jenjang_id_select == 4 ? (
          <p className="text-sm">IBB : {ibb}</p>
        ) : (
          ""
        )}
        {this.state.jenjang_id_select == 5 ? (
          <p className="text-sm">BAHASA : {ibb}</p>
        ) : (
          ""
        )}
        {this.state.jenjang_id_select == 4 ||
        this.state.jenjang_id_select == 3 ? (
          <>
            {/* <p className="text-sm">SENI : {seni}</p> */}
            <p className="text-sm">PRAKARYA : {prakar}</p>
            {this.state.jenjang_id_select == 4 ? (
              <>
                {this.state.kurikulum_search == "k13" ? (
                  <>
                    <p className="text-sm">LINTAS MINAT 1 : {l1}</p>
                    <p className="text-sm">LINTAS MINAT 2 : {l2}</p>
                  </>
                ) : (
                  <>
                    {this.state.kelas_search == "XI" ||
                    this.state.kelas_search == "XII" ? (
                      <>
                        <p className="text-sm">
                          MAPEL PILIHAN 1: {totalPilihan1}
                        </p>
                        <p className="text-sm">
                          MAPEL PILIHAN 2: {totalPilihan2}
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        <p className="text-sm">TOTAL : {allTotal}</p>
      </div>
    );

    this.setState({ preview: preview, modal_preview: true });
  }

  hidePreview() {
    this.setState({
      preview: "",
      modal_preview: false,
      mapel: "",
      kuota: "",
      type_of: "",
    });
  }

  submitSave() {
    axios
      .post(
        this.state.url_create_mapel,
        {
          mapel: this.state.mapel,
          kuota: this.state.kuota,
          type_of: this.state.type_of,
          rombel: this.state.rombel_search,
          semester: this.state.semester_search,
          created_by: this.state.institusi_id_select,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        if (res.data.status == false) {
          this.setState({
            show_alert: true,
            title_alert: "Gagal",
            icon_alert: "error",
            message_alert: res.data.message[0],
          });
          this.hidePreview();
        } else {
          this.setState({
            show_alert: true,
            title_alert: "Berhasil",
            icon_alert: "success",
            message_alert: res.data.message,
          });
          this.hidePreview();
        }
      })
      .catch((err) => {
        this.alertMessage(err.response);
        this.hidePreview();
      });
  }

  sameValue(e) {
    e.preventDefault();
    var namedInput = e.target.className;
    var input = document.getElementsByClassName(namedInput);
    console.log(namedInput, input);
    for (var i = 0; i < input.length; i++) {
      input[i].value = e.target.value;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <SweetAlert
            show={this.state.show_alert}
            title={this.state.title_alert}
            text={this.state.message_alert}
            type={this.state.icon_alert}
            onConfirm={() => this.setState({ show_alert: false })}
            onOutsideClick={() => this.setState({ show_alert: false })}
          />

          <Modal show={this.state.modal_preview} onHide={this.hidePreview}>
            <Modal.Header>Data Kuota yang akan di inputkan</Modal.Header>
            <Modal.Body>{this.state.preview}</Modal.Body>
            <Modal.Footer>
              <button
                className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
                onClick={this.submitSave}
              >
                Iya
              </button>
              <button
                className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
                onClick={this.hidePreview}
              >
                Tidak
              </button>
            </Modal.Footer>
          </Modal>

          <div className="sm-container flex flex-row py-2 mt-4 mx-auto">
            <SideBarJadwal />
            <div className="w-10/12 px-1 ml-2">
              <div className="flex justify-between items-center px-6 py-4 rounded-lg shadow-md bg-white mb-2 border">
                <h1 className="text-xl text-gray-800 font-extrabold">
                  <span className="text-gray-500 font-thin block text-sm mb-2">
                    <p href="/sadasd" className="text-gray-600">
                      2 dari 8 langkah
                    </p>
                  </span>
                  Atur Mata Pelajaran Per Rombongan Belajar
                  <span className="text-gray-500 font-thin block text-sm">
                    Atur Mata Pelajaran Per Rombongan Belajar.{" "}
                    {/* <a href="/sadasd" className="text-blue-600">
                      Pelajari Lebih Lanjut ?
                    </a> */}
                  </span>
                </h1>
                <div className="flex"></div>
              </div>

              <div className="mt-4 px-6 py-4 rounded-lg shadow-md bg-white border">
                {this.state.loadingFilter == false ? (
                  <span className="text-md text-gray-800">Cari :</span>
                ) : (
                  ""
                )}
                <div className="mb-6">
                  <div className="flex flex-row flex-wrap">
                    {this.state.loadingFilter ? (
                      <>
                        <div className="w-1/4 px-1">
                          <div className="py-2">
                            <Skeleton count={2} />
                          </div>
                        </div>
                        <div className="w-1/4 px-1">
                          <div className="py-2">
                            <Skeleton count={2} />
                          </div>
                        </div>
                        <div className="w-1/4 px-1">
                          <div className="py-2">
                            <Skeleton count={2} />
                          </div>
                        </div>
                        <div className="w-1/4 px-1">
                          <div className="py-2">
                            <Skeleton count={2} />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {this.showSelectFilterSD()}
                        {this.showSelectFilterKurikulum()}
                        {this.showSelectFilterSMA()}
                        {this.showSelectFilterSMK()}
                        {this.showSelectFilterALL()}
                        {this.showSelectFilterSemester()}
                        {this.state.loading_show_data ? (
                          <div className="w-1/4 px-1 flex items-center">
                            <div className="py-2">
                              <div className="flex">
                                <span>Pilih secara berurutan</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="w-1/4 px-1">
                            <div className="py-2">
                              <div className="flex">
                                {this.state.semester_search !== "" ? (
                                  <>
                                    <button
                                      onClick={this.setMapel}
                                      className={`${
                                        this.state.loadingMapel
                                          ? `btn-disabled`
                                          : `btn-schoolmedia`
                                      } px-4 flex items-center rounded-full text-white py-2 text-sm`}
                                      disabled={
                                        this.state.loadingMapel ? true : false
                                      }
                                    >
                                      {this.state.loadingMapel ? (
                                        <>
                                          <span className="text-gray-800">
                                            Tunggu...
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <i className="bx bx-search text-lg mr-1"></i>
                                          Cari
                                        </>
                                      )}
                                    </button>
                                    <button
                                      onClick={this.setResetFilter}
                                      className="flex btn-default text-sm text-gray-800 px-4 py-2 ml-2 rounded-full items-center"
                                    >
                                      Reset
                                    </button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {/* <label className="mb-2 ml-2 text-red-600 block text-sm">Jika anda mengubah pengaturan mata pelajaran ini, anda harus mengubah data di menu lainnya.</label> */}
                </div>
                {this.state.loadingFilter ? (
                  <></>
                ) : (
                  <>
                    <div className="w-full items-center mb-4 px-1">
                      {this.state.loadingMapel ? (
                        <>
                          <div className="flex flex-row mb-2">
                            <div className="w-1/4 px-2">
                              <Skeleton />
                            </div>
                            <div className="w-1/4 px-2">
                              <Skeleton />
                            </div>
                            <div className="w-1/4 px-2">
                              <Skeleton />
                            </div>
                            <div className="w-1/4 px-2">
                              <Skeleton />
                            </div>
                          </div>
                          <Skeleton count={2} />
                        </>
                      ) : (
                        <>
                          {this.state.availabeData ? (
                            <div
                              className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4"
                              role="alert"
                            >
                              <p className="font-bold">Informasi</p>
                              <p>
                                Data mata pelajaran untuk yang rombel ini sudah
                                ada.
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.showData()}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className="mt-2 px-4 py-4 rounded-lg shadow-md bg-white border">
                <div className="px-1 flex flex-row justify-between items-center">
                  <div className="flex">
                    <TombolKembali />
                  </div>
                  <div className="flex">
                    <Link to="/jadwal-pelajaran/daftar-mapel-rombel">
                      <button className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm">
                        Selanjutnya
                        <i className="bx bx-chevron-right-circle text-lg ml-1"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
