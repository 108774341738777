import React from "react";
import { Link } from "react-router-dom";
import conf from "../../config.js";
import tokenApi from "../auth/Helpers";
import axios from 'axios';
import NotifCard from './notif/NotifBell';
import SearchBox from "./SearchBox.js";
import SweetAlert from 'sweetalert2-react';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const activeMenu = {
  control: styles => ({ ...styles, background: '#081824' }),
};

// const menuItemsType = [
//   {
//     title: 'Jenis Kurikulum',
//     // url: '',
//     submenu: [
//       {
//         title: 'Kurikulum 2013',
//         url: '/referensi/struktur'
//       }
//     ]
//   }
// ];

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      labelItem: null,
      typeDropdown: null,
      url_session: `${conf.uri_backend}api/v1/auth/sso/user`,

      active_menu: '',
      active_sub_menu: '',
      isLoading: false,
      profilLoading: true,
      mutasiLoading: true,

      // alert
      show_alert    : false,
      title_alert   : '',
      message_alert : '',
      icon_alert    : '',
      refMenu       : 'sd',

      confirm_alert    : false,
    };
    this.logout = this.logout.bind(this)
    this.menuActive = this.menuActive.bind(this);
    this.getNotifikasi = this.getNotifikasi.bind(this);
    this.showNotifikasi = this.showNotifikasi.bind(this);
    this.confirmNotifikasi = this.confirmNotifikasi.bind(this);
    this.declineNotifikasi = this.declineNotifikasi.bind(this);

    this.hideDropdown = this.hideDropdown.bind(this);

    this.dropDown = React.createRef();

    // this.validateConfirm = this.validateConfirm.bind(this);
  }

  componentDidMount(){
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
          this.setState({ 
            nama_sekolah: res.data.profile.nama_institusi,
            data_session: res.data,
            profilLoading: false
          });

          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({ 
              institusi_id_select : res.data.id,
              logo: res.data.logo,
              jenjang_id_select   : res.data.m_struktur_jenjang_id,
              nama_institusi      : res.data.nama_institusi,
            }, function(){
              if(this.state.jenjang_id_select == 2 ? this.setState({ refMenu: "sd" }) : '');
              if(this.state.jenjang_id_select == 3 ? this.setState({ refMenu: "smp" }) : '');
              if(this.state.jenjang_id_select == 4 ? this.setState({ refMenu: "sma" }) : '');
              if(this.state.jenjang_id_select == 5 ? this.setState({ refMenu: "smk" }) : '');
            });
            this.getNotifikasi();
          });

          this.menuActive(window.location.pathname.split("/")[1], window.location.pathname.split("/")[2]);
      });
    }
  }

  menuActive(active_menu, active_sub_menu){
    this.setState({ active_menu: active_menu},function () {});
    this.setState({ active_sub_menu: active_sub_menu}, function () {});
  }

  showDropdown(name) {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.setState({ isOpen: name });
    }
  }

  getNotifikasi(){
    axios.get(`${conf.uri_backend}api/v1/rombel/mutasi?institusi_id=${this.state.institusi_id_select}`, {headers:{Authorization:tokenApi}})
    .then(res => {
        this.setState({ 
          data_notifikasi_income: res.data.data_income, 
          data_notifikasi_info: res.data.data_info, 
          total_notifikasi: res.data.total,
          mutasiLoading: false
        });
    });
  }

  confirmNotifikasi(notif){
    axios.post(`${conf.uri_backend}api/v1/rombel/mutasi/siswa/${notif}`, {status: 1}, {headers:{Authorization:tokenApi}}).then(res =>{
      this.getNotifikasi();
      
      if(res.data.status == true){
        this.setState({ 
          show_alert    : true,
          title_alert   : "Berhasil",
          icon_alert    : "success",
          message_alert : res.data.messages[0],
        });
      }
    })
  }

  declineNotifikasi(notif){
    axios.post(`${conf.uri_backend}api/v1/rombel/mutasi/siswa/${notif}`, {status: 2}, {headers:{Authorization:tokenApi}}).then(res =>{
      this.getNotifikasi();
      if(res.data.status == true){
        this.setState({ 
          show_alert    : true,
          title_alert   : "Berhasil",
          icon_alert    : "success",
          message_alert : res.data.messages[0],
        });
      }
    })
  }

  hideDropdown(){
    // document.onClick = (e) => {
    //   if(this.state.isOpen){
    //       alert('tes')
    //       this.setState({isOpen : false})
    //   }
    // }
    if(this.state.isOpen){
        this.setState({isOpen : false})
        if(event.relatedTarget === null) {
          document.getElementById('btn-drop');
        }
    }
  }

  // validateConfirm(notif){
  //   this.setState({ 
  //     confirm_alert    : true,
  //   });
  //   console.log(this.state.confirm_alert+"tes")
  //   if (this.state.confirm_alert == true){
  //     return(
  //       <Modal>
  //         <Modal.Body>
  //           <div className="">
  //             <div className="flex flex-row justify-end border-t border-gray-300">
  //               <button onClick={this.confirmNotifikasi.bind(this, notif)} className="bg-schoolmedia mt-1 rounded-full text-sm text-white py-1 px-2 mr-2">Terima</button>
  //               <button onClick={this.declineNotifikasi.bind(this, notif.id)} className="bg-red-600 mt-1 rounded-full text-sm text-white py-1 px-2">Batal</button>
  //             </div>
  //           </div>
  //         </Modal.Body>
  //       </Modal>
  //     )
  //   }
  // }

  showNotifikasi(){
      return(
        <div>
          <hr/>
          <div className="mt-4 w-full bg-gray-100">
            Siswa Pindahan
          </div>
          {this.state.data_notifikasi_income != null ? (
            this.state.data_notifikasi_income.map((notif, index) => (
              <div key={index} className="flex flex-col bg-white rounded px-2 py-2 my-2 border-l-2 border-blue-500 hover:shadow-md" style={{minWidth: '20rem'}}>
                <div className="flex flex-row w-full">
                  <div className="w-1/4 px-2 py-2 flex items-center">
                    
                    <img 
                      className="h-10 w-10 rounded-full mx-auto" 
                      src=
                      {(() => {
                        if (this.state.logo == "") {
                          return `${conf.uri_frontend}assets/img/user.jpeg`;
                        } else if (this.state.logo == null){
                          return `${conf.uri_frontend}assets/img/user.jpeg`;
                        } else{
                          return `https://static.schoolmedia.id/public/assets/socmed/NI/photo/${this.state.logo}`;
                        }
                      })()}
                      style={{
                        objectFit: 'cover'
                      }}
                    /> 
                  
                  </div>
                  <div className="w-3/4 text-left flex flex-col px-2">
                    <p className="text-xs text-gray-500">Asal Sekolah :</p>
                    <p className="text-sm font-bold text-gray-900 mb-1">{notif.nama_institusi} - {this.state.data_session.npsn}</p>
                    <p className="text-xs text-gray-500">Nama Siswa :</p>
                    <p className="text-sm font-bold text-gray-900 mb-2">{notif.nama}</p>
                  </div>
                </div>
                <div className="flex flex-row justify-end border-t border-gray-300">
                  <button onClick={this.confirmNotifikasi.bind(this, notif.id) } className="bg-schoolmedia mt-1 rounded-full text-sm text-white py-1 px-2 mr-2">Terima</button>
                  <button onClick={this.declineNotifikasi.bind(this, notif.id)} className="bg-red-600 mt-1 rounded-full text-sm text-white py-1 px-2">Tolak</button>
                </div>
              </div>
            ))
          ) : ("Tidak ada siswa pindahan")}
          <hr/>
          <div className="mt-4 w-full bg-gray-100">
            Informasi murid yang telah dipindahkan
          </div>
          {this.state.data_notifikasi_info != null ? (
            this.state.data_notifikasi_info.map((notif, index) => (
              <div key={index} className="flex flex-col bg-white rounded px-2 py-2 my-2 border-l-2 border-blue-500 hover:shadow-md" style={{minWidth: '20rem'}}>
                <div className="flex flex-row w-full">
                  <div className="w-1/4 px-2 py-2 flex items-center">
                    <img 
                      className="h-10 w-10 rounded-full mx-auto" 
                      src=
                      {(() => {
                        if (this.state.logo == "") {
                          return `${conf.uri_frontend}assets/img/user.jpeg`;
                        } else if (this.state.logo == null){
                          return `${conf.uri_frontend}assets/img/user.jpeg`;
                        } else{
                          return `https://static.schoolmedia.id/public/assets/socmed/NI/photo/${this.state.logo}`;
                        }
                      })()}
                      style={{
                        objectFit: 'cover'
                      }}
                    /> 
                  </div>
                  <div className="w-3/4 text-left flex flex-col px-2">
                    <p className="text-xs text-gray-500">Asal Sekolah :</p>
                    <p className="text-sm font-bold text-gray-900 mb-1">{notif.nama_institusi} - {this.state.data_session.npsn}</p>
                    <p className="text-xs text-gray-500">Nama Siswa :</p>
                    <p className="text-sm font-bold text-gray-900 mb-2">{notif.nama}</p>
                  </div>
                </div>
                <div className="flex flex-row justify-end border-t border-gray-300">
                  <span>
                    {notif.status == 1 ? ("Siswa diterima di sekolah tujuan") : ("")}
                    {notif.status == 2 ? ("Siswa ditolak di sekolah tujuan") : ("")}
                  </span>
                </div>
              </div>
            ))
          ) : ("Belum ada siswa yang dipindahkan")}
        </div>
      );
  }

  logout(){
    this.setState({ isLoading: true });
    axios.post(`${conf.uri_backend}api/v1/auth/sso/logout`, {token:localStorage.getItem('token')}).then(res => {
      if(res.data.status == true){
        localStorage.setItem('token', '');
        localStorage.setItem('is_login', '');
        window.location.replace(`${conf.uri_sso}`+'auth/logout?token=' + res.data.token + '&redirect_uri=' + `${conf.uri_frontend}auth/sso/redirect/`);
      }
    });
  }
  render() {
    return (
      
      <div className="sm-navbar">
        {/* alert */}
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />
        {this.state.isLoading ? (
          <div className="loader-box transparent-box flex-col"
              style={{
                  position: 'absolute',
                  height: "100vh", 
                  width: "100%", 
                  zIndex: "99999",
                  top: '0',
              }}
          >
              <div className="loader"></div>
              <span className="text-white mt-5">Sedang Keluar...</span>
          </div>
        ) : ("")}
        <nav className="bg-schoolmedia" style={{height: '51px'}}>
          <div className="mx-auto flex justify-between nav-container">
            <div className="flex">
              <div
                className="flex site-logo relative bg-schoolmedia2 pr-3 ml-10 items-center"
                style={{ height: "3.2rem"}}
              >
                <div className="flex items-center w-full">
                  <div className="flex-shrink-0 z-50 flex items-center">
                    <Link to="/profil">
                    <img src="/assets/img/site-logo.png" style={{ width: "12rem" }} />
                    </Link>
                  </div>
                </div>
              </div>
              <h1 className="flex items-center px-3">
                <span className="font-extrabold font-italic text-white text-2xl">
                  User Management
                </span>
              </h1>
            </div>
            <div className="flex items-center mr-10">
              <div className="block pl-4 sm:pl-6 lg:pl-8 float-right">
                <div className="ml-4 flex items-center">

                  {/* <SearchBox className="border-r border-gray-400"/> */}

                  <Dropdown>
                    <Dropdown.Toggle 
                      variant={null}
                      tabIndex="0"
                      className="p-1 relative ml-1 px-3  text-gray-400 hover:text-yellow-200 mr-2"
                      aria-label="Notifications"
                      >
                        {this.state.mutasiLoading ?
                          <>
                            <div className="rounded-full border-5 mx-auto border-white shadow-xl animateFadeInTop">
                              <div className="flex flex-col items-center justify-center">
                                <Skeleton width={40} className="bg-gray-400"/>
                              </div>
                            </div>
                          </>:
                          <>
                            <i className="bx bxs-bell text-white text-xl relative"></i>
                            <span className="rounded-full bg-red-600 text-white hover:text-yellow-200 mr-3 px-1" style={{position: 'absolute', top: '-.1rem', fontSize: '0.60rem', right: '0.2rem'}}>{this.state.total_notifikasi != null ? this.state.total_notifikasi : ""}</span>
                          </>
                        }
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="absolute bg-gray-200" style={{maxHeight: '30rem', right: '10rem', padding: 'unset'}}>
                      <div className="origin-bottom-right border-1 text-left relative top-0 z-30 w-auto rounded shadow-md bg-gray-200 px-2 py-2 overflow-y-scroll">
                        <p className="font-bold text-sm my-3 text-gray-800">Notifikasi Pindah Sekolah</p>
                        {this.showNotifikasi()}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <button
                    tabIndex="0"
                    className="p-1 relative border-r border-l ml-1 border-gray-500 px-3  text-gray-400  hover:text-yellow-200  "
                    aria-label="Notifications"
                    onClick={() => this.showDropdown("notifMutasi")}
                  >
                    {this.state.mutasiLoading ?
                      <>
                        <div className="rounded-full border-5 mx-auto border-white shadow-xl animateFadeInTop">
                          <div className="flex flex-col items-center justify-center">
                            <Skeleton width={40} className="bg-gray-400"/>
                          </div>
                        </div>
                      </>:
                      <>
                        <i className="bx bxs-bell text-white text-xl relative"></i>
                        <span className="rounded-full bg-red-600 text-white hover:text-yellow-200 px-1" style={{position: 'absolute', top: '-.1rem', fontSize: '0.60rem', right: '0.2rem'}}>{this.state.total_notifikasi != null ? this.state.total_notifikasi : ""}</span>
                      </>
                    }
                  </button>

                  {this.state.isOpen == "notifMutasi" ? (
                    <div className="absolute top-0 mt-12 z-99 w-auto" style={{transition: '0.7s ease-in'}} onFocus={this.state.isOpen ? true : false}>
                      <div className="origin-bottom-right border-1 text-left relative top-0 z-30 w-auto rounded shadow-md mr-10 bg-gray-200 px-2 py-2 overflow-y-scroll" style={{maxHeight: '30rem', right: '10rem'}}>
                        <p className="font-bold text-sm my-3 text-gray-800">Notifikasi Pindah Sekolah</p>
                        {this.showNotifikasi()}
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}

                  {/* <button
                    className="p-1 relative border-r border-gray-400 px-3  text-gray-500  hover:text-white  "
                    aria-label="Notifications"
                    onClick={() => this.showDropdown("notifikasi")}
                  >
                    {this.state.profilLoading ?
                      <>
                        <BoxAnimation
                          inDuration={500}
                          visibleAnimation={true}
                          classAnimation={`rounded-full border-5 mx-auto border-white shadow-xl`}
                          style={{width: '12rem', height: '12rem'}}
                          contentAnimation={(
                            <>
                              <div className="flex flex-col items-center justify-center">
                                <Skeleton width={40} className="bg-gray-400"/>
                              </div>
                            </>
                          )}
                        />
                      </>:
                      <>
                        <i className="bx bxs-envelope buzz-out-on-hover text-white text-xl"></i>
                        <span className="rounded-full bg-red-600 text-white px-1" style={{position: 'absolute', top: '-.1rem', fontSize: '0.60rem', right: '0.2rem'}}>99+</span>
                      </>
                    }
                  </button>

                  {this.state.isOpen == "notifikasi" ? (
                    <div className="absolute top-0 mt-10 z-99 w-auto">
                      <div className="origin-bottom-right border-1 text-left relative top-0 z-30 w-auto rounded shadow-md mr-10 bg-gray-200 px-2 py-2 overflow-y-scroll" style={{maxHeight: '30rem', right: '10rem'}}>
                        <p className="font-bold text-sm my-3 text-gray-800">Pusat Notifikasi</p>
                        <NotifCard
                          judul="Rosid"
                          subjek="Hai?"
                          logo={this.state.logo}
                        />
                        <NotifCard
                          judul="Doi"
                          subjek="hmmm"
                          logo={this.state.logo}
                        />
                        <NotifCard
                          judul="Rosid"
                          subjek="Km Kenapa?"
                          logo={this.state.logo}
                        />
                        <NotifCard
                          judul="Doi"
                          subjek="Km gak peka banget sih jadi couo, dahlah males"
                          logo={this.state.logo}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}

                  {/* <button
                    className="p-1 border-r border-gray-900 px-3 text-gray-400 hover:text-white  "
                    aria-label="Notifications"
                  >
                    <i className="bx bxs-home text-white text-xl"></i>
                  </button> */}

                  <div className="relative">
                    <div>
                      {this.state.profilLoading ?
                        <>
                          <div className="rounded-full border-5 mx-auto border-white shadow-xl animateFadeInTop">
                                <div className="flex flex-col items-center justify-center">
                                  <Skeleton width={100} className="bg-gray-400"/>
                                  <Skeleton width={100} className="bg-gray-400"/>
                                </div>
                          </div>
                        </>:
                        <>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant={null}
                            className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid pr-0 pl-0"
                            id="user-menu"
                          >
                            <div className={`rounded-full border-5 mx-auto border-white bg-white shadow-xl`}>
                              <img
                                className="h-8 w-8 rounded-full border-white border-3"
                                src=
                                {(() => {
                                  if (this.state.logo == "") {
                                    return `${conf.uri_frontend}assets/img/user.jpeg`;
                                  } else if (this.state.logo == null){
                                    return `${conf.uri_frontend}assets/img/user.jpeg`;
                                  } else if (this.state.logo == "null") {
                                    return `${conf.uri_frontend}assets/img/user.jpeg`;
                                  } else {
                                    // return `https://static.schoolmedia.id/public/assets/socmed/NI/photo/${this.state.logo}`;
                                    return `${conf.uri_backend}storage/00-universal/${this.state.logo}`;
                                  }
                                })()}
                                style={{
                                  objectFit: 'cover'
                                }}
                              />
                            </div>
                            <span className="ml-2 font-bold">{this.state.nama_sekolah}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="origin-bottom-right absolute top-0 z-30 w-auto rounded-md shadow-md p-0 border-0">
                          <div
                            className="absolute flex flex-col rounded-md bg-schoolmedia shadow-md mx-auto overflow-hidden"
                            style={{minWidth: '14rem', left: '-5rem', zIndex: '99'}}
                          >
                            <div className="py-6 px-3 w-full profile-bg">
                              <div className="flex flex-col z-10 items-center">
                                <Link to="/profil">
                                  <span className="mx-2 font-bold text-center text-white">{this.state.nama_institusi}</span>
                                </Link>
                                <Link to="/profil">
                                  <span className="mx-2 font-bold text-center text-sm text-white">{this.state.data_session.npsn}</span>
                                </Link>
                                <button onClick={this.logout} className="bg-red-600 mt-3 px-4 flex items-center rounded-full text-white py-2 text-sm">
                                  <i className="bx bx-x text-lg mr-1"></i>
                                  Keluar
                                </button>
                              </div>
                            </div>
                            <img
                              className="absolute h-24 w-24 mx-auto rounded-full"
                              src=
                              {(() => {
                                if (this.state.logo == "") {
                                  return `${conf.uri_frontend}assets/img/user.jpeg`;
                                } else if (this.state.logo == null){
                                  return `${conf.uri_frontend}assets/img/user.jpeg`;
                                } else if (this.state.logo == "null") {
                                  return `${conf.uri_frontend}assets/img/user.jpeg`;
                                } else {
                                  return `https://static.schoolmedia.id/public/assets/socmed/NI/photo/${this.state.logo}`;
                                }
                              })()}
                              alt=""
                              style={{
                                left: '-1.5rem',
                                top: '3rem', 
                                objectFit: 'cover'
                              }}
                            />
                          </div>
                          </Dropdown.Menu>
                        </Dropdown>
                        </>
                      }
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <nav className="bg-white shadow-md leading-5">
          <div className="mx-auto flex nav-container items-center" style={{height: '40.8px'}}>
            <Dropdown className="ml-10">
              <Dropdown.Toggle
                variant={null}
                id={`ref`}
                className={`relative text-left font-semibold pl-2 pr-2 py-2 flex items-center rounded-md text-sm hover:no-underline hover:text-gray-700 ${this.state.active_menu == "referensi" ? "navActive" : null} nav-hover`}
                style={this.state.active_menu == "referensi" ? {color:"#3182ce"} : {color:"#4a5568"}}
              >
                <i className="bx bx-book-reader item-center pr-2 text-lg"></i>
                <div className=" flex flex-col">
                    Referensi
                </div>
                <i className="ml-2 bx bxs-chevron-down"></i>  
              </Dropdown.Toggle>
              <Dropdown.Menu className="origin-bottom-right absolute top-0 w-64 rounded-md shadow-md">
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                
                <Dropdown>
                  {['end'].map((direction) => (
                    <Dropdown.Toggle
                      variant={null}
                      key={direction}
                      id={`ref-${direction}`}
                      drop={direction}
                      className={`dropdown-menu-right block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "jenis-kurikulum" ? "subNavActive" : null} subNav-hover`}
                      style={this.state.active_menu == "jenis-kurikulum" ? {color:"#3182ce"} : {color:"#4a5568"}}
                    >
                      Jenis Kurikulum
                      <i className="ml-2 bx bxs-chevron-right"></i>
                    </Dropdown.Toggle>
                  ))}
                  <Dropdown.Menu className="dropdown-menu-right absolute right-0 w-64 rounded-md shadow-md">
                    <Link
                      className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "kurikulum-2013" ? "subNavActive" : null} subNav-hover`}
                      to={"/referensi/jenis-kurikulum/k13/struktur-kurikulum/"+this.state.refMenu}
                      onClick={this.menuActive.bind(this, "referensi", "jenis-kurikulum", "kurikulum-2013")}
                      style={this.state.active_sub_menu == "kurikulum-2013" ? {color: "#3182ce"} : {color: "#4a5568"}}
                    >
                      Kurikulum 2013
                    </Link>
                    <Link
                      className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "kurikulum-merdeka" ? "subNavActive" : null} subNav-hover`}
                      to={"/referensi/jenis-kurikulum/merdeka/penggerak"}
                      onClick={this.menuActive.bind(this, "referensi", "jenis-kurikulum", "kurikulum-merdeka")}
                      style={this.state.active_sub_menu == "kurikulum-merdeka" ? {color: "#3182ce"} : {color: "#4a55"}}
                    >
                      Kurikulum Merdeka
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>

                {/* <Link
                  className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "struktur-kurikulum" ? "subNavActive" : null} subNav-hover`}
                  to={"/referensi/struktur-kurikulum/"+this.state.refMenu}
                  onClick={this.menuActive.bind(this, "referensi", "struktur-kurikulum")}
                >
                  Struktur Kurikulum
                </Link> */}
                {/* <Link
                  className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "kompetensi-dasar" ? "subNavActive" : null} subNav-hover`}
                  to={"/referensi/kompetensi-dasar/"+this.state.refMenu} 
                  onClick={this.menuActive.bind(this, "referensi", "kompetensi-dasar")}
                >
                  Kompetensi Dasar
                </Link> */}
                <Link
                  className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "kode-wilayah" ? "subNavActive" : null} subNav-hover`}
                  to="/referensi/kode-wilayah" 
                  onClick={this.menuActive.bind(this, "referensi", "kode-wilayah")}
                >
                  Kode Wilayah
                </Link>

                <Link
                  className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "sarpras" ? "subNavActive" : null} subNav-hover`}
                  to={"/referensi/sarpras/"+this.state.refMenu}
                  onClick={this.menuActive.bind(this, "referensi", "sarpras")}
                >
                  Standar Sarana dan Prasarana
                </Link>
              </div>
              </Dropdown.Menu>
            </Dropdown>
            
            <Dropdown>
              <Dropdown.Toggle
                variant={null}
                className={`relative text-left font-semibold ml-1 pl-2 pr-2 py-2 flex items-center rounded-md text-sm hover:no-underline hover:text-gray-700 ${this.state.active_menu == "sarpras_management" ? "navActive" : null} nav-hover`}
                style={this.state.active_menu == "sarpras_management" ? {color:"#3182ce"} : {color:"#4a5568"}}
              >
                <i className="bx bx-buildings item-center pr-2 text-lg"></i>
                Sarana & Prasarana
                <i className="ml-2 bx bxs-chevron-down"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="origin-bottom-right absolute top-0 w-64 rounded-md shadow-md rounded-md">
                  <div className=" bg-white shadow-xs">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "lahan" ? "subNavActive" : null} subNav-hover`}
                        to="/sarpras/lahan" 
                        onClick={this.menuActive.bind(this, "sarpras_management", "lahan")}
                      >
                        Lahan
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "bangunan" ? "subNavActive" : null} subNav-hover`}
                        to="/sarpras/bangunan"
                        onClick={this.menuActive.bind(this, "sarpras_management", "bangunan")}
                      >
                        Bangunan
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "ruang" ? "subNavActive" : null} subNav-hover`}
                        to="/sarpras/ruang" 
                        onClick={this.menuActive.bind(this, "sarpras_management", "ruang")}
                      >
                        Ruang
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "analisis" ? "subNavActive" : null} subNav-hover`}
                        to="/sarpras/analisis" 
                        onClick={this.menuActive.bind(this, "sarpras_management", "analisis")}
                      >
                        Analisis
                      </Link>
                    </div>
                  </div>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle
                variant={null}
                className={`relative text-left font-semibold ml-1 pl-2 pr-2 py-2 flex items-center rounded-md text-sm hover:no-underline hover:text-gray-700 ${this.state.active_menu == "user" ? "navActive" : null} nav-hover`}
                style={this.state.active_menu == "user" ? {color:"#3182ce"} : {color:"#4a5568"}}
              >
                <i className="bx bx-group item-center pr-2 text-lg"></i>
                User
                <i className="ml-2 bx bxs-chevron-down"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="origin-bottom-right absolute top-0 w-64 rounded-md shadow-md">
              <div className="rounded-md bg-white shadow-xs">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "guru" ? "subNavActive" : null} subNav-hover`}
                        to="/user/guru"
                        onClick={this.menuActive.bind(this, "user", "guru")}
                      >
                        Guru
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "lainnya" ? "subNavActive" : null} subNav-hover`}
                        to="/user/lainnya"
                        onClick={this.menuActive.bind(this, "user", "lainnya")}
                      >
                        Lainnya
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "siswa" ? "subNavActive" : null} subNav-hover`}
                        to="/user/siswa"
                        onClick={this.menuActive.bind(this, "user", "siswa")}
                      >
                        Siswa
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "orangtua" ? "subNavActive" : null} subNav-hover`}
                        to="/user/orangtua"
                        onClick={this.menuActive.bind(this, "user", "orangtua")}
                      >
                        Orang tua / Wali
                      </Link>
                    </div>
                  </div>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle
                variant={null}
                className={`relative text-left font-semibold ml-1 pl-2 pr-2 py-2 flex items-center rounded-md text-sm hover:no-underline hover:text-gray-700 ${this.state.active_menu == "penugasan" ? "navActive" : null} nav-hover`}
                style={this.state.active_menu == "penugasan" ? {color:"#3182ce"} : {color:"#4a5568"}}
              >
                <i className="bx bxs-graduation item-center pr-2 text-lg"></i>
                Penugasan
                <i className="ml-2 bx bxs-chevron-down"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="origin-bottom-right absolute top-0 w-64 rounded-md shadow-md">
              <div className="rounded-md bg-white shadow-xs">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "penugasan_guru" ? "subNavActive" : null} subNav-hover`}
                        to="/penugasan/penugasan-guru"
                        onClick={this.menuActive.bind(this, "penugasan", "penugasan_guru")}
                      >
                        Guru
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "penugasan_lainnya" ? "subNavActive" : null} subNav-hover`}
                        to="/penugasan/penugasan-lainnya"
                        onClick={this.menuActive.bind(this, "penugasan", "penugasan_lainnya")}
                      >
                        Lainnya
                      </Link>
                    </div>
                  </div>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle
                variant={null}
                className={`relative text-left font-semibold ml-1 pl-2 pr-2 py-2 flex items-center rounded-md text-sm hover:no-underline hover:text-gray-700 ${this.state.active_menu == "rombel" ? "navActive" : null} nav-hover`}
                style={this.state.active_menu == "rombel" ? {color:"#3182ce"} : {color:"#4a5568"}}
              >
                <i className="bx bx-group item-center pr-2 text-lg"></i>
                Rombel
                <i className="ml-2 bx bxs-chevron-down"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="origin-bottom-right absolute top-0 w-64 rounded-md shadow-md">
              <div className="rounded-md bg-white shadow-xs">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "rombel_per_ta" ? "subNavActive" : null} subNav-hover`}
                        to="/rombel/rombel-per-ta"
                        onClick={this.menuActive.bind(this, "rombel", "rombel_per_ta")}
                      >
                        Rombel Per TA
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "tambah_siswa" ? "subNavActive" : null} subNav-hover`}
                        to="/rombel/tambah-siswa"
                        onClick={this.menuActive.bind(this, "rombel", "tambah_siswa")}
                      >
                        Tambah Siswa
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "kenaikan_siswa" ? "subNavActive" : null} subNav-hover`}
                        to="/rombel/kenaikan-siswa"
                        onClick={this.menuActive.bind(this, "rombel", "kenaikan_siswa")}
                      >
                        Kenaikan Siswa
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "history_rombel" ? "subNavActive" : null} subNav-hover`}
                        to="/rombel/histori-rombel"
                        onClick={this.menuActive.bind(this, "rombel", "history_rombel")}
                      >
                        History Rombel
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "siswa_rombel" ? "subNavActive" : null} subNav-hover`}
                        to="/rombel/siswa-rombel"
                        onClick={this.menuActive.bind(this, "rombel", "siswa_rombel")}
                      >
                        Siswa Per Rombel
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "ortu_rombel" ? "subNavActive" : null} subNav-hover`}
                        to="/rombel/orangtua-rombel"
                        onClick={this.menuActive.bind(this, "rombel", "ortu_rombel")}
                      >
                        Orang Tua Per Rombel
                      </Link>
                      <Link
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${this.state.active_sub_menu == "pindah_sekolah" ? "subNavActive" : null} subNav-hover`}
                        to="/rombel/pindah-sekolah"
                        onClick={this.menuActive.bind(this, "rombel", "pindah_sekolah")}
                      >
                        Pindah Sekolah
                      </Link>
                    </div>
                  </div>
              </Dropdown.Menu>
            </Dropdown>
            <p
              className={`relative text-left font-semibold ml-1 pl-2 pr-2 py-2 flex items-center rounded-md text-sm hover:no-underline ${this.state.active_menu == "jadwal_pelajaran" ? "navActive" : null} nav-hover`}
              style={this.state.active_menu == "jadwal_pelajaran" ? {color:"#3182ce"} : {color:"#4a5568"}}
            >
              <Link
              className="text-sm leading-5 focus:outline-none flex items-center"
              to="/jadwal-pelajaran/index"
              style={this.state.active_menu == "jadwal_pelajaran" ? {color:"#3182ce"} : {color:"#4a5568"}}
              onClick={this.menuActive.bind(this, "jadwal_pelajaran", "index")}
              >
                <i className="bx bx-calendar item-center pr-2 text-lg"></i> Jadwal
                Pelajaran
              </Link>
            </p>
            <p
              className={`relative text-left font-semibold ml-1 pl-2 pr-2 py-2 flex items-center rounded-md text-sm hover:no-underline ${this.state.active_menu == "forum" ? "navActive" : null} nav-hover`}
              style={this.state.active_menu == "forum" ? {color:"#3182ce"} : {color:"#4a5568"}}
            >
              <Link
                className="text-sm leading-5 focus:outline-none flex items-center"
                to="/forum/index"
                style={this.state.active_menu == "forum" ? {color:"#3182ce"} : {color:"#4a5568"}}
                onClick={this.menuActive.bind(this, "forum", "index")}
              >
                <i className="bx bx-comment  item-center pr-2 text-lg"></i> Forum
              </Link>
            </p>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
