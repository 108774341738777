import React from "react";
import axios from "axios";
import DataTable from "./../../../../partials/table/DataTables.js";
import conf from "./../../../../../config.js";

export default class ContentLahan extends React.Component {
  componentWillReceiveProps(nextProps) {
    let q = "";
    if (nextProps.query) {
      q = `&query=${nextProps.query}`;
    }
    this.dt.fetchEntities(
      `&jenjang_id=${nextProps.jenjang}${q}`
    );
  }

  componentDidMount(){
    let q = "";
    if (this.props.query) {
      q = `&query=${this.props.query}`;
    }
    this.dt.fetchEntities(
        `&jenjang_id=${this.props.jenjang}${q}`
      );
  }
  
  render() {
    return (
      <DataTable
        ref={(dt) => {
          this.dt = dt;
        }}
        url={`${conf.uri_backend}api/v1/referensi/sarpras/lahan`}
        columns={[
          {
            index: "kode_deskripsi_lahan",
            label: "Kode",
            maxWidth: "0.1rem ",
          },
          {
            index: "deskripsi_lahan",
            label: "Deskripsi Lahan",
            maxWidth: "6rem ",
          },
        ]}
      />
    );
  }
}
