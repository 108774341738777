import React from 'react';
import conf from "../../../config.js";

export default class NotifCard extends React.Component{
 render(){
    return(
        <>
           <div className="flex flex-col bg-white rounded px-2 py-2 my-2 border-l-2 border-blue-500 hover:shadow-md" style={{minWidth: '20rem'}}>
               <div className="flex flex-row w-full">
                   <div className="w-1/4 px-2 py-2 flex items-center">
                       <img className="h-10 w-10 rounded-full mx-auto" src={this.state.logo !== null ? `https://static.schoolmedia.id/assets/socmed/NI/photo/${this.state.logo}` : `${conf.uri_frontend}assets/img/user.jpeg`} />
                   </div>
                   <div className="w-3/4 text-left flex flex-col px-2">
                       <p className="text-xs text-gray-500">Asal Sekolah :</p>
                       <p className="text-sm font-bold text-gray-900 mb-1">{ this.props.sekolah }</p>
                       <p className="text-xs text-gray-500">Nama Siswa :</p>
                       <p className="text-sm font-bold text-gray-900 mb-2">{this.props.nama}</p>
                   </div>
               </div>
               <div className="flex flex-row justify-end border-t border-gray-300">
                   <button className="bg-schoolmedia mt-1 rounded-full text-sm text-white py-1 px-2 mr-2">Terima</button>
                   <button className="bg-red-600 mt-1 rounded-full text-sm text-white py-1 px-2">Tolak</button>
               </div>
           </div>
        </>
    )
 }
}