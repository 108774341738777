import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from "react-router-dom";
import "./style/errorPage.css";

export const Kembali = () =>  {
    let history = useHistory();
    return (
        <>
            <button
                onClick={() => history.goBack()} 
                className="btn-hover color-6"
            >
                <a >Kembali ke Halaman Sebelumnya</a>
            </button>
        </>
    );
};

export default class NotFoundPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }
    render(){
        return(
            <React.Fragment>
            <style dangerouslySetInnerHTML={{__html: `
                .sm-navbar { display: none }
            `}}
            />
                <div id="error-pages">
                    <div id="notfound">
                        <div className="notfound">
                            <div className="notfound-404">
                                <h1>4<span></span>4</h1>
                            </div>
                            <h2>Oops! Halaman tidak ditemukan</h2>
                            <p>Maaf halaman yang Anda cari tidak ada atau untuk sementara tidak tersedia</p>
                            <Kembali/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}