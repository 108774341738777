import React,{useState
} from 'react';
import axios from 'axios';
import conf from "../../config.js";

import { configure } from "@testing-library/react";

const tokenApi = "Bearer " + localStorage.getItem("token");

export default tokenApi;
