import React from "react";

export default class Select extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            value : "selected"
        }
    }
  onSelect = (event) => {
    this.props.onSelect(parseInt(event.target.value));
    this.setState({
        value: parseInt(event.target.value)
    })
  }
  render() {
    return (
      <div className="px-3 py-2 mb-0">
        <div className="relative">
          <select
            value={this.state.value}
            onChange={this.onSelect}
            className="max-w-xs text-gray-700 py-2 pl-3 pr-8 rounded-full border leading-tight outline-none bg-white border-gray-500"
            id="grid-state"
            name={"check_data[]"}
            data-id={this.props.idsiswa}
            data-jenis={this.props.jenisMapel}
          >
            <option selected="selected" value="selected" disabled="disabled">
              {this.props.placeholder}
            </option>
            {this.props.data.map((data) => (
              <option
                key={data[this.props.id]}
                value={data[this.props.id]}
                selected={this.props.selectedId === data[this.props.id]}
              >
                {data[this.props.value]}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}
