import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Modal, Carousel } from 'react-bootstrap';
import {Tab, Tabs} from 'react-bootstrap';
import SideBarJadwal from "./component/SideBarJadwal.js";
import Select from 'react-select';
import axios from "axios";
import SweetAlert from 'sweetalert2-react';
import tokenApi from "../../auth/Helpers";
import conf from "../../../config.js";
import { TombolKembali } from '../../partials/TombolKembali.js';
import DataTable from "./../../partials/table/DataTables.js";
import { data } from 'autoprefixer';

// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

const dataKurikulum = [
  { value: "k13", label: "K13" },
  { value: "merdeka", label: "Merdeka" },
];

const semester = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
];

export default class AturGuruWaktu extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      // url API
      url                 : `${conf.uri_backend}api/v1/penugasan/table_penugasan`,
      url_get_waktu       : `${conf.uri_backend}api/v1/jadwal/get_waktu_by_hari`,
      url_get_max_waktu   : `${conf.uri_backend}api/v1/jadwal/max_waktu`,
      url_create          : `${conf.uri_backend}api/v1/jadwal/create_penugasan_guru_waktu`,
      url_edit            : `${conf.uri_backend}api/v1/jadwal/penugasan_guru_waktu`,
      url_get_penugasan   : `${conf.uri_backend}api/v1/penugasan/select_penugasan/GU/1`,
      tahunSekarang       : new Date().getFullYear(),
      modal_tambah        : false,
      modal_materi        : false,
      modal_edit          : false,
      tahun_ajaran_search : '',
      penugasan_search    : '',
      select_penugasan    : false,
      show_alert          : false,
      title_alert         : '',
      message_alert       : '',
      icon_alert          : '',
      senin               : '',
      selasa              : '',
      rabu                : '',
      kamis               : '',
      jumat               : '',
      sabtu               : '',
      minggu              : '',
      jenis_tombol        : false,
      showSenin           : true,
      showSelasa          : true,
      showRabu            : true,
      showKamis           : true,
      showJumat           : true,
      showSabtu           : true,
      showMinggu          : true,
      mode_show           : 10,
      loading_show_data : true,
      tahun_ajaran_search2: "",
      kelas_search2: "",
      kurikulum_search2: "",
      semester_search2: "",
    }
    // get option filter
    this.getTahunAjaranNow  = this.getTahunAjaranNow.bind(this);
    this.getPenugasan   = this.getPenugasan.bind(this);
    // filter and search
    this.filterTahunAjaran  = this.filterTahunAjaran.bind(this);
    this.filterPenugasan    = this.filterPenugasan.bind(this);
    // show select filter
    this.showSelectFilterSD = this.showSelectFilterSD.bind(this);

    this.setFilter          = this.setFilter.bind(this);
    this.searchText         = this.searchText.bind(this);
    this.senin              = this.senin.bind(this);
    this.selasa             = this.selasa.bind(this);
    this.rabu               = this.rabu.bind(this);
    this.kamis              = this.kamis.bind(this);
    this.jumat              = this.jumat.bind(this);
    this.sabtu              = this.sabtu.bind(this);
    this.minggu             = this.minggu.bind(this);
    this.create             = this.create.bind(this);
    
    // Show Jam Terbang
    this.hideJamTerbang     = this.hideJamTerbang.bind(this);
    this.hideDetail     = this.hideDetail.bind(this);
    this.showData     = this.showData.bind(this);
    this.getWaktuData       = this.getWaktuData.bind(this);
    this.getDataSelect      = this.getDataSelect.bind(this);
    this.getMaxWaktu      = this.getMaxWaktu.bind(this);
    this.fullJam      = this.fullJam.bind(this);
    // this.showData        = this.showData.bind(this);
    this.showMode                 = this.showMode.bind(this);

    // binding data
    this.filterTahunAjaran2 = this.filterTahunAjaran2.bind(this);
    this.filterKurikulum2 = this.filterKurikulum2.bind(this);
    this.filterKelas2 = this.filterKelas2.bind(this);
    this.filterSemester2 = this.filterSemester2.bind(this);
    this.setFilter2 = this.setFilter2.bind(this);
    this.setResetFilter2 = this.setResetFilter2.bind(this);

  }

  componentDidMount() {
    // get session user
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ data_session: res.data }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({ 
              institusi_id_select : res.data.id,
              jenjang_id_select   : res.data.m_struktur_jenjang_id,
              npsn_id_select      : res.data.npsn,
            }, function(){
              this.getKelas();
              this.getKurikulum();
              this.getTahunAjaranNow();
              this.dt.fetchEntities(`&paginate=${this.state.mode_show}`);
              this.getMaxWaktu();
            });
          });
        });
      });
    }
  }

  
  showMode(num){
    this.setState({mode_show: num}, function(){
      this.dt.resetPage(`&paginate=${this.state.mode_show}&query=${document.getElementById("search").value}`);
      this.dt.loading();
    })
  }

  alertMessage(e){
    let message = null;

    switch(e.status){
      case 422:
        message = e.data.messages[0];
      break;
      case 500:
        message= "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
      break;
      case 401:
        message = "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 400:
        message = "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 408:
        message = "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 429:
        message = "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
    }

    this.setState({
      show_alert    : true,
      title_alert   : "Gagal",
      icon_alert    : "error",
      message_alert : message,
      simpleLoading: false,
    })
  }

  getTahunAjaranNow(){
    var ta             = [];
    var u              = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for(var i=tahun_sekarang; i >= 2005; i--){
      u = u - 1;
      ta.push({"value":u+' / '+i, "label":u+' / '+i});
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  // Get List Penugasan from DB
  getPenugasan(){
    this.setState({loading_penugasan: true})
    axios.get(this.state.url_get_penugasan, {headers:{Authorization:tokenApi}})
      .then(res => {
        this.setState({data_penugasan: res.data});
        this.setState({loading_penugasan: false})
      });
  }

  getMaxWaktu(){
    axios.get(this.state.url_get_max_waktu+'?institusi='+this.state.institusi_id_select, {headers:{Authorization:tokenApi}})
      .then(res => {
        if(res.data.status == true){
          this.setState({ max_waktu: res.data.data });
        }
      })
  }

  
  getKelas() {
    var kelas = [];
    if (this.state.jenjang_id_select == "2") {
      kelas = [
        { value: "I", label: "I" },
        { value: "II", label: "II" },
        { value: "III", label: "III" },
        { value: "IV", label: "IV" },
        { value: "V", label: "V" },
        { value: "VI", label: "VI" },
      ];
    } else if (this.state.jenjang_id_select == "3") {
      kelas = [
        { value: "VII", label: "VII" },
        { value: "VIII", label: "VIII" },
        { value: "IX", label: "IX" },
      ];
    } else if (this.state.jenjang_id_select == "4") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
      ];
    } else if (this.state.jenjang_id_select == "5") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
        { value: "XIII", label: "XIII" },
      ];
    }
    this.setState({ data_kelas: kelas });
  }

  getKurikulum() {
    this.setState({ data_kurikulum: dataKurikulum });
  }

  fullJam(e, hari, mode){
    e.preventDefault();

    var waktu   = '';
    var setData = [];
    var i = 0;

    if(hari == 'senin' ? this.setState({ showSenin:false }, function(){
      waktu = this.state.dataSenin;
      setData.push(waktu);
      this.setState({ senin: setData[0], showSenin:true });
    }) : '');

    if(hari == 'selasa' ? this.setState({ showSelasa:false }, function(){
      waktu = this.state.dataSelasa;
      setData.push(waktu);
      this.setState({ selasa: setData[0], showSelasa:true });
    }) : '');

    if(hari == 'rabu' ? this.setState({ showRabu:false }, function(){
      waktu = this.state.dataRabu;
      setData.push(waktu);
      this.setState({ rabu: setData[0], showRabu:true });
    }) : '');

    if(hari == 'kamis' ? this.setState({ showKamis:false }, function(){
      waktu = this.state.dataKamis;
      setData.push(waktu);
      this.setState({ kamis: setData[0], showKamis:true });
    }) : '');

    if(hari == 'jumat' ? this.setState({ showJumat:false }, function(){
      waktu = this.state.dataJumat;
      setData.push(waktu);
      this.setState({ jumat: setData[0], showJumat:true });
    }) : '');

    if(hari == 'sabtu' ? this.setState({ showSabtu:false }, function(){
      waktu = this.state.dataSabtu;
      setData.push(waktu);
      this.setState({ sabtu: setData[0], showSabtu:true });
    }) : '');

    if(hari == 'minggu' ? this.setState({ showMinggu:false }, function(){
      waktu = this.state.dataMinggu;
      setData.push(waktu);
      this.setState({ minggu: setData[0], showMinggu:true });
    }) : '');

  }

  getWaktuData(){
    var dataSenin  = [];
    var dataSelasa = [];
    var dataRabu   = [];
    var dataKamis  = [];
    var dataJumat  = [];
    var dataSabtu  = [];
    var dataMinggu = [];
    axios.get(this.state.url_get_waktu+'?institusi='+this.state.institusi_id_select+`&tahun_ajaran=${this.state.tahun_ajaran_search2}&kelas=${this.state.kelas_search2}&kurikulum=${this.state.kurikulum_search2}&semester=${this.state.semester_search2}`, {headers:{Authorization:tokenApi}})
      .then(res => {
        if(res.data != null){
          for(var i = 0; i < res.data.length; i++){
            if(res.data[i].hari == 1 ? dataSenin.push({value:res.data[i].id, label:res.data[i].jam}) : '');
            if(res.data[i].hari == 2 ? dataSelasa.push({value:res.data[i].id, label:res.data[i].jam}) : '');
            if(res.data[i].hari == 3 ? dataRabu.push({value:res.data[i].id, label:res.data[i].jam}) : '');
            if(res.data[i].hari == 4 ? dataKamis.push({value:res.data[i].id, label:res.data[i].jam}) : '');
            if(res.data[i].hari == 5 ? dataJumat.push({value:res.data[i].id, label:res.data[i].jam}) : '');
            if(res.data[i].hari == 6 ? dataSabtu.push({value:res.data[i].id, label:res.data[i].jam}) : '');
            if(res.data[i].hari == 7 ? dataMinggu.push({value:res.data[i].id, label:res.data[i].jam}) : '');
          }
        }
        
        if(dataSenin != null ? this.setState({dataSenin}) : '');
        if(dataSelasa != null ? this.setState({dataSelasa}) : '');
        if(dataRabu != null ? this.setState({dataRabu}) : '');
        if(dataKamis != null ? this.setState({dataKamis}) : '');
        if(dataJumat != null ? this.setState({dataJumat}) : '');
        if(dataSabtu != null ? this.setState({dataSabtu}) : '');
        if(dataMinggu != null ? this.setState({dataMinggu}) : '');

        this.getEditData();
      });
  }

  getDataSelect(penugasan, metode){
    if(metode == 'edit'){
      this.setState({
        loading_edit: true,
        penugasan_guru : penugasan,
        modalJamTebang: true
      })
    } else if(metode == 'detail'){
      this.setState({
        loading_detail: true,
        penugasan_guru : penugasan,
        modalDetail: true
      })
    }

   
  }

  showData(){
    if(this.state.modalDetail == true){
      var waktunya = [
        this.state.dataSenin.length,
        this.state.dataSelasa.length,
        this.state.dataRabu.length,
        this.state.dataKamis.length,
        this.state.dataJumat.length,
        this.state.dataSabtu.length,
        this.state.dataMinggu.length,
      ]

      var hariSum = [
        this.state.senin.length, 
        this.state.selasa.length, 
        this.state.rabu.length, 
        this.state.kamis.length, 
        this.state.jumat.length, 
        this.state.sabtu.length,
        this.state.minggu.length,
      ];
      var maxLength = this.state.max_waktu;
      var i = 0;
      var trSenin  = [];
      var trSelasa = [];
      var trRabu   = [];
      var trKamis  = [];
      var trJumat  = [];
      var trSabtu  = [];
      var trMinggu = [];
      var trJamke = [];
      var u = 1;
      var y = 0;

      // trJamke
      for(i=0, u = 1; i<maxLength; i++){
        trJamke.push(<th>{u}</th>);
        u++;
      }

      // Senin
      for(i=0, u = 1, y = 0; i<maxLength; i++){
        if(this.state.senin[y] != undefined && this.state.senin[y].label == u){
          trSenin.push(<td>Ada</td>)
          y++;
        } else {
          if(u > waktunya[0]){
            trSenin.push(<td className="bg-gray-300"></td>);
          } else {
            trSenin.push(<td>-</td>);
          }
        }
        u++;
      }
      
      // Selasa
      for(i=0, u = 1, y = 0; i<maxLength; i++){
        if(this.state.selasa[y] != undefined && this.state.selasa[y].label == u){
          trSelasa.push(<td>Ada</td>)
          y++;
        } else {
          if(u > waktunya[1]){
            trSelasa.push(<td className="bg-gray-300"></td>);
          } else {
            trSelasa.push(<td>-</td>);
          }
        }
        u++;
      }

      // Rabu
      for(i=0, u = 1, y = 0; i<maxLength; i++){
        if(this.state.rabu[y] != undefined && this.state.rabu[y].label == u){
          trRabu.push(<td>Ada</td>)
          y++;
        } else {
          if(u > waktunya[2]){
            trRabu.push(<td className="bg-gray-300"></td>);
          } else {
            trRabu.push(<td>-</td>);
          }
        }
        u++;
      }

      // Kamis
      for(i=0, u = 1, y = 0; i<maxLength; i++){
        if(this.state.kamis[y] != undefined && this.state.kamis[y].label == u){
          trKamis.push(<td>Ada</td>)
          y++;
        } else {
          if(u > waktunya[3]){
            trKamis.push(<td className="bg-gray-300"></td>);
          } else {
            trKamis.push(<td>-</td>);
          }
        }
        u++;
      }

      // Jumat
      for(i=0, u = 1, y = 0; i<maxLength; i++){
        if(this.state.jumat[y] != undefined && this.state.jumat[y].label == u){
          trJumat.push(<td>Ada</td>)
          y++;
        } else {
          if(u > waktunya[4]){
            trJumat.push(<td className="bg-gray-300"></td>);
          } else {
            trJumat.push(<td>-</td>);
          }
        }
        u++;
      }
      
      // Sabtu
      for(i=0, u = 1, y = 0; i<maxLength; i++){
        if(this.state.sabtu[y] != undefined && this.state.sabtu[y].label == u){
          trSabtu.push(<td>Ada</td>)
          y++;
        } else {
          if(u > waktunya[5]){
            trSabtu.push(<td className="bg-gray-300"></td>);
          } else {
            trSabtu.push(<td>-</td>);
          }
        }
        u++;
      }

      // Minggu
      for(i=0, u = 1, y = 0; i<maxLength; i++){
        if(this.state.minggu[y] != undefined && this.state.minggu[y].label == u){
          trMinggu.push(<td>Ada</td>)
          y++;
        } else {
          if(u > waktunya[6]){
            trMinggu.push(<td className="bg-gray-300"></td>);
          } else {
            trMinggu.push(<td>-</td>);
          }
        }
        u++;
      }
      
      return(
        <table className="w-full table table-bordered text-sm">
          <thead className="text-center">
            <tr className="items-center">
              <th rowSpan="2">Hari</th>
              <th colSpan={maxLength}>Jam Ke</th>
            </tr>
            <tr>
              {trJamke}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Senin</td>
              {trSenin}
            </tr>
            <tr>
              <td>Selasa</td>
              {trSelasa}
            </tr>
            <tr>
              <td>Rabu</td>
              {trRabu}
            </tr>
            <tr>
              <td>Kamis</td>
              {trKamis}
            </tr>
            <tr>
              <td>Jumat</td>
              {trJumat}
            </tr>
            <tr>
              <td>Sabtu</td>
              {trSabtu}
            </tr>
            <tr>
              <td>Minggu</td>
              {trMinggu}
            </tr>
          </tbody>
        </table>
      )
    }
  }
    
  // SHOW TYPE FILTER PER JENJANG
  showSelectFilterSD(){
    if(this.state.data_tahun_ajaran && this.state.tahun_ajaran_search !== undefined){
      return(
        <div className="ml-3 flex flex-row">
          <div className="flex w-1/2">
            <div className="py-2 mr-2">
            <Select
              id="filterTahunAjaran"
              styles={selectStyles}
              isDisabled={this.state.disabledFilter}
              defaultValue={this.state.tahun_ajaran_search != '' ? {value: this.state.tahun_ajaran_search, label: this.state.tahun_ajaran_search}  : ""}
              options={this.state.data_tahun_ajaran}
              onChange={this.filterTahunAjaran}
              placeholder= {'Pilih Tahun Ajaran'}
            />
            </div>
          </div>
          {this.state.penugasan_search !== undefined && this.state.tahun_ajaran_search !== '' ? (
            <div className="flex w-1/2">
              <div className="py-2 mr-2">
                <Select
                  id="filterPenugasan"
                  styles={selectStyles}
                  isLoading={this.state.loading_penugasan ? true : false}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={this.state.penugasan_search != '' ? {value: this.state.penugasan_search, label: this.state.penugasan_search}  : ""}
                  options={this.state.data_penugasan}
                  onChange={this.filterPenugasan}
                  placeholder= {'Pilih Penugasan'}
                />
              </div>
            </div>
          ) : ("")}
        </div>
      )
    }
  }

  // FILTER HANDLE CHANGE FUNCTION
  filterTahunAjaran(e){
    if(e){
      this.setState({ tahun_ajaran_search: e.value });
      this.getPenugasan();
    } else {
      this.setState({ tahun_ajaran_search:'' });
    }
  }

  filterPenugasan(e){
    if(e){
      this.setState({ penugasan_search : e.value });
    } else {
      this.setState({ penugasan_search : ''});
    }
  }
  //  List MataPelajaran
  setFilter(e){
    this.setState({search: true });
    e.preventDefault();
    // this.searchText();
    this.dt.loading();
    this.dt.fetchEntities((`&query=${document.getElementById("search").value}&tahun_ajaran=${this.state.tahun_ajaran_search}&penugasan=${this.state.penugasan_search}&paginate=${this.state.mode_show}`))
  }

  filterTahunAjaran2(e) {
    if (e) {
      this.setState({ tahun_ajaran_search2: e.value })
    } else {
      this.setState({ tahun_ajaran_search2: "" })
    }
  }
  filterKurikulum2(e) {
    if (e) {
      this.setState({ kurikulum_search2: e.value })
    } else {
      this.setState({ kurikulum_search2: "" })
    }
  }

  filterKelas2(e) {
    if (e) {
      this.setState({ kelas_search2: e.value })
    } else {
      this.setState({ kelas_search2: "" })
    }
  }

  filterSemester2(e) {
    if (e) {
      this.setState({ semester_search2: e.value });
    } else {
      this.setState({ semester_search2: "" });
    }
  }

  setFilter2(e) {
    e.preventDefault();
    this.getWaktuData();
  }

  getEditData() {
    var senin  = [];
    var selasa = [];
    var rabu   = [];
    var kamis  = [];
    var jumat  = [];
    var sabtu  = [];
    var minggu = [];
    axios.get(this.state.url_edit+'?penugasan='+this.state.penugasan_guru+`&tahun_ajaran=${this.state.tahun_ajaran_search2}&kelas=${this.state.kelas_search2}&kurikulum=${this.state.kurikulum_search2}&semester=${this.state.semester_search2}`, {headers:{Authorization:tokenApi}})
    .then(res => {
      if(res.data.length != 0){
        this.setState({ jenis_tombol: true });
        for(var i = 0; i < res.data.length; i++){
          if(res.data[i].hari == 1 ? senin.push({value:res.data[i].id, label:res.data[i].jam}) : '');
          if(res.data[i].hari == 2 ? selasa.push({value:res.data[i].id, label:res.data[i].jam}) : '');
          if(res.data[i].hari == 3 ? rabu.push({value:res.data[i].id, label:res.data[i].jam}) : '');
          if(res.data[i].hari == 4 ? kamis.push({value:res.data[i].id, label:res.data[i].jam}) : '');
          if(res.data[i].hari == 5 ? jumat.push({value:res.data[i].id, label:res.data[i].jam}) : '');
          if(res.data[i].hari == 6 ? sabtu.push({value:res.data[i].id, label:res.data[i].jam}) : '');
          if(res.data[i].hari == 7 ? minggu.push({value:res.data[i].id, label:res.data[i].jam}) : '');
        }
      } else {
        this.setState({ jenis_tombol: false });
      }
      
      if(senin != null ? this.setState({senin}) : '');
      if(selasa != null ? this.setState({selasa}) : '');
      if(rabu != null ? this.setState({rabu}) : '');
      if(kamis != null ? this.setState({kamis}) : '');
      if(jumat != null ? this.setState({jumat}) : '');
      if(sabtu != null ? this.setState({sabtu}) : '');
      if(minggu != null ? this.setState({minggu}) : '');
      this.setState({ loading_edit: false, loading_show_data: false })
    });
  }

  setResetFilter2(e) {
    e.preventDefault();

  }

  searchText(){
    this.dt.loading();
    this.dt.resetPage(`&query=${document.getElementById("search").value}&tahun_ajaran=${this.state.tahun_ajaran_search}&penugasan=${this.state.penugasan_search}&paginate=${this.state.mode_show}`);
  }

  senin(e){
    if(e){
      this.setState({ senin: e });
    } else {
      this.setState({ senin:'' });
    }
  }
  selasa(e){
    if(e){
      this.setState({ selasa: e });
    } else {
      this.setState({ selasa:'' });
    }
  }
  rabu(e){
    if(e){
      this.setState({ rabu: e });
    } else {
      this.setState({ rabu:'' });
    }
  }
  kamis(e){
    if(e){
      this.setState({ kamis: e });
    } else {
      this.setState({ kamis:'' });
    }
  }
  jumat(e){
    if(e){
      this.setState({ jumat: e });
    } else {
      this.setState({ jumat:'' });
    }
  }
  sabtu(e){
    if(e){
      this.setState({ sabtu: e });
    } else {
      this.setState({ sabtu:'' });
    }
  }
  minggu(e){
    if(e){
      this.setState({ minggu: e });
    } else {
      this.setState({ minggu:'' });
    }
  }

  create(e){
    e.preventDefault();
    var allData = [];

    // Senin
    if(this.state.senin != ""){
      this.state.senin.map((jam, index)=>{
        allData.push(jam.value);
      });
    }

    // Selasa
    if(this.state.selasa != ""){
      this.state.selasa.map((jam, index)=>{
        allData.push(jam.value);
      });
    }

    // Rabu
    if(this.state.rabu != ""){
      this.state.rabu.map((jam, index)=>{
        allData.push(jam.value);
      });
    }
    
    // Kamis
    if(this.state.kamis != ""){
      this.state.kamis.map((jam, index)=>{
        allData.push(jam.value);
      });
    }

    // Jumat
    if(this.state.jumat != ""){
      this.state.jumat.map((jam, index)=>{
        allData.push(jam.value);
      });
    }

    // Sabtu
    if(this.state.sabtu != ""){
      this.state.sabtu.map((jam, index)=>{
        allData.push(jam.value);
      });
    }

    // Minggu
    if(this.state.minggu != ""){
      this.state.minggu.map((jam, index)=>{
        allData.push(jam.value);
      });
    }
    
    axios.post(this.state.url_create, {
      penugasan_guru_id : this.state.penugasan_guru,
      data              : allData,
      created_by        : this.state.institusi_id_select,
    }, {headers:{Authorization:tokenApi}})
      .then(res => {
        this.setState({ 
          show_alert    : true,
          title_alert   : "Berhasil",
          icon_alert    : "success",
          message_alert : res.data.messages[0],
        });
        this.hideJamTerbang();
        this.dt.fetchEntities(`&paginate=${this.state.mode_show}`);
      })
      .catch(err => {
        this.alertMessage(err.response)
      })

  }

  hideDetail(){
    this.setState({
      modalDetail : false,
      loading_show_data : true,
      senin       : '',
      selasa      : '',
      rabu        : '',
      kamis       : '',
      jumat       : '',
      sabtu       : '',
      minggu      : '',
    });
  }

  hideJamTerbang(){
    this.setState({
      modalJamTebang : false,
      loading_show_data : true,
      senin          : '',
      selasa         : '',
      rabu           : '',
      kamis          : '',
      jumat          : '',
      sabtu          : '',
      minggu         : '',
    });
  }

  render(){
    return(
      <React.Fragment>
        <div>
          <div className="sm-container flex flex-row py-2 mt-4 mx-auto">
            <SideBarJadwal/>
            <div className="w-10/12 px-1 ml-2">
              {/* Header */}
              <div className="flex justify-between items-center px-6 py-4 rounded-lg shadow-md bg-white mb-2 border">
                <h1 className="text-xl text-gray-800 font-extrabold">
                  <span className="text-gray-500 font-thin block text-sm mb-2">
                    <p href="" className="text-gray-600">
                      6 dari 8 langkah
                    </p>
                  </span>

                  Atur Waktu Guru
                  <span className="text-gray-500 font-thin block text-sm">
                    Atur Waktu Guru. {" "}
                    {/* <a href="/sadasd" className="text-blue-600">
                      Pelajari Lebih Lanjut ?
                    </a> */}
                  </span>
                </h1>
              </div>

              <SweetAlert
                show={this.state.show_alert}
                title={this.state.title_alert}
                text={this.state.message_alert}
                type={this.state.icon_alert}
                onConfirm={() => this.setState({ show_alert: false })}
                onOutsideClick={() => this.setState({ show_alert: false })}
              />

              {/* Content */}
              <div className="mt-4 px-4 py-4 rounded-lg shadow-md bg-white border">
                {this.state.tahun_ajaran_search !== undefined ? (<span className="text-md text-gray-800">Cari :</span>) : ("")}
                <div className="w-full flex flex-wrap items-center mt-3 mb-2 px-4 pb-2">
                  <div className="flex w-1/2 flex-wrap items-center">
                    <button
                      className="btn-blue px-4 py-2 mr-1 flex items-center rounded-full text-white text-xs"
                      onClick={() => {
                        this.setState({
                          showFilter: !this.state.showFilter,
                        });
                      }}
                    >
                      <i className="bx bx-filter text-lg mr-1"></i>
                      Pilih
                    </button>
                    <div className="relative w-2/3">
                      <input
                        type="search"
                        className="w-full pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                        placeholder="Cari Nama Guru atau Kode Pendaftaran  "
                        id="search"
                        onChange={this.searchText}
                      />
                      <div className="absolute top-0 left-0 inline-flex items-center py-2 px-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5 text-gray-400"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                          <circle cx="10" cy="10" r="7"></circle>
                          <line x1="21" y1="21" x2="15" y2="15"></line>
                        </svg>
                      </div>
                    </div>
                    
                  </div>
                  <div className="w-1/2 text-right mr-0">
                    <span className="text-sm text-gray-800 px-1 mb-2">Tampilkan</span>
                    <div className="w-1/2 ml-auto flex flex-wrap text-right items-center justify-end">
                      <div className="mr-0 flex text-right ">
                        <button onClick={this.showMode.bind(this, 10)} className={`${this.state.mode_show == 10 ? `btn-schoolmedia` : `bg-gray-400`} px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}>10</button>
                        <button onClick={this.showMode.bind(this, 50)} className={`${this.state.mode_show == 50 ? `btn-schoolmedia` : `bg-gray-400`} px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}>50</button>
                        <button onClick={this.showMode.bind(this, 100)} className={`${this.state.mode_show == 100 ? `btn-schoolmedia` : `bg-gray-400`} px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}>100</button>
                        <button onClick={this.showMode.bind(this, 200)} className={`${this.state.mode_show == 200 ? `btn-schoolmedia` : `bg-gray-400`} px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}>200</button>
                      </div>
                    </div>
                  </div>
                </div>

                <Modal size="lg" show={this.state.modalDetail} onHide={this.hideDetail}>
                  <Modal.Header>Detail Waktu Guru Mengajar</Modal.Header>
                  <Modal.Body>
                    {this.showData()}
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideDetail}>Tutup</button>
                  </Modal.Footer>
                </Modal>
                
                <Modal size="lg"  show={this.state.modalJamTebang} onHide={this.hideJamTerbang}>
                  <Modal.Header>Waktu Guru Mengajar</Modal.Header>
                  <Modal.Body>
                    <span className="text-md text-gray-800">Cari Waktu Pelajaran :</span>

                    <div className="w-full flex">
                      <div className="w-1/2">
                        <div className="py-2 mr-2">
                          <Select
                            id="filterTahunAjaran"
                            styles={selectStyles}
                            isDisabled={this.state.disabledFilter}
                            options={this.state.data_tahun_ajaran}
                            onChange={this.filterTahunAjaran2}
                            placeholder={"Pilih Tahun Ajaran"}
                          />
                        </div>
                      </div>
                      <div className="w-1/2">
                        <div className="py-2 mr-2">
                          <Select
                            id="filterKelas"
                            styles={selectStyles}
                            isDisabled={this.state.disabledFilter}
                            options={this.state.data_kelas}
                            onChange={this.filterKelas2}
                            placeholder={"Pilih Kelas"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex">
                      <div className="w-1/2">
                        <div className="py-2 mr-2">
                          <Select
                            styles={selectStyles}
                            className="react-select__control"
                            placeholder="Pilih Kurikulum"
                            isDisabled={this.state.disabledFilter}
                            options={this.state.data_kurikulum}
                            onChange={this.filterKurikulum2}
                          />
                        </div>
                      </div>
                      <div className="w-1/2">
                        <div className="py-2 ml-2 mr-2">
                          <Select
                            id="filterSemester"
                            styles={selectStyles}
                            isDisabled={this.state.disabledFilter}
                            options={semester}
                            onChange={this.filterSemester2}
                            placeholder={"Pilih Semester"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex">
                      <button
                        onClick={this.setFilter2}
                        className={`btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm`}
                      >
                        <i className="bx bx-search text-lg mr-1" ></i>
                        Cari
                      </button>
                      <button onClick={this.setResetFilter2} className="flex btn-default text-sm text-gray-800 px-4 py-2 ml-2 rounded-full items-center">
                        Reset
                      </button>
                    </div>
                    <br></br><hr></hr><br></br>
                    {this.state.loading_show_data == false ? (
                      <form onSubmit={this.create}>
                        <div className="w-full flex">
                          <div className="w-1/2 my-4 px-2">
                            <label className="mb-2 ml-2 text-gray-800 block text-sm">Senin, Jam Ke</label>
                            {this.state.showSenin ? (
                              <>
                                <Select
                                  styles={selectStyles}
                                  className="react-select__control"
                                  placeholder="Pilih Jam Ke"
                                  defaultValue={this.state.senin != '' ? this.state.senin : ''}
                                  isMulti
                                  options={this.state.dataSenin}
                                  onChange={this.senin}
                                />
                                <div className="flex flex-wrap w-full items-center mb-2 mt-2">
                                  <a href="#" onClick={e=>(this.fullJam(e, 'senin', 'full'))} className="text-xs bg-gray-500 text-white rounded-full px-3 pt-1 ml-2">Set Full Jam</a>
                                </div>
                              </>
                            ) : ''}
                          </div>  
                          <div className="w-1/2  my-4 px-2">
                            <label className="mb-2 ml-2 text-gray-800 block text-sm">Selasa, Jam Ke</label>
                            {this.state.showSelasa ? (
                              <>
                                <Select
                                  styles={selectStyles}
                                  className="react-select__control"
                                  placeholder="Pilih Jam Ke"
                                  defaultValue={this.state.selasa != '' ? this.state.selasa : ''}
                                  isMulti
                                  options={this.state.dataSelasa}
                                  onChange={this.selasa}
                                />
                                <div className="flex flex-wrap w-full items-center mb-2 mt-2">
                                  <a href="#" onClick={e=>(this.fullJam(e, 'selasa', 'full'))} className="text-xs bg-gray-500 text-white rounded-full px-3 pt-1 ml-2">Set Full Jam</a>
                                </div>
                              </>
                            ) : ''}
                          </div>  
                        </div>
                        <div className="w-full flex">
                          <div className="w-1/2 px-2 my-4">
                            <label className="mb-2 ml-2 text-gray-800 block text-sm">Rabu, Jam Ke</label>
                            {this.state.showRabu ? (
                              <>
                                <Select
                                  styles={selectStyles}
                                  className="react-select__control"
                                  placeholder="Pilih Jam Ke"
                                  defaultValue={this.state.rabu != '' ? this.state.rabu : ''}
                                  isMulti
                                  options={this.state.dataRabu}
                                  onChange={this.rabu}
                                />
                                <div className="flex flex-wrap w-full items-center mb-2 mt-2">
                                  <a href="#" onClick={e=>(this.fullJam(e, 'rabu', 'full'))} className="text-xs bg-gray-500 text-white rounded-full px-3 pt-1 ml-2">Set Full Jam</a>
                                </div>
                              </>
                            ) : ''} 
                          </div>  
                          <div className="w-1/2 px-2 my-4">
                            <label className="mb-2 ml-2 text-gray-800 block text-sm">Kamis, Jam Ke</label>
                            {this.state.showKamis ? (
                              <>
                                <Select
                                  styles={selectStyles}
                                  className="react-select__control"
                                  placeholder="Pilih Jam Ke"
                                  defaultValue={this.state.kamis != '' ? this.state.kamis : ''}
                                  isMulti
                                  options={this.state.dataKamis}
                                  onChange={this.kamis}
                                />
                                <div className="flex flex-wrap w-full items-center mb-2 mt-2">
                                  <a href="#" onClick={e=>(this.fullJam(e, 'kamis', 'full'))} className="text-xs bg-gray-500 text-white rounded-full px-3 pt-1 ml-2">Set Full Jam</a>
                                </div>
                              </>
                            ) : ''} 
                          </div>  
                        </div>
                        <div className="w-full flex">
                          <div className="w-1/2 px-2 my-4">
                            <label className="mb-2 ml-2 text-gray-800 block text-sm">Jumat, Jam Ke</label>
                            {this.state.showJumat ? (
                              <>
                                <Select
                                  styles={selectStyles}
                                  className="react-select__control"
                                  placeholder="Pilih Jam Ke"
                                  defaultValue={this.state.jumat != '' ? this.state.jumat : ''}
                                  isMulti
                                  options={this.state.dataJumat}
                                  onChange={this.jumat}
                                />
                                <div className="flex flex-wrap w-full items-center mb-2 mt-2">
                                  <a href="#" onClick={e=>(this.fullJam(e, 'jumat', 'full'))} className="text-xs bg-gray-500 text-white rounded-full px-3 pt-1 ml-2">Set Full Jam</a>
                                </div>
                              </>
                            ) : ''} 
                          </div>  
                          <div className="w-1/2 px-2 my-4">
                            <label className="mb-2 ml-2 text-gray-800 block text-sm">Sabtu, Jam Ke</label>
                            {this.state.showSabtu ? (
                              <>
                                <Select
                                  styles={selectStyles}
                                  className="react-select__control"
                                  placeholder="Pilih Jam Ke"
                                  defaultValue={this.state.sabtu != '' ? this.state.sabtu : ''}
                                  isMulti
                                  options={this.state.dataSabtu}
                                  onChange={this.sabtu}
                                />
                                <div className="flex flex-wrap w-full items-center mb-2 mt-2">
                                  <a href="#" onClick={e=>(this.fullJam(e, 'sabtu', 'full'))} className="text-xs bg-gray-500 text-white rounded-full px-3 pt-1 ml-2">Set Full Jam</a>
                                </div>
                              </>
                            ) : ''} 
                          </div>  
                        </div>
                        <div className="w-1/2 px-2 my-4">
                          <label className="mb-2 ml-2 text-gray-800 block text-sm">Minggu, Jam Ke</label>
                          {this.state.showMinggu ? (
                            <>
                              <Select
                                styles={selectStyles}
                                className="react-select__control"
                                placeholder="Pilih Jam Ke"
                                defaultValue={this.state.minggu != '' ? this.state.minggu : ''}
                                isMulti
                                options={this.state.dataMinggu}
                                onChange={this.minggu}
                              />
                              <div className="flex flex-wrap w-full items-center mb-2 mt-2">
                                <a href="#" onClick={e=>(this.fullJam(e, 'minggu', 'full'))} className="text-xs bg-gray-500 text-white rounded-full px-3 pt-1 ml-2">Set Full Jam</a>
                              </div>
                            </>
                          ) : ''}
                        </div>  
                        <input type="submit" className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm" value={this.state.jenis_tombol == true ? "Update" : "Simpan"} />
                      </form>
                    ) : ("Pilih Angkatan dan Kurikulum terlebih dahulu")}
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideJamTerbang}>Tutup</button>
                  </Modal.Footer>
                </Modal>

                <div className="flex flex-row flex-wrap">
                  {this.state.showFilter ? (
                    <div className="flex flex-row">
                      {this.showSelectFilterSD()}
                      <div className="w-1/4 px-1">
                        <div className="py-2">
                          <div className="flex">
                            {this.state.penugasan_search != '' ? (
                              <button onClick={this.setFilter } className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm">
                                <i className="bx bx-search text-lg mr-1"></i>
                                Cari
                              </button>
                            ):""}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  <div className=" py-1">
                    <div className=" py-1 table-guru">
                    <DataTable
                        ref={(dt) => {
                          this.dt = dt;
                        }}
                        action={{
              
                          jenis_kelamin: (col, data) => {
                            var jekel = '';
                            if(col.jenis_kelamin == 1){
                              jekel = "Laki - laki";
                            } else if(col.jenis_kelamin == 0){
                              jekel = "Perempuan";
                            } else {
                              jekel = "Belum diketahui";
                            }
                            return (<p className="text-sm">{jekel}</p>);
                          },

                          inisial: (col, data) => {
                            var inisial = '';
                            var potong = '';
                            var potong2 = '';
                            var potong4 = '';
                            var potong5 = '';
                            if(col.inisial !== null){
                              inisial = col.inisial
                            }else if(col.nama_guru !== null){
                              potong = col.nama_guru.substr(0, 3);
                              potong2 = col.nama_guru.substr(0, 2);
                              potong4 = col.nama_guru.substr(0, 4);
                              potong5 = col.nama_guru.substr(0, 5);
                              if(potong == "Dr."){
                                potong = col.nama_guru.substr(4);
                                inisial = potong.substr(0, 3);
                              }else if(potong4 == "Drs."){
                                potong = col.nama_guru.substr(5);
                                inisial = potong.substr(0, 3);
                              }else if(potong == "Ir."){
                                potong = col.nama_guru.substr(4);
                                inisial = potong.substr(0, 3);
                              }else if(potong5 == "Prof."){
                                potong = col.nama_guru.substr(6);
                                inisial = potong.substr(0, 3);
                              }else if(potong2 == "H."){
                                potong = col.nama_guru.substr(3);
                                inisial = potong.substr(0, 3);
                              }else if(potong == "Hj."){
                                potong = col.nama_guru.substr(4);
                                inisial = potong.substr(0, 3);
                              }else{
                                inisial = potong.substr(0, 3);
                              }
                            } 
                            return (<p className="text-sm uppercase">{inisial}</p>);
                          },

                          aksi:(col, data) => {
                            return (
                              <div className="flex">
                                {/* <button onClick={this.getDataSelect.bind(this, data.id_penugasan, 'detail')} className="btn-yellow text-white py-1 px-2 rounded-full font-medium mr-1 text-xs"
                                  disabled={this.state.loading_detail ? true : false}
                                >
                                  {this.state.loading_detail ? "Tunggu..." : "Detail"}
                                </button> */}
                                {data.waktu == 0 ? (
                                  <button 
                                    onClick={this.getDataSelect.bind(this, data.id_penugasan, 'edit')} className="btn-yellow text-white py-1 px-2 rounded-full font-medium mr-1 text-xs w-24"
                                    disabled={this.state.loading_edit ? true : false}
                                  >
                                    {this.state.loading_edit ? "Tunggu..." : "Tambah Jam"}
                                  </button>
                                ): (
                                  <button 
                                    onClick={this.getDataSelect.bind(this, data.id_penugasan, 'edit')} className="btn-green text-white py-1 px-2 rounded-full font-medium mr-1 text-xs w-24"
                                    disabled={this.state.loading_edit ? true : false}
                                  >
                                    {this.state.loading_edit ? "Tunggu..." : "Edit Jam"}
                                  </button>
                                )}
                                
                              </div>
                            );
                          }

                        }}
                        url={this.state.url+'/'+this.state.institusi_id_select+'/GU'}
                        columns={[
                          {
                            index: "tahun_ajaran",
                            label: "Tahun Ajaran",
                          },
                          {
                            index: "kode_pendaftaran",
                            label: "KODE PENDAFTARAN",
                          },
                          {
                            index: "nama_guru",
                            label: "NAMA GURU",
                          },
                          {
                            index: "inisial",
                            label: "INISIAL",
                          },
                          {
                            index: "tanggal_lahir",
                            label: "TANGGAL LAHIR",
                          },
                          {
                            index: "jenis_kelamin",
                            label: "JENIS KELAMIN",
                          },
                          {
                            index: "nama_penugasan",
                            label: "PENUGASAN",
                          },
                          {
                            index: "aksi",
                            label: "AKSI",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex w-full flex-wrap items-center px-3 pb-2 pt-2 mt-1">
                  {/* <div className="flex flex-wrap items-center">
                    <button className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm">
                      <i className="bx bx-save text-lg mr-1"></i>
                      Simpan
                    </button>
                  </div> */}
                </div>

                <div className="flex w-full flex-wrap items-center justify-between px-3 pb-2 pt-2 mt-4">
                  <div className="flex flex-wrap items-center">
                    <TombolKembali/>
                  </div>
                  <div className="flex flex-wrap items-center">
                    <Link to="/jadwal-pelajaran/atur-guru-rombel">
                      <button className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm">
                        Selanjutnya
                        <i className="bx bx-chevron-right-circle text-lg ml-1"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}