import React from 'react';
import Select from 'react-select';
import {Tab, Tabs} from 'react-bootstrap';
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import conf from "./../../../config.js";
import DataTable from "./../../partials/table/DataTables.js";
import Skeleton from 'react-loading-skeleton';

const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

export default class ForumLevel4 extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      url : `${conf.uri_backend}api/v1/forum/`,
      url_get_spektrum : `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_rombel : `${conf.uri_backend}api/v1/rombel/get_rombel/`,
      url_get_smk : `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,

      // data session
      institusi_id_select : [],
      jenjang_id_select : [],

      tahunSekarang : new Date().getFullYear(),

      data_tahun_ajaran : null,
      data_kelas : null,
      data_spektrum: null,
      data_bidang : null,
      data_program : null,
      data_kompetensi : null,
      data_rombel : null,

      tahun_ajaran_search : '',
      kelas_search : '',
      peminatan_search : '',
      rombel_search : '',
      bidang_search : '',
      program_search : '',
      kompetensi_search : '',

      showTable: false,
      query : null,
    };
    // get option filter
    this.getTahunAjaranNow = this.getTahunAjaranNow.bind(this);
    this.getKelas = this.getKelas.bind(this);
    this.getPeminatan = this.getPeminatan.bind(this);
    this.getBidang = this.getBidang.bind(this);
    this.getProgram = this.getProgram.bind(this);
    this.getKompetensi = this.getKompetensi.bind(this);
    this.getRombel = this.getRombel.bind(this);

    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterKelas = this.filterKelas.bind(this);
    this.filterPeminatan = this.filterPeminatan.bind(this);
    this.filterBidang = this.filterBidang.bind(this);
    this.filterProgram = this.filterProgram.bind(this);
    this.filterKompetensi = this.filterKompetensi.bind(this);
    this.filterRombel = this.filterRombel.bind(this);

    this.showFilterPeminatan = this.showFilterPeminatan.bind(this);
    this.showFilterSMK = this.showFilterSMK.bind(this);
    this.showFilterTahunAjaran = this.showFilterTahunAjaran.bind(this);
    this.showFilterRombel = this.showFilterRombel.bind(this);

    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
  }

  componentDidMount(){
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ data_session: res.data }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({ 
              institusi_id_select : res.data.id,
              jenjang_id_select   : res.data.m_struktur_jenjang_id,
            }, function(){
              this.getTahunAjaranNow();
            });
          });
        });
      });
    }
  }

  getTahunAjaranNow(){
    var ta              = [];
    var u               = this.state.tahunSekarang + 1;
    var tahun_sekarang  = this.state.tahunSekarang + 1;

    for(var i=tahun_sekarang; i >= 2005; i--){
      u = u -1;
      ta.push({"value":u+' / '+i, "label":u+' / '+i});
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  getKelas(){
    var kelas = [];
    if(this.state.jenjang_id_select == '2'){
      kelas = [
        { value: 'I',   label: 'I'   },
        { value: 'II',  label: 'II'  },
        { value: 'III', label: 'III' },
        { value: 'IV',  label: 'IV'  },
        { value: 'V',   label: 'V'   },
        { value: 'VI',  label: 'VI'  },
      ];
    } else if(this.state.jenjang_id_select == '3') {
      kelas = [
        { value: 'VII',   label: 'VII'  },
        { value: 'VIII',  label: 'VIII' },
        { value: 'IX',    label: 'IX'   },
      ];
    } else if(this.state.jenjang_id_select == '4') {
      kelas = [
        { value: 'X',   label: 'X'   },
        { value: 'XI',  label: 'XI'  },
        { value: 'XII', label: 'XII' },
      ];
    } else if(this.state.jenjang_id_select == '5'){
      kelas = [
        { value: 'X',   label: 'X'   },
        { value: 'XI',  label: 'XI'  },
        { value: 'XII', label: 'XII' },
        { value: 'XIII', label: 'XIII' },
      ];
    }
    this.setState({ data_kelas: kelas });
  }

  getPeminatan(){
    if(this.state.jenjang_id_select == "4") {
      axios.get(this.state.url_get_spektrum+"?jenjang="+this.state.jenjang_id_select, {headers:{Authorization:tokenApi}})
        .then(res => {
          if(res.data.status == true){
            this.setState({ data_spektrum: res.data.data });
          } else{
            this.setState({ data_spektrum: null });
          }
        })
        .catch(err =>{
          //
        });
    }
  }
  // SMK
  getBidang(){
    if(this.state.jenjang_id_select == "5") {
      axios.get(`${this.state.url_get_smk}?parent_id=0&jenjang_id=5`, {headers:{Authorization:tokenApi}})
      .then(res => {
        this.setState({data_bidang: res.data})
      })
    }
  }
  getProgram(){
    if(this.state.jenjang_id_select == "5") {
      axios.get(`${this.state.url_get_smk}?parent_id=${this.state.bidang_search}&jenjang_id=5`, {headers:{Authorization:tokenApi}})
        .then(res => {
            this.setState({data_program: res.data})
        })
    }
  }
  getKompetensi(){
    if(this.state.jenjang_id_select == "5") {
      axios.get(`${this.state.url_get_smk}?parent_id=${this.state.program_search}&jenjang_id=5`, {headers:{Authorization:tokenApi}})
        .then(res => {
          this.setState({data_kompetensi: res.data})
        })
    }
  }

  getRombel () {
    var id = this.state.peminatan_search;
    if(this.state.jenjang_id_select == 5){
      id = this.state.kompetensi_search
    }
    axios.get(`${this.state.url_get_rombel}?tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&peminatan=${id}&user_institusi_id=${this.state.institusi_id_select}`, {headers:{Authorization:tokenApi}})
      .then(res => {
          this.setState({ data_rombel: res.data });
      })
  }

  showFilterTahunAjaran(){
    if(this.state.data_tahun_ajaran != null){
      return(
        <div className="py-2">
          <Select
            isDisabled = {this.state.showTable ? true : false}
            id="filterTahunAjaran"
            styles={selectStyles}
            isClearable
            options={this.state.data_tahun_ajaran ? this.state.data_tahun_ajaran : null}
            onChange={this.filterTahunAjaran}
            placeholder= {'Tahun Ajaran'}
          />
        </div>
      ) 
    } else {
      return(
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2}/>
          </div>
        </div>
      )
    }
  }

  showFilterPeminatan(){
    if(this.state.jenjang_id_select == '4') {
      if(this.state.data_spektrum !== null) {
        return(
          <div className=" py-2 ml-2 mb-0 ">
            <Select
              id="filterPeminatan"
              styles={selectStyles}
              isClearable
              isDisabled = {this.state.showTable ? true : false}
              options={this.state.data_spektrum}
              onChange={this.filterPeminatan}
              className="animateFadeInLeft"
              placeholder= {'Pilih Peminatan'}
            />
          </div>
        )
      } else {
        return(
          <div className="w-1/4 px-1">
            <div className="py-2">
              <Skeleton count={2}/>
            </div>
          </div>
        )
      }
    } 
  }

  showFilterSMK(){
    if(this.state.jenjang_id_select == '5'){
      return(
        <>
          {this.state.data_bidang !== null ?
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterBidang"
                  styles={selectStyles}
                  isClearable
                  isDisabled = {this.state.showTable ? true : false}
                  options={this.state.data_bidang}
                  onChange={this.filterBidang}
                  className="animateFadeInLeft"
                  placeholder= {this.state.loading_bidang ? "Loading" : "Bidang Keahlian"}
                />
              </div>
            </>
          :
            <div className="w-1/4 px-1">
              <div className="py-2">
                <Skeleton count={2}/>
              </div>
            </div>
          }

          {this.state.data_program !== null ?
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterProgram"
                  styles={selectStyles}
                  isClearable
                  isDisabled = {this.state.showTable ? true : false}
                  options={this.state.data_program}
                  onChange={this.filterProgram}
                  placeholder= {this.state.loading_program ? "Loading" : "Program Keahlian"}
                />
              </div>
            </> 
          : 
            <div className="w-1/4 px-1">
              <div className="py-2">
                <Skeleton count={2}/>
              </div>
            </div>
          }

          {this.state.data_kompetensi !== null ?
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterKompetensi"
                  styles={selectStyles}
                  isClearable
                  isDisabled = {this.state.showTable ? true : false}
                  options={this.state.data_kompetensi}
                  onChange={this.filterKompetensi}
                  placeholder= {this.state.loading_kompetensi ? "Loading" : "Kompetensi Keahlian"}
                />
              </div>
            </>
          :
            <div className="w-1/4 px-1">
              <div className="py-2">
                <Skeleton count={2}/>
              </div>
            </div>
          }
        </>
      )
    }
  }

  showFilterRombel(){
    if (this.state.data_rombel !== null) {
      return(
        <>
          <div className="py-2 pl-2 mb-0">
            <Select
              id="filterRombel"
              styles={selectStyles}
              isClearable
              isDisabled = {this.state.showTable ? true : false}
              options={this.state.data_rombel}
              onChange={this.filterRombel}
              className="animateFadeInLeft"
              placeholder= "Pilih Rombel"
              />
          </div>
        </>
      )
    } else {
      return(
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2}/>
          </div>
        </div>
      )
    }
  }

  // FILTER HANDLE CHANGE FUNCTION
  filterTahunAjaran(e){
    if(e){
      this.setState({ 
        tahun_ajaran_search : e.value,
        kelas_search : '',
        peminatan_search : '',
        bidang_search : '',
        program_search : '',
        kompetensi_search : '',
        rombel_search : '',
        data_kelas : null,
        data_spektrum : null,
        data_bidang : null,
        data_program : null,
        data_kompetensi : null,
        data_rombel : null,
      }, function(){
        this.getKelas();
      })
    } else {
      this.setState({ 
        tahun_ajaran_search : '', 
        kelas_search : '',
        peminatan_search : '',
        bidang_search : '',
        program_search : '',
        kompetensi_search : '',
        rombel_search : '',
        data_kelas : null,
        data_spektrum : null,
        data_bidang : null,
        data_program : null,
        data_kompetensi : null,
        data_rombel : null,
      });
    }
  }

  filterKelas(e){
    if(e){
      this.setState({ 
        kelas_search : e.value,
        peminatan_search : '',
        bidang_search : '',
        program_search : '',
        kompetensi_search : '',
        rombel_search : '',
        data_spektrum : null,
        data_bidang : null,
        data_program : null,
        data_kompetensi : null,
        data_rombel : null,
       }, function(){
        switch(this.state.jenjang_id_select) {
          case 1:
            this.getRombel();
            break;
          case 2:
            this.getRombel();
            break;
          case 3:
            this.getRombel();
            break;
          case 4:
            this.getPeminatan();
            break;
          case 5:
            this.getBidang();
            break;
          default:
            // code block
            break;
        }
      });
    } else {
      this.setState({ 
        kelas_search : '',
        peminatan_search : '',
        bidang_search : '',
        program_search : '',
        kompetensi_search : '',
        rombel_search : '',
        data_spektrum : null,
        data_bidang : null,
        data_program : null,
        data_kompetensi : null,
        data_rombel : null,
      });
    }
  }

  filterPeminatan(e){ // only for SMA
    if(e){
      this.setState({ 
        peminatan_search : e.value,
        rombel_search : '',
        data_rombel : null, 
      }, function(){
        this.getRombel();
      });
    } else {
      this.setState({ 
        peminatan_search : '',
        rombel_search : '',
        data_rombel : null,
      });
    }
  }

  // only for SMK
  filterBidang(e){
    if(e){
      this.setState({
        bidang_search : e.value,
        program_search : '',
        kompetensi_search : '',
        rombel_search : '',
        data_program : null,
        data_kompetensi : null,
        data_rombel : null,
      }, function(){
        this.getProgram();
      })
    } else {
      this.setState({ 
        bidang_search : '',
        program_search : '',
        kompetensi_search : '',
        rombel_search : '',
        data_program : null,
        data_kompetensi : null,
        data_rombel : null,
     })
    }
  }
  filterProgram(e){
    if(e){
      this.setState({
        program_search : e.value,
        kompetensi_search : '',
        rombel_search : '',
        data_kompetensi : null,
        data_rombel : null,
      }, function(){
        this.getKompetensi();
      })
    } else {
      this.setState({
        program_search: '',
        kompetensi_search : '',
        rombel_search : '',
        data_kompetensi : null,
        data_rombel : null,
    })
    }
  }
  filterKompetensi(e){
    if(e){
      this.setState({ 
        kompetensi_search: e.value,
        rombel_search : '',
        data_rombel : null,
      }, function(){
        this.getRombel();
      })
    } else {
      this.setState({
        kompetensi_search: '',
        rombel_search : '',
        data_rombel : null,
      })
    }
  }

  filterRombel(e){
    if(e){
      this.setState({ rombel_search : e.value });
    } else {
      this.setState({ rombel_search : '' });
    }
  }

  setFilter (e) {
    e.preventDefault();
    var tahun_ajaran = this.state.tahun_ajaran_search;
    var kelas = this.state.kelas_search;
    var rombel = this.state.rombel_search;
    if(tahun_ajaran != '' && kelas != '') {
      var query = `&level=4&institusi_id=${this.state.institusi_id_select}&tahun_ajaran=${tahun_ajaran}&kelas=${kelas}&rombel=${rombel}`;
      this.setState({ 
        query : query,
        showTable : true,
      }, function(){
        this.dtortu.fetchEntities(this.state.query+`&jenis_user=orangtua`);
        this.dtwalikelas.fetchEntities(this.state.query+`&jenis_user=walikelas`);
      })
    }
  }

  setResetFilter(e){
    e.preventDefault();
    this.setState({ 
      tahun_ajaran_search : '', 
      peminatan_search : '',
      bidang_search : '',
      program_search : '',
      kompetensi_search : '',
      rombel_search : '',
      kelas_search : '', 
      showTable : false,
      data_tahun_ajaran : null,
      data_kelas : null,
      data_spektrum : null,
      data_bidang : null,
      data_program : null,
      data_kompetensi : null,
      data_rombel : null,
      query : null,
    }, function() {
      this.getTahunAjaranNow();
    });
  }
  render () {
    return(
      <>
        <div>
          <div className="sm-container shadow-md mx-auto py-3 mt-6 rounded-lg bg-white border">
            <div className="flex justify-between items-center mt-2 px-6 pb-4">
              <h1 className="text-xl font-medium w-1/2 text-gray-800 ">
                Forum Level 4
                <span className="text-gray-600 font-thin block text-sm">
                  Daftar user dalam forum grup tetap level 4. {" "}
                  {/* <a href="/sadasd" className="text-blue-600">
                      Pelajari Lebih Lanjut ?
                  </a> */}
                </span>
              </h1>
              <div className="flex flex-wrap items-center mt-3 mb-2 px-4 pb-2">
                  
              </div>
            </div>
            <div className="flex w-full border-t flex-wrap items-center pt-2">
              <div className="flex w-full flex-wrap items-center px-4">
                {this.showFilterTahunAjaran()}
                {this.state.data_kelas !== null ? (
                  <div className="py-2 pl-2 mb-0">
                    <Select
                      id="filterKelas"
                      styles={selectStyles}
                      isClearable
                      isDisabled = {this.state.showTable ? true : false}
                      options={this.state.data_kelas}
                      onChange={this.filterKelas}
                      className="animateFadeInLeft"
                      placeholder= {'Kelas'}
                    />
                  </div>
                ) : (
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2}/>
                    </div>
                  </div>
                )}
                {this.showFilterPeminatan()}
                {this.showFilterSMK()}
                {this.showFilterRombel()}
                {this.state.rombel_search !== '' ? (
                  <div className="flex flx-row animateFadeInLeft">
                    <button onClick={this.setFilter} className="flex btn-schoolmedia text-sm text-white ml-2 px-4 py-2 rounded-full items-center">
                      <i className="bx bx-search mr-1"></i>
                      Cari
                    </button>
                    <button onClick={this.setResetFilter} className="flex btn-default text-sm text-gray-800 ml-2 px-4 py-2 rounded-full items-center">
                      Reset
                    </button>
                  </div>
                ) : (
                  <p className="ml-2 text-sm text-gray-500">Pilih filter secara berurutan</p>
                )}
              </div>
            </div>
          </div>
          <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
            {this.state.showTable ? (
              <Tabs defaultActiveKey="ortu" id="uncontrolled-tab-example" className="px-6">
                <Tab eventKey="ortu" title="Orang Tua / Wali">
                  <div className="relative w-1/3 mt-6 mx-6 mb-2">
                    <input
                      type="search"
                      className="w-full pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                      placeholder="Cari nama orang tua"
                      id="searchOrtu"
                      onChange={(event) => {
                        this.dtortu.fetchEntities(`
                          &jenis_user=orangtua
                          &query=${event.target.value}
                          ${this.state.query}
                        `);
                      }}
                    />
                    <div className="absolute top-0 left-0 inline-flex items-center py-2 px-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 text-gray-400"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                        <circle cx="10" cy="10" r="7"></circle>
                        <line x1="21" y1="21" x2="15" y2="15"></line>
                      </svg>
                    </div>
                  </div>
                  <div className="mx-2">
                    <DataTable
                      ref={(dt) => {
                        this.dtortu = dt;
                      }}
                      url={`${this.state.url}`}
                      action={{
                        jenis_kelamin: (col, data) => {
                          var jekel = '';
                          if(col.jenis_kelamin == 1){
                            jekel = "Laki - Laki";
                          } else if(col.jenis_kelamin == 0){ 
                            jekel = "Perempuan";
                          } else {
                            jekel = "Belum diketahui";
                          }
                          return (<p className="text-sm">{jekel}</p>);
                        },
                      }}
                      columns={[
                        {
                          index: "tahun_ajaran",
                          label: "TAHUN AJARAN",
                        },
                        {
                          index: "kelas",
                          label: "KELAS",
                        },
                        this.state.jenjang_id_select == 4 ? 
                        {
                          index: "jurusan",
                          label: "PEMINATAN",
                        } : {},
                        this.state.jenjang_id_select == 5 ? 
                        {
                          index: "jurusan",
                          label: "KOMPETENSI",
                        } : {},
                        {
                          index: "rombel",
                          label: "ROMBEL",
                        },
                        {
                          index: "nama_anak",
                          label: "NAMA ANAK",
                        },
                        {
                          index: "jenis_orangtua",
                          label: "JENIS ORANGTUA",
                        },
                        {
                          index: "nama",
                          label: "NAMA ORANGTUA",
                        },
                      ]}
                    />
                  </div>
                </Tab>

                <Tab eventKey="guru" title="Wali Kelas">
                  <div className="relative w-1/3 mt-6 mx-6 mb-2">
                    <input
                      type="search"
                      className="w-full pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                      placeholder="Cari nama wali kelas"
                      id="searchWalikelas"
                      onChange={(event) => {
                        this.dtwalikelas.fetchEntities(`
                          &jenis_user=walikelas
                          &query=${event.target.value}
                          ${this.state.query}
                        `);
                      }}
                    />
                    <div className="absolute top-0 left-0 inline-flex items-center py-2 px-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 text-gray-400"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                        <circle cx="10" cy="10" r="7"></circle>
                        <line x1="21" y1="21" x2="15" y2="15"></line>
                      </svg>
                    </div>
                  </div>
                  <div className="mx-2">
                    <DataTable
                      ref={(dt) => {
                        this.dtwalikelas = dt;
                      }}
                      url={`${this.state.url}`}
                      action={{
                        jenis_kelamin: (col, data) => {
                          var jekel = '';
                          if(col.jenis_kelamin == 1){
                            jekel = "Laki - Laki";
                          } else if(col.jenis_kelamin == 0){ 
                            jekel = "Perempuan";
                          } else {
                            jekel = "Belum diketahui";
                          }
                          return (<p className="text-sm">{jekel}</p>);
                        },
                      }}
                      columns={[
                        {
                          index: "tahun_ajaran",
                          label: "TAHUN AJARAN",
                        },
                        {
                          index: "nama_penugasan",
                          label: "PENUGASAN GURU",
                        },
                        {
                          index: "rombel",
                          label: "Rombel",
                        },
                        {
                          index: "nama",
                          label: "NAMA",
                        },
                        {
                          index: "jenis_kelamin",
                          label: "JENIS KELAMIN",
                        },
                      ]}
                    />
                  </div>
                </Tab>
              </Tabs>
            ) : (
              <div className="w-full flex justify-center items-center py-2 text-center">
                <p className="text-sm">Select Filter terlebih dahulu</p>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}