import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import conf from "../../../../../config";
import DataTable from "../../../../partials/table/DataTables";
import tokenApi from "../../../../auth/Helpers";
import { Button, Modal } from "react-bootstrap";
import TableSkeleton from "../../../../partials/table/TableSkeleton";
import SweetAlert from 'sweetalert2-react';
import Select from 'react-select';
import swal from 'sweetalert2';

// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

const dataJKMPUmum = [
  { value: 'lokal', label: 'Lokal' },
];

const dataJKMPSmk = [
  { value: 'lokal', label: 'Lokal' },
  { value: 'custom', label: 'Prakarya dan Vokasi' },
];
export default class Kurikulum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table_url: ``,
      url_delete_kustom_mapel : `${conf.uri_backend}api/v1/referensi/struktur-kurikulum/delete-kustom-mapel`,
      sepktrum: [],
      showModal: false,
      showModalTambahMapel: false,
      showModalEditMapel: false,
      showModalListKustomMapel: false,
      kode_mapel: null,
      tabsActive: "I",
      content: [],
      jenjang: "",
      jenjang_id: "",
      elements: [],

      //tambah mapel
      jkmpTambah: "",
      listKustomMapel: null,
      selectMapel: null,
      kodeMapelTambah: "",
      loading_edit: true,
      mapelTambah: "",
      kuotaSmt1Tambah: "",
      kuotaSmt2Tambah: "",
      userInstitusi: '',
      id_jkmp_ml: '',
      mp_ml: '',
      id_jkmp_custom: '',
      mp_custom: '',
      loading_delete: false,

      // alert
      show_alert            : false,
      title_alert           : '',
      message_alert         : {},
      icon_alert            : '',

      loadingDetail: true,
    };
    this.delete              = this.delete.bind(this);
    this.setShowModalEditMapel              = this.setShowModalEditMapel.bind(this);
    this.getListKustomMapel              = this.getListKustomMapel.bind(this);

  }

  componentDidMount() {
    if (localStorage.getItem("token") !== null) {
      axios
        .post(`${conf.uri_backend}api/v1/auth/sso/user`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          this.setState(
            {
              data_session: res.data,
              jenjang: res.data.profile.jenjang.indeks_jenjang,
            },
            function() {
              this.getInstitusi();
            }
          );
        });
    }
  }

  getInstitusi() {
    axios
      .get(
        `${conf.uri_backend}api/v1/select-institusi/` +
          this.state.data_session.access_to,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        
        this.setState({ userInstitusiIde: res.data.id })
        if (this.state.jenjang == "1") {
          var jenjang = "2";
          this.setState({
            jenjang_id: "2",
            elements: ["I", "II", "III", "IV", "V", "VI"],
          });
        } else if (this.state.jenjang == "2") {
          var jenjang = "3";
          this.setState({
            jenjang_id: "3",
            elements: ["VII", "VIII", "IX"],
          });
        } else if (this.state.jenjang == "3" || this.state.jenjang == "4") {
          var jenjang = "4";
          this.setState({
            jenjang_id: "4",
            elements: ["X", "XI", "XII"],
          });
        }
        this.setState({ table_url: `${conf.uri_backend}api/v1/referensi/struktur-kurikulum?jenjang_id=${jenjang}&kurikulum=Merdeka&`},
          function() {
            this.dt.fetchEntities(`&jenjang_id=${jenjang}`)
            this.getListKustomMapel();
          }
        );
      });
  }

  getListKustomMapel() {
    axios
      .get(
        `${conf.uri_backend}api/v1/referensi/struktur-kurikulum/list-kustom-mapel?uid=${this.state.userInstitusiIde}`,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({
          listKustomMapel: res.data
        })
      })
  }

  render() {
    // console.log("ini jenjang : ", this.state.jenjang);
    // console.log("ini params", this.props.match.params);
    return (
      <div>
        {this.showDetailModal()}
        {this.showMdlTambahMapel()}
        {this.showMdlEditMapel()}
        {this.showMdlListKustomMapel()}
        <div className="sm-container shadow-md mx-auto py-3 mt-6 rounded-lg bg-white border">
          <div className="flex justify-between items-center mt-2 px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Kurikulum Merdeka
              <span className="text-gray-500 font-thin block text-sm">
                Daftar Kurikulum Merdeka.{" "}
                {/* <a href="/sadasd" className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
          </div>
        </div>
        <div className="sm-container shadow-md mx-auto mt-2 rounded-lg bg-white border">
          <ul className="flex border-b mb-2">

            {/* Yang bner */}
            {/* <li className="-mb-px mr-1">
              {this.state.jenjang == "2" ? (
                <>
                  <Link
                    className="bg-white inline-block py-2 px-4 text-blue-700 hover:text-blue-800 font-semibold"
                    to="/referensi/struktur-kurikulum/smp"
                  >
                    SMP / MT
                  </Link>
                </>
              ) : this.state.jenjang == "3" ? (
                <>
                  <li className="-mb-px mr-1">
                    <Link
                      className="bg-white inline-block py-2 px-4 text-blue-700 hover:text-blue-800 font-semibold"
                      to="/referensi/struktur-kurikulum/sma"
                    >
                      Kurikulum 2013
                    </Link>
                  </li>
                </>
              ) : this.state.jenjang == "4" ? (
                <>
                  <li className="-mb-px mr-1">
                    <Link
                      className="bg-white inline-block py-2 px-4 text-blue-700 hover:text-blue-800 font-semibold"
                      to="/referensi/struktur-kurikulum/smk"
                    >
                      Kurikulum 2013
                    </Link>
                  </li>
                </>
              ) : this.state.jenjang == "1" ? (
                <>
                  <Link
                    className="bg-white inline-block py-2 px-4 text-blue-700 hover:text-blue-800 font-semibold"
                    to="/referensi/struktur-kurikulum/sd"
                  >
                    SD / MI
                  </Link>
                </>
              ) : null}
            </li> */}
            <li className="-mb-px mr-1">
                <Link
                    className="bg-white inline-block py-2 px-4 text-blue-700 hover:text-blue-800 font-semibold"
                    to="/referensi/jenis-kurikulum/merdeka/penggerak"
                >
                    Struktur Kurikulum
                </Link>
            </li>
            <li className="-mb-px mr-1">
              <Link
                className="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold"
                to="/referensi/jenis-kurikulum/merdeka/capaian-pembelajaran"
              >
                Capaian Pembelajaran
              </Link>
            </li>
            {/* End yang bener */}
            
          </ul>
          <div className="px-2 py-4">{this.renderTableData()}</div>
        </div>
      </div>
    );
  }

  renderTableData() {
    return (
      <DataTable
        ref={(dt) => {
          this.dt = dt;
        }}
        action={{
          aksi: (col, data) => {
            return (
              <div className="text-gray-700 px-4 py-1 flex items-center">
                <button
                  onClick={this.setShowModal.bind(this, data.id)}
                  className="flex bg-schoolmedia text-sm text-white px-4 py-2 rounded-full items-center mr-1"
                >
                  Detail
                </button>
              </div>
            );
          },
        }}
        // url={`${conf.uri_backend}api/v1/referensi/struktur-kurikulum?jenjang_id=4&status=penggerak&`}
        url={this.state.table_url}
        columns={[
          {
            index: "nama_spektrum",
            label: "PROGRAM PEMINATAN",
          },
          {
            index: "kode_spektrum",
            label: "KODE",
          },
          {
            index: "lama_belajar",
            label: "MASA STUDI",
          },
          {
            index: "aksi",
            label: "AKSI",
          },
        ]}
      />
    );
  }

  renderTableDataListKustomMapel(content) {
    if (content == [] || content == null || content == "") {
      return ("Belum ada Kustom Mapel")
    } else {
      const detailStrukturMapel = content.map((peminatan, index) => {
        return (
          <tr key={index}>
            <td className="border-t border-gray-200 userId">
              <span className="text-gray-700 px-4 py-2 flex items-center">
                {peminatan.kode_kmp}
              </span>
            </td>
            <td className="border-t border-gray-200 userId">
              <span className="text-gray-700 px-4 py-2 flex items-center">
                {peminatan.m_struktur_jkmp.kode_jkmp}
              </span>
            </td>
            <td className="border-t border-gray-200 userId">
              <span className="text-gray-700 px-4 py-2 flex items-center">
                {peminatan.m_struktur_jkmp.jkmp}
              </span>
            </td>
            <td className="border-t border-gray-200 userId">
              <span className="text-gray-700 px-4 py-2 flex items-center">
                {peminatan.kode_mp}
              </span>
            </td>
            <td className="border-t border-gray-200 userId">
              <span className="text-gray-700 px-4 py-2 flex items-center">
                {peminatan.mp}
              </span>
            </td>
            {/* <td className="border-t border-gray-200 userId">
              <span className="text-gray-700 px-4 py-2 flex items-center">
                {peminatan.indeks_mpk}
              </span>
            </td>
            <td className="border-t border-gray-200 userId">
              <span className="text-gray-700 px-4 py-2 flex items-center">
                {peminatan.semester_1}
              </span>
            </td>
            <td className="border-t border-gray-200 userId">
              <span className="text-gray-700 px-4 py-2 flex items-center">
                {peminatan.semester_2}
              </span>
            </td> */}
            <td className="border-t border-gray-200 userId">
              <span className="text-gray-700 px-4 py-2 flex items-center">
                {peminatan.user_institusi_id != null ? (
                  <>
                    <button onClick={this.setShowModalEditMapel.bind(this, true, peminatan.id)} className="btn-yellow text-lg text-white py-1 px-2 rounded-full font-medium mr-1">
                      <i className="bx bxs-edit-alt"></i>
                    </button>
                    <button onClick={this.delete.bind(this, peminatan.id)} className={`${this.state.loading_delete === true ?`btn-gray`:`btn-red`} text-lg text-white py-1 px-2 rounded-full font-medium`} disabled={this.state.loading_delete === true ? true : false}>
                      <i className="bx bx-trash"></i>
                    </button>
                  </>
                ) : ("-")}
              </span>
            </td>
  
          </tr>
        );
      });
      return (
        <table className="w-full border-gray-400">
          <thead>
            <tr className="text-left">
              <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600 tracking-wider text-sm">
                KODE
              </th>
              {/* <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
                KMP
              </th> */}
              <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
                KODE
              </th>
              <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
                JKMP
              </th>
              <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
                KODE
              </th>
              <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
                MATA PELAJARAN
              </th>
              {/* <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
                INDEKS MPK
              </th>
              <th className="top-0  border-gray-700 border-b px-1 py-2 text-gray-600 tracking-wider  text-sm">
                KUOTA SEMESTER 1
              </th>
              <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600 tracking-wider  text-sm">
                KUOTA SEMESTER 2
              </th> */}
              <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
                Aksi
              </th>
            </tr>
          </thead>
          <tbody>
            {detailStrukturMapel}
          </tbody>
        </table>
      );
    }
  }

  renderTableDataDetail(content) {
    const detailStrukturMapel = content.map((peminatan, index) => {
      return (
        <tr key={index}>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.kode_kmp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.kmp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.kode_jkmp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.jkmp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.kode_mp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.mp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.indeks_mpk}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.semester_1}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-4 py-2 flex items-center">
              {peminatan.semester_2}
            </span>
          </td>
        </tr>
      );
    });
    return (
      <table className="w-full border-gray-400">
        <thead>
          <tr className="text-left">
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600 tracking-wider text-sm">
              KODE
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              KMP
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              KODE
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              JKMP
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              KODE
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              MATA PELAJARAN
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              INDEKS MPK
            </th>
            <th className="top-0  border-gray-700 border-b px-1 py-2 text-gray-600 tracking-wider  text-sm">
              KUOTA SEMESTER 1
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600 tracking-wider  text-sm">
              KUOTA SEMESTER 2
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.loadingDetail ? (
            <>
              <tr>
                <td
                  className=" border-t border-gray-200 userId overflow-hidden"
                  colSpan="100"
                >
                  <TableSkeleton
                    style={{
                      width: "76rem",
                      bottom: "90px",
                      position: "relative",
                    }}
                  />
                </td>
              </tr>
            </>
          ) : (
            <>{detailStrukturMapel}</>
          )}
        </tbody>
      </table>
    );
  }

  setShowModal(show = true, kode_mapel = null) {
    this.setState({ showModal: show, kode_mapel: kode_mapel });
    var code = "I";
    if (this.state.jenjang == "2") {
      code = "VII";
    } else if (this.state.jenjang == "3" || this.state.jenjang == "4") {
      code = "X";
    }
    this.showDataDetail(kode_mapel, code);
    if (show == false) {
      this.setState({ content: [], tabsActive: code });
    }
  }

  showDetailModal() {
    // const elements = ["I", "II", "III", "IV", "V", "VI"];
    return (
      <>
        <Modal
          size="xl"
          show={this.state.showModal}
          onHide={() => this.setShowModal(false)}
        >
          <Modal.Body>
            <div className="flex items-start text-xl justify-between p-2 w-full rounded-t">
              Daftar Mata Pelajaran
              <button
                className="flex bg-white text-xl text-black px-4 py-1 rounded-full items-center"
                onClick={() => this.setShowModal(false)}
              >
                ×
              </button>
            </div>
            <div className="relative pb-4 flex-auto">
              <ul className="list-reset flex border-b">
                {this.state.elements.map((value, index) => {
                  return (
                    <li className="-mb-px mr-1" key={index}>
                      {this.state.tabsActive == value ? (
                        <a
                          className="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold"
                          href="#"
                          onClick={this.setActiveTabs.bind(this, value)}
                        >
                          {value}
                        </a>

                      ) : (
                        <a
                          className="bg-white inline-block py-2 px-4 hover:text-blue-800 font-semibold"
                          href="#"
                          onClick={this.setActiveTabs.bind(this, value)}
                        >
                          {value}
                        </a>
                      )}
                    </li>
                  );
                })}
                <>
                  <div className="flex items-start text-md justify-between p-2 w-full rounded-t">
                    <div></div>
                    <button
                      className="flex bg-schoolmedia text-md text-white px-4 py-1 rounded-full items-center"
                      onClick={this.setShowModalListKustomMapel.bind(this)}
                    >
                      List Kurikulum Kustom
                    </button>
                  </div>
                  <div className="flex items-start text-md justify-between p-2 w-full rounded-t">
                    <div></div>
                    <button
                      className="flex bg-schoolmedia text-md text-white px-4 py-1 rounded-full items-center"
                      onClick={this.setShowModalTambahMapel.bind(this)}
                    >
                      Tambah Mapel
                    </button>
                  </div>
                </>
              </ul>
              
              <div className="overflow-x-scroll">
                {this.state.content != undefined
                  ? this.renderTableDataDetail(this.state.content)
                  : ""}
              </div>
              
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center justify-end p-2 rounded-b">
              <button
                className="flex bg-white text-sm text-gray-800 border-2 px-4 py-2 rounded-full items-center"
                type="button"
                onClick={() => this.setShowModal(false)}
              >
                Tutup
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  setActiveTabs(id) {
    this.setState({
      tabsActive: id,
      // content: {
      //   nasional: undefined,
      //   lintas1: undefined,
      //   lintas2: undefined,
      // },
    });
    this.showDataDetail2(id);
  }

  showDataDetail(kode_mapel, defaults) {
    axios
      .get(
        `${conf.uri_backend}api/v1/referensi/struktur-kurikulum/${this.state.jenjang_id}/detail?kode_mapel=null&kelas=${defaults}&status=merdeka&uid=${this.state.userInstitusiIde}`,
        {
          headers: {
            Authorization: tokenApi,
          },
        }
      )
      .then((res) => {
        res.data.map((data, index) => {
          if(data.mp == "Muatan Lokal") console.log("ez");
          if(data.mp == "Muatan Lokal") this.setState({ id_jkmp_ml: data.id_jkmp, mp_ml: data.mp});
          if(data.mp == "Kustom/Lain-lain") this.setState({ id_jkmp_custom: data.id_jkmp, mp_custom: data.mp});
        })
        this.setState({ content: res.data });
        this.setState({ loadingDetail: false });
      });
  }

  showDataDetail2(id) {
    this.setState({ loadingDetail: true });
    axios
      .get(
        `${conf.uri_backend}api/v1/referensi/struktur-kurikulum/${this.state.jenjang_id}/detail?kode_mapel=null&kelas=${id}&status=merdeka&uid=${this.state.userInstitusiIde}`,
        {
          headers: {
            Authorization: tokenApi,
          },
        }
      )
      .then((res) => {
        this.setState({ content: res.data });
        this.setState({ loadingDetail: false });
      });
  }

  alertMessage(e){
    let message = null;

    switch(e.status){
      case 422:
        message = e.data.messages[0];
      break;
      case 500:
        message= "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
      break;
      case 401:
        message = "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 400:
        message = "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 408:
        message = "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 429:
        message = "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 405:
        message = "Method Tidak Diperbolehkan, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
    }

    this.setState({
      show_alert    : true,
      title_alert   : "Gagal",
      icon_alert    : "error",
      message_alert : message,
      simpleLoading: false,
    })
  }

  jkmp(e){
    if(e){
      this.setState({ jkmpTambah: e.value })
    }
    
  }

  kodeMapel(e){
    this.setState({ kodeMapelTambah: e })
  }

  mapel(e){
    this.setState({ mapelTambah: e })
  }

  kuotaSmt1(e){
    this.setState({ kuotaSmt1Tambah: e })
  }

  kuotaSmt2(e){
    this.setState({ kuotaSmt2Tambah: e })
  }

  inputDataMapelBaru = (e) =>{
      e.preventDefault()
      // alert("work")

      let jkmp= this.state.jkmpTambah
      let kodeMapel= this.state.kodeMapelTambah
      let mapel= this.state.mapelTambah
      let kuotaSmt1= this.state.kuotaSmt1Tambah
      let kuotaSmt2= this.state.kuotaSmt2Tambah
      let uid= this.state.userInstitusiIde
      let jenjang_id = this.state.jenjang_id
      let id_jkmp = this.state.jkmpTambah == 'lokal' ? this.state.id_jkmp_ml : this.state.id_jkmp_custom

      axios.post(`${conf.uri_backend}api/v1/referensi/struktur-kurikulum/kustom-mapel`, {jkmp, kodeMapel, mapel, kuotaSmt1, kuotaSmt2, uid, jenjang_id, id_jkmp})
      .then(res => {
        this.setState({ 
          show_alert      : true,
          title_alert     : "Berhasil",
          icon_alert      : "success",
          message_alert   : 'Berhasil Menambahkan Mapel Baru',
        });
        this.setState({ showModalTambahMapel: false });
      })
      .catch(err => {
        this.setState({simpleLoading: false});
        this.alertMessage(err.response)
      });
  }

  inputEditDataMapelBaru = (e) =>{
      e.preventDefault()
      // alert("work")
      let id_mp= this.state.selectIdMp
      let jkmp= this.state.jkmpTambah
      let kodeMapel= this.state.kodeMapelTambah
      let mapel= this.state.mapelTambah
      let uid= this.state.userInstitusiIde
      let jenjang_id = this.state.jenjang_id
      let id_jkmp = this.state.jkmpTambah == 'lokal' ? this.state.id_jkmp_ml : this.state.id_jkmp_custom

      axios.post(`${conf.uri_backend}api/v1/referensi/struktur-kurikulum/update-kustom-mapel`, {id_mp, jkmp, kodeMapel, mapel, uid, jenjang_id, id_jkmp})
      .then(res => {
        this.setState({ 
          show_alert      : true,
          title_alert     : "Berhasil",
          icon_alert      : "success",
          message_alert   : 'Berhasil Menambahkan Mapel Baru',
        });
        this.setState({ showModalEditMapel: false });
      })
      .catch(err => {
        this.setState({simpleLoading: false});
        this.alertMessage(err.response)
      });
  }

  setShowModalListKustomMapel(show = true) {
    this.setState({ showModalListKustomMapel: show }, function() {
      this.getListKustomMapel();
    });
    this.setState({ showModal: false })
    if (show == false) {
      this.setState({ content: [] });
    }
  }

  setShowModalTambahMapel(show = true) {
    this.setState({ showModalTambahMapel: show });
    this.setState({ showModal: false })
    if (show == false) {
      this.setState({ content: [] });
    }
  }

  setShowModalEditMapel(show = true, id = null) {
    console.log( 'sss',show);
    this.setState({ showModalEditMapel: show, loading_edit:true, }, function() {
      this.getEditMapel(id);
    });
    this.setState({ showModal: false })
    this.setState({ showModalListKustomMapel: false })
    if (show == false) {
      this.setState({ content: [] });
    } 
  }

  getEditMapel(id) {
    if (id != null) {
      axios
        .get(
          `${conf.uri_backend}api/v1/referensi/struktur-kurikulum/edit-kustom-mapel?id_mp=${id}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          this.setState({
            selectIdMp: id,
            selectMapel: res.data,
            jkmpTambah : res.data?.m_struktur_jkmp.jkmp,
            mapel : res.data?.mp,
            kodeMapel : res.data?.kode_mp,
            loading_edit: false,
          })
        })
    } 
  }

  delete(data){
    this.setState({loading_delete: true});

    swal.fire({
      title: 'Perhatian',
      icon: 'warning',
      text: 'Apakah anda yakin menghapus user lainnya tersebut?',
      showCancelButton: true,
      confirmButtonText: `Iya`,
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.value == true) {
        axios.post(this.state.url_delete_kustom_mapel, {
          id_mp : data,
          id_jenjang : this.state.jenjang_id,
          uid : this.state.userInstitusiIde
        }, {headers:{Authorization:tokenApi}})
          .then(res => {
            this.setState({ 
              show_alert      : true,
              loading_delete      : false,
              title_alert     : "Berhasil",
              icon_alert      : "success",
              message_alert   : 'Berhasil Menghapus Mapel Kustom',
            }, function() {
              this.setShowModalListKustomMapel(false, null);
            });
          })
          .catch(err => {
            if(err.response.status == 500){
              this.setState({ 
                show_alert    : true,
                title_alert   : "Gagal",
                loading_delete      : false,
                icon_alert    : "error",
                message_alert : "Periksa koneksi anda",
              });
            }
            this.alertMessage(err.response); 
          })
      } else if (result.dismiss == "cancel") {
        this.setState({loading_delete: false});
      } else if (result.dismiss == "backdrop") {
          this.setState({loading_delete: false});
        }
    });
  }

  showMdlTambahMapel() {
    // const elements = ["I", "II", "III", "IV", "V", "VI"];
    return (
      <>
      {/* alert */}
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />
        <Modal
          size="md"
          show={this.state.showModalTambahMapel}
          onHide={() => this.setShowModalTambahMapel(false)}
        >
          <Modal.Body>
            <div className="flex items-start text-xl justify-between p-2 w-full rounded-t">
              Kustom Mata Pelajaran
              <button
                className="flex bg-white text-xl text-black px-4 py-1 rounded-full items-center"
                onClick={() => this.setShowModalTambahMapel(false)}
              >
                ×
              </button>
            </div>
            <div className="relative pb-4 flex-auto">
              
              <div className="">
              <div class="mt-10 sm:mt-0">
                  <form onSubmit={this.inputDataMapelBaru}>
                    <div class=" overflow-hidden sm:rounded-md">
                        <div class="col-span-12 sm:col-span-3">
                          <label class="block text-md font-medium text-gray-700">JKMP</label>
                          {/* <input
                            type="search"
                            className="w-100 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                            placeholder="Lokal atau Prakarya dan Vokasi"
                            id="jkmp"
                            onChange={e => this.jkmp(e)}
                          /> */}
                          {/* <label className="mb-2 ml-2 text-gray-800 block text-sm">JKMP</label> */}
                          <Select
                            styles={selectStyles}
                            className="react-select__control"
                            options={this.state.jenjang_id != 5 ? dataJKMPUmum : dataJKMPSmk}
                            placeholder="Pilih JKMP"
                            onChange={e => this.jkmp(e)}
                          />
                        </div>

                        <div class="col-span-12 sm:col-span-3">
                          <label class="block text-md font-medium text-gray-700">Kode Mata Pelajaran</label>
                          <input
                            type="text"
                            className="w-100 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                            placeholder="Ex: BKM"
                            id="kode_mapel"
                            onChange={e => this.kodeMapel(e.currentTarget.value)}
                          />
                        </div>

                        <div class="col-span-12 sm:col-span-4">
                          <label class="block text-md font-medium text-gray-1000">Mata Pelajaran</label>
                          <input
                            type="text"
                            className="w-100 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                            placeholder="Ex: Bimbingan Konseling dan Moral"
                            id="mapel"
                            onChange={e => this.mapel(e.currentTarget.value)}
                          />
                        </div>

                        <div class="col-span-12 sm:col-span-4">
                          <label class="block text-md font-medium text-gray-700">Kuota Semester 1</label>
                          <input
                            type="text"
                            className="w-100 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                            placeholder="Ex: 2"
                            id="kuota_smt_1"
                            onChange={e => this.kuotaSmt1(e.currentTarget.value)}
                          />
                        </div>
                        <div class="col-span-12 sm:col-span-4">
                          <label class="block text-md font-medium text-gray-700">Kuota Semester 2</label>
                          <input
                            type="text"
                            className="w-100 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                            placeholder="Ex: 2"
                            id="kuota_smt_2"
                            onChange={e => this.kuotaSmt2(e.currentTarget.value)}
                          />
                        </div>
                        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Simpan</button>
                        </div>
                    </div>
                  </form>
              </div>
              </div>
              
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  showMdlEditMapel() {
    // const elements = ["I", "II", "III", "IV", "V", "VI"];
    return (
      <>
      {/* alert */}
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />
        <Modal
          size="md"
          show={this.state.showModalEditMapel}
          onHide={() => this.setShowModalEditMapel(false)}
        >
          <Modal.Body>
            <div className="flex items-start text-xl justify-between p-2 w-full rounded-t">
              Edit Kustom Mata Pelajaran
              <button
                className="flex bg-white text-xl text-black px-4 py-1 rounded-full items-center"
                onClick={() => this.setShowModalEditMapel(false)}
              >
                ×
              </button>
            </div>
            <div className="relative pb-4 flex-auto">
              
              <div className="">
              <div class="mt-10 sm:mt-0">
                  {this.state.loading_edit == false ? (
                    <form onSubmit={this.inputEditDataMapelBaru}>
                      <div class="overflow-hidden sm:rounded-md">
                          <div class="col-span-12 sm:col-span-3">
                            <label class="block text-md font-medium text-gray-700">JKMP</label>
                            {/* <input
                              type="search"
                              className="w-100 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                              placeholder="Lokal atau Prakarya dan Vokasi"
                              id="jkmp"
                              onChange={e => this.jkmp(e)}
                            /> */}
                            {/* <label className="mb-2 ml-2 text-gray-800 block text-sm">JKMP</label> */}
                            <Select
                              styles={selectStyles}
                              className="react-select__control"
                              defaultValue={{value: this.state.selectMapel.m_struktur_jkmp.jkmp.toLowerCase(), label: this.state.selectMapel.m_struktur_jkmp.jkmp}}
                              options={this.state.jenjang_id != 5 ? dataJKMPUmum : dataJKMPSmk}
                              placeholder="Pilih JKMP"
                              onChange={e => this.jkmp(e)}
                            />
                          </div>

                          <div class="col-span-12 sm:col-span-3">
                            <label class="block text-md font-medium text-gray-700">Kode Mata Pelajaran</label>
                            <input
                              type="text"
                              className="w-100 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                              placeholder="Ex: BKM"
                              id="kode_mapel"
                              defaultValue={this.state.selectMapel.kode_mp}
                              onChange={e => this.kodeMapel(e.currentTarget.value)}
                            />
                          </div>

                          <div class="col-span-12 sm:col-span-4">
                            <label class="block text-md font-medium text-gray-1000">Mata Pelajaran</label>
                            <input
                              type="text"
                              className="w-100 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                              placeholder="Ex: Bimbingan Konseling dan Moral"
                              id="mapel"
                              defaultValue={this.state.selectMapel.mp}
                              onChange={e => this.mapel(e.currentTarget.value)}
                            />
                          </div>

                          {/* <div class="col-span-12 sm:col-span-4">
                            <label class="block text-md font-medium text-gray-700">Kuota Semester 1</label>
                            <input
                              type="text"
                              className="w-100 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                              placeholder="Ex: 2"
                              id="kuota_smt_1"
                              onChange={e => this.kuotaSmt1(e.currentTarget.value)}
                            />
                          </div>
                          <div class="col-span-12 sm:col-span-4">
                            <label class="block text-md font-medium text-gray-700">Kuota Semester 2</label>
                            <input
                              type="text"
                              className="w-100 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                              placeholder="Ex: 2"
                              id="kuota_smt_2"
                              onChange={e => this.kuotaSmt2(e.currentTarget.value)}
                            />
                          </div> */}
                          <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Edit</button>
                          </div>
                      </div>
                    </form>
                  ) : ("")}
              </div>
              </div>
              
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  showMdlListKustomMapel() {
    return (
      <>
      {/* alert */}
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />
        <Modal
          size="md"
          show={this.state.showModalListKustomMapel}
          onHide={() => this.setShowModalListKustomMapel(false)}
        >
          <Modal.Body>
            <div className="flex items-start text-xl justify-between p-2 w-full rounded-t">
              List Kustom Mapel
              <button
                className="flex bg-white text-xl text-black px-4 py-1 rounded-full items-center"
                onClick={() => this.setShowModalListKustomMapel(false)}
              >
                ×
              </button>
            </div>
            <div className="relative pb-4 flex-auto">
              <div className="overflow-x-scroll">
                {this.state.listKustomMapel != null ? (this.renderTableDataListKustomMapel(this.state.listKustomMapel)) : ("Belum ada Kustom Mapel")}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center justify-end p-2 rounded-b">
              <button
                className="flex bg-white text-sm text-gray-800 border-2 px-4 py-2 rounded-full items-center"
                type="button"
                onClick={() => this.setShowModalListKustomMapel(false)}
              >
                Tutup
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
