import React from 'react';
import axios from "axios";
import conf from "./../../../config.js";
import tokenApi from "../../auth/Helpers";
import Select from 'react-select';
import {Tab, Tabs} from 'react-bootstrap';
import DataTable from "./../../partials/table/DataTables.js";
import Skeleton from 'react-loading-skeleton';

const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

export default class ForumLevel1 extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      url : `${conf.uri_backend}api/v1/forum`,
      institusi_id_select : [],
      m_struktur_id : [],
      tahunSekarang : new Date().getFullYear(),
      data_tahun_ajaran : null,
      tahun_ajaran_search : '',
      showTable : false,
      query : null
    };

    // Function Of Filter Table
    this.getTahunAjaranNow = this.getTahunAjaranNow.bind(this);
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
  }

  componentDidMount() {
    // get session user
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ data_session: res.data }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({ 
              institusi_id_select : res.data.id,
              m_struktur_id : res.data.m_struktur_jenjang_id,
            });
            this.getTahunAjaranNow();
          });
        });
      });
    }
  }

  getTahunAjaranNow(){
    var ta              = [];
    var u               = this.state.tahunSekarang + 1;
    var tahun_sekarang  = this.state.tahunSekarang + 1;

    for(var i=tahun_sekarang; i >= 2005; i--){
      u = u -1;
      ta.push({"value":u+' / '+i, "label":u+' / '+i});
    }
    this.setState({ data_tahun_ajaran: ta });
    this.setState({ disabled_tahun_ajaran: true });
  }

  // FILTER HANDLE CHANGE FUNCTION
  filterTahunAjaran(e){
    if(e){
      this.setState({ tahun_ajaran_search : e.value })
    } else {
      this.setState({ 
        tahun_ajaran_search : '',
        query : null,
      });
    }
  }

  setFilter(e){
    e.preventDefault();
    if(this.state.tahun_ajaran_search != ''){
      var query = `&level=1&institusi_id=${this.state.institusi_id_select}&tahun_ajaran=${this.state.tahun_ajaran_search}`;
      this.setState({
        showTable : true,
        query : query
      }, function() {
        this.dtsiswa.fetchEntities(this.state.query+`&jenis_user=siswa`);
        this.dtguru.fetchEntities(this.state.query+`&jenis_user=guru`);
        this.dtlainnya.fetchEntities(this.state.query+`&jenis_user=lainnya`);
      })
    }
  }

  setResetFilter(e){
    e.preventDefault();
    this.setState({ 
      tahun_ajaran_search : '', 
      showTable : false,
      data_tahun_ajaran : null,
      query : null,
    }, function() {
      this.getTahunAjaranNow();
    });
  }
    
  render(){
    return(
      <>
        <div>
          <div className="sm-container shadow-md mx-auto py-3 mt-6 rounded-lg bg-white border">
            <div className="flex justify-between items-center mt-2 px-6 pb-4">
              <h1 className="text-xl text-gray-800 font-extrabold">
                Forum Level 1
                <span className="text-gray-600 font-thin block text-sm">
                  Daftar user dalam forum grup tetap level 1. {" "}
                  {/* <a href="/sadasd" className="text-blue-600">
                    Pelajari Lebih Lanjut ?
                  </a> */}
                </span>
              </h1>
            </div>
            <div className="flex w-full border-t flex-wrap items-center pt-2">
              <div className="flex w-full flex-wrap items-center px-4">
                {this.state.data_tahun_ajaran !== null ? (
                  <div className="py-2">
                    <Select
                      isDisabled = {this.state.disabled_tahun_ajaran ? false : true}
                      isLoading = {this.state.disabled_tahun_ajaran ? false : true}
                      isClearable
                      id="filterTahunAjaran"
                      styles={selectStyles}
                      options={this.state.data_tahun_ajaran}
                      onChange={this.filterTahunAjaran}
                      placeholder= {'Tahun Ajaran'}
                    />
                  </div>
                ) : (
                  <div className="w-1/4 px-1">
                    <div className="py-2">
                      <Skeleton count={2}/>
                    </div>
                  </div>
                )}
                {this.state.disabled_tahun_ajaran ? (
                  <>
                    <button onClick={this.setFilter} className="animateFadeInLeft flex btn-schoolmedia text-sm text-white ml-2 px-4 py-2 rounded-full items-center">
                      <i className="bx bx-search mr-1"></i> Cari
                    </button>
                    <button onClick={this.setResetFilter}className="animateFadeInLeft flex btn-default text-sm ml-2 px-4 py-2 rounded-full items-center">
                      Reset
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="shadow-md mx-auto py-3 px-6 mt-2 rounded-lg bg-white sm-container border">
            {this.state.showTable ? (
              <Tabs defaultActiveKey="siswa" id="uncontrolled-tab-example">
                <Tab eventKey="siswa" title="Siswa">
                  <div className="flex w-full flex-wrap items-center mt-6 pb-2">
                      <div className="flex flex-row-reverse pl-2 w-1/3 rounded-full border border-gray-500">
                        <input
                          type="search"
                          className="w-full pl-1 pr-4 py-1 rounded-full text-gray-800 focus:outline-none focus:shadow-outline"
                          placeholder="Cari Nama Siswa"
                          id="searchSiswa"
                          onChange={(event) => {
                            this.dtsiswa.fetchEntities(`
                              &jenis_user=siswa
                              &query=${event.target.value}
                              ${this.state.query}
                            `);
                          }}
                        >
                        </input>
                        <div className="relative top-0 left-0 inline-flex items-center py-2 px-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 text-gray-400"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                            <circle cx="10" cy="10" r="7"></circle>
                            <line x1="21" y1="21" x2="15" y2="15"></line>
                          </svg>
                        </div>
                      </div>
                  </div>
                    <DataTable
                      ref={(dt) => {
                        this.dtsiswa = dt;
                      }}
                      url={`${this.state.url}`}
                      action={{
                        jenis_kelamin: (col, data) => {
                          var jekel = '';
                          if(col.jenis_kelamin == 1){
                            jekel = "Laki - Laki";
                          } else if(col.jenis_kelamin == 0){ 
                            jekel = "Perempuan";
                          } else {
                            jekel = "Belum diketahui";
                          }
                          return (<p className="text-sm">{jekel}</p>);
                        },
                      }}
                      columns={[
                        {
                          index: "tahun_ajaran",
                          label: "TAHUN AJARAN",
                        },
                        {
                          index: "kelas",
                          label: "KELAS",
                        },
                        this.state.m_struktur_id == 4 ? 
                        {
                          index: "jurusan",
                          label: "PEMINATAN",
                        } : {},
                        this.state.m_struktur_id == 5 ? 
                        {
                          index: "jurusan",
                          label: "Kompetensi",
                        } : {},
                        {
                          index: "rombel",
                          label: "ROMBEL",
                        },
                        {
                          index: "nama",
                          label: "NAMA",
                        },
                        {
                          index: "jenis_kelamin",
                          label: "JENIS KELAMIN",
                        },
                      ]}
                    />
                </Tab>
                <Tab eventKey="guru" title="Guru">
                  <div className="flex w-full flex-wrap items-center mt-6 pb-2">
                    <div className="flex flex-row-reverse pl-2 w-1/3 rounded-full border border-gray-500">
                      <input
                        type="search"
                        className="w-full pl-1 pr-4 py-1 rounded-full text-gray-800 focus:outline-none focus:shadow-outline"
                        placeholder="Cari Nama Guru"
                        id="searchGuru"
                        onChange={(event) => {
                          this.dtguru.fetchEntities(`
                            &jenis_user=guru
                            &query=${event.target.value}
                            ${this.state.query}
                          `);
                        }}
                      >
                      </input>
                      <div className="relative top-0 left-0 inline-flex items-center py-2 px-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5 text-gray-400"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                          <circle cx="10" cy="10" r="7"></circle>
                          <line x1="21" y1="21" x2="15" y2="15"></line>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <DataTable
                    ref={(dt) => {
                      this.dtguru = dt;
                    }}
                    url={`${this.state.url}`}
                    action={{
                      jenis_kelamin: (col, data) => {
                        var jekel = '';
                        if(col.jenis_kelamin == 1){
                          jekel = "Laki - Laki";
                        } else if(col.jenis_kelamin == 0){ 
                          jekel = "Perempuan";
                        } else {
                          jekel = "Belum diketahui";
                        }
                        return (<p className="text-sm">{jekel}</p>);
                      },
                    }}
                    columns={[
                      {
                        index: "tahun_ajaran",
                        label: "TAHUN AJARAN",
                      },
                      {
                        index: "nama_penugasan",
                        label: "PENUGASAN GURU",
                      },
                      {
                        index: "nama",
                        label: "NAMA",
                      },
                      {
                        index: "jenis_kelamin",
                        label: "JENIS KELAMIN",
                      },
                    ]}
                  />
                </Tab>
                <Tab eventKey="lainnya" title="Lainnya">
                  <div className="flex w-full flex-wrap items-center mt-6 pb-2">
                    <div className="flex flex-row-reverse pl-2 w-1/3 rounded-full border border-gray-500">
                      <input
                        type="search"
                        className="w-full pl-1 pr-4 py-1 rounded-full text-gray-800 focus:outline-none focus:shadow-outline"
                        placeholder="Cari Nama lainnya"
                        id="searchLainnya"
                        onChange={(event) => {
                          this.dtlainnya.fetchEntities(`
                            &jenis_user=lainnya
                            &query=${event.target.value}
                            ${this.state.query}
                          `);
                        }}
                      >
                      </input>
                      <div className="relative top-0 left-0 inline-flex items-center py-2 px-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5 text-gray-400"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                          <circle cx="10" cy="10" r="7"></circle>
                          <line x1="21" y1="21" x2="15" y2="15"></line>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <DataTable
                    ref={(dt) => {
                      this.dtlainnya = dt;
                    }}
                    url={`${this.state.url}`}
                    action={{
                      jenis_kelamin: (col, data) => {
                        var jekel = '';
                        if(col.jenis_kelamin == 1){
                          jekel = "Laki - Laki";
                        } else if(col.jenis_kelamin == 0){ 
                          jekel = "Perempuan";
                        } else {
                          jekel = "Belum diketahui";
                        }
                        return (<p className="text-sm">{jekel}</p>);
                      },
                    }}
                    columns={[
                      {
                        index: "tahun_ajaran",
                        label: "TAHUN AJARAN",
                      },
                      {
                        index: "nama_penugasan",
                        label: "PENUGASAN LAINNYA",
                      },
                      {
                        index: "nama",
                        label: "NAMA",
                      },
                      {
                        index: "jenis_kelamin",
                        label: "JENIS KELAMIN",
                      },
                    ]}
                  />
                </Tab>
              </Tabs>
            ) : (
              <div className="w-full flex justify-center items-center py-2 text-center">
                <p className="text-sm">Select Filter terlebih dahulu</p>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}