import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import conf from "../../config.js";
import axios from "axios";
// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

export default class Caught extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // url api
    };
  }

  componentDidMount(){
    const query = new URLSearchParams(this.props.location.search);
    const sso_token = query.get('SSO_TOKEN');

    axios.post(`${conf.uri_backend}api/v1/auth/sso/login`, {token:sso_token}).then(res => {
      localStorage.setItem("token", res.data.access_token);
      localStorage.setItem('is_login', 'is_login');
      window.location.replace(`${conf.uri_frontend}profil`);
    }).catch(function(response){
      if (response.response.status == 302){
        // alert('Hanya user dengan group code NI yang bisa masuk');
        if(response.response.data.messages != null){
          localStorage.setItem("alert", response.response.data.messages);
        }
        window.location.replace(response.response.data.message);
      } 
      else if(response.response.status == 403){

        // localStorage.setItem("alert", response.response.data.messages);
        // alert('Hanya user dengan group code NI yang bisa masuk');
        window.location.replace(response.response.data.message);
      }
    });
    
  }

 

  render() {
    // let modalPelajari=this.props.modal_pelajari
    return (
      <React.Fragment>
        <style dangerouslySetInnerHTML=
          {{
            __html: `.sm-navbar { display: none }`
          }}
        />
        <div className="loader-box" style={{background: "#0b0f25"}}>
          <div className="loader"></div>
        </div>
      </React.Fragment>
    )
  }
}
