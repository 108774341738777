import React from "react";
import DataTable from "./../../partials/table/DataTables.js";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import conf from "./../../../config.js";
import SelectJs from "./../../partials/Select.js";
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import { render } from "@testing-library/react";
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import SweetAlert from 'sweetalert2-react';
import Skeleton from "react-loading-skeleton";

const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

const sekolah = [
  { value: 'SMKN 1 Dlanggu', label: 'SMKN 1 Dlanggu' },
  { value: 'SMK Pemuda', label: 'SMK Pemuda' },
  { value: 'SMK Hayam Wuruk', label: 'SMK Hayam Wuruk' }
];

export default class PindahSekolah extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url                 : `${conf.uri_backend}api/v1/rombel/siswa_mutasi/siswa`,
      url_get_negara      : `${conf.uri_backend}api/v1/referensi/wilayah/negara?per_page=20`,
      url_get_provinsi    : `${conf.uri_backend}api/v1/referensi/wilayah/provinsi?per_page=20&negara_id=`,
      url_get_kota        : `${conf.uri_backend}api/v1/referensi/wilayah/kota?per_page=20&provinsi_id=`,
      url_get_kecamatan   : `${conf.uri_backend}api/v1/referensi/wilayah/kecamatan?per_page=20&kota_id=`,
      url_get_kelurahan   : `${conf.uri_backend}api/v1/referensi/wilayah/kelurahan?per_page=20&kecamatan_id=`,  
      url_get_sekolah     : `${conf.uri_backend}api/v1/sekolah`,  
      url_get_rombel      : `${conf.uri_backend}api/v1/rombel/get_rombel/`,
      url_save_pindah     : `${conf.uri_backend}api/v1/rombel/mutasi`,
      url_get_smk         : `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,
      url_get_spektrum    : `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_filter      : `${conf.uri_backend}api/v1/rombel/filter`,
      url_show_data       : '',

      data_negara         : '',
      data_provinsi       : '',
      data_kota           : '',
      data_kecamatan      : '',
      data_kelurahan      : '',
      tahunSekarang       : new Date().getFullYear(),
      modal_pindah        : false,
      searchText          : '',
      loadingFilter       : true,
      // alert
      show_alert          : false,
      title_alert         : '',
      message_alert       : '',
      icon_alert          : '',
    };

    this.getNegara           = this.getNegara.bind(this);
    this.getProvinsi         = this.getProvinsi.bind(this);
    this.getKota             = this.getKota.bind(this);
    this.getKecamatan        = this.getKecamatan.bind(this);
    this.getKelurahan        = this.getKelurahan.bind(this);
    this.getSekolah          = this.getSekolah.bind(this);
    this.filterPindahKe      = this.filterPindahKe.bind(this);

    this.saveMutasi          = this.saveMutasi.bind(this);

    // Pindah Funtion
    this.showModalPindah     = this.showModalPindah.bind(this);
    this.hideModalPindah     = this.hideModalPindah.bind(this);
  
    this.searchText          = this.searchText.bind(this);

  }

  componentDidMount() {
    // get session user
    if(localStorage.getItem('token') !== null){
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ data_session: res.data }, function() {
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({ 
              institusi_id_select : res.data.id,
              jenjang_id_select   : res.data.m_struktur_jenjang_id,
              url_show_data       : `${this.state.url}`,
              query               : '&institusi='+res.data.id,
            }, function(){
              this.getNegara();
              this.dt.fetchEntities(this.state.query);
            });
          });
        });
      });
    }
  }

  alertMessage(e){
    let message = null;

    switch(e.status){
      case 422:
        message = e.data.messages[0];
      break;
      case 500:
        message= "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
      break;
      case 401:
        message = "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 400:
        message = "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 408:
        message = "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 429:
        message = "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 405:
        message = "Method Tidak Diperbolehkan, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
    }

    this.setState({
      show_alert    : true,
      title_alert   : "Gagal",
      icon_alert    : "error",
      message_alert : message,
      simpleLoading: false,
    })
  }

  getNegara(){
    axios.get(this.state.url_get_negara, {headers:{Authorization:tokenApi}})
    .then( res => {
      this.setState({data_negara: res.data});
    });
  }

  getProvinsi(e){
    if(e){
      axios.get(this.state.url_get_provinsi+e.value, {headers:{Authorization:tokenApi}})
      .then( res => {
        this.setState({data_provinsi: res.data, negara:e.value},function () {});
      });
    }
  }

  getKota(e){
    if(e){
      axios.get(this.state.url_get_kota+e.value, {headers:{Authorization:tokenApi}})
      .then( res => {
        this.setState({data_kota: res.data, provinsi:e.value},function () {});
      });
    }
  }

  getKecamatan(e){
    if(e){
      axios.get(this.state.url_get_kecamatan+e.value, {headers:{Authorization:tokenApi}})
      .then( res => {
        this.setState({data_kecamatan: res.data, kota:e.value},function () {});
      });
    }
  }

  getKelurahan(e){
    if(e){
      axios.get(this.state.url_get_kelurahan+e.value, {headers:{Authorization:tokenApi}})
      .then( res => {
        this.setState({data_kelurahan: res.data, kecamatan:e.value},function () {});
      });
    }
  }

  getSekolah(e){
    if(e){
      axios.get(this.state.url_get_sekolah+`?jenjang_id=${this.state.jenjang_id_select}&negara_id=${this.state.negara}&provinsi_id=${this.state.provinsi}&kota_id=${this.state.kota}&kecamatan_id=${this.state.kecamatan}&keluarahan_id=${e.value}&institusi_id=${this.state.institusi_id_select}`, {headers:{Authorization:tokenApi}})
      .then( res => {
        if(res.data.length == 0){
          this.setState({kosong:true, keluarahan:e.value},function () {});
        } else {
          this.setState({data_sekolah: res.data, keluarahan:e.value},function () {});
        }
      });
    }
  }

  filterPindahKe(e){
    if(e){
      this.setState({ id_sekolah_pindah: e.value }, function() {});
    }
  }

  // SEARCH TEXT
  searchText(e){
    e.preventDefault();
    if(this.state.url_show_data !== ''){
      this.setState({
        searchText : e.target.value,
        query      : '&institusi='+this.state.institusi_id_select+
                     '&query='+e.target.value,
      }, function(){
        this.dt.loading();
        this.dt.resetPage(this.state.query);
      });
    }
  }

  showData(){
      return(
        <div>
          <DataTable
            ref={(dt) => {
              this.dt = dt;
            }}
            action={{
              jenis_kelamin:(col, data) => {
                var jk = ['Perempuan', 'Laki - laki'];
                var jenis_kelamin = jk[data.jenis_kelamin];
                return (<div className="text-sm">{jenis_kelamin}</div>);
              },

              aksi:(col, data) => {
                var button = <button onClick={this.showModalPindah.bind(this, data.siswa_id)} className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm">Pindah </button>;
                return (button);
              }
            }}
            url={this.state.url_show_data}
            columns={[
              {
                index: "kode_pendaftaran",
                label: "Kode Pendaftaran",
              },
              {
                index: "username",
                label: "Username",
              },
              {
                index: "nama",
                label: "NAMA LENGKAP",
              },
              // {
              //   index: "kelas",
              //   label: "KELAS",
              // },
              // {
              //   index: "peminatan",
              //   label: "PEMINATAN",
              // },
              // {
              //   index: "nama_rombel",
              //   label: "ROMBEL",
              // },
              {
                index: "jenis_kelamin",
                label: "JK",
              },
              {
                index: "tanggal_lahir",
                label: "TGL LAHIR",
              },
              // {
              //   index: "created_at",
              //   label: "TGL PENDAFTARAN",
              // },
              {
                index: "aksi",
                label: "AKSI",
              },
            ]}
          />
        </div>
      )
  }
  
  saveMutasi(e){
    e.preventDefault();
    axios.post(this.state.url_save_pindah, {
      user_siswa_id  : this.state.id_siswa,
      institusi_from : this.state.institusi_id_select,
      institusi_to   : this.state.id_sekolah_pindah,
      created_by     : this.state.institusi_id_select
    }, {headers:{Authorization:tokenApi}})
      .then(res => {
        this.setState({
          show_alert    : true,
          title_alert   : "Berhasil",
          icon_alert    : "success",
          message_alert : res.data.messages[0],
        });
        this.hideModalPindah();
        this.dt.fetchEntities(this.state.query);
      })
      .catch(err => {
        this.alertMessage(err.response)
      })
  }

  // Pindah Funtion
  showModalPindah(siswa){
    this.setState({ modal_pindah:true, id_siswa:siswa  });
  }

  hideModalPindah(){
    this.setState({ 
      modal_pindah      : false,
      data_provinsi     : '',
      data_kota         : '',
      data_kecamatan    : '',
      data_kelurahan    : '',
      data_sekolah      : '',
      id_sekolah_pindah : '',
      kosong : false,
    });
  }

  render () {
    return(
      <div>
        {/* alert */}
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />
        <div className="shadow-md mx-auto py-3 mt-6 rounded-lg bg-white sm-container border">
          <div className="bg-white flex justify-between items-center mt-2 px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Pindah Sekolah
              <span className="text-gray-500 font-thin block text-sm">
                Pindah Antar Sekolah. {" "}
                {/* <a href="/sadasd" className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
            <div className="flex">
            </div>
          </div>
        </div>
        <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white justify-between sm-container border">
          <div className="flex flex-row items-baseline px-3">
            {/* <button
              className="btn-blue px-4 mr-2 flex items-center rounded-full text-white py-2 text-xs"
              onClick={() => {
                this.setState({
                  showFilter: !this.state.showFilter,
                });
              }}
            >
              <i className="bx bx-filter text-lg mr-1"></i>
              Pilih
            </button> */}
            <div className="flex w-full flex-wrap items-center my-1 px-3 pb-2 pt-2">
              <input
                type="search"
                className="w-1/4 pl-2 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Cari kode pendaftaran, username, nama, rombel"
                id="search"
                onChange={this.searchText}
              />
            </div>
          </div>
          {/* {this.state.search ? (
            <div className="flex w-full border-t flex-wrap items-center my-1 px-3 pb-2 pt-2">
              <input
                type="search"
                className="w-1/4 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Cari disini"
                id="search"
                onChange={this.searchText}
              />
            </div>
          ) : (
            ""
          )} */}
        </div>    
        <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
          {this.showData()}
        </div>

        {/* modal pindah */}
        <Modal show={this.state.modal_pindah} onHide={this.hideModalPindah}>
          <Modal.Header>Pindah Siswa</Modal.Header>
          <Modal.Body>
            <form onSubmit={this.saveMutasi}>
              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">Negara</label>
                <Select
                  name="id_negara"
                  styles={selectStyles}
                  placeholder="Pilih Negara"
                  className="react-select__control"
                  options={this.state.data_negara}
                  onChange={this.getProvinsi}
                />
              </div>
              {this.state.data_provinsi != '' ? (
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">Provinsi</label>
                  <Select
                    styles={selectStyles}
                    placeholder="Pilih Provinsi"
                    className="react-select__control"
                    options={this.state.data_provinsi}
                    onChange={this.getKota}
                  />
                </div>
              ) : ("")}
              {this.state.data_kota != '' ? (
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">Kabupaten / Kota</label>
                  <Select
                    styles={selectStyles}
                    placeholder="Pilih Kabupaten"
                    className="react-select__control"
                    options={this.state.data_kota}
                    onChange={this.getKecamatan}
                  />
                </div>
              ) : ("")}
              {this.state.data_kecamatan != '' ? (
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">Kecamatan</label>
                  <Select
                    styles={selectStyles}
                    placeholder="Pilih Kecamatan"
                    className="react-select__control"
                    options={this.state.data_kecamatan}
                    onChange={this.getKelurahan}
                  />
                </div>
              ) : ("")}
              {this.state.data_kelurahan != '' ? (
              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">Kelurahan</label>
                <Select
                  styles={selectStyles}
                  placeholder="Pilih Kelurahan"
                  className="react-select__control"
                  options={this.state.data_kelurahan}
                  onChange={this.getSekolah}
                />
              </div>
              ) : ("")}
              {this.state.data_sekolah != '' && this.state.data_sekolah != null ? (
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">Nama Sekolah</label>
                  <Select
                    styles={selectStyles}
                    placeholder="Pilih Nama Sekolah"
                    className="react-select__control"
                    options={this.state.data_sekolah}
                    onChange={this.filterPindahKe}
                  />
                </div>
              ) : (
                <div className="w-full px-1">
                  <div className="py-2">
                    <Skeleton count={2}/>
                  </div>
                </div>
              )}
              {this.state.kosong == true ? (<p className="text-sm">Sekolah Tidak ada di daerah tersebut</p>) : ('')}
              <input type="submit" className="btn-schoolmedia px-4 py-2 mt-2 flex items-center rounded-full text-white text-sm" value="Pindah Siswa" />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideModalPindah}>Tutup</button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}