import React from "react";
import DataTable from "./../../partials/table/DataTables.js";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import conf from "./../../../config.js";
import SelectJs from "./../../partials/Select.js";
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import { render } from "@testing-library/react";
import { Button, Modal } from "react-bootstrap";
import SweetAlert from "sweetalert2-react";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { useChecklist } from "react-checklist";

import { UserButtonLoading } from "../../partials/loadings/ComponentLoading.js";
import { isEmpty } from "lodash";
import { capitalize } from "@material-ui/core";

const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "100px",
    minWidth: "15rem",
  }),
};

const dataKurikulum = [
  { value: "k13", label: "K13" },
  { value: "merdeka", label: "Merdeka" },
];

function captFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default class TambahKenaikanSiswa extends React.Component {
  selectRef = null;
  selectReff = null;
  constructor(props) {
    super(props);
    this.state = {
      url: `${conf.uri_backend}api/v1/rombel/table-kenaikan`,
      url_table_kenaikan_filter: `${conf.uri_backend}api/v1/rombel/table-kenaikan-filter`,
      url_naik_kelas: `${conf.uri_backend}api/v1/rombel/kenaikan`,
      url_get_rombel: `${conf.uri_backend}api/v1/rombel/get_rombel`,
      url_get_spektrum: `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_filter: `${conf.uri_backend}api/v1/rombel/filter`,
      url_tambah_alumni: `${conf.uri_backend}api/v1/rombel/alumni`,
      url_get_smk: `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,
      url_show_data: "",
      search: false,
      tahunSekarang: new Date().getFullYear(),
      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",

      tahun_ajaran_search: null,
      filterKurikulumSearch: "",
      kelas_search: "",
      peminatan_search: "",

      peminatan_search_baru: "",

      rombel_search: "",
      bidang_search: "",
      program_search: "",
      kompetensi_search: "",

      bidang_search_baru: "",
      program_search_baru: "",
      kompetensi_search_baru: "",
      searchText: "",

      data_tahun_ajaran: "",
      data_kelas: "",
      data_peminatan: "",
      data_peminatan_baru: "",
      data_bidang: "",
      data_program: "",
      data_kompetensi: "",
      data_rombel: "",

      loadingFilter: true,
      urutanFilter: true,
      showFilter: false,

      isChecked: false,
      check_item: false,
      checkAll: false,
      query: "",

      mode_show: 10,
      query: "",

      filterTahunAjaran: "",
      filterKelas: "",
      filterPeminatan: "",
      filterPeminatanBaru: "",
      filterRombel: "",
    };
    // get option filter
    this.getTahunAjaranNow = this.getTahunAjaranNow.bind(this);
    this.getKelas = this.getKelas.bind(this);
    this.getRombel = this.getRombel.bind(this);
    this.getPeminatanSMA = this.getPeminatanSMA.bind(this);
    this.getDataFilterSMA = this.getDataFilterSMA.bind(this);
    this.getBidang = this.getBidang.bind(this);
    this.getProgram = this.getProgram.bind(this);
    this.getKompetensi = this.getKompetensi.bind(this);
    // filter and search
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterKurikulume = this.filterKurikulume.bind(this);
    this.filterKelas = this.filterKelas.bind(this);
    // this.filterKurikulum = this.filterKurikulum.bind(this);
    this.filterRombel = this.filterRombel.bind(this);
    this.filterPeminatan = this.filterPeminatan.bind(this);
    this.filterBidang = this.filterBidang.bind(this);
    this.filterProgram = this.filterProgram.bind(this);
    this.filterKompetensi = this.filterKompetensi.bind(this);
    this.urutanJenjang = this.urutanJenjang.bind(this);
    this.searchText = this.searchText.bind(this);

    this.filterBidangBaru = this.filterBidangBaru.bind(this);
    this.filterProgramBaru = this.filterProgramBaru.bind(this);
    this.filterKompetensiBaru = this.filterKompetensiBaru.bind(this);

    // show select filter
    this.showSelectFilterSD = this.showSelectFilterSD.bind(this);
    this.showSelectFilterSMA = this.showSelectFilterSMA.bind(this);
    this.showSelectFilterSMK = this.showSelectFilterSMK.bind(this);
    this.showSelectFilterALL = this.showSelectFilterALL.bind(this);
    this.formFilter = this.formFilter.bind(this);
    this.clickFilter = this.clickFilter.bind(this);

    // this.getDataTable = this.getDataTable.bind(this);
    // processing  Data
    this.setFilter = this.setFilter.bind(this);
    this.showData = this.showData.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
    // Save data
    this.naikKelas = this.naikKelas.bind(this);
    this.tambahAlumni = this.tambahAlumni.bind(this);

    this.selectAll = this.selectAll.bind(this);
    this.selectOne = this.selectOne.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.unCheckAll = this.unCheckAll.bind(this);
    this.showMode = this.showMode.bind(this);
  }

  componentDidMount() {
    // get session user
    if (localStorage.getItem("token") !== null) {
      axios
        .post(`${conf.uri_backend}api/v1/auth/sso/user`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          this.setState({ data_session: res.data }, function() {
            axios
              .get(
                `${conf.uri_backend}api/v1/select-institusi/` +
                  this.state.data_session.access_to,
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                this.setState(
                  {
                    institusi_id_select: res.data.id,
                    jenjang_id_select: res.data.m_struktur_jenjang_id,
                  },
                  function() {
                    this.getDataFilterSMA();
                  }
                );
              });
          });
        });
    }
  }

  alertMessage(e) {
    let message = null;

    switch (e.status) {
      case 422:
        message = e.data.messages[0];
        break;
      case 500:
        message =
          "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
        break;
      case 401:
        message =
          "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 400:
        message =
          "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 408:
        message =
          "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 429:
        message =
          "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 405:
        message =
          "Method Tidak Diperbolehkan, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
    }

    this.setState({
      show_alert: true,
      title_alert: "Gagal",
      icon_alert: "error",
      message_alert: message,
      simpleLoading: false,
    });
  }

  showMode(num) {
    var m_spektrum = "";
    if (
      this.state.jenjang_id_select == "5"
        ? (m_spektrum = this.state.kompetensi_search)
        : (m_spektrum = this.state.peminatan_search)
    );

    this.setState(
      {
        mode_show: num,
        query:
          "&tahun_ajaran=" +
          this.state.tahun_ajaran_search +
          "&kelas=" +
          this.state.kelas_search +
          "&m_spektrum_id=" +
          m_spektrum +
          "&user_institusi_id=" +
          this.state.institusi_id_select +
          "&query=" +
          this.state.searchText +
          "&paginate=" +
          num,
      },
      function() {
        this.dt.loading();
        this.dt.resetPage(this.state.query);
      }
    );
  }

  getTahunAjaranNow() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  getKelas() {
    var kelas = [];
    if (this.state.jenjang_id_select == "2") {
      kelas = [
        { value: "I", label: "I" },
        { value: "II", label: "II" },
        { value: "III", label: "III" },
        { value: "IV", label: "IV" },
        { value: "V", label: "V" },
        { value: "VI", label: "VI" },
      ];
    } else if (this.state.jenjang_id_select == "3") {
      kelas = [
        { value: "VII", label: "VII" },
        { value: "VIII", label: "VIII" },
        { value: "IX", label: "IX" },
      ];
    } else if (this.state.jenjang_id_select == "4") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
      ];
    } else if (this.state.jenjang_id_select == "5") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
        { value: "XIII", label: "XIII" },
      ];
    }
    this.setState({ data_kelas: kelas });
  }

  getPeminatanSMA() {
    this.setState({ loading_peminatan: !this.state.loading_peminatan });
    if (this.state.jenjang_id_select == "4") {
      let filterKurikulum = this.state.filterKurikulumSearch
        ? this.state.filterKurikulumSearch
        : "k13";
      axios
        .get(
          this.state.url_get_spektrum +
            "?jenjang=" +
            this.state.jenjang_id_select +
            "&kurikulum=" +
            filterKurikulum,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (res.data.status == true) {
            this.setState({ data_peminatan: res.data.data });
            this.setState({ data_peminatan_baru: res.data.data });
            this.setState({ loading_peminatan: false });
          } else {
            this.setState({ data_peminatan: [] });
            this.setState({ data_peminatan_baru: [] });
            this.setState({ loading_peminatan: false });
          }
        });
    }
  }

  // SMK
  getBidang() {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_bidang: !this.state.loading_bidang });
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=0&jenjang_id=5&kurikulum=${this.state.filterKurikulumSearch}`,
          {
            headers: { Authorization: tokenApi },
          }
        )
        .then((res) => {
          this.setState({ data_bidang: res.data });
          this.setState({ loading_bidang: false });
        })
        .catch((err) => {
          this.setState({ loading_bidang: false });
        });
    }
  }
  getProgram() {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_program: !this.state.loading_program });
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=${this.state.bidang_search}&jenjang_id=5&kurikulum=${this.state.filterKurikulumSearch}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          this.setState({ data_program: res.data });
          this.setState({ loading_program: false });
        })
        .catch((err) => {
          this.setState({ loading_program: false });
        });
    }
  }
  getKompetensi(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_kompetensi: !this.state.loading_kompetensi });
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=${this.state.program_search}&jenjang_id=5&kurikulum=${this.state.filterKurikulumSearch}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          this.setState({ data_kompetensi: res.data });
          this.setState({ loading_kompetensi: false });
        })
        .catch((err) => {
          this.setState({ loading_kompetensi: false });
        });
    }
  }

  getBidangBaru() {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_bidang: !this.state.loading_bidang });
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=0&jenjang_id=5&kurikulum=${this.state.filterKurikulumSearch}`,
          {
            headers: { Authorization: tokenApi },
          }
        )
        .then((res) => {
          this.setState({ data_bidang: res.data });
          this.setState({ loading_bidang: false });
        })
        .catch((err) => {
          this.setState({ loading_bidang: false });
        });
    }
  }
  getProgramBaru() {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_program: !this.state.loading_program });
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=${this.state.bidang_search}&jenjang_id=5&kurikulum=${this.state.filterKurikulumSearch}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          this.setState({ data_program: res.data });
          this.setState({ loading_program: false });
        })
        .catch((err) => {
          this.setState({ loading_program: false });
        });
    }
  }

  getKompetensiBaru(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_kompetensi: !this.state.loading_kompetensi });
      axios
        .get(
          `${this.state.url_get_smk}?parent_id=${this.state.program_search}&jenjang_id=5&kurikulum=${this.state.filterKurikulumSearch}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          this.setState({ data_kompetensi: res.data });
          this.setState({ loading_kompetensi: false });
        })
        .catch((err) => {
          this.setState({ loading_kompetensi: false });
        });
    }
  }

  //e = kelas, b = peminatan
  getRombel(e, b) {
    if (e || b) {
      this.setState({
        loading_rombel: !this.state.loading_rombel,
        filterPeminatanBaru: b,
      });
      if (e !== 0) {
        this.setState({ filterKelas: e });
        this.setState({
          loading_peminatan_baru: !this.state.loading_peminatan_baru,
        });
      }

      if (
        this.state.jenjang_id_select == "5"
          ? (peminatan = this.state.kompetensi_search)
          : (peminatan = this.state.peminatan_search)
      );

      let filterKurikulum = this.state.filterKurikulumSearch
        ? this.state.filterKurikulumSearch
        : "k13";
      this.setState({ loading_rombel_baru: !this.state.loading_rombel_baru });

      // let filterPeminatanAgain = this.state.filterPeminatanBaru ? this.state.filterPeminatanBaru : b
      let filterKelasAgain = this.state.filterKelas
        ? this.state.filterKelas
        : this.state.kelas_search;

      axios
        .get(
          `${this.state.url_get_rombel}?tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${filterKelasAgain}
          &peminatan=${b}&user_institusi_id=${this.state.institusi_id_select}&kurikulum=${filterKurikulum}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          // console.log("ini data rombel", res.data)
          this.setState({
            data_rombel: res.data,
            loadingFilter: false,
            loading_rombel_baru: false,
            loading_rombel: false,
            loading_peminatan_baru: false,
          });
        });
    } else {
      this.setState({ loading_rombel: !this.state.loading_rombel });
      var peminatan = "";
      if (
        this.state.jenjang_id_select == "5"
          ? (peminatan = this.state.kompetensi_search)
          : (peminatan = this.state.peminatan_search)
      );

      let filterKurikulum = this.state.filterKurikulumSearch
        ? this.state.filterKurikulumSearch
        : "k13";
      axios
        .get(
          `${this.state.url_get_rombel}?tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}
          &peminatan=${peminatan}&user_institusi_id=${this.state.institusi_id_select}&kurikulum=${filterKurikulum}`,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          this.setState({
            data_rombel: res.data,
            loadingFilter: false,
            loading_rombel: false,
          });
        });
    }
  }

  showSelectFilterBaru() {
    if (this.state.jenjang_id_select == "4") {
      return (
        <div className="flex w-1/5">
          <div className="py-2 mr-2">
            <Select
              id="filterPeminatan"
              styles={selectStyles}
              // isClearable={true}
              isLoading={this.state.loading_peminatan ? true : false}
              isDisabled={this.state.disabledFilter}
              defaultValue={
                this.state.peminatan_search != ""
                  ? {
                      value: this.state.peminatan_search,
                      label: this.state.peminatan_search_label,
                    }
                  : ""
              }
              options={this.state.data_peminatan}
              onChange={this.filterPeminatan}
              placeholder={"Pilih Peminatan"}
            />
          </div>
        </div>
      );
    } else if (this.state.jenjang_id_select == "5") {
      return (
        <>
          {this.state.kelas_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterBidang"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.bidang_search != ""
                      ? {
                          value: this.state.bidang_search,
                          label: this.state.bidang_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_bidang ? true : false}
                  options={this.state.data_bidang}
                  onChange={this.filterBidang}
                  className="animateFadeInLeft"
                  placeholder={
                    this.state.loading_bidang ? "Loading" : "Bidang Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      );
    }
  }

  getDataFilterSMA() {
    axios
      .get(this.state.url_get_filter + "/" + this.state.institusi_id_select, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        if (res.data.status == true) {
          this.setState(
            {
              tahun_ajaran_search:
                res.data.data.tahun_ajaran !== null
                  ? res.data.data.tahun_ajaran
                  : "",
              kelas_search:
                res.data.data.kelas !== null ? res.data.data.kelas : "",
              bidang_search:
                res.data.data.bidang_id !== null ? res.data.data.bidang_id : "",
              bidang_search_label:
                res.data.data.bidang !== null ? res.data.data.bidang : "",
              program_search:
                res.data.data.program_id !== null
                  ? res.data.data.program_id
                  : "",
              program_search_label:
                res.data.data.program !== null ? res.data.data.program : "",
              kompetensi_search:
                res.data.data.kompetensi_id !== null
                  ? res.data.data.kompetensi_id
                  : "",
              kompetensi_search_label:
                res.data.data.kompetensi !== null
                  ? res.data.data.kompetensi
                  : "",
              peminatan_search:
                res.data.data.kompetensi_id !== null
                  ? res.data.data.kompetensi_id
                  : "",
              peminatan_search_label:
                res.data.data.kompetensi !== null
                  ? res.data.data.kompetensi
                  : "",
              rombel_search:
                res.data.data.rombel_id !== null ? res.data.data.rombel_id : "",
              rombel_search_label:
                res.data.data.rombel !== null ? res.data.data.rombel : "",
              filterKurikulumSearch:
                res.data.data.kurikulum !== null ? res.data.data.kurikulum : "",
              loadingFilter: false,
            },
            function() {
              // this.getDataTable();
              this.getTahunAjaranNow();
              this.getKelas();
              this.getPeminatanSMA();
              this.getRombel();
              this.getBidang();
              this.getProgram();
              this.getKompetensi();
              this.urutanJenjang();
            }
          );
        } else {
          this.setState({ loadingFilter: false });
          this.getTahunAjaranNow();
        }
      })
      .catch((err) => {
        // this.getDataTable();
        this.setState({ loadingFilter: false });
        this.getTahunAjaranNow();
      });
  }

  // getDataTable(){
  //   var m_spektrum = '';
  //             if(this.state.jenjang_id_select == '5' ? m_spektrum = this.state.kompetensi_search : m_spektrum = this.state.peminatan_search);
  //             this.setState({
  //               query         : `&tahun_ajaran=${this.state.tahun_ajaran_search}`+
  //                       '&kelas='+this.state.kelas_search+
  //                       '&m_spektrum_id='+m_spektrum+
  //                       '&user_institusi_id='+this.state.institusi_id_select,
  //             }, function(){
  //               this.dt.fetchEntities(this.state.query);
  //             });
  // }

  // SHOW TYPE FILTER PER JENJANG
  showSelectFilterSD() {
    return (
      <>
        {this.state.data_tahun_ajaran != "" ? (
          <div className="flex w-1/5">
            <div className="py-2 mr-2">
              <Select
                id="filterTahunAjaran"
                styles={selectStyles}
                isDisabled={this.state.disabledFilter}
                defaultValue={
                  this.state.tahun_ajaran_search != null
                    ? {
                        value: this.state.tahun_ajaran_search,
                        label: this.state.tahun_ajaran_search,
                      }
                    : ""
                }
                options={this.state.data_tahun_ajaran}
                onChange={this.filterTahunAjaran}
                // className="animateFadeInLeft"
                placeholder={"Pilih Tahun Ajaran"}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="flex w-1/5">
          <div className="py-2 mr-2">
            <Select
              styles={selectStyles}
              className="react-select__control"
              placeholder="Pilih Kurikulum"
              isDisabled={this.state.disabledFilter}
              defaultValue={
                this.state.filterKurikulumSearch != ""
                  ? {
                      value: this.state.filterKurikulumSearch,
                      label: this.state.filterKurikulumSearch,
                    }
                  : ""
              }
              options={dataKurikulum}
              onChange={(e) => {
                if (e) {
                  this.filterKurikulume(e);
                } else {
                  this.filterKurikulume("");
                }
              }}
            />
          </div>
        </div>
        {this.state.data_kelas != "" ? (
          <div className="flex w-1/5">
            <div className="py-2 mr-2">
              <Select
                id="filterKelas"
                styles={selectStyles}
                isClearable={true}
                isDisabled={this.state.disabledFilter}
                defaultValue={
                  this.state.kelas_search != ""
                    ? {
                        value: this.state.kelas_search,
                        label: this.state.kelas_search,
                      }
                    : ""
                }
                options={this.state.data_kelas}
                onChange={this.filterKelas}
                placeholder={"Pilih Kelas"}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  showSelectFilterSMA() {
    if (this.state.jenjang_id_select == "4" && this.state.data_peminatan) {
      return (
        <div className="flex w-1/5">
          <div className="py-2 mr-2">
            <Select
              id="filterPeminatan"
              styles={selectStyles}
              // isClearable={true}
              isLoading={this.state.loading_peminatan ? true : false}
              isDisabled={this.state.disabledFilter}
              defaultValue={
                this.state.peminatan_search != ""
                  ? {
                      value: this.state.peminatan_search,
                      label: this.state.peminatan_search_label,
                    }
                  : ""
              }
              options={this.state.data_peminatan}
              onChange={this.filterPeminatan}
              placeholder={"Pilih Peminatan"}
            />
          </div>
        </div>
      );
    }
  }

  showSelectFilterSMK() {
    if (
      this.state.jenjang_id_select == "5" &&
      this.state.filterKurikulumSearch === "k13"
    ) {
      return (
        <>
          {this.state.kelas_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterBidang"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.bidang_search != ""
                      ? {
                          value: this.state.bidang_search,
                          label: this.state.bidang_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_bidang ? true : false}
                  options={this.state.data_bidang}
                  onChange={this.filterBidang}
                  className="animateFadeInLeft"
                  placeholder={
                    this.state.loading_bidang ? "Loading" : "Bidang Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {this.state.bidang_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterProgram"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.program_search != ""
                      ? {
                          value: this.state.program_search,
                          label: this.state.program_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_program ? true : false}
                  options={this.state.data_program}
                  onChange={this.filterProgram}
                  placeholder={
                    this.state.loading_program ? "Loading" : "Program Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {this.state.program_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterKompetensi"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.kompetensi_search != ""
                      ? {
                          value: this.state.kompetensi_search,
                          label: this.state.kompetensi_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_kompetensi ? true : false}
                  options={this.state.data_kompetensi}
                  onChange={this.filterKompetensi}
                  placeholder={
                    this.state.loading_kompetensi
                      ? "Loading"
                      : "Kompetensi Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      );
    }
    // For select kurikulum merdeka
    if (
      this.state.jenjang_id_select == "5" &&
      this.state.filterKurikulumSearch === "merdeka"
    ) {
      return (
        <>
          {this.state.kelas_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterBidang"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.bidang_search != ""
                      ? {
                          value: this.state.bidang_search,
                          label: this.state.bidang_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_bidang ? true : false}
                  options={this.state.data_bidang}
                  onChange={this.filterBidang}
                  className="animateFadeInLeft"
                  placeholder={
                    this.state.loading_bidang ? "Loading" : "Bidang Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {this.state.bidang_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterProgram"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.program_search != ""
                      ? {
                          value: this.state.program_search,
                          label: this.state.program_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_program ? true : false}
                  options={this.state.data_program}
                  onChange={this.filterProgram}
                  placeholder={
                    this.state.loading_program ? "Loading" : "Program Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      );
    }
  }

  showSelectFilterALL() {
    var kondisiJenjang = "";
    if (
      this.state.jenjang_id_select == 2 && this.state.kelas_search != ""
        ? (kondisiJenjang = true)
        : ""
    );
    if (
      this.state.jenjang_id_select == 3 && this.state.kelas_search != ""
        ? (kondisiJenjang = true)
        : ""
    );
    if (
      this.state.jenjang_id_select == 4 && this.state.peminatan_search != ""
        ? (kondisiJenjang = true)
        : ""
    );
    if (
      this.state.jenjang_id_select == 5 &&
      this.state.kompetensi_search != "" &&
      this.state.filterKurikulumSearch === "k13"
        ? (kondisiJenjang = true)
        : ""
    );
    if (
      this.state.jenjang_id_select == 5 &&
      this.state.program_search != "" &&
      this.state.filterKurikulumSearch === "merdeka"
        ? (kondisiJenjang = true)
        : ""
    );
    if (this.state.data_rombel) {
      return (
        <div className="flex w-1/5">
          <div className="py-2 mr-2">
            <Select
              id="filterRombel"
              styles={selectStyles}
              isClearable={true}
              isLoading={this.state.loading_rombel ? true : false}
              isDisabled={this.state.disabledFilter}
              defaultValue={
                this.state.rombel_search != ""
                  ? {
                      value: this.state.rombel_search,
                      label: this.state.rombel_search_label,
                    }
                  : ""
              }
              options={this.state.data_rombel}
              onChange={this.filterRombel}
              placeholder={
                this.state.data_rombel == "" ? "Rombel Kosong" : "Pilih Rombel"
              }
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  urutanJenjang() {
    if (
      this.state.tahun_ajaran_search != "" &&
      this.state.kelas_search != "" &&
      this.state.rombel_search != ""
    ) {
      if (
        this.state.jenjang_id_select == "3" ||
        this.state.jenjang_id_select == "2"
          ? this.setState({ urutanFilter: true })
          : ""
      );
      if (
        this.state.jenjang_id_select == "4" && this.state.peminatan_search != ""
          ? this.setState({ urutanFilter: true })
          : ""
      );
      if (
        this.state.bidang_search != "" &&
        this.state.program_search != "" &&
        this.state.kompetensi_search != ""
      ) {
        if (
          this.state.jenjang_id_select == "5"
            ? this.setState({ urutanFilter: true })
            : ""
        );
      }
    }
  }

  // FILTER HANDLE CHANGE FUNCTION
  filterTahunAjaran(e) {
    if (e) {
      this.setState(
        {
          tahun_ajaran_search: e.value,
          filterKurikulumSearch: "",
          data_kelas: "",
          data_peminatan: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          // kelas_search: "",
          peminatan_search: "",
          rombel_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          this.setState({ filterKurikulumSearch: "" });
        }
      );
    } else {
      this.setState({
        tahun_ajaran_search: "",
        filterKurikulumSearch: "",
        data_kelas: "",
        data_peminatan: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        kelas_search: "",
        peminatan_search: "",
        rombel_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
      });
    }
  }

  filterKurikulume(e) {
    if (e) {
      this.setState(
        {
          filterKurikulumSearch: e.value,
          data_kelas: "",
          data_peminatan: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          kelas_search: "",
          peminatan_search: "",
          rombel_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          this.getKelas();
        }
      );
    } else {
      this.setState({
        filterKurikulumSearch: null,
        data_kelas: "",
        data_peminatan: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        kelas_search: "",
        peminatan_search: "",
        rombel_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
      });
    }
  }

  filterKelas(e) {
    if (e) {
      this.setState(
        {
          kelas_search: e.value,
          data_peminatan: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          peminatan_search: "",
          rombel_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          if (this.state.jenjang_id_select == "5" ? this.getBidang() : "");
          if (
            this.state.jenjang_id_select == "4" ? this.getPeminatanSMA() : ""
          );
          if (
            this.state.jenjang_id_select == "2" ||
            this.state.jenjang_id_select == "3"
              ? this.getRombel()
              : ""
          );
        }
      );
    } else {
      this.setState(
        {
          kelas_search: "",
          data_peminatan: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          peminatan_search: "",
          rombel_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          if (
            this.state.jenjang_id_select == "3" ||
            this.state.jenjang_id_select == "2"
              ? this.getRombel()
              : ""
          );
          if (this.state.jenjang_id_select == "5" ? this.getBidang() : "");
        }
      );
    }
  }

  clearValue = () => {
    this.selectRef.select.clearValue();
  };

  filterKelasBaru(e) {
    if (e) {
      this.setState(
        {
          kelas_search: e.value,
          // data_peminatan: "",
          // data_bidang: "",
          // data_program: "",
          // data_kompetensi: "",
          // data_rombel: "",
          // peminatan_search_baru: "",
          // peminatan_search_label_baru: "",
          // rombel_search: "",
          // bidang_search: "",
          // program_search: "",
          // kompetensi_search: "",
        },
        function() {
          this.getRombel(e.value, 0);
        }
      );
    }
  }

  filterPeminatan(e) {
    //Only SMA
    if (e) {
      this.setState(
        {
          // filterPeminatanBaru: e.value,
          peminatan_search_baru: e.value,
          peminatan_search_label_baru: e.label,
          // data_bidang: "",
          // data_program: "",
          // data_kompetensi: "",
          // data_rombel: "",
          // rombel_search: "",
          // bidang_search: "",
          // program_search: "",
          // kompetensi_search: "",
        },
        function() {
          this.getRombel(0, e.value);
        }
      );
      this.getPeminatanSMA;
    } else {
      this.setState({
        peminatan_search: "",
        peminatan_search_label: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        bidang_search: "",
        rombel_search: "",
        program_search: "",
        kompetensi_search: "",
      });
    }
  }

  // only for SMK
  filterBidang(e) {
    if (e) {
      this.setState(
        {
          bidang_search: e.value,
          bidang_search_label: e.label,
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          rombel_search: "",
          program_search: "",
          kompetensi_search: "",
        },
        function() {
          this.getProgram();
        }
      );
    } else {
      this.setState({
        bidang_search: "",
        bidang_search_label: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        rombel_search: "",
        program_search: "",
        kompetensi_search: "",
      });
    }
  }
  filterProgram(e) {
    if (e) {
      this.setState(
        {
          program_search: e.value,
          program_search_label: e.label,
          data_kompetensi: "",
          data_rombel: "",
          rombel_search: "",
          kompetensi_search: "",
        },
        function() {
          if (this.state.filterKurikulumSearch === "k13") {
            this.getKompetensi();
          }
          if (this.state.filterKurikulumSearch === "merdeka") {
            this.getRombel();
          }
        }
      );
    } else {
      this.setState({
        program_search: "",
        program_search_label: "",
        data_kompetensi: "",
        data_rombel: "",
        rombel_search: "",
        kompetensi_search: "",
      });
    }
  }
  filterKompetensi(e) {
    if (e) {
      this.setState(
        {
          kompetensi_search: e.value,
          kompetensi_search_label: e.label,
          rombel_search: "",
        },
        function() {
          this.getRombel();
        }
      );
    } else {
      this.setState({
        kompetensi_search: "",
        kompetensi_search_label: "",
        rombel_search: "",
      });
    }
  }

  filterRombel(e) {
    if (e) {
      this.setState(
        {
          rombel_search: e.value,
          rombel_search_label: e.label,
        },
        function() {
          this.urutanJenjang();
        }
      );
    } else {
      this.setState(
        {
          rombel_search: "",
          rombel_search_label: "",
        },
        function() {
          this.urutanJenjang();
        }
      );
    }
  }

  setFilter(e) {
    e.preventDefault();
    // console.log(this.state.peminatan_search, this.state.rombel_search, this.state.rombel_search_label)
    this.setState({ disabledFilter: true });
    this.setState({ loadingSearch: true });
    var kompetensi = "";
    var kompetensi_label = "";
    var program = "";
    var program_label = "";

    if (
      this.state.jenjang_id_select == "5" &&
      this.state.filterKurikulumSearch === "k13"
    ) {
      kompetensi = this.state.kompetensi_search;
      kompetensi_label = this.state.kompetensi_search_label;
    } else if (
      this.state.jenjang_id_select == "5" &&
      this.state.filterKurikulumSearch === "merdeka"
    ) {
      program = this.state.program_search;
      program_label = this.state.program_search_label;
    } else {
      kompetensi = this.state.peminatan_search;
      kompetensi_label = this.state.peminatan_search_label;
    }
    axios
      .put(
        this.state.url_get_filter + "/" + this.state.institusi_id_select,
        {
          tahun_ajaran: this.state.tahun_ajaran_search,
          kelas: this.state.kelas_search,
          bidang: this.state.bidang_search_label,
          bidang_id: this.state.bidang_search,
          program: program_label,
          program_id: program,
          kompetensi: kompetensi_label,
          kompetensi_id: kompetensi,
          rombel_id: this.state.rombel_search,
          rombel: this.state.rombel_search_label,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({ disabledFilter: false });
        this.setState({ loadingSearch: false });
      });

    var m_spektrum = "";
    if (
      this.state.jenjang_id_select == "5" &&
      this.state.filterKurikulumSearch === "k13"
        ? (m_spektrum = this.state.kompetensi_search)
        : (m_spektrum = this.state.peminatan_search)
    );

    // for select kurikulum merdeka
    if (
      this.state.jenjang_id_select == "5" &&
      this.state.filterKurikulumSearch === "merdeka"
        ? (m_spektrum = this.state.program_search)
        : (m_spektrum = this.state.peminatan_search)
    );

    let peminatanCuk = m_spektrum ? m_spektrum : this.state.filterPeminatanBaru;

    if (this.state.rombel_search != "") {
      let filterKurikulum = this.state.filterKurikulumSearch
        ? this.state.filterKurikulumSearch
        : "k13";
      this.setState(
        {
          url_show_data: `${this.state.url}`,
          query:
            "&tahun_ajaran=" +
            this.state.tahun_ajaran_search +
            "&kelas=" +
            this.state.kelas_search +
            "&m_spektrum_id=" +
            peminatanCuk +
            "&user_institusi_id=" +
            this.state.institusi_id_select +
            "&paginate=" +
            this.state.mode_show,
          // + "&nama_rombel="
          // + this.state.rombel_search
          // +"&jenjang="+ this.state.jenjang_id_select,
          search: true,
        },
        function() {
          if (
            this.state.tahun_ajaran_search == null ||
            this.state.tahun_ajaran_search == ""
          ) {
            this.setState({
              show_alert: true,
              title_alert: "Gagal",
              icon_alert: "error",
              message_alert: "Tahun Ajaran wajib diisi",
            });
          } else {
            // console.log("reset");
            // console.log(this.state.query)
            this.dt.loading();
            this.dt.resetPage(this.state.query);
          }
        }
      );
    } else {
      this.setState({
        show_alert: true,
        title_alert: "Gagal",
        icon_alert: "error",
        message_alert: "Isi filter dengan Benar",
        // disabledFilter : false,
        // loadingSearch  : false
      });
    }
  }

  setResetFilter(e) {
    e.preventDefault();
    this.setState(
      {
        tahun_ajaran_search: "",
        filterKurikulumSearch: "",
        data_tahun_ajaran: "",
        data_kelas: "",
        data_peminatan: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        kelas_search: "",
        peminatan_search: "",
        rombel_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        disabledFilter: false,
      },
      function() {
        this.getTahunAjaranNow();
        this.setState({
          url_show_data: "",
          query: "",
        });
      }
    );
    this.setState({ filterKurikulumSearch: "" });
  }

  // SEARCH TEXT
  searchText(e) {
    e.preventDefault();
    if (this.state.url_show_data !== "") {
      var m_spektrum = "";
      if (
        this.state.jenjang_id_select == "5" &&
        this.state.filterKurikulumSearch === "k13"
          ? (m_spektrum = this.state.kompetensi_search)
          : (m_spektrum = this.state.peminatan_search)
      );

      // For select kurikulum merdeka
      if (
        this.state.jenjang_id_select == "5" &&
        this.state.filterKurikulumSearch === "merdeka"
          ? (m_spektrum = this.state.program_search)
          : (m_spektrum = this.state.peminatan_search)
      );

      this.setState(
        {
          query:
            "&tahun_ajaran=" +
            this.state.tahun_ajaran_search +
            "&kelas=" +
            this.state.kelas_search +
            "&m_spektrum_id=" +
            m_spektrum +
            "&rombel_id=" +
            this.state.rombel_search +
            "&user_institusi_id=" +
            this.state.institusi_id_select +
            "&paginate=" +
            this.state.mode_show,
          searchText: e.target.value,
        },
        function() {
          this.dt.resetPage(this.state.query);
        }
      );
    }
  }

  showData() {
    if (this.state.query != "") {
      return (
        <div className="px-1 py-1">
          <DataTable
            ref={(dt) => {
              this.dt = dt;
            }}
            action={{
              npsn: (col, data) => {
                if (data.npsn_mutasi != null) {
                  return data.npsn_mutasi;
                } else {
                  return data.npsn;
                }
              },
              aksi: (col, data) => {
                return (
                  <input
                    type="checkbox"
                    name="check_data[]"
                    id={data.id}
                    value={data.id}
                    onChange={this.selectOne}
                    className="select-checkbox"
                    checked={
                      this.state.check_item
                        ? true
                        : false || this.state.check_id == data.id
                        ? false
                        : null || this.state.check_all
                        ? true
                        : null
                    }
                  />
                );
              },
            }}
            url={this.state.url}
            columns={[
              {
                index: "kode_pendaftaran",
                label: "Kode Pendaftaran",
              },
              {
                index: "tahun_masuk",
                label: "TH MASUK",
              },
              {
                index: "tahun_angkatan",
                label: "TH SEKARANG",
              },
              {
                index: "npsn",
                label: "NPSN",
              },
              {
                index: "kelas",
                label: "KELAS",
              },
              {
                index: "nama_spektrum",
                label: "PEMINATAN",
              },
              {
                index: "nama_rombel",
                label: "ROMBEL",
              },
              {
                index: "nama",
                label: "NAMA LENGKAP",
              },
              {
                index: "aksi",
                label: "AKSI",
              },
            ]}
          />
        </div>
      );
    } else {
      return (
        <div className="w-full text-center">
          <p className="text-sm">Select Filter terlebih dahulu</p>
        </div>
      );
    }
  }

  naikKelas(e) {
    this.setState({ loading_naik: true });
    e.preventDefault();
    var checkboxes = document.getElementsByName("check_data[]");
    var vals = [];
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    if (vals.length == 0) {
      this.setState({
        show_alert: true,
        title_alert: "Gagal",
        icon_alert: "warning",
        message_alert: "Mohon centang data terlebih dahulu",
        disabledFilter: false,
        loading_naik: false,
      });
    } else {
      axios
        .post(
          this.state.url_naik_kelas,
          {
            siswa_id: vals,
            tahun_ajaran: this.state.tahun_ajaran_search,
            rombel_id: this.state.rombel_search,
            created_by: this.state.institusi_id_select,
          },
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          // let pesan = res.data.messages.key;
          this.setState({ loading_naik: false });
          if (res.data.status == false) {
            this.setState({
              show_alert: true,
              title_alert: "Gagal",
              icon_alert: "error",
              message_alert: res.data.messages[0],
              loading_naik: false,
            });
            this.dt.fetchEntities(this.state.query);
          } else {
            this.setState({
              show_alert: true,
              title_alert: "Berhasil",
              icon_alert: "success",
              message_alert: res.data.messages,
              loading_naik: false,
            });
            this.dt.fetchEntities(this.state.query);
          }
        })
        .catch((err) => {
          this.setState({ loading_naik: false });
          this.alertMessage(err.response);
        });
    }
  }

  tambahAlumni(e) {
    e.preventDefault();
    this.setState({ loading_alumni: true });
    var checkboxes = document.getElementsByName("check_data[]");
    var vals = [];
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    if (vals.length == 0) {
      this.setState({
        show_alert: true,
        title_alert: "Gagal",
        icon_alert: "warning",
        message_alert: "Mohon centang data terlebih dahulu",
        disabledFilter: false,
        loading_alumni: false,
      });
    } else {
      axios
        .post(
          this.state.url_tambah_alumni,
          {
            siswa_id: vals,
            updated_by: this.state.institusi_id_select,
          },
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          // let pesan = res.data.messages.key;
          this.setState({ loading_alumni: false });
          if (res.data.status == false) {
            this.setState({
              show_alert: true,
              title_alert: "Gagal",
              icon_alert: "error",
              message_alert: res.data.messages[0],
              loading_alumni: false,
            });
            this.dt.fetchEntities(this.state.query);
          } else {
            this.setState({
              show_alert: true,
              title_alert: "Berhasil",
              icon_alert: "success",
              message_alert: res.data.messages,
              loading_alumni: false,
            });
            this.dt.fetchEntities(this.state.query);
          }
        })
        .catch((err) => {
          this.setState({ loading_alumni: false });
          this.alertMessage(err.response);
        });
    }
  }

  selectAll(e) {
    let itemName = e.target.name;
    let itemVal = e.target.value;
    let itemId = e.target.id;
    let checkall = e.target.checked;
    if (itemName === "check_semua") {
      document.getElementsByName("check_semua").checked = true;
      this.setState({ check_all: checkall });
    }
  }
  selectOne(e) {
    let itemName = e.target.name;
    let itemVal = e.target.value;
    let itemId = e.target.id;
    let itemCheck = e.target.checked;
    if (this.state.check_all == true) {
      {
        itemId == itemVal
          ? this.setState({ check_id: itemId })
          : null ||
            document.getElementsByClassName(".select-checkbox").checked != true
          ? this.setState({ check_item: true })
          : this.setState({ check_item: false });
      }
      // { document.getElementsByClassName('.select-checkbox').checked == false ? this.setState({check_item: true}) : this.setState({check_item: false}) }
      // if(itemId == itemVal){
      //   this.setState({check_id: itemId})
      // }
    }
  }

  checkAll(e) {
    e.preventDefault();
    var check = document.getElementsByName("check_data[]");
    this.setState({ checkAll: true });

    if (check.length != 0) {
      for (var i = 0; i < check.length; i++) {
        check[i].checked = true;
      }
    }
  }

  unCheckAll(e) {
    e.preventDefault();
    var check = document.getElementsByName("check_data[]");
    this.setState({ checkAll: false });

    if (check.length != 0) {
      for (var i = 0; i < check.length; i++) {
        check[i].checked = false;
      }
    }
  }

  filterBidangBaru(e) {
    if (e) {
      this.setState(
        {
          bidang_search: e.value,
          bidang_search_label: e.label,
          // data_program: "",
          // data_kompetensi: "",
          // data_rombel: "",
          // rombel_search: "",
          // program_search: "",
          // kompetensi_search: "",
        },
        function() {
          this.getProgramBaru();
        }
      );
    } else {
      this.setState({
        bidang_search: "",
        bidang_search_label: "",
        // data_program: "",
        // data_kompetensi: "",
        // data_rombel: "",
        // rombel_search: "",
        // program_search: "",
        // kompetensi_search: "",
      });
    }
  }
  filterProgramBaru(e) {
    if (e) {
      this.setState(
        {
          program_search: e.value,
          program_search_label: e.label,
          // data_kompetensi: "",
          // data_rombel: "",
          // rombel_search: "",
          // kompetensi_search: "",
        },
        function() {
          this.getKompetensiBaru();
        }
      );
    } else {
      this.setState({
        program_search: "",
        program_search_label: "",
        // data_kompetensi: "",
        // data_rombel: "",
        // rombel_search: "",
        // kompetensi_search: "",
      });
    }
  }
  filterKompetensiBaru(e) {
    if (e) {
      this.setState(
        {
          kompetensi_search: e.value,
          kompetensi_search_label: e.label,
          // rombel_search: "",
        },
        function() {
          this.getRombel(0, e.value);
        }
      );
    } else {
      this.setState({
        kompetensi_search: "",
        kompetensi_search_label: "",
        // rombel_search: "",
      });
    }
  }

  filterPeminatanShow() {
    if (this.state.jenjang_id_select == 4) {
      return (
        <div className="flex w-1/5">
          <div className="py-2 mr-2">
            <Select
              styles={selectStyles}
              // isClearable={true}
              isLoading={this.state.loading_peminatan ? true : false}
              defaultValue={
                this.state.peminatan_search_baru != ""
                  ? {
                      value: this.state.peminatan_search_baru,
                      label: this.state.peminatan_search_label_baru,
                    }
                  : ""
              }
              onChange={(e) => {
                if (!isEmpty(e)) {
                  // this.setState({
                  //   filterPeminatan: e.value,
                  // });
                  this.selectReff.select.clearValue();
                  this.filterPeminatan(e);
                }
              }}
              ref={(ref) => {
                this.selectRef = ref;
              }}
              options={this.state.data_peminatan}
              placeholder={
                this.state.data_peminatan == ""
                  ? "Peminatan Kosong"
                  : "Pilih Peminatan"
              }
            />
          </div>
        </div>
      );
    } else if (
      this.state.jenjang_id_select == "5" &&
      this.state.filterKurikulumSearch === "k13"
    ) {
      return (
        <>
          {this.state.kelas_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterBidang"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.bidang_search_baru != ""
                      ? {
                          value: this.state.bidang_search_baru,
                          label: this.state.bidang_search_label_baru,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_bidang ? true : false}
                  options={this.state.data_bidang}
                  onChange={this.filterBidangBaru}
                  // className="animateFadeInLeft"
                  placeholder={
                    this.state.loading_bidang ? "Loading" : "Bidang Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {this.state.bidang_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterProgram"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.program_search_baru != ""
                      ? {
                          value: this.state.program_search_baru,
                          label: this.state.program_search_label_baru,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_program ? true : false}
                  options={this.state.data_program}
                  onChange={this.filterProgramBaru}
                  placeholder={
                    this.state.loading_program ? "Loading" : "Program Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {this.state.program_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterKompetensi"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.kompetensi_search_baru != ""
                      ? {
                          value: this.state.kompetensi_search_baru,
                          label: this.state.kompetensi_search_label_baru,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_kompetensi ? true : false}
                  options={this.state.data_kompetensi}
                  onChange={this.filterKompetensiBaru}
                  placeholder={
                    this.state.loading_kompetensi
                      ? "Loading"
                      : "Kompetensi Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      );
    } else if (
      this.state.jenjang_id_select == "5" &&
      this.state.filterKurikulumSearch === "merdeka"
    ) {
      return (
        <>
          {this.state.kelas_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterBidang"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.bidang_search_baru != ""
                      ? {
                          value: this.state.bidang_search_baru,
                          label: this.state.bidang_search_label_baru,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_bidang ? true : false}
                  options={this.state.data_bidang}
                  onChange={this.filterBidangBaru}
                  // className="animateFadeInLeft"
                  placeholder={
                    this.state.loading_bidang ? "Loading" : "Bidang Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {this.state.bidang_search != "" ? (
            <>
              <div className="py-2 ml-2">
                <Select
                  id="filterProgram"
                  styles={selectStyles}
                  isClearable={true}
                  defaultValue={
                    this.state.program_search_baru != ""
                      ? {
                          value: this.state.program_search_baru,
                          label: this.state.program_search_label_baru,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_program ? true : false}
                  options={this.state.data_program}
                  onChange={this.filterProgramBaru}
                  placeholder={
                    this.state.loading_program ? "Loading" : "Program Keahlian"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      );
    }
  }

  formFilter() {
    return (
      <>
        {this.state.jenjang_id_select == "5" ? (
          <>
            <div class="flex flex-wrap">
              <div className="flex w-1/5">
                <div className="py-2 mr-2">
                  <Select
                    styles={selectStyles}
                    isDisabled={this.state.disabledFilter}
                    defaultValue={
                      this.state.tahun_ajaran_search != null
                        ? {
                            value: this.state.tahun_ajaran_search,
                            label: this.state.tahun_ajaran_search,
                          }
                        : ""
                    }
                    onChange={(e) => {
                      if (!isEmpty(e)) {
                        this.setState({
                          filterTahunAjaran: e.value,
                        });
                      }
                    }}
                    options={this.state.data_tahun_ajaran}
                    placeholder={"Pilih Tahun Ajaran"}
                  />
                </div>
              </div>

              <div className="flex w-1/5">
                <div className="py-2 mr-2">
                  <Select
                    styles={selectStyles}
                    isDisabled={this.state.disabledFilter}
                    onChange={(e) => {
                      if (!isEmpty(e)) {
                        this.setState({
                          filterKurikulumBaru: e.value,
                        });
                      }
                    }}
                    options={dataKurikulum}
                    placeholder={"Pilih Kurikulum"}
                  />
                </div>
              </div>

              <div className="flex w-1/5">
                <div className="py-2 mr-2">
                  <Select
                    styles={selectStyles}
                    options={this.state.data_kelas}
                    placeholder={"Pilih Kelas"}
                    onChange={(e) => {
                      if (!isEmpty(e)) {
                        if (this.state.jenjang_id_select == 4) {
                          this.selectRef.select.clearValue();
                        }
                        this.filterKelasBaru(e);
                      }
                    }}
                  />
                </div>
              </div>

              {this.filterPeminatanShow()}

              <div className="flex w-1/5">
                <div className="py-2 mr-2">
                  <Select
                    styles={selectStyles}
                    isClearable={true}
                    isLoading={this.state.loading_rombel_baru ? true : false}
                    defaultValue={
                      this.state.rombel_search != ""
                        ? {
                            value: this.state.rombel_search,
                            label: this.state.rombel_search_label,
                          }
                        : ""
                    }
                    options={this.state.data_rombel}
                    onChange={(e) => {
                      if (!isEmpty(e)) {
                        this.setState({
                          filterRombel: e.value,
                        });
                      }
                    }}
                    ref={(ref) => {
                      this.selectReff = ref;
                    }}
                    placeholder={
                      this.state.data_rombel == ""
                        ? "Rombel Kosong"
                        : "Pilih Rombel"
                    }
                  />
                </div>
              </div>

              <button
                className="btn-blue px-4 py-2 mr-1 flex items-center rounded-full text-white text-xs"
                onClick={() => this.clickFilter()}
              >
                <i className="bx bx-filter text-lg mr-1"></i>
                Filter
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex w-1/5">
              <div className="py-2 mr-2">
                <Select
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.tahun_ajaran_search != null
                      ? {
                          value: this.state.tahun_ajaran_search,
                          label: this.state.tahun_ajaran_search,
                        }
                      : ""
                  }
                  onChange={(e) => {
                    if (!isEmpty(e)) {
                      this.setState({
                        filterTahunAjaran: e.value,
                      });
                    }
                  }}
                  options={this.state.data_tahun_ajaran}
                  placeholder={"Pilih Tahun Ajaran"}
                />
              </div>
            </div>

            <div className="flex w-1/5">
              <div className="py-2 mr-2">
                <Select
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  onChange={(e) => {
                    if (!isEmpty(e)) {
                      this.setState({
                        filterKurikulumBaru: e.value,
                      });
                    }
                  }}
                  options={dataKurikulum}
                  placeholder={"Pilih Kurikulum"}
                />
              </div>
            </div>

            <div className="flex w-1/5">
              <div className="py-2 mr-2">
                <Select
                  styles={selectStyles}
                  // isClearable={true}
                  options={this.state.data_kelas}
                  // defaultValue={
                  //   this.state.kelas_search != ""
                  //     ? {
                  //         value: this.state.kelas_search,
                  //         label: this.state.kelas_search,
                  //       }
                  //     : ""
                  // }
                  placeholder={"Pilih Kelas"}
                  onChange={(e) => {
                    if (!isEmpty(e)) {
                      // this.setState({
                      //   filterKelas: e.value,
                      // });
                      if (this.state.jenjang_id_select == 4) {
                        this.selectRef.select.clearValue();
                      }
                      this.filterKelasBaru(e);
                    }
                  }}
                />
              </div>
            </div>

            {this.filterPeminatanShow()}

            <div className="flex w-1/5">
              <div className="py-2 mr-2">
                <Select
                  styles={selectStyles}
                  isClearable={true}
                  isLoading={this.state.loading_rombel_baru ? true : false}
                  defaultValue={
                    this.state.rombel_search != ""
                      ? {
                          value: this.state.rombel_search,
                          label: this.state.rombel_search_label,
                        }
                      : ""
                  }
                  options={this.state.data_rombel}
                  onChange={(e) => {
                    if (!isEmpty(e)) {
                      this.setState({
                        filterRombel: e.value,
                      });
                    }
                  }}
                  ref={(ref) => {
                    this.selectReff = ref;
                  }}
                  placeholder={
                    this.state.data_rombel == ""
                      ? "Rombel Kosong"
                      : "Pilih Rombel"
                  }
                />
              </div>
            </div>
            <button
              className="btn-blue px-4 py-2 mr-1 flex items-center rounded-full text-white text-xs"
              onClick={() => this.clickFilter()}
            >
              <i className="bx bx-filter text-lg mr-1"></i>
              Filter
            </button>
          </>
        )}
      </>
    );
  }

  clickFilter() {
    // console.log(this.state.filterKurikulumBaru)
    this.setState({ disabledFilter: true });
    this.setState({ loadingSearch: true });
    var kompetensi = "";
    var kompetensi_label = "";

    var m_spektrum = "";
    if (
      this.state.jenjang_id_select == "5" &&
      this.state.filterKurikulumSearch === "k13"
        ? (m_spektrum = this.state.kompetensi_search)
        : (m_spektrum = this.state.peminatan_search)
    );

    // For select kurikulum merdeka
    if (
      this.state.jenjang_id_select == "5" &&
      this.state.filterKurikulumSearch === "merdeka"
        ? (m_spektrum = this.state.program_search)
        : (m_spektrum = this.state.peminatan_search)
    );

    if (this.state.rombel_search !== "") {
      let filterKurikulum = this.state.filterKurikulumBaru
        ? this.state.filterKurikulumBaru
        : "k13";
      this.setState(
        {
          url_show_data: `${this.state.url}`,
          query:
            "&tahun_ajaran=" +
            this.state.filterTahunAjaran +
            "&kelas=" +
            this.state.filterKelas +
            "&m_spektrum_id=" +
            this.state.filterPeminatanBaru +
            "&user_institusi_id=" +
            this.state.institusi_id_select +
            "&paginate=" +
            this.state.mode_show +
            "&filter=true" +
            "&filter_rombel=" +
            this.state.filterRombel +
            "&kurikulum=" +
            filterKurikulum,
          search: true,
        },
        function() {
          if (
            this.state.filterTahunAjaran == null ||
            this.state.filterTahunAjaran == ""
          ) {
            this.setState({ disabledFilter: false });
            this.setState({ loadingSearch: false });
            this.setState({
              search: false,
              show_alert: true,
              title_alert: "Gagal",
              icon_alert: "error",
              message_alert: "Tahun Ajaran wajib diisi",
            });
          } else {
            this.dt.loading();
            this.dt.resetPage(this.state.query);
          }
        }
      );
    }
  }

  render() {
    return (
      <div>
        <div className=" shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white sm-container border">
          <div className="flex justify-between items-center mt-2  px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Kenaikan Siswa
              <span className="text-gray-500 font-thin block text-sm">
                Daftar Bursa Siswa.{" "}
                {/* <a href="/sadasd" className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
            <div className="flex">
              <Link to="alumni">
                <button className="btn-yellow px-4 flex items-center rounded-full text-white py-2 text-sm">
                  <i className="bx bx-list-check text-lg mr-1"></i>
                  Lihat Alumni
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/* alert */}
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />

        <div className="sm-container shadow-md mx-auto py-3 mt-2 rounded-lg bg-white border">
          {this.state.loadingFilter ? (
            <div className="flex flex-wrap items-center px-3 pb-2 pt-2">
              <div className="w-1/4 px-1">
                <div className="py-2">
                  <Skeleton count={2} />
                </div>
              </div>
              <div className="w-1/4 px-1">
                <div className="py-2">
                  <Skeleton count={2} />
                </div>
              </div>
              <div className="w-1/4 px-1">
                <div className="py-2">
                  <Skeleton count={2} />
                </div>
              </div>
              <div className="w-1/4 px-1">
                <div className="py-2">
                  <Skeleton count={2} />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-row items-baseline px-3 ml-2">
                <p>Pilih Rombel :</p>
              </div>
              <div className="flex flex-wrap items-center px-3 pb-2 pt-2">
                {this.showSelectFilterSD()}
                {this.showSelectFilterSMA()}
                {this.showSelectFilterSMK()}
                {this.showSelectFilterALL()}
                {this.state.rombel_search != "" ? (
                  <div className="flex flex-row items-center">
                    <button
                      className={`${
                        this.state.loadingSearch
                          ? `btn-disabled`
                          : `btn-schoolmedia`
                      } px-4 py-2 ml-1 flex items-center rounded-full text-white text-sm mr-3`}
                      onClick={this.setFilter}
                      disabled={this.state.loadingSearch ? true : false}
                    >
                      {this.state.loadingSearch ? (
                        <>
                          <span className="text-gray-800">Tunggu...</span>
                        </>
                      ) : (
                        <>
                          <i className="bx bx-search text-lg mr-1"></i>
                          Pilih Rombel
                        </>
                      )}
                    </button>
                    <button
                      className="flex btn-default text-sm text-gray-800 px-4 py-2 rounded-full items-center"
                      onClick={this.setResetFilter}
                    >
                      Reset
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {this.state.query != "" ? (
                <>
                  <hr></hr>
                  <div className="w-full flex justify-between items-center px-4 py-2">
                    <div className="relative w-2/3 flex flex-row">
                      <input
                        type="search"
                        className="w-1/2 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                        placeholder="Cari kode pendaftaran, nama"
                        id="search"
                        onChange={(event) => {
                          var m_spektrum = "";
                          if (
                            this.state.jenjang_id_select == "5"
                              ? (m_spektrum = this.state.kompetensi_search)
                              : (m_spektrum = this.state.peminatan_search)
                          );

                          this.setState(
                            {
                              query: `&query=${event.target.value}
                                        &user_institusi_id=${this.state.institusi_id_select}
                                        &rombel_id=${this.state.rombel_search}
                                        &m_spektrum_id=${m_spektrum}
                                        &kelas=${this.state.kelas_search}
                                        &tahun_ajaran=${this.state.tahun_ajaran_search}
                                        &paginate=${this.state.mode_show}`,
                              searchText: event.target.value,
                            },
                            function() {
                              this.dt.loading();
                              this.dt.resetPage(this.state.query);
                            }
                          );
                        }}
                      />
                      <div className="absolute top-1 left-0 inline-flex items-center py-2 px-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5 text-gray-400"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                            stroke="none"
                          ></rect>
                          <circle cx="10" cy="10" r="7"></circle>
                          <line x1="21" y1="21" x2="15" y2="15"></line>
                        </svg>
                      </div>
                    </div>
                    <div className="w-1/4 text-right ml-auto">
                      <span className="text-sm text-gray-800 px-1 mb-2">
                        Tampilkan
                      </span>
                      <div className="w-1/2 ml-auto flex flex-wrap text-right items-center justify-end">
                        <div className="mr-0 flex text-right ">
                          <button
                            onClick={this.showMode.bind(this, 10)}
                            className={`${
                              this.state.mode_show == 10
                                ? `btn-schoolmedia`
                                : `bg-gray-400`
                            } px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}
                          >
                            10
                          </button>
                          <button
                            onClick={this.showMode.bind(this, 50)}
                            className={`${
                              this.state.mode_show == 50
                                ? `btn-schoolmedia`
                                : `bg-gray-400`
                            } px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}
                          >
                            50
                          </button>
                          <button
                            onClick={this.showMode.bind(this, 100)}
                            className={`${
                              this.state.mode_show == 100
                                ? `btn-schoolmedia`
                                : `bg-gray-400`
                            } px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}
                          >
                            100
                          </button>
                          <button
                            onClick={this.showMode.bind(this, 200)}
                            className={`${
                              this.state.mode_show == 200
                                ? `btn-schoolmedia`
                                : `bg-gray-400`
                            } px-4 flex items-center rounded-full text-white py-2 text-xs mr-1`}
                          >
                            200
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          )}
          <div className="ml-4">
            <button
              className="btn-blue px-4 py-2 mr-1 flex items-center rounded-full text-white text-xs"
              onClick={() => {
                this.setState({
                  showFilter: !this.state.showFilter,
                });
              }}
            >
              <i className="bx bx-filter text-lg mr-1"></i>
              FILTER
            </button>

            <div
              style={
                this.state.showFilter == false ? { display: "none" } : null
              }
            >
              <div className="flex items-center pb-2 pt-2">
                {this.formFilter()}
              </div>
            </div>
          </div>
        </div>

        <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
          {this.showData()}
        </div>
        {this.state.url_show_data != "" ? (
          <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
            <div className="w-full text-right flex justify-end px-4">
              {this.state.urutanFilter == true ? (
                <div className="flex flex-wrap items-baseline px-3 justify-end">
                  <p>Pilih siswa dan klik tombol</p>
                  {this.state.checkAll == false ? (
                    <button
                      className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                      onClick={this.checkAll}
                    >
                      Pilih Semua
                    </button>
                  ) : (
                    <button
                      className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                      onClick={this.unCheckAll}
                    >
                      Tidak Pilih Semua
                    </button>
                  )}
                  {this.state.loading_naik ? (
                    <button
                      className="btn-schoolmedia px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                      disabled={this.state.loading_naik == true}
                    >
                      <UserButtonLoading />
                    </button>
                  ) : (
                    <button
                      onClick={this.naikKelas}
                      className="btn-schoolmedia px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                      disabled={this.state.kelas_search == true ? true : false}
                    >
                      {this.state.kelas_search == "X" ||
                      this.state.kelas_search == "I" ||
                      this.state.kelas_search == "VII"
                        ? "Masuk Kelas"
                        : "Naik Kelas"}
                    </button>
                  )}
                  {/* <button
                    onClick={this.tambahAlumni}
                    className="btn-green px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                    disabled={this.state.loading_alumni == true ? true : false}
                  >
                    {this.state.loading_alumni ? (
                      <UserButtonLoading />
                    ) : (
                      "Alumni"
                    )}
                  </button> */}
                  {/* <input type="checkbox" name="check_semua" onChange={this.selectAll}/> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
