import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import axios from "axios";
// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

export default class landingpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // url api
     
    };

   

  }

  componentDidMount(){
    localStorage.setItem('token', '');
    localStorage.setItem('is_login', ''); 
  }

 

  render() {
    // let modalPelajari=this.props.modal_pelajari
    return <React.Fragment></React.Fragment>;
    
  }
}
