import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Select from 'react-select';
import axios from "axios";
import conf from "./../../../config.js";
import tokenApi from "../../auth/Helpers";
import { Button, Modal, Carousel, Tab, Tabs } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import SweetAlert from 'sweetalert2-react';
import { UserButtonLoading } from "../../partials/loadings/ComponentLoading.js";

const semester = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
];

// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

const dataKurikulum = [
  { value: "k13", label: "K13" },
  { value: "merdeka", label: "Merdeka" },
];

function captFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default class JadwalSiswa extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      // modal
      url_get_jadwal      : `${conf.uri_backend}api/v1/jadwal/jadwal_guru`,
      url_export_data     : `${conf.uri_backend}api/v1/jadwal/jadwal_pelajaran_siswa`,
      url_get_kuota       : `${conf.uri_backend}api/v1/jadwal/get_kuota_per_waktu_pelajaran`,
      url_get_jdwl_rombel : `${conf.uri_backend}api/v1/jadwal/get_jadwal_pelajaran_rombel`,
      url_get_detail      : `${conf.uri_backend}api/v1/jadwal/jadwal_mapel`,
      url_get_spektrum    : `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_filter      : `${conf.uri_backend}api/v1/rombel/filter`,
      url_get_smk         : `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,
      url_get_rombel      : `${conf.uri_backend}api/v1/rombel/get_rombel`,
      tahunSekarang       : new Date().getFullYear(),
      modal_cetak         : false,
      loadingJadwal       : false,
      disabledFilter      : false,
      
      // filter print
      sms_jadwal_search   : '',
      ta_jadwal_search    : '',
      kurikulum_jadwal_search   : '',
      kls_jadwal_search   : '',
      
      // filter
      tahun_ajaran_search : '',
      kelas_search        : '',
      peminatan_search    : '',
      rombel_search       : '',
      bidang_search       : '',
      program_search      : '',
      semester_search     : '',
      kompetensi_search   : '',
      kurikulum_search   : '',

      // data filter
      data_spektrum       : '',
      data_bidang         : '',
      data_program        : '',
      data_semester       : '',
      data_kompetensi     : '',
      data_rombel         : '',
      data_jadwal         : '',
      jmlSenin            : '',
      jmlSelasa           : '',
      jmlRabu             : '',
      jmlKamis            : '',
      jmlJumat            : '',
      jmlSabtu            : '',
      jmlMinggu           : '',
      // alert
      show_alert          : false,
      title_alert         : '',
      message_alert       : '',
      icon_alert          : '',
      institusi_id_select : '',
      jenjang_id_select   : '',
      messageGetJadwal    : '',

    }
    this.setFilter                = this.setFilter.bind(this);
    this.getData                  = this.getData.bind(this);
    this.resetFilter              = this.resetFilter.bind(this);
    this.getKuota                 = this.getKuota.bind(this);
    this.showData                 = this.showData.bind(this);
    this.showModalCetak           = this.showModalCetak.bind(this);
    this.hideModalCetak           = this.hideModalCetak.bind(this);

    // get option filter
    this.getTahunAjaranNow        = this.getTahunAjaranNow.bind(this);
    this.getKelas                 = this.getKelas.bind(this);
    this.getRombel                = this.getRombel.bind(this);
    this.getPeminatanSMA          = this.getPeminatanSMA.bind(this);
    this.getBidang                = this.getBidang.bind(this);
    this.getSemester              = this.getSemester.bind(this);
    this.getProgram               = this.getProgram.bind(this);
    this.getKompetensi            = this.getKompetensi.bind(this);

    // filter main table
    this.filterTaJadwal           = this.filterTaJadwal.bind(this);
    this.filterSemesterJadwal     = this.filterSemesterJadwal.bind(this);
    this.filterKelasJadwal          = this.filterKelasJadwal.bind(this);
    this.filterKurikulumJadwal    = this.filterKurikulumJadwal.bind(this);

    // filter and search
    this.filterTahunAjaran        = this.filterTahunAjaran.bind(this);
    this.filterKelas              = this.filterKelas.bind(this);
    this.filterRombel             = this.filterRombel.bind(this);
    this.filterKurikulum          = this.filterKurikulum.bind(this);
    this.filterPeminatan          = this.filterPeminatan.bind(this);
    this.filterBidang             = this.filterBidang.bind(this);
    this.filterProgram            = this.filterProgram.bind(this);
    this.filterKompetensi         = this.filterKompetensi.bind(this);
    this.filterSemester           = this.filterSemester.bind(this);
    this.printJadwal              = this.printJadwal.bind(this);
    // show select filter
    this.showSelectFilterSD       = this.showSelectFilterSD.bind(this);
    this.showFilterKurikulum      = this.showFilterKurikulum.bind(this);
    this.showSelectFilterSMA      = this.showSelectFilterSMA.bind(this);
    this.showSelectFilterSMK      = this.showSelectFilterSMK.bind(this);
    this.showSelectFilterALL      = this.showSelectFilterALL.bind(this);
    this.showSelectFilterSemester = this.showSelectFilterSemester.bind(this);
    this.showFilterJadwal         = this.showFilterJadwal.bind(this);
    this.export                   = this.export.bind(this);
  }

  componentDidMount() {
    // get session user
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ data_session: res.data }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({ 
              institusi_id_select : res.data.id,
              jenjang_id_select   : res.data.m_struktur_jenjang_id,
              npsn_id_select      : res.data.npsn,
            }, function(){
              this.getTahunAjaranNow();
              this.getKelas();
            });
          });
        });
      });
    }
  }

  getData(){
    this.setState({
      loadingJadwal  : true,
      disabledFilter : true,
    });
    
    if(this.state.sms_jadwal_search != '' && this.state.ta_jadwal_search != '' && this.state.kls_jadwal_search != '' && this.state.kurikulum_jadwal_search != ''){
      axios.get(this.state.url_get_jadwal+`?institusi=${this.state.institusi_id_select}&semester=${this.state.sms_jadwal_search}&tahun_ajaran=${this.state.ta_jadwal_search}&kurikulum=${this.state.kurikulum_jadwal_search}&kelas=${this.state.kls_jadwal_search}`, { headers: { Authorization: tokenApi } })
        .then(res => {
          this.setState({
            data_jadwal      : res.data.data,
            loadingJadwal    : false,
            disabledFilter   : false,
            messageGetJadwal : '',
          });
        })
        .catch(err =>{
          this.setState({
            data_jadwal      : err.response.data.data,
            loadingJadwal    : false,
            disabledFilter   : false,
            messageGetJadwal : err.response.data.messages[0],
          });
        })
    }
  }

  resetFilter(){
    this.setState({
      data_jadwal : '',
      kls_jadwal_search : '',
      sms_jadwal_search : '',
      ta_jadwal_search  : '',
      data_tahun_ajaran : '',
      data_kelas        : '',
    }, function(){
      this.getTahunAjaranNow();
      this.getKelas();
    })
  }

  getKuota(){
    axios.get(this.state.url_get_kuota+'?institusi='+this.state.institusi_id_select+'&semester='+this.state.sms_jadwal_search+'&tahun_ajaran='+this.state.ta_jadwal_search+'&kelas='+this.state.kls_jadwal_search+'&kurikulum='+this.state.kurikulum_jadwal_search, { headers: { Authorization: tokenApi } })
      .then(res => {
        if(res.data.length != 0){
          this.setState({
            jmlSenin  : res.data.senin,
            jmlSelasa : res.data.selasa,
            jmlRabu   : res.data.rabu,
            jmlKamis  : res.data.kamis,
            jmlJumat  : res.data.jumat,
            jmlSabtu  : res.data.sabtu,
            jmlMinggu : res.data.minggu,
          }, function() {
            this.getData();
          });
        }
      })
  }

  export(event){
    event.preventDefault();
    this.setState({loading_export: true});
    if(this.state.ta_jadwal_search == '' || this.state.sms_jadwal_search == '' || this.state.kurikulum_jadwal_search == ''|| this.state.kls_jadwal_search == ''){
      this.setState({ 
        show_alert     : true, 
        title_alert    : "Gagal",
        icon_alert     : "error",
        message_alert  : "Select filter tahun ajaran dan semester terlebih dahulu",
        loading_export : false,
      });
    } else {
      axios({
        url           : this.state.url_export_data+'?institusi='+this.state.institusi_id_select+'&semester='+this.state.sms_jadwal_search+'&tahun_ajaran='+this.state.ta_jadwal_search+'&status=siswa&kelas='+this.state.kls_jadwal_search+'&kurikulum='+this.state.kurikulum_jadwal_search,
        method        : 'GET',
        responseType  : 'blob', // important
        headers       : {Authorization:tokenApi},
      }).then((response) => {
        const url  = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href  = url;
        if(this.state.kls_jadwal_search != ''){
          link.setAttribute('download', 'JadwalSiswa_'+this.state.ta_jadwal_search.replace(" / ", "-")+'_'+this.state.sms_jadwal_search+'_'+this.state.kls_jadwal_search+'.xlsx'); //or any other extension
        } else {
          link.setAttribute('download', 'JadwalSiswa_'+this.state.ta_jadwal_search.replace(" / ", "-")+'_'+this.state.sms_jadwal_search+'.xlsx'); //or any other extension
        }
        document.body.appendChild(link);
        link.click();
        this.setState({loading_export: false})
      }).catch((err)=>{
        if(err.response.status == 422){
          this.setState({ 
            show_alert    : true,
            title_alert   : "Gagal",
            icon_alert    : "error",
            message_alert : "Tidak ada jadwal pelajaran ditahun ajaran dan semester tersebut",
            loading_export: false
          });
        } 
      })
    }
  }

  forCountJamke(data){
    var item = [];
    for(var i = 0; i<data.length; i++){
      if(data[i].jam_ke == 0){
        item.push(<td>{"-"}</td>);
      } else {
        item.push(<td>{data[i].jam_ke}</td>);
      }
    }

    if(data.length == 0){
      item.push(<td>-</td>)
    }
    return(item);
  }

  showData(){
    if(this.state.data_jadwal != ''){
      var jdwl = this.state.data_jadwal;
      var dataShow  = [];
      var hariJwdl  = [];
      for(var i = 0; i<jdwl.length; i++){
        hariJwdl = [];
        for(var u = 0; u < jdwl[i].waktu.length; u++){
          if(jdwl[i].waktu[u].keterangan == "Pelajaran"){
            if(jdwl[i].waktu[u].mp == "AGAMA" || jdwl[i].waktu[u].mp == "LINTAS MINAT 1" || jdwl[i].waktu[u].mp == "LINTAS MINAT 2" ){
              var dataMapel = jdwl[i].waktu[u].pelajaran;
              var pelajaran = [];
              for(var y = 0; y < dataMapel.length; y++){
                if(dataMapel[y].nama != undefined){
                  pelajaran.push(
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={dataMapel[y].nama}>
                          {dataMapel[y].nama+ ' - '+ dataMapel[y].indeks_mpk + '-'}<span className="lowercase">{dataMapel[y].nama.substr(0, 3)}</span>
                        </Tooltip>
                      }
                    >
                      <div className="border-2 border-gray-200 p-2">{dataMapel[y].mp +' - '+ dataMapel[y].indeks_mpk}</div>
                    </OverlayTrigger>
                  );
                } else {
                  pelajaran.push(<td className="p-2 bg-gray-400 border-2 border-gray-200">-</td>)
                }
              }
              hariJwdl.push(
                <td style={{minWidth:'8rem'}} className="p-0 bg-gray-400 ">
                  {pelajaran}
                </td>
              );
            } else if(jdwl[i].waktu[u].nama != undefined || jdwl[i].waktu[u].nama != null) {
              hariJwdl.push(
                <td style={{minWidth:'8rem'}} className="p-2 bg-gray-400 border-2 border-gray-200">
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id={jdwl[i].waktu[u].nama}>
                        {jdwl[i].waktu[u].nama +' - '+jdwl[i].waktu[u].indeks_mpk +'-'}<span className="lowercase">{jdwl[i].waktu[u].nama.substr(0, 3)}</span>
                      </Tooltip>
                    }
                  >
                    <div>{jdwl[i].waktu[u].mp +' - '+ jdwl[i].waktu[u].indeks_mpk}</div>
                  </OverlayTrigger>
                </td>
              );
            } else {
              hariJwdl.push(<td style={{minWidth:'8rem'}} className="p-2 bg-gray-400 border-2 border-gray-200">-</td>)
            }
          } else {
            hariJwdl.push(
              <td style={{minWidth:'8rem'}} className="p-2 bg-green-400 border-2 border-green-200">
                <div>{jdwl[i].waktu[u].keterangan}</div>
              </td>
            );
          }
        }

        dataShow.push(
          <tr>
            <td style={{minWidth:'8rem'}} className="p-2">{jdwl[i].nama_rombel}</td>
            {hariJwdl}
          </tr>
        )
      } 

      return(
        <table className="w-full table-bordered align-middle text-xs mb-4">
          <thead className="text-center">
            <tr>
              <th rowSpan="2">Rombel</th>
              <th colSpan={this.state.jmlSenin.length}>Senin</th>
              <th colSpan={this.state.jmlSelasa.length}>Selasa</th>
              <th colSpan={this.state.jmlRabu.length}>Rabu</th>
              <th colSpan={this.state.jmlKamis.length}>Kamis</th>
              <th colSpan={this.state.jmlJumat.length}>Jumat</th>
              <th colSpan={this.state.jmlSabtu.length}>Sabtu</th>
              <th colSpan={this.state.jmlMinggu.length}>Minggu</th>
            </tr>
            <tr>
              {this.forCountJamke(this.state.jmlSenin)}
              {this.forCountJamke(this.state.jmlSelasa)}
              {this.forCountJamke(this.state.jmlRabu)}
              {this.forCountJamke(this.state.jmlKamis)}
              {this.forCountJamke(this.state.jmlJumat)}
              {this.forCountJamke(this.state.jmlSabtu)}
              {this.forCountJamke(this.state.jmlMinggu)}
            </tr>
          </thead>
          <tbody className="text-left">
            {dataShow}
          </tbody>
        </table>
      );
    } else {
      return (
        <div className="w-full text-center p-4"><p className="text-sm"><b>{this.state.messageGetJadwal != '' ? (this.state.messageGetJadwal) : ("Select Filter terlebih dahulu")}</b></p></div>
      )
    }
  }

  printJadwal(e){
    e.preventDefault();
    this.setState({loadingPrint : true})
    var windowPrint = window.open('', 'new div', 'height=400,width=600');
    var tableSenin  = [];
    var tableSelasa = [];
    var tableRabu   = [];
    var tableKamis  = [];
    var tableJumat  = [];
    var tableSabtu  = [];
    var tableMinggu = [];

    var jadwalSenin  = '';
    var jadwalSelasa = '';
    var jadwalRabu   = '';
    var jadwalKamis  = '';
    var jadwalJumat  = '';
    var jadwalSabtu  = '';
    var jadwalMinggu = '';
   
    if(this.state.rombel_search == '' && this.state.semester_search == ''){
      this.setState({ 
        show_alert     : true, 
        title_alert    : "Gagal",
        icon_alert     : "error",
        message_alert  : "Isi filter dengan Benar",
        loadingPrint   : false
      });
    } else {
      axios.get(`${this.state.url_get_jdwl_rombel}?rombel=${this.state.rombel_search}&institusi=${this.state.institusi_id_select}&semester=${this.state.semester_search}`, {headers:{Authorization:tokenApi}})
        .then(res => {
          if(res.data.status == true){
            var jadwal      = res.data.data;
            var show        = false;
            for(var u = 0; u < jadwal.length; u++){
              if(jadwal[u].m_hari_id == 1){
                tableSenin += `
                  <tr>
                    <td>${jadwal[u].jam_ke}</td>
                    <td>${jadwal[u].start}</td>
                    <td>${jadwal[u].finish}</td>`;
                    if(jadwal[u].pelajaran != undefined && jadwal[u].pelajaran != null){
                      var mataPelajaranMulti = '';
                      var guruMapelMulti = '';
                      mataPelajaranMulti += '<ul style="padding-inline-start: 9px">';
                      guruMapelMulti += '<ul style="padding-inline-start: 9px">';
                      for(var i = 0; i < jadwal[u].pelajaran.length; i++){
                        if(jadwal[u].pelajaran[i].mp != undefined){
                          mataPelajaranMulti += "<li>"+jadwal[u].pelajaran[i].mp+" - "+jadwal[u].pelajaran[i].indeks_mpk+"</li>";
                        } else {
                          guruMapelMulti += "<li> - </li>";
                        }
                        if(jadwal[u].pelajaran[i].nama != undefined){
                          guruMapelMulti += "<li>"+jadwal[u].pelajaran[i].nama+" - "+jadwal[u].pelajaran[i].indeks_mpk+"-"+jadwal[u].pelajaran[i].nama.substr(0, 3)+"</li>";
                        } else {
                          guruMapelMulti += "<li> - </li>";
                        }
                      }
                      mataPelajaranMulti += '</ul>';
                      guruMapelMulti += '</ul>';
                      tableSenin += `
                          <td>${mataPelajaranMulti}</td>
                          <td>${guruMapelMulti}</td>
                      `;
                    } else {
                      tableSenin += `
                          <td>${jadwal[u].keterangan != "Pelajaran" ? jadwal[u].keterangan : jadwal[u].mp + ' - ' +jadwal[u].indeks_mpk}</td>
                          <td>${jadwal[u].nama != null ? jadwal[u].nama + ' - '+jadwal[u].indeks_mpk_guru  : '-'}</td>
                      `;
                    }
                tableSenin += `
                  </tr>`;
              }
              if(jadwal[u].m_hari_id == 2){
                tableSelasa += `
                  <tr>
                    <td>${jadwal[u].jam_ke}</td>
                    <td>${jadwal[u].start}</td>
                    <td>${jadwal[u].finish}</td>`;
                    if(jadwal[u].pelajaran != undefined && jadwal[u].pelajaran != null){
                      var mataPelajaranMulti = '';
                      var guruMapelMulti = '';
                      for(var i = 0; i < jadwal[u].pelajaran.length; i++){
                        if(jadwal[u].pelajaran[i].mp != undefined){
                          mataPelajaranMulti += "("+jadwal[u].pelajaran[i].mp+" - "+jadwal[u].pelajaran[i].indeks_mpk+") ";
                        }
                        if(jadwal[u].pelajaran[i].nama != undefined){
                          guruMapelMulti += "("+jadwal[u].pelajaran[i].nama+" - "+jadwal[u].pelajaran[i].indeks_mpk+"-"+jadwal[u].pelajaran[i].nama.substr(0, 3)+") ";
                        } else {
                          guruMapelMulti += "-";
                        }
                      }
                      tableSelasa += `
                          <td>${mataPelajaranMulti}</td>
                          <td>${guruMapelMulti}</td>
                      `;
                    } else {
                      tableSelasa += `
                          <td>${jadwal[u].keterangan != "Pelajaran" ? jadwal[u].keterangan : jadwal[u].mp + ' - ' +jadwal[u].indeks_mpk}</td>
                          <td>${jadwal[u].nama != null ? jadwal[u].nama + ' - '+jadwal[u].indeks_mpk_guru  : '-'}</td>
                      `;
                    }
                tableSelasa += `
                  </tr>`;
              }
              if(jadwal[u].m_hari_id == 3){
                tableRabu += `
                  <tr>
                    <td>${jadwal[u].jam_ke}</td>
                    <td>${jadwal[u].start}</td>
                    <td>${jadwal[u].finish}</td>`;
                    if(jadwal[u].pelajaran != undefined && jadwal[u].pelajaran != null){
                      var mataPelajaranMulti = '';
                      var guruMapelMulti = '';
                      for(var i = 0; i < jadwal[u].pelajaran.length; i++){
                        if(jadwal[u].pelajaran[i].mp != undefined){
                          mataPelajaranMulti += "("+jadwal[u].pelajaran[i].mp+" - "+jadwal[u].pelajaran[i].indeks_mpk+") ";
                        }
                        if(jadwal[u].pelajaran[i].nama != undefined){
                          guruMapelMulti += "("+jadwal[u].pelajaran[i].nama+" - "+jadwal[u].pelajaran[i].indeks_mpk+"-"+jadwal[u].pelajaran[i].nama.substr(0, 3)+") ";
                        } else {
                          guruMapelMulti += "-";
                        }
                      }
                      tableRabu += `
                          <td>${mataPelajaranMulti}</td>
                          <td>${guruMapelMulti}</td>
                      `;
                    } else {
                      tableRabu += `
                          <td>${jadwal[u].keterangan != "Pelajaran" ? jadwal[u].keterangan : jadwal[u].mp + ' - ' +jadwal[u].indeks_mpk}</td>
                          <td>${jadwal[u].nama != null ? jadwal[u].nama + ' - '+jadwal[u].indeks_mpk_guru  : '-'}</td>
                      `;
                    }
                tableRabu += `
                  </tr>`;
              }
              if(jadwal[u].m_hari_id == 4){
                tableKamis += `
                  <tr>
                    <td>${jadwal[u].jam_ke}</td>
                    <td>${jadwal[u].start}</td>
                    <td>${jadwal[u].finish}</td>`;
                    if(jadwal[u].pelajaran != undefined && jadwal[u].pelajaran != null){
                      var mataPelajaranMulti = '';
                      var guruMapelMulti = '';
                      for(var i = 0; i < jadwal[u].pelajaran.length; i++){
                        if(jadwal[u].pelajaran[i].mp != undefined){
                          mataPelajaranMulti += "("+jadwal[u].pelajaran[i].mp+" - "+jadwal[u].pelajaran[i].indeks_mpk+") ";
                        }
                        if(jadwal[u].pelajaran[i].nama != undefined){
                          guruMapelMulti += "("+jadwal[u].pelajaran[i].nama+" - "+jadwal[u].pelajaran[i].indeks_mpk+"-"+jadwal[u].pelajaran[i].nama.substr(0, 3)+") ";
                        } else {
                          guruMapelMulti += "-";
                        }
                      }
                      tableKamis += `
                          <td>${mataPelajaranMulti}</td>
                          <td>${guruMapelMulti}</td>
                      `;
                    } else {
                      tableKamis += `
                          <td>${jadwal[u].keterangan != "Pelajaran" ? jadwal[u].keterangan : jadwal[u].mp + ' - ' +jadwal[u].indeks_mpk}</td>
                          <td>${jadwal[u].nama != null ? jadwal[u].nama + ' - '+jadwal[u].indeks_mpk_guru  : '-'}</td>
                      `;
                    }
                tableKamis += `
                  </tr>`;
              }
              if(jadwal[u].m_hari_id == 5){
                tableJumat += `
                  <tr>
                    <td>${jadwal[u].jam_ke}</td>
                    <td>${jadwal[u].start}</td>
                    <td>${jadwal[u].finish}</td>`;
                    if(jadwal[u].pelajaran != undefined && jadwal[u].pelajaran != null){
                      var mataPelajaranMulti = '';
                      var guruMapelMulti = '';
                      for(var i = 0; i < jadwal[u].pelajaran.length; i++){
                        if(jadwal[u].pelajaran[i].mp != undefined){
                          mataPelajaranMulti += "("+jadwal[u].pelajaran[i].mp+" - "+jadwal[u].pelajaran[i].indeks_mpk+") ";
                        }
                        if(jadwal[u].pelajaran[i].nama != undefined){
                          guruMapelMulti += "("+jadwal[u].pelajaran[i].nama+" - "+jadwal[u].pelajaran[i].indeks_mpk+"-"+jadwal[u].pelajaran[i].nama.substr(0, 3)+") ";
                        } else {
                          guruMapelMulti += "-";
                        }
                      }
                      tableJumat += `
                          <td>${mataPelajaranMulti}</td>
                          <td>${guruMapelMulti}</td>
                      `;
                    } else {
                      tableJumat += `
                          <td>${jadwal[u].keterangan != "Pelajaran" ? jadwal[u].keterangan : jadwal[u].mp + ' - ' +jadwal[u].indeks_mpk}</td>
                          <td>${jadwal[u].nama != null ? jadwal[u].nama + ' - '+jadwal[u].indeks_mpk_guru  : '-'}</td>
                      `;
                    }
                tableJumat += `
                  </tr>`;
              }
              if(jadwal[u].m_hari_id == 6){
                tableSabtu += `
                  <tr>
                    <td>${jadwal[u].jam_ke}</td>
                    <td>${jadwal[u].start}</td>
                    <td>${jadwal[u].finish}</td>`;
                    if(jadwal[u].pelajaran != undefined && jadwal[u].pelajaran != null){
                      var mataPelajaranMulti = '';
                      var guruMapelMulti = '';
                      for(var i = 0; i < jadwal[u].pelajaran.length; i++){
                        if(jadwal[u].pelajaran[i].mp != undefined){
                          mataPelajaranMulti += "("+jadwal[u].pelajaran[i].mp+" - "+jadwal[u].pelajaran[i].indeks_mpk+") ";
                        }
                        if(jadwal[u].pelajaran[i].nama != undefined){
                          guruMapelMulti += "("+jadwal[u].pelajaran[i].nama+" - "+jadwal[u].pelajaran[i].indeks_mpk+"-"+jadwal[u].pelajaran[i].nama.substr(0, 3)+") ";
                        } else {
                          guruMapelMulti += "-";
                        }
                      }
                      tableSabtu += `
                          <td>${mataPelajaranMulti}</td>
                          <td>${guruMapelMulti}</td>
                      `;
                    } else {
                      tableSabtu += `
                          <td>${jadwal[u].keterangan != "Pelajaran" ? jadwal[u].keterangan : jadwal[u].mp + ' - ' +jadwal[u].indeks_mpk}</td>
                          <td>${jadwal[u].nama != null ? jadwal[u].nama + ' - '+jadwal[u].indeks_mpk_guru  : '-'}</td>
                      `;
                    }
                tableSabtu += `
                  </tr>`;
              }
              if(jadwal[u].m_hari_id == 7){
                tableMinggu += `
                  <tr>
                    <td>${jadwal[u].jam_ke}</td>
                    <td>${jadwal[u].start}</td>
                    <td>${jadwal[u].finish}</td>`;
                    if(jadwal[u].pelajaran != undefined && jadwal[u].pelajaran != null){
                      var mataPelajaranMulti = '';
                      var guruMapelMulti = '';
                      for(var i = 0; i < jadwal[u].pelajaran.length; i++){
                        if(jadwal[u].pelajaran[i].mp != undefined){
                          mataPelajaranMulti += "("+jadwal[u].pelajaran[i].mp+" - "+jadwal[u].pelajaran[i].indeks_mpk+") ";
                        }
                        if(jadwal[u].pelajaran[i].nama != undefined){
                          guruMapelMulti += "("+jadwal[u].pelajaran[i].nama+" - "+jadwal[u].pelajaran[i].indeks_mpk+"-"+jadwal[u].pelajaran[i].nama.substr(0, 3)+") ";
                        } else {
                          guruMapelMulti += "-";
                        }
                      }
                      tableMinggu += `
                          <td>${mataPelajaranMulti}</td>
                          <td>${guruMapelMulti}</td>
                      `;
                    } else {
                      tableMinggu += `
                          <td>${jadwal[u].keterangan != "Pelajaran" ? jadwal[u].keterangan : jadwal[u].mp + ' - ' +jadwal[u].indeks_mpk}</td>
                          <td>${jadwal[u].nama != null ? jadwal[u].nama + ' - '+jadwal[u].indeks_mpk_guru  : '-'}</td>
                      `;
                    }
                tableMinggu += `
                  </tr>`;
              }
            }

            var total = 0;

            if(tableSenin != ''){
              total = total + 1;
              show = true;
              jadwalSenin += `
                <div style="margin:10px">
                  <span>Hari Senin<span>
                  <table class="table1 jadwal1">
                    <thead>
                      <tr>
                        <th>Jam Ke</th>
                        <th>Jam Awal</th>
                        <th>Jam Akhir</th>
                        <th>Mata Pelajaran</th>
                        <th>Guru</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${tableSenin}
                    </tbody>
                  </table>
                </div>
              `
            }
            
            if(tableSelasa != ''){
              total = total + 1;
              show = true;

              jadwalSelasa += `
                <div style="margin:10px">
                  <span>Hari Selasa<span>
                  <table class="table1 jadwal2">
                    <thead>
                      <tr>
                        <th>Jam Ke</th>
                        <th>Jam Awal</th>
                        <th>Jam Akhir</th>
                        <th>Mata Pelajaran</th>
                        <th>Guru</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${tableSelasa}
                    </tbody>
                  </table>
                </div>
              `
            }

            if(tableRabu != ''){
              total = total + 1;
              show = true;

              jadwalRabu += `
                <div style="margin:10px">
                  <span>Hari Rabu<span>
                  <table class="table1 jadwal3">
                    <thead>
                      <tr>
                        <th>Jam Ke</th>
                        <th>Jam Awal</th>
                        <th>Jam Akhir</th>
                        <th>Mata Pelajaran</th>
                        <th>Guru</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${tableRabu}
                    </tbody>
                  </table>
                </div>
              `
            }

            if(tableKamis != ''){
              total = total + 1;
              show = true;

              jadwalKamis += `
                <div style="margin:10px">
                  <span>Hari Kamis<span>
                  <table class="table1 jadwal4">
                    <thead>
                      <tr>
                        <th>Jam Ke</th>
                        <th>Jam Awal</th>
                        <th>Jam Akhir</th>
                        <th>Mata Pelajaran</th>
                        <th>Guru</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${tableKamis}
                    </tbody>
                  </table>
                </div>
              `
            }

            if(tableJumat != ''){
              total = total + 1;
              show = true;

              jadwalJumat += `
                <div style="margin:10px">
                  <span>Hari Jumat<span>
                  <table class="table1 jadwal5">
                    <thead>
                      <tr>
                        <th>Jam Ke</th>
                        <th>Jam Awal</th>
                        <th>Jam Akhir</th>
                        <th>Mata Pelajaran</th>
                        <th>Guru</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${tableJumat}
                    </tbody>
                  </table>
                </div>
              `
            }

            if(tableSabtu != ''){
              total = total + 1;
              show = true;

              jadwalSabtu += `
                <div style="margin:10px">
                  <span>Hari Sabtu<span>
                  <table class="table1 jadwal6">
                    <thead>
                      <tr>
                        <th>Jam Ke</th>
                        <th>Jam Awal</th>
                        <th>Jam Akhir</th>
                        <th>Mata Pelajaran</th>
                        <th>Guru</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${tableSabtu}
                    </tbody>
                  </table>
                </div>
              `
            }

            if(tableMinggu != ''){
              total = total + 1;

              jadwalMinggu += `
                <div style="margin:10px">
                  <span>Hari Minggu<span>
                  <table class="table1 jadwal7">
                    <thead>
                      <tr>
                        <th>Jam Ke</th>
                        <th>Jam Awal</th>
                        <th>Jam Akhir</th>
                        <th>Mata Pelajaran</th>
                        <th>Guru</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${tableMinggu}
                    </tbody>
                  </table>
                </div>
              `
            }

            windowPrint.document.write(
              `<html>
                <head>
                <style>
                  @media print {
                    .table1 {
                      max-height: 100%;
                      overflow: hidden;
                      page-break-after: always;
                    }
                    .jadwal${total} {
                      page-break-after: auto !important;
                    }
                  }
                  .table1 {
                    font-family: sans-serif;
                    color: #232323;
                    border-collapse: collapse;
                  }
                  
                  .table1, th, td {
                    border: 1px solid #999;
                    padding: 8px 20px;
                  }
                  
                  .table1 {
                    font-family: sans-serif;
                    color: #232323;
                    border-collapse: collapse;
                  }
              
                </style>
                </head>
                <body style="display: flex; flex-wrap: wrap; align-content: flex-start;">
                  ${jadwalSenin}
                  ${jadwalSelasa}
                  ${jadwalRabu}
                  ${jadwalKamis}
                  ${jadwalJumat}
                  ${jadwalSabtu}
                  ${jadwalMinggu}
                </body>
              </html>
              `
            );
        
            windowPrint.print();
            windowPrint.close();
          }
          
          this.setState({loadingPrint  : false});
        });
    }
  }

  getTahunAjaranNow(){
    var ta             = [];
    var u              = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for(var i=tahun_sekarang; i >= 2005; i--){
      u = u - 1;
      ta.push({"value":u+' / '+i, "label":u+' / '+i});
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  getSemester(){
    this.setState({ data_semester: semester });
  }
    
  getKelas(){
    var kelas = [];
    if(this.state.jenjang_id_select == '2'){
      kelas = [
        { value: 'I',   label: 'I'   },
        { value: 'II',  label: 'II'  },
        { value: 'III', label: 'III' },
        { value: 'IV',  label: 'IV'  },
        { value: 'V',   label: 'V'   },
        { value: 'VI',  label: 'VI'  },
      ];
    } else if(this.state.jenjang_id_select == '3') {
      kelas = [
        { value: 'VII',   label: 'VII'  },
        { value: 'VIII',  label: 'VIII' },
        { value: 'IX',    label: 'IX'   },
      ];
    } else if(this.state.jenjang_id_select == '4') {
      kelas = [
        { value: 'X',   label: 'X'   },
        { value: 'XI',  label: 'XI'  },
        { value: 'XII', label: 'XII' },
      ];
    } else if(this.state.jenjang_id_select == '5'){
      kelas = [
        { value: 'X',   label: 'X'   },
        { value: 'XI',  label: 'XI'  },
        { value: 'XII', label: 'XII' },
        { value: 'XIII', label: 'XIII' },
      ];
    }
    this.setState({ data_kelas: kelas });
    if(this.state.jenjang_id_select == '3' || this.state.jenjang_id_select == '2' ? this.setState({loadingFIlter: false}) : '');
    if(this.state.jenjang_id_select == '5' ? this.setState({loadingFIlter: false}) : '');
  }

  getPeminatanSMA(){
    if(this.state.jenjang_id_select == "4"){
      axios.get(this.state.url_get_spektrum+"?jenjang="+this.state.jenjang_id_select+"&kurikulum="+this.state.kurikulum_search, {headers:{Authorization:tokenApi}})
      .then(res => {
        if(res.data.status == true){
          this.setState({ data_spektrum: res.data.data });
        } 
      });
    }
  }
    
  getRombel(){
    var peminatan = '';
    if(this.state.jenjang_id_select == '5' ? peminatan = this.state.kompetensi_search : peminatan = this.state.peminatan_search);
    axios.get(`${this.state.url_get_rombel}?tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&peminatan=${peminatan}&user_institusi_id=${this.state.institusi_id_select}`, {headers:{Authorization:tokenApi}})
      .then( res => {
        this.setState({
          data_rombel: res.data,
        })
      });
  }

  // SMK
  getBidang(){
    if(this.state.jenjang_id_select == 5){
      axios.get(`${this.state.url_get_smk}?parent_id=0&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`, {headers:{Authorization:tokenApi}})
      .then(
        res => {
          this.setState({data_bidang: res.data});
        }
      )
    }
  }
  getProgram(){
    if(this.state.jenjang_id_select == 5){
      axios.get(`${this.state.url_get_smk}?parent_id=${this.state.bidang_search}&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`, {headers:{Authorization:tokenApi}})
      .then(
        res => {
          this.setState({data_program: res.data})
        }
      )
    }
  }
  getKompetensi(create){
    if(this.state.jenjang_id_select == 5){
      axios.get(`${this.state.url_get_smk}?parent_id=${this.state.program_search}&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`, {headers:{Authorization:tokenApi}})
      .then(
        res => {
          this.setState({data_kompetensi: res.data})
        }
      )
    }
  }

  showFilterJadwal(){
    if(this.state.data_tahun_ajaran != '' && this.state.institusi_id_select != ''){
      return(
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-1/4">
            <div className="py-2 mr-2">
              <Select
                id="filterTahunAjaran"
                styles={selectStyles}
                isDisabled={this.state.disabledFilter}
                options={this.state.data_tahun_ajaran}
                onChange={this.filterTaJadwal}
                placeholder= {'Pilih Tahun Ajaran'}
              />
            </div>
          </div>
          <div className="w-1/4">
            <div className="py-2 mr-2">
              <Select
                id="filterKelas"
                styles={selectStyles}
                isDisabled={this.state.disabledFilter}
                options={this.state.data_kelas}
                onChange={this.filterKelasJadwal}
                placeholder= {'Pilih Kelas'}
              />
            </div>
          </div>
          <div className="w-1/4">
            <div className="py-2 mr-2">
              <Select
                id="filterKurikulumJadwal"
                styles={selectStyles}
                isDisabled={this.state.disabledFilter}
                options={dataKurikulum}
                onChange={this.filterKurikulumJadwal}
                placeholder= {'Pilih Kurikulum'}
              />
            </div>
          </div>
          <div className="w-1/4">
            <div className="py-2 mr-2">
              <Select
                id="filterSemester"
                styles={selectStyles}
                isDisabled={this.state.disabledFilter}
                options={semester}
                onChange={this.filterSemesterJadwal}
                placeholder= {'Pilih Semester'}
              />
            </div>
          </div>
          <div className="w-1/4 flex">
            <button
              onClick={this.setFilter}
              className={`${this.state.loadingJadwal ? `btn-disabled` : `btn-schoolmedia`}  px-4 flex items-center rounded-full text-white py-2 text-sm`}
              disabled={this.state.loadingJadwal ? true : false}
            >
              {this.state.loadingJadwal ? <span className="text-gray-800">Tunggu...</span> : <><i className="bx bx-search text-lg mr-1"></i>Cari</>}
            </button>
            <button
              onClick={this.resetFilter}
              className={`${this.state.loadingJadwal ? `btn-disabled` : `btn-default`} ml-1 px-4 flex items-center rounded-full text-gray-800 py-2 text-sm`}
              disabled={this.state.loadingJadwal ? true : false}
            >
              {this.state.loadingJadwal ? <span className="text-gray-800">Tunggu...</span> : <>Reset</>}
            </button>
          </div>
        </div>
      )
    }
  }

  setFilter(){
    if(this.state.sms_jadwal_search == '' || this.state.ta_jadwal_search == ''){
      this.setState({ 
        show_alert     : true, 
        title_alert    : "Gagal",
        icon_alert     : "error",
        message_alert  : "Isi filter dengan Benar",
      });
    } else {
      this.getKuota();
    }
  }

  // SHOW TYPE FILTER PER JENJANG
  showSelectFilterSD(){
    if(this.state.data_tahun_ajaran != ''){
      return(
        <>
          <div className="w-full my-2">
            <Select
              id="filterTahunAjaran"
              styles={selectStyles}
              options={this.state.data_tahun_ajaran}
              onChange={this.filterTahunAjaran}
              placeholder= {'Pilih Tahun Ajaran'}
            />
          </div>
          {this.state.tahun_ajaran_search != '' && this.state.data_kelas != '' ? (
            <div className="w-full my-2">
              <Select
                id="filterKelas"
                styles={selectStyles}
                options={this.state.data_kelas}
                onChange={this.filterKelas}
                placeholder= {'Pilih Kelas'}
              />
            </div>
          ) : ("")}
        </>
      )
    }
  }

  showFilterKurikulum(){
    if(this.state.kelas_search != '' ){
      return(
        <div className="w-full my-2">
          <Select
            id="filterKurikulum"
            styles={selectStyles}
            options={dataKurikulum}
            onChange={this.filterKurikulum}
            placeholder= {'Pilih Kurikulum'}
          />
        </div>
      )
    }
  }

  showSelectFilterSMA(){
    if(this.state.kurikulum_search != '' && this.state.data_spektrum != '' ){
      return(
        <div className="w-full my-2">
          <Select
            id="filterPeminatan"
            styles={selectStyles}
            options={this.state.data_spektrum}
            onChange={this.filterPeminatan}
            placeholder= {'Pilih Peminatan'}
          />
        </div>
      )
    }
  }

  showSelectFilterSMK(){
    if(this.state.jenjang_id_select == '5'){
      return(
        <>
          {this.state.kurikulum_search != '' && this.state.data_bidang != '' ?
          <div className="w-full my-2">
            <Select
              id="filterBidang"
              styles={selectStyles}
              isClearable
              options={this.state.data_bidang}
              onChange={this.filterBidang}
              className="animateFadeInLeft"
            />
          </div>:<></>}
          {this.state.bidang_search != '' && this.state.data_program != '' ?
            <div className="w-full my-2">
              <Select
                id="filterProgram"
                styles={selectStyles}
                isClearable
                options={this.state.data_program}
                onChange={this.filterProgram}
              />
            </div>
          :""}
          {this.state.program_search != '' && this.state.data_kompetensi != '' ?
          <div className="w-full my-2">
            <Select
              id="filterKompetensi"
              styles={selectStyles}
              isClearable
              isDisabled={this.state.disabledFilter}
              isLoading = {this.state.loading_kompetensi ? true : false}
              options={this.state.data_kompetensi}
              onChange={this.filterKompetensi}
              placeholder= {this.state.loading_kompetensi ? "Loading" : "Kompetensi Keahlian"}
            />
          </div>:""}
          
        </>
      )
    }
  }

  showSelectFilterALL(){
    var kondisiJenjang = '';
    if(this.state.jenjang_id_select == 2 ? kondisiJenjang = this.state.kelas_search : '');
    if(this.state.jenjang_id_select == 3 ? kondisiJenjang = this.state.kelas_search : '');
    if(this.state.jenjang_id_select == 4 ? kondisiJenjang = this.state.peminatan_search : '');
    if(this.state.jenjang_id_select == 5 ? kondisiJenjang = this.state.kompetensi_search : '');
    if(kondisiJenjang != '' && this.state.data_rombel != ''){
      return(
        <div className="w-full my-2">
          <Select
            id="filterRombel"
            styles={selectStyles}
            options={this.state.data_rombel}
            onChange={this.filterRombel}
            placeholder= {'Pilih Rombel'}
          />
        </div>
      )
    }
  }

  showSelectFilterSemester(){
    if(this.state.rombel_search != '' && this.state.data_semester != ''){
      return(
        <div className="w-full my-2">
          <Select
            id="filterSemester"
            styles={selectStyles}
            isDisabled={this.state.disabledFilter}
            options={this.state.data_semester}
            onChange={this.filterSemester}
            placeholder= {'Pilih Semester'}
          />
        </div>
      )
    }
  }

  // FILTER HANDLE CHANGE FUNCTION
  filterTahunAjaran(e){
    if(e){
      this.setState({ 
        tahun_ajaran_search : e.value,
        kelas_search        : '', 
        kurikulum_search    : '', 
        peminatan_search    : '',
        bidang_search       : '',
        program_search      : '', 
        kompetensi_search   : '', 
        rombel_search       : '', 
        semester_search     : '', 
      }, function(){
        this.getKelas();
      });
    } else {
      this.setState({ 
        tahun_ajaran_search : '',
        kelas_search        : '', 
        kurikulum_search    : '', 
        peminatan_search    : '',
        bidang_search       : '',
        program_search      : '', 
        kompetensi_search   : '', 
        rombel_search       : '', 
        semester_search     : '', 
      });
    }
  }

  filterKelas(e){
    if(e){
      this.setState({ 
        kelas_search        : e.value,
        kurikulum_search    : '', 
        peminatan_search    : '',
        bidang_search       : '',
        program_search      : '', 
        kompetensi_search   : '', 
        rombel_search       : '', 
        semester_search     : '', 
        data_spektrum       : '',
        data_bidang         : '',
      });
    } else {
      this.setState({ 
        kelas_search        : '',
        kurikulum_search    : '', 
        peminatan_search    : '',
        bidang_search       : '',
        program_search      : '', 
        kompetensi_search   : '', 
        rombel_search       : '', 
        semester_search     : '', 
        data_spektrum       : '',
        data_bidang         : '',
      });
    }
  }

  filterKurikulum(e){
    if(e){
      this.setState({ 
        kurikulum_search    : e.value,
        peminatan_search    : '',
        bidang_search       : '',
        program_search      : '', 
        kompetensi_search   : '', 
        rombel_search       : '', 
        semester_search     : '', 
        data_spektrum       : '',
        data_bidang         : '',
      }, function(){
        this.getPeminatanSMA();
        this.getBidang();
      });
    } else {
      this.setState({ 
        kurikulum_search    : '',
        peminatan_search    : '',
        bidang_search       : '',
        program_search      : '', 
        kompetensi_search   : '', 
        rombel_search       : '', 
        semester_search     : '', 
        data_spektrum       : '',
        data_bidang         : '',
      });
    }
  }

  filterSemester(e){
    if(e){
      this.setState({ semester_search : e.value });
    } else {
      this.setState({ semester_search : ''});
    }
  }

  filterSemesterJadwal(e){
    if(e){
      this.setState({ sms_jadwal_search : e.value });
    } else {
      this.setState({ sms_jadwal_search : ''});
    }
  }

  filterTaJadwal(e){
    if(e){
      this.setState({ ta_jadwal_search : e.value });
    } else {
      this.setState({ ta_jadwal_search : '' });
    }
  }

  filterKelasJadwal(e){
    if(e){
      this.setState({ kls_jadwal_search : e.value });
    } else {
      this.setState({ kls_jadwal_search : '' });
    }
  }

  filterKurikulumJadwal(e){
    if(e){
      this.setState({ kurikulum_jadwal_search : e.value });
    } else {
      this.setState({ kurikulum_jadwal_search : '' });
    }
  }

  filterPeminatan(e){ //Only SMA
    if(e){
      this.setState({ 
        peminatan_search       : e.value, 
        peminatan_search_label : e.label,
        rombel_search          : '', 
        semester_search        : '', 
        data_rombel            : '',
      }, function(){
        this.getRombel();
      });
    } else {
      this.setState({ 
        peminatan_search       : '', 
        peminatan_search_label : '',
        rombel_search          : '', 
        semester_search        : '', 
        data_rombel            : '',
      });
    }
  }

  filterRombel(e){
    if(e){
      this.setState({ 
        rombel_search       : e.value, 
        rombel_search_label : e.label,
        semester_search     : '', 
        data_semester       : '', 
      }, function(){
        this.getSemester();
      });
    } else {
      this.setState({ 
        rombel_search       : '', 
        rombel_search_label : '',
        data_semester       : '', 
        semester_search     : '', 
      });
    }
  }

  // only for SMK
  filterBidang(e){
    if(e){
      this.setState({
        bidang_search       : e.value, 
        bidang_search_label : e.label,
        program_search      : '', 
        kompetensi_search   : '', 
        rombel_search       : '', 
        semester_search     : '', 
        data_program        : '',
      }, function(){
        this.getProgram();
      })
    } else {
      this.setState({
        bidang_search       : '', 
        bidang_search_label : '',
        program_search      : '', 
        kompetensi_search   : '', 
        rombel_search       : '', 
        semester_search     : '', 
        data_program        : '',
      })
    }
  }
  filterProgram(e){
    if(e){
      this.setState({
        program_search        : e.value, 
        program_search_label  : e.label,
        kompetensi_search     : '', 
        rombel_search         : '', 
        semester_search       : '', 
        data_kompetensi       : '',
      }, function(){
        this.getKompetensi();
      })
    } else {
      this.setState({
        program_search        : '', 
        program_search_label  : '',
        kompetensi_search     : '', 
        rombel_search         : '', 
        semester_search       : '',
        data_kompetensi       : '',
      })
    }
  }
  filterKompetensi(e){
    if(e){
      this.setState({
        kompetensi_search       : e.value, 
        kompetensi_search_label : e.label,
        rombel_search           : '', 
        semester_search         : '',
        data_rombel             : '',
      }, function(){
        this.getRombel();
      })
    } else {
      this.setState({
        kompetensi_search       : '', 
        kompetensi_search_label : '',
        rombel_search           : '', 
        semester_search         : '',
        data_rombel             : '',
      })
    }
  }

  showModalCetak(){
    this.setState({modal_cetak:true});
  }

  hideModalCetak(){
    this.setState({
      modal_cetak         : false,
      tahun_ajaran_search : '',
      kelas_search        : '', 
      peminatan_search    : '',
      bidang_search       : '',
      program_search      : '', 
      kompetensi_search   : '', 
      rombel_search       : '', 
      semester_search     : '', 
    });
  }

  render(){
    // select style
    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
    };

    return(
      <div>
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />

        <Modal show={this.state.modal_cetak} onHide={this.hideModalCetak}>
          <Modal.Body>
            <form className="w-full" onSubmit={this.printJadwal}>
              {this.showSelectFilterSD()}
              {this.showFilterKurikulum()}
              {this.showSelectFilterSMA()}
              {this.showSelectFilterSMK()}
              {this.showSelectFilterALL()}
              {this.showSelectFilterSemester()}
              {this.state.semester_search != '' ? (
                
                <button
                  className={`btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm`}
                  disabled={this.state.loadingPrint ? true : false}
                >
                  {this.state.loadingPrint ? <UserButtonLoading color="text-gray-700"/> : <><i className="bx bx-export text-lg mr-1"></i> Print</>}
                </button>
              ) : ''}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex flex-wrap justify-beetwen">
              <button onClick={this.hideModalCetak} className="btn-default mx-1 px-4 justify-center font-medium rounded-full text-gray-800 items-center py-2 text-sm">
                <span className=" text-md">Tutup</span>
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <div className="sm-container shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border">
          <div className="flex justify-between items-center mt-2  px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Jadwal Pelajaran Siswa
              <span className="text-gray-500 font-thin block text-sm">
                {/* <a href="/sadasd" className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
            <div className="flex">
              {this.state.institusi_id_select != '' ? (
                <>
                  <button onClick={this.export} className="btn-default mx-1 px-4 justify-center font-medium rounded-full text-gray-800 items-center py-2 text-sm" disabled={this.state.loading_export ? true : false}>
                    {this.state.loading_export ? <UserButtonLoading color="text-gray-700"/> : <><i className="bx bx-export text-lg mr-1"></i> Download</>}
                  </button>
                  {/* <button onClick={this.export} className="btn-default flex items-center rounded-full text-gray-600 py-2 px-4 mx-2 text-sm">
                    <i className="bx bx-download text-lg mr-1"></i>
                    Download
                  </button> */}
                  <button onClick={this.showModalCetak} className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm">
                    <i className="bx bx-printer text-lg mr-1"></i>
                    Cetak
                  </button>
                </>
              ) : (
                <div className="w-full flex justify-between mt-4">
                  <div className="w-full flex">
                    <div className="w-32 px-2"> <Skeleton height={20} className="bg-gray-400"/><Skeleton height={20} className="bg-gray-400"/> </div>
                    <div className="w-32 px-2"> <Skeleton height={20} className="bg-gray-400"/><Skeleton height={20} className="bg-gray-400"/> </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="sm-container shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border">
          <div className="flex justify-between items-center my-2 px-6 pb-2">
            <div className="w-3/4">
              <h1 className="text-xl text-gray-800 font-extrabold">
                Tabel Jadwal Pelajaran Siswa
              </h1>
              {this.state.institusi_id_select != '' && this.state.data_tahun_ajaran ?
                  this.showFilterJadwal() 
                : (
                <div className="w-full flex justify-between mt-4">
                  <div className="w-full flex">
                    <div className="w-1/4 px-2"> <Skeleton height={20} className="bg-gray-400"/><Skeleton height={20} className="bg-gray-400"/> </div>
                    <div className="w-1/4 px-2"> <Skeleton height={20} className="bg-gray-400"/><Skeleton height={20} className="bg-gray-400"/> </div>
                    <div className="w-1/4 px-2"> <Skeleton height={20} className="bg-gray-400"/><Skeleton height={20} className="bg-gray-400"/> </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-row">
              
            </div>
          </div>
          <div className={this.state.data_jadwal == '' ? '' : 'overflow-x-scroll'}>
            <div className="mx-2">
              {this.state.loadingJadwal ? (
                <div className="w-full mb-6 mt-6">
                  <div className="flex flex-row mb-2 mt-2 px-4">
                    <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                    <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                    <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                  </div>
                  <div className="flex flex-row mb-2 mt-2 px-4">
                    <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                    <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                    <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                  </div>
                  <div className="flex flex-row mb-2 mt-2 px-4">
                    <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                    <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                    <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                  </div>
                  <div className="flex flex-row mb-2 mt-2 px-4">
                    <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                    <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                    <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                  </div>
                </div>
              ) : this.showData()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
