import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from "react-router-dom";

export const TombolKembali = () =>  {
    let history = useHistory();
    return (
        <>
            <button
                onClick={() => history.goBack()} 
                className="btn-default px-4 flex items-center rounded-full text-gray-800 py-2 text-sm"
            >
                <i className="bx bx-chevron-left-circle text-lg mr-1"></i>
                Kembali
            </button>
        </>
    );
};