import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SideBarJadwal from "./component/SideBarJadwal.js";
import Select from 'react-select';
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import conf from "./../../../config.js";
import { TombolKembali } from '../../partials/TombolKembali.js';
import SweetAlert from 'sweetalert2-react';
import Skeleton from 'react-loading-skeleton';

// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

const semester = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
];

const dataKurikulum = [
  { value: "k13", label: "K13" },
  { value: "merdeka", label: "Merdeka" },
];

function captFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export default class DaftarMapelSiswa extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      url_get_rombel       : `${conf.uri_backend}api/v1/rombel/get_rombel`,
      url_get_mapel        : `${conf.uri_backend}api/v1/jadwal/mapel_per_siswa`,
      url_get_option_mapel : `${conf.uri_backend}api/v1/jadwal/get_mapel`,
      url_get_spektrum     : `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_filter       : `${conf.uri_backend}api/v1/rombel/filter`,
      url_get_smk          : `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,
      tahunSekarang        : new Date().getFullYear(),
      // alert
      show_alert           : false,
      title_alert          : '',
      message_alert        : '',
      icon_alert           : '',
      // filter
      tahun_ajaran_search  : '',
      kelas_search         : '',
      peminatan_search     : '',
      rombel_search        : '',
      bidang_search        : '',
      program_search       : '',
      kurikulum_search     : "",
      kompetensi_search    : '',
      // data filter
      data_spektrum        : '',
      data_bidang          : '',
      data_program         : '',
      data_kompetensi      : '',
      data_rombel          : '',
      loadingFilter        : true,
      loading_show_data: false,
    }
    // get option filter
    this.getTahunAjaranNow        = this.getTahunAjaranNow.bind(this);
    this.getKelas                 = this.getKelas.bind(this);
    this.getKurikulum = this.getKurikulum.bind(this);
    this.getRombel                = this.getRombel.bind(this);
    this.getPeminatanSMA          = this.getPeminatanSMA.bind(this);
    this.getDataFilterSMA         = this.getDataFilterSMA.bind(this);
    this.getBidang                = this.getBidang.bind(this);
    this.getProgram               = this.getProgram.bind(this);
    this.getKompetensi            = this.getKompetensi.bind(this);
    // filter and search
    this.filterTahunAjaran        = this.filterTahunAjaran.bind(this);
    this.filterKelas              = this.filterKelas.bind(this);
    this.filterRombel             = this.filterRombel.bind(this);
    this.filterPeminatan          = this.filterPeminatan.bind(this);
    this.filterBidang             = this.filterBidang.bind(this);
    this.filterKurikulum             = this.filterKurikulum.bind(this);
    this.filterProgram            = this.filterProgram.bind(this);
    this.filterKompetensi         = this.filterKompetensi.bind(this);
    this.filterSemester           = this.filterSemester.bind(this);
    this.filterMpq                = this.filterMpq.bind(this);
    // show select filter
    this.showSelectFilterSD       = this.showSelectFilterSD.bind(this);
    this.showSelectFilterSMA      = this.showSelectFilterSMA.bind(this);
    this.showSelectFilterSMK      = this.showSelectFilterSMK.bind(this);
    this.showSelectFilterALL      = this.showSelectFilterALL.bind(this);
    this.showSelectFilterSemester = this.showSelectFilterSemester.bind(this);
    this.showSelectFilterMPQ      = this.showSelectFilterMPQ.bind(this);
    // processing data
    this.setFilter                = this.setFilter.bind(this);
    this.setResetFilter           = this.setResetFilter.bind(this);
    this.showData                 = this.showData.bind(this);
    this.cekFilterJenjang = this.cekFilterJenjang.bind(this);
  }

    
  componentDidMount() {
    // get session user
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ data_session: res.data }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({ 
              institusi_id_select : res.data.id,
              jenjang_id_select   : res.data.m_struktur_jenjang_id,
              npsn_id_select      : res.data.npsn,
            }, function(){
                this.getTahunAjaranNow();
                this.getDataFilterSMA();
                this.getMapelData();
            });
          });
        });
      });
    }
  }

  getTahunAjaranNow() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  getKelas() {
    var kelas = [];
    if (this.state.jenjang_id_select == "2") {
      kelas = [
        { value: "I", label: "I" },
        { value: "II", label: "II" },
        { value: "III", label: "III" },
        { value: "IV", label: "IV" },
        { value: "V", label: "V" },
        { value: "VI", label: "VI" },
      ];
    } else if (this.state.jenjang_id_select == "3") {
      kelas = [
        { value: "VII", label: "VII" },
        { value: "VIII", label: "VIII" },
        { value: "IX", label: "IX" },
      ];
    } else if (this.state.jenjang_id_select == "4") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
      ];
    } else if (this.state.jenjang_id_select == "5") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
        { value: "XIII", label: "XIII" },
      ];
    }
    this.setState({ data_kelas: kelas });
  }

  
  getKurikulum() {
    this.setState({ data_kurikulum: dataKurikulum });
  }

  getPeminatanSMA() {
    this.setState({ loading_peminatan: !this.state.loading_peminatan });
    if (this.state.jenjang_id_select == "4") {
      axios
        .get(
          this.state.url_get_spektrum +
            "?jenjang=" +
            this.state.jenjang_id_select +
            "&kurikulum=" +
            this.state.kurikulum_search,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (res.data.status == true) {
            this.setState({ data_spektrum: res.data.data });
            this.setState({ loading_peminatan: false });
          } else {
            this.setState({ data_spektrum: [] });
            this.setState({ loading_peminatan: false });
          }
        });
    }
  }

  getRombel() {
    this.setState({ loading_rombel: !this.state.loading_rombel });
    var peminatan = "";
    if (
      this.state.jenjang_id_select == "5"
        ? (peminatan = this.state.kompetensi_search)
        : (peminatan = this.state.peminatan_search)
    );
    if (this.state.kurikulum_search == null) {
      this.setState({
        peminatan_search: "",
      });
    }
    axios
      .get(
        `${this.state.url_get_rombel}?tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&peminatan=${peminatan}&user_institusi_id=${this.state.institusi_id_select}&kurikulum=${this.state.kurikulum_search}`,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({
          data_rombel: res.data,
          loadingFilter: false,
          loading_rombel: false,
        });
      });
  }

  getMapelData(){
    if(this.state.rombel_search != '' && this.state.kelas_search){
      axios.get(`${this.state.url_get_option_mapel}?mpk=${this.state.kelas_search}&rombel_id=${this.state.rombel_search}`, {headers:{Authorization:tokenApi}})
      .then( res => {
        this.setState({
          data_mpq:res.data,
          // loadingFilter: false
        });
      });
    }
  }

  // SMK
  getBidang(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_bidang: !this.state.loading_bidang });
      axios
        .get(`${this.state.url_get_smk}?parent_id=0&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`, {
          headers: { Authorization: tokenApi },
        })
        .then((res) => {
          if (create != null) {
            this.setState({ data_bidang_p: res.data });
          } else {
            this.setState({ data_bidang: res.data });
            this.setState({ loading_bidang: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_bidang: false });
        });
    }
  }
  
  getProgram(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_program: !this.state.loading_program });
      var bidang = "";
      if (
        create != null
          ? (bidang = this.state.bidang)
          : (bidang = this.state.bidang_search)
      );
      axios
        .get(`${this.state.url_get_smk}?parent_id=${bidang}&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`, {
          headers: { Authorization: tokenApi },
        })
        .then((res) => {
          if (create != null) {
            this.setState({ data_program_p: res.data });
          } else {
            this.setState({ data_program: res.data });
            this.setState({ loading_program: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_program: false });
        });
    }
  }

  getKompetensi(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_kompetensi: !this.state.loading_kompetensi });
      var program = "";
      if (
        create != null
          ? (program = this.state.program)
          : (program = this.state.program_search)
      );
      axios
        .get(`${this.state.url_get_smk}?parent_id=${program}&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`, {
          headers: { Authorization: tokenApi },
        })
        .then((res) => {
          if (create != null) {
            this.setState({ data_kompetensi_p: res.data });
          } else {
            this.setState({ data_kompetensi: res.data });
            this.setState({ loading_kompetensi: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_kompetensi: false });
        });
    }
  }

  getDataFilterSMA() {
    axios
      .get(this.state.url_get_filter + "/" + this.state.institusi_id_select, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        if (res.data.status == true) {
          this.setState(
            {
              tahun_ajaran_search: res.data.data.tahun_ajaran != null ? res.data.data.tahun_ajaran : "",
              kelas_search: res.data.data.kelas !== null ? res.data.data.kelas : "",
              peminatan_search: res.data.data.kompetensi_id !== null ? res.data.data.kompetensi_id : "",
              peminatan_search_label: res.data.data.kompetensi !== null ? res.data.data.kompetensi : "",
              bidang_search: res.data.data.bidang_id !== null ? res.data.data.bidang_id : "",
              bidang_search_label: res.data.data.bidang !== null ? res.data.data.bidang : "",
              program_search: res.data.data.program_id !== null ? res.data.data.program_id : "",
              program_search_label: res.data.data.program !== null ? res.data.data.program : "",
              kompetensi_search: res.data.data.kompetensi_id !== null ? res.data.data.kompetensi_id : "",
              kompetensi_search_label: res.data.data.kompetensi !== null ? res.data.data.kompetensi : "",
              rombel_search: res.data.data.rombel_id !== null ? res.data.data.rombel_id : "",
              rombel_search_label: res.data.data.rombel !== null ? res.data.data.rombel : "",
              semester_search: res.data.data.semester !== null ? res.data.data.semester : "",
              kurikulum_search: res.data.data.kurikulum !== null ? res.data.data.kurikulum : "",
            },
            function() {
              this.getTahunAjaranNow();
              this.getKelas();
              this.getKurikulum();
              this.getPeminatanSMA();
              this.getRombel();
              this.getBidang();
              this.getProgram();
              this.getKompetensi();
              if (this.state.rombel_search != "" && this.state.semester_search != "")
                this.getMapelData();
            }
          );
        } else {
          this.setState({ loadingFilter : false });
        }
      });
  }

  // SHOW TYPE FILTER PER JENJANG
  showSelectFilterSD() {
    if  (this.state.loadingFilter == false && this.state.data_tahun_ajaran ) {
      return (
        <>
          <div className="flex w-1/4">
            <div className="py-2 mr-2">
              <Select
                id="filterTahunAjaran"
                styles={selectStyles}
                isDisabled={this.state.disabledFilter}
                defaultValue={
                  this.state.tahun_ajaran_search != ""
                    ? {
                        value: this.state.tahun_ajaran_search,
                        label: this.state.tahun_ajaran_search,
                      }
                    : ""
                }
                options={this.state.data_tahun_ajaran}
                onChange={this.filterTahunAjaran}
                placeholder={"Pilih Tahun Ajaran"}
              />
            </div>
          </div>
          {this.state.data_kelas && this.state.tahun_ajaran_search !== "" ? (
            <div className="flex w-1/4">
              <div className="py-2 mr-2">
                <Select
                  id="filterKelas"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.kelas_search != ""
                      ? {
                          value: this.state.kelas_search,
                          label: this.state.kelas_search,
                        }
                      : ""
                  }
                  options={this.state.data_kelas}
                  onChange={this.filterKelas}
                  placeholder={"Pilih Kelas"}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  showSelectFilterKurikulum() {
    if (this.state.loadingFilter == false && this.state.data_kurikulum != "" &&  this.state.kelas_search != "") {
      return (
        <>
          <div className="flex w-1/4">
            <div className="py-2 mr-2">
              <Select
                styles={selectStyles}
                className="react-select__control"
                placeholder="Pilih Kurikulum"
                isDisabled={this.state.disabledFilter}
                // isMulti
                defaultValue={
                  this.state.kurikulum_search != ""
                    ? {
                        value: this.state.kurikulum_search,
                        label: captFirstLetter(this.state.kurikulum_search),
                      }
                    : ""
                }
                options={this.state.data_kurikulum}
                onChange={this.filterKurikulum}
              />
            </div>
          </div>
        </>
      );
    } else {
      
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  showSelectFilterSMA() {
    if (this.state.loadingFilter == false && this.state.jenjang_id_select == "4" ) {
      if (this.state.data_spektrum && this.state.kelas_search !== "" && this.state.kurikulum_search !== "") {
        return (
          <>
            <div className="flex w-1/4">
              <div className="py-2 mr-2">
                <Select
                  id="filterPeminatan"
                  styles={selectStyles}
                  isLoading={this.state.loading_peminatan ? true : false}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.peminatan_search != ""
                      ? {
                          value: this.state.peminatan_search,
                          label: this.state.peminatan_search_label,
                        }
                      : ""
                  }
                  options={this.state.data_spektrum}
                  onChange={this.filterPeminatan}
                  placeholder={"Pilih Peminatan"}
                />
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div className="w-1/4 px-1">
            <div className="py-2">
              <Skeleton count={2} />
            </div>
          </div>
        );
      }
    } 
  }

  showSelectFilterSMK() {
    if (this.state.loadingFilter == false && this.state.jenjang_id_select == "5") {
      return (
        <>
          {this.state.kelas_search != "" ? (
            <div className="flex w-1/4">
              <div className="py-2 ml-2">
                <Select
                  id="filterBidang"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.bidang_search != ""
                      ? {
                          value: this.state.bidang_search,
                          label: this.state.bidang_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_bidang ? true : false}
                  options={this.state.data_bidang}
                  onChange={this.filterBidang}
                  className="animateFadeInLeft"
                  placeholder={
                    this.state.loading_bidang ? "Loading" : "Bidang Keahlian"
                  }
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {this.state.bidang_search != "" ? (
            <div className="flex w-1/4">
              <div className="py-2 ml-2">
                <Select
                  id="filterProgram"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.program_search != ""
                      ? {
                          value: this.state.program_search,
                          label: this.state.program_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_program ? true : false}
                  options={this.state.data_program}
                  onChange={this.filterProgram}
                  placeholder={
                    this.state.loading_program ? "Loading" : "Program Keahlian"
                  }
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.program_search != "" ? (
            <div className="flex w-1/4">
              <div className="py-2 mr-2">
                <Select
                  id="filterKompetensi"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.kompetensi_search != ""
                      ? {
                          value: this.state.kompetensi_search,
                          label: this.state.kompetensi_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_kompetensi ? true : false}
                  options={this.state.data_kompetensi}
                  onChange={this.filterKompetensi}
                  placeholder={
                    this.state.loading_kompetensi
                      ? "Loading"
                      : "Kompetensi Keahlian"
                  }
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  showSelectFilterALL() {
    if (this.state.loadingFilter == false && this.state.data_rombel && this.cekFilterJenjang('peminatan', this.state.peminatan_search) && this.cekFilterJenjang('kompetensi', this.state.kompetensi_search)) {
      return (
        <>
          <div className="flex w-1/4">
            <div className="py-2 mr-2">
              <Select
                id="filterRombel"
                styles={selectStyles}
                isLoading={this.state.loading_rombel ? true : false}
                isDisabled={this.state.disabledFilter}
                defaultValue={
                  this.state.rombel_search != ""
                    ? {
                        value: this.state.rombel_search,
                        label: this.state.rombel_search_label,
                      }
                    : ""
                }
                options={this.state.data_rombel}
                onChange={this.filterRombel}
                placeholder={"Pilih Rombel"}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  showSelectFilterSemester() {
    if (this.state.loadingFilter == false && this.state.rombel_search != "") {
      return (
        <div className="flex w-1/4">
          <div className="py-2 ml-2 mr-2">
            <Select
              id="filterSemester"
              styles={selectStyles}
              isDisabled={this.state.disabledFilter}
              defaultValue={
                this.state.semester_search != ""
                  ? {
                      value: this.state.semester_search,
                      label: this.state.semester_search,
                    }
                  : ""
              }
              options={semester}
              onChange={this.filterSemester}
              placeholder={"Pilih Semester"}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  
  showSelectFilterMPQ(){
    if(this.state.data_mpq != undefined && this.state.semester_search != undefined && this.state.semester_search != ''){
      return(
        <div className="flex w-1/4">
          <div className="py-2 mr-2">
            <Select
              id="filterMpq"
              styles={selectStyles}
              isDisabled={this.state.disabledFilter}
              options={this.state.data_mpq}
              onChange={this.filterMpq}
              placeholder= {'Pilih Mata Pelajaran'}
            />
          </div>
        </div>
      )
    } else {
      return(
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2}/>
          </div>
        </div>
      )
    }
  }

  cekFilterJenjang(type, value) {
    var cek = true;
    if (this.state.jenjang_id_select == 4) {
      if (type == "peminatan" && value == "")
        cek = false;
    } 
    
    if (this.state.jenjang_id_select == 5) {
      if (type == "bidang" && value == "")
        cek = false;
      if (type == "program" && value == "")
        cek = false;
      if (type == "kompetensi" && value == "")
        cek = false;
    } 

    return cek;
  }
  // FILTER HANDLE CHANGE FUNCTION
  filterTahunAjaran(e) {
    if (e) {
      this.setState(
        {
          tahun_ajaran_search: e.value,
          kelas_search: "",
          kurikulum_search: "",
          peminatan_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_spektrum: "",
          data_bidang: "",
          data_program: "",
          data_kelas: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getKelas();
        }
      );
    } else {
      this.setState({
        tahun_ajaran_search: "",
        kelas_search: "",
        kurikulum_search: "",
        peminatan_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_spektrum: "",
        data_kelas: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      }, function() {
        this.getKelas();
      });
    }
  }
  filterKurikulum(e) {
    if (e) {
      this.setState(
        {
          kurikulum_search: e.value,
          peminatan_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_spektrum: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
        },
        function() {
          if (this.state.jenjang_id_select == 2 || this.state.jenjang_id_select == 3)
            this.getRombel();
          else if (this.state.jenjang_id_select == 4)
            this.getPeminatanSMA();
          else if (this.state.jenjang_id_select == 5)
            this.getBidang();
        }
      );
    } else {
      this.setState({
        kurikulum_search: "",
        peminatan_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_spektrum: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
      });
    }
  }

  filterKelas(e) {
    if (e) {
      this.setState(
        {
          kelas_search: e.value,
          kurikulum_search: "",
          peminatan_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_kurikulum: "",
          data_spektrum: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getKurikulum();
        }
      );
    } else {
      this.setState({
        kelas_search: "",
        kurikulum_search: "",
        peminatan_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_kurikulum: "",
        data_spektrum: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }

  filterSemester(e) {
    if (e) {
      this.setState({ semester_search: e.value, loading_show_data: false });
    } else {
      this.setState({ semester_search: "" });
    }
  }

  filterPeminatan(e) {
    //Only SMA
    if (e) {
      this.setState(
        {
          peminatan_search: e.value,
          peminatan_search_label: e.label,
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getRombel();
        }
      );
    } else {
      this.setState({
        peminatan_search: "",
        peminatan_search_label: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }

  // only for SMK
  filterBidang(e) {
    if (e) {
      this.setState(
        {
          bidang_search: e.value,
          bidang_search_label: e.label,
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getProgram();
        }
      );
    } else {
      this.setState({
        bidang_search: "",
        bidang_search_label: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }
  filterProgram(e) {
    if (e) {
      this.setState(
        {
          program_search: e.value,
          program_search_label: e.label,
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getKompetensi();
        }
      );
    } else {
      this.setState({
        program_search: "",
        program_search_label: "",
        rombel_search: "",
        semester_search: "",
        kompetensi_search: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }
  filterKompetensi(e) {
    if (e) {
      this.setState(
        {
          kompetensi_search: e.value,
          kompetensi_search_label: e.label,
          rombel_search: "",
          semester_search: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getRombel();
        }
      );
    } else {
      this.setState({
        kompetensi_search: "",
        kompetensi_search_label: "",
        rombel_search: "",
        semester_search: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }

  filterRombel(e) {
    if (e) {
      this.setState({
        rombel_id: e.value,
      });
      this.setState({
        rombel_search: e.value,
        rombel_search_label: e.label,
        semester_search: "",
        loading_show_data: false,
      }, function() {
        this.getMapelData();

      });
    } else {
      this.setState({
        rombel_search: "",
        rombel_search_label: "",
        semester_search: "",
        loading_show_data: false,
      });
    }
  }

  filterMpq(e){
    if(e){
      this.setState({ mpq_search : e.value });
    } else {
      this.setState({ mpq_search : '' });
    }
  }

  setFilter(){
    var kompetensi       = '';
    var kompetensi_label = '';

    if(this.state.jenjang_id_select == '5'){
      kompetensi       = this.state.kompetensi_search;
      kompetensi_label = this.state.kompetensi_search_label;
    } else {
      kompetensi       = this.state.peminatan_search;
      kompetensi_label = this.state.peminatan_search_label;
    }

    axios.put(this.state.url_get_filter+'/'+this.state.institusi_id_select, {
      tahun_ajaran  : this.state.tahun_ajaran_search,
      kelas         : this.state.kelas_search,
      bidang        : this.state.bidang_search_label,
      bidang_id     : this.state.bidang_search,
      program       : this.state.program_search_label,
      program_id    : this.state.program_search,
      kompetensi    : kompetensi_label,
      kompetensi_id : kompetensi,
      rombel_id     : this.state.rombel_search,
      rombel        : this.state.rombel_search_label,
      semester      : this.state.semester_search,
    }, {headers:{Authorization:tokenApi}});
    
    this.setState({ disabledFilter:true });
    this.setState({ loadingMapel : true });

    if(this.state.mpq_search == '' && this.state.rombel_search == '' && this.state.semester_search == ''){
      this.setState({ 
        show_alert     : true, 
        title_alert    : "Gagal",
        icon_alert     : "error",
        message_alert  : "Isi filter dengan Benar",
        disabledFilter : false
      });
    } else {
      axios.get(`${this.state.url_get_mapel}?m_struktur_mpk_id=${this.state.mpq_search}&rombel_id=${this.state.rombel_search}&semester=${this.state.semester_search}`, {headers:{Authorization:tokenApi}})
        .then( res => {
          this.setState({ data_mapel:res.data });
          this.setState({ disabledFilter: false });
          this.setState({ loadingMapel : false });
        });
    }
  }

  setResetFilter(e){
    e.preventDefault();
    this.setState({
      data_tahun_ajaran         : null,
      data_kelas                : null,
      data_spektrum             : null,
      data_rombel               : null,
      data_mapel                : null,
      searchText                : '',
      kelas_search              : '',
      mpq_search                : '',
      peminatan_search          : '',
      peminatan_search_label    : '',
      rombel_search             : '',
      kurikulum_search             : '',
      rombel_search_label       : '',
      tahun_ajaran_search       : '',
      semester_search           : '',
      kompetensi_search         : '',
      kompetensi_search_label   : '',
      bidang_search_label       : '',
      bidang_search             : '',
      program_search_label      : '',
      program_search            : '',
      data_mpq                  : '',
      disabledFilter            : false,
    }, function(){
      this.getTahunAjaranNow();
      this.getMapelData();
      this.setState({ loading_filter : false })

    });
  }

  showData(){
    if(this.state.data_mapel != undefined){
      console.log(this.state.data_mapel);
      var gender = ['Perempuan', 'Laki - laki']
      return(
        <table className="table table-bordered font-thin text-sm">
          <thead className="text-center">
            <tr>
              <th>No</th>
              <th>NISN</th>
              <th>NAMA LENGKAP</th>
              <th>JENIS KELAMIN</th>
              <th>USERNAME</th>
              <th>KELAS</th>
              {this.state.jenjang_id_select == "4" ? (
                <th>PEMINATAN</th>
              ) : ""}
              {this.state.jenjang_id_select == "5" ? (
                <>
                  <th>BIDANG KEAHLIAN</th>
                  <th>PROGRAM KEAHLIAN</th>
                  <th>KOMPETENSI KEAHLIAN</th>
                </>
              ) : ""}
              <th>ROMBEL</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data_mapel.map((mapel, index) => (
              <tr>
                <td><div className="text-sm">{index+1}</div></td>
                <td><div className="text-sm">{mapel.nisn}</div></td>
                <td><div className="text-sm">{mapel.nama}</div></td>
                <td><div className="text-sm">{gender[mapel.jenis_kelamin]}</div></td>
                <td><div className="text-sm">{mapel.username}</div></td>
                <td><div className="text-sm">{mapel.kelas}</div></td>
                {this.state.jenjang_id_select == "4" ? (
                  <td><div className="text-sm">{this.state.peminatan_search_label}</div></td>
                ) : ""}
                {this.state.jenjang_id_select == "5" ? (
                  <>
                    <td><div className="text-sm">{this.state.bidang_search_label}</div></td>
                    <td><div className="text-sm">{this.state.program_search_label}</div></td>
                    <td><div className="text-sm">{this.state.kompetensi_search_label}</div></td>
                  </>
                ) : ""}
                <td><div className="text-sm">{mapel.nama_rombel}</div></td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    } else {
      return(<div className="px-2 py-2 text-center text-sm font-bold text-gray-600">Select Filter terlebih dahulu</div>);
    }
  }

  render(){
    return(
      <React.Fragment>
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />
        <div>
          <div className="sm-container flex flex-row py-2 mt-4 mx-auto">
            <SideBarJadwal/>

            <div className="w-10/12 px-1 ml-2">
              {/* Header */}
              <div className="flex justify-between items-center px-6 py-4 rounded-lg shadow-md bg-white mb-2 border">
                <h1 className="text-xl text-gray-800 font-extrabold">
                  <span className="text-gray-500 font-thin block text-sm mb-2">
                    <p href="/sadasd" className="text-gray-600">
                      5 dari 8 langkah
                    </p>
                  </span>
                  Daftar Mata Pelajaran Per Siswa
                  <span className="text-gray-500 font-thin block text-sm">
                    {/* <a href="/sadasd" className="text-blue-600">
                      Pelajari Lebih Lanjut ?
                    </a> */}
                  </span>
                </h1>
              </div>

              {/* Content */}
              <div className="mt-4 px-4 py-4 rounded-lg shadow-md bg-white border">
                {this.state.tahun_ajaran_search !== undefined ? (<span className="text-md text-gray-800">Cari :</span>) : ("")}
                {this.state.loadingFilter ?
                <>
                  <div className="mb-6 flex flex-row flex-wrap">
                    <div className="w-1/4 px-1">
                      <div className="py-2">
                        <Skeleton count={2}/>
                      </div>
                    </div>
                    <div className="w-1/4 px-1">
                      <div className="py-2">
                        <Skeleton count={2}/>
                      </div>
                    </div>
                    <div className="w-1/4 px-1">
                      <div className="py-2">
                        <Skeleton count={2}/>
                      </div>
                    </div>
                    <div className="w-1/4 px-1">
                      <div className="py-2">
                        <Skeleton count={2}/>
                      </div>
                    </div>
                  </div>
                </>:
                <>
                  <div className="mb-6 flex flex-row flex-wrap">
                    {this.showSelectFilterSD()}
                    {this.showSelectFilterKurikulum()}
                    {this.showSelectFilterSMA()}
                    {this.showSelectFilterSMK()}
                    {this.showSelectFilterALL()}
                    {this.showSelectFilterSemester()}
                    {this.showSelectFilterMPQ()}
                    <div className="w-1/4 px-1">
                      <div className="py-2">
                        <div className="flex">
                          {this.state.mpq_search !== undefined || '' ? (
                            <>
                            <button
                                onClick={this.setFilter}
                                className={`${this.state.loadingMapel ? `btn-disabled` : `btn-schoolmedia`} px-4 flex items-center rounded-full text-white py-2 text-sm`}
                                disabled={this.state.loadingMapel ? true : false}
                              >
                                {this.state.loadingMapel ?
                                <>
                                 <span className="text-gray-800">Tunggu...</span>
                                </>:
                                <>
                                <i className="bx bx-search text-lg mr-1"></i>
                                Cari
                                </>}
                            </button>
                              {this.state.loadingMapel ? <></> :
                                <>
                                  <button onClick={this.setResetFilter} className="flex ml-2 btn-default text-sm text-gray-800 px-4 py-2 rounded-full items-center">
                                    Reset
                                  </button>
                                </>
                              }
                            </>
                          ):
                          <div className="px-1 flex items-center">
                            <div className="py-2">
                              <div className="flex">
                                <span>Pilih secara berurutan</span>
                              </div>
                            </div>
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="overflow-x-scroll px-1">
                  {this.state.loadingMapel ?
                    <>
                      <div className="flex flex-row mb-2">
                        <div className="w-1/4 px-2">
                          <Skeleton/>
                        </div>
                        <div className="w-1/4 px-2">
                          <Skeleton/>
                        </div>
                        <div className="w-1/4 px-2">
                          <Skeleton/>
                        </div>
                        <div className="w-1/4 px-2">
                          <Skeleton/>
                        </div>
                      </div>
                      <Skeleton count={2}/>
                    </>
                    :
                    <>
                      {this.showData()}
                    </>}
                  </div>
                </>
                }
              </div>

              <div className="mt-2 px-4 py-4 rounded-lg shadow-md bg-white border">
                <div className="flex w-full flex-wrap items-center justify-between px-1">
                  <div className="flex flex-wrap items-center">
                    <TombolKembali/>
                  </div>
                  <div className="flex flex-wrap items-center">
                    <Link to="/jadwal-pelajaran/atur-guru-waktu">
                      <button className="bg-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm">    
                        Selanjutnya
                        <i className="bx bx-chevron-right-circle text-lg ml-1"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}