import React from "react";
import DataTable from "../../partials/table/DataTables.js";
import conf from "../../../config.js";
import tokenApi from "../../auth/Helpers";
import axios from "axios";
import { Button, Modal } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import swal from 'sweetalert2';
import { UserEditSkeleton, DetailSkeleton, UserButtonLoading } from "../../partials/loadings/ComponentLoading.js";

import "react-datepicker/dist/react-datepicker.css";

export default class Lahan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // url API
      url           : `${conf.uri_backend}api/v1/sarpras-m/table/lahan/`,
      url_create    : `${conf.uri_backend}api/v1/sarpras-m/create/lahan`,
      url_get_edit  : `${conf.uri_backend}api/v1/sarpras-m/lahan/`,
      url_put       : `${conf.uri_backend}api/v1/sarpras-m/lahan/`,
      url_delete    : `${conf.uri_backend}api/v1/sarpras-m/lahan/`,
      url_delete_m  : `${conf.uri_backend}api/v1/sarpras-m/sarpras/destroy/lahan/`,

      // for modal
      modal_tambah  : false,
      modal_edit    : false,
      modal_detail  : false,
      startDate     : new Date(),

      // bind data
      nama            : '',
      luas_terbuka    : '',
      luas_terbangun  : '',
      keterangan      : '',
      kesesuaian      : '',
      id              : '',
      jenjang         : '',

      // for data
      detail  : [],

      // alert
      show_alert    : false,
      title_alert   : '',
      message_alert : '',
      icon_alert    : '',

      // data session
      institusi_id_select : '',
      jenjang_id_select   : '',
      data_session        : [],

      isChecked:false,
      check_item            : false,
      checkAll              : false,
      loading_check         : false,
    };

    // bind data
    this.id             = this.id.bind(this);
    this.nama           = this.nama.bind(this);
    this.luas_terbuka   = this.luas_terbuka.bind(this);
    this.luas_terbangun = this.luas_terbangun.bind(this);
    this.keterangan     = this.keterangan.bind(this);
    this.kesesuaian     = this.kesesuaian.bind(this);

    // Create Function
    this.showModalTambah  = this.showModalTambah.bind(this);
    this.hideModalTambah  = this.hideModalTambah.bind(this);
    this.create           = this.create.bind(this);

    // Delete Function
    this.delete = this.delete.bind(this);

    // Edit Function
    this.showModalEdit  = this.showModalEdit.bind(this);
    this.hideModalEdit  = this.hideModalEdit.bind(this);
    this.update         = this.update.bind(this);

    // Detail Function
    this.showModalDetail = this.showModalDetail.bind(this);
    this.hideModalDetail = this.hideModalDetail.bind(this);

    // Reset Search Function
    this.resetSearch = this.resetSearch.bind(this);

    this.checkAll           = this.checkAll.bind(this);
    this.unCheckAll         = this.unCheckAll.bind(this);
    this.deleteMulti        = this.deleteMulti.bind(this);
  }

  componentDidMount() {
    // get session user
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ data_session: res.data }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({
              institusi_id_select : res.data.id,
              jenjang_id_select   : res.data.m_struktur_jenjang_id,
              npsn_id_select      : res.data.npsn,
            }, function(){
              this.dt.fetchEntities();
            });
          });
        });
      });
    }
  }

  // bind data
  id(e){
    this.setState({ id: e.target.value });
  }
  nama(e){
    this.setState({ nama: e.target.value });
  }
  luas_terbuka(e){
    if(e.target.value < 0){
      this.setState({luas_terbuka: '0'})
      document.getElementById('luas_terbuka').value = "0";
    } else {
      this.setState({ luas_terbuka: e.target.value });
    }
  }
  luas_terbangun(e){
    if(e.target.value < 0){
      this.setState({luas_terbangun: '0'})
      document.getElementById('luas_terbangun').value = "0";
    } else {
      this.setState({ luas_terbangun: e.target.value });
    }
  }
  keterangan(e){
    this.setState({ keterangan: e.target.value });
  }
  kesesuaian(e){
    if(e.target.value < 0){
      this.setState({kesesuaian: '0'})
      document.getElementById('kesesuaian').value = "0";
    } else {
      this.setState({ kesesuaian: e.target.value });
    }
  }

  // Create Lahan
  showModalTambah(){
    this.setState({ modal_tambah:true });
  }

  hideModalTambah(){
    this.setState({ 
      modal_tambah   : false,
      nama           : '',
      luas_terbuka   : '',
      luas_terbangun : '',
      keterangan     : '',
      kesesuaian     : '',
    });
  }

  alertMessage(e){
    let message = null;

    switch(e.status){
      case 422:
        message = e.data.messages[0];
      break;
      case 500:
        message= "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
      break;
      case 401:
        message = "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 400:
        message = "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 408:
        message = "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 429:
        message = "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 405:
        message = "Method Tidak Diperbolehkan, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
    }

    this.setState({
      show_alert    : true,
      title_alert   : "Gagal",
      icon_alert    : "error",
      message_alert : message,
      loading_button: false,
    })
  }

  create(event){
    event.preventDefault();

    this.setState({loading_button: true})
    axios.post(this.state.url_create, {
      nama              : this.state.nama,
      user_institusi_id : this.state.institusi_id_select,
      luas_terbuka      : this.state.luas_terbuka,
      luas_terbangun    : this.state.luas_terbangun,
      keterangan        : this.state.keterangan,
      kesesuaian        : this.state.kesesuaian,
      created_at        : new Date(),
      created_by        : this.state.institusi_id_select,
    }, {
      headers: {
        Authorization:tokenApi
      }
    })
      .then(res => {
        this.setState({
          show_alert    : true,
          title_alert   : "Berhasil",
          icon_alert    : "success",
          message_alert : res.data.messages[0],
          loading_button: false
        });
        this.hideModalTambah();
        this.dt.fetchEntities();
      })
      .catch(err => {
        this.setState({loading_button: false});
        this.alertMessage(err.response);
      })
  }

  // Edit Lahan
  showModalEdit(data){
    this.setState({modal_edit : true, loading_edit: true})
    axios.get(this.state.url_get_edit+data, {
      headers: {
        Authorization: tokenApi
      }
    })
    .then(res => {
        if(res.data.status == true){
            this.setState({
              id              : res.data.data.id,
              nama            : res.data.data.nama,
              luas_terbuka    : res.data.data.luas_terbuka,
              luas_terbangun  : res.data.data.luas_terbangun,
              keterangan      : res.data.data.keterangan,
              kesesuaian      : res.data.data.kesesuaian,
              loading_edit    : false,
            });
        } else {
            console.log("error");
        }
    });
  }

  update(event){
    event.preventDefault();
    this.setState({loading_saveedit: true})
    axios.put(this.state.url_put, {
      id             : this.state.id,
      nama           : this.state.nama,
      luas_terbuka   : this.state.luas_terbuka,
      luas_terbangun : this.state.luas_terbangun,
      keterangan     : this.state.keterangan,
      kesesuaian     : this.state.kesesuaian,
      update_at      : new Date(),
      update_by      : this.state.institusi_id_select,
    }, {
      headers: {
        Authorization: tokenApi
      }
    })
      .then(res => {
        this.setState({
          show_alert    : true,
          title_alert   : "Berhasil",
          icon_alert    : "success",
          message_alert : res.data.messages[0],
          loading_saveedit: false,
        });
        this.hideModalEdit();
        this.dt.fetchEntities();
      })
      .catch(err => {
        this.setState({loading_saveedit: false})
        this.alertMessage(err.response);
      })
  }

  hideModalEdit(){
    this.setState({ 
      modal_edit     : false,
      id             : '',
      nama           : '',
      luas_terbuka   : '',
      luas_terbangun : '',
      keterangan     : '',
      kesesuaian     : '',
    });
  }

  // Delete Lahan
  delete(data){
    this.setState({loading_delete: true});

    swal.fire({
      title: 'Peringatan',
      text: 'Apakah anda yakin menghapus lahan tersebut?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Iya`,
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.value == true) {
        axios.delete(this.state.url_delete+data, {
          headers: {
            Authorization: tokenApi
          }
        }).then(res => {
          this.dt.resetPage();
          this.setState({
            show_alert    : true,
            title_alert   : "Berhasil Menghapus",
            icon_alert    : "success",
            message_alert : res.data.messages[0],
            loading_delete: false,
          });
        }).catch((err)=>{
          this.alertMessage(err.response);
          this.setState({loading_delete: false});
        });
      } else if (result.dismiss == "cancel") {
        this.setState({loading_delete: false});
      } else if (result.dismiss == "backdrop") {
          this.setState({loading_delete: false});
        }
    })
  }

  checkAll(e){
    e.preventDefault();
    this.setState({ loading_check : true }, function(){
      this.setState({ checkAll:true, loading_check: false });
    })
  }

  unCheckAll(e){
    e.preventDefault();
    this.setState({ loading_check : true }, function(){
      this.setState({ checkAll:false, loading_check: false });
    })
  }

  deleteMulti(){
    this.setState({loading_delete: true});
    var checkboxes = document.getElementsByName('check_data[]');
    var vals = [];
    for (var i=0, n=checkboxes.length;i<n;i++){
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    if(vals.length == 0){
      this.setState({ 
        show_alert     : true, 
        title_alert    : "Gagal",
        icon_alert     : 'warning',
        message_alert  : "Mohon centang data terlebih dahulu",
        disabledFilter : false,
        loading_delete : false,
      });
    } else {
      swal.fire({
        title: 'Peringatan',
        text: 'Apakah anda yakin menghapus lahan yang dipilih?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Iya`,
        cancelButtonText: 'Batal'
      }).then((result) => {
        if (result.value == true) {
          axios.post(this.state.url_delete_m, {data  : vals}, {headers:{Authorization:tokenApi}})
            .then(res => {
              if(res.data.status == false){
                this.setState({ 
                  show_alert      : true,
                  title_alert     : "Gagal",
                  icon_alert      : "error",
                  message_alert   : res.data.messages[0],
                  loading_delete  : false,
                  checkAll        : false,
                });
                this.dt.loading();
                this.dt.resetPage();
              } else {
                this.setState({ 
                  show_alert      : true,
                  title_alert     : "Berhasil",
                  icon_alert      : "success",
                  message_alert   : res.data.messages,
                  loading_delete  : false,
                  checkAll        : false,
                });
                this.dt.loading();
                this.dt.resetPage();
              }
            })
            .catch(err => {
              this.setState({loading_delete : false})
              this.alertMessage(err.response);
            })
        } else if (result.dismiss == "cancel") {
          this.setState({loading_delete: false});
        } else if (result.dismiss == "backdrop") {
          this.setState({loading_delete: false});
        }
      })
    }

  }

  // Detail Function
  showModalDetail(event){
    event.preventDefault();
    this.setState({ modal_detail:true, loading_detail: true, });
    axios.get(`${conf.uri_backend}api/v1/sarpras-m/detail/lahan/${this.state.jenjang_id_select}`, {
      headers: {
        Authorization: tokenApi
      }
    }).then(res => {
      this.setState({ detail: res.data.data, loading_detail: false });
    })
  }

  hideModalDetail(){
    this.setState({ modal_detail:false });
  }

  // reset search
  resetSearch(event){
    event.preventDefault();
    document.getElementById("search").value = "";
    this.dt.fetchEntities();
  }

  render() {
    return (
     <div>
      <div className=" shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border sm-container">
        <div className="flex justify-between items-center mt-2 px-6 pb-2">
          <h1 className="text-xl text-gray-800 font-extrabold">
            Sarana & Prasarana
            <span className="text-gray-500 font-thin block text-sm">
              Daftar Lahan.
              {/* <a href="/sadasd" className="text-blue-600 ml-1">
                Pelajari Lebih Lanjut ?
              </a> */}
            </span>
          </h1>
          <div className="flex">
            <button className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm" onClick={this.showModalTambah}>
              <i className="bx bx-plus text-lg mr-1"></i>
              Tambah Lahan
            </button>
          </div>
        </div>
      </div>

      {/* alert */}
      <SweetAlert
        show={this.state.show_alert}
        title={this.state.title_alert}
        text={this.state.message_alert}
        type={this.state.icon_alert}
        onConfirm={() => this.setState({ show_alert: false })}
        onOutsideClick={() => this.setState({ show_alert: false })}
      />

      {/* modal tambah */}
      <Modal size="lg" show={this.state.modal_tambah} onHide={this.hideModalTambah}>
        <Modal.Header>Tambah Lahan</Modal.Header>
        <Modal.Body>
          <form onSubmit={this.create} className="mt-4">
            <div className="w-full mt-1">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Nama</label>
              <input
                type="text"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Nama Lahan"
                onChange={this.nama}
                maxLength="255"
              />
            </div>
            <div className="w-full mt-3 flex">
              <div className="w-1/2 pr-1">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">Luas Terbuka (meter)</label>
                <input
                  type="number"
                  id="luas_terbuka"
                  className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                  placeholder="Masukkan Luas Terbuka Lahan"
                  onChange={this.luas_terbuka}
                  max="1000000"
                />
              </div>
              <div className="w-1/2 pl-1">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">Luas Terbangun (meter)</label>
                <input
                  type="number"
                  id="luas_terbangun"
                  className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                  placeholder="Masukkan Luas Terbangun Lahan"
                  onChange={this.luas_terbangun}
                  max="1000000"
                />
              </div>
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Keterangan</label>
              <input
                type="text"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Keterangan Lahan"
                onChange={this.keterangan}
                maxLength="255"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kesesuaian (%)</label>
              <input
                type="number"
                id="kesesuaian"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Kesesuaian Lahan"
                onChange={this.kesesuaian}
                max="100"
              />
            </div>
            <hr className="mt-2"/>
            <div className="w-full flex justify-between mt-4">
              <p className="text-gray-800 block text-sm">Untuk melihat referensi Lahan Klik detail</p>
              <button onClick={this.showModalDetail} className="btn-blue text-white px-3 mb-4 py-1 rounded-full text-base font-medium">
                <i className="bx bx-show-alt pr-1 text-base"></i> Detail
              </button>
            </div>
            <hr/>
            <input type="submit" className={this.state.loading_button == true ? "btn-disabled px-4 flex items-center rounded-full py-2 mt-2 text-sm" : "btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"} value={this.state.loading_button == true ? 'Menyimpan...' : 'Simpan'} disabled={this.state.loading_button == true ? true : false}/>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideModalTambah}>Tutup</button>
        </Modal.Footer>
      </Modal>

      {/* modal edit */}
      <Modal size="lg" show={this.state.modal_edit} onHide={this.hideModalEdit}>
        <Modal.Header>Edit Lahan</Modal.Header>
        {this.state.loading_edit ?
        <UserEditSkeleton/>
        :
        <Modal.Body>
          <form onSubmit={this.update}>
            <input
                type="hidden"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Nama Lahan"
                value={this.state.id}
                onChange={this.id}
            />
            <div className="w-full mt-1">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Nama</label>
              <input
                type="text"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Nama Lahan"
                value={this.state.nama}
                onChange={this.nama}
                maxLength="255"
              />
            </div>
            <div className="w-full mt-3 flex">
              <div className="w-1/2 pr-1">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">Luas Terbuka (meter)</label>
                <input
                  type="number"
                  id="luas_terbuka"
                  className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                  placeholder="Masukkan Luas Terbuka Lahan"
                  value={this.state.luas_terbuka}
                  onChange={this.luas_terbuka}
                  max="1000000"
                />
              </div>
              <div className="w-1/2 pl-1">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">Luas Terbangun (meter)</label>
                <input
                  type="number"
                  id="luas_terbangun"
                  className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                  placeholder="Masukkan Luas Terbangun Lahan"
                  value={this.state.luas_terbangun}
                  onChange={this.luas_terbangun}
                  max="1000000"
                />
              </div>
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Keterangan</label>
              <input
                type="text"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Keterangan Lahan"
                value={this.state.keterangan}
                onChange={this.keterangan}
                maxLength="255"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kesesuaian (%)</label>
              <input
                type="number"
                id="kesesuaian"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Kesesuaian Lahan"
                value={this.state.kesesuaian}
                onChange={this.kesesuaian}
                max="100"
              />
            </div>
            <hr className="mt-2"/>
            <div className="w-full flex justify-between mt-4">
              <p className="text-gray-800 block text-sm">Untuk melihat referensi Lahan Klik detail</p>
              <button onClick={this.showModalDetail} className="btn-blue text-white px-3 mb-4 py-1 rounded-full text-base font-medium">
                <i className="bx bx-show-alt pr-1 text-base"></i> Detail
              </button>
            </div>
            <hr/>
            {this.state.loading_saveedit ? 
            <button className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm" disabled={this.state.loading_saveedit == true ? true : false}><UserButtonLoading/></button>:
            <input type="submit" className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm" value="Edit" />
            }
          </form>
        </Modal.Body>
        }
        <Modal.Footer>
          <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideModalEdit}>Tutup</button>
        </Modal.Footer>
      </Modal>

      {/* modal detail */}
      <Modal size="lg" show={this.state.modal_detail} onHide={this.hideModalDetail}>
        <Modal.Header>Detail Referensi</Modal.Header>
        {this.state.loading_detail ? <DetailSkeleton/>:
        <Modal.Body>
          <table className="table table-bordered font-thin text-sm">
            <thead classNam="text-center">
              <th>INDEKS LAHAN</th>
              <th>DESKRIPSI LAHAN</th>
            </thead>
            <tbody>
              {this.state.detail.map((lahan, index) => (
                <tr>
                <td>{lahan.indeks_lahan}</td>
                <td>{lahan.deskripsi_lahan}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        }
        <Modal.Footer>
          <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideModalDetail}>Tutup</button>
        </Modal.Footer>
      </Modal>

      <div className=" shadow-md  mx-auto py-2 mt-2 rounded-lg bg-white border sm-container">
        <div className="w-full flex flex-col mt-3 mb-2 px-4 pb-2">
          <span className="text-sm text-gray-800 px-1">Cari :</span>
          <div className="relative w-1/3 flex flex-row">
            <input
              type="search"
              id="search"
              className="w-full pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
              placeholder="Nama lahan"
              onChange={(event) => {
                this.dt.resetPage(`&query=${event.target.value}`);
                this.dt.loading();
              }}
            />
            <div className="absolute top-0 left-0 inline-flex items-center py-2 px-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 text-gray-400"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                <circle cx="10" cy="10" r="7"></circle>
                <line x1="21" y1="21" x2="15" y2="15"></line>
              </svg>
            </div>
          </div>
        </div>

        <div className="px-2 py-1">
          <DataTable
            ref={(dt) => {
              this.dt = dt;
            }}
            action={{
              created_at: (col, data) => {
                if(col.created_at !== null){
                  var date  = '';
                  var month = '';
                  var year  = '';
                  date      = col.created_at.substr(8, 2);
                  month     = col.created_at.substr(5, 2);
                  year      = col.created_at.substr(0, 4);

                  return (<p className="text-sm">{date+"-"+month+"-"+year}</p>);
                } else {
                  return (<p className="">Tidak Diketahui</p>);
                }
              },

              total_luas: (col, data) => {
                var terbuka = col.luas_terbuka;
                var terbangun = col.luas_terbangun;

                var total = terbuka + terbangun;

                return (<p className="text-sm">{total}</p>);
              },

              aksi:(col, data) => {
                return (
                  <div className="text-gray-700 px-4 py-1 flex items-center">
                    <button onClick={this.showModalEdit.bind(this, data.id_lahan)} className="btn-yellow text-lg text-white py-1 px-2 rounded-full font-medium mr-1">
                      <i className="bx bx-edit"></i>
                    </button>
                    <button onClick={this.delete.bind(this, data.id_lahan)} className={`${this.state.loading_delete === data.id_lahan ?`btn-gray`:`btn-red`} text-lg text-white py-1 px-2 rounded-full font-medium`} disabled={this.state.loading_delete === data.id_lahan ? true : false}>
                      {this.state.loading_delete === data.id_lahan ?<UserButtonLoading color="text-white" loadingText={null}/>:<i className="bx bx-trash"></i>}
                    </button>
                    {this.state.loading_check == false ? (
                      <input type="checkbox" name="check_data[]" id={data.id_lahan} value={data.id_lahan}
                        className="select-checkbox ml-2"
                        defaultChecked={this.state.checkAll}
                      />
                    ):("")}
                  </div>
                );
              }

            }}
            url={this.state.url+this.state.institusi_id_select}
            columns={[
              {
                index: "nama",
                label: "NAMA LAHAN",
              },
              {
                index: "luas_terbuka",
                label: "Luas Terbuka",
              },
              {
                index: "luas_terbangun",
                label: "Luas Terbangun",
              },
              {
                index: "total_luas",
                label: "LUAS",
              },
              {
                index: "keterangan",
                label: "Keterangan Kondisi Lahan",
              },
              {
                index: "kesesuaian",
                label: "Kesesuaian terhadap standar (%)",
              },
              {
                index: "created_at",
                label: "Dibuat Tanggal",
              },
              {
                index: "aksi",
                label: "AKSI",
              },
            ]}
          />
        </div>
      </div>

      <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
        <div className="w-full text-right flex justify-end px-4">
          <div className="flex flex-wrap items-baseline px-3 justify-end">
            <p>Pilih Lahan dan klik tombol</p>
            {this.state.checkAll == false ? (
              <button className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm" onClick={this.checkAll}>Pilih Semua</button>
            ) : (
              <button className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm" onClick={this.unCheckAll}>Tidak Pilih Semua</button>
            )}
            <button
              onClick={this.deleteMulti} 
              className="btn-green px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
              disabled={this.state.loading_delete == true ? true : false}
            >
              {this.state.loading_delete ? <UserButtonLoading/> :'Hapus'}
            </button>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
