import React from "react";
import ContentBangunan from "./ContentBangunan";
import ContentLahan from "./ContentLahan";
import ContentRuang from "./ContentRuang";
export default class ItemBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [
        {
          label: "Lahan",
          value: "lahan",
        },
        {
          label: "Bangunan",
          value: "bangunan",
        },
        {
          label: "Ruang",
          value: "ruang",
        }
      ],
      active_menu: "lahan",
      query: null
    };
  }
  
  render() {
    return (
      <div>
        <div className="flex justify-between">
          {this.menuData()}
        </div>
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    if (this.state.active_menu == "lahan") {
      return <ContentLahan jenjang={this.props.id} query={this.state.query}/>;
    }

    if (this.state.active_menu == "ruang") {
      return <ContentRuang jenjang={this.props.id} query={this.state.query}/>;
    }

    if (this.state.active_menu == "bangunan") {
      return <ContentBangunan jenjang={this.props.id} query={this.state.query}/>;
    }
  }
  
  setActiveMenu(v) {
    this.setState({
      active_menu: v.value,
    });
  }

  componentWillReceiveProps() {
    this.setState({ active_menu: "lahan" });
  }

  menuData() {
    return (
      <div className="flex items-center mt-4 mb-2 px-4 pb-2">
        {this.state.menu.map((v) => {
          if (this.state.active_menu == v.value) {
            return (
              <button className="bg-schoolmedia mx-1 justify-center font-medium px-4 rounded-full inline-flex items-center text-white py-2 text-sm">
                {v.label}
              </button>
            );
          } else {
            return (
              <button
                onClick={() => {
                  this.setActiveMenu(v);
                }}
                className="mx-1 justify-center font-medium px-4 rounded-full inline-flex items-center border-2 text-gray py-2 text-sm"
              >
                {v.label}
              </button>
            );
          }
        })}
      </div>
    );
  }
}
