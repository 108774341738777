import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DataTable from "../../../../../partials/table/DataTables";
import conf from "../../../../../../config";
import Skeleton from "react-loading-skeleton";
import { data } from "autoprefixer";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import tokenApi from "../../../../../auth/Helpers";
import TableSkeleton from "../../../../../partials/table/TableSkeleton";

// select style
const selectStyles = {
  control: (styles) => ({ ...styles, borderRadius: "100px", width: "100%" }),
};

export default class Kurikulum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,

      sepktrum: [],
      showModal: false,
      kode_mapel: null,
      tabsActive: "X",
      select: {
        bidang_keahlian: false,
        program_keahlian: false,
        kompetensi_keahlian: false,
      },
      data_bidang: [],
      content: [],
      queryTable: false,
      elements: "",
      lama_belajar: "",
      sepktrum: [],

      // filter
      data_bidang: "",
      data_program: "",
      data_kompetensi: "",

      // urutan filter
      select_program: false,
      select_kompetensi: false,
      select_semua: false,
      query: "",

      loadingDetail: true,
    };

    this.filterBidang = this.filterBidang.bind(this);
    this.filterProgram = this.filterProgram.bind(this);
    this.filterKompetensi = this.filterKompetensi.bind(this);
    this.getReset = this.getReset.bind(this);
  }

  componentDidMount() {
    this.getBidang();
    this.dt.fetchEntities("&jenjang_id=5");
  }

  render() {
    return (
      <div>
        <div className="sm-container shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border">
          <div className="flex justify-between items-center mt-2  px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Struktur Kurikulum
              <span className="text-gray-500 font-thin block text-sm">
                Daftar Struktur Kurikulum.{" "}
                {/* <a href="/sadasd" className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
          </div>
        </div>
        <div className="sm-container shadow-md mx-auto mt-2 rounded-lg bg-white border">
        <ul className="flex border-b mb-2">
            <li className="-mb-px mr-1">
              <Link
                className="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold"
                to="/referensi/jenis-kurikulum/k13/struktur-kurikulum/smk"
              >
                {/* SMA / MA */}
                Struktur Kurikulum
              </Link>
            </li>
            <li className="-mb-px mr-1">
              <Link
                className="bg-white inline-block py-2 px-4 text-blue-700 hover:text-blue-800 font-semibold"
                to="/referensi/jenis-kurikulum/k13/kompetensi-dasar/smk"
              >
                Kompetensi Dasar
              </Link>
            </li>
          </ul>
          <div className="px-2">
            <span className="font-medium ml-4 text-gray-700">Cari : </span>
            <div className="flex justify-end items-center ml-4 mb-6">
              <div className="flex w-full flex-wrap items-center">
                {this.selectBidang()}
                {this.selectProgram()}
                {this.selectKompetensi()}
                {this.state.select_semua ? (
                  <button
                    onClick={this.getReset}
                    className="flex bg-white text-sm text-gray-800 border-2 px-4 py-2 rounded-full items-center"
                  >
                    Reset
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="px-2 py-1">
            <DataTable
              ref={(dt) => {
                this.dt = dt;
              }}
              query={this.state.queryTable}
              url={this.state.url}
              action={{
                id: (col, data) => {
                  return (
                    <span className="text-gray-700 py-2 flex items-center">
                      <button
                        onClick={this.clickDetail.bind(
                          this,
                          data.kompetensi_id,
                          data.lama_belajar
                        )}
                        className="bg-blue-500 text-white px-3 text-base py-1 rounded-full font-medium"
                      >
                        Detail
                      </button>
                    </span>
                  );
                },
                kode_bidang_keahlianc: (col, data) => {
                  if (data.jenis_spektrum == "bidang_keahlian") {
                    return data.nama_spektrum;
                  }
                },
                kode_bidang_keahliancc: (col, data) => {
                  if (data.jenis_spektrum == "bidang_keahlian") {
                    return data.kode_spektrum;
                  }
                },
                program_keahlian: (col, data) => {
                  if (data.jenis_spektrum == "program_keahlian") {
                    return data.nama_spektrum;
                  }
                },
              }}
              columns={[
                // {
                //   index: "kode_bidang_keahliancc",
                //   label: "Kode",
                //   maxWidth: "3.4rem ",
                // },
                // { index: "kode_bidang_keahlianc", label: "Bidang Keahlian" },

                {
                  index: "program_kode",
                  label: "Kode",
                  maxWidth: "4rem ",
                },
                {
                  index: "program_nama",
                  label: "Program Keahlian",
                  maxWidth: "11rem ",
                },
                {
                  index: "kompetensi_kode",
                  label: "Kode",
                  maxWidth: "4rem ",
                },
                {
                  index: "kompetensi_nama",
                  label: "Kompetensi",
                  maxWidth: "8rem ",
                },
                {
                  index: "kompetensi_no",
                  label: "No Spektrum",
                  maxWidth: "6rem ",
                },
                {
                  index: "lama_belajar",
                  label: "Lama Belajar",
                  maxWidth: "6rem ",
                },
                { index: "id", label: "Aksi" },
              ]}
            />
            {this.showDetailModal()}
          </div>
        </div>
      </div>
    );
  }

  getBidang() {
    axios
      .get(
        `${conf.uri_backend}api/v1/referensi/struktur-kurikulum?jenjang_id=5&parent_id=0&kurikulum=K13`,
        {
          headers: {
            Authorization: tokenApi,
          },
        }
      )
      .then((res) => {
        this.setState({ data_bidang: res.data });
      });
  }

  getReset(e) {
    e.preventDefault();
    this.setState(
      {
        data_bidang: "",
        select_program: false,
        select_kompetensi: false,
        select_semua: false,
      },
      function() {
        this.getBidang();
        this.dt.fetchEntities("&jenjang_id=5");
      }
    );
  }

  setQueryTable(qry) {
    this.setState({
      queryTable: qry,
    });
  }

  selectBidang() {
    if (this.state.data_bidang != "") {
      return (
        <div className="flex w-1/4">
          <div className="w-full">
            <div className="py-2 mr-4">
              <Select
                id="filter"
                styles={selectStyles}
                isClearable
                options={this.state.data_bidang}
                onChange={this.filterBidang}
                placeholder={
                  (this.state.select.data_bidang = null
                    ? "Memuat Data..."
                    : "Bidang Keahlian")
                }
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  selectProgram() {
    if (this.state.data_program && this.state.select_program == true) {
      return (
        <div className="flex w-1/4">
          <div className="w-full">
            <div className="py-2 mr-4">
              <Select
                id="filter"
                styles={selectStyles}
                isClearable
                options={this.state.data_program}
                onChange={this.filterProgram}
                placeholder={
                  (this.state.select.data_program = null
                    ? "Memuat Data..."
                    : "Program Keahlian")
                }
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  selectKompetensi() {
    if (this.state.data_kompetensi && this.state.select_kompetensi == true) {
      return (
        <div className="flex w-1/4">
          <div className="w-full">
            <div className="py-2 mr-4">
              <Select
                id="filter"
                styles={selectStyles}
                isClearable
                options={this.state.data_kompetensi}
                onChange={this.filterKompetensi}
                placeholder={"Kompetensi Keahlian"}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  filterBidang(e) {
    this.setState({
      select_program : false,
      select_kompetensi : false,
    }, function() {
      if (e) {
        axios
          .get(
            `${conf.uri_backend}api/v1/referensi/struktur-kurikulum?jenjang_id=5&parent_id=${e.value}&kurikulum=K13`,
            {
              headers: {
                Authorization: tokenApi,
              },
            }
          )
          .then((res) => {
            // const sepktrum = res.data.data;
            // this.setState({ sepktrum : res.data.data });
            this.setState({ data_program: res.data, query: e.value }, function() {
              this.dt.resetPage(`&jenjang_id=5&paginate=10&query=${this.state.query}`);
            });
            this.dt.fetchEntities(`&jenjang_id=5&bidang_id=${e.value}`);
          });
        this.setState({ 
          data_program: "", 
          data_kompetensi: "",
          select_program : true,
        });
      } else {
        this.setState({ 
          data_program: "", 
          data_kompetensi: "",
        });
        this.dt.fetchEntities("&jenjang_id=5");
      }
      this.dt.loading();
    })
  }

  filterProgram(e) {
    this.setState({
      select_kompetensi : false,
    }, function() {
      if (e) {
        axios
          .get(
            `${conf.uri_backend}api/v1/referensi/struktur-kurikulum?jenjang_id=5&parent_id=${e.value}&kurikulum=K13`,
            {
              headers: {
                Authorization: tokenApi,
              },
            }
          )
          .then((res) => {
            // const sepktrum = res.data.data;
            // this.setState({ sepktrum : res.data.data });
            this.setState({ data_kompetensi: res.data });
            this.dt.fetchEntities(`&jenjang_id=5&program_id=${e.value}`);
          });
        this.setState({ 
          select_kompetensi: true,
          data_kompetensi: "",
        });
      } else {
        this.setState({ 
          data_kompetensi: "",
        });
        this.dt.fetchEntities("&jenjang_id=5");
      }
      this.dt.loading();
    });
  }

  filterKompetensi(e) {
    if (e) {
      this.setState({ select_semua: true });
      this.dt.fetchEntities(`&jenjang_id=5&kompetensi_id=${e.value}`);
    } else {
      this.setState({ select_semua: false });
      this.dt.fetchEntities("&jenjang_id=5");
    }
  }

  renderTableDataDetail(content) {
    const tableDataDetail = content.map((peminatan, index) => {
      return (
        <tr key={index}>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-1  py-2 flex items-center">
              {peminatan.kode_kmp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="border-gray-700 px-1 py-2 flex items-center">
              {peminatan.kmp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-1 py-2 flex items-center">
              {peminatan.kode_jkmp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-1 py-2 flex items-center">
              {peminatan.jkmp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-1 py-2 flex items-center">
              {peminatan.kode_mp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-1 py-2 flex items-center">
              {peminatan.mp}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-1 py-2  flex items-center">
              {peminatan.indeks_mpk}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-1 py-2 flex items-center">
              {peminatan.semester_1}
            </span>
          </td>
          <td className="border-t border-gray-200 userId">
            <span className="text-gray-700 px-1 py-2 flex items-center">
              {peminatan.semester_2}
            </span>
          </td>
        </tr>
      );
    });

    return (
      <table className="border-gray-400" style={{ width: "100%" }}>
        <thead>
          <tr className="text-left">
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600 tracking-wider text-sm">
              KODE
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              KMP
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              KODE
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              JKMP
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              KODE
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              MATA PELAJARAN
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600  tracking-wider  text-sm">
              INDEKS MPK
            </th>
            <th className="top-0  border-gray-700 border-b px-1 py-2 text-gray-600 tracking-wider  text-sm">
              KUOTA SEMESTER 1
            </th>
            <th className="top-0 border-gray-700 border-b px-1 py-2 text-gray-600 tracking-wider  text-sm">
              KUOTA SEMESTER 2
            </th>
          </tr>
        </thead>
        <tbody>{tableDataDetail}</tbody>
      </table>
    );
  }

  clickDetail(id, lama_belajar) {
    let kode_mapel = id;

    // this.setState({
    //   elements,
    // });
    if (lama_belajar == "4 th") {
      var kelasnya = ["X", "XI", "XII", "XIII"];
      this.setState({ elements: kelasnya }, function() {
        this.setShowModal(id, true, kode_mapel);
      });
    } else {
      var kelasnya = ["X", "XI", "XII"];
      this.setState({ elements: kelasnya }, function() {
        this.setShowModal(id, true, kode_mapel);
      });
    }
  }

  setShowModal(data, show = true, kode_mapel = null) {
    this.setState({
      showModal: show,
      kode_mapel: kode_mapel,
      id_spektrum: data,
    });
    this.showDataDetail(kode_mapel, "X", data);
    // console.log(this.state.content+"conten")
    if (show == false) {
      this.setState({ content: [], tabsActive: "X" });
    }
  }

  showDetailModal() {
    if (this.state.showModal) {
      return (
        <>
          <Modal
            size="xl"
            show={this.state.showModal}
            onHide={() => this.setShowModal(70, false)}
          >
            <Modal.Body>
              {/*header*/}
              <div className="flex items-start text-xl justify-between p-2 w-full rounded-t">
                Daftar Mata Pelajaran
                <button
                  className="flex bg-white text-xl text-black px-4 py-1 rounded-full items-center"
                  onClick={() => this.setShowModal(70, false)}
                >
                  ×
                </button>
              </div>
              {/*body*/}
              <div className="relative pb-4 flex-auto">
                <ul className="list-reset flex border-b">
                  {this.state.elements.map((value, index) => {
                    return (
                      <li className="-mb-px mr-1" key={index}>
                        {this.state.tabsActive == value ? (
                          <a
                            className="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold"
                            href="#"
                            onClick={this.setActiveTabs.bind(this, value)}
                          >
                            {value}
                          </a>
                        ) : (
                          <a
                            className="bg-white inline-block py-2 px-4 hover:text-blue-800 font-semibold"
                            href="#"
                            onClick={this.setActiveTabs.bind(this, value)}
                          >
                            {value}
                          </a>
                        )}
                      </li>
                    );
                  })}
                </ul>
                <h1 className="text-2xl text-gray-800 font-extrabold mt-2">
                  Nasional
                </h1>
                {this.state.loadingDetail ? (
                  <>
                    <TableSkeleton
                      style={{
                        width: "70rem",
                        bottom: "90px",
                        position: "relative",
                      }}
                    />
                  </>
                ) : (
                  <>
                    {this.state.content.nasional != undefined
                      ? this.renderTableDataDetail(this.state.content.nasional)
                      : ""}
                  </>
                )}
                {/* <h1 className="text-2xl text-gray-800 font-extrabold mt-8">
                    Lintas Minat 1
                  </h1>
                  <div className="overflow-x-scroll">
                    {this.state.loadingDetail ? 
                      <>
                        <TableSkeleton style={{width: '70rem', bottom: '90px', position: 'relative'}}/>
                      </>:
                      <>
                        {this.state.content.peminatan1 != undefined ? this.renderTableDataDetail(this.state.content.peminatan1) : ""}
                      </>
                    }
                  </div>
                  <h1 className="text-2xl text-gray-800 font-extrabold mt-8">
                    Lintas Minat 2
                  </h1>
                  <div className="overflow-x-scroll">
                  {this.state.loadingDetail ? 
                      <>
                        <TableSkeleton style={{width: '70rem', bottom: '90px', position: 'relative'}}/>
                      </>:
                      <>
                        {this.state.content.peminatan2 != undefined ? this.renderTableDataDetail(this.state.content.peminatan2) : ""}
                      </>
                    }
                  </div> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {/*footer*/}
              <div className="flex items-center justify-end p-2 rounded-b">
                <button
                  className="flex bg-white text-sm text-gray-800 border-2 px-4 py-2 rounded-full items-cente"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                  onClick={() => this.setShowModal(70, false)}
                >
                  Tutup
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }

  setActiveTabs(id) {
    this.setState({
      tabsActive: id,
      content: {
        nasional: undefined,
        peminatan1: undefined,
        peminatan2: undefined,
      },
    });
    this.showDataDetail2(id);
  }

  showDataDetail(kode_mapel, defaults, data) {
    axios
      .get(
        `${conf.uri_backend}api/v1/referensi/struktur-kurikulum/${data}/detail?kode_mapel=${kode_mapel}&kelas=${defaults}`,
        {
          headers: {
            Authorization: tokenApi,
          },
        }
      )
      .then((res) => {
        this.setState({ content: res.data });
        this.setState({ loadingDetail: false });
      });
  }

  showDataDetail2(id) {
    this.setState({ loadingDetail: true });
    axios
      .get(
        `${conf.uri_backend}api/v1/referensi/struktur-kurikulum/${this.state.id_spektrum}/detail?kode_mapel=${this.state.kode_mapel}&kelas=${id}`,
        {
          headers: {
            Authorization: tokenApi,
          },
        }
      )
      .then((res) => {
        this.setState({ content: res.data });
        this.setState({ loadingDetail: false });
      });
  }
}
