import React from "react";
// import DataTable from "../../../partials/table/DataTables.js";
import { Link } from "react-router-dom";
// import conf from "../../../../config.js";
// import SelectJs from "../../../partials/Select.js";
// import axios from "axios";
// import Select from 'react-select';
// import tokenApi from "../../../auth/Helpers";
// import TableSkeleton from "../../../partials/table/TableSkeleton";
// import Skeleton from 'react-loading-skeleton';
import DataTable from "../../../../../partials/table/DataTables";
import conf from "../../../../../../config.js";
import SelectJs from "../../../../../partials/Select.js";
import axios from "axios";
import Select from 'react-select';
import tokenApi from "../../../../../auth/Helpers";
import TableSkeleton from "../../../../../partials/table/TableSkeleton";
import Skeleton from 'react-loading-skeleton';


// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', width: '100%'}),
};

export default class Kurikulum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select: {
        kmp: [],
        jkmp: [],
        mp: [],
        mpk: [],
        ki: [],
        kd: [],
      },

      defaultSelect: {
        kmp: [],
        jkmp: [],
        mp: [],
        mpk: [],
        ki: [],
        kd: [],
      },

      //loading
      loadingKiKd: true,

      // value for select
      data_kmp  : '',
      data_jkmp : '',
      data_mp   : '',
      data_mpk  : '',
      data_ki   : '',
      data_kd   : '',

      sc_kmp    : '',
      sc_jkmp   : '',
      sc_mp     : '',
      sc_mpk    : '',
      sc_ki     : '',
      sc_kd     : '',
      query     : '',

      list_menu : '',
      refMenu   : '',
      active_menu: 2,
      url: `${conf.uri_backend}api/v1/referensi/kd`,
    };

    this.filterKmp = this.filterKmp.bind(this);
    this.filterJkmp = this.filterJkmp.bind(this);
    this.filterMp = this.filterMp.bind(this);
    this.filterMpk = this.filterMpk.bind(this);
    this.filterKi = this.filterKi.bind(this);
    this.filterKd = this.filterKd.bind(this);
    this.getReset = this.getReset.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ 
          data_session: res.data,
        }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({ 
              active_menu: res.data.m_struktur_jenjang_id,
              loadingKiKd: false
            }, () => {
              var menuJenjang = [];
              if(this.state.active_menu == 2){
                menuJenjang.push({ label: "SD / MI", value: 2 })
              } else if(this.state.active_menu == 3){
                menuJenjang.push({ label: "SMP / MTS", value: 3 })
              } else if(this.state.active_menu == 4){
                menuJenjang.push({ label: "SMA / MA", value: 4 })
              } else if(this.state.active_menu == 5){
                menuJenjang.push({ label: "SMK / MAK", value: 5 })
              }

              this.setState({ list_menu: menuJenjang}, function(){
                this.dt.fetchEntities(`&jenjang_id=${this.state.active_menu}`);
                axios.get(`${conf.uri_backend}api/v1/referensi/kmp/select?jenjang_id=${this.state.active_menu}`, {
                  headers : { Authorization: tokenApi }
                }).then(res => {
                  this.setState({
                    data_kmp: res.data,
                    loadingKiKd: false,
                    disabled_kmp: true
                  })
                })
              })
            });

            this.setState({ refMenu: res.data.m_struktur_jenjang_id }, function() {
              if (this.state.active_menu == 2 ? this.setState({ refMenu: "sd" }) : '');
              if (this.state.active_menu == 3 ? this.setState({ refMenu: "smp" }) : '');
              if (this.state.active_menu == 4 ? this.setState({ refMenu: "sma" }) : '');
              if (this.state.active_menu == 5 ? this.setState({ refMenu: "smk" }) : '');
            });

          });
        });
      });
    }
  }

  filterKmp(e){

   

    if(e){

      this.setState({ 
        data_jkmp : '',
        data_mp   : '',
        data_mpk  : '',
        data_ki   : '',
        data_kd   : '',
        query     : e.value
      }, function(){
        this.dt.resetPage(`&jenjang_id=${this.state.active_menu}&paginate=10&query=${this.state.query}`);
        
        axios.get(`${conf.uri_backend}api/v1/referensi/jkmp/select?jenjang_id=${this.state.active_menu}&kmp_id=${e.value}`, {
          headers: {
            Authorization: tokenApi
          }
        }).then((res) => {
          this.setState({
            data_jkmp : res.data,
            sc_kmp    : e.value,
          });
         
           
          this.dt.fetchEntities(`&kmp_id=${e.value}&jenjang_id=${this.state.active_menu}`);
          // this.dt.resetPage(`&query=${this.state.query}`);
           this.dt.loading();
          ;
        });
      });
    } else {
      this.setState({ 
        data_jkmp : '', 
        sc_kmp    : '',
        query     : e?.value  
      }, function(){
        
       
        this.dt.fetchEntities(`&kmp_id=${e?.value}&jenjang_id=${this.state.active_menu}`);
        this.dt.resetPage(`&jenjang_id=${this.state.active_menu}&paginate=10&query=${this.state.query}`);
        this.dt.loading();
      });
       
    }
    // console.log(this.state.data_kmp)
  }

  render() {
    return (
      <div>
        <div className="sm-container shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border">
          <div className="flex justify-between items-center mt-2  px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Kompetensi Dasar
              <span className="text-gray-500 font-thin block text-sm">
                Daftar Kompetensi Dasar.{" "}
                {/* <a href="/sadasd" className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
          </div>
        </div>
        
        {/* Tabs*/}
        {/* <div className="sm-container shadow-md mx-auto mt-2 rounded-lg bg-white border"> */}
          
          {/* <div className="px-2 py-4">{this.renderTableData()}</div> */}
        {/* </div> */}

        <div className="sm-container shadow-md mx-auto mt-2 rounded-lg bg-white border">
          {/* {this.menuBuilder()}  */}
          <ul className="flex border-b mb-2">
            <li className="-mb-px mr-1">
              <Link
                className="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold"
                to={"/referensi/jenis-kurikulum/k13/struktur-kurikulum/"+this.state.refMenu}>
                Struktur Kurikulum
              </Link>
            </li>
            <li className="-mb-px mr-1">
              <Link
                className="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold"
                to={"/referensi/jenis-kurikulum/k13/kompetensi-dasar/"+this.state.refMenu}>
                Kompetensi Dasar
              </Link>
            </li>
          </ul>

          {this.state.list_menu != '' ? (
            <div className="px-2">
              {this.state.data_kmp != '' ? (
                <span className="font-medium ml-4 text-gray-700">Cari : </span>
              ) : ("")}
              <div className="flex justify-end items-center mb-2 pb-2">
                <div className="flex w-full flex-wrap items-center px-4 pb-2">
                  {this.selectKmp()}
                  {this.selectJkmp()}
                  {this.selectMp()}
                  {this.selectMpk()}
                  {this.selectKi()}
                  {this.selectKd()}
                  {this.state.data_kd != '' ? (
                    <form>
                      <div className="mr-2 flex">
                        <button onClick={this.getReset} className="animateFadeInLeft flex btn-default text-sm text-gray-800 px-4 py-2 rounded-full items-center">
                          Reset
                        </button>
                      </div>
                    </form>
                  ):(
                    this.state.data_kmp != '' ? (
                      <p className="ml-2 text-sm text-gray-500">Pilih filter secara berurutan</p>
                    ) : ("")
                  )}
                </div>
              </div>
            </div>
          ) : ('')}
          <div className="px-2 py-1">
            {this.state.loadingKiKd ? 
              <>
                {/* {alert('oke')} */}
              </>:
              <>
                <DataTable
                  ref={(dt) => {
                    this.dt = dt;
                  }}
                  url={this.state.url}
                  columns={[
                    {
                      index: "indeks_jenjang",
                      label: "J",
                      maxWidth: "2rem ",
                    },
                    {
                      index: "kode_kmp",
                      label: "KMP",

                      maxWidth: "2.5rem ",
                    },

                    {
                      index: "kode_jkmp",
                      label: "JKMP",
                      maxWidth: "2.5rem ",
                    },
                    {
                      index: "kode_mp",
                      label: "MP",
                      maxWidth: "2.5rem ",
                    },
                    {
                      index: "kode_mpk",
                      label: "KLS",
                      maxWidth: "2.5rem ",
                    },
                    {
                      index: "kode_ki",
                      label: "KI",
                      maxWidth: "2.5rem ",
                    },
                    {
                      index: "kode_kd",
                      label: "KD",
                      maxWidth: "2.5rem ",
                    },
                    {
                      index: "kd",
                      label: "Rincian Kompetensi Dasar",
                      maxWidth: "15rem ",
                    },
                    {
                      index: "indeks_kd",
                      label: "Indeks KD",
                    },
                  ]}
                />
              </>
            }
          </div>
        </div>
      </div>
    );
  }

  getUrl() {
    return `${conf.uri_backend}api/v1/referensi/kd/select?jenjang_id=${this.state.active_menu}`;
  }

  setActiveMenu(value) {
    axios.get(`${conf.uri_backend}api/v1/referensi/kmp/select?jenjang_id=${value}`, {
      headers : { Authorization: tokenApi }
    }).then(res => {
      this.setState({
        data_kmp    : res.data,
        loadingKiKd : false
      })
    })
    this.setState({ 
      active_menu: value, 
      select: this.state.defaultSelect,
      loadingKiKd: false
    }, function(){
      this.dt.fetchEntities(`&jenjang_id=${this.state.active_menu}`);
    });
  }

  menuBuilder() {
    // <ul className="flex border-b mb-2">
    if(this.state.list_menu != ''){
      const c = this.state.list_menu.map((value) => {
        if (value.value == this.state.active_menu) {
          return (
            <li className="-mb-px mr-1">
              <a className="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold">
                {value.label}
              </a>
            </li>
          );
        }
        // return (
        //   <li className="-mb-px mr-1">
        //     <a
        //       className="bg-white inline-block py-2 px-4 hover:text-blue-800 font-semibold"
        //       href="#"
        //       onClick={this.setActiveMenu.bind(this, value.value)}
        //     >
        //       {value.label}
        //     </a>
        //   </li>
        // );
      });
      return <ul className="flex border-b mb-2">{c}</ul>;
    } else {
      return (
        <>
          <div className="mb-6 mt-6">
            <div className="flex flex-row mb-2 mt-2 px-4">
              <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
              <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
              <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
            </div>
            <div className="flex flex-row mb-2 mt-2 px-4">
              <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
              <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
              <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
            </div>
            <div className="flex flex-row mb-2 mt-2 px-4">
              <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
              <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
              <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
            </div>
            <div className="flex flex-row mb-2 mt-2 px-4">
              <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
              <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
              <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
            </div>
          </div>
        </>
      );
    }
  }

  // filter
 

  filterJkmp(e){
    if(e){
      this.setState({ 
        data_mp   : '',
        data_mpk  : '',
        data_ki   : '',
        data_kd   : '',
      }, function(){
        axios.get(`${conf.uri_backend}api/v1/referensi/mp/select?jenjang_id=${this.state.active_menu}&jkmp_id=${e.value}`, {
          headers: {
            Authorization: tokenApi
          }
        }).then((res) => {
          this.setState({
            data_mp : res.data,
            sc_jkmp : e.value,
          }, function(){
            this.dt.loading();
            this.dt.fetchEntities(`&jkmp_id=${e.value}&jenjang_id=${this.state.active_menu}`);
          });
        });
      })
    } else {
      this.setState({
        data_mp   : '',
        data_mpk  : '',
        data_ki   : '',
        data_kd   : '',
        sc_jkmp   : '',
      }, function(){
        this.dt.loading();
        this.dt.fetchEntities(`&kmp_id=${this.state.sc_kmp}&jenjang_id=${this.state.active_menu}`);
      });
    }
  }

  filterMp(e){
    if(e){
      this.setState({ 
        data_mpk  : '',
        data_ki   : '',
        data_kd   : '',
      }, function(){
        axios.get(`${conf.uri_backend}api/v1/referensi/mpk/select?jenjang_id=${this.state.active_menu}&mp_id=${e.value}`, {
          headers: {
            Authorization: tokenApi
          }
        }).then((res) => {
          this.setState({
            data_mpk : res.data,
            sc_mp    : e.value,
          });
          this.dt.fetchEntities(`&mp_id=${e.value}&jenjang_id=${this.state.active_menu}`);
        });
      })
    } else {
      this.setState({
        data_mpk  : '',
        data_ki   : '',
        data_kd   : '',
        sc_mp     : '',
      }, function(){
        this.dt.loading()
        this.dt.fetchEntities(`&jkmp_id=${this.state.sc_jkmp}&jenjang_id=${this.state.active_menu}`);
      });
    }
  }

  filterMpk(e){
    if(e){
      this.setState({ 
        data_ki   : '',
        data_kd   : '',
      }, function(){
        axios.get(`${conf.uri_backend}api/v1/referensi/ki/select?jenjang_id=${this.state.active_menu}&mpk_id=${e.value}`, {
          headers: {
            Authorization: tokenApi
          }
        }).then((res) => {
          this.setState({
            data_ki   : res.data,
            sc_mpk    : e.value,
          });
          this.dt.loading();
          this.dt.fetchEntities(`&mpk_id=${e.value}&jenjang_id=${this.state.active_menu}`);
        });
      })
    } else {
      this.setState({
        data_ki   : '',
        data_kd   : '',
        sc_mpk    : '',
      }, function(){
        this.dt.loading();
        this.dt.fetchEntities(`&mp_id=${this.state.sc_mp}&jenjang_id=${this.state.active_menu}`);
      });
    }
  }

  filterKi(e){
    if(e){
      this.setState({ 
        data_kd   : '',
      }, function(){
        axios.get(`${conf.uri_backend}api/v1/referensi/kd/select?jenjang_id=${this.state.active_menu}&ki_id=${e.value}`, {
          headers: {
            Authorization: tokenApi
          }
        }).then((res) => {
          this.setState({
            data_kd : res.data,
            sc_ki   : e.value
          });
          this.dt.loading();
          this.dt.fetchEntities(`&ki_id=${e.value}&jenjang_id=${this.state.active_menu}`);
        });
      })
    } else {
      this.setState({
        data_kd : '',
        sc_ki   : ''
      }, function(){
        this.dt.loading();
        this.dt.fetchEntities(`&mpk_id=${this.state.sc_mpk}&jenjang_id=${this.state.active_menu}`);
      });
    }
  }

  filterKd(e){
    if(e){
      this.setState({
        sc_kd : e.value,
      }, function(){
        this.dt.loading();
        this.dt.fetchEntities(`&kd_id=${e.value}&jenjang_id=${this.state.active_menu}`);
      })
    } else {
      this.setState({
        sc_kd : e?.value,
      }, function(){
        this.dt.loading();
        this.dt.fetchEntities(`&ki_id=${this.state.sc_ki}&jenjang_id=${this.state.active_menu}`);
      });
    }
  }

  // Select
  selectKmp() {
    if (this.state.data_kmp != '') {
      return (
        <div className="flex w-1/4">
          <div className="w-full">
            <div className="py-2 mr-4">
              <Select
                id="filter"
                isClearable={true}
                styles={selectStyles}
                options={this.state.data_kmp}
                onChange={this.filterKmp}
                placeholder={"Kelompok Mapel"}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  selectJkmp() {
    if (this.state.data_jkmp != '') {
      return (
        <div className="flex w-1/4">
          <div className="w-full">
            <div className="py-2 mr-4">
              <Select
                id="filter"
                isClearable={true}
                styles={selectStyles}
                className="animateFadeInLeft"
                options={this.state.data_jkmp}
                onChange={this.filterJkmp}
                placeholder={"Jenis Kelompok Pelajaran"}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  selectMp() {
    if (this.state.data_mp != '') {
      return (
        <div className="flex w-1/4">
          <div className="w-full">
            <div className="py-2 mr-4">
              <Select
                id="filter"
                isClearable={true}
                styles={selectStyles}
                className="animateFadeInLeft"
                options={this.state.data_mp}
                onChange={this.filterMp}
                placeholder= {"Mata Pelajaran"}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  selectMpk() {
    if (this.state.data_mpk != '') {
      return (
        <div className="flex w-1/4">
          <div className="w-full">
            <div className="py-2">
              <Select
                id="filter"
                isClearable={true}
                styles={selectStyles}
                className="animateFadeInLeft"
                options={this.state.data_mpk}
                onChange={this.filterMpk}
                placeholder= {"Kelas"}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  selectKi() {
    if (this.state.data_ki != '') {
      return (
        <div className="flex w-1/4">
          <div className="w-full">
            <div className="py-2 mr-4">
              <Select
                id="filter"
                isClearable={true}
                styles={selectStyles}
                className="animateFadeInLeft"
                options={this.state.data_ki}
                onChange={this.filterKi}
                placeholder= {"Kompetensi Inti"}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  selectKd() {
    if (this.state.data_kd != '') {
      return (
        <div className="flex w-1/4">
          <div className="w-full">
            <div className="py-2 mr-4">
              <Select
                id="filter"
                isClearable={true}
                styles={selectStyles}
                className="animateFadeInLeft"
                options={this.state.data_kd}
                onChange={this.filterKd}
                placeholder= {"Kompetensi Dasar"}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  getReset(e){
    e.preventDefault();
    this.setState({
      data_kmp  : '',
      data_jkmp : '',
      data_mp   : '',
      data_mpk  : '',
      data_ki   : '',
      data_kd   : '',
    }, function(){
      axios.get(`${conf.uri_backend}api/v1/referensi/kmp/select?jenjang_id=${this.state.active_menu}`, {
        headers : { Authorization: tokenApi }
      }).then(res => {
        this.setState({
          data_kmp     : res.data,
        })
      })
    })
    this.dt.loading();
    this.dt.fetchEntities(`&jenjang_id=${this.state.active_menu}`);
  }
}
