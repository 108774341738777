import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Modal, Carousel } from 'react-bootstrap';
import {Tab, Tabs} from 'react-bootstrap';
import SideBarJadwal from "./component/SideBarJadwal.js";
import Select from 'react-select';
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import conf from "./../../../config.js";
import { TombolKembali } from '../../partials/TombolKembali.js';
import Skeleton from 'react-loading-skeleton';
import SweetAlert from 'sweetalert2-react';

// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};
const selectKotak = {
  control: styles => ({ ...styles, minWidth: '15rem' }),
};
const selectPertemuan = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '6rem' }),
};


const dataKurikulum = [
  { value: "k13", label: "K13" },
  { value: "merdeka", label: "Merdeka" },
];

function captFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


const semester = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
];

const tempat = [
  { value: 'PAI', label: 'X TKJ A' },
  { value: 'Matematika', label: 'X RPL A' },
  { value: 'IPA', label: 'X RPL B' },
  { value: 'Perpustakaan', label: 'Perpustakaan' }
];
const hari = [
  { value: 'Senin', label: 'Senin' },
  { value: 'Selasa', label: 'Selasa' },
  { value: 'Rabu', label: 'Rabu' },
  { value: 'Kamis', label: 'Kamis' },
  { value: 'Jumat', label: 'Jumat' }
];
const jamke = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' }
];
const pertemuan = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' }
];
const kuota = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' }
];

export default class AturGuruRombel extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      url_get_rombel       : `${conf.uri_backend}api/v1/rombel/get_rombel`,
      url_get_mapel        : `${conf.uri_backend}api/v1/jadwal/mapel_per_rombel`,
      url_get_spektrum     : `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_guru         : `${conf.uri_backend}api/v1/jadwal/guru_mapel`,
      url_detail_mapel     : `${conf.uri_backend}api/v1/jadwal/detail_guru_rombel`,
      url_get_filter       : `${conf.uri_backend}api/v1/rombel/filter`,
      url_get_smk          : `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,
      url_get_ruang        : `${conf.uri_backend}api/v1/sarpras-m/sarpras/select_input/ruang`,
      url_create           : `${conf.uri_backend}api/v1/jadwal/create_guru_per_rombel`,
      url_check_update     : `${conf.uri_backend}api/v1/jadwal/check_mapel_guru`,
      tahunSekarang        : new Date().getFullYear(),
      // modal
      modal_pengampu       : false,
      modal_waktu          : false,
      // filter
      tahun_ajaran_search  : '',
      kelas_search         : '',
      peminatan_search     : '',
      rombel_search        : '',
      bidang_search        : '',
      program_search       : '',
      kompetensi_search    : '',
      kurikulum_search     : "",
      // data filter
      data_spektrum        : '',
      data_bidang          : '',
      data_program         : '',
      data_kompetensi      : '',
      data_rombel          : '',
      data_ruang           : '',
      data_mapel           : '',
      // countMapel
      count_agama          : '',
      count_bahasa         : '',
      count_l1             : '',
      count_l2             : '',
      count_pilihan_1      : '',
      count_pilihan_2      : '',
      count_pilihan        : '',
      // filter berurutan
      select_kelas         : false,
      select_rombel        : false,
      select_peminatan     : false,
      select_semester      : false,
      select_semua         : false,
      loading_show_data: false,
      loadingFilter        : true,
      // alert
      show_alert           : false,
      title_alert          : '',
      message_alert        : '',
      icon_alert           : '',
    }
    // get option filter
    this.getTahunAjaranNow        = this.getTahunAjaranNow.bind(this);
    this.getKelas                 = this.getKelas.bind(this);
    this.getRombel                = this.getRombel.bind(this);
    this.getPeminatanSMA          = this.getPeminatanSMA.bind(this);
    this.getKurikulum = this.getKurikulum.bind(this);
    this.getDataFilterSMA         = this.getDataFilterSMA.bind(this);
    this.getBidang                = this.getBidang.bind(this);
    this.getProgram               = this.getProgram.bind(this);
    this.getKompetensi            = this.getKompetensi.bind(this);
    this.getRuang                 = this.getRuang.bind(this);
    this.checkMapelMulti          = this.checkMapelMulti.bind(this);
    // filter and search
    this.filterTahunAjaran        = this.filterTahunAjaran.bind(this);
    this.filterKelas              = this.filterKelas.bind(this);
    this.filterRombel             = this.filterRombel.bind(this);
    this.filterPeminatan          = this.filterPeminatan.bind(this);
    this.filterBidang             = this.filterBidang.bind(this);
    this.filterKurikulum = this.filterKurikulum.bind(this);
    this.filterProgram            = this.filterProgram.bind(this);
    this.filterKompetensi         = this.filterKompetensi.bind(this);
    this.filterSemester           = this.filterSemester.bind(this);
    // show select filter
    this.showSelectFilterSD        = this.showSelectFilterSD.bind(this);
    this.showSelectFilterSMA       = this.showSelectFilterSMA.bind(this);
    this.showSelectFilterSMK       = this.showSelectFilterSMK.bind(this);
    this.showSelectFilterALL       = this.showSelectFilterALL.bind(this);
    this.showSelectFilterSemester  = this.showSelectFilterSemester.bind(this);
    
    this.loopPertemuan             = this.loopPertemuan.bind(this);
    this.loopPengampu              = this.loopPengampu.bind(this);
    this.checkPengampu             = this.checkPengampu.bind(this);
    this.showTablePengampu         = this.showTablePengampu.bind(this);

    this.setFilter                 = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
    this.showData                  = this.showData.bind(this);
    this.saveGuruRombel            = this.saveGuruRombel.bind(this);
    this.submitForm                = this.submitForm.bind(this);

    //modal pengampu
    this.showModalPengampu         = this.showModalPengampu.bind(this);
    this.hideModalPengampu         = this.hideModalPengampu.bind(this);
    this.cekFilterJenjang = this.cekFilterJenjang.bind(this);
  }

  componentDidMount() {
    // get session user
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ data_session: res.data }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({ 
              institusi_id_select : res.data.id,
              jenjang_id_select   : res.data.m_struktur_jenjang_id,
              npsn_id_select      : res.data.npsn,
            }, function(){});
              this.getTahunAjaranNow();
              this.getDataFilterSMA();
            });
        });
      });
    }
  }

  alertMessage(e){
    let message = null;

    switch(e.status){
      case 422:
        message = e.data.messages[0];
      break;
      case 500:
        message= "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
      break;
      case 401:
        message = "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 400:
        message = "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 408:
        message = "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 429:
        message = "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
    }

    this.setState({
      show_alert    : true,
      title_alert   : "Gagal",
      icon_alert    : "error",
      message_alert : message,
      simpleLoading: false,
    })
  }

  
  getTahunAjaranNow() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  getKelas() {
    var kelas = [];
    if (this.state.jenjang_id_select == "2") {
      kelas = [
        { value: "I", label: "I" },
        { value: "II", label: "II" },
        { value: "III", label: "III" },
        { value: "IV", label: "IV" },
        { value: "V", label: "V" },
        { value: "VI", label: "VI" },
      ];
    } else if (this.state.jenjang_id_select == "3") {
      kelas = [
        { value: "VII", label: "VII" },
        { value: "VIII", label: "VIII" },
        { value: "IX", label: "IX" },
      ];
    } else if (this.state.jenjang_id_select == "4") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
      ];
    } else if (this.state.jenjang_id_select == "5") {
      kelas = [
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
        { value: "XIII", label: "XIII" },
      ];
    }
    this.setState({ data_kelas: kelas });
  }
  
  getKurikulum() {
    this.setState({ data_kurikulum: dataKurikulum });
  }

  getPeminatanSMA() {
    this.setState({ loading_peminatan: !this.state.loading_peminatan });
    if (this.state.jenjang_id_select == "4") {
      axios
        .get(
          this.state.url_get_spektrum +
            "?jenjang=" +
            this.state.jenjang_id_select +
            "&kurikulum=" +
            this.state.kurikulum_search,
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          if (res.data.status == true) {
            this.setState({ data_spektrum: res.data.data });
            this.setState({ loading_peminatan: false });
          } else {
            this.setState({ data_spektrum: [] });
            this.setState({ loading_peminatan: false });
          }
        });
    }
  }

  getRombel() {
    this.setState({ loading_rombel: !this.state.loading_rombel });
    var peminatan = "";
    if (
      this.state.jenjang_id_select == "5"
        ? (peminatan = this.state.kompetensi_search)
        : (peminatan = this.state.peminatan_search)
    );
    if (this.state.kurikulum_search == null) {
      this.setState({
        peminatan_search: "",
      });
    }
    axios
      .get(
        `${this.state.url_get_rombel}?tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&peminatan=${peminatan}&user_institusi_id=${this.state.institusi_id_select}&kurikulum=${this.state.kurikulum_search}`,
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({
          data_rombel: res.data,
          loadingFilter: false,
          loading_rombel: false,
        });
      });
  }

  // SMK
  getBidang(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_bidang: !this.state.loading_bidang });
      axios
        .get(`${this.state.url_get_smk}?parent_id=0&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`, {
          headers: { Authorization: tokenApi },
        })
        .then((res) => {
          if (create != null) {
            this.setState({ data_bidang_p: res.data });
          } else {
            this.setState({ data_bidang: res.data });
            this.setState({ loading_bidang: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_bidang: false });
        });
    }
  }

  getProgram(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_program: !this.state.loading_program });
      var bidang = "";
      if (
        create != null
          ? (bidang = this.state.bidang)
          : (bidang = this.state.bidang_search)
      );
      axios
        .get(`${this.state.url_get_smk}?parent_id=${bidang}&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`, {
          headers: { Authorization: tokenApi },
        })
        .then((res) => {
          if (create != null) {
            this.setState({ data_program_p: res.data });
          } else {
            this.setState({ data_program: res.data });
            this.setState({ loading_program: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_program: false });
        });
    }
  }

  getKompetensi(create) {
    if (this.state.jenjang_id_select == 5) {
      this.setState({ loading_kompetensi: !this.state.loading_kompetensi });
      var program = "";
      if (
        create != null
          ? (program = this.state.program)
          : (program = this.state.program_search)
      );
      axios
        .get(`${this.state.url_get_smk}?parent_id=${program}&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`, {
          headers: { Authorization: tokenApi },
        })
        .then((res) => {
          if (create != null) {
            this.setState({ data_kompetensi_p: res.data });
          } else {
            this.setState({ data_kompetensi: res.data });
            this.setState({ loading_kompetensi: false });
          }
        })
        .catch((err) => {
          this.setState({ loading_kompetensi: false });
        });
    }
  }

  getDataFilterSMA() {
    axios
      .get(this.state.url_get_filter + "/" + this.state.institusi_id_select, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        if (res.data.status == true) {
          this.setState(
            {
              tahun_ajaran_search: res.data.data.tahun_ajaran != null ? res.data.data.tahun_ajaran : "",
              kelas_search: res.data.data.kelas !== null ? res.data.data.kelas : "",
              peminatan_search: res.data.data.kompetensi_id !== null ? res.data.data.kompetensi_id : "",
              peminatan_search_label: res.data.data.kompetensi !== null ? res.data.data.kompetensi : "",
              bidang_search: res.data.data.bidang_id !== null ? res.data.data.bidang_id : "",
              bidang_search_label: res.data.data.bidang !== null ? res.data.data.bidang : "",
              program_search: res.data.data.program_id !== null ? res.data.data.program_id : "",
              program_search_label: res.data.data.program !== null ? res.data.data.program : "",
              kompetensi_search: res.data.data.kompetensi_id !== null ? res.data.data.kompetensi_id : "",
              kompetensi_search_label: res.data.data.kompetensi !== null ? res.data.data.kompetensi : "",
              rombel_search: res.data.data.rombel_id !== null ? res.data.data.rombel_id : "",
              rombel_search_label: res.data.data.rombel !== null ? res.data.data.rombel : "",
              semester_search: res.data.data.semester !== null ? res.data.data.semester : "",
              kurikulum_search: res.data.data.kurikulum !== null ? res.data.data.kurikulum : "",
            },
            function() {
              this.getTahunAjaranNow();
              this.getKelas();
              this.getKurikulum();
              this.getPeminatanSMA();
              this.getRombel();
              this.getBidang();
              this.getProgram();
              this.getKompetensi();
              this.getRuang();
            }
          );
        } else {
          this.setState({ loadingFilter : false });
        }
      });
  }

  getRuang(){
    axios.get(`${this.state.url_get_ruang}?institusi_id=${this.state.institusi_id_select}`, {headers:{Authorization:tokenApi}})
      .then( res => {
        if(res.data.status == true){
          this.setState({data_ruang: res.data.data})
        }
      })
  }
  
  // SHOW TYPE FILTER PER JENJANG
  showSelectFilterSD() {
    if  (this.state.loadingFilter == false && this.state.data_tahun_ajaran ) {
      return (
        <>
          <div className="flex w-1/4">
            <div className="py-2 mr-2">
              <Select
                id="filterTahunAjaran"
                styles={selectStyles}
                isDisabled={this.state.disabledFilter}
                defaultValue={
                  this.state.tahun_ajaran_search != ""
                    ? {
                        value: this.state.tahun_ajaran_search,
                        label: this.state.tahun_ajaran_search,
                      }
                    : ""
                }
                options={this.state.data_tahun_ajaran}
                onChange={this.filterTahunAjaran}
                placeholder={"Pilih Tahun Ajaran"}
              />
            </div>
          </div>
          {this.state.data_kelas && this.state.tahun_ajaran_search !== "" ? (
            <div className="flex w-1/4">
              <div className="py-2 mr-2">
                <Select
                  id="filterKelas"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.kelas_search != ""
                      ? {
                          value: this.state.kelas_search,
                          label: this.state.kelas_search,
                        }
                      : ""
                  }
                  options={this.state.data_kelas}
                  onChange={this.filterKelas}
                  placeholder={"Pilih Kelas"}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  showSelectFilterKurikulum() {
    if (this.state.loadingFilter == false && this.state.data_kurikulum != "" &&  this.state.kelas_search != "") {
      return (
        <>
          <div className="flex w-1/4">
            <div className="py-2 mr-2">
              <Select
                styles={selectStyles}
                className="react-select__control"
                placeholder="Pilih Kurikulum"
                isDisabled={this.state.disabledFilter}
                // isMulti
                defaultValue={
                  this.state.kurikulum_search != ""
                    ? {
                        value: this.state.kurikulum_search,
                        label: captFirstLetter(this.state.kurikulum_search),
                      }
                    : ""
                }
                options={this.state.data_kurikulum}
                onChange={this.filterKurikulum}
              />
            </div>
          </div>
        </>
      );
    } else {
      
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  showSelectFilterSMA() {
    if (this.state.loadingFilter == false && this.state.jenjang_id_select == "4" ) {
      if (this.state.data_spektrum && this.state.kelas_search !== "" && this.state.kurikulum_search !== "") {
        return (
          <>
            <div className="flex w-1/4">
              <div className="py-2 mr-2">
                <Select
                  id="filterPeminatan"
                  styles={selectStyles}
                  isLoading={this.state.loading_peminatan ? true : false}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.peminatan_search != ""
                      ? {
                          value: this.state.peminatan_search,
                          label: this.state.peminatan_search_label,
                        }
                      : ""
                  }
                  options={this.state.data_spektrum}
                  onChange={this.filterPeminatan}
                  placeholder={"Pilih Peminatan"}
                />
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div className="w-1/4 px-1">
            <div className="py-2">
              <Skeleton count={2} />
            </div>
          </div>
        );
      }
    } 
  }

  showSelectFilterSMK() {
    if (this.state.loadingFilter == false && this.state.jenjang_id_select == "5") {
      return (
        <>
          {this.state.kelas_search != "" ? (
            <div className="flex w-1/4">
              <div className="py-2 ml-2">
                <Select
                  id="filterBidang"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.bidang_search != ""
                      ? {
                          value: this.state.bidang_search,
                          label: this.state.bidang_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_bidang ? true : false}
                  options={this.state.data_bidang}
                  onChange={this.filterBidang}
                  className="animateFadeInLeft"
                  placeholder={
                    this.state.loading_bidang ? "Loading" : "Bidang Keahlian"
                  }
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {this.state.bidang_search != "" ? (
            <div className="flex w-1/4">
              <div className="py-2 ml-2">
                <Select
                  id="filterProgram"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.program_search != ""
                      ? {
                          value: this.state.program_search,
                          label: this.state.program_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_program ? true : false}
                  options={this.state.data_program}
                  onChange={this.filterProgram}
                  placeholder={
                    this.state.loading_program ? "Loading" : "Program Keahlian"
                  }
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.program_search != "" ? (
            <div className="flex w-1/4">
              <div className="py-2 mr-2">
                <Select
                  id="filterKompetensi"
                  styles={selectStyles}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={
                    this.state.kompetensi_search != ""
                      ? {
                          value: this.state.kompetensi_search,
                          label: this.state.kompetensi_search_label,
                        }
                      : ""
                  }
                  isLoading={this.state.loading_kompetensi ? true : false}
                  options={this.state.data_kompetensi}
                  onChange={this.filterKompetensi}
                  placeholder={
                    this.state.loading_kompetensi
                      ? "Loading"
                      : "Kompetensi Keahlian"
                  }
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  showSelectFilterALL() {
    if (this.state.loadingFilter == false && this.state.data_rombel && this.cekFilterJenjang('peminatan', this.state.peminatan_search) && this.cekFilterJenjang('kompetensi', this.state.kompetensi_search)) {
      return (
        <>
          <div className="flex w-1/4">
            <div className="py-2 mr-2">
              <Select
                id="filterRombel"
                styles={selectStyles}
                isLoading={this.state.loading_rombel ? true : false}
                isDisabled={this.state.disabledFilter}
                defaultValue={
                  this.state.rombel_search != ""
                    ? {
                        value: this.state.rombel_search,
                        label: this.state.rombel_search_label,
                      }
                    : ""
                }
                options={this.state.data_rombel}
                onChange={this.filterRombel}
                placeholder={"Pilih Rombel"}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  showSelectFilterSemester() {
    if (this.state.loadingFilter == false && this.state.rombel_search != "") {
      return (
        <div className="flex w-1/4">
          <div className="py-2 ml-2 mr-2">
            <Select
              id="filterSemester"
              styles={selectStyles}
              isDisabled={this.state.disabledFilter}
              defaultValue={
                this.state.semester_search != ""
                  ? {
                      value: this.state.semester_search,
                      label: this.state.semester_search,
                    }
                  : ""
              }
              options={semester}
              onChange={this.filterSemester}
              placeholder={"Pilih Semester"}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2} />
          </div>
        </div>
      );
    }
  }

  cekFilterJenjang(type, value) {
    var cek = true;
    if (this.state.jenjang_id_select == 4) {
      if (type == "peminatan" && value == "")
        cek = false;
    } 
    
    if (this.state.jenjang_id_select == 5) {
      if (type == "bidang" && value == "")
        cek = false;
      if (type == "program" && value == "")
        cek = false;
      if (type == "kompetensi" && value == "")
        cek = false;
    } 

    return cek;
  }

  // FILTER HANDLE CHANGE FUNCTION
  filterTahunAjaran(e) {
    if (e) {
      this.setState(
        {
          tahun_ajaran_search: e.value,
          kelas_search: "",
          kurikulum_search: "",
          peminatan_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_spektrum: "",
          data_bidang: "",
          data_program: "",
          data_kelas: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getKelas();
        }
      );
    } else {
      this.setState({
        tahun_ajaran_search: "",
        kelas_search: "",
        kurikulum_search: "",
        peminatan_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_spektrum: "",
        data_kelas: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      }, function() {
        this.getKelas();
      });
    }
  }
  filterKurikulum(e) {
    if (e) {
      this.setState(
        {
          kurikulum_search: e.value,
          peminatan_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_spektrum: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
        },
        function() {
          if (this.state.jenjang_id_select == 2 || this.state.jenjang_id_select == 3)
            this.getRombel();
          else if (this.state.jenjang_id_select == 4)
            this.getPeminatanSMA();
          else if (this.state.jenjang_id_select == 5)
            this.getBidang();
        }
      );
    } else {
      this.setState({
        kurikulum_search: "",
        peminatan_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_spektrum: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
      });
    }
  }

  filterKelas(e) {
    if (e) {
      this.setState(
        {
          kelas_search: e.value,
          kurikulum_search: "",
          peminatan_search: "",
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_kurikulum: "",
          data_spektrum: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getKurikulum();
        }
      );
    } else {
      this.setState({
        kelas_search: "",
        kurikulum_search: "",
        peminatan_search: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_kurikulum: "",
        data_spektrum: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }

  filterSemester(e) {
    if (e) {
      this.setState({ semester_search: e.value, loading_show_data: false });
    } else {
      this.setState({ semester_search: "" });
    }
  }

  filterPeminatan(e) {
    //Only SMA
    if (e) {
      this.setState(
        {
          peminatan_search: e.value,
          peminatan_search_label: e.label,
          bidang_search: "",
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_bidang: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getRombel();
        }
      );
    } else {
      this.setState({
        peminatan_search: "",
        peminatan_search_label: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_bidang: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }

  // only for SMK
  filterBidang(e) {
    if (e) {
      this.setState(
        {
          bidang_search: e.value,
          bidang_search_label: e.label,
          program_search: "",
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_program: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getProgram();
        }
      );
    } else {
      this.setState({
        bidang_search: "",
        bidang_search_label: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        semester_search: "",
        data_program: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }
  filterProgram(e) {
    if (e) {
      this.setState(
        {
          program_search: e.value,
          program_search_label: e.label,
          kompetensi_search: "",
          rombel_search: "",
          semester_search: "",
          data_kompetensi: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getKompetensi();
        }
      );
    } else {
      this.setState({
        program_search: "",
        program_search_label: "",
        rombel_search: "",
        semester_search: "",
        kompetensi_search: "",
        data_kompetensi: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }
  filterKompetensi(e) {
    if (e) {
      this.setState(
        {
          kompetensi_search: e.value,
          kompetensi_search_label: e.label,
          rombel_search: "",
          semester_search: "",
          data_rombel: "",
          loading_show_data: true,
        },
        function() {
          this.getRombel();
        }
      );
    } else {
      this.setState({
        kompetensi_search: "",
        kompetensi_search_label: "",
        rombel_search: "",
        semester_search: "",
        data_rombel: "",
        loading_show_data: true,
      });
    }
  }

  filterRombel(e) {
    if (e) {
      this.setState({
        rombel_id: e.value,
      });
      this.setState({
        rombel_search: e.value,
        rombel_search_label: e.label,
        semester_search: "",
        loading_show_data: false,
      });
    } else {
      this.setState({
        rombel_search: "",
        rombel_search_label: "",
        semester_search: "",
        loading_show_data: false,
      });
    }
  }

  checkMapelMulti(mapel){
    axios.get(`${this.state.url_check_update}?rombel=${this.state.rombel_search}&mapel=${mapel}&semester=${this.state.semester_search}`, {headers:{Authorization:tokenApi}})
      .then( res => {
        if(mapel == "AGAMA"){
          this.setState({ checkAgama: res.data.status});
        };

        if(mapel == "LINTAS MINAT 1"){
          this.setState({ checkL1: res.data.status});
        };

        if(mapel == "LINTAS MINAT 2"){
          this.setState({ checkL2: res.data.status});
        };

        if(mapel == "MAPEL PILIHAN 1") {
          this.setState({checkPilihan1: res.data.status})
        }

        if(mapel == "MAPEL PILIHAN 2") {
          this.setState({checkPilihan2: res.data.status})
        }

        if(mapel == "PILIHAN") {
          this.setState({ checkPilihan: res.data.status});
        };
      })
  }


  //  List MataPelajaran
  setFilter(){
    var kompetensi       = '';
    var kompetensi_label = '';

    if(this.state.jenjang_id_select == '5'){
      kompetensi       = this.state.kompetensi_search;
      kompetensi_label = this.state.kompetensi_search_label;
    } else {
      kompetensi       = this.state.peminatan_search;
      kompetensi_label = this.state.peminatan_search_label;
    }

    axios.put(this.state.url_get_filter+'/'+this.state.institusi_id_select, {
      tahun_ajaran  : this.state.tahun_ajaran_search,
      kelas         : this.state.kelas_search,
      bidang        : this.state.bidang_search_label,
      bidang_id     : this.state.bidang_search,
      program       : this.state.program_search_label,
      program_id    : this.state.program_search,
      kompetensi    : kompetensi_label,
      kompetensi_id : kompetensi,
      rombel_id     : this.state.rombel_search,
      rombel        : this.state.rombel_search_label,
      semester      : this.state.semester_search,
    }, {headers:{Authorization:tokenApi}});

    this.setState({ disabledFilter:true, loadingMapel : true });
    

    this.checkMapelMulti("AGAMA");
    if(this.state.kurikulum_search == 'k13') {
      this.checkMapelMulti("LINTAS MINAT 1");
      this.checkMapelMulti("LINTAS MINAT 2");
    } else {
      if(this.state.kelas_search == "XI" || this.state_kelas_search == "XII") {
        this.checkMapelMulti("MAPEL PILIHAN 1");
        this.checkMapelMulti("MAPEL PILIHAN 2")
      }
      // this.checkMapelMulti("PILIHAN");
    }
    

    axios.post(`${this.state.url_get_mapel}`, {
      rombel: this.state.rombel_search,
      semester: this.state.semester_search,
    }, {headers:{Authorization:tokenApi}})
    .then( res => {
      this.setState({
        data_mapel     : res.data.data,
        disabledFilter : false,
        loadingMapel   : false,
        count_agama    : res.data.count.agama,
        count_bahasa   : res.data.count.bahasa,
        count_l1       : res.data.count.lintas_minat_1,
        count_l2       : res.data.count.lintas_minat_2,
        count_pilihan_1: res.data.count.mapel_pilihan_1,
        count_pilihan_2: res.data.count.mapel_pilihan_2,
        count_pilihan  : res.data.count.pilihan,
      });

    });
  }

  setResetFilter(e) {
    e.preventDefault();
    this.setState(
      {
        data_tahun_ajaran: null,
        data_kelas: null,
        data_spektrum: null,
        data_rombel: null,
        searchText: "",
        kelas_search: "",
        peminatan_search: "",
        peminatan_search_label: "",
        bidang_search: "",
        program_search: "",
        kompetensi_search: "",
        rombel_search: "",
        rombel_search_label: "",
        tahun_ajaran_search: "",
        semester_search: "",
        disabledFilter: false,
      },
      function() {
        this.getTahunAjaranNow();
        this.setState({ loading_filter : false })
      }
    );
  }

  loopPertemuan(id, kuota){
    var u = 1;
    var options = [];
    for(var i = 0; i < kuota; i++){
      options.push(<option value={u}>{u}</option>);
      u++;
    }
    return(
      <select id={"pertemuan-"+id} onChange={this.changePertemuan}>
        {options}
      </select>
    )
  }

  checkPengampu(id, jkmp, indeks_mpk, kuota, type){
    var multi_id = [];
    var rombel_guru_id = [];
    axios.get(`${this.state.url_detail_mapel}?type=${type}&semester=${this.state.semester_search}&rombel_id=${this.state.rombel_search}&mapel=${id}&institusi=${this.state.institusi_id_select}&tahun_ajaran=${this.state.tahun_ajaran_search}`, {headers:{Authorization:tokenApi}})
      .then( res => {
        if(res.data.status == true){// jika data sudah di edit dan mapel multi
          if(type == 'AGAMA' || type == "LINTAS MINAT 1" || type == "LINTAS MINAT 2" || type == "MAPEL PILIHAN 1" || type == "MAPEL PILIHAN 2"){ 
            for(var i = 0; i<res.data.mp.length; i++){
              multi_id = [];
              for(var u = 0; u < res.data.data.length; u++){
                if(res.data.data[u].m_struktur_mpk_id == res.data.mp[i].id){
                  multi_id.push(res.data.data[u].mapel_multi);
                }
              }
              rombel_guru_id.push(multi_id);
            }

            this.setState({rombel_guru_id : rombel_guru_id}, function(){
              this.showEdit(res.data, type);
            });
            
          } else {
            res.data.data.map((guru, index) => {
              rombel_guru_id.push(guru.rombel_guru_id);
            })

            this.setState({ rombel_guru_id: rombel_guru_id }, function(){
              this.showEdit(res.data.data, type);
            })
          }
        } else {// jika data belum di edit dan bukan mapel multi
          this.loopPengampu(id, jkmp, indeks_mpk, kuota, type);
        }
      });
  }

  showEdit(data, type){
    var table = [];
    var td = [];
    var u = 1;
    this.setState({ status: type });

    if(type == 'AGAMA' || type == "LINTAS MINAT 1" || type == "LINTAS MINAT 2" || type ==  "MAPEL PILIHAN 1" || type == "MAPEL PILIHAN 2"){
      var tabs = [];
      var items = [];
      var itemnya = [];

      for(var i=0; i<data.mp.length; i++){
        items = [];
        for(var z = 0; z < data.data.length; z++){
          if(data.data[z].m_struktur_mpk_id == data.mp[i].id){
            if(data.data[z].data_guru != null && data.data[z].data_guru.length != 0){
              items.push(
                <tr>
                  <td>
                    <div className="text-sm">
                      {u}
                    </div>
                  </td>
                  <td>
                    <select className="w-full px-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 text-sm" name={data.data[z].m_struktur_mpk_id+"-guru[]"}>
                      {data.data[z].data_guru != null ? (
                        data.data[z].data_guru.map((guru, index) => {
                          return(<option value={guru.value} selected={guru.value == data.data[z].penugasan_guru_mapel_id ? true : false }>{guru.label}</option>)
                        })
                      ) : ''}
                    </select>
                  </td>
                  <td><div className="text-sm">{data.data[z].indeks_mpk}-<span className="lowercase">{data.data[z].nama_guru != null ? data.data[z].nama_guru.substr(0, 3) : ''}</span></div></td>
                  <td>
                    <select className="w-full px-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 text-sm" name={data.data[z].m_struktur_mpk_id+"-tempat[]"}>
                      {data.data[z].tempatnya != null ? (
                        data.data[z].tempatnya.map((tempat, index) => {
                          return(<option value={tempat.value} selected={tempat.value == data.data[z].sarpras_ruang_id ? true : false }>{tempat.label}</option>)
                        })
                      ) : ''}
                    </select>  
                  </td>
                </tr>
              )
            } 
            u++;
          } 
        }
        itemnya.push(items);
      }

      for(var i=0; i<data.mp.length; i++){
        tabs.push(
          <Tab eventKey={data.mp[i].mp} title={data.mp[i].mp}>
            <form id={"mapel-"+data.mp[i].id}>
              <table className="table table-bordered font-thin text-sm">
                <thead className="text-center">
                  <tr>
                    <th>Pertemuan Ke</th>
                    <th>Guru Pengajar</th>
                    <th>Indeks MPK</th>
                    <th>Nama Tempat</th>
                  </tr>
                </thead>
                <tbody>
                  {itemnya[i] != null && itemnya[i].length != 0 ? itemnya[i] : (
                    <tr className="text-center"><td colSpan="4">Tidak ada guru yang mengajar pada mapel ini.</td></tr>
                  )}
                </tbody>
              </table>
            </form>
            {itemnya[i] != null && itemnya[i].length != 0 ? (
              <button onClick={this.submitForm.bind(this, data.mp[i].id, i)} className="btn-schoolmedia mx-1 px-4 justify-center font-medium rounded-full text-white items-center py-2 text-sm">
                <i className="bx bx-save text-lg mr-1"></i>
                <span className="text-md">Simpan</span>
              </button>
            ) : ""}
          </Tab>
        )
      }

      table.push(
        <div className="w-full">
          <Tabs defaultActiveKey={data.mp[0].mp} id="uncontrolled-tab-example" className="px-6">
            {tabs}
          </Tabs>
        </div>
      )

      this.setState({ atur:table }, function(){
        this.showModalPengampu();
      });
      
    } else {
      
      this.setState({ mpk_id:data[0].mpk_id });

      for(var i=0; i<data.length; i++){
        td.push(
          <tr>
            <td>
              <div className="text-sm">
                {u}
              </div>
            </td>
            <td>
              <select className="w-full px-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 text-sm" name="guru[]">
                {data[i].data_guru != null ? (
                  data[i].data_guru.map((guru, index) => {
                    return(<option value={guru.value} selected={guru.value == data[i].penugasan_guru_mapel_id ? true : false }>{guru.label}</option>)
                  })
                ) : ''}
              </select>
            </td>
            <td><div className="text-sm">{data[i].indeks_mpk}-<span className="lowercase">{data[i].nama_guru.substr(0, 3)}</span></div></td>
            <td>
              <select className="w-full px-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 text-sm" name="tempat[]">
                {data[i].tempatnya != null ? (
                  data[i].tempatnya.map((tempat, index) => {
                    return(<option value="awdawd" selected={tempat.value == data[i].sarpras_ruang_id ? true : false }>{tempat.label}</option>)
                  })
                ) : ""}
              </select>  
            </td>
          </tr>
        )
        u++;
      }

      table.push(
        <form onSubmit={this.saveGuruRombel}>
          <table className="table table-bordered font-thin text-sm">
            <thead className="text-center">
              <tr>
                <th>Pertemuan Ke</th>
                <th>Guru Pengajar</th>
                <th>Indeks Guru MPK</th>
                <th>Nama Tempat</th>
              </tr>
            </thead>
            <tbody>
              {td}
            </tbody>
          </table>
          <button className="btn-schoolmedia mx-1 px-4 justify-center font-medium rounded-full text-white items-center py-2 text-sm">
            <i className="bx bx-save text-lg mr-1"></i>
            <span className="text-md">Simpan</span>
          </button>
        </form>
      )

      this.setState({ atur:table }, function(){
        this.showModalPengampu();
      });
    }
  }

  loopPengampu(id, jkmp, indeks_mpk, kuota, type){
    var jumlah  = kuota;
    var guru    = [];
    var gedung  = [];
    var u       = 0;
    this.setState({status: type});

    //looping option gedung
    this.state.data_ruang.map((ruang, index) => {
      gedung.push(<option value={ruang.value}>{ruang.label}</option>)
    });

    if(type == 'AGAMA' || type == "LINTAS MINAT 1" || type == "LINTAS MINAT 2" || type == "MAPEL PILIHAN 1" || type == "MAPEL PILIHAN 2"){
      var mapel = [];
      var gurus = [];
      axios.post(`${this.state.url_get_mapel}`, {
        rombel: this.state.rombel_search,
        semester: this.state.semester_search,
        type: type,
        tahun_ajaran: this.state.tahun_ajaran_search,
        institusi: this.state.institusi_id_select,
      }, {headers:{Authorization:tokenApi}})
      .then( res => {
        for(var i = 0; i < res.data.data.length; i++){
          if(res.data.data[i].type_mapel == type ? mapel.push(res.data.data[i]) : '');
        }
        if(mapel != null){
          for(let i=0 ; i<mapel.length ; i++) {
            guru = [];
            console.log(mapel[i].guru)
            for(let j=0 ; j<mapel[i].guru.length ; j++) {
              const penugasan_guru = mapel[i].guru[j]
              guru.push(<option value={penugasan_guru.value}>{penugasan_guru.label}</option>)
            }
            gurus.push(guru)
            this.showTablePengampu(id, jkmp, jumlah, gurus, mapel, gedung, type);
          }

          // for(var i = 0; i < mapel.length; i++){
          //   var id_mapel = mapel[i].id;
          //   axios.post(`${this.state.url_get_guru}`, {
          //     mapel: mapel[i].id,
          //     rombel: this.state.rombel_search,
          //     semester: this.state.semester_search,
          //     tahun_ajaran: this.state.tahun_ajaran_search,
          //     institusi: this.state.institusi_id_select,
          //   }, {headers:{Authorization:tokenApi}})
          //   .then( res => {
          //     guru    = [];
          //     // looping option guru
          //     res.data.map((pengampu, index) => {
          //       guru.push(<option value={pengampu.value}>{pengampu.label}</option>)
          //     });
          //     gurus.push(guru);
          //     this.showTablePengampu(id, jkmp, jumlah, gurus, mapel, gedung, type);
          //   });
          // }
        }
      });
    } else {
      axios.post(`${this.state.url_get_guru}`, {
        mapel        : id,
        rombel       : this.state.rombel_search,
        semester     : this.state.semester_search,
        tahun_ajaran : this.state.tahun_ajaran_search,
        institusi    : this.state.institusi_id_select,
      }, {headers:{Authorization:tokenApi}})
      .then( res => {
        this.setState({ pengampuGuru : res.data }, function(){
  
          // looping option guru
          this.state.pengampuGuru.map((pengampu, index) => {
            guru.push(<option value={pengampu.value}>{pengampu.label}</option>)
          });
  
          this.showTablePengampu(id, jkmp, jumlah, guru, indeks_mpk, gedung, type);
        });
      });
    }
  }

  saveGuruRombel(e){
    e.preventDefault();
    
    var guru    = document.getElementsByName('guru[]');
    var tempat  = document.getElementsByName('tempat[]');
    var gedungs = [];
    var gurus   = [];
    
    for (var i = 0, n = guru.length; i<n; i++){
      gurus.push(guru[i].value);
    }

    for (var i = 0, n = tempat.length; i<n; i++){
      gedungs.push(tempat[i].value);
    }

    axios.post(`${this.state.url_create}`, {
      rombel   : this.state.rombel_search,
      id       : (this.state.rombel_guru_id != null ? this.state.rombel_guru_id : ''),
      sarpras  : gedungs,
      guru     : gurus,
      mpk_id   : this.state.mpk_id,
      semester : this.state.semester_search,
      status   : this.state.status,
      created_by : this.state.institusi_id_select
    }, {headers:{Authorization:tokenApi}})
    .then(res => {
      this.setState({ 
        show_alert    : true,
        title_alert   : "Berhasil",
        icon_alert    : "success",
        message_alert : res.data.messages[0],
      });
    })
    .catch(err => {
      this.alertMessage(err.response)
    })
  }

  submitForm(id, index){
     if(index != null){
      var id_multi = this.state.rombel_guru_id;
     } else {
       var id_multi = '';
     }
    // console.log("id="+id+" index="+index+" datanya=" );
    // console.log(id[index]);
    var table      = document.getElementById('mapel-'+id)
    var guru       = table.elements.namedItem(id+'-guru[]');
    var tempat     = table.elements.namedItem(id+'-tempat[]');
    var gedungs    = [];
    var gurus      = [];

    for (var i = 0, n = guru.length; i<n; i++){
      gurus.push(guru[i].value);
    }

    for (var i = 0, n = tempat.length; i<n; i++){
      gedungs.push(tempat[i].value);
    }

    axios.post(`${this.state.url_create}`, {
      id       : (id_multi[index] != null ? id_multi[index] : ''),
      rombel   : this.state.rombel_search,
      sarpras  : gedungs,
      guru     : gurus,
      mpk_id   : id,
      semester : this.state.semester_search,
      status   : this.state.status,
      created_by : this.state.institusi_id_select
    }, {headers:{Authorization:tokenApi}})
    .then(res => {
      this.setState({ 
        show_alert    : true,
        title_alert   : "Berhasil",
        icon_alert    : "success",
        message_alert : res.data.messages[0],
      });
    })
    .catch(err => {
      this.alertMessage(err.response)
    })

  }

  showTablePengampu(id, jkmp, jumlah, guru, indeks_mpk, gedung, type){
    var items = [];
    var table = [];
    var u = 1;
    this.setState({ mpk_id:id });

    if(type == "AGAMA" || type == "LINTAS MINAT 1" || type == "LINTAS MINAT 2" || type == "MAPEL PILIHAN 1" || type == "MAPEL PILIHAN 2"){
      var itemnya = [];
      for(var i = 0; i < indeks_mpk.length; i++){
        items = [];
        if(guru[i] != null && guru[i] != '' && guru[i].length != 0){
          u = 1;
          for(var z = 0; z < jumlah; z++){
            items.push(
              <tr>
                <td>
                  <div className="text-sm">
                    {u}
                  </div>
                </td>
                <td>
                  <select className="w-full px-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 text-sm" name={indeks_mpk[i].id+"-guru[]"}>
                    {guru[i]}
                  </select>
                </td>
                <td><div className="text-sm">{indeks_mpk[i].indeks_mpk}</div></td>
                <td>
                  <select className="w-full px-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 text-sm" name={indeks_mpk[i].id+"-tempat[]"}>
                    {gedung}
                  </select>  
                </td>
              </tr>
            )
            u++;
          }
        }
        itemnya.push(items);
      }
    } else {
      if(guru != null && guru != '' && guru.length != 0){
        for(var i = 0; i < jumlah; i++){
          items.push(
            <tr>
              <td>
                <div className="text-sm">
                  {u}
                </div>
              </td>
              <td>
                <select className="w-full px-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 text-sm" name="guru[]">
                  {guru}
                </select>
              </td>
              <td><div className="text-sm">{indeks_mpk}</div></td>
              <td>
                <select className="w-full px-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 text-sm" name="tempat[]">
                  {gedung}
                </select>  
              </td>
            </tr>
          )
          u++;
        }
      }
    }
      
    if(items){
      if(type == "AGAMA" || type == "LINTAS MINAT 1" || type == "LINTAS MINAT 2" || type == 'MAPEL PILIHAN 1' || type == "MAPEL PILIHAN 2"){
        var tab = [];
        for(var i = 0; i<indeks_mpk.length; i++){
          tab.push(
            <Tab eventKey={indeks_mpk[i].mp} title={indeks_mpk[i].mp}>
              <form id={"mapel-"+indeks_mpk[i].id}>
                <table className="table table-bordered font-thin text-sm">
                  <thead className="text-center">
                    <tr>
                      <th>Pertemuan Ke</th>
                      <th>Guru Pengajar</th>
                      <th>Indeks MPK</th>
                      <th>Nama Tempat</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemnya[i] != null && itemnya[i].length != 0 ? itemnya[i] : (<tr className="text-center"><td colSpan="4">Tidak ada guru yang mengajar pada mapel ini.</td></tr>)}
                  </tbody>
                </table>
              </form>
              {itemnya[i] != null && itemnya[i].length != 0 ? (
                <button onClick={this.submitForm.bind(this, indeks_mpk[i].id, null)} className="btn-schoolmedia mx-1 px-4 justify-center font-medium rounded-full text-white items-center py-2 text-sm">
                  <i className="bx bx-save text-lg mr-1"></i>
                  <span className="text-md">Simpan </span>
                </button>
              ) : ("")}
            </Tab>
          )
        }
        table.push(
          <div className="w-full">
            <Tabs defaultActiveKey={indeks_mpk[0].mp} id="uncontrolled-tab-example" className="px-6">
              {tab}
            </Tabs>
          </div>
        )
      } else {
        table.push(
          <form onSubmit={this.saveGuruRombel}>
            <table className="table table-bordered font-thin text-sm">
              <thead className="text-center">
                <tr>
                  <th>Pertemuan Ke</th>
                  <th>Guru Pengajar</th>
                  <th>Indeks Guru MPK</th>
                  <th>Nama Tempat</th>
                </tr>
              </thead>
              <tbody>
                {items != null && items.length != 0 ? items : (<tr className="text-center"><td colSpan="4">Tidak ada guru yang mengajar pada mapel ini.</td></tr>)}
              </tbody>
            </table>
            {items != null && items.length != 0 ?(
              <button className="btn-schoolmedia mx-1 px-4 justify-center font-medium rounded-full text-white items-center py-2 text-sm">
                <i className="bx bx-save text-lg mr-1"></i>
                <span className="text-md">Simpan</span>
              </button>
            ) : ("")}
          </form>
        )  
      }
    }
    
    this.setState({ atur:table }, function(){
      this.showModalPengampu();
    });
  }

  showAturGuru(table){
    return(table);
  }

  showData(){
    if(this.state.data_mapel != ''){
      var u = 0;
      return(
        <table className="table table-bordered font-thin text-sm">
          <thead className="text-center">
            <tr>
              <th>No</th>
              <th>KODE KMP</th>
              <th>KODE JKMP</th>
              <th>MATA PELAJARAN</th>
              <th>KODE MPK</th>
              <th>KUOTA</th>
              <th>PENGAMPU</th>
            </tr>
          </thead>
          <tbody>
            {this.state.count_agama != undefined ? (
              <tr>
                <td><div className="text-sm">{u=u+1}</div></td>
                <td><div className="text-sm">{"A"}</div></td>
                <td><div className="text-sm">{"A"}</div></td>
                <td><div className="text-sm">{"Agama"}</div></td>
                <td><div className="text-sm">-</div></td>
                <td><div className="text-sm">{this.state.count_agama}</div></td>
                <td>
                  <div className="text-sm">
                    <button 
                      onClick={this.checkPengampu.bind(this, '-', '-', '-', this.state.count_agama, 'AGAMA')} 
                      className={this.state.checkAgama   ? "btn-yellow px-4 flex items-center rounded-full text-white py-2 text-sm" : "btn-green px-4 flex items-center rounded-full text-white py-2 text-sm"}
                    >
                      {this.state.checkAgama ? "Atur" : "Edit"}
                      <i className="bx bx-slider text-lg ml-1"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ) : ("")}
            {this.state.data_mapel.map((mapel, index) => {
              if(mapel.type_mapel == 'UMUM' || mapel.type_mapel == 'SENI' || mapel.type_mapel == 'PRAKARYA' || mapel.type_mapel == 'BAHASA'){
                return(
                  <tr key={index}>
                    <td><div className="text-sm">{u=u+1}</div></td>
                    <td><div className="text-sm">{mapel.kode_kmp}</div></td>
                    <td><div className="text-sm">{mapel.kode_jkmp}</div></td>
                    <td><div className="text-sm">{mapel.mp}</div></td>
                    <td><div className="text-sm">{mapel.indeks_mpk}</div></td>
                    <td><div className="text-sm">{mapel.kuota}</div></td>
                    <td>
                      <div className="text-sm">
                        <button 
                          onClick={this.checkPengampu.bind(this, mapel.id, mapel.jkmp, mapel.indeks_mpk, mapel.kuota, mapel.type_mapel)} 
                          className={mapel.status == null ? "btn-yellow px-4 flex items-center rounded-full text-white py-2 text-sm" : "btn-green px-4 flex items-center rounded-full text-white py-2 text-sm"}
                        >
                          {mapel.status == null ? "Atur" : "Edit"}
                          <i className="bx bx-slider text-lg ml-1"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              }
            })}
            {this.state.count_l1 != undefined ? (
              <tr>
                <td><div className="text-sm">{u=u+1}</div></td>
                <td><div className="text-sm">{"C"}</div></td>
                <td><div className="text-sm">{"2"}</div></td>
                <td><div className="text-sm">{"Lintas Minat 1"}</div></td>
                <td><div className="text-sm">-</div></td>
                <td><div className="text-sm">{this.state.count_l1}</div></td>
                <td>
                  <div className="text-sm">
                    <button 
                      onClick={this.checkPengampu.bind(this, '-', '-', '-', this.state.count_l1, 'LINTAS MINAT 1')} 
                      className={this.state.checkL1 ? "btn-yellow px-4 flex items-center rounded-full text-white py-2 text-sm" : "btn-green px-4 flex items-center rounded-full text-white py-2 text-sm"}
                    >
                      {this.state.checkL1 ? "Atur" : "Edit"}
                      <i className="bx bx-slider text-lg ml-1"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ) : ("")}
            {this.state.count_l2 != undefined ? (
              <tr>
                <td><div className="text-sm">{u=u+1}</div></td>
                <td><div className="text-sm">{"C"}</div></td>
                <td><div className="text-sm">{"2"}</div></td>
                <td><div className="text-sm">{"Lintas Minat 2"}</div></td>
                <td><div className="text-sm">-</div></td>
                <td><div className="text-sm">{this.state.count_l2}</div></td>
                <td>
                  <div className="text-sm">
                    <button 
                      onClick={this.checkPengampu.bind(this, '-', '-', '-', this.state.count_l2, 'LINTAS MINAT 2')} 
                      className={this.state.checkL2 ? "btn-yellow px-4 flex items-center rounded-full text-white py-2 text-sm" : "btn-green px-4 flex items-center rounded-full text-white py-2 text-sm"}
                    >
                      {this.state.checkL2 ? "Atur" : "Edit"}
                      <i className="bx bx-slider text-lg ml-1"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ) : ("")}
            {this.state.kurikulum_search == 'merdeka' ? (
              <>
                {this.state.count_pilihan_1 != undefined ? (
                  <tr>
                    <td><div className="text-sm">{u=u+1}</div></td>
                    <td><div className="text-sm">{"C"}</div></td>
                    <td><div className="text-sm">{"N"}</div></td>
                    <td><div className="text-sm">{"Pilihan 1"}</div></td>
                    <td><div className="text-sm">-</div></td>
                    <td><div className="text-sm">{this.state.count_pilihan_1}</div></td>
                    <td>
                      <div className="text-sm">
                        <button 
                          onClick={this.checkPengampu.bind(this, '-', '-', '-', this.state.count_pilihan_1, 'MAPEL PILIHAN 1')} 
                          className={this.state.checkPilihan1 ? "btn-yellow px-4 flex items-center rounded-full text-white py-2 text-sm" : "btn-green px-4 flex items-center rounded-full text-white py-2 text-sm"}
                        >
                          {this.state.checkPilihan1 ? "Atur" : "Edit"}
                          <i className="bx bx-slider text-lg ml-1"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ) : ("")}

                {this.state.count_pilihan_2 != undefined ? (
                  <tr>
                    <td><div className="text-sm">{u=u+1}</div></td>
                    <td><div className="text-sm">{"C"}</div></td>
                    <td><div className="text-sm">{"N"}</div></td>
                    <td><div className="text-sm">{"Pilihan 2"}</div></td>
                    <td><div className="text-sm">-</div></td>
                    <td><div className="text-sm">{this.state.count_pilihan_2}</div></td>
                    <td>
                      <div className="text-sm">
                        <button 
                          onClick={this.checkPengampu.bind(this, '-', '-', '-', this.state.count_pilihan_2, 'MAPEL PILIHAN 2')} 
                          className={this.state.checkPilihan2 ? "btn-yellow px-4 flex items-center rounded-full text-white py-2 text-sm" : "btn-green px-4 flex items-center rounded-full text-white py-2 text-sm"}
                        >
                          {this.state.checkPilihan2 ? "Atur" : "Edit"}
                          <i className="bx bx-slider text-lg ml-1"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ) : ("")}
                {/* {this.state.count_pilihan != undefined ? (
                  <tr>
                    <td><div className="text-sm">{u=u+1}</div></td>
                    <td><div className="text-sm">{"C"}</div></td>
                    <td><div className="text-sm">{"N"}</div></td>
                    <td><div className="text-sm">{"Pilihan"}</div></td>
                    <td><div className="text-sm">-</div></td>
                    <td><div className="text-sm">{this.state.count_pilihan}</div></td>
                    <td>
                      <div className="text-sm">
                        <button 
                          onClick={this.checkPengampu.bind(this, '-', '-', '-', this.state.count_pilihan, 'PILIHAN')} 
                          className={this.state.checkPilihan ? "btn-yellow px-4 flex items-center rounded-full text-white py-2 text-sm" : "btn-green px-4 flex items-center rounded-full text-white py-2 text-sm"}
                        >
                          {this.state.checkL2 ? "Atur" : "Edit"}
                          <i className="bx bx-slider text-lg ml-1"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ) : ("")} */}
              </>
            ) : ""}
          </tbody>
        </table>
      )
    } else {
      return("");
    }
  }

  showModalPengampu(){
    this.setState({modal_pengampu:true});
  }
  hideModalPengampu(){
    this.setState({
      modal_pengampu : false,
      status         : '',
      rombel_guru_id : '',
      data_mapel     : '',
    }, function(){
      this.setFilter();
    });
  }

  render(){
    return(
      <React.Fragment>
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />
        <div>
          <Modal size="xl" show={this.state.modal_pengampu} onHide={this.hideModalPengampu}>
            <Modal.Body>
              <div className="flex flex-col py-2 px-2">
                {this.state.atur ? this.showAturGuru(this.state.atur) : ''}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="flex flex-wrap justify-beetwen">
                <button onClick={this.hideModalPengampu} className="btn-default mx-1 px-4 justify-center font-medium rounded-full text-gray-800 items-center py-2 text-sm">
                  <span className="text-md">Tutup</span>
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          <div className="sm-container flex flex-row py-2 mt-4 mx-auto">
            <SideBarJadwal/>
            <div className="w-10/12 px-1 ml-2">
              {/* Header */}
              <div className="flex justify-between items-center px-6 py-4 rounded-lg shadow-md bg-white mb-2 border">
                <h1 className="text-xl text-gray-800 font-extrabold">
                  <span className="text-gray-500 font-thin block text-sm mb-2">
                    <p href="" className="text-gray-600">
                      7 dari 8 langkah
                    </p>
                  </span>

                  Atur Guru Per Rombel
                  <span className="text-gray-500 font-thin block text-sm">
                    Atur Guru Per Rombel. {" "}
                    {/* <a href="/sadasd" className="text-blue-600">
                      Pelajari Lebih Lanjut ?
                    </a> */}
                  </span>
                </h1>
              </div>

              {/* Content */}
              <div className="mt-4 px-4 py-4 rounded-lg shadow-md bg-white border">
                {this.state.tahun_ajaran_search !== undefined ? (<span className="text-md text-gray-800">Cari :</span>) : ("")}
                {this.state.loadingFilter ?
                <>
                  <div className="mb-6 flex flex-row flex-wrap">
                    <div className="w-1/4 px-1">
                      <div className="py-2">
                        <Skeleton count={2}/>
                      </div>
                    </div>
                    <div className="w-1/4 px-1">
                      <div className="py-2">
                        <Skeleton count={2}/>
                      </div>
                    </div>
                    <div className="w-1/4 px-1">
                      <div className="py-2">
                        <Skeleton count={2}/>
                      </div>
                    </div>
                    <div className="w-1/4 px-1">
                      <div className="py-2">
                        <Skeleton count={2}/>
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="flex flex-row flex-wrap mb-6">
                    {this.showSelectFilterSD()}
                    {this.showSelectFilterKurikulum()}
                    {this.showSelectFilterSMA()}
                    {this.showSelectFilterSMK()}
                    {this.showSelectFilterALL()}
                    {this.showSelectFilterSemester()}
                    {this.state.loading_show_data ? 
                      <div className="w-1/4 px-1 flex items-center">
                        <div className="py-2">
                          <div className="flex">
                            <span>Pilih secara berurutan</span>
                          </div>
                        </div>
                      </div>
                      :
                    <div className="w-1/4 px-1">
                      <div className="py-2">
                        <div className="flex">
                          {this.state.semester_search != '' ? (
                            <>

                              <button onClick={this.setFilter } className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm">
                                <i className="bx bx-search text-lg mr-1"></i>
                                Cari
                              </button>
                              <button onClick={this.setResetFilter} className="flex btn-default text-sm text-gray-800 px-4 py-2 ml-2 rounded-full items-center">
                                Reset
                              </button>
                            </>
                          ):""}
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div>
                    {this.state.loadingMapel ?
                    <>
                      <div className="flex flex-row mb-2">
                        <div className="w-1/4 px-2">
                          <Skeleton/>
                        </div>
                        <div className="w-1/4 px-2">
                          <Skeleton/>
                        </div>
                        <div className="w-1/4 px-2">
                          <Skeleton/>
                        </div>
                        <div className="w-1/4 px-2">
                          <Skeleton/>
                        </div>
                      </div>
                      <Skeleton count={2}/>
                    </>
                    :
                    <>
                      {this.showData()}
                    </>}
                  </div>
                </>}
                <div className="flex w-full flex-wrap items-center px-3 pb-2 pt-2 mt-1">
                  <div className="flex flex-wrap items-center">
                    {/* <button className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm">
                      <i className="bx bx-save text-lg mr-1"></i>
                      Simpan
                    </button> */}
                  </div>
                </div>

                <div className="flex w-full flex-wrap items-center justify-between px-3 pb-2 pt-2 mt-4">
                  <div className="flex flex-wrap items-center">
                    <TombolKembali/>
                  </div>
                  <div className="flex flex-wrap items-center">
                    <Link to="/jadwal-pelajaran/atur-jadwal-pelajaran">
                      <button className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm">
                        Selanjutnya
                        <i className="bx bx-chevron-right-circle text-lg ml-1"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}