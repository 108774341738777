import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import axios from "axios";

/* Configurasi */
import conf from "./config.js";

/* Navbar */
import Navbar from "./component/partials/Navbar";
// import Content from "./component/partials/Content";

/* Landing Page */
import LandingPage from "./component/pages/index";

/* Auth */
import Redirect from "./component/auth/Redirect";
import Caught from "./component/auth/Caught";
import Logout from "./component/auth/Logout";

/* Referensi */
// Jenis Kurikulum -> Kurikulum 2013 -> Struktur Kurikulum
import StrukturKurikulumSd from "./component/pages/referensi/jenis_kurikulum/k13/struktur_kurikulum/StrukturKurikulumSd";
import StrukturKurikulumSmp from "./component/pages/referensi/jenis_kurikulum/k13/struktur_kurikulum/StrukturKurikulumSmp";
import StrukturKurikulumSmk from "./component/pages/referensi/jenis_kurikulum/k13/struktur_kurikulum/StrukturKurikulumSmk";
import StrukturKurikulumSma from "./component/pages/referensi/jenis_kurikulum/k13/struktur_kurikulum/StrukturKurikulumSma";
// Jenis Kurikulum -> Kurikulum 2013 -> Kompetensi Dasar
import KompetensiDasar from "./component/pages/referensi/jenis_kurikulum/k13/kompetensi_dasar/KompetensiDasar";
// Jenis Kurikulum -> Merdeka
import Penggerak from "./component/pages/referensi/jenis_kurikulum/merdeka/Penggerak";
import CapaianPembelajaran from "./component/pages/referensi/jenis_kurikulum/merdeka/Capaian_pembelajaran";

/* Struktur Kurikulum */
// import KurikulumSd from "./component/pages/referensi/struktur_kurikulum/KurikulumSd";
// import KurikulumSmp from "./component/pages/referensi/struktur_kurikulum/KurikulumSmp";
// import KurikulumSma from "./component/pages/referensi/struktur_kurikulum/KurikulumSma";
// import KurikulumSmk from "./component/pages/referensi/struktur_kurikulum/KurikulumSmk";
// import Penggerak from "./component/pages/referensi/struktur_kurikulum/penggerak";
import Prototipe from "./component/pages/referensi/struktur_kurikulum/prototipe";

/* Kompetensi Dasar */
// import KompetensiDasar from "./component/pages/referensi/kompetensi_dasar/KompetensiDasar";

/* Kompetensi Dasar */
import Kodewilayah from "./component/pages/referensi/wilayah/KodeWilayah";

/* Sarpras */
import RefSarpas from "./component/pages/referensi/sarpras/RefSarpas";

/* Sarpras */
import Lahan from "./component/pages/sarpras/Lahan";
import Bangunan from "./component/pages/sarpras/Bangunan";
import Ruang from "./component/pages/sarpras/Ruang";
import Analisis from "./component/pages/sarpras/Analisis";

/* User */
import Guru from "./component/pages/user/Guru";
import Siswa from "./component/pages/user/Siswa";
import Lainnya from "./component/pages/user/Lainnya";
import Orangtua from "./component/pages/user/Orangtua";

/* Penugasan */
import PenugasanGuru from "./component/pages/penugasan/PenugasanGuru";
import PenugasanLainnya from "./component/pages/penugasan/PenugasanLainnya";

/* Rombel */
import RombelPerTA from "./component/pages/rombel/RombelPerTA";
import KenaikanSiswa from "./component/pages/rombel/KenaikanSiswa";
import Alumni from "./component/pages/rombel/Alumni";
import SiswaAlumni from "./component/pages/rombel/SiswaAlumni";
import HistoriRombel from "./component/pages/rombel/HistoriRombel";
import SiswaRombel from "./component/pages/rombel/SiswaRombel";
import OrangtuaRombel from "./component/pages/rombel/OrangtuaRombel";
import PindahSekolah from "./component/pages/rombel/PindahSekolah";

/* Jadwal Pelajaran */
import IndexJadwalPelajaran from "./component/pages/jadwal_pelajaran/IndexJadwalPelajaran";
import AturWaktuPelajaran from "./component/pages/jadwal_pelajaran/AturWaktuPelajaran";
import AturMapelRombel from "./component/pages/jadwal_pelajaran/AturMapelRombel";
import DaftarMapelRombel from "./component/pages/jadwal_pelajaran/DaftarMapelRombel";
import AturMapelSiswa from "./component/pages/jadwal_pelajaran/AturMapelSiswa";
import DaftarMapelSiswa from "./component/pages/jadwal_pelajaran/DaftarMapelSiswa";
import AturGuruWaktu from "./component/pages/jadwal_pelajaran/AturGuruWaktu";
import AturGuruRombel from "./component/pages/jadwal_pelajaran/AturGuruRombel";
import AturJadwalPelajaran from "./component/pages/jadwal_pelajaran/AturJadwalPelajaran";
import JadwalSiswa from "./component/pages/jadwal_pelajaran/JadwalSiswa";
import JadwalGuru from "./component/pages/jadwal_pelajaran/JadwalGuru";

/* Forum Grup */
import IndexForum from "./component/pages/forum/IndexForum";
import ForumLevel1 from "./component/pages/forum/ForumLevel1";
import ForumLevel2 from "./component/pages/forum/ForumLevel2";
import ForumLevel3 from "./component/pages/forum/ForumLevel3";
import ForumLevel4 from "./component/pages/forum/ForumLevel4";

/* Profil */
import Profil from "./component/pages/profil/Profil";

/* Error Page */
import NotFoundPage from "./component/pages/error_page/404";
import NotLoginPage from "./component/pages/error_page/NotLogin";

/* Style CSS */
import "./styles/app.css";
import "./styles/loader.css";
import "./styles/button.css";
import "./styles/animate.css";
import Footer from "./component/partials/Footer.js";
import Aktivasi from "./component/pages/aktivasi/Aktivasi";
import TambahSiswa from "./component/pages/rombel/TambahSiswa.js";

function App() {
  console.log(
    "%cBerhenti Untuk Debug!!! Jangan Lupa Pakai Masker",
    "background: red; color: yellow; font-size: 1.5rem; border-radius: 1rem; padding: 0.5rem;"
  );
  if (!localStorage.hasOwnProperty("token")) {
    localStorage.setItem("is_login", "");
    localStorage.setItem("token", "");
  }

  var data = window.location.pathname;
  var datac = data.split("/", 2);

  if (localStorage.getItem("token") != "") {
    axios
      .post(
        `${conf.uri_backend}api/v1/auth/sso/check`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        // if (res.data.status == true) {
        // }
      })
      .catch(function(error) {
        if (error.response.status == 401) {
          axios
            .post(`${conf.uri_backend}api/v1/auth/sso/logout`, {
              token: localStorage.getItem("token"),
            })
            .then((res) => {
              if (res.data.status == true) {
                localStorage.setItem("token", "");
                localStorage.setItem("is_login", "");
                window.location.replace(
                  `${conf.uri_sso}` +
                    "auth/logout?token=" +
                    res.data.token +
                    "&redirect_uri=" +
                    res.data.redirect_uri
                );
              }
            });
        }
      });
  } else {
    // if (localStorage.getItem("token") == "") {
    // }
  }

  let navbar = "";
  // console.log(window.location.pathname);
  if (window.location.pathname != "/auth" && window.location.pathname != "/") {
    navbar = <Navbar />;
  }
  let footer = "";
  if (window.location.pathname != "/auth" && window.location.pathname != "/") {
    footer = <Footer />;
  }

  if (localStorage.getItem("token") == "") {
    // console.log("%cBe careful :)", "background: red; color: yellow; font-size: x-large");
    return (
      <Router>
        <div>
          {/* {navbar} */}
          <div>
            <Switch>
              <Route exact path="/">
                <LandingPage />
              </Route>
              
              <Route exact path="/aktivasi">
                <Aktivasi />
              </Route>
              <Route exact path="/auth/sso/redirect/" component={Redirect} />
              <Route exact path="/auth/sso/logout" component={Logout} />
              <Route exact path="/auth/sso/caught/" component={Caught} />
              <Route component={NotLoginPage} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  } else if (localStorage.getItem("is_login") == "is_login") {
    return (
      <Router>
        <div>
          {navbar}
          <div style={{ minHeight: "65vh" }}>
            <Switch>
              {/* Landing Page */}
              <Route exact path="/">
                <LandingPage />
              </Route>


              {/* Auth */}
              <Route exact path="/auth/sso/redirect/" component={Redirect} />
              <Route exact path="/auth/sso/logout" component={Logout} />
              <Route exact path="/auth/sso/caught/" component={Caught} />

              {/* Referensi */}
              {/* Jenis Kurikulum -> Kurikulum 2013  */}
              <Route path="/referensi/jenis-kurikulum/k13/struktur-kurikulum/sd">
                <StrukturKurikulumSd />
              </Route>
              <Route path="/referensi/jenis-kurikulum/k13/struktur-kurikulum/smp">
                <StrukturKurikulumSmp />
              </Route>
              <Route path="/referensi/jenis-kurikulum/k13/struktur-kurikulum/smk">
                <StrukturKurikulumSmk />
              </Route>
              <Route path="/referensi/jenis-kurikulum/k13/struktur-kurikulum/sma">
                <StrukturKurikulumSma />
              </Route>
              <Route path="/referensi/jenis-kurikulum/k13/kompetensi-dasar/">
                <KompetensiDasar />
              </Route>

              {/* Jenis Kurikulum -> Merdeka */}
              <Route path="/referensi/jenis-kurikulum/merdeka/penggerak">
                <Penggerak />
              </Route>
              <Route path="/referensi/jenis-kurikulum/merdeka/capaian-pembelajaran">
                <CapaianPembelajaran/>
              </Route>

              {/* Struktur Kurikulum */}
              {/* <Route path="/referensi/struktur-kurikulum/sd">
                <StrukturKurikulumSd />
              </Route>
              <Route path="/referensi/struktur-kurikulum/smp">
                <StrukturKurikulumSmp />
              </Route>
              <Route path="/referensi/struktur-kurikulum/sma">
                <StrukturKurikulumSma />
              </Route>
              <Route path="/referensi/struktur-kurikulum/smk">
                <StrukturKurikulumSmk />
              </Route> */}
              {/* <Route path="/referensi/struktur-kurikulum/penggerak">
                <Penggerak />
              </Route> */}
              <Route path="/referensi/struktur-kurikulum/prototipe">
                <Prototipe />
              </Route>

              {/* Kompetensi Dasar */}
              {/* <Route path="/referensi/kompetensi-dasar">
                <KompetensiDasar />
              </Route> */}

              {/* Kode Wilayah */}
              <Route path="/referensi/kode-wilayah">
                <Kodewilayah />
              </Route>

              {/* Sarpras */}
              <Route
                path="/referensi/sarpras/:sarprasId"
                component={RefSarpas}
              />

              {/* Sarpras */}
              <Route path="/sarpras/lahan">
                <Lahan />
              </Route>
              <Route path="/sarpras/bangunan">
                <Bangunan />
              </Route>
              <Route path="/sarpras/ruang">
                <Ruang />
              </Route>
              <Route path="/sarpras/analisis">
                <Analisis />
              </Route>

              {/* User */}
              <Route path="/user/guru">
                <Guru />
              </Route>
              <Route path="/user/lainnya">
                <Lainnya />
              </Route>
              <Route path="/user/siswa">
                <Siswa />
              </Route>
              <Route path="/user/orangtua">
                <Orangtua />
              </Route>

              {/* Penugasan */}
              <Route path="/penugasan/penugasan-guru">
                <PenugasanGuru />
              </Route>
              <Route path="/penugasan/penugasan-lainnya">
                <PenugasanLainnya />
              </Route>

              {/* Rombel */}
              <Route path="/rombel/rombel-per-ta">
                <RombelPerTA />
              </Route>
              <Route path="/rombel/kenaikan-siswa">
                <KenaikanSiswa />
              </Route>
              <Route path="/rombel/tambah-siswa">
                <TambahSiswa />
              </Route>
              <Route path="/rombel/alumni">
                <Alumni />
              </Route>
              <Route path="/rombel/siswa-alumni">
                <SiswaAlumni />
              </Route>
              <Route path="/rombel/histori-rombel">
                <HistoriRombel />
              </Route>
              <Route path="/rombel/siswa-rombel">
                <SiswaRombel />
              </Route>
              <Route path="/rombel/orangtua-rombel">
                <OrangtuaRombel />
              </Route>
              <Route path="/rombel/pindah-sekolah">
                <PindahSekolah />
              </Route>

              {/* Jadwal Pelajaran */}
              <Route path="/jadwal-pelajaran/index">
                <IndexJadwalPelajaran />
              </Route>
              <Route path="/jadwal-pelajaran/atur-waktu">
                <AturWaktuPelajaran />
              </Route>
              <Route path="/jadwal-pelajaran/atur-mapel-rombel">
                <AturMapelRombel />
              </Route>
              <Route path="/jadwal-pelajaran/daftar-mapel-rombel">
                <DaftarMapelRombel />
              </Route>
              <Route path="/jadwal-pelajaran/atur-mapel-siswa">
                <AturMapelSiswa />
              </Route>
              <Route path="/jadwal-pelajaran/daftar-mapel-siswa">
                <DaftarMapelSiswa />
              </Route>
              <Route path="/jadwal-pelajaran/atur-guru-waktu">
                <AturGuruWaktu />
              </Route>
              <Route path="/jadwal-pelajaran/atur-guru-rombel">
                <AturGuruRombel />
              </Route>
              <Route path="/jadwal-pelajaran/atur-jadwal-pelajaran">
                <AturJadwalPelajaran />
              </Route>
              <Route path="/jadwal-pelajaran/jadwal-siswa">
                <JadwalSiswa />
              </Route>
              <Route path="/jadwal-pelajaran/jadwal-guru">
                <JadwalGuru />
              </Route>

              {/* Forum */}
              <Route path="/forum/index">
                <IndexForum />
              </Route>
              <Route path="/forum/level-1">
                <ForumLevel1 />
              </Route>
              <Route path="/forum/level-2">
                <ForumLevel2 />
              </Route>
              <Route path="/forum/level-3">
                <ForumLevel3 />
              </Route>
              <Route path="/forum/level-4">
                <ForumLevel4 />
              </Route>

              {/* Profil */}
              <Route path="/profil">
                <Profil />
              </Route>

              {/* Error Page */}
              <Route path="" component={NotFoundPage} />
            </Switch>
          </div>
          {footer}
        </div>
      </Router>
    );
  }
}

export default App;
