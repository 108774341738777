import React from "react";
import DataTable from "./../../../partials/table/DataTables.js";
import { Link } from "react-router-dom";
import conf from "./../../../../config.js";
import SelectJs from "./../../../partials/Select.js";
import axios from "axios";
import Select from "react-select";
import tokenApi from "../../../auth/Helpers";
import Skeleton from "react-loading-skeleton";

// select style
const selectStyles = {
  control: (styles) => ({ ...styles, borderRadius: "100px", width: "100%" }),
};

export default class Wilayah extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${conf.uri_backend}api/v1/referensi/wilayah/detail`,
      url_wilayah: `${conf.uri_backend}api/v1/referensi/wilayah`,
      select: {
        provinsi: [],
        kab_kota: [],
        kecamatan: [],
        kelurahan: [],
      },

      defaultSelect: {
        provinsi: [],
        kab_kota: [],
        kecamatan: [],
        kelurahan: [],
      },
      // showFilter: true,

      // data untuk filter
      data_provinsi: [],
      data_kota: [],
      data_kecamatan: [],
      data_kelurahan: [],

      // select filter
      select_kabupaten: false,
      select_kecamatan: false,
      select_kelurahan: false,
      show_button_reset: false,
      query: "",

      loadingReset: false,
    };

    this.filterProvinsi = this.filterProvinsi.bind(this);
    this.filterKabupaten = this.filterKabupaten.bind(this);
    this.filterKecamatan = this.filterKecamatan.bind(this);
    this.filterKelurahan = this.filterKelurahan.bind(this);

    this.getReset = this.getReset.bind(this);
  }

  render() {
    return (
      <div>
        <div className="shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border sm-container">
          <div className="flex justify-between items-center mt-2  px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Kode Wilayah
              <span className="text-gray-500 font-thin block text-sm">
                Daftar Kode Wilayah.{" "}
                {/* <a href="/sadasd" className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
          </div>
        </div>
        <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white border sm-container">
          <div className="flex flex-wrap items-center px-6 mt-3 mb-6">
            <button
              className="btn-blue px-4 mr-2 flex items-center rounded-full text-white py-2 text-xs"
              onClick={() => {
                this.setState({
                  showFilter: !this.state.showFilter,
                });
              }}
            >
              <i className="bx bx-filter text-lg mr-1"></i>
              Pilih
            </button>
            <div className="relative w-1/3">
              <input
                type="search"
                className="w-full pl-10 pr-4 py-1 rounded-full border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                placeholder="Provinsi, Kota, Kecamatan, Kelurahan"
                onChange={(event) => {
                  this.dt.resetPage(`&query=${event.target.value}`);
                  this.dt.loading();
                }}
              />
              <div className="absolute top-0 left-0 inline-flex items-center py-2 px-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 text-gray-400"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                  <circle cx="10" cy="10" r="7"></circle>
                  <line x1="21" y1="21" x2="15" y2="15"></line>
                </svg>
              </div>
            </div>
          </div>
          {this.state.showFilter ? (
            <div className="flex w-full border-t flex-wrap items-center my-1 pb-5 pt-2">
              <div className="flex w-full flex-wrap items-center px-6">
                {this.selectProvinsi()}
                {this.selectKabupaten()}
                {this.selectKecamatan()}
                {this.selectKelurahan()}
                {this.state.show_button_reset ? (
                  <form className="flex">
                    <button
                      onClick={this.getReset}
                      className="animateFadeInLeft flex btn-default text-sm text-gray-800 px-4 py-2 rounded-full items-center"
                    >
                      Reset
                    </button>
                  </form>
                ) : (
                  <p className="ml-2 text-sm text-gray-500">
                    Pilih filter secara berurutan
                  </p>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="px-2 py-1">
            <DataTable
              ref={(dt) => {
                this.dt = dt;
              }}
              url={this.state.url}
              columns={[
                {
                  index: "kode_provinsi",
                  label: "Kode",
                  maxWidth: "4rem ",
                },
                {
                  index: "provinsi",
                  label: "Provinsi",
                },

                {
                  index: "kode_kab_kota",
                  label: "Kode",
                  maxWidth: "4rem ",
                },
                {
                  index: "kab_kota",
                  label: "Kabupaten/Kota",
                },
                {
                  index: "kode_kecamatan",
                  label: "Kode",
                  maxWidth: "4rem ",
                },
                {
                  index: "kecamatan",
                  label: "Kecamatan",
                },
                {
                  index: "kode_kelurahan",
                  label: "Kode",
                },
                {
                  index: "kelurahan",
                  label: "Kelurahan",
                },
                {
                  index: "kodepos",
                  label: "Kode POS",
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    axios
      .get(
        `${conf.uri_backend}api/v1/referensi/wilayah/provinsi?&negara_id=1`,
        {
          headers: {
            Authorization: tokenApi,
          },
        }
      )
      .then((res) => {
        this.setState({
          data_provinsi: res.data,
          disabled_prov: true,
        });
      });
    this.dt.fetchEntities();
  }

  // Filter
  filterProvinsi(e) {
    this.setState({ loadingReset: true });
    if (e) {
      this.setState(
        {
          data_kabupaten: null,
          data_kecamatan: null,
          data_kelurahan: null,
          query: e.value,
        },
        function() {}
      );
      axios
        .get(
          `${conf.uri_backend}api/v1/referensi/wilayah/kota?&negara_id=1&provinsi_id=${e.value}`,
          {
            headers: {
              Authorization: tokenApi,
            },
          }
        )
        .then((res) => {
          this.setState({
            data_kabupaten: res.data,
            disabled_kab: true,
            loadingReset: false,
          });

          this.dt.resetPage(`&provinsi_id=${e.value}`, "validate");

          this.dt.fetchEntities(`&provinsi_id=${e.value}`);
          this.dt.loading();
        })
        .catch((err) => {
          this.setState({ loadingReset: false });
        });
      this.setState({ select_kabupaten: true });
    } else {
      this.setState({ data_kabupaten: "", loadingReset: false });
      this.dt.fetchEntities(null);
    }
  }

  filterKabupaten(e) {
    if (e) {
      this.setState({
        data_kecamatan: null,
        data_kelurahan: null,
      });
      axios
        .get(
          `${conf.uri_backend}api/v1/referensi/wilayah/kecamatan?&negara_id=1&kota_id=${e.value}`,
          {
            headers: {
              Authorization: tokenApi,
            },
          }
        )
        .then((res) => {
          this.setState({
            data_kecamatan: res.data,
            disabled_kec: true,
          });
          this.dt.loading();
          this.dt.fetchEntities(`&kab_kota_id=${e.value}`);
        });
      this.setState({ select_kecamatan: true });
    } else if (e) {
      this.setState({ data_kecamatan: "" });
      this.dt.loading();
      this.dt.fetchEntities(`&provinsi_id=${e.value}`);
    }
  }

  filterKecamatan(e) {
    if (e) {
      this.setState({
        data_kelurahan: null,
      });
      axios
        .get(
          `${conf.uri_backend}api/v1/referensi/wilayah/kelurahan?&negara_id=1&kecamatan_id=${e.value}`,
          {
            headers: {
              Authorization: tokenApi,
            },
          }
        )
        .then((res) => {
          this.setState({
            data_kelurahan: res.data,
            disabled_kel: true,
          });
          this.dt.loading();
          this.dt.fetchEntities(`&kecamatan_id=${e.value}`);
        });
      this.setState({ select_kelurahan: true });
    } else if (e) {
      this.setState({ data_kelurahan: "" });
      this.dt.fetchEntities(`&kab_kota_id=${e.value}`);
      this.dt.loading();
    }
  }

  filterKelurahan(e) {
    if (e) {
      this.dt.fetchEntities(`&kelurahan_id=${e.value}`);
      this.dt.loading();
      this.setState({ show_button_reset: true });
    } else if (e) {
      this.setState({ show_button_reset: false });
      this.dt.fetchEntities(`&kecamatan_id=${this.filterKecamatan(e.value)}`);
      this.dt.loading();
    }
  }

  getReset(e) {
    e.preventDefault();
    this.setState({
      select_kabupaten: false,
      select_kecamatan: false,
      select_kelurahan: false,
      show_button_reset: false,
    });
    this.dt.fetchEntities(null);
  }

  loadSelect(url, name) {
    axios.get(url).then((res) => {
      let data = res.data;
      let response = {};
      response[name] = data;
      const select = { ...this.state.select, ...response };
      this.setState({ select });
    });
  }

  // Select
  selectProvinsi() {
    return (
      <div className="flex w-1/5">
        <div className="w-full">
          <div className="py-2 mr-4">
            <Select
              id="filter"
              isDisabled={this.state.disabled_prov ? false : true}
              isLoading={this.state.disabled_prov ? false : true}
              styles={selectStyles}
              options={this.state.data_provinsi}
              onChange={this.filterProvinsi}
              placeholder={"Provinsi"}
            />
          </div>
        </div>
      </div>
    );
  }

  selectKabupaten() {
    if (this.state.select_kabupaten === true) {
      return (
        <div className="flex w-1/5">
          <div className="w-full">
            {this.state.data_kabupaten === null ? (
              <div className="px-1">
                <div className="py-2">
                  <Skeleton count={2} />
                </div>
              </div>
            ) : (
              <div className="py-2 mr-4">
                <Select
                  id="filter"
                  isDisabled={this.state.disabled_kab ? false : true}
                  isLoading={this.state.disabled_kab ? false : true}
                  className="animateFadeInLeft"
                  styles={selectStyles}
                  options={this.state.data_kabupaten}
                  onChange={this.filterKabupaten}
                  placeholder={"Kabupaten / Kota"}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  selectKecamatan() {
    if (this.state.select_kecamatan === true) {
      return (
        <div className="flex w-1/5">
          <div className="w-full">
            {this.state.data_kecamatan === null ? (
              <div className="px-1">
                <div className="py-2">
                  <Skeleton count={2} />
                </div>
              </div>
            ) : (
              <div className="py-2 mr-4">
                <Select
                  id="filter"
                  isDisabled={this.state.disabled_kec ? false : true}
                  isLoading={this.state.disabled_kec ? false : true}
                  className="animateFadeInLeft"
                  styles={selectStyles}
                  options={this.state.data_kecamatan}
                  onChange={this.filterKecamatan}
                  placeholder={"Kecamatan"}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  selectKelurahan() {
    if (this.state.select_kelurahan === true) {
      return (
        <div className="flex w-1/5">
          <div className="w-full">
            {this.state.data_kelurahan === null ? (
              <div className="px-1">
                <div className="py-2">
                  <Skeleton count={2} />
                </div>
              </div>
            ) : (
              <div className="py-2 mr-4">
                <Select
                  id="filter"
                  isDisabled={this.state.disabled_kel ? false : true}
                  isLoading={this.state.disabled_kel ? false : true}
                  className="animateFadeInLeft"
                  styles={selectStyles}
                  options={this.state.data_kelurahan}
                  onChange={this.filterKelurahan}
                  placeholder={"Kelurahan / Desa"}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}
