import { configure } from "@testing-library/react";
import axios from 'axios';

let conf = {

    uri_backend: process.env.REACT_APP_URI_BACKEND,
    uri_frontend: process.env.REACT_APP_URI_FRONTEND,
    uri_sso: process.env.REACT_APP_URI_SSO
}

export default conf;