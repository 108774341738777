import React from "react";
import ContentBase from "./items/ContentBase.js";
import { Link } from "react-router-dom";
import axios from "axios";
import conf from "../../../../config.js";
import tokenApi from "../../../auth/Helpers";
import Skeleton from 'react-loading-skeleton';

export default class RefrensiSarpras extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kelas: [
        {
          label: "SD / MI",
          value: 2,
          slug: "sd",
        },
        {
          label: "SMP / MTS",
          value: 3,
          slug: "smp",
        },
        {
          label: "SMA / MA",
          value: 4,
          slug: "sma",
        },
        {
          label: "SMK / MAK",
          value: 5,
          slug: "smk",
        },
      ],
      active_class: '',
    };
  }

  componentDidMount() {
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ 
          data_session: res.data,
        }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({ active_class: res.data.m_struktur_jenjang_id });
          });
        });
      });
    }
  }

  render() {
    return (
      <div>
        <div className="shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border sm-container">
          <div className="flex justify-between items-center mt-2  px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Standar Sarana dan Prasarana
              <span className="text-gray-500 font-thin block text-sm">
                Daftar Standar Sarana dan Prasarana.{" "}
                {/* <a href="/sadasd" className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
          </div>
        </div>
        <div className="shadow-md mx-auto mt-2 rounded-lg bg-white border sm-container">
          {this.state.active_class != '' ? this.showKelas() : ''}
          {this.state.active_class != '' ? (
            <ContentBase id={this.state.active_class} />
          ) : (
            <>
              <div className="mb-6 mt-6">
                <div className="flex flex-row mb-2 mt-2 px-4">
                  <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                  <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                  <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                </div>
                <div className="flex flex-row mb-2 mt-2 px-4">
                  <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                  <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                  <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                </div>
                <div className="flex flex-row mb-2 mt-2 px-4">
                  <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                  <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                  <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                </div>
                <div className="flex flex-row mb-2 mt-2 px-4">
                  <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                  <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                  <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  setActiveClass(v) {
    this.setState({
      active_class: v.value,
    });
  }

  showKelas() {
    let c = this.state.kelas.map((v) => {
      if (this.state.active_class == v.value) {
        return (
          <li className="-mb-px mr-1">
            <span className="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold">
              {v.label}
            </span>
          </li>
        );
      }
    });
    return <ul className="flex border-b mb-2">{c}</ul>;
  }
}