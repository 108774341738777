import React from 'react';
import { Link } from "react-router-dom";

export default class IndexForum extends React.Component{
    render(){
        return(
            <>
                <div className="sm-container flex flex-col shadow-md px-4  mx-auto py-4 mt-6 rounded-lg bg-white border">
                    <div className="flex flex-row">
                        <div className="w-1/2 px-4 py-4 flex flex-row border rounded-lg bg-1 mx-2">
                            <div className="w-4/12 flex justify-center items-center">
                                <h3 className="text-2xl font-bold mb-2 text-white">Forum <br/> Level 1</h3>
                            </div>
                            <div className="w-8/12 flex flex-col py-5">
                                <p className="text-sm text-white mb-1">
                                    <b><u>Anggota Forum Group Tetap Level 1</u></b> adalah semua guru, PTK/lainnya dan siswa dalam 1 sekolah semua level angkatan per tahun ajaran yang sama.
                                </p>
                                <Link to="/forum/level-1">
                                    <button className="btn-yellow flex items-center justify-center w-20 rounded-full text-white font-bold px-4 py-2 mt-2 text-sm">
                                        <i className="bx bx-info-circle text-lg mr-1"></i>
                                        Lihat
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="w-1/2 px-4 py-4 flex flex-row border rounded-lg bg-1 mx-2">
                            <div className="w-4/12 flex justify-center items-center">
                                <h3 className="text-2xl font-bold mb-2 text-white">Forum <br/> Level 2</h3>
                            </div>
                            <div className="w-8/12 flex flex-col py-5">
                                <p className="text-sm text-white mb-1">
                                    <b><u>Anggota Forum Group Tetap Level 2</u></b> adalah semua guru, PTK/lainnya dan siswa dalam 1 sekolah per level angkatan dan tahun ajaran yang sama.
                                </p>
                                <Link to="/forum/level-2">
                                    <button className="btn-yellow flex items-center justify-center w-20 rounded-full text-white font-bold px-4 py-2 text-sm">
                                        <i className="bx bx-info-circle text-lg mr-1"></i>
                                        Lihat
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row mt-3">
                        <div className="w-1/2 px-4 py-4 flex flex-row border rounded-lg bg-1 mx-2">
                            <div className="w-4/12 flex justify-center items-center">
                                <h3 className="text-2xl font-bold mb-2 text-white">Forum <br/> Level 3</h3>
                            </div>
                            <div className="w-8/12 flex flex-col py-5">
                                <p className="text-sm text-white mb-1">
                                    <b><u>Anggota Forum Group Tetap Level 3</u></b> adalah siswa dan guru pengajar per rombel dan tahun ajaran yang sama.
                                </p>
                                <Link to="/forum/level-3">
                                    <button className="btn-yellow flex items-center justify-center w-20 rounded-full text-white font-bold px-4 py-2 text-sm">
                                        <i className="bx bx-info-circle text-lg mr-1"></i>
                                        Lihat
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="w-1/2 px-4 py-4 flex flex-row border rounded-lg bg-1 mx-2">
                            <div className="w-4/12 flex justify-center items-center">
                                <h3 className="text-2xl font-bold mb-2 text-white">Forum <br/> Level 4</h3>
                            </div>
                            <div className="w-8/12 flex flex-col py-5">
                                <p className="text-sm text-white mb-1">
                                    <b><u>Anggota Forum Group Tetap Level 4</u></b> adalah wali kelas dan orang tua / wali per rombel dan tahun ajaran yang sama.
                                </p>
                                <Link to="/forum/level-4">
                                    <button className="btn-yellow flex items-center justify-center w-20 rounded-full text-white font-bold px-4 py-2 text-sm">
                                        <i className="bx bx-info-circle text-lg mr-1"></i>
                                        Lihat
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}