import React from "react";
import DataTable from "./../../partials/table/DataTables.js";
import { Link } from "react-router-dom";
import conf from "./../../../config.js";
import SelectJs from "./../../partials/Select.js";
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import { Button, FormText, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import SweetAlert from 'sweetalert2-react';
import Skeleton from "react-loading-skeleton";
import swal from 'sweetalert2';
import { UserButtonLoading } from "../../partials/loadings/ComponentLoading.js";

const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

const dataKurikulum = [
  { value: 'k13', label: 'K13' },
  { value: 'merdeka', label: 'Merdeka' },
]

function captFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default class SiswaPerRombel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url                 : `${conf.uri_backend}api/v1/rombel/siswa`,
      url_get_rombel      : `${conf.uri_backend}api/v1/rombel/get_rombel`,
      url_get_spektrum    : `${conf.uri_backend}api/v1/rombel/get_spektrum`,
      url_get_smk         : `${conf.uri_backend}api/v1/referensi/struktur-kurikulum`,
      url_get_filter      : `${conf.uri_backend}api/v1/rombel/filter`,
      url_delete          : `${conf.uri_backend}api/v1/rombel/siswa_delete`,
      url_tambah_alumni   : `${conf.uri_backend}api/v1/rombel/alumni`,
      url_show_data       : '',
      // bind data
      tahunSekarang       : new Date().getFullYear(),
      search              : false,
      // alert
      show_alert          : false,
      title_alert         : '',
      message_alert       : '',
      icon_alert          : '',

      tahun_ajaran_search : '',
      kelas_search        : '',
      peminatan_search    : '',
      rombel_search       : '',
      bidang_search       : '',
      program_search      : '',
      kompetensi_search   : '',
      kurikulum_search    : '',

      data_tahun_ajaran   : '',
      data_kelas          : '',
      data_peminatan      : '',
      data_bidang         : '',
      data_program        : '',
      data_kompetensi     : '',
      data_rombel         : '',
      checkAll            : false,

      loadingFilter       : true,
    };
    // get option filter
    this.getTahunAjaranNow   = this.getTahunAjaranNow.bind(this);
    this.getKelas            = this.getKelas.bind(this);
    this.getRombel           = this.getRombel.bind(this);
    this.getPeminatanSMA     = this.getPeminatanSMA.bind(this);
    this.getDataFilterSMA    = this.getDataFilterSMA.bind(this);
    this.getBidang           = this.getBidang.bind(this);
    this.getProgram          = this.getProgram.bind(this);
    this.getKompetensi       = this.getKompetensi.bind(this);
    // filter and search
    this.filterTahunAjaran   = this.filterTahunAjaran.bind(this);
    this.filterKurikulum     = this.filterKurikulum.bind(this);
    this.filterKelas         = this.filterKelas.bind(this);
    this.filterRombel        = this.filterRombel.bind(this);
    this.filterPeminatan     = this.filterPeminatan.bind(this);
    this.filterBidang        = this.filterBidang.bind(this);
    this.filterProgram       = this.filterProgram.bind(this);
    this.filterKompetensi    = this.filterKompetensi.bind(this);
    this.searchText          = this.searchText.bind(this);
    this.urutanJenjang       = this.urutanJenjang.bind(this);
    // show select filter
    this.showSelectFilterSD  = this.showSelectFilterSD.bind(this);
    this.showSelectFilterSMA = this.showSelectFilterSMA.bind(this);
    this.showSelectFilterSMK = this.showSelectFilterSMK.bind(this);
    this.showSelectFilterALL = this.showSelectFilterALL.bind(this);
    // processing  Data
    this.setFilter           = this.setFilter.bind(this);
    this.showData            = this.showData.bind(this);
    this.setResetFilter      = this.setResetFilter.bind(this);
    this.deleteSiswaRombel   = this.deleteSiswaRombel.bind(this);
    this.checkAll            = this.checkAll.bind(this);
    this.unCheckAll          = this.unCheckAll.bind(this);
    //save data
    this.tambahAlumni = this.tambahAlumni.bind(this);
  }

  componentDidMount() {
    // get session user
    if(localStorage.getItem('token') !== null){
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ data_session: res.data }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
            this.setState({ 
              institusi_id_select : res.data.id,
              jenjang_id_select   : res.data.m_struktur_jenjang_id
            });
            this.getDataFilterSMA();
          });
        });
      });
    }
  }

  
  getTahunAjaranNow(){
    var ta             = [];
    var u              = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for(var i=tahun_sekarang; i >= 2005; i--){
      u = u - 1;
      ta.push({"value":u+' / '+i, "label":u+' / '+i});
    }
    this.setState({ data_tahun_ajaran: ta });
  }
    
  getKelas(){
    var kelas = [];
    if(this.state.jenjang_id_select == '2'){
      kelas = [
        { value: 'I',   label: 'I'   },
        { value: 'II',  label: 'II'  },
        { value: 'III', label: 'III' },
        { value: 'IV',  label: 'IV'  },
        { value: 'V',   label: 'V'   },
        { value: 'VI',  label: 'VI'  },
      ];
    } else if(this.state.jenjang_id_select == '3') {
      kelas = [
        { value: 'VII',   label: 'VII'  },
        { value: 'VIII',  label: 'VIII' },
        { value: 'IX',    label: 'IX'   },
      ];
    } else if(this.state.jenjang_id_select == '4') {
      kelas = [
        { value: 'X',   label: 'X'   },
        { value: 'XI',  label: 'XI'  },
        { value: 'XII', label: 'XII' },
      ];
    } else if(this.state.jenjang_id_select == '5'){
      kelas = [
        { value: 'X',   label: 'X'   },
        { value: 'XI',  label: 'XI'  },
        { value: 'XII', label: 'XII' },
        { value: 'XIII', label: 'XIII' },
      ];
    }
    this.setState({ data_kelas: kelas });
    if(this.state.jenjang_id_select == '3' || this.state.jenjang_id_select == '2' || this.state.jenjang_id_select == '5' ? this.setState({loadingFIlter: false}) : '');
  }

  getPeminatanSMA(){
    this.setState({loading_peminatan: !this.state.loading_peminatan})
    let filterKurikulum = this.state.kurikulum_search ? this.state.kurikulum_search : 'k13'
    if(this.state.jenjang_id_select == "4"){
      axios.get(this.state.url_get_spektrum+"?jenjang="+this.state.jenjang_id_select+"&kurikulum="+filterKurikulum, {headers:{Authorization:tokenApi}})
      .then(res => {
        if(res.data.status == true){
          this.setState({ data_peminatan: res.data.data, loading_peminatan: false});
        } else{
          this.setState({ data_peminatan: [], loading_peminatan: false});
        }
      });
    }
  }
    
  getRombel(){
    this.setState({loading_rombel : !this.state.loading_rombel})
    let filterKurikulum = this.state.kurikulum_search ? this.state.kurikulum_search : 'k13'
    var peminatan = '';
    if(this.state.jenjang_id_select == '5' ? peminatan = this.state.kompetensi_search : peminatan = this.state.peminatan_search);
    axios.get(`${this.state.url_get_rombel}?tahun_ajaran=${this.state.tahun_ajaran_search}&kelas=${this.state.kelas_search}&peminatan=${peminatan}&user_institusi_id=${this.state.institusi_id_select}&kurikulum=${filterKurikulum}`, {headers:{Authorization:tokenApi}})
    .then( res => {
      this.setState({data_rombel: res.data, loadingFilter: false, loading_rombel : false})
    });
  }

  getDataFilterSMA(){
    axios.get(this.state.url_get_filter+"/"+this.state.institusi_id_select, {headers:{Authorization:tokenApi}})
    .then(res => {
      if(res.data.status == true){
        this.setState({
          tahun_ajaran_search     : (res.data.data.tahun_ajaran != null ? res.data.data.tahun_ajaran : ''),
          kelas_search            : (res.data.data.kelas !== null ? res.data.data.kelas : ''),
          bidang_search           : (res.data.data.bidang_id !== null ? res.data.data.bidang_id : ''),
          bidang_search_label     : (res.data.data.bidang !== null ? res.data.data.bidang : ''),
          program_search          : (res.data.data.program_id !== null ? res.data.data.program_id : ''),
          program_search_label    : (res.data.data.program !== null ? res.data.data.program : ''),
          kompetensi_search       : (res.data.data.kompetensi_id !== null ? res.data.data.kompetensi_id : ''),
          kompetensi_search_label : (res.data.data.kompetensi !== null ? res.data.data.kompetensi : ''),
          peminatan_search        : (res.data.data.kompetensi_id !== null ? res.data.data.kompetensi_id : ''),
          peminatan_search_label  : (res.data.data.kompetensi !== null ? res.data.data.kompetensi : ''),
          rombel_search           : (res.data.data.rombel_id !== null ? res.data.data.rombel_id : ''),
          rombel_search_label     : (res.data.data.rombel !== null ? res.data.data.rombel : ''),
          kurikulum_search     : (res.data.data.kurikulum !== null ? res.data.data.kurikulum : ''),
        }, function(){
          this.getRombel();
          this.getTahunAjaranNow();
          this.getKelas();
          this.getPeminatanSMA();
          this.getBidang();
          this.getProgram();
          this.getKompetensi();
          this.urutanJenjang();
        });
      }
    }).catch(err=>{
      this.getRombel();
      this.getTahunAjaranNow();
      this.getKelas();
      this.getPeminatanSMA();
      this.getBidang();
      this.getProgram();
      this.getKompetensi();
      this.urutanJenjang();
    })
  }

  
  // SMK
  getBidang(){
    if(this.state.jenjang_id_select == 5){
      this.setState({ loading_bidang: !this.state.loading_bidang});
      axios.get(`${this.state.url_get_smk}?parent_id=0&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`, {headers:{Authorization:tokenApi}})
        .then(
          res => {
            this.setState({data_bidang: res.data});
            this.setState({ loading_bidang: false});
          }
        ).catch(err =>{
          this.setState({ loading_bidang: false});
        })
    }
  }
  getProgram(){
    if(this.state.jenjang_id_select == 5){
      this.setState({ loading_program: !this.state.loading_program});
      axios.get(`${this.state.url_get_smk}?parent_id=${this.state.bidang_search}&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`, {headers:{Authorization:tokenApi}})
        .then(
          res => {
            this.setState({data_program: res.data})
            this.setState({ loading_program: false});
          }
        ).catch(err =>{
          this.setState({ loading_program: false});
        })
    }
  }
  getKompetensi(create){
    if(this.state.jenjang_id_select == 5){
      this.setState({ loading_kompetensi: !this.state.loading_kompetensi});
      axios.get(`${this.state.url_get_smk}?parent_id=${this.state.program_search}&jenjang_id=5&kurikulum=${this.state.kurikulum_search}`, {headers:{Authorization:tokenApi}})
        .then(
          res => {
            this.setState({data_kompetensi: res.data})
            this.setState({ loading_kompetensi: false});
          }
        ).catch(err =>{
          this.setState({ loading_kompetensi: false});
        })
    }
  }

  // SHOW TYPE FILTER PER JENJANG
  showSelectFilterSD(){
    if(this.state.data_tahun_ajaran){
      return(
        <>
          <div className="flex w-1/5">
            <div className="py-2 mr-2">
            <Select
              id="filterTahunAjaran"
              styles={selectStyles}
              isClearable={true}
              isDisabled={this.state.disabledFilter}
              defaultValue={this.state.tahun_ajaran_search != '' ? {value: this.state.tahun_ajaran_search, label: this.state.tahun_ajaran_search}  : ""}
              options={this.state.data_tahun_ajaran}
              onChange={this.filterTahunAjaran}
              placeholder= {'Pilih Tahun Ajaran'}
            />
            </div>
          </div>
          <div className="flex w-1/5">
            <div className="py-2 mr-2">
            <Select
              id="filterKurikulum"
              styles={selectStyles}
              isClearable={true}
              isDisabled={this.state.disabledFilter}
              defaultValue={this.state.kurikulum_search != '' ? {value: this.state.kurikulum_search, label: captFirstLetter(this.state.kurikulum_search)}  : ""}
              options={dataKurikulum}
              onChange={this.filterKurikulum}
              placeholder= {'Pilih Kurikulum'}
            />
            </div>
          </div>
          {this.state.data_kelas ? (
            this.state.tahun_ajaran_search ? (
              <div className="flex w-1/5">
                <div className="py-2 mr-2">
                  <Select
                    id="filterKelas"
                    styles={selectStyles}
                    isClearable={true}
                    isDisabled={this.state.disabledFilter}
                    defaultValue={this.state.kelas_search != '' ? {value: this.state.kelas_search, label: this.state.kelas_search}  : ""}
                    options={this.state.data_kelas}
                    onChange={this.filterKelas}
                    placeholder= {'Pilih Kelas'}
                  />
                </div>
              </div>
            ) : (
              <div className="w-1/4 px-1">
                <div className="py-2">
                  <Skeleton count={2}/>
                </div>
              </div>
            )
          ) : ("")}
        </>
      )
    }
  }

  showSelectFilterSMA(){
    if(this.state.jenjang_id_select == '4'){
      if(this.state.data_peminatan != '' && this.state.tahun_ajaran_search != ''){
        return(
          <div className="flex w-1/5">
              <div className="py-2 mr-2">
                <Select
                  id="filterPeminatan"
                  styles={selectStyles}
                  isClearable={true}
                  isLoading={this.state.loading_peminatan ? true : false}
                  isDisabled={this.state.disabledFilter}
                  defaultValue={this.state.peminatan_search != '' ? {value: this.state.peminatan_search, label: this.state.peminatan_search_label}  : ""}
                  options={this.state.data_peminatan}
                  onChange={this.filterPeminatan}
                  placeholder= {'Pilih Peminatan'}
                />
              </div>
          </div>
        )
      } else {
        return(
          <div className="w-1/4 px-1">
            <div className="py-2">
              <Skeleton count={2}/>
            </div>
          </div>
        )
      }
    } 
  }

  showSelectFilterSMK(){
    if(this.state.jenjang_id_select == '5'){
      return(
        <>
          {this.state.kelas_search != '' && this.state.data_bidang != '' ?
          <>
            <div className="py-2 ml-2">
              <Select
                id="filterBidang"
                styles={selectStyles}
                isClearable={true}
                defaultValue={this.state.bidang_search != '' ? {value: this.state.bidang_search, label: this.state.bidang_search_label}  : ""}
                isLoading = {this.state.loading_bidang ? true : false}
                options={this.state.data_bidang}
                onChange={this.filterBidang}
                className="animateFadeInLeft"
                placeholder= {this.state.loading_bidang ? "Loading" : "Bidang Keahlian"}
              />
            </div>
          </>
          :
          <div className="w-1/4 px-1">
            <div className="py-2">
              <Skeleton count={2}/>
            </div>
          </div>
          }
          {this.state.bidang_search != '' && this.state.data_program != '' ?
          <>
            <div className="py-2 ml-2">
              <Select
                id="filterProgram"
                styles={selectStyles}
                isClearable={true}
                defaultValue={this.state.program_search != '' ? {value: this.state.program_search, label: this.state.program_search_label}  : ""}
                isLoading = {this.state.loading_program ? true : false}
                options={this.state.data_program}
                onChange={this.filterProgram}
                placeholder= {this.state.loading_program ? "Loading" : "Program Keahlian"}
              />
            </div>
          </>
          :
          <div className="w-1/4 px-1">
            <div className="py-2">
              <Skeleton count={2}/>
            </div>
          </div>
          }
          {this.state.program_search != '' && this.state.data_kompetensi != '' ?
          <>
            <div className="py-2 ml-2">
              <Select
                id="filterKompetensi"
                styles={selectStyles}
                isClearable={true}
                defaultValue={this.state.kompetensi_search != '' ? {value: this.state.kompetensi_search, label: this.state.kompetensi_search_label}  : ""}
                isLoading = {this.state.loading_kompetensi ? true : false}
                options={this.state.data_kompetensi}
                onChange={this.filterKompetensi}
                placeholder= {this.state.loading_kompetensi ? "Loading" : "Kompetensi Keahlian"}
              />
            </div>
          </>
          :
          <div className="w-1/4 px-1">
            <div className="py-2">
              <Skeleton count={2}/>
            </div>
          </div>
          }
          
        </>
      )
    }
  }

  showSelectFilterALL(){
    var kondisiJenjang = '';
    if(this.state.jenjang_id_select == 2 && this.state.kelas_search != '' ? kondisiJenjang = true : '');
    if(this.state.jenjang_id_select == 3 && this.state.kelas_search != '' ? kondisiJenjang = true  : '');
    if(this.state.jenjang_id_select == 4 && this.state.peminatan_search != '' ? kondisiJenjang = true : '');
    if(this.state.jenjang_id_select == 5 && this.state.kompetensi_search != '' ? kondisiJenjang = true : '');
    if(this.state.data_rombel != '' && kondisiJenjang == true){
      return(
        <div className="flex w-1/5">
          <div className="py-2 mr-2">
            <Select
              id="filterRombel"
              styles={selectStyles}
              isClearable={true}
              isLoading={this.state.loading_rombel ? true : false}
              isDisabled={this.state.disabledFilter}
              defaultValue={this.state.rombel_search != '' ? {value: this.state.rombel_search, label: this.state.rombel_search_label}  : ""}
              options={this.state.data_rombel}
              onChange={this.filterRombel}
              placeholder= {'Pilih Rombel'}
            />
          </div>
        </div>
      )
    } else {
      return(
        <div className="w-1/4 px-1">
          <div className="py-2">
            <Skeleton count={2}/>
          </div>
        </div>
      )
    }
  }

  urutanJenjang(){
    if(this.state.tahun_ajaran_search && this.state.kelas_search){
      if(this.state.jenjang_id_select == '3' || this.state.jenjang_id_select == '2' ? this.setState({ urutanFilter:true }) : '');
      if(this.state.jenjang_id_select == '4' && this.state.peminatan_search != '' ? this.setState({ urutanFilter:true }) : '');
      if(this.state.bidang_search != '' && this.state.program_search != '' && this.state.kompetensi_search != ''){
        if(this.state.jenjang_id_select == '5' ? this.setState({ urutanFilter:true }) : '');
      }
    }
  }

  // FILTER HANDLE CHANGE FUNCTION
  filterTahunAjaran(e){
    if(e){
      this.setState({ 
        tahun_ajaran_search : e.value, 
        data_kelas          : '',
        data_peminatan       : '',
        data_bidang         : '',
        data_program        : '',
        data_kompetensi     : '',
        data_rombel         : '',
        kelas_search        : '',
        peminatan_search    : '',
        rombel_search       : '',
        bidang_search       : '',
        program_search      : '',
        kompetensi_search   : '',
      });
    } else {
      this.setState({ 
        tahun_ajaran_search : '',
        data_kelas          : '',
        data_peminatan       : '',
        data_bidang         : '',
        data_program        : '',
        data_kompetensi     : '',
        data_rombel         : '',
        kelas_search        : '',
        peminatan_search    : '',
        rombel_search       : '',
        bidang_search       : '',
        program_search      : '',
        kompetensi_search   : '',
      });
    }
  }

  filterKurikulum(e){
    if(e){
      this.setState({ 
        kurikulum_search : e.value, 
        data_kelas          : '',
        data_peminatan       : '',
        data_bidang         : '',
        data_program        : '',
        data_kompetensi     : '',
        data_rombel         : '',
        kelas_search        : '',
        peminatan_search    : '',
        rombel_search       : '',
        bidang_search       : '',
        program_search      : '',
        kompetensi_search   : '',
      }, function(){
        this.getKelas();
        this.getBidang()
      });
    } else {
      this.setState({ 
        kurikulum_search : '',
        data_kelas          : '',
        data_peminatan       : '',
        data_bidang         : '',
        data_program        : '',
        data_kompetensi     : '',
        data_rombel         : '',
        kelas_search        : '',
        peminatan_search    : '',
        rombel_search       : '',
        bidang_search       : '',
        program_search      : '',
        kompetensi_search   : '',
      })
    }
  }

  filterKelas(e){
    if(e){
      this.setState({ 
        kelas_search      : e.value,
        data_peminatan     : '',
        data_bidang       : '',
        data_program      : '',
        data_kompetensi   : '',
        data_rombel       : '',
        peminatan_search  : '',
        rombel_search     : '',
        bidang_search     : '',
        program_search    : '',
        kompetensi_search : '',
      }, function(){
        if(this.state.jenjang_id_select == '5' ? this.getBidang() : '');
        if(this.state.jenjang_id_select == '4' ? this.getPeminatanSMA() : '');
        if(this.state.jenjang_id_select == '2' || this.state.jenjang_id_select == '3' ? this.getRombel() : '');
      });
    } else {
      this.setState({ 
        kelas_search      : '',
        data_peminatan     : '',
        data_bidang       : '',
        data_program      : '',
        data_kompetensi   : '',
        data_rombel       : '',
        peminatan_search  : '',
        rombel_search     : '',
        bidang_search     : '',
        program_search    : '',
        kompetensi_search : '',
      }, function(){
        if(this.state.jenjang_id_select == '3' || this.state.jenjang_id_select == '2' ? this.getRombel() : '');
        if(this.state.jenjang_id_select == '5' ? this.getBidang() : '');
      });
    }
  }

  filterPeminatan(e){ //Only SMA
    if(e){
      this.setState({ 
        peminatan_search       : e.value, 
        peminatan_search_label : e.label,
        data_bidang            : '',
        data_program           : '',
        data_kompetensi        : '',
        data_rombel            : '',
        rombel_search          : '',
        bidang_search          : '',
        program_search         : '',
        kompetensi_search      : '',
      }, function(){
        this.getRombel();
      });
    } else {
      this.setState({ 
        peminatan_search       : '', 
        peminatan_search_label : '',
        data_bidang            : '',
        data_program           : '',
        data_kompetensi        : '',
        data_rombel            : '',
        bidang_search          : '',
        rombel_search          : '',
        program_search         : '',
        kompetensi_search      : '',
      });
    }
  }

  
  // only for SMK
  filterBidang(e){
    if(e){
      this.setState({ 
        bidang_search           : e.value, 
        bidang_search_label     : e.label,
        data_program            : '',
        data_kompetensi         : '',
        data_rombel             : '',
        rombel_search           : '',
        program_search          : '',
        kompetensi_search       : '',
      }, function(){
        this.getProgram();
      })
    } else {
      this.setState({
        bidang_search           : '', 
        bidang_search_label     : '',
        data_program            : '',
        data_kompetensi         : '',
        data_rombel             : '',
        rombel_search           : '',
        program_search          : '',
        kompetensi_search       : '',
      })
    }
  }
  filterProgram(e){
    if(e){
      this.setState({
        program_search          : e.value, 
        program_search_label    : e.label,
        data_kompetensi         : '',
        data_rombel             : '',
        rombel_search           : '',
        kompetensi_search       : '',
      }, function(){
        this.getKompetensi();
      })
    } else {
      this.setState({
        program_search          : '', 
        program_search_label    : '',
        data_kompetensi         : '',
        data_rombel             : '',
        rombel_search           : '',
        kompetensi_search       : '',
      })
    }
  }
  filterKompetensi(e){
    if(e){
      this.setState({
        kompetensi_search       : e.value, 
        kompetensi_search_label : e.label,
        rombel_search           : '',
      }, function(){
        this.getRombel();
      })
    } else {
      this.setState({
        kompetensi_search       : '', 
        kompetensi_search_label : '',
        rombel_search           : '',
      })
    }
  }


  filterRombel(e){
    if(e){
      this.setState({ 
        rombel_search       : e.value, 
        rombel_search_label : e.label,
      });
    } else {
      this.setState({ 
        rombel_search       : '', 
        rombel_search_label : '',
      });
    }
  }

  setFilter(e){
    e.preventDefault();
    this.setState({ loadingSearch  : true });
    this.setState({ disabledFilter : true });

    var kompetensi       = '';
    var kompetensi_label = '';

    if(this.state.jenjang_id_select == '5'){
      kompetensi       = this.state.kompetensi_search;
      kompetensi_label = this.state.kompetensi_search_label;
    } else {
      kompetensi       = this.state.peminatan_search;
      kompetensi_label = this.state.peminatan_search_label;
    }

    axios.put(this.state.url_get_filter+'/'+this.state.institusi_id_select, {
      tahun_ajaran  : this.state.tahun_ajaran_search,
      kelas         : this.state.kelas_search,
      bidang        : this.state.bidang_search_label,
      bidang_id     : this.state.bidang_search,
      program       : this.state.program_search_label,
      program_id    : this.state.program_search,
      kompetensi    : kompetensi_label,
      kompetensi_id : kompetensi,
      rombel_id     : this.state.rombel_search,
      rombel        : this.state.rombel_search_label,
    }, {headers:{Authorization:tokenApi}}).then(res=>{
      this.setState({loadingSearch: false})
      this.setState({ disabledFilter: false});
    })
    
    var m_spektrum = '';
    if(this.state.jenjang_id_select == '5' ? m_spektrum = this.state.kompetensi_search : m_spektrum = this.state.peminatan_search);

    if(this.state.rombel_search !== ''){
      this.setState({ 
        url_show_data : `${this.state.url}`,
        query         : '&kelas='+this.state.kelas_search+
                        '&m_spektrum_id='+m_spektrum+
                        '&tahun_ajaran='+this.state.tahun_ajaran_search+
                        '&rombel_id='+this.state.rombel_search+
                        '&institusi_id='+this.state.institusi_id_select+
                        '&kurikulum='+this.state.kurikulum_search,
        search        : true,
      }, function(){
        this.dt.loading();
        this.dt.fetchEntities(this.state.query);
      });
    } else {
      this.setState({ 
        show_alert     : true, 
        title_alert    : "Gagal",
        icon_alert     : "error",
        message_alert  : "Isi filter dengan Benar",
        disabledFilter : false
      });
    }
  }

  setResetFilter(e){
    e.preventDefault();
    this.setState({
      data_tahun_ajaran         : '',
      data_kelas                : '',
      data_peminatan            : '',
      data_rombel               : '',
      searchText                : '',
      kelas_search              : '',
      peminatan_search          : '',
      peminatan_search_label    : '',
      rombel_search             : '',
      rombel_search_label       : '',
      tahun_ajaran_search       : '',
      disabledFilter            : false,
    }, function(){
      this.getTahunAjaranNow();
      this.getKelas();
      this.getPeminatanSMA();
      this.setState({ 
        url_show_data : '',
        query         : '',
      });
    });
  }

  // SEARCH TEXT
  searchText(e){
    e.preventDefault();
    if(this.state.url_show_data !== ''){
      var m_spektrum = '';
      if(this.state.jenjang_id_select == '5' ? m_spektrum = this.state.kompetensi_search : m_spektrum = this.state.peminatan_search);

      this.setState({
        query      : '&query='+document.getElementById("search").value+
                     '&kelas='+this.state.kelas_search+
                     '&m_spektrum_id='+m_spektrum+
                     '&tahun_ajaran='+this.state.tahun_ajaran_search+
                     '&rombel_id='+this.state.rombel_search+
                     '&institusi_id='+this.state.institusi_id_select,
        searchText : e.target.value,
      }, function(){
        this.dt.fetchEntities(this.state.query);
      });
    }
  }

  deleteSiswaRombel(e){
    e.preventDefault();
    this.setState({loading_delete: true});

    var data = document.getElementsByName('check_data[]');
    var vals = [];
    var siswa_id = [];
    for (var i=0, n=data.length;i<n;i++){
      if (data[i].checked) {
        vals.push(data[i].value);
        siswa_id.push(data[i].getAttribute('data-id'));
      }
    }

    if(vals.length == 0){
      this.setState({ 
        show_alert     : true, 
        title_alert    : "Gagal",
        icon_alert     : 'warning',
        message_alert  : "Mohon centang data terlebih dahulu",
        loading_delete : true
      });
    } else {
      swal.fire({
        title: 'Peringatan',
        text: 'Apakah anda yakin menghapus data yang dipilih?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Iya`,
        cancelButtonText: "Batal"
      }).then((result) => {
        if (result.value == true) {
          axios.post(this.state.url_delete, {
            rombel: this.state.rombel_search,
            siswa_id_rombel : vals,
            siswa_id  : siswa_id
          }, {headers:{Authorization:tokenApi}}).then(res => {
            if(res.data.status == true){
              this.setState({ 
                show_alert     : true, 
                title_alert    : "Berhasil",
                icon_alert     : "success",
                message_alert  : "Berhasil Menghapus Siswa dari rombel",
              });
              this.dt.resetPage(this.state.query);
            }
          });
        } else if (result.dismiss == "cancel") {
          this.setState({loading_delete: false});
        } else if (result.dismiss == "backdrop") {
          this.setState({loading_delete: false});
        }
      })
    }
  }

  checkAll(e){
    e.preventDefault();
    var check = document.getElementsByName('check_data[]');
    this.setState({ checkAll:true });

    if(check.length != 0){
      for(var i = 0; i < check.length; i++){
        check[i].checked = true;
      }
    }
  }

  unCheckAll(e){
    e.preventDefault();
    var check = document.getElementsByName('check_data[]');
    this.setState({ checkAll:false });

    if(check.length != 0){
      for(var i = 0; i < check.length; i++){
        check[i].checked = false;
      }
    }
  }

  tambahAlumni(e) {
    e.preventDefault();
    this.setState({ loading_alumni: true });
    var checkboxes = document.getElementsByName("check_data[]");
    var vals = [];
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].getAttribute('data-id'));
      }
    }
    console.log(vals)

    if (vals.length == 0) {
      this.setState({
        show_alert: true,
        title_alert: "Gagal",
        icon_alert: 'warning',
        message_alert: "Mohon centang data terlebih dahulu",
        disabledFilter: false,
        loading_alumni: false,
      });
    } else {
      axios
        .post(
          this.state.url_tambah_alumni,
          {
            siswa_id: vals,
            updated_by: this.state.institusi_id_select,
          },
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          // let pesan = res.data.messages.key;
          this.setState({ loading_alumni: false });
          if (res.data.status == false) {
            this.setState({
              show_alert: true,
              title_alert: "Gagal",
              icon_alert: "error",
              message_alert: res.data.messages[0],
              loading_alumni: false,
            });
            this.dt.fetchEntities(this.state.query);
          } else {
            this.setState({
              show_alert: true,
              title_alert: "Berhasil",
              icon_alert: "success",
              message_alert: res.data.messages,
              loading_alumni: false,
            });
            this.dt.fetchEntities(this.state.query);
          }
        })
        .catch((err) => {
          this.setState({ loading_alumni: false });
          this.alertMessage(err.response);
        });
    }
  }

  showData(){
    if(this.state.url_show_data != ''){
      return(
        <div className="px-1 py-1">
          <DataTable
            ref={(dt) => {
              this.dt = dt;
            }}
            action={{
              aksi:(col, data) => {
                var button = <button className="flex btn-blue text-sm text-white px-4 py-2 rounded-full items-center">Detail</button>;
                return (button);
              },

              jenis_kelamin:(col, data) => {
                var jenis_kelamin = ['Perempuan', 'Laki - laki'];
                return(<div className="text-sm">{jenis_kelamin[col.jenis_kelamin]}</div>)
              },

              tanggal_lahir: (col, data) => {
                if(col.tanggal_lahir !== null){
                  var date  = '';
                  var month = '';
                  var year  = '';

                  date      = col.tanggal_lahir.substr(8, 2);
                  month     = col.tanggal_lahir.substr(5, 2);
                  year      = col.tanggal_lahir.substr(0, 4);

                  return (<p className="text-sm">{date+"-"+month+"-"+year}</p>);
                } else {
                  return (<p className="text-sm">Tidak Diketahui</p>);
                }
              },

              username: (col, data) => {
                if(col.username !== null){
                  return (<p className="text-sm">{col.username}</p>);
                } else {
                  return (<p className="text-sm">-</p>);
                }
              },

              pilih: (col, data) => {
                return(
                  <input 
                    type="checkbox" 
                    name="check_data[]" 
                    value={data.id}
                    data-id={data.user_siswa_id}
                    className="select-checkbox"
                  />
                )
              }
            }}
            url={this.state.url_show_data}
            columns={[
              {
                index: "tahun_ajaran",
                label: "TAHUN AJARAN",
              },
              {
                index: "kode_pendaftaran",
                label: "Kode Pendaftaran",
              },
              {
                index: "nama",
                label: "NAMA LENGKAP",
              },
              {
                index: "jenis_kelamin",
                label: "JENIS KELAMIN",
              },
              {
                index: "tanggal_lahir",
                label: "TANGGAL LAHIR",
              },
              {
                index: "username",
                label: "USERNAME",
              },
              // {
              //   index: "no_handphone",
              //   label: "NOMOR HP",
              // },
              {
                index: "kelas",
                label: "KELAS",
              },
              {
                index: "nama_rombel",
                label: "ROMBEL",
              },
              {
                index: "pilih",
                label: "pilih",
              },
            ]}
          />
        </div>
      );
    } else {
      return(
        <div className="px-2 py-2 text-center text-sm font-bold text-gray-600">Select Filter terlebih dahulu</div>
      )
    }
  }

  render () {
    return(
      <div>
        <div className="shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white sm-container border">
          <div className="bg-white flex justify-between items-center mt-2 px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Siswa Per Rombongan Belajar
              <span className="text-gray-500 font-thin block text-sm">
                Daftar Siswa Per Rombongan Belajar. {" "}
                {/* <a href="/sadasd" className="text-blue-600">
                  Pelajari Lebih Lanjut ?
                </a> */}
              </span>
            </h1>
          </div>
        </div>

        {/* alert */}
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />

        <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white justify-between sm-container border">
          {this.state.loadingFilter ? 
          <div className="flex flex-wrap items-center px-3 pb-2">
            <div className="w-1/4 px-1">
              <div className="py-2">
                <Skeleton count={2}/>
              </div>
            </div>
            <div className="w-1/4 px-1">
              <div className="py-2">
                <Skeleton count={2}/>
              </div>
            </div>
            <div className="w-1/4 px-1">
              <div className="py-2">
                <Skeleton count={2}/>
              </div>
            </div>
            <div className="w-1/4 px-1">
              <div className="py-2">
                <Skeleton count={2}/>
              </div>
            </div>
            <div className="w-1/4 px-1">
              <div className="py-2">
                <Skeleton count={2}/>
              </div>
            </div>
          </div>:
          <>
            <span className="text-sm text-gray-700 px-4">Cari :</span>
            <div className="flex flex-wrap items-center px-3 pb-2">
              {this.showSelectFilterSD()}
              {this.showSelectFilterSMA()}
              {this.showSelectFilterSMK()}
              {this.showSelectFilterALL()}
              {this.state.rombel_search != '' ? (
                <div className="flex flex-row items-center">
                  <button
                    className={this.state.loadingSearch == true ? "btn-disabled px-4 py-2 ml-1 flex items-center rounded-full text-sm" : "btn-schoolmedia px-4 py-2 ml-1 flex items-center rounded-full text-white text-sm"}
                    onClick={this.setFilter}
                    disabled={this.state.loadingSearch == true ? true : false}
                  >
                    { this.state.loadingSearch == true ? (
                      "Tunggu..."
                    ) : ( "Cari" ) }
                  </button>
                  <button
                    className="btn-default text-sm text-gray-800 px-4 py-2 rounded-full items-center"
                    onClick={this.setResetFilter}
                  >
                    Reset
                  </button>
                </div>
              ): ""}
            </div>
          </>}
          
          {/* {this.state.search ? (
            <div className="flex w-full border-t flex-wrap items-center my-1 px-3 pb-2 pt-2">
              <input
                type="search"
                className="w-1/4 pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Cari disini"
                id="search"
                onChange={this.searchText}
              />
            </div>
          ) : (
            ""
          )} */}
        </div>
        <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
          {this.showData()}
        </div>
        {this.state.url_show_data != '' ? (
          <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
            <div className="w-full text-right flex justify-end px-4">
              {this.state.checkAll == false ? (
                <button className="bg-red-500 px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm" onClick={this.checkAll}>Pilih Semua</button>
              ) : (
                <button className="bg-red-500 px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm" onClick={this.unCheckAll}>Tidak Pilih Semua</button>
              )}
              <button className="bg-red-500 px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm" onClick={this.deleteSiswaRombel}>Hapus</button>
              {this.state.kelas_search == "VI" || this.state.kelas_search == "IX" || this.state.kelas_search == "XII" || this.state.kelas_search == "XIII" ? (

                  <button
                  onClick={this.tambahAlumni}
                  className="btn-green px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                  disabled={this.state.loading_alumni == true ? true : false}
                  >
                  {this.state.loading_alumni ? (
                    <UserButtonLoading />
                  ) : (
                    "Alumni"
                  )}
                  </button>
              ) : ("")}
            </div>
          </div>
        ) : ''}
      </div>
    )
  }
}