import React from "react";
import '../../styles/searchbox.css';

export default class SearchBox extends React.Component{
    render() {
        return(
            <div className="search-container">
                <form action="/search" method="get">
                    <input className="search expandright" id="searchright" type="search" name="q" placeholder="Cari" />
                    <label className="button searchbutton" htmlFor="searchright">
                        <span className="bx bx-search"></span>
                    </label>
                </form>
            </div>
        )
    }
}