import React from "react";
import DataTable from "./../../partials/table/DataTables.js";
import conf from "./../../../config.js";
import axios from "axios";
import tokenApi from "../../auth/Helpers";
import { Button, Modal } from "react-bootstrap";
import SweetAlert from "sweetalert2-react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";

import {
  UserEditSkeleton,
  DetailSkeleton,
  UserButtonLoading,
} from "../../partials/loadings/ComponentLoading.js";

// select style
const selectStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "100px",
    minWidth: "15rem",
  }),
};

const animatedComponent = makeAnimated();

function captFirstLetter(string) {
  return (
    string
      .toString()
      .charAt(0)
      .toUpperCase() + string.slice(1)
  );
}

const dataKurikulum = [
  { value: "K13", label: "K13" },
  { value: "Merdeka", label: "Merdeka" },
];

const handleClear = () => {
  setOptions((currentOptions) =>
    currentOptions.filter((currentOption) => !selected.includes(currentOption))
  );
  setSelected([]);
};

export default class PenugasanGuru extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // url API
      url: `${conf.uri_backend}api/v1/penugasan/table_penugasan/`,
      url_get_guru: `${conf.uri_backend}api/v1/penugasan/nama_pekerja/GU/`,
      url_get_penugasan: `${conf.uri_backend}api/v1/penugasan/select_penugasan/GU/1`,
      url_get_pengampu: `${conf.uri_backend}api/v1/penugasan/select_pengampu/GU/`,
      url_get_kurikulum: `${conf.uri_backend}api/v1/penugasan/select_kurikulum/`,
      url_create_penugasan: `${conf.uri_backend}api/v1/penugasan/create`,
      url_detail_penugasan: `${conf.uri_backend}api/v1/penugasan/edit/`,
      url_put_penugasan: `${conf.uri_backend}api/v1/penugasan/update/`,
      url_delete_penugasan: `${conf.uri_backend}api/v1/penugasan/delete/`,
      url_delete_penugasan_m: `${conf.uri_backend}api/v1/penugasan/penugasan_destroy/`,
      url_pindah_ta: `${conf.uri_backend}api/v1/penugasan/tahun_ajaran/update/`,
      url_set_inisial: `${conf.uri_backend}api/v1/penugasan/set-inisial`,
      // modal
      modal_tambah: false,
      modal_materi: false,
      modal_edit: false,
      // bind data form
      id_penugasan: "",
      nama: "",
      nama_guru: "",
      penugasan: "",
      penugasan_nama: "",
      pengampu: "",
      pengampu_merdeka: "",
      tahun_ajaran: "",
      materi: "",
      tahun_ajaran_lama: "",
      guru_id_lama: "",
      kurikulum_select: null,
      tahunSekarang: new Date().getFullYear(),
      // Selected Select Edit data
      dataEditMateri: [],
      // alert
      show_alert: false,
      title_alert: "",
      message_alert: {},
      icon_alert: "",
      // search and filter
      searchText: "",
      filterTahunAjaran: "",
      filterPindahTa: "",
      filterPenugasan: "",
      filterPengamuSc: "",
      simpleLoading: false,
      loading_button: null,
      isChecked: false,
      check_item: false,
      checkAll: false,
      loading_check: false,
      loading_delete: false,

      defaultValuePengampuEdit: {},
      kurikulum: [],
    };
    // Bind data
    this.nama = this.nama.bind(this);
    this.penugasan = this.penugasan.bind(this);
    this.kurikulum = this.kurikulum.bind(this);
    this.pengampu = this.pengampu.bind(this);
    this.tahun_ajaran = this.tahun_ajaran.bind(this);
    // Create Funtion
    this.showModalTambah = this.showModalTambah.bind(this);
    this.hideModalTambah = this.hideModalTambah.bind(this);
    this.create_penugasan = this.create_penugasan.bind(this);
    // Show Materi
    this.showModalMateri = this.showModalMateri.bind(this);
    this.hideModalMateri = this.hideModalMateri.bind(this);
    // Show Edit
    this.showModalEdit = this.showModalEdit.bind(this);
    this.hideModalEdit = this.hideModalEdit.bind(this);
    this.update_penugasan = this.update_penugasan.bind(this);
    // Get List data from   DB
    this.getNamaGuru = this.getNamaGuru.bind(this);
    this.getPenugasan = this.getPenugasan.bind(this);
    this.getPengampu = this.getPengampu.bind(this);
    // Delete Penugasan
    this.deletePenugasan = this.deletePenugasan.bind(this);
    // filter and search
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterPenugasan = this.filterPenugasan.bind(this);
    this.filterPengamuSc = this.filterPengamuSc.bind(this);
    this.filterPindahTa = this.filterPindahTa.bind(this);
    this.searchText = this.searchText.bind(this);
    this.setFilter = this.setFilter.bind(this);
    // get option filter
    this.getTahunAjaranNow = this.getTahunAjaranNow.bind(this);

    this.checkAll = this.checkAll.bind(this);
    this.unCheckAll = this.unCheckAll.bind(this);
    this.pindahTa = this.pindahTa.bind(this);
    this.savePindahTa = this.savePindahTa.bind(this);
    this.hideModalTa = this.hideModalTa.bind(this);
    this.deleteMulti = this.deleteMulti.bind(this);

    this.setInisial = this.setInisial.bind(this);
  }

  componentDidMount() {
    // get session user
    if (localStorage.getItem("token") !== null) {
      axios
        .post(`${conf.uri_backend}api/v1/auth/sso/user`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          this.setState({ data_session: res.data }, function() {
            axios
              .get(
                `${conf.uri_backend}api/v1/select-institusi/` +
                  this.state.data_session.access_to,
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                this.setState(
                  {
                    institusi_id_select: res.data.id,
                    jenjang_id_select: res.data.m_struktur_jenjang_id,
                    npsn_id_select: res.data.npsn,
                  },
                  function() {
                    this.dt.fetchEntities();
                    this.getNamaGuru(this.state.institusi_id_select);
                    this.getTahunAjaranNow();
                    this.getPenugasan();
                  }
                );
              });
          });
        });
    }
  }

  getTahunAjaranNow() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ data_tahun_ajaran: ta });
  }

  // Bind data form
  nama(e) {
    this.setState({ nama: e.value });
  }

  penugasan(e) {
    this.setState({ penugasan: e });
  }

  selectRef = null;
  clearValue = () => {
    this.selectRef?.select.clearValue();
  };

  kurikulum(e) {
    // if (this.state.kurikulum_select !== null) this.clearValue();
    if(this.state.kurikulum_select) this.setState({ kurikulum_select: e });
    this.setState({ kurikulum: e });
    // this.getPengampu(e.value);
    if (e?.length) {
      this.getPengampu(e[e?.length - 1]?.value);
    }
  }

  pengampu(e, kurikulum) {
    console.log("ss", e);
    console.log("ssaa", kurikulum);
    if (kurikulum.value == "K13") this.setState({ pengampu: e });
    else this.setState({ pengampu_merdeka: e });
  }

  tahun_ajaran(e) {
    this.setState({ tahun_ajaran: e.value });
  }

  // Get List Nama Guru from DB
  getNamaGuru(id) {
    axios.get(this.state.url_get_guru + id, {
        headers: { Authorization: tokenApi },
      }).then((res) => {
        this.setState({ data_nama_guru: res.data });
      });
  }

  // Get List Penugasan from DB
  getPenugasan() {
    axios.get(this.state.url_get_penugasan, {
        headers: { Authorization: tokenApi },
      }).then((res) => {
        this.setState({ data_penugasan: res.data });
      });
  }

  // Get List Pengampu from DB
  getPengampu(e){
    console.log("asa", e)
    var jenjangPengampu = '';
    var kurikulum = e
    if(this.state.jenjang_id_select == '2' ? jenjangPengampu = '1' : '');
    if(this.state.jenjang_id_select == '3' ? jenjangPengampu = '2' : '');
    if(this.state.jenjang_id_select == '4' ? jenjangPengampu = '3' : '');
    if(this.state.jenjang_id_select == '5' ? jenjangPengampu = '4' : '');
    axios.get(this.state.url_get_pengampu+jenjangPengampu+"/"+kurikulum, {headers:{Authorization:tokenApi}})
      .then(res => {
        if (e == "K13")
          this.setState({data_pengampu: res.data});
        else 
          this.setState({data_pengampu_merdeka : res.data });
      });
  }

  // Create Funtion
  showModalTambah() {
    this.setState({ modal_tambah: true });
  }

  hideModalTambah() {
    this.setState({
      modal_tambah: false,
      nama: "",
      penugasan: "",
      pengampu: "",
      tahun_ajaran: "",
      kurikulum: "",
    });
  }

  alertMessage(e) {
    let message = null;

    switch (e.status) {
      case 422:
        message = e.data.messages[0];
        break;
      case 500:
        message =
          "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
        break;
      case 401:
        message =
          "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 400:
        message =
          "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 408:
        message =
          "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 429:
        message =
          "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 405:
        message =
          "Method Tidak Diperbolehkan, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
    }

    this.setState({
      show_alert: true,
      title_alert: "Gagal",
      icon_alert: "error",
      message_alert: message,
      simpleLoading: false,
    });
  }

  // Tambah Penugasan Guru
  create_penugasan(event) {
    event.preventDefault();
    this.setState({ simpleLoading: true });

    var nama = this.state.nama;
    var penugasan = this.state.penugasan;
    var kurikulum = this.state.kurikulum;
    var pengampuCollect = "";

    if (this.state.pengampu !== "") {
      this.state.pengampu.map((pengampu, index) => {
        pengampuCollect += "," + pengampu.value;
      });
    }

    if (this.state.pengampu_merdeka !== "") {
      this.state.pengampu_merdeka.map((pengampu, index) => {
        pengampuCollect += "," + pengampu.value;
      });
    }
    console.log(pengampuCollect);
    var tahun_ajaran = this.state.tahun_ajaran;

    axios
      .post(
        this.state.url_create_penugasan,
        {
          guru_id: nama,
          penugasan_id: penugasan,
          kurikulum: kurikulum,
          id_mapel: pengampuCollect,
          tahun_ajaran: tahun_ajaran,
          institusi_id: this.state.institusi_id_select,
          created_at: new Date(),
          created_by: this.state.institusi_id_select,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        // console.log(this.state.pengampu)
        // console.log(this.state.pengampu_merdeka)
        console.log(this.state.kurikulum);
        this.setState({
          show_alert: true,
          title_alert: "Berhasil",
          icon_alert: "success",
          message_alert: res.data.messages[0],
        });
        this.hideModalTambah();
        this.dt.fetchEntities();
        this.setState({ simpleLoading: false });
      })
      .catch((err) => {
        this.setState({ simpleLoading: false });
        this.alertMessage(err.response);
      });
  }

  // Materi Show Funtion
  showModalMateri(data) {
    this.setState({ loading_view: data });
    axios
      .get(this.state.url_detail_penugasan + data, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        if (res.data.status == true) {
          // Materi
          var materiCollect = [];
          var pengampuK13 = [];
          var pengampuMerdeka = [];
          var kurikulumCollect = [];
          res.data.data.materi.map((mapel, index) => {
            materiCollect.push(mapel);
            kurikulumCollect.push(mapel.kurikulum);

            kurikulumCollect.map((value, index) => {
              return {value}
            })

            // if (kurikulumCollect == ['K13']) {
            //   pengampuK13.push({ value: mapel.id_materi, label: mapel.materi });
            // }

            // if (kurikulumCollect == ['Merdeka']) {
            //   pengampuMerdeka.push({ value: mapel.id_materi, label: mapel.materi });
            // }

            // if (mapel.kurikulum == 'K13' || mapel.kurikulum == 'Merdeka') {
            //   pengampuMerdeka.push({ value: mapel.id_materi, label: mapel.materi });
            //   pengampuK13.push({ value: mapel.id_materi, label: mapel.materi });
            // } else {
            //   pengampuMerdeka.push({ value: mapel.id_materi, label: mapel.materi });
            // }

            if (mapel.kurikulum == "K13")
              pengampuK13.push(mapel);
            else
              pengampuMerdeka.push(mapel);
          });

          console.log(res.data, "Data Kabeh");

          this.setState({
            modal_materi: true,
            loading_view: false,
            materiK13: pengampuK13,
            materiMerdeka: pengampuMerdeka,
          });
          console.log(this.state.materiK13, "Kurikulum 2013");
          console.log(this.state.materiMerdeka, "Kurikulum Merdeka");
        }
      });
  }

  hideModalMateri() {
    this.setState({ modal_materi: false, dataEditMateri: [] });
  }

  // Edit Funtion
  update_penugasan(event) {
    event.preventDefault();
    this.setState({ loading_saveedit: true });
    console.log(this.state.pengampu);

    var pengampuCollect = "";

    if (this.state.pengampu !== "") {
      this.state.pengampu.map((pengampu, index) => {
        pengampuCollect += "," + pengampu.value;
      });
    }

    if (this.state.pengampu_merdeka !== "") {
      this.state.pengampu_merdeka.map((pengampu, index) => {
        pengampuCollect += "," + pengampu.value;
      });
    }

    var tahun_ajaran = this.state.tahun_ajaran;
    // var kurikulum    = this.state.kurikulum

    axios
      .put(
        this.state.url_put_penugasan + this.state.id_penugasan,
        {
          // kurikulum         : this.state.kurikulum ? this.state.kurikulum.value : this.state.kurikulum_select.value,
          kurikulum: this.state.kurikulum,
          guru_id: this.state.nama,
          penugasan_id: this.state.penugasan,
          id_mapel: pengampuCollect,
          tahun_ajaran: this.state.tahun_ajaran,
          tahun_ajaran_lama: this.state.tahun_ajaran_lama,
          guru_id_lama: this.state.guru_id_lama,
          institusi_id: this.state.institusi_id_select,
          updated_at: new Date(),
          updated_by: this.state.institusi_id_select,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({
          show_alert: true,
          title_alert: "Berhasil",
          icon_alert: "success",
          message_alert: res.data.messages[0],
          loading_saveedit: false,
        });
        this.hideModalEdit();
        this.dt.fetchEntities();

        console.log(this.state.kurikulum);
      })
      .catch((err) => {
        this.setState({ loading_saveedit: false });
        this.alertMessage(err.response);
      });
  }

  showModalEdit(data) {
    this.setState({ loading_edit: true, modal_edit: true });
    var nama = [];
    var penugasan = [];
    var tahunAjaran = [];
    var pengampuK13 = [];
    var pengampuMerdeka = [];
    var kurikulum = [];
    axios
      .get(this.state.url_detail_penugasan + data, {
        headers: { Authorization: tokenApi },
      })
      .then((res) => {
        if (res.data.status == true) {
          let kurikulumFirstLetter = captFirstLetter(
            res.data.data.kurikulum ?? "K13"
          );
          nama.push({
            value: res.data.data.guru_id,
            label: res.data.data.nama_guru,
          });
          penugasan.push({
            value: res.data.data.penugasan_id,
            label: res.data.data.nama_penugasan_guru,
          });
          // console.log("cok", res.data.data.kurikulum.filter(x=>x!==""))
          kurikulum.push({
            value: res.data.data.kurikulum.filter(x=>x!=="") ?? "K13",
            label: kurikulumFirstLetter,
          });
          // kurikulum.push({value: res.data.data.kurikulum, label:res.data.data.kurikulum});
          tahunAjaran.push({
            value: res.data.data.tahun_ajaran,
            label: res.data.data.tahun_ajaran,
          });

          res.data.data.materi.map((mapel, index) => {
            if (mapel.kurikulum == "K13")
              pengampuK13.push({ value: mapel.id_materi, label: mapel.materi });
            else
              pengampuMerdeka.push({ value: mapel.id_materi, label: mapel.materi });
          });

          this.setState({
            pengampu: pengampuK13,
            pengampu_merdeka: pengampuMerdeka,
          });

          let dtKurikulum =
            res.data.data?.kurikulum.filter(x=>x!=="").map((e) => {
              return { label: e, value: e };
            }) ?? [];

            

          this.setState(
            {
              id_penugasan: res.data.data.id,
              nama: res.data.data.guru_id,
              penugasan: penugasan,
              tahun_ajaran: res.data.data.tahun_ajaran,
              nama_select: nama,
              penugasan_select: penugasan,
              tahun_ajaran_select: tahunAjaran,
              // pengampu_select       : pengampu,
              // pengampu_select       : dtPengampu,
              // pengampu              : dtPengampu,
              tahun_ajaran_lama: res.data.data.tahun_ajaran,
              guru_id_lama: res.data.data.guru_id,
              kurikulum_select: dtKurikulum,
              kurikulum: dtKurikulum,
            },
            function() {
              this.setState({ loading_edit: false });
            }
          );

          console.log(this.state.kurikulum, "Kurikulum");
          console.log(this.state.kurikulum_select, "Kurikulum Select");
          console.log(this.state.data_pengampu, "Pengampu K13 jancok")
          console.log(this.state.data_pengampu_merdeka, "Pengampu merdeka jancok")
          console.log(this.state.penugasan)
        } else {
          console.log("error");
        }
      });
  }

  hideModalEdit() {
    this.setState({
      modal_edit: false,
      nama: "",
      penugasan: "",
      pengampu: "",
      tahun_ajaran: "",
      nama_select: "",
      penugasan_select: "",
      tahun_ajaran_select: "",
      pengampu: "",
    });

    this.state.kurikulum_select = null;
  }

  deletePenugasan(data) {
    this.setState({ loading_delete: true });

    swal
      .fire({
        title: "Peringatan",
        text: "Apakah anda yakin menghapus penugasan tersebut?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Iya`,
        cancelButtonText: "Batal",
      })
      .then((result) => {
        if (result.value == true) {
          axios
            .delete(this.state.url_delete_penugasan + data, {
              headers: { Authorization: tokenApi },
            })
            .then((res) => {
              this.setState({
                show_alert: true,
                title_alert: "Berhasil",
                icon_alert: "success",
                message_alert: res.data.messages[0],
                loading_delete: false,
              });
              this.dt.resetPage(
                `&query=${
                  document.getElementById("search").value
                }&tahun_ajaran=${this.state.filterTahunAjaran}&penugasan=${
                  this.state.filterPenugasan
                }&mapel=${this.state.filterPengamuSc}`
              );
            })
            .catch((err) => {
              this.setState({ loading_delete: false });
              this.alertMessage(err.response);
            });
        } else if (result.dismiss == "cancel") {
          this.setState({ loading_delete: false });
        } else if (result.dismiss == "backdrop") {
          this.setState({ loading_delete: false });
        }
      });
  }

  searchText() {
    this.dt.resetPage(
      `&query=${document.getElementById("search").value}&tahun_ajaran=${
        this.state.filterTahunAjaran
      }&penugasan=${this.state.filterPenugasan}&mapel=${
        this.state.filterPengamuSc
      }`
    );
    this.dt.loading();
  }

  filterTahunAjaran(e) {
    if (e) {
      this.setState({ filterTahunAjaran: e.value });
    } else {
      this.setState({ filterTahunAjaran: "" });
    }
  }

  filterPindahTa(e) {
    if (e) {
      this.setState({ filterPindahTa: e.value });
    } else {
      this.setState({ filterPindahTa: "" });
    }
  }

  filterPenugasan(e) {
    if (e) {
      this.setState({ filterPenugasan: e.value });
    } else {
      this.setState({ filterPenugasan: "" });
    }
  }

  filterPengamuSc(e) {
    if (e) {
      this.setState({ filterPengamuSc: e.value });
    } else {
      this.setState({ filterPengamuSc: "" });
    }
  }

  setFilter(e) {
    console.log(this.dt.loadingIndicator());
    this.setState({
      loading_button: this.dt.loadingIndicator(),
    });
    e.preventDefault();
    this.searchText();
    this.dt.loading();
    this.dt.resetPage(
      `&query=${document.getElementById("search").value}&tahun_ajaran=${
        this.state.filterTahunAjaran
      }&penugasan=${this.state.filterPenugasan}&mapel=${
        this.state.filterPengamuSc
      }`
    );
  }

  checkAll(e) {
    e.preventDefault();
    this.setState({ loading_check: true }, function() {
      this.setState({ checkAll: true, loading_check: false });
    });
  }

  unCheckAll(e) {
    e.preventDefault();
    this.setState({ loading_check: true }, function() {
      this.setState({ checkAll: false, loading_check: false });
    });
  }

  deleteMulti() {
    this.setState({ loading_delete: true });
    var checkboxes = document.getElementsByName("check_data[]");
    var vals = [];
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    if (vals.length == 0) {
      this.setState({
        show_alert: true,
        title_alert: "Gagal",
        icon_alert: "warning",
        message_alert: "Mohon centang data terlebih dahulu",
        disabledFilter: false,
        loading_delete: false,
      });
    } else {
      swal
        .fire({
          title: "Peringatan",
          text: "Apakah anda yakin menghapus penugasan yang dipilih?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Iya`,
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value == true) {
            axios
              .post(
                this.state.url_delete_penugasan_m,
                { id: vals },
                { headers: { Authorization: tokenApi } }
              )
              .then((res) => {
                if (res.data.status == false) {
                  this.setState({
                    show_alert: true,
                    title_alert: "Gagal",
                    icon_alert: "error",
                    message_alert: res.data.messages[0],
                    loading_delete: false,
                    checkAll: false,
                  });
                  this.dt.loading();
                  this.dt.resetPage(
                    `&query=${
                      document.getElementById("search").value
                    }&tahun_ajaran=${this.state.filterTahunAjaran}&penugasan=${
                      this.state.filterPenugasan
                    }&mapel=${this.state.filterPengamuSc}`
                  );
                } else {
                  this.setState({
                    show_alert: true,
                    title_alert: "Berhasil",
                    icon_alert: "success",
                    message_alert: res.data.messages[0],
                    checkAll: false,
                    loading_delete: false,
                  });
                  this.dt.loading();
                  this.dt.resetPage(
                    `&query=${
                      document.getElementById("search").value
                    }&tahun_ajaran=${this.state.filterTahunAjaran}&penugasan=${
                      this.state.filterPenugasan
                    }&mapel=${this.state.filterPengamuSc}`
                  );
                }
              })
              .catch((err) => {
                this.setState({ loading_delete: false });
                this.alertMessage(err.response);
              });
          } else if (result.dismiss == "cancel") {
            this.setState({ loading_delete: false });
          } else if (result.dismiss == "backdrop") {
            this.setState({ loading_delete: false });
          }
        });
    }
  }

  hideModalTa() {
    this.setState({
      modal_pindah_ta: false,
    });
  }

  pindahTa() {
    var checkboxes = document.getElementsByName("check_data[]");
    var vals = [];
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    if (vals.length == 0) {
      this.setState({
        show_alert: true,
        title_alert: "Gagal",
        icon_alert: "warning",
        message_alert: "Mohon centang data terlebih dahulu",
        disabledFilter: false,
        loading_delete: false,
      });
    } else {
      this.setState({
        modal_pindah_ta: true,
      });
    }
  }

  savePindahTa() {
    var checkboxes = document.getElementsByName("check_data[]");
    var vals = [];
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    axios
      .post(
        this.state.url_pindah_ta,
        {
          id: vals,
          institusi_id: this.state.institusi_id_select,
          tahun_ajaran: this.state.filterPindahTa,
        },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        if (res.data.status == false) {
          this.setState({
            show_alert: true,
            title_alert: "Gagal",
            icon_alert: "error",
            message_alert: res.data.messages[0],
            loading_delete: false,
            checkAll: false,
          });
          this.dt.loading();
          this.dt.resetPage(
            `&query=${document.getElementById("search").value}&tahun_ajaran=${
              this.state.filterTahunAjaran
            }&penugasan=${this.state.filterPenugasan}&mapel=${
              this.state.filterPengamuSc
            }`
          );
        } else {
          this.setState({
            show_alert: true,
            title_alert: "Berhasil",
            icon_alert: "success",
            message_alert: res.data.messages[0],
            loading_delete: false,
            checkAll: false,
          });
          this.dt.loading();
          this.dt.resetPage(
            `&query=${document.getElementById("search").value}&tahun_ajaran=${
              this.state.filterTahunAjaran
            }&penugasan=${this.state.filterPenugasan}&mapel=${
              this.state.filterPengamuSc
            }`
          );
        }
      })
      .catch((err) => {
        this.setState({ loading_delete: false });
        this.alertMessage(err.response);
      });
  }

  setInisial(e, guru_id) {
    if (e.length == 3) {
      axios
        .post(
          this.state.url_set_inisial,
          { guru_id: guru_id, inisial: e },
          { headers: { Authorization: tokenApi } }
        )
        .then((res) => {
          this.dt.loading();
          this.dt.resetPage(
            `&query=${document.getElementById("search").value}&tahun_ajaran=${
              this.state.filterTahunAjaran
            }&penugasan=${this.state.filterPenugasan}&mapel=${
              this.state.filterPengamuSc
            }`
          );
        })
        .catch((err) => {
          this.setState({ loading_delete: false });
          this.alertMessage(err.response);
        });
    }
  }

  render() {
    // console.log(this.state.loading_button)
    return (
      <div>
        <div className="sm-container shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border">
          <div className="flex justify-between items-center mt-2  px-6 pb-2">
            <h1 className="text-xl text-gray-800 font-extrabold">
              Penugasan Guru
              <span className="text-gray-500 font-thin block text-sm">
                Daftar Penugasan Guru.
                {/* <a href="/sadasd" className="text-blue-600">
                Pelajari Lebih Lanjut ?
              </a> */}
              </span>
            </h1>
            <div className="flex">
              <button
                className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm"
                onClick={this.showModalTambah}
              >
                <i className="bx bx-plus text-lg mr-1"></i>
                Tambah Penugasan
              </button>
            </div>
          </div>
        </div>

        {/* alert */}
        <SweetAlert
          show={this.state.show_alert}
          title={this.state.title_alert}
          text={this.state.message_alert}
          type={this.state.icon_alert}
          onConfirm={() => this.setState({ show_alert: false })}
          onOutsideClick={() => this.setState({ show_alert: false })}
        />

        {/* Modal Tambah */}
        <Modal show={this.state.modal_tambah} onHide={this.hideModalTambah}>
          <Modal.Header>Tambah Data</Modal.Header>
          <Modal.Body>
            <form onSubmit={this.create_penugasan}>
              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Nama
                </label>
                <Select
                  styles={selectStyles}
                  className="react-select__control"
                  options={this.state.data_nama_guru}
                  placeholder="Pilih Nama Guru"
                  onChange={this.nama}
                />
              </div>
              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Penugasan
                </label>
                <Select
                  styles={selectStyles}
                  className="react-select__control"
                  options={this.state.data_penugasan}
                  isMulti
                  placeholder="Pilih Penugasan"
                  onChange={this.penugasan}
                  components={animatedComponent}
                />
              </div>

            <div className="w-full mt-2">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kurikulum</label>
              <Select
                styles={selectStyles}
                className="react-select__control"
                options={dataKurikulum}
                isMulti
                placeholder="Pilih Kurikulum"
                onChange={this.kurikulum}
                components={animatedComponent}
              />
            </div>
            {
              this.state.kurikulum ?
              this.state.kurikulum?.map((values, idx) => {
                let value = values?.value ?? ""
                return(
                  <div className="w-full mt-2">
                    <label className="mb-2 ml-2 text-gray-800 block text-sm">Pengampu {value}</label>
                    <Select
                      styles={selectStyles}
                      className="react-select__control"
                      isMulti
                      placeholder="Pilih Mata Pelajaran"
                      options={value == "Merdeka" ? this.state.data_pengampu_merdeka : this.state.data_pengampu}
                      onChange={(e) => {this.pengampu(e, values)}}
                      components={animatedComponent}
                    />
                  </div>
                )
              })
              :
              null
            }

              {/* <div className="w-full mt-2">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Pengampu</label>
              <Select
                styles={selectStyles}
                className="react-select__control"
                isMulti
                placeholder="Pilih Mata Pelajaran"
                options={this.state.data_pengampu}
                onChange={this.pengampu}
              />
            </div> */}

              <div className="w-full mt-2">
                <label className="mb-2 ml-2 text-gray-800 block text-sm">
                  Tahun Ajaran
                </label>
                <Select
                  styles={selectStyles}
                  className="react-select__control"
                  placeholder="Pilih Tahun Ajaran"
                  options={this.state.data_tahun_ajaran}
                  onChange={this.tahun_ajaran}
                />
              </div>

              <span className="flex flex-row items-center">
                <input
                  type="submit"
                  className={
                    this.state.simpleLoading
                      ? "btn-disabled px-4 flex items-center rounded-full py-2 mt-2 text-sm"
                      : "btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                  }
                  value={this.state.simpleLoading ? "Tunggu..." : "Tambah"}
                  disabled={this.state.simpleLoading ? true : false}
                />
              </span>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalTambah}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        {/* Modal Edit */}
        <Modal show={this.state.modal_edit} onHide={this.hideModalEdit}>
          <Modal.Header>Edit Data</Modal.Header>
          {this.state.loading_edit ? (
            <UserEditSkeleton />
          ) : (
            <Modal.Body>
              <form onSubmit={this.update_penugasan}>
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">
                    Nama
                  </label>
                  <Select
                    styles={selectStyles}
                    className="react-select__control"
                    placeholder="Pilih Nama Guru"
                    defaultValue={
                      this.state.nama_select != ""
                        ? this.state.nama_select
                        : null
                    }
                    options={this.state.data_nama_guru}
                    isDisabled={true}
                    onChange={this.nama}
                  />
                </div>
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">
                    Penugasan
                  </label>
                  <Select
                    styles={selectStyles}
                    className="react-select__control"
                    placeholder="Pilih Penugasan"
                    isMulti
                    defaultValue={
                      this.state.penugasan_select != ""
                        ? this.state.penugasan_select
                        : null
                    }
                    options={this.state.data_penugasan}
                    onChange={this.penugasan}
                    components={animatedComponent}
                  />
                </div>
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">
                    Kurikulum
                  </label>
                  <Select
                    styles={selectStyles}
                    className="react-select__control"
                    placeholder="Pilih Kurikulum"
                    isMulti
                    defaultValue={
                      this.state.kurikulum_select != ""
                        ? this.state.kurikulum_select
                        : null
                    }
                    options={dataKurikulum}
                    onChange={this.kurikulum}
                    components={animatedComponent}
                  />
                </div>
                {this.state.kurikulum_select
                  ? this.state.kurikulum_select?.map((values, idx) => {
                      let value = values?.value ?? null;
                      return (
                        <div className="w-full mt-2">
                          <label className="mb-2 ml-2 text-gray-800 block text-sm">
                            Pengampu {value}
                          </label>
                          <Select
                            styles={selectStyles}
                            className="react-select__control"
                            placeholder="Pilih Mata Pelajaran"
                            isMulti
                            defaultValue={value == 'Merdeka' ? this.state.pengampu_merdeka: this.state.pengampu}
                            options={value == 'Merdeka' ? this.state.data_pengampu_merdeka : this.state.data_pengampu}
                            onChange={(e) => {
                              this.pengampu(e, values);
                            }}
                            components={animatedComponent}
                            ref={(ref) => {
                              this.selectRef = ref;
                            }}
                          />
                        </div>
                      );
                    })
                  : null}
                <div className="w-full mt-2">
                  <label className="mb-2 ml-2 text-gray-800 block text-sm">
                    Tahun Ajaran
                  </label>
                  <Select
                    styles={selectStyles}
                    className="react-select__control"
                    placeholder="Pilih Tahun Ajaran"
                    defaultValue={
                      this.state.tahun_ajaran_select != ""
                        ? this.state.tahun_ajaran_select
                        : null
                    }
                    options={this.state.data_tahun_ajaran}
                    onChange={this.tahun_ajaran}
                    components={animatedComponent}
                  />
                </div>
                {this.state.loading_saveedit ? (
                  <button
                    className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                    disabled={
                      this.state.loading_saveedit == true ? true : false
                    }
                  >
                    <UserButtonLoading />
                  </button>
                ) : (
                  <input
                    type="submit"
                    className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"
                    value="Edit"
                  />
                )}
              </form>
            </Modal.Body>
          )}
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalEdit}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        {/* Modal Materi */}
        <Modal show={this.state.modal_materi} onHide={this.hideModalMateri}>
          <Modal.Header>Mata Pelajaran per Kelas</Modal.Header>
          <Modal.Body>
            <>
              <Tabs className="mt-4" id="uncontrolled-tab-example">
                {/* Tab Kurikulum 2013 */}
                <Tab eventKey="K13" title="Kurikulum 2013">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Mata Pelajaran</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.materiK13?.map((value, index) => {
                        return (
                          <>
                            <tr>
                              <td>{index + 1}</td>
                              <td id={value.id_materi}>{value.materi}</td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                </Tab>
                {/* Tabs Kurikulum Merdeka */}
                <Tab eventKey="Merdeka" title="Kurikulum Merdeka">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Mata Pelajaran</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.materiMerdeka?.map((value, index) => {
                          return (
                            <>
                              <tr>
                                <td>{index + 1}</td>
                                <td id={value.id_materi}>{value.materi}</td>
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                </Tab>
              </Tabs>
            </>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalMateri}
            >
              Tutup
            </button>
          </Modal.Footer>
        </Modal>

        {/* Modal Pindah Ta */}
        <Modal
          size="sm"
          show={this.state.modal_pindah_ta}
          onHide={this.hideModalTa}
        >
          <Modal.Header>Pindah Penugasan Ke Tahun Ajaran</Modal.Header>
          <Modal.Body>
            <Select
              id="filterTahunAjaran"
              styles={selectStyles}
              isClearable
              options={this.state.data_tahun_ajaran}
              onChange={this.filterPindahTa}
              placeholder={"Tahun Ajaran"}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.savePindahTa}
            >
              Pindah
            </button>
            <button
              className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm"
              onClick={this.hideModalTa}
            >
              Batal
            </button>
          </Modal.Footer>
        </Modal>

        <div className="sm-container shadow-md  mx-auto py-2 mt-2 rounded-lg bg-white border">
          <div className="flex w-full flex-wrap items-center mt-3 mb-2 px-4 pb-2">
            <button
              className="btn-blue px-4 py-2 mr-1 flex items-center rounded-full text-white text-xs"
              onClick={() => {
                this.setState({
                  showFilter: !this.state.showFilter,
                });
              }}
            >
              <i className="bx bx-filter text-lg mr-1"></i>
              Pilih
            </button>
            <div className="relative w-1/3">
              <input
                type="search"
                className="w-full pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Cari Nama Guru atau Kode Pendaftaran  "
                id="search"
                onChange={this.searchText}
              />
              <div className="absolute top-0 left-0 inline-flex items-center py-2 px-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 text-gray-400"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                  <circle cx="10" cy="10" r="7"></circle>
                  <line x1="21" y1="21" x2="15" y2="15"></line>
                </svg>
              </div>
            </div>
          </div>
          <div className="flex w-full border-t flex-wrap items-center my-1 pb-2 pt-2">
            {this.state.showFilter ? (
              <div className="flex w-full flex-wrap items-center px-4 pb-2 ">
                <div className="py-2">
                  <Select
                    id="filterTahunAjaran"
                    styles={selectStyles}
                    isClearable
                    options={this.state.data_tahun_ajaran}
                    onChange={this.filterTahunAjaran}
                    placeholder={"Tahun Ajaran"}
                  />
                </div>
                <div className="py-2 pl-2">
                  <Select
                    id="filterPenugasan"
                    styles={selectStyles}
                    isClearable
                    options={this.state.data_penugasan}
                    onChange={this.filterPenugasan}
                    placeholder={"Penugasan"}
                  />
                </div>
                <div className="py-2 pl-2">
                  <Select
                    id="filterPengampu"
                    styles={selectStyles}
                    isClearable
                    options={this.state.data_pengampu}
                    onChange={this.filterPengamuSc}
                    placeholder={"Mata Pelajaran"}
                  />
                </div>
                <button
                  onClick={this.setFilter}
                  className="flex btn-schoolmedia text-sm text-white px-4 py-2 ml-2 rounded-full items-center"
                >
                  {this.state.loading_button == true ? "Tunggu" : "Cari"}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="px-2 py-1">
            <div className="px-2 py-1 table-guru">
              <DataTable
                ref={(dt) => {
                  this.dt = dt;
                }}
                action={{
                  jenis_kelamin: (col, data) => {
                    var jekel = "";
                    if (col.jenis_kelamin == 1) {
                      jekel = "Laki - laki";
                    } else if (col.jenis_kelamin == 0) {
                      jekel = "Perempuan";
                    } else {
                      jekel = "Belum diketahui";
                    }
                    return <p className="text-sm">{jekel}</p>;
                  },

                  // inisial: (col, data) => {
                  //   var inisial = '';
                  //   if(col.nama_guru !== null){
                  //     inisial = col.nama_guru.substr(0, 3);
                  //   }
                  //   return (<p className="text-sm uppercase">{inisial}</p>);
                  // },

                  inisial: (col, data) => {
                    var inisial = "";
                    var potong = "";
                    var potong2 = "";
                    var potong4 = "";
                    var potong5 = "";
                    if (col.inisial !== null) {
                      inisial = col.inisial;
                    } else if (col.nama_guru !== null) {
                      potong = col.nama_guru.substr(0, 3);
                      potong2 = col.nama_guru.substr(0, 2);
                      potong4 = col.nama_guru.substr(0, 4);
                      potong5 = col.nama_guru.substr(0, 5);
                      if (potong == "Dr.") {
                        potong = col.nama_guru.substr(4);
                        inisial = potong.substr(0, 3);
                      } else if (potong4 == "Drs.") {
                        potong = col.nama_guru.substr(5);
                        inisial = potong.substr(0, 3);
                      } else if (potong == "Ir.") {
                        potong = col.nama_guru.substr(4);
                        inisial = potong.substr(0, 3);
                      } else if (potong5 == "Prof.") {
                        potong = col.nama_guru.substr(6);
                        inisial = potong.substr(0, 3);
                      } else if (potong2 == "H.") {
                        potong = col.nama_guru.substr(3);
                        inisial = potong.substr(0, 3);
                      } else if (potong == "Hj.") {
                        potong = col.nama_guru.substr(4);
                        inisial = potong.substr(0, 3);
                      } else {
                        inisial = potong.substr(0, 3);
                      }
                    }
                    return <p className="text-sm uppercase">{inisial}</p>;
                  },

                  mata_pelajaran: (col, data) => {
                    var buttonLihat = (
                      <button
                        onClick={this.showModalMateri.bind(
                          this,
                          data.id_penugasan
                        )}
                        disabled={
                          this.state.loading_view === data.id_penugasan
                            ? true
                            : false
                        }
                        className={`flex btn-blue text-sm text-white px-2 py-1 rounded-full items-center`}
                      >
                        {this.state.loading_view === data.id_penugasan ? (
                          <UserButtonLoading color="text-white" />
                        ) : (
                          "Lihat"
                        )}
                      </button>
                    );
                    return buttonLihat;
                  },

                  aksi: (col, data) => {
                    return (
                      <div className="flex items-center">
                        <button
                          onClick={this.showModalEdit.bind(
                            this,
                            data.id_penugasan
                          )}
                          className="btn-yellow text-lg text-white py-1 px-2 rounded-full font-medium mr-1"
                        >
                          <i className="bx bx-edit"></i>
                        </button>
                        <button
                          onClick={this.deletePenugasan.bind(
                            this,
                            data.id_penugasan
                          )}
                          className={`${
                            this.state.loading_delete === true
                              ? `btn-gray`
                              : `btn-red`
                          } text-lg text-white py-1 px-2 rounded-full font-medium`}
                          disabled={
                            this.state.loading_delete === true ? true : false
                          }
                        >
                          {this.state.loading_delete === true ? (
                            <UserButtonLoading
                              color="text-white"
                              loadingText={null}
                            />
                          ) : (
                            <i className="bx bx-trash"></i>
                          )}
                        </button>
                        {this.state.loading_check == false ? (
                          <input
                            type="checkbox"
                            name="check_data[]"
                            id={data.id_penugasan}
                            value={data.id_penugasan}
                            onChange={this.selectOne}
                            className="select-checkbox ml-2"
                            defaultChecked={this.state.checkAll}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  },
                }}
                url={this.state.url + this.state.institusi_id_select + "/GU"}
                columns={[
                  {
                    index: "tahun_ajaran",
                    label: "Tahun Ajaran",
                  },
                  {
                    index: "kode_pendaftaran",
                    label: "KODE PENDAFTARAN",
                  },
                  {
                    index: "nama_guru",
                    label: "NAMA GURU",
                  },
                  {
                    index: "inisial",
                    label: "INISIAL",
                  },
                  {
                    index: "tanggal_lahir",
                    label: "TANGGAL LAHIR",
                  },
                  {
                    index: "jenis_kelamin",
                    label: "JENIS KELAMIN",
                  },
                  {
                    index: "nama_penugasan",
                    label: "PENUGASAN",
                  },
                  {
                    index: "penugasansub",
                    label: "Penugasan Sub",
                  },
                  {
                    index: "mata_pelajaran",
                    label: "MATA PELAJARAN",
                  },
                  {
                    index: "aksi",
                    label: "AKSI",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {this.state.url_show_data != "" ? (
          <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
            <div className="w-full text-right flex justify-end px-4">
              <div className="flex flex-wrap items-baseline px-3 justify-end">
                <p>Pilih Guru dan klik tombol</p>
                {this.state.checkAll == false ? (
                  <button
                    className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                    onClick={this.checkAll}
                  >
                    Pilih Semua
                  </button>
                ) : (
                  <button
                    className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                    onClick={this.unCheckAll}
                  >
                    Tidak Pilih Semua
                  </button>
                )}
                <button
                  onClick={this.pindahTa}
                  className="btn-green px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                  disabled={this.state.loading_pindah_ta == true ? true : false}
                >
                  {this.state.loading_pindah_ta ? (
                    <UserButtonLoading />
                  ) : (
                    "Pindah ke Tahun Ajaran"
                  )}
                </button>
                <button
                  onClick={this.deleteMulti}
                  className="btn-green px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
                  disabled={this.state.loading_delete == true ? true : false}
                >
                  {this.state.loading_delete ? <UserButtonLoading /> : "Hapus"}
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  renderTableDataMapel() {
    return (
      <table className="table table-bordered" id="tableMapel">
        <thead>
          <tr>
            <th>No</th>
            <th>Mata Pelajaran</th>
          </tr>
        </thead>
        <tbody>
          {this.state.dataEditMateri.map((value, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
