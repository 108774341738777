import React from "react";
import DataTable from "../../partials/table/DataTables.js";
import conf from "../../../config.js";
import axios from "axios";
import { Button, Modal } from 'react-bootstrap';
import SweetAlert from 'sweetalert2-react';
import Select from 'react-select';
import swal from 'sweetalert2';

import "react-datepicker/dist/react-datepicker.css";
import tokenApi from "../../auth/Helpers";
import { UserEditSkeleton, DetailSkeleton, UserButtonLoading } from "../../partials/loadings/ComponentLoading.js";


// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

export default class Ruang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // url API
      url                 : `${conf.uri_backend}api/v1/sarpras-m/table/ruang/`,
      url_create          : `${conf.uri_backend}api/v1/sarpras-m/create/ruang`,
      url_get_edit        : `${conf.uri_backend}api/v1/sarpras-m/detail/ruang/`,
      url_put             : `${conf.uri_backend}api/v1/sarpras-m/ruang/`,
      url_delete          : `${conf.uri_backend}api/v1/sarpras-m/ruang/`,
      url_get_jenis_ruang : `${conf.uri_backend}api/v1/sarpras-m/sarpras/jenis_ruang/select/`,
      url_sapras_detail   : `${conf.uri_backend}api/v1/sarpras-m/sarpras/detail/`,
      url_delete_m        : `${conf.uri_backend}api/v1/sarpras-m/sarpras/destroy/ruang/`,

      // for modal
      modal_tambah        : false,
      modal_edit          : false,
      modal_detail_sarana : false,
      startDate           : new Date(),

      // bind data
      nama                      : '',
      m_sarpras_ruang_jenis_id : '',
      nama                      : '',
      lebar                     : '',
      panjang                   : '',
      kondisi_pencahayaan       : '',
      kondisi_udara             : '',
      kondisi_bangunan          : '',
      kesesuaian_ruang          : '',
      kesesuaian_jenis_barang   : '',
      kesesuaian_rasio          : '',
      id                        : '',

      // for data
      data_kesesuaian_jenis_barang  : [],
      data_jenis_ruang              : [],
      data_sarana                   : [],
      data_prasarana                : [],

      // alert
      show_alert    : false,
      title_alert   : '',
      message_alert : '',
      icon_alert    : '',

      // data session
      institusi_id_select : '',
      jenjang_id_select   : '',
      data_session        : [],
      
      isChecked:false,
      check_item            : false,
      checkAll              : false,
      loading_check         : false,
    };

    // bind data
    this.id                         = this.id.bind(this);
    this.m_sarpras_ruang_jenis_id  = this.m_sarpras_ruang_jenis_id.bind(this);
    this.nama                       = this.nama.bind(this);
    this.lebar                      = this.lebar.bind(this);
    this.panjang                    = this.panjang.bind(this);
    this.kondisi_pencahayaan        = this.kondisi_pencahayaan.bind(this);
    this.kondisi_udara              = this.kondisi_udara.bind(this);
    this.kondisi_bangunan           = this.kondisi_bangunan.bind(this);
    this.kesesuaian_ruang           = this.kesesuaian_ruang.bind(this);
    this.kesesuaian_jenis_barang    = this.kesesuaian_jenis_barang.bind(this);
    this.kesesuaian_rasio           = this.kesesuaian_rasio.bind(this);

    this.showDetailReferensi      = this.showDetailReferensi.bind(this);
    this.showModalDetailSarana    = this.showModalDetailSarana.bind(this);
    this.hideModalDetailSarana    = this.hideModalDetailSarana.bind(this);
    this.showModalDetailPrasarana = this.showModalDetailPrasarana.bind(this);
    this.hideModalDetailPrasarana = this.hideModalDetailPrasarana.bind(this);

    // Create Function
    this.showModalTambah  = this.showModalTambah.bind(this);
    this.hideModalTambah  = this.hideModalTambah.bind(this);
    this.create           = this.create.bind(this);

    // Delete Function
    this.delete = this.delete.bind(this);

    // Edit Function
    this.showModalEdit  = this.showModalEdit.bind(this);
    this.hideModalEdit  = this.hideModalEdit.bind(this);
    this.update         = this.update.bind(this);

    // Reset Search Function
    this.resetSearch = this.resetSearch.bind(this);
    
    this.checkAll           = this.checkAll.bind(this);
    this.unCheckAll         = this.unCheckAll.bind(this);
    this.deleteMulti        = this.deleteMulti.bind(this);
  }

  componentDidMount() {
    // get session user
    if(localStorage.getItem('token') !== null)
    {
      axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')}).then(res => {
        this.setState({ data_session: res.data }, function(){
          axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {
            headers: {
              Authorization: tokenApi
            }
          }).then(res => {
            this.setState({
              institusi_id_select : res.data.id,
              jenjang_id_select   : res.data.m_struktur_jenjang_id,
              npsn_id_select      : res.data.npsn,
            }, function(){
              this.dt.fetchEntities();
              this.getDataJenisKesesuaianBarang();
              this.getDataJenisRuang();
            });
          });
        });
      });
    }

  }

  // bind data
  id(e){
    this.setState({ id: e.target.value });
  }
  m_sarpras_ruang_jenis_id(e){
    this.setState({ m_sarpras_ruang_jenis_id: e.value });
    this.showDetailReferensi(e.value);
  }
  nama(e){
    this.setState({ nama: e.target.value });
  }
  lebar(e){
    if(e.target.value < 0){
      this.setState({lebar: '0'})
      document.getElementById('lebar').value = "0";
    } else {
      this.setState({ lebar: e.target.value });
    }
  }
  panjang(e){
    if(e.target.value < 0){
      this.setState({panjang: '0'})
      document.getElementById('panjang').value = "0";
    } else {
      this.setState({ panjang: e.target.value });
    }
  }
  kondisi_pencahayaan(e){
    this.setState({ kondisi_pencahayaan: e.target.value });
  }
  kondisi_udara(e){
    this.setState({ kondisi_udara: e.target.value });
  }
  kondisi_bangunan(e){
    this.setState({ kondisi_bangunan: e.target.value });
  }
  kesesuaian_ruang(e){
    if(e.target.value < 0){
      this.setState({kesesuaian_ruang: '0'})
      document.getElementById('kesesuaian_ruang').value = "0";
    } else {
      this.setState({ kesesuaian_ruang: e.target.value });
    }
  }
  kesesuaian_jenis_barang(e){
    this.setState({ kesesuaian_jenis_barang: e.value });
  }
  kesesuaian_rasio(e){
    if(e.target.value < 0){
      this.setState({kesesuaian_rasio: '0'})
      document.getElementById('kesesuaian_rasio').value = "0";
    } else {
      this.setState({ kesesuaian_rasio: e.target.value });
    }
  }

  // Create Ruang
  showModalTambah(){
    this.setState({ modal_tambah:true });
  }

  hideModalTambah(){
    this.setState({
      modal_tambah              : false,
      nama                      : '',
      m_sarpras_ruang_jenis_id : '',
      lebar                     : '',
      panjang                   : '',
      kondisi_pencahayaan       : '',
      kondisi_udara             : '',
      kondisi_bangunan          : '',
      kesesuaian_ruang          : '',
      kesesuaian_jenis_barang   : '',
      kesesuaian_rasio          : '',
    });
  }

  getDataJenisKesesuaianBarang(){
    var data = [
      {label : "Melebihi Standar", value : "Melebihi Standar"},
      {label : "Sesuai Standar",   value : "Sesuai Standar"},
      {label : "Kurang Sesuai",    value : "Kurang Sesuai"},
      {label : "Sangat Kurang",    value : "Sangat Kurang"},
    ]
    this.setState({ data_kesesuaian_jenis_barang: data });
  }

  getDataJenisRuang(){
    axios.get(this.state.url_get_jenis_ruang+this.state.jenjang_id_select, {
      headers: {
        Authorization: tokenApi
      }
    })
      .then(res => {
        this.setState({ data_jenis_ruang: res.data });
      });
  }

  alertMessage(e){
    let message = null;

    switch(e.status){
      case 422:
        message = e.data.messages[0];
      break;
      case 500:
        message= "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
      break;
      case 401:
        message = "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 400:
        message = "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 408:
        message = "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 429:
        message = "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
      case 405:
        message = "Method Tidak Diperbolehkan, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
      break;
    }

    this.setState({
      show_alert    : true,
      title_alert   : "Gagal",
      icon_alert    : "error",
      message_alert : message,
      loading_button: false,
    })
  }

  create(event){
    event.preventDefault();

    this.setState({loading_button: true})
    axios.post(this.state.url_create, {
      nama                     : this.state.nama,
      user_institusi_id        : this.state.institusi_id_select,
      m_sarpras_ruang_jenis_id : this.state.m_sarpras_ruang_jenis_id,
      lebar                    : this.state.lebar,
      panjang                  : this.state.panjang,
      kondisi_pencahayaan      : this.state.kondisi_pencahayaan,
      kondisi_udara            : this.state.kondisi_udara,
      kondisi_bangunan         : this.state.kondisi_bangunan,
      kesesuaian_ruang         : this.state.kesesuaian_ruang,
      kesesuaian_jenis_barang  : this.state.kesesuaian_jenis_barang,
      kesesuaian_rasio         : this.state.kesesuaian_rasio,
      created_at               : new Date(),
      created_by               : this.state.institusi_id_select,
    }, {
      headers: {
        Authorization: tokenApi
      }
    })
      .then(res => {
        this.setState({
          show_alert    : true,
          title_alert   : "Berhasil",
          icon_alert    : "success",
          message_alert : res.data.messages[0],
          loading_button: false
        });
        this.hideModalTambah();
        this.dt.fetchEntities();
      })
      .catch(err => {
        this.setState({loading_button: false});
        this.alertMessage(err.response);
      })
  }

  // Edit Ruang
  showModalEdit(data){
    this.setState({modal_edit : true, loading_edit: true})
    axios.get(this.state.url_get_edit+data, {
      headers: {
        Authorization: tokenApi
      }
    })
    .then(res => {
        if(res.data.status == true){
          this.setState({
            id                        : res.data.data.id,
            nama                      : res.data.data.nama,
            m_sarpras_ruang_jenis_id : res.data.data.m_sarpras_ruang_jenis_id,
            nama_jenis_ruang          : res.data.data.jenis_ruang,
            lebar                     : res.data.data.lebar,
            panjang                   : res.data.data.panjang,
            kondisi_pencahayaan       : res.data.data.kondisi_pencahayaan,
            kondisi_udara             : res.data.data.kondisi_udara,
            kondisi_bangunan          : res.data.data.kondisi_bangunan,
            kesesuaian_ruang          : res.data.data.kesesuaian_ruang,
            kesesuaian_jenis_barang   : res.data.data.kesesuaian_jenis_barang,
            kesesuaian_rasio          : res.data.data.kesesuaian_rasio,
            loading_edit    : false,
          });
        } else {
          console.log("error");
        }
    });

  }

  update(event){
    event.preventDefault();

    this.setState({loading_saveedit: true})
    axios.put(this.state.url_put, {
      id                       : this.state.id,
      user_institusi_id        : this.state.institusi_id_select,
      nama                     : this.state.nama,
      m_sarpras_ruang_jenis_id : this.state.m_sarpras_ruang_jenis_id,
      lebar                    : this.state.lebar,
      panjang                  : this.state.panjang,
      kondisi_pencahayaan      : this.state.kondisi_pencahayaan,
      kondisi_udara            : this.state.kondisi_udara,
      kondisi_bangunan         : this.state.kondisi_bangunan,
      kesesuaian_ruang         : this.state.kesesuaian_ruang,
      kesesuaian_jenis_barang  : this.state.kesesuaian_jenis_barang,
      kesesuaian_rasio         : this.state.kesesuaian_rasio,
      update_by                : this.state.institusi_id_select,
      update_at                : new Date(),
    }, {
      headers: {
        Authorization: tokenApi
      }
    })
      .then(res => {
        this.setState({
          show_alert    : true,
          title_alert   : "Berhasil",
          icon_alert    : "success",
          message_alert : res.data.messages[0],
          loading_saveedit: false
        });
        this.hideModalEdit();
        this.dt.fetchEntities();
      })
      .catch(err => {
        this.setState({loading_saveedit: false})
        this.alertMessage(err.response);
      })
  }

  hideModalEdit(){
    this.setState({ 
      modal_edit                : false,
      nama                      : '',
      m_sarpras_ruang_jenis_id : '',
      lebar                     : '',
      panjang                   : '',
      kondisi_pencahayaan       : '',
      kondisi_udara             : '',
      kondisi_bangunan          : '',
      kesesuaian_ruang          : '',
      kesesuaian_jenis_barang   : '',
      kesesuaian_rasio          : '',
    });
  }

  showDetailReferensi(data){
    if(data !== ""){
      return(
        <div className="w-full flex justify-between mt-4">
          <p className="text-gray-800 block text-sm">Untuk melihat referensi Lahan Klik detail</p>
          {this.state.loading_sarana ? <UserButtonLoading disabled={this.state.loading_sarana == true ? true : false}
            />:
            <a href="#"
              onClick={this.showModalDetailSarana.bind(this, data, "sarana")}
              className="mb-4 bg-blue-500 text-white px-3 text-base py-1 rounded-full font-medium">
              <i className="bx bx-show-alt pr-1 text-base"></i> Detail Sarana
            </a>
          }
          {this.state.loading_prasarana ? <UserButtonLoading disabled={this.state.loading_prasarana == true ? true : false}/>:
              <a href="#"
              onClick={this.showModalDetailPrasarana.bind(this, data, "prasarana")}
              className="mb-4 bg-blue-500 text-white px-3 text-base py-1 rounded-full font-medium">
                <i className="bx bx-show-alt pr-1 text-base"></i> Detail Prasarana
            </a>
          }
        </div>
      );
    } else {
      return("");
    }
  }

  showModalDetailPrasarana(data, jenis){
    this.setState({loading_prasarana: true})
    axios.get(this.state.url_sapras_detail+data+"/"+jenis, {
      headers: {
        Authorization: tokenApi
      }
    }).then(res => {
      this.setState({
        data_prasarana          : res.data,
        modal_detail_prasarana  : true,
        loading_prasarana: false,
      });
    }).catch((err)=>{
      this.alertMessage(err.response);
    })
  }

  showModalDetailSarana(data, jenis){
    this.setState({loading_sarana: true})
    axios.get(this.state.url_sapras_detail+data+"/"+jenis, {
      headers: {
        Authorization: tokenApi
      }
    }).then(res => {
      this.setState({
        data_sarana         : res.data,
        modal_detail_sarana : true,
        loading_sarana: false,
      });
    }).catch((err)=>{
      this.alertMessage(err.response);
    })
  }
  
  hideModalDetailSarana(){
    this.setState({ modal_detail_sarana:false });
  }
  hideModalDetailPrasarana(){
    this.setState({ modal_detail_prasarana:false });
  }

  // Delete Ruang
  delete(data){
    this.setState({loading_delete: true});

    swal.fire({
      title: 'Peringatan',
      text: 'Apakah anda yakin menghapus ruang tersebut?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Iya`,
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.value == true) {
        axios.delete(this.state.url_delete+data, {
          headers: {
            Authorization: tokenApi
          }
        }).then(res => {
          this.setState({
            show_alert    : true,
            title_alert   : "Berhasil Menghapus",
            icon_alert    : "success",
            message_alert : res.data.messages[0],
            loading_delete: false,
          });
          this.dt.resetPage();
        }).catch((err)=>{
          this.alertMessage(err.response)
        })
      } else if (result.dismiss == "cancel") {
        this.setState({loading_delete: false});
      } else if (result.dismiss == "backdrop") {
          this.setState({loading_delete: false});
        }
    })
  }

  checkAll(e){
    e.preventDefault();
    this.setState({ loading_check : true }, function(){
      this.setState({ checkAll:true, loading_check: false });
    })
  }

  unCheckAll(e){
    e.preventDefault();
    this.setState({ loading_check : true }, function(){
      this.setState({ checkAll:false, loading_check: false });
    })
  }

  deleteMulti(){
    this.setState({loading_delete: true});
    var checkboxes = document.getElementsByName('check_data[]');
    var vals = [];
    for (var i=0, n=checkboxes.length;i<n;i++){
      if (checkboxes[i].checked) {
        vals.push(checkboxes[i].value);
      }
    }

    if(vals.length == 0){
      this.setState({ 
        show_alert     : true, 
        title_alert    : "Gagal",
        icon_alert     : 'warning',
        message_alert  : "Mohon centang data terlebih dahulu",
        disabledFilter : false,
        loading_delete : false,
      });
    } else {
      swal.fire({
        title: 'Peringatan',
        text: 'Apakah anda yakin menghapus ruang yang dipilih?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Iya`,
        cancelButtonText: 'Batal'
      }).then((result) => {
        if (result.value == true) {
          axios.post(this.state.url_delete_m, {data  : vals}, {headers:{Authorization:tokenApi}})
            .then(res => {
              if(res.data.status == false){
                this.setState({ 
                  show_alert      : true,
                  title_alert     : "Gagal",
                  icon_alert      : "error",
                  message_alert   : res.data.messages[0],
                  loading_delete  : false,
                  checkAll        : false,
                });
                this.dt.loading();
                this.dt.resetPage();
              } else {
                this.setState({ 
                  show_alert      : true,
                  title_alert     : "Berhasil",
                  icon_alert      : "success",
                  message_alert   : res.data.messages,
                  loading_delete  : false,
                  checkAll        : false,
                });
                this.dt.loading();
                this.dt.resetPage();
              }
            })
            .catch(err => {
              this.setState({loading_delete : false})
              this.alertMessage(err.response);
            })
        } else if (result.dismiss == "cancel") {
          this.setState({loading_delete: false});
        } else if (result.dismiss == "backdrop") {
          this.setState({loading_delete: false});
        }
      })
    }
  }

  // reset search
  resetSearch(event){
    event.preventDefault();
    document.getElementById("search").value = "";
    this.dt.fetchEntities();
  }

  render() {
    return (
     <div>
      <div className=" shadow-md  mx-auto py-3 mt-6 rounded-lg bg-white border sm-container">
        <div className="flex justify-between items-center mt-2  px-6 pb-2">
          <h1 className="text-xl text-gray-800 font-extrabold">
            Sarana & Prasarana
            <span className="text-gray-500 font-thin block text-sm">
              Daftar Ruang.
              {/* <a href="/sadasd" className="text-blue-600 ml-1">
                Pelajari Lebih Lanjut ?
              </a> */}
            </span>
          </h1>
          <div className="flex">
            <button className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 text-sm" onClick={this.showModalTambah}>
              <i className="bx bx-plus text-lg mr-1"></i>
              Tambah Ruang
            </button>
          </div>
        </div>
      </div>

      {/* alert */}
      <SweetAlert
        show={this.state.show_alert}
        title={this.state.title_alert}
        text={this.state.message_alert}
        type={this.state.icon_alert}
        onConfirm={() => this.setState({ show_alert: false })}
        onOutsideClick={() => this.setState({ show_alert: false })}
      />

      <Modal size="lg" show={this.state.modal_detail_sarana} onHide={this.hideModalDetailSarana}>
        <Modal.Header>Detail</Modal.Header>
        <Modal.Body>
        <table className="table table-bordered font-thin text-sm">
          <thead className="text-center">
            <th>Nama Sarana</th>
            <th>Jenis Sarana</th>
            <th>Rasio</th>
            <th>Deskripsi Sarana</th>
          </thead>
          <tbody>
            {this.state.data_sarana.map((sarana, index) => (
              <tr>
              <td><div className="text-sm">{sarana.nama_sarana}</div></td>
              <td><div className="text-sm">{sarana.jenis_sarana}</div></td>
              <td><div className="text-sm">{sarana.rasio}</div></td>
              <td><div className="text-sm">{sarana.deskripsi_sarana}</div></td>
              </tr>
            ))}
          </tbody>
        </table>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideModalDetailSarana}>Tutup</button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={this.state.modal_detail_prasarana} onHide={this.hideModalDetailPrasarana}>
        <Modal.Header>Detail</Modal.Header>
        <Modal.Body>
        <table className="table table-bordered font-thin text-sm">
          <thead className="text-center">
            <th>Kode Prasarana</th>
            <th>Deskripsi Prasarana</th>
          </thead>
          <tbody>
            {this.state.data_prasarana.map((prasarana, index) => (
              <tr>
              <td><div className="text-sm">{prasarana.kode_prasarana}</div></td>
              <td><div className="text-sm">{prasarana.deskripsi_prasarana}</div></td>
              </tr>
            ))}
          </tbody>
        </table>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideModalDetailPrasarana}>Tutup</button>
        </Modal.Footer>
      </Modal>

      {/* modal tambah */}
      <Modal size="lg" show={this.state.modal_tambah} onHide={this.hideModalTambah}>
        <Modal.Header>Tambah Ruang</Modal.Header>
        <Modal.Body>
          <form onSubmit={this.create} className="mt-4">
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Jenis Ruang</label>
              <Select
                styles={selectStyles}
                className="react-select__control"
                placeholder="Pilih Jenis Ruang"
                options={this.state.data_jenis_ruang}
                onChange={this.m_sarpras_ruang_jenis_id}
              />
            </div>
            <div className="w-full mt-1">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Nama</label>
              <input
                type="text"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Nama Ruang"
                onChange={this.nama}
                maxLength="255"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Lebar (meter)</label>
              <input
                type="number"
                id="lebar"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Lebar Ruang"
                onChange={this.lebar}
                max="1000000"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Panjang (meter)</label>
              <input
                type="number"
                id="panjang"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Panjang Ruang"
                onChange={this.panjang}
                max="1000000"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kondisi Pencahayaan</label>
              <input
                type="text"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Kondisi Pencahayaan Ruang"
                onChange={this.kondisi_pencahayaan}
                maxLength="255"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kondisi Udara</label>
              <input
                type="text"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Kondisi Udara Ruang"
                onChange={this.kondisi_udara}
                maxLength="255"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kondisi Bangunan</label>
              <input
                type="text"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Kondisi Bangunan Ruang"
                onChange={this.kondisi_bangunan}
                maxLength="255"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kesesuaian Ruang (%)</label>
              <input
                type="number"
                id="kesesuaian_ruang"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Kesesuaian Ruang"
                onChange={this.kesesuaian_ruang}
                max="100"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kesesuaian Jenis Barang</label>
              <Select
              styles={selectStyles}
                className="react-select__control"
                placeholder="Pilih Kesesuaian Jenis Barang"
                options={this.state.data_kesesuaian_jenis_barang}
                onChange={this.kesesuaian_jenis_barang}
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kesesuaian Kesesuaian Rasio (%)</label>
              <input
                type="number"
                id="kesesuaian_rasio"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Kesesuaian Rasio Ruang"
                onChange={this.kesesuaian_rasio}
                max="100"
              />
            </div>
            <hr className="mt-2"/>
            {this.showDetailReferensi(this.state.m_sarpras_ruang_jenis_id)}
            <hr/>
            <input type="submit" className={this.state.loading_button == true ? "btn-disabled px-4 flex items-center rounded-full py-2 mt-2 text-sm" : "btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm"} value={this.state.loading_button == true ? 'Menyimpan...' : 'Simpan'} disabled={this.state.loading_button == true ? true : false}/>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideModalTambah}>Tutup</button>
        </Modal.Footer>
      </Modal>

      {/* modal edit */}
      <Modal size="lg" show={this.state.modal_edit} onHide={this.hideModalEdit}>
        <Modal.Header>Edit Ruang</Modal.Header>
        {this.state.loading_edit ?
        <UserEditSkeleton/>
        :
        <Modal.Body>
          <form onSubmit={this.update}>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Jenis Ruang</label>
              <Select
                styles={selectStyles}
                className="react-select__control"
                placeholder="Pilih Jenis Ruang"
                defaultValue={{value:this.state.m_sarpras_ruang_jenis_id, label:this.state.nama_jenis_ruang}}
                options={this.state.data_jenis_ruang}
                onChange={this.m_sarpras_ruang_jenis_id}
              />
            </div>
            <div className="w-full mt-1">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Nama</label>
              <input
                type="text"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Nama Ruang"
                value={this.state.nama}
                onChange={this.nama}
                maxLength="255"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Lebar</label>
              <input
                type="number"
                id="lebar"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Lebar Ruang"
                value={this.state.lebar}
                onChange={this.lebar}
                max="1000000"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Panjang</label>
              <input
                type="number"
                id="panjang"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Panjang Ruang"
                value={this.state.panjang}
                onChange={this.panjang}
                max="1000000"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kondisi Pencahayaan</label>
              <input
                type="text"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Kondisi Pencahayaan Ruang"
                value={this.state.kondisi_pencahayaan}
                onChange={this.kondisi_pencahayaan}
                maxLength="255"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kondisi Udara</label>
              <input
                type="text"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Kondisi Udara Ruang"
                value={this.state.kondisi_udara}
                onChange={this.kondisi_udara}
                maxLength="255"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kondisi Bangunan</label>
              <input
                type="text"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Kondisi Bangunan Ruang"
                value={this.state.kondisi_bangunan}
                onChange={this.kondisi_bangunan}
                maxLength="255"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kesesuaian Ruang</label>
              <input
                type="number"
                id="kesesuaian_ruang"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Kesesuaian Ruang"
                value={this.state.kesesuaian_ruang}
                onChange={this.kesesuaian_ruang}
                max="100"
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kesesuaian Jenis Barang</label>
              <Select
                styles={selectStyles}
                className="react-select__control"
                placeholder="Pilih Kesesuaian Jenis Barang"
                defaultValue={{value:this.state.kesesuaian_jenis_barang, label:this.state.kesesuaian_jenis_barang}}
                options={this.state.data_kesesuaian_jenis_barang}
                onChange={this.kesesuaian_jenis_barang}
              />
            </div>
            <div className="w-full mt-3">
              <label className="mb-2 ml-2 text-gray-800 block text-sm">Kesesuaian Kesesuaian Rasio</label>
              <input
                type="number"
                id="kesesuaian_rasio"
                className="w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
                placeholder="Masukkan Kesesuaian Rasio Ruang"
                value={this.state.kesesuaian_rasio}
                onChange={this.kesesuaian_rasio}
                max="100"
              />
              {this.showDetailReferensi(this.state.m_sarpras_ruang_jenis_id)}
            </div>
            {this.state.loading_saveedit ? 
            <button className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm" disabled={this.state.loading_saveedit == true ? true : false}>
              <UserButtonLoading/>
            </button>:
            <input type="submit" className="btn-schoolmedia px-4 flex items-center rounded-full text-white py-2 mt-2 text-sm" value="Edit" />
            }
          </form>
        </Modal.Body>}
        <Modal.Footer>
          <button className="btn-default mx-1 px-4 py-2 justify-center font-medium rounded-full text-gray-800 items-center text-sm" onClick={this.hideModalEdit}>Tutup</button>
        </Modal.Footer>
      </Modal>

      <div className=" shadow-md  mx-auto py-2 mt-2 rounded-lg bg-white border sm-container">
        <div className="w-full flex flex-col mt-3 mb-2 px-4 pb-2">
          <span className="text-sm text-gray-800 px-1">Cari :</span>
          <div className="relative w-1/3 flex flex-row">
            <input
              type="search"
              id="search"
              className="w-full pl-10 pr-4 py-1 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800 "
              placeholder="Cari berdasarkan nama ruang"
              onChange={(event) => {
                this.dt.resetPage(`&query=${event.target.value}`);
                this.dt.loading();
              }}
            />
            <div className="absolute top-0 left-0 inline-flex items-center py-2 px-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 text-gray-400"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                <circle cx="10" cy="10" r="7"></circle>
                <line x1="21" y1="21" x2="15" y2="15"></line>
              </svg>
            </div>
          </div>
        </div>

        <div className="px-2 py-1">
          <DataTable
            ref={(dt) => {
              this.dt = dt;
            }}
            action={{
              created_at: (col, data) => {
                if(col.created_at !== null){
                  var date = '';
                  var month = '';
                  var year = '';
                  date = col.created_at.substr(8, 2);
                  month = col.created_at.substr(5, 2);
                  year = col.created_at.substr(0, 4);

                  return (<p className="text-sm">{date+"-"+month+"-"+year}</p>);
                } else {
                  return (<p className="text-sm">Tidak Diketahui</p>);
                }
              },

              aksi:(col, data) => {
                return (
                  <div className="text-gray-700 px-4 py-1 flex items-center">
                    <button onClick={this.showModalEdit.bind(this, data.id_ruang)} className="btn-yellow text-lg text-white py-1 px-2 rounded-full font-medium mr-1">
                      <i className="bx bx-edit"></i>
                    </button>
                    <button onClick={this.delete.bind(this, data.id_ruang)} className={`${this.state.loading_delete === data.id_ruang ?`btn-gray`:`btn-red`} text-lg text-white py-1 px-2 rounded-full font-medium`} disabled={this.state.loading_delete === data.id_ruang ? true : false}>
                    {this.state.loading_delete === data.id_ruang ?<UserButtonLoading color="text-white" loadingText={null}/>:<i className="bx bx-trash"></i>}
                    </button>
                    {this.state.loading_check == false ? (
                      <input type="checkbox" name="check_data[]" id={data.id_ruang} value={data.id_ruang}
                        className="select-checkbox ml-2"
                        defaultChecked={this.state.checkAll}
                      />
                    ):("")}
                  </div>
                );
              }

            }}
            url={this.state.url+this.state.institusi_id_select}
            columns={[
              {
                index: "nama",
                label: "NAMA Ruang",
              },
              {
                index: "jenis_ruang",
                label: "Jenis Ruang",
              },
              {
                index: "lebar",
                label: "Lebar",
              },
              {
                index: "panjang",
                label: "Panjang",
              },
              {
                index: "kondisi_pencahayaan",
                label: "Kondisi Pencahayaan",
              },
              {
                index: "kondisi_udara",
                label: "Kondisi Udara",
              },
              {
                index: "kondisi_bangunan",
                label: "Kondisi Bangunan",
              },
              {
                index: "kesesuaian_ruang",
                label: "Kesesuaian Ruang",
              },
              {
                index: "kesesuaian_jenis_barang",
                label: "Kesesuaian Jenis Barang",
              },
              {
                index: "kesesuaian_rasio",
                label: "Kesesuaian Rasio",
              },
              {
                index: "created_at",
                label: "Dibuat Tanggal",
              },
              {
                index: "aksi",
                label: "AKSI",
              },
            ]}
          />
        </div>
      </div>

      <div className="shadow-md mx-auto py-3 mt-2 rounded-lg bg-white sm-container border">
        <div className="w-full text-right flex justify-end px-4">
          <div className="flex flex-wrap items-baseline px-3 justify-end">
            <p>Pilih Ruang dan klik tombol</p>
            {this.state.checkAll == false ? (
              <button className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm" onClick={this.checkAll}>Pilih Semua</button>
            ) : (
              <button className="btn-red px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm" onClick={this.unCheckAll}>Tidak Pilih Semua</button>
            )}
            <button
              onClick={this.deleteMulti} 
              className="btn-green px-4 py-2 ml-2 flex items-center rounded-full text-white text-sm"
              disabled={this.state.loading_delete == true ? true : false}
            >
              {this.state.loading_delete ? <UserButtonLoading/> :'Hapus'}
            </button>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
