import React from 'react';
import { NavLink } from 'react-router-dom'

export default class SideBarJadwal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            step: 'text-gray-500',
        }

        this.stepSelesai = this.stepSelesai.bind(this);
    }
    stepSelesai() {
        this.setState({step: 'text-green-500'})
    }
    render (){
        return (
          <React.Fragment>
            <div
              className="w-2/12 px-3 py-4 rounded-lg shadow-md bg-white border"
              style={{ height: "max-content" }}
            >
              <div className="sidebar-jadwal relative">
                <ul className="list-none m-0 p-0 z-10">
                  <li className="mb-3">
                    <NavLink
                      to="/jadwal-pelajaran/atur-waktu"
                      activeClassName="sidebar-active"
                      style={{ cursor: "pointer" }}
                      // onClick={e => e.preventDefault()}
                      // onChange={this.stepSelesai}
                    >
                      <div className="flex items-center mb-1">
                        <div className="bg-gray-500 rounded-full h-5 w-5 flex items-center justify-center z-10 sidebar-dot">
                          <p className="text-white text-xs">1</p>
                        </div>
                        <div
                          className={`flex-1 ml-4 text-sm ${this.state.step}`}
                        >
                          Waktu Pelajaran
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li className="mb-3">
                    <NavLink
                      to="/jadwal-pelajaran/atur-mapel-rombel"
                      activeClassName="sidebar-active"
                      style={{ cursor: "pointer" }}
                      // onClick={e => e.preventDefault()}
                    >
                      <div className="flex items-center mb-1">
                        <div className="bg-gray-500 rounded-full h-5 w-5 flex items-center justify-center z-10 sidebar-dot">
                          <p className="text-white text-xs">2</p>
                        </div>
                        <div
                          className={`flex-1 ml-4 text-sm ${this.state.step}`}
                        >
                          Mapel Rombel
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li className="mb-3">
                    <NavLink
                      to="/jadwal-pelajaran/daftar-mapel-rombel"
                      activeClassName="sidebar-active"
                      style={{ cursor: "pointer" }}
                      // onClick={e => e.preventDefault()}
                    >
                      <div className="flex items-center mb-1">
                        <div className="bg-gray-500 rounded-full h-5 w-5 flex items-center justify-center z-10 sidebar-dot">
                          <p className="text-white text-xs">3</p>
                        </div>
                        <div
                          className={`flex-1 ml-4 text-sm ${this.state.step}`}
                        >
                          Daftar Mapel Rombel
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li className="mb-3">
                    <NavLink
                      to="/jadwal-pelajaran/atur-mapel-siswa"
                      activeClassName="sidebar-active"
                      style={{ cursor: "pointer" }}
                      // onClick={e => e.preventDefault()}
                    >
                      <div className="flex items-center mb-1">
                        <div className="bg-gray-500 rounded-full h-5 w-5 flex items-center justify-center z-10 sidebar-dot">
                          <p className="text-white text-xs">4</p>
                        </div>
                        <div className="flex-1 ml-4 text-sm text-gray-500">
                          Mapel Siswa
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li className="mb-3">
                    <NavLink
                      to="/jadwal-pelajaran/daftar-mapel-siswa"
                      activeClassName="sidebar-active"
                      style={{ cursor: "pointer" }}
                      // onClick={e => e.preventDefault()}
                    >
                      <div className="flex items-center mb-1">
                        <div className="bg-gray-500 rounded-full h-5 w-5 flex items-center justify-center z-10 sidebar-dot">
                          <p className="text-white text-xs">5</p>
                        </div>
                        <div className="flex-1 ml-4 text-sm text-gray-500">
                          Daftar Mapel Siswa
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li className="mb-3">
                    <NavLink
                      to="/jadwal-pelajaran/atur-guru-waktu"
                      activeClassName="sidebar-active"
                      style={{ cursor: "pointer" }}
                      // onClick={e => e.preventDefault()}
                    >
                      <div className="flex items-center mb-1">
                        <div className="bg-gray-500 rounded-full h-5 w-5 flex items-center justify-center z-10 sidebar-dot">
                          <p className="text-white text-xs">6</p>
                        </div>
                        <div className="flex-1 ml-4 text-sm text-gray-500">
                          Guru Waktu
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li className="mb-3">
                    <NavLink
                      to="/jadwal-pelajaran/atur-guru-rombel"
                      activeClassName="sidebar-active"
                      style={{ cursor: "pointer" }}
                      // onClick={e => e.preventDefault()}
                    >
                      <div className="flex items-center mb-1">
                        <div className="bg-gray-500 rounded-full h-5 w-5 flex items-center justify-center z-10 sidebar-dot">
                          <p className="text-white text-xs">7</p>
                        </div>
                        <div className="flex-1 ml-4 text-sm text-gray-500">
                          Guru Rombel
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li className="mb-3">
                    <NavLink
                      to="/jadwal-pelajaran/atur-jadwal-pelajaran"
                      activeClassName="sidebar-active"
                      style={{ cursor: "pointer" }}
                      // onClick={e => e.preventDefault()}
                    >
                      <div className="flex items-center mb-1">
                        <div className="bg-gray-500 rounded-full h-5 w-5 flex items-center justify-center z-10 sidebar-dot">
                          <p className="text-white text-xs">8</p>
                        </div>
                        <div className="flex-1 ml-4 text-sm text-gray-500">
                          Jadwal Pelajaran
                        </div>
                      </div>
                    </NavLink>
                  </li>
                </ul>
                <div
                  className="border-r-2 border-gray-500 absolute h-full top-0"
                  style={{ left: "8px" }}
                ></div>
              </div>
            </div>
          </React.Fragment>
        );
    }
}