import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import conf from "./../../../config";
import axios from "axios";
import { browserHistory } from 'react-router';
// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

export default class Aktivasi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
     
    };
    // this.login = this.login.bind(this);
  }

  componentDidMount() {
    // get session user
    // if(localStorage.getItem('token') !== '')
    // {
    //   window.location.replace('/profil');
    // }
  }


  render() {
    return (
      <>
        <style dangerouslySetInnerHTML={{__html: `
            .sm-navbar { display: none };
          `}}
        />
        <style dangerouslySetInnerHTML={{__html: `
            body, html {
              height: 100%;
              background: url(/static/media/landing_page_bg.8e9cd166.png);
              background-size: cover;
              background-repeat: no-repeat;
              background-color: #09719d;
              height: 100% !important;
              background-position: center;
            }
          `}}
        />
        <div className="sm:hidden lg:block flex flex-col w-full" style={{height: '100%'}}>
          <nav>
            <div className="mx-auto flex justify-between pt-5" style={{maxWidth: "85rem"}}>
              <div className="flex ">
                <div
                  className="flex relative pl-1 pr-3 items-center "
                  style={{ height: "3.2rem"}}
                >
                  <div className="flex items-center w-full">
                    <div className="flex-shrink-0 z-50 flex items-center">
                      <img src="/assets/img/site-logo.png" className="ml-12" style={{ width: "13.8rem" }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <div className="block px-4 sm:px-6 lg:px-8 float-right">
                    <div className="ml-4 flex flex-row items-center">
                      <Link>
                        <h3 className="text-white font-bold text-md mr-5">TENTANG SCHOOLMEDIA</h3>
                      </Link>
                      {/* <Link>
                        <h3 className="text-white font-bold text-md ml-5">PROMO</h3>
                      </Link> */}
                    </div> 
                </div>
              </div>
            </div>
          </nav>
          <div className="flex">
            <div className="flex flex-row items-center mx-auto pt-16" style={{maxWidth: "78rem", width: '78rem'}}>
              <div className="flex flex-col w-1/2">
                <div className="flex flex-row mt-2">
                  {localStorage.getItem("alert") != undefined && localStorage.getItem("alert") != '' ? (
                    <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4" role="alert">
                      <p class="font-bold">Informasi</p>
                      <p>{localStorage.getItem("alert")}</p>
                    </div>
                  )  : ''}
                </div>
                <h3 className="text-white font-bolder" style={{fontSize: '2.8rem', fontWeight: 'bolder'}}>USER MANAGEMENT</h3>
                <div className="w-full shadow-md  mx-auto py-5 mt-6 rounded-lg bg-white border p-4">
                  <div className="w-full mt-2">
                    <button className="text-xl font-bold ml-2">
                      Form Aktivasi User
                    </button>
                  </div>
                  <hr className="my-4"></hr>
                  <div className="w-full mt-2">
                    <label className="mb-2 ml-2 text-gray-800 block text-sm">Email</label>
                    <input
                      type="text"
                      placeholder="Masukkan Email"
                      className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className="mb-2 ml-2 text-gray-800 block text-sm">Username</label>
                    <input
                      type="text"
                      placeholder="Masukkan Username"
                      className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className="mb-2 ml-2 text-gray-800 block text-sm">Kata Sandi</label>
                    <input
                      type="password"
                      placeholder="Masukkan Kata Sandi"
                      className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className="mb-2 ml-2 text-gray-800 block text-sm">Konfirmasi Kata Sandi</label>
                    <input
                      type="password"
                      placeholder="Masukkan Konfirmasi Kata Sandi"
                      className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                    />
                  </div>
                  <div className="w-full flex mt-4 justify-end ">
                    <button className="btn-green flex flex-row items-center rounded px-3 py-2 text-white text-lg font-bold ">
                      Aktivasi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-16 w-full flex justify-between pt-5">
            <div className="flex ">
              <div
                className="flex relative pr-3 items-center "
                style={{ height: "3.2rem"}}
              >
                <div className="flex items-center w-full">
                  <div className="flex-shrink-0 z-50 flex items-center">
                    <p className="text-white font-bold text-bold ">© 2018 Schoolmedia. All rights reserved</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:block lg:hidden">
          <nav className="w-full">
            <div className="w-full flex justify-between pt-5">
              <div className="w-1/2 flex ">
                <div
                  className="flex relative pl-1 pr-3 items-center "
                  style={{ height: "3.2rem"}}
                >
                  <div className="flex items-center w-full">
                    <div className="flex-shrink-0 z-50 flex items-center">
                      <img src="/assets/img/site-logo.png" className="ml-6 mt-10" style={{ width: "25.8rem" }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 flex items-center">
                <div className="hidden block px-4 float-right">
                    <div className="ml-4 flex flex-row items-center">
                      <Link>
                        <h3 className="text-white font-bold text-md mr-5">TENTANG SCHOOLMEDIA</h3>
                      </Link>
                      {/* <Link>
                        <h3 className="text-white font-bold text-md ml-5">PROMO</h3>
                      </Link> */}
                    </div> 
                </div>
              </div>
            </div>
          </nav>
          <div className="w-full p-10" style={{marginTop: '15rem'}}>
            <div className="flex items-center mx-auto">
              <div className="flex flex-col w-1/2">
                <div className="flex flex-row mt-2">
                  {localStorage.getItem("alert") != undefined && localStorage.getItem("alert") != '' ? (
                    <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4" role="alert">
                      <p class="font-bold">Informasi</p>
                      <p>{localStorage.getItem("alert")}</p>
                    </div>
                  )  : ''}
                </div>
                <h3 className="text-white font-bolder" style={{fontSize: '4rem', fontWeight: 'bolder'}}>USER MANAGEMENT</h3>
                <div className="w-full shadow-md  mx-auto py-5 mt-6 rounded-lg bg-white border p-4">
                  <div className="w-full mt-2">
                    <button className="text-xl font-bold ml-2">
                      Form Aktivasi User
                    </button>
                  </div>
                  <hr className="my-4"></hr>
                  <div className="w-full mt-2">
                    <label className="mb-2 ml-2 text-gray-800 block text-sm">Email</label>
                    <input
                      type="text"
                      placeholder="Masukkan Email"
                      className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className="mb-2 ml-2 text-gray-800 block text-sm">Username</label>
                    <input
                      type="text"
                      placeholder="Masukkan Username"
                      className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className="mb-2 ml-2 text-gray-800 block text-sm">Kata Sandi</label>
                    <input
                      type="password"
                      placeholder="Masukkan Kata Sandi"
                      className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <label className="mb-2 ml-2 text-gray-800 block text-sm">Konfirmasi Kata Sandi</label>
                    <input
                      type="password"
                      placeholder="Masukkan Konfirmasi Kata Sandi"
                      className="disabled:opacity-75 w-full pl-4 pr-4 my-0 py-2 rounded-full  border border-gray-500 focus:outline-none focus:shadow-outline text-gray-800"
                    />
                  </div>
                  <div className="w-full flex mt-4 justify-end ">
                    <button className="btn-green flex flex-row items-center rounded px-3 py-2 text-white text-lg font-bold ">
                      Aktivasi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto w-full flex justify-between pt-5 bottom-1" style={{position: 'absolute', left: '1.5rem'}}>
            <div className="flex ">
              <div
                className="flex relative pl-1 pr-3 items-center "
                style={{ height: "3.2rem"}}
              >
                <div className="flex items-center w-full">
                  <div className="flex-shrink-0 z-50 flex items-center">
                    <p className="text-white text-2xl font-bold text-bold ">© 2018 Schoolmedia. All rights reserved</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
