import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import conf from "../../config.js";
import axios from "axios";
// select style
const selectStyles = {
  control: styles => ({ ...styles, borderRadius: '100px', minWidth: '15rem' }),
};

export default class Redirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // url api
     
    };
  }

  componentDidMount(){
    const query = new URLSearchParams(this.props.location.search);
    const code = query.get('code')

    axios.post(`${conf.uri_backend}api/v1/auth/sso/login`, {code:code}).then(res => {
      if(res.data.access_token != undefined) {
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem('is_login', 'is_login');
        window.location.replace(`${conf.uri_frontend}profil`);
      }
      else{
        localStorage.setItem("token", "");
        localStorage.setItem('is_login', "");
        window.location.replace(`${conf.uri_frontend}`);
      }
    }).catch(function(response){
      if (response.response.status == 302){
        // alert('Hanya user dengan group code NI yang bisa masuk');
        window.location.replace(response.response.data.message);
      }
      else if(response.response.status == 403){
        localStorage.setItem("alert", response.response.data.messages);
        window.location.replace(`${conf.uri_frontend}`);
      }
    });
    
  }

  render() {
    // let modalPelajari=this.props.modal_pelajari
    return (
      <React.Fragment>
        <style dangerouslySetInnerHTML=
          {{
            __html: `.sm-navbar { display: none }`
          }}
        />
        <div className="loader-box" style={{background: "#0b0f25"}}>
          <div className="loader"></div>
        </div>
      </React.Fragment>
    ) 
  }
}
