import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Spinner from 'react-bootstrap/Spinner';

class UserEditSkeleton extends React.Component{
  render(){
    return(
      <>
        <div className="modal-body">
          <div className="flex flex-col w-full">
            <div className="w-50 mt-3">
              <Skeleton height={10}/>
            </div>
            <div className="w-full mt-1">
              <Skeleton height={25}/>
            </div>
            <div className="w-50 mt-3">
              <Skeleton height={10}/>
            </div>
            <div className="w-full mt-1">
              <Skeleton height={25}/>
            </div>
            <div className="w-50 mt-3">
              <Skeleton height={10}/>
            </div>
            <div className="w-full mt-1">
              <Skeleton height={25}/>
            </div>
            <div className="w-50 mt-3">
              <Skeleton height={10}/>
            </div>
            <div className="w-full mt-1">
              <Skeleton height={25}/>
            </div>
          </div>
        </div>
      </>
    )
  }
}

class UserDetailSkeleton extends React.Component{
  render(){
    return(
      <>
        <div className="modal-body" alt="Loading...">
            <div className="flex flex-col w-full">
              <div className={`w-full rounded-lg mb-10`} style={{height: '10rem'}}>
                <div className="overflow-hidden rounded-lg" style={{height: '10rem'}}>
                  <Skeleton height="100%"/>
                </div>
                <div className="flex flex-row px-4 pb-4 rounded-lg items-center w-full bg-transparent-grad relative" style={{bottom: "10rem", paddingTop: "6rem"}}>
                  {/* <img className="rounded-full border-4 border-white shadow-xl" style={{width: '6rem', height: '6rem'}} src={this.state.data_detail.foto_profil ? `https://static.schoolmedia.id/public/assets/socmed/NI/photo/${( this.state.data_detail.foto_profil )}` : (`${conf.uri_frontend}img/profile-unavailable.png`)}/> */}
                  <Skeleton circle={true} height={100} width={100}/>
                  <h1 className="text-xl font-bold text-gray-800 ml-2"></h1>
                </div>
              </div>
            </div>
            <div className="show-grid flex pt-3">
              <div className="sm:w-full w-1/2 pr-2">
                <div className="w-full mt-3">
                  <Skeleton height={25}/>
                </div>
                <div className="w-full mt-3">
                  <Skeleton height={25}/>
                </div>
                <div className="w-full mt-3">
                  <Skeleton height={25}/>
                </div>
              </div>
              <div className="sm:w-full w-1/2 pl-2">
                <div className="w-full mt-3">
                  <Skeleton height={25}/>
                </div>
                <div className="w-full mt-3">
                  <Skeleton height={25}/>
                </div>
                <div className="w-full mt-3">
                  <Skeleton height={25}/>
                </div>
              </div>
            </div>
            <div className="show-grid flex pt-3">
              <div className="sm:w-full w-1/2 pr-2">
                <div className="w-full mt-3">
                  <Skeleton height={75}/>
                </div>
              </div>
              <div className="sm:w-full w-1/2 pl-2">
                <div className="w-full mt-3">
                  <Skeleton height={75}/>
                </div>
              </div>
            </div>
        </div>
      </>
    )
  }
}

class UserPrintSkeleton extends React.Component{
  render(){
    return(
      <>
      <tr>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
      </tr>
      <tr>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
      </tr>
      <tr>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
      </tr>
      <tr>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
        <td><Skeleton height={25}/></td>
      </tr>
      </>
    )
  }
}

class UserButtonLoading extends React.Component{
  render(){
    var load = this.props.loadingText;
    return(
      <span className={`${this.props.color} text-gray`}>
        <Spinner animation="grow" size="sm" className="mr-2"/>
        {load === undefined ? 'Loading...' : load}
      </span>
    )
  }
}

class DetailSkeleton extends React.Component{
  render(){
    return(
      <div className="modal-body">
        <div className="show-grid flex pt-3">
              <div className="sm:w-full w-1/2 pr-2">
                <div className="w-full mt-3">
                  <Skeleton height={25}/>
                </div>
                <div className="w-full mt-3">
                  <Skeleton height={25}/>
                </div>
                <div className="w-full mt-3">
                  <Skeleton height={25}/>
                </div>
              </div>
              <div className="sm:w-full w-1/2 pl-2">
                <div className="w-full mt-3">
                  <Skeleton height={25}/>
                </div>
                <div className="w-full mt-3">
                  <Skeleton height={25}/>
                </div>
                <div className="w-full mt-3">
                  <Skeleton height={25}/>
                </div>
              </div>
            </div>
      </div>
    )
  }
}

export {UserEditSkeleton, UserDetailSkeleton, UserPrintSkeleton, UserButtonLoading, DetailSkeleton};