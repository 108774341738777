import React from "react";
import DataTable from "./../../../../partials/table/DataTables.js";
import conf from "./../../../../../config.js";

export default class ContentBangunan extends React.Component {
  componentDidMount() {
    let q = "";
    if (this.props.query) {
      q = `&query=${this.props.query}`;
    }
    var jenjang= '';
    if(this.props.jenjang === "sd"){
      jenjang = 2;
    }else{
      jenjang = this.props.jenjang;
    }
    this.dt.fetchEntities(
      `&jenjang_id=${jenjang}${q}`
    );
  }
  
  componentWillReceiveProps(nextProps) {
    let q = "";
    if (nextProps.query) {
      q = `&query=${nextProps.query}`;
    }
    var jenjang= '';
    if(nextProps.jenjang === "sd"){
      jenjang = 2;
    }else{
      jenjang = nextProps.jenjang;
    }
    this.dt.fetchEntities(
      `&jenjang_id=${jenjang}${q}`
    );
  }

  render() {
    return (
      <DataTable
        ref={(dt) => {
          this.dt = dt;
        }}
        url={`${conf.uri_backend}api/v1/referensi/sarpras/bangunan`}
        columns={[
          {
            index: "kode_deskripsi_bangunan",
            label: "Kode",
            maxWidth: "0.1rem ",
          },
          {
            index: "deskripsi_bangunan",
            label: "Deskripsi Bangunan",
            maxWidth: "6rem ",
          },
        ]}
      />
    );
  }
}
